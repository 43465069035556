import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { sumData } from './productivity/HelperFun';
require("highcharts/modules/exporting")(Highcharts);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#316076",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // "& .MuiTableCell-root": {
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];
function TableManPower(dt: any) {
    let data = dt.dt;

    return (
        <>
            <TableContainer style={{ maxHeight: 550 }} component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell rowSpan={2} width={200} style={{ position: 'sticky', left: 0, top: 0, zIndex: 1 }}>SPV</StyledTableCell>
                            <StyledTableCell rowSpan={2} style={{ position: 'sticky', left: 0, top: 0, }}>Training Level</StyledTableCell>
                            {month.map((value, index) => (
                                <StyledTableCell key={value} colSpan={3} style={{ position: 'sticky', left: 0, top: 0, borderLeft: "2px solid rgba(224, 224, 224, 1)" }} align="center">{value}</StyledTableCell>
                            ))}
                        </TableRow>
                        <TableRow key={month[0]}>
                            {month.map((value, index) => (
                                <React.Fragment key={value + index}>
                                    <TableCell style={{ position: 'sticky', backgroundColor: "white", top: 50, borderLeft: "2px solid rgba(224, 224, 224, 1)" }} align="right">MP</TableCell>
                                    <TableCell style={{ position: 'sticky', backgroundColor: "white", top: 50 }} align="right">DO</TableCell>
                                    <TableCell style={{ position: 'sticky', backgroundColor: "white", top: 50 }} align="right">Prod</TableCell>
                                </React.Fragment>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((val: any, i: number) => {
                            return <React.Fragment key={i+4}>
                                <TableRow >
                                    <TableCell style={{ fontSize: 14,position: 'sticky', left: 0, backgroundColor: 'white' }} rowSpan={6} colSpan={1}>{val.nama_spv}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Expert</TableCell>
                                    {month.map((value, index) => (
                                        <React.Fragment key={index+1}>
                                            <TableCell>{val[`dt${index}mp_expert`]}</TableCell>
                                            <TableCell>{val[`dt${index}do_expert`]}</TableCell>
                                            <TableCell>{val[`dt${index}prod_expert`]}</TableCell>
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{backgroundColor: "#d9d9d9"}}>Master</TableCell>
                                    {month.map((value, index) => (
                                        <React.Fragment key={index+1}>
                                            <TableCell sx={{backgroundColor: "#d9d9d9"}}>{val[`dt${index}mp_master`]}</TableCell>
                                            <TableCell sx={{backgroundColor: "#d9d9d9"}}>{val[`dt${index}do_master`]}</TableCell>
                                            <TableCell sx={{backgroundColor: "#d9d9d9"}}>{val[`dt${index}prod_master`]}</TableCell>
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    <TableCell>No Level</TableCell>
                                    {month.map((value, index) => (
                                        <React.Fragment key={index+1}>
                                            <TableCell>{val[`dt${index}mp_untrained`]}</TableCell>
                                            <TableCell>{val[`dt${index}do_untrained`]}</TableCell>
                                            <TableCell>{val[`dt${index}prod_untrained`]}</TableCell>
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{backgroundColor: "#d9d9d9"}}>Professional</TableCell>
                                    {month.map((value, index) => (
                                        <React.Fragment key={index+1}>
                                            <TableCell sx={{backgroundColor: "#d9d9d9"}}>{val[`dt${index}mp_professional`]}</TableCell>
                                            <TableCell sx={{backgroundColor: "#d9d9d9"}}>{val[`dt${index}do_professional`]}</TableCell>
                                            <TableCell sx={{backgroundColor: "#d9d9d9"}}>{val[`dt${index}prod_professional`]}</TableCell>
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
                                    {month.map((value, index) => (
                                        <React.Fragment key={index+1}>
                                            <TableCell sx={{ fontWeight: "bold" }}>{sumData(val[`dt${index}mp_professional`], val[`dt${index}mp_master`], val[`dt${index}mp_expert`], val[`dt${index}mp_untrained`])}</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>{sumData(val[`dt${index}do_professional`], val[`dt${index}do_master`], val[`dt${index}do_expert`], val[`dt${index}do_untrained`])}</TableCell>
                                            <TableCell sx={{ fontWeight: "bold" }}>{sumData(val[`dt${index}do_professional`], val[`dt${index}do_master`], val[`dt${index}do_expert`], val[`dt${index}do_untrained`]) === 0 ? 0 : ((sumData(val[`dt${index}do_professional`], val[`dt${index}do_master`], val[`dt${index}do_expert`], val[`dt${index}do_untrained`]) / sumData(val[`dt${index}mp_professional`], val[`dt${index}mp_master`], val[`dt${index}mp_expert`], val[`dt${index}mp_untrained`]))).toFixed(1)}</TableCell>
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                            </React.Fragment>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

type chart = {
    title: string;
    category: string[];
    dt: {
        dtMP: number[];
        dtDO: number[];
        dtProd: number[];
    };
    legend ?: {a: string, b: string, c: string};
}
function ChartMoM({ title, category, dt, legend }: chart) {
    const chartComponent = React.useRef(null);
    let max2 = 0;
    let data = dt?.dtDO?.concat(dt.dtMP);
    data?.map((d: number) => {
        max2 = Math.max(max2, d);
        return max2;
    });


    let max = 0;
    dt.dtProd?.map((d: number) => {
        max = Math.max(max, d);
        return max;
    });
    // console.log("max2", max);

    const options = {
        chart: {
            backgroundColor: "transparent",
            // zoomType: "xy",
            // height: 350
        },
        credits: {
            enabled: false,
        },
        title: {
            text: "",
            align: "left",
        },
        xAxis: [
            {
                categories: category,
                // disable shadow behind labels
                crosshair: false,
            },
        ],
        yAxis: [
            {
                visible: false,
                //   tickAmount: 7,
                min: -40,
                max: max + 10,
                // Primary yAxis
                labels: {
                    // format: "{value}°C",
                    style: {
                        //   color: Highcharts.getOptions().colors[1],
                    },
                },
                title: {
                    text: "",
                    style: {
                        //   color: Highcharts.getOptions().colors[1],
                    },
                },
            },
            {
                visible: false,
                // Secondary yAxis
                min: 0,
                max: max2 + 200,
                title: {
                    text: "",
                    style: {
                        //   color: Highcharts.getOptions().colors[0],
                    },
                },
                labels: {
                    format: "{value} mm",
                    style: {
                        //   color: Highcharts.getOptions().colors[0],
                    },
                },
                opposite: true,
            },
        ],
        tooltip: {
            shared: true,
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            // x: 80,
            // verticalAlign: "bottom",
            // y: 60,
            // floating: true,
            backgroundColor: "rgba(255,255,255,0.25)",
        },
        plotOptions: {
            series: {
                // maxPointWidth: 20,
                dataLabels: {
                    enabled: true,
                },
            },
            column: {
                pointPadding: 0,
                borderWidth: 2,
                groupPadding: 0.1,
            },
            spline: {
                dataLabels: {
                    // format: "{y}%",
                    format: "{point.y:.1f}"
                },
            },
        },
        series: [
            {
                name: legend?.a || "MP",
                type: "column",
                color: "#287f9d",
                yAxis: 1,
                data: dt.dtMP,
                tooltip: {
                    // valueSuffix: " mm",
                },
            },
            {
                name: legend?.b || "DO",
                type: "column",
                color: "#fcc47c",
                yAxis: 1,
                data: dt.dtDO,
                tooltip: {
                    // valueSuffix: " mm",
                },
            },
            {
                name: legend?.c || "Prod",
                type: "spline",
                data: dt.dtProd,
                tooltip: {
                    valueSuffix: "",
                    valueDecimals: 2,
                },
            },
        ],
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            layout: "horizontal",
                            align: "center",
                            verticalAlign: "bottom",
                        },
                    },
                },
            ],
        },
    };
    return (
        <>
            <div style={{ overflow: "visible" }}>
                <HighchartsReact
                    ref={chartComponent}
                    highcharts={Highcharts}
                    options={options}
                // series={series}
                />
            </div>
        </>
    );
}

function TableProductivity({dt}: any) {
    return (
        <>
            <TableContainer style={{ maxHeight: 500 }} component={Paper}>
                <Table sx={{ minWidth: 800 }} aria-label="customized table">
                    <TableHead>
                        <TableRow key={1}>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0, zIndex: 1 }}>Outlet</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Total MP</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0, }}>Total DO</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Prod</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Prod (+)</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Prod (+) %</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Prod (-)</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Prod (-) %</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Unprod</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Unprod %</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dt?.map((row:any, i:number) => (
                                <StyledTableRow key={i}>
                                    <StyledTableCell style={{ position: 'sticky', left: 0, backgroundColor: 'white' }} component="th" scope="row">
                                        {row.outlet}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{row.countMP}</StyledTableCell>
                                    <StyledTableCell align="right">{row.countDO}</StyledTableCell>
                                    <StyledTableCell align="right">{parseFloat(row.prod).toFixed(2)}</StyledTableCell>
                                    <StyledTableCell align="right">{row.prodP}</StyledTableCell>
                                    <StyledTableCell align="right">{`${row.prodPComp}%`}</StyledTableCell>
                                    <StyledTableCell align="right">{row.prodM}</StyledTableCell>
                                    <StyledTableCell align="right">{`${row.prodMComp} %`}</StyledTableCell>
                                    <StyledTableCell align="right">{row.unProd}</StyledTableCell>
                                    <StyledTableCell align="right">{`${row.unProdComp} %`}</StyledTableCell>
                                </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

function PieProductivity(dt: any) {
    const chartComponent = React.useRef(null);

    // React.useEffect(() => {
    //     console.log("DT", dt);
        
    // }, [dt]);

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            backgroundColor: "transparent",
            type: "pie",
            height: 500
        },
        title: {
            text: "Category Productivity",
            align: "center",
        },
        tooltip: {
            pointFormat: "{point.y} <b>({point.percentage:.0f} %)</b>"
            // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            // pointFormat: "{point.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
            point: {
                valueSuffix: "%",
            },
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            // itemMarginTop: 10,
            // itemMarginBottom: 10
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    // format: "{point.y:.1f} %",
                    format: "{point.percentage:.0f} %",
                    distance: -30,
                    align: "center",
                },
                slicedOffset: 0,
                showInLegend: true,
            },
        },
        series: [
            {
                // colorByPoint: true,
                name: "Brands",
                colorByPoint: true,
                states: {
                    hover: {
                        enabled: true,
                    },
                    inactive: {
                        enabled: false,
                    },
                },
                data: [
                    {
                        name: "Prod +",
                        y: dt?.dt?.prodP,
                        color: "#439f47",
                        // sliced: true,
                        // selected: true,
                    },
                    {
                        name: "Prod -",
                        y: dt?.dt?.prodM,
                        color: "#FCC47C"
                    },
                    {
                        name: "Unprod",
                        y: dt?.dt?.unProd,
                        color: "#BF392B"
                    },
                ],
            },
        ],
    };
    return (
        <>
            <HighchartsReact
                ref={chartComponent}
                highcharts={Highcharts}
                options={options}
            // series={series}
            />
        </>
    );
}

export { TableProductivity, ChartMoM, TableManPower, PieProductivity }