import React, { useEffect, useMemo, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { MaterialReactTableDetails } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';
import { HighchartsSample } from '../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample';
import { DateFilter, LeasingFilter } from '../../../../hooks/filterMSCP';
import { calcPercentage, equalsCheck } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';
import GetDataFilter from '../../../../hooks/filter';
import { MscpAPI } from '../../../../api/MscpAPI';
import DataTableMuiModel from '../../../../models/DataTable/dataTableModal';
import { ChartLeadtimeHelper, ChartMoMHelper, RatebyModel, RatebyOutlet, RejectBeforeProcessing } from './helper';
import LeadtimeApproval from './LeadtimeApproval';
import { Skeleton } from '@mui/material';
import TypesFilter from '../../../../hooks/filterType';

export type dates = {
    month: string[];
    year: string[];
}
const DashboardIndex = (props: any) => {
    // Leasing Filter
    const leasingOpt = LeasingFilter();
    const modelOpt = TypesFilter();
    const [tempLeasing, setTempLeasing] = useState<Option[]>([]);
    const [leasingValue, setLeasingValue] = useState<any>([]);
    const [dtBoxes, setDtBoxes] = useState<any>([]);

    // Date Filter
    const dateOpt = DateFilter();
    const [dateValue, setDateValue] = useState<any>([]);
    const [tempDate, setTempDate] = useState<Option[]>([]);
    const [dateSelected, setDateSelected] = useState<dates>({
        month: [],
        year: [],
    });

    // Filter ASM & Outlet
    const [asmVal, setAsmVal] = useState<string[]>([]);
    const [tempAsm, setTempAsm] = useState<Option[]>([]);
    const [outletVal, setOutletVal] = useState<string[]>([]);
    const [tempOutlet, setTempOutlet] = useState<Option[]>([]);
    const filterData = GetDataFilter({ asmVal: asmVal, outletVal: outletVal });

    // // Approval Rate and Rejection Rate by Outlet
    const [columnApproveRejectOutlet, setColumnApproveRejectOutlet] = useState<any>([]);
    const [dataApproveRejectOutlet, setDataApproveRejectOutlet] = useState<any>([]);
    const [columnApproveRejectModel, setColumnApproveRejectModel] = useState<any>([]);
    const [dataApproveRejectModel, setDataApproveRejectModel] = useState<any>([]);
    // Reject Before Processing
    const [columnRejectOutlet, setColumnRejectOutlet] = useState<any>([]);
    const [dataRejectOutlet, setDataRejectOutlet] = useState<any>([]);
    const [columnRejectModel, setColumnRejectModel] = useState<any>([]);
    const [dataRejectModel, setDataRejectModel] = useState<any>([]);
    // ChartMoMRate
    const [dtChartMoM, setdtChartMoM] = useState<any>([]);

    const dataTable = async ({ coverage, dates, leasing }) => {
        const { data: tableApprovalRateOutlet } = await new MscpAPI().getDtTableRatebyOutlet({ coverage, dates, leasing })

        let tableApprovalRatebyOutlet = new DataTableMuiModel({ readOnly: true });
        tableApprovalRateOutlet.map((item: any, i: number) => {
            let dataModel = new RatebyOutlet(item);
            tableApprovalRatebyOutlet.add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null
            })
            return item
        })
        setColumnApproveRejectOutlet(await tableApprovalRatebyOutlet.getColumn());
        setDataApproveRejectOutlet(await tableApprovalRatebyOutlet.getDatas());

        let tableRejectRatebyOutlet = new DataTableMuiModel({ readOnly: true });
        tableApprovalRateOutlet.map((item: any, i: number) => {
            let dataModel = new RejectBeforeProcessing(item);
            tableRejectRatebyOutlet.add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null
            })
            return item
        })
        setColumnRejectOutlet(await tableRejectRatebyOutlet.getColumn());
        setDataRejectOutlet(await tableRejectRatebyOutlet.getDatas());

        const { data: tableApprovalRateModel } = await new MscpAPI().getDtTableRatebyModel({ coverage, dates, leasing })

        let tableApprovalRatebyModel = new DataTableMuiModel({ readOnly: true });
        tableApprovalRateModel.map((item: any, i: number) => {
            let dataModel = new RatebyModel(item);
            tableApprovalRatebyModel.add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null
            })
            return item
        })

        setColumnApproveRejectModel(await tableApprovalRatebyModel.getColumn());
        setDataApproveRejectModel(await tableApprovalRatebyModel.getDatas());


        let tableRejectRatebyModel = new DataTableMuiModel({ readOnly: true });
        tableApprovalRateModel.map((item: any, i: number) => {
            let dataModel = new RejectBeforeProcessing(item);
            tableRejectRatebyModel.add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null
            })
            return item
        })

        setColumnRejectModel(await tableRejectRatebyModel.getColumn());
        setDataRejectModel(await tableRejectRatebyModel.getDatas());
    }
    const getData = async ({ coverage, dates, leasing }) => {
        Promise.all([
            new MscpAPI().getDtBox({ coverage, dates, leasing }).then((res: any) => {
                setDtBoxes(res.data)
            }),
            new MscpAPI().getDtMoMRate({ coverage, dates, leasing }).then((res: any) => {
                setdtChartMoM(res.data)
            }),
        ]).then(() => {
            console.log("Done")
        })
    }
    let memoInitialData = useMemo(() => {
        let initialDates = {
            month: dateOpt?.map((val: dates) => val.month),
            year: dateOpt?.map((val: dates) => val.year)
        }
        return {
            dtTempLeasing: leasingValue.length > 0 ? leasingValue : leasingOpt.map((val: any) => val.value),
            dtTempDate: dateSelected?.month.length > 0 ? dateSelected : initialDates

        }
    }, [leasingValue, leasingOpt, dateOpt, dateSelected]);

    useEffect(() => {

        if (filterData.coverage) {
            getData({ coverage: filterData.coverage, dates: memoInitialData.dtTempDate, leasing: memoInitialData.dtTempLeasing });
            dataTable({ coverage: filterData.coverage, dates: memoInitialData.dtTempDate, leasing: memoInitialData.dtTempLeasing });
        }

    }, [filterData.coverage, leasingValue, leasingOpt, dateOpt, dateSelected, memoInitialData]);

    return (
        <React.Fragment>
            <PageTitle breadcrumbs={[{ title: "Credit Process", path: "/sales/dashboard", isSeparator: false, isActive: false }]}>
                {"Dashboard"}
            </PageTitle>
            {/* BEGIN:: Filter Input */}
            <div className="row g-5">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-3 mb-5">
                        <FilterMultiSelect
                            label={"ASM"}
                            name="asm"
                            options={filterData?.asmOpt}
                            onMenuClose={() => {
                                let toFilter = tempAsm?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    asmVal
                                )
                                    ? null
                                    : setAsmVal(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempAsm(selected);
                            }}
                            value={tempAsm}
                        />
                    </div>
                    <div className="col-lg-3 mb-5">
                        <FilterMultiSelect
                            label={"Outlet"}
                            name="outlet"
                            options={filterData?.outletOpt}
                            onMenuClose={() => {
                                let toFilter = tempOutlet?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    outletVal
                                )
                                    ? null
                                    : setOutletVal(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempOutlet(selected);
                            }}
                            value={tempOutlet}
                        />
                    </div>
                    <div className="col-lg-3 mb-5">
                        <FilterMultiSelect
                            label={"Leasing"}
                            name="leasing"
                            options={leasingOpt}
                            onMenuClose={() => {
                                let toFilter = tempLeasing?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    leasingValue
                                )
                                    ? null
                                    : setLeasingValue(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempLeasing(selected);
                            }}
                            value={tempLeasing}
                        />
                    </div>
                    <div className="col-lg-3 mb-5">
                        <FilterMultiSelect
                            label={"Date"}
                            name="date"
                            options={dateOpt}
                            onMenuClose={() => {
                                let toFilter = tempDate?.map((val) => val.value);
                                let forDates = {
                                    month: tempDate?.map((val: Option) => val.month),
                                    year: tempDate?.map((val: Option) => val.year)
                                }
                                let toDates = tempDate.length > 0 ? forDates
                                    : ({
                                        month: dateOpt?.map((val: dates) => val.month),
                                        year: dateOpt?.map((val: dates) => val.year)
                                    })
                                console.log("toDates", toDates);

                                setDateSelected(toDates as any);

                                return equalsCheck(
                                    toFilter as [],
                                    dateValue
                                )
                                    ? null
                                    : setDateValue(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempDate(selected);
                            }}
                            value={tempDate}
                        />
                    </div>
                </div>
            </div >
            {/* END:: Filter Input */}
            {/* ----------------------------------------------------------------------------- */}
            {/* BEGIN:: Application In, Processed, Approve, Reject, Reject before Processing & In Process */}
            <div className="row g-5 mh-xxl-210px">
                {/* Application In */}
                <div className="col-xxl-2 bd-highlight">
                    <KTCard stretch='stretch' className='mb-5 mh-160px'>
                        <div className="h-auto py-3 px-1 ">
                            <div className="d-flex justify-content-center flex-column align-items-center">
                                <div className="fs-2 text-gray-600 fw-bolder">Application In</div>
                                <div className="fs-2x my-5 text-gray-800 fw-bolder">{dtBoxes?.allIn}</div>
                                <div className="d-flex flex-wrap justify-content-center mb-5">
                                    <div className="w-60px mx-xxl-5">
                                        <div className="d-flex justify-content-center flex-column align-items-center">
                                            <div className="fs-9 text-gray-500 fw-bolder text-center w-60px text-wrap">New Process</div>
                                            <div className="fs-1 fw-bolder">{dtBoxes?.dtNewProcess}</div>
                                        </div>
                                    </div>
                                    <div className="w-80px">
                                        <div className="d-flex justify-content-center flex-column align-items-center">
                                            <div className="fs-9 text-gray-500 fw-bolder text-center w-60px text-wrap">Carry Over Process</div>
                                            <div className="fs-1 fw-bolder">{dtBoxes?.dtCarryOver}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </KTCard>
                </div>
                {/* Processed */}
                <div className="col-xxl-2 bd-highlight">
                    <KTCard stretch='stretch' className='mb-5 mh-160px'>
                        <div className="h-auto py-3 px-1 ">
                            <div className="d-flex justify-content-center flex-column align-items-center">
                                <div className="fs-2 text-gray-600 fw-bolder">Processed</div>
                                <div className="fs-2x my-5 text-gray-800 fw-bolder">{dtBoxes?.dtProcessed}</div>
                                <div className="d-flex flex-wrap justify-content-center mb-5">
                                    <div className="min-w-60px mx-xxl-5">
                                        <div className="d-flex justify-content-center flex-column align-items-center">
                                            <div className="fs-9 text-gray-500 fw-bolder"></div>
                                            <div className="fs-1x fw-bolder"></div>
                                        </div>
                                    </div>
                                    <div className="min-w-80px">
                                        <div className="d-flex justify-content-center flex-column align-items-center">
                                            <div className="fs-9 text-gray-500 fw-bolder"></div>
                                            <div className="fs-1x fw-bolder"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </KTCard>
                </div>
                {/* Approve */}
                <div className="col-xxl-2 bd-highlight">
                    <KTCard stretch='stretch' className='mb-5 mh-160px'>
                        <div className="h-auto py-3 px-1 ">
                            <div className="d-flex justify-content-center flex-column align-items-center">
                                <div className="fs-2 text-gray-600 fw-bolder">Approve</div>
                                <div className="fs-2x my-5 text-gray-800 fw-bolder">{dtBoxes?.dtApprove}</div>
                                <div className="d-flex flex-column align-items-center justify-content-center mb-5">
                                    <div className="fs-1 text-gray-800 fw-bolder">{calcPercentage(dtBoxes?.dtApprove, dtBoxes?.dtProcessed)}</div>
                                </div>
                            </div>
                        </div>
                    </KTCard>
                </div>
                {/* Reject */}
                <div className="col-xxl-2 bd-highlight">
                    <KTCard stretch='stretch' className='mb-5 mh-160px'>
                        <div className="h-auto py-3 px-1 ">
                            <div className="d-flex justify-content-center flex-column align-items-center">
                                <div className="fs-2 text-gray-600 fw-bolder">Reject</div>
                                <div className="fs-2x my-5 text-gray-800 fw-bolder">{dtBoxes?.dtAllReject}</div>
                                <div className="d-flex flex-column align-items-center justify-content-center mb-5">
                                    <div className="fs-1 text-gray-800 fw-bolder">{calcPercentage(dtBoxes?.dtAllReject, dtBoxes?.dtProcessed)}</div>
                                </div>
                            </div>
                        </div>
                    </KTCard>
                </div>
                {/* Reject before Processing */}
                <div className="col-xxl-2 bd-highlight">
                    <KTCard stretch='stretch' className='mb-5 mh-160px bg-opacity-25 bg-warning'>
                        <div className="h-auto py-3 px-1 ">
                            <div className="d-flex justify-content-center flex-column align-items-center">
                                <div className="fs-2 text-gray-600 fw-bolder text-wrap text-center">Reject before Processing</div>
                                <div className="fs-2x my-5 text-gray-800 fw-bolder">{dtBoxes?.dtReject}</div>
                                <div className="d-flex flex-column align-items-center justify-content-center mb-5">
                                    <div className="fs-3 text-gray-800 fw-bolder">{calcPercentage(dtBoxes?.dtReject, dtBoxes?.allIn)}</div>
                                    <div className="fs-1x text-gray-800 fw-bolder text-wrap text-center">Compare to Application In</div>
                                </div>
                            </div>
                        </div>
                    </KTCard>
                </div>
                {/* Reject before Processing */}
                <div className="col-xxl-2 bd-highlight">
                    <KTCard stretch='stretch' className='mb-5 mh-160px bg-opacity-25 bg-warning'>
                        <div className="h-auto py-3 px-1 ">
                            <div className="d-flex justify-content-center flex-column align-items-center">
                                <div className="fs-2 text-gray-600 fw-bolder text-wrap text-center">In Process</div>
                                <div className="fs-2x my-5 text-gray-800 fw-bolder">{dtBoxes?.dtInProcess}</div>
                                <div className="d-flex flex-column align-items-center justify-content-center mb-5">
                                    <div className="fs-1 text-gray-800 fw-bolder">{calcPercentage(dtBoxes?.dtInProcess, dtBoxes?.dtProcessed)}</div>
                                    <div className="fs-1x text-gray-800 fw-bolder text-wrap text-center"></div>
                                </div>
                            </div>
                        </div>
                    </KTCard>
                </div>
            </div>
            {/* END:: Application In, Processed, Approve, Reject, Reject before Processing & In Process  */}
            {/* ----------------------------------------------------------------------------- */}
            {/* BEGIN:: Approval Rate and Rejection Rate by Outlet, Approval Rate and Rejection Rate by Model, Reject Before Processing by Outlet & Reject Before Processing by Type */}
            <div className="row g-5 mh-xxl">
                {/* Approval Rate and Rejection Rate by Outlet */}
                <div className="col-xxl-6 bd-highlight">
                    <KTCard stretch='stretch' className='mb-5'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1x">Approval Rate and Rejection Rate by Outlet</span>
                            </h3>
                        </div>
                        <KTCardBody className="pl-5 px-2 pt-0">
                            <MaterialReactTableDetails
                                data={dataApproveRejectOutlet??[]}
                                columns={columnApproveRejectOutlet??[]}
                                isLoading={false}
                                maxHeight={"700px"}
                                hover={false}
                                optionsHead={{
                                    backgroundColor: '#0097a7',
                                    fontSize: 10,
                                }}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
                {/* Approval Rate and Rejection Rate by Model */}
                <div className="col-xxl-6 bd-highlight">
                    <KTCard stretch='stretch' className='mb-5 h-auto'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1x">Approval Rate and Rejection Rate by Model</span>
                            </h3>
                        </div>
                        <KTCardBody className="pl-5 px-2 pt-0">
                            <MaterialReactTableDetails
                                data={dataApproveRejectModel}
                                columns={columnApproveRejectModel}
                                isLoading={false}
                                maxHeight={"400px"}
                                hover={false}
                                optionsHead={{
                                    backgroundColor: '#0097a7',
                                    fontSize: 10,
                                }}
                            />
                        </KTCardBody>
                    </KTCard>
                    <div className='row g-5 mt-5'>
                        {/* Reject Before Processing by Outlet */}
                        <div className="col-xxl-6 bd-highlight">
                            <KTCard stretch='stretch' className='mb-5'>
                                <div className="card-header justify-content-center">
                                    <h3 className="card-title align-items-center flex-column">
                                        <span className="card-label fw-bold fs-6 text-center text-wrap">Reject Before Processing by Outlet</span>
                                    </h3>
                                </div>
                                <KTCardBody className="pl-5 px-2 pt-0">
                                    <MaterialReactTableDetails
                                        data={dataRejectOutlet}
                                        columns={columnRejectOutlet}
                                        isLoading={false}
                                        maxHeight={"250px"}
                                        hover={false}
                                        enableTopToolbar={false}
                                        optionsHead={{
                                            backgroundColor: '#d32f2f',
                                            fontSize: 10,
                                        }}
                                    />
                                </KTCardBody>
                            </KTCard>
                        </div>
                        {/* Reject Before Processing by Type */}
                        <div className="col-xxl-6 bd-highlight">
                            <KTCard stretch='stretch' className='mb-5'>
                                <div className="card-header h-100 justify-content-center">
                                    <h3 className="card-title align-items-center flex-column">
                                        <span className="card-label fw-bold fs-6 text-center text-wrap">Reject Before Processing by Type</span>
                                    </h3>
                                </div>
                                <KTCardBody className="pl-5 px-2 pt-0">
                                    <MaterialReactTableDetails
                                        data={dataRejectModel}
                                        columns={columnRejectModel}
                                        isLoading={false}
                                        maxHeight={"250px"}
                                        hover={false}
                                        enableTopToolbar={false}
                                        optionsHead={{
                                            backgroundColor: '#d32f2f',
                                            fontSize: 10,
                                        }}
                                    />
                                </KTCardBody>
                            </KTCard>
                        </div>
                    </div>
                </div>
            </div>
            {/* END:: Table MME  */}
            {/* ----------------------------------------------------------------------------- */}
            {/* ----------------------------------------------------------------------------- */}
            {/* Leadtime by Outlet & Approval by DP Application */}
            {filterData.coverage ?
                <LeadtimeApproval leasing={memoInitialData.dtTempLeasing} dates={memoInitialData.dtTempDate} coverage={filterData.coverage} modelOpt={modelOpt} />
                : <Skeleton height={400} />}
            {/* MoM Approval rate and Rejection rate */}
            <div className="row g-5"
            style={{ height: "100vh" }}>
                <div className="bd-highlight">
                    <KTCard className='mb-5'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1">MoM Approval rate and Rejection rate</span>
                            </h3>
                        </div>
                        <KTCardBody className="pl-5 pt-0">
                            <HighchartsSample
                                id="chart_line"
                                type="line"
                                height='70px'
                                series={[{
                                    name: "Approved",
                                    type: "line",
                                    data: dtChartMoM?.datas?.map((v) => v.approve),
                                    tooltip: {
                                        valueSuffix: "",
                                    },
                                    dataLabels : {
                                        format: "{point.y:.0f}",
                                    }
                                }]}
                                categories={dtChartMoM?.fullMonth}
                                chartStyle={{
                                    spacingLeft: 5,
                                    spacingRight: 5,
                                }}
                                optionsSetting={{
                                    // colors: ["#43a047"],
                                    xAxis: {
                                        visible: false
                                    }, yAxis: {
                                        visible: false
                                    }, legend: {
                                        enabled: false
                                    }
                                }}
                            />
                            <ChartMoMHelper dt={dtChartMoM}/>
                            {/* <HighchartsSample
                                id="whole_sales_column_stacked_chart"
                                type="column_stacked"
                                stacking="percent"
                                height="450px"
                                series={[]}
                                categories={[]}
                                chartStyle={{
                                    spacingLeft: 5,
                                    spacingRight: 5,
                                }}
                            /> */}
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
        </React.Fragment >
    )
}

export default DashboardIndex;