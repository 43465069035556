import React, { Fragment, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);

type Props = {
    comp: {
        title: string,
        data?: Array<string | number>
    }
};

type PropsSPKbyModel = {
    comp: {
        title: string,
        data?: {
            tempData: any
            model: Array<string | number>
            sumberProspek: Array<string | number>
        }
    }
};


function tableRScolor(val: number) {
    if (val < 70) {
        return "#F4C7C3";
    } else if (val >= 70) {
        return "#C8E6C9";
    } else {
        return "transparent";
    }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    // '&:last-child td, &:last-child th': {
    //   border: 0,
    // },
}));

function ExecutionTable({ comp }: Props) {
    useEffect(() => {
        // datas.push(newData);

    }, [comp]);
    return (
        <>
            <div className="d-flex align-items-center flex-column mt-3 border-0 pt-5">
                {/* begin::Title */}
                <h3 className="align-items-center flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">{comp.title}</span>
                </h3>
            </div>
            <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
                <Table sx={{ minWidth: 650, overflow: "scroll", '&:last-child td, &:last-child th': { border: 0 } }} size="small" aria-label="a dense table">
                    <TableHead
                        sx={{
                            position: "sticky",
                            top: "0px",
                            backgroundColor: "rgb(56,73,102)",
                            // zIndex: 0,
                        }}
                    >
                        <TableRow>
                            <TableCell sx={{ color: "white" }}>Kab/Kota</TableCell>
                            <TableCell align="right" sx={{ color: "white" }}>Kecamatan</TableCell>
                            <TableCell align="right" sx={{ color: "white" }}>Lokasi Plan</TableCell>
                            <TableCell align="right" sx={{ color: "white" }}>Lokasi Actual</TableCell>
                            <TableCell align="right" sx={{ color: "white" }}>Plan Activity</TableCell>
                            <TableCell align="right" sx={{ color: "white" }}>Visitor Plan</TableCell>
                            <TableCell align="right" sx={{ color: "white" }}>Visitor Actual</TableCell>
                            <TableCell align="right" sx={{ color: "white" }}>Prospect Plan</TableCell>
                            <TableCell align="right" sx={{ color: "white" }}>Cost / SPK</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {comp.data?.map((row: any, i: number) => (
                            <StyledTableRow key={i}>
                                <StyledTableCell component="th" scope="row">
                                    {row.nama_kabupaten}
                                </StyledTableCell>
                                <StyledTableCell align="right">{row.nama_kecamatan}</StyledTableCell>
                                <StyledTableCell align="right">{row.lokasi}</StyledTableCell>
                                <StyledTableCell align="right">{row.lokasi}</StyledTableCell>
                                <StyledTableCell align="right">{row.event_jenis}</StyledTableCell>
                                <StyledTableCell align="right">{row.t_visitor}</StyledTableCell>
                                <StyledTableCell align="right">{row.r_visitor}</StyledTableCell>
                                <StyledTableCell align="right">{row.t_prospect}</StyledTableCell>
                                <StyledTableCell style={{ backgroundColor: tableRScolor(row.cost_per_spk) }} align="right">{row.cost_per_spk?.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

type MoMChartProps = {
    title: string,
    data: [],
}
function MoMChart({ title, data }: MoMChartProps) {
    useEffect(() => {

    }, [data]);
    const options = {
        chart: {
            backgroundColor: "transparent",
            zoomType: "xy",
            height: 750,
        },
        title: {
            text: "",
            style: {
                fontSize: "25px",
            },
            align: "center",
            verticalAlign: "top",
        },
        xAxis: {
            // categories: [
            //     "Jan",
            //     "Feb",
            //     "Mar",
            //     "Apr",
            //     "May",
            //     "Jun",
            //     "Jul",
            //     "Aug",
            //     "Sep",
            //     "Oct",
            //     "Nov",
            //     "Dec",
            // ],
            categories: data.map((item: any) => item.bulan),
        },
        yAxis: [
            {
                visible: false,
            },
        ],
        plotOptions: {
            series: {
                marker: {
                    /* fillColor: '#FFFFFF', */
                    lineWidth: 2,
                    lineColor: null, // inherit from series
                },
            },
        },
        tooltip: {
            shared: true,
        },
        series: [
            {
                name: "Visitor Actual",
                marker: {
                    symbol: "circle",
                },
                data: data.map((item: any) => item.suspect),
            },
            {
                name: "Prospect Actual",
                marker: {
                    symbol: "circle",
                },
                data: data.map((item: any) => item.prospect),
            },
            {
                name: "Test Drive Actual",
                marker: {
                    symbol: "circle",
                },
                data: data.map((item: any) => item.testdrive),
            },
            {
                name: "SPK Actual",
                marker: {
                    symbol: "circle",
                },
                data: data.map((item: any) => item.spk),
            },
        ],
    };
    return (
        <>
         <div className="d-flex align-items-center flex-column my-10 border-0">
                {/* begin::Title */}
                <h2 className="align-items-center flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">{title}</span>
                </h2>
            </div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </>
    );
}
function ConvertionTable({ comp }: Props) {
    useEffect(() => {
    }, [comp]);
    return (
        <>
            <div className="d-flex align-items-center flex-column my-10 border-0">
                {/* begin::Title */}
                <h2 className="align-items-center flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">{comp.title}</span>
                </h2>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ maxHeight: 100, '&:last-child td, &:last-child th': { border: 0 } }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#384966" }}>
                            <TableCell style={{ color: "white" }} align="center">Model</TableCell>
                            <TableCell style={{ color: "white" }} align="center">Visitor</TableCell>
                            <TableCell style={{ color: "white" }} align="center">Prospect</TableCell>
                            <TableCell style={{ color: "white" }} align="center">Visitor-Prospect</TableCell>
                            <TableCell style={{ color: "white" }} align="center">Test Drive</TableCell>
                            <TableCell style={{ color: "white" }} align="center">Prospect-Test Drive</TableCell>
                            <TableCell style={{ color: "white" }} align="center">SPK</TableCell>
                            <TableCell style={{ color: "white" }} align="center">Test Drive - SPK</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {comp.data?.map((row: any) => (
                            <StyledTableRow key={row.type}>
                                <StyledTableCell component="th" scope="row">
                                    {row.type}
                                </StyledTableCell>
                                <StyledTableCell align="right">{row.total}</StyledTableCell>
                                <StyledTableCell align="right">{row.count_prospek}</StyledTableCell>
                                <StyledTableCell align="center">{`${row.visitor_prospect}%`}</StyledTableCell>
                                <StyledTableCell align="right">{row.count_testdrive}</StyledTableCell>
                                <StyledTableCell align="center">{`${row.prospect_testdrive}%`}</StyledTableCell>
                                <StyledTableCell align="right">{row.count_spk}</StyledTableCell>
                                <StyledTableCell align="right">{`${row.testdrive_spk}%`}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}


function SPKPieChart({ comp }: Props) {
    const chartComponent = useRef(null);
    useEffect(() => { }, [comp]);

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            backgroundColor: "transparent",
            type: "pie",
            height: 350
        },
        title: {
            text: "",
            align: "center",
        },
        tooltip: {
            // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            // pointFormat: "{point.name}: <b>{point.percentage:.1f}%</b>",
            pointFormat: "<b>{point.y} ({point.percentage:.1f}%)</b>",
        },
        accessibility: {
            point: {
                valueSuffix: "%",
            },
        },
        legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "middle",
            // itemMarginTop: 10,
            // itemMarginBottom: 10
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    // format: "{point.y}: <b>{point.percentage:.1f}%</b>",
                    format: "{point.percentage:.1f} %",
                    distance: -30,
                    align: "center",
                },
                slicedOffset: 0,
                showInLegend: true,
            },
        },
        series: [
            {
                // colorByPoint: true,
                name: "Brands",
                colorByPoint: true,
                states: {
                    hover: {
                        enabled: true,
                    },
                    inactive: {
                        enabled: false,
                    },
                },
                data: comp.data
            },
        ],
    };
    return (
        <>
            <div className="d-flex align-items-center flex-column my-10 border-0">
                {/* begin::Title */}
                <h2 className="align-items-center flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">SPK Source Composition</span>
                </h2>
            </div>
            <HighchartsReact
                ref={chartComponent}
                highcharts={Highcharts}
                options={options}
            // series={series}
            />
        </>
    );
}

function SPKbyModel({ comp }: PropsSPKbyModel) {
    useEffect(() => {
    }, [comp]);
    return (
        <>
            <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
                {/* begin::Title */}
                <h3 className="align-items-center flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">{comp.title}</span>
                </h3>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ maxHeight: 100, '&:last-child td, &:last-child th': { border: 0 } }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Activity</TableCell>
                            {comp.data?.model.map((row: any, i: number) => (
                                <TableCell key={i} align="center">{row}</TableCell>
                            ))}
                            {/* <TableCell align="right">Almaz</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {comp.data?.tempData.map((row: {
                            sumber: string;
                            AirEV: number;
                            Almaz: number;
                            Alvez: number;
                            AMBULANCES: number;
                            Binguo: number;
                            Confero: number;
                            Cortez: number;
                            Formo: number;
                            max: number
                        }) => {
                            return <StyledTableRow key={row.sumber}>
                                <StyledTableCell component="th" scope="row">
                                    {row.sumber}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Fragment>
                                        <div className="d-flex fw-semibold w-100 h-100 align-items-start">
                                            <div className="w-35px h-10px fw-bolder fs-1x text-gray-700 text-md-end text-gray-800 me-1">{row.AirEV}</div>
                                            <div
                                                className='h-15px'
                                                role='progressbar'
                                                style={{
                                                    width: `${Math.round(row.AirEV / row.max * 100)}%`,
                                                    backgroundColor: "rgb(0,151,167)"
                                                }}
                                                // aria-valuenow={100}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            ></div>
                                        </div>
                                    </Fragment>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Fragment>
                                        <div className="d-flex fw-semibold w-100 h-100 align-items-start">
                                            <div className="w-35px h-10px fw-bolder fs-1x text-gray-700 text-md-end text-gray-800 me-1">{row.Almaz}</div>
                                            <div
                                                className='h-15px'
                                                role='progressbar'
                                                style={{
                                                    width: `${Math.round(row.Almaz / row.max * 100)}%`,
                                                    backgroundColor: "rgb(0,151,167)"
                                                }}
                                                // aria-valuenow={100}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            ></div>
                                        </div>
                                    </Fragment>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Fragment>
                                        <div className="d-flex fw-semibold w-100 h-100 align-items-start">
                                            <div className="w-35px h-10px fw-bolder fs-1x text-gray-700 text-md-end text-gray-800 me-1">{row.Alvez}</div>
                                            <div
                                                className='h-15px'
                                                role='progressbar'
                                                style={{
                                                    width: `${Math.round(row.Alvez / row.max * 100)}%`,
                                                    backgroundColor: "rgb(0,151,167)"
                                                }}
                                                // aria-valuenow={100}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            ></div>
                                        </div>
                                    </Fragment>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Fragment>
                                        <div className="d-flex fw-semibold w-100 h-100 align-items-start">
                                            <div className="w-35px h-10px fw-bolder fs-1x text-gray-700 text-md-end text-gray-800 me-1">{row.AMBULANCES}</div>
                                            <div
                                                className='h-15px'
                                                role='progressbar'
                                                style={{
                                                    width: `${Math.round(row.AMBULANCES / row.max * 100)}%`,
                                                    backgroundColor: "rgb(0,151,167)"
                                                }}
                                                // aria-valuenow={100}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            ></div>
                                        </div>
                                    </Fragment>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Fragment>
                                        <div className="d-flex fw-semibold w-100 h-100 align-items-start">
                                            <div className="w-35px h-10px fw-bolder fs-1x text-gray-700 text-md-end text-gray-800 me-1">{row.Binguo}</div>
                                            <div
                                                className='h-15px'
                                                role='progressbar'
                                                style={{
                                                    width: `${Math.round(row.Binguo / row.max * 100)}%`,
                                                    backgroundColor: "rgb(0,151,167)"
                                                }}
                                                // aria-valuenow={100}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            ></div>
                                        </div>
                                    </Fragment>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Fragment>
                                        <div className="d-flex fw-semibold w-100 h-100 align-items-start">
                                            <div className="w-35px h-10px fw-bolder fs-1x text-gray-700 text-md-end text-gray-800 me-1">{row.Confero}</div>
                                            <div
                                                className='h-15px'
                                                role='progressbar'
                                                style={{
                                                    width: `${Math.round(row.Confero / row.max * 100)}%`,
                                                    backgroundColor: "rgb(0,151,167)"
                                                }}
                                                // aria-valuenow={100}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            ></div>
                                        </div>
                                    </Fragment>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Fragment>
                                        <div className="d-flex fw-semibold w-100 h-100 align-items-start">
                                            <div className="w-35px h-10px fw-bolder fs-1x text-gray-700 text-md-end text-gray-800 me-1">{row.Cortez}</div>
                                            <div
                                                className='h-15px'
                                                role='progressbar'
                                                style={{
                                                    width: `${Math.round(row.Cortez / row.max * 100)}%`,
                                                    backgroundColor: "rgb(0,151,167)"
                                                }}
                                                // aria-valuenow={100}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            ></div>
                                        </div>
                                    </Fragment>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    <Fragment>
                                        <div className="d-flex fw-semibold w-100 h-100 align-items-start">
                                            <div className="w-35px h-10px fw-bolder fs-1x text-gray-700 text-md-end text-gray-800 me-1">{row.Formo}</div>
                                            <div
                                                className='h-15px'
                                                role='progressbar'
                                                style={{
                                                    width: `${Math.round(row.Formo / row.max * 100)}%`,
                                                    backgroundColor: "rgb(0,151,167)"
                                                }}
                                                // aria-valuenow={100}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            ></div>
                                        </div>
                                    </Fragment>
                                </StyledTableCell>
                            </StyledTableRow>
                        })}
                    </TableBody>
                </Table >
            </TableContainer >
        </>
    );
}

export { ExecutionTable, MoMChart, ConvertionTable, SPKPieChart, SPKbyModel };
