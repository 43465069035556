import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_SIDEBAR_URL = `${API_URL}/sidebar`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const RESET_PASSWORD_URL = `${API_URL}/auth/resetPassword`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
  })
}

// Server should return AuthModel
export function register(
  username: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    username,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}
export function resetPassword(
  id: any,
  token: any,
  password: string,
  password_confirmation: string
) {
  return axios.post(RESET_PASSWORD_URL, {
    id,
    token,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string, id: any) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
    id: id,
  })
}
export function getUserBySideBar(id_roles: number, menu_inner: string, alias_brand: string) {
  return axios.post(GET_USER_BY_SIDEBAR_URL, {
    id_roles: id_roles,
    menu_inner: menu_inner,
    alias_brand: alias_brand,
  })
}
