import React, { useEffect } from "react";

function UpDownHelper({ val }) {
  useEffect(() => {}, [val]);
  return (
    <>
      {parseInt(val) > 0 ? (
        <span className="text-success">
          <span style={{ width: "4rem", marginRight: "0.5rem" }}>
            <i
              className="fa-solid fa-angles-up fa-beat-fade"
              style={{ color: "green" }}
            ></i>
          </span>
          {parseFloat(val).toFixed(1) || "-"} %
        </span>
      ) : (
        <span className="text-danger">
          <span style={{ maxWidth: "4rem", marginRight: "0.5rem" }}>
            <i
              className="fa-solid fa-angles-down fa-beat-fade"
              style={{ color: "red" }}
            ></i>
          </span>
          {parseFloat(val).toFixed(1) || "-"} %
        </span>
      )}
    </>
  );
}

export default UpDownHelper;
