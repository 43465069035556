import { Box } from "@mui/material";

export default class BrandModel {
  constructor(
    data = {
      id: "",
      id_brand: "",
      nama: "",
      logos: "",
      background: "",
      status: 0,
    }
  ) {
    const { id, id_brand, nama, logos, background, status } = data;
    this["id"] = id;
    this["id_brand"] = id_brand;
    this["nama"] = nama;
    this["logos"] = logos;
    this["background"] = background;
    this["status"] = status;
  }

  async getdata(this: any) {
    const { nama, logos, background } = this;
    return [{
      header: "Nama Brand",
      id: "nama_brand",
      value: nama,
      size: 60,
    }, {
      header: "Logo",
      id: "logos",
      value: logos,
      Cell: ({ cell }) => {
        if (cell.getValue()) {
          return <Box
            sx={{
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <img
              alt="logos"
              height={40}
              src={cell.getValue()}
              loading="lazy"
              style={{ borderRadius: '50%' }}
            />
          </Box>
        } else {
          return null
        }
      },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      size: 120,
    }, {
      header: "Dashboard background",
      id: "background",
      value: background,
      Cell: ({ cell }) => {
        if (cell.getValue()) {
          return <Box
            sx={{
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <img
              alt="logos"
              height={40}
              src={cell.getValue()}
              loading="lazy"
              style={{ borderRadius: '50%' }}
            />
          </Box>
        } else {
          return null
        }
      },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      size: 120,
    }];
  }
}
