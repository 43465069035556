/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import { getAuth } from "../pages/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertToast from "../../_metronic/helpers/components/alert/alert";

const API_URL = process.env.REACT_APP_API_URL;

export async function fetchingGetData({ method = "get", url, data = {} }) {
  const auth = getAuth();

  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth?.api_token,
    },
    method: method,
    data: JSON.stringify(data),
  };

  if (method === "post" || method === "delete" || method === "put") {
    options = {
      ...options,
    };
  }
  const response = await axios(`${API_URL}/${url}`, options);
  return response['data'];
}

export async function simpleFetching({ method = "get", url, data = {} }) {
  const auth = getAuth();
  toast.loading("Please wait...", {
    position: toast.POSITION.TOP_RIGHT,
    progressClassName: "success-progress-bar",
    toastId: 2,
  });
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth?.api_token,
    },
    method: method,
    data: JSON.stringify(data),
  };

  if (method === "post" || method === "delete" || method === "put") {
    options = {
      ...options,
    };
  }
  setTimeout(async () => {
    await axios(`${API_URL}/${url}`, options)
      .then((response) => {
        toast.update(2, {
          render: response["data"]["message"],
          type: response["data"]["status"] ? "success" : "error",
          hideProgressBar: false,
          autoClose: 1000,
          isLoading: false,
        });
        if (response["data"]["reload"]) {
          setTimeout(() => {
            window["location"].reload();
          }, 1000);
        }
      }).catch((error) => {
        toast.update(2, {
          render: error,
          type: "error",
          autoClose: 5000,
          isLoading: false,
        });
      });
  }, 1000);
}

export async function multiPartFetching({ method = "get", url, data = {} }) {
  const auth = getAuth();
  let options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + auth?.api_token,
    },
    method: method,
    data: data,
  };

  if (method === "post" || method === "delete" || method === "put") {
    options = {
      ...options,
    };
  }
  toast.loading("Please wait...", {
    position: toast.POSITION.TOP_RIGHT,
    progressClassName: "success-progress-bar",
    toastId: 2,
  });
  setTimeout(async () => {
    await axios(`${API_URL}/${url}`, options)
      .then((response) => {
        toast.update(2, {
          render: response["data"]["message"],
          type: response["data"]["status"] ? "success" : "error",
          hideProgressBar: false,
          autoClose: 1000,
          isLoading: false,
        });
        if (response["data"]["reload"]) {
          setTimeout(() => {
            window["location"].reload();
          }, 1000);
        }
      })
      .catch((error) => {
        toast.update(2, {
          render: error,
          type: "error",
          autoClose: 5000,
          isLoading: false,
        });
      });
  }, 1000);
}

export async function multiPartFilesFetching({ method = "get", url, data = {}, onUploadProgress }) {
  const auth = getAuth();
  let options = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + auth?.api_token,
    },
    onUploadProgress,
    method: method,
    data: data,
  };

  if (method === "post" || method === "delete" || method === "put") {
    options = {
      ...options,
    };
  }

  toast.loading("Please wait...", {
    position: toast.POSITION.TOP_RIGHT,
    progressClassName: "success-progress-bar",
    toastId: 2,
  });
  setTimeout(async () => {
    await axios(`${API_URL}/${url}`, options)
      .then((response) => {
        toast.update(2, {
          render: response["data"]["message"],
          type: response["data"]["status"] ? "success" : "error",
          hideProgressBar: false,
          autoClose: 1000,
          isLoading: false,
        });
        if (response["data"]["reload"]) {
          setTimeout(() => {
            window["location"].reload();
          }, 1000);
        }
      }).catch((error) => {
        const { code } = error?.response?.data;
        switch (code) {
          case "FILE_MISSING":
            AlertToast("Please select a file before uploading!", "error");
            break;
          case "LIMIT_FILE_SIZE":
            AlertToast("File size is too large. Please upload files below 1MB!", "error");
            break;

          default:
            AlertToast("Sorry! Something went wrong. Please try again later", "error");
            break;
        }
      });
  }, 1000);

  // const response = await axios(`${API_URL}/${url}`, options);
  // return response['data'];
}

export const getIdUser = () => {
  const auth = getAuth();
  return auth?.id;
};