import React from "react";
import { numberWithPoint } from "../../helpers-data/AssetHelpers";

//** Start Huruf Besar */
export const progressBarLine = (value: number, sumValue: number, backgroundColor: string) => {
  let valueInPercent = 0;
  if (value) {
    valueInPercent = (value / sumValue) * 100;
  }
  return (
    <React.Fragment>
      <div className="d-flex fw-semibold align-items-start w-100 h-100">
        <div className="h-10px w-auto fw-bolder text-align-start flex-shrink-0 text-gray-800 me-2">{`${value ? numberWithPoint(value) : value}`}</div>
        <div
          className='h-15px'
          role='progressbar'
          style={{
            width: `${valueInPercent}%`,
            backgroundColor: backgroundColor
          }}
          aria-valuenow={valueInPercent}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>
    </React.Fragment>
  );
}

export const badgeUpDownPercent = (value: string, fontSize: string) => {
  let val = parseInt(value);
  let values: any;
  let textColor = "";
  let icon = "";
  if (!isNaN(val) && val !== 0) {
    if (val >= 0) {
      textColor = "success";
      icon = "arrow-up";
    } else {
      textColor = "danger";
      icon = "arrow-down";
    }
    values = `${value}%`;
  } else {
    values = "null";
  }
  return (
    <>
      {values !== "null" ? (
        <span className={`badge badge-light-${textColor} ${fontSize}`}>
          <i className={`ki-duotone ki-${icon} fs-5 text-${textColor} ${fontSize} ms-n1`}>
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
          {`${values}`}
        </span>
      ) : (
        <span className={`badge ${fontSize}`}>
          {`${values}`}
        </span>
      )}
    </>
  );
}

export const spanNumberWithPoint = (value: string, percent: boolean) => {
  let val = parseInt(value);
  let values: any;
  if (!isNaN(val) && val !== 0) {
    let valuex = value;
    if (percent) {
      values = `${valuex}%`;
    } else {
      let valuex = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      values = valuex
    }
  } else {
    values = "null";
  }
  return (
    <>
      <span className={`text-dark fw-bold fs-1x`}>
        {`${values}`}
      </span>
    </>
  );
}

export const spanNumberWithPointTotal = (value: string | number) => {
  return (
    <span className={`text-dark fw-bold`}>
      {`${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`}
    </span>
  );
}

export const spanString = (value: string | number, className: string) => {
  return (
    <span className={`fw-bold ${className}`}>
        {`${value}`}
      </span>
  );
}

export const backgroundColorCell = (value: string, array: any, percent: boolean, backgroundColorRGB: string) => {
  let val = parseInt(value);
  let values: any;
  if (!isNaN(val)) {
    let valuex = value;
    if (percent) {
      if (val === 0) {
        values = `+${valuex}%`;
      } else {
        values = `${valuex}%`;
      }
    } else {
      let valuex = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      values = valuex
    }
  } else {
    values = "null";
  }
  let valueBackgroundColor = "";
  if (val >= 0 && val <= 10) {
    valueBackgroundColor = `rgb(${backgroundColorRGB}, 0.1)`;
  } else if (val > 10 && val <= 20) {
    valueBackgroundColor = `rgb(${backgroundColorRGB}, 0.2)`;
  } else if (val > 20 && val <= 30) {
    valueBackgroundColor = `rgb(${backgroundColorRGB}, 0.3)`;
  } else if (val > 30 && val <= 40) {
    valueBackgroundColor = `rgb(${backgroundColorRGB}, 0.4)`;
  } else if (val > 40 && val <= 50) {
    valueBackgroundColor = `rgb(${backgroundColorRGB}, 0.5)`;
  } else if (val > 60 && val <= 70) {
    valueBackgroundColor = `rgb(${backgroundColorRGB}, 0.6)`;
  } else if (val > 70 && val <= 80) {
    valueBackgroundColor = `rgb(${backgroundColorRGB}, 0.7)`;
  } else if (val > 80 && val <= 90) {
    valueBackgroundColor = `rgb(${backgroundColorRGB}, 0.8)`;
  } else if (val > 90 && val <= 100) {
    valueBackgroundColor = `rgb(${backgroundColorRGB}, 0.9)`;
  } else {
    valueBackgroundColor = "";
  }

  return <span className="badge py-3 px-8 fs-7" style={{ backgroundColor: `${valueBackgroundColor}` }}>{values}</span>;
}

export const TextColor = (value: number) => {
  let values: number | string;

  if (value !== null) {
    if (value === 0) {
      values = `+${value}%`;
    } else {
      values = `${value}%`;
    }
    return (<div className={`text-center fw-bolder fs-1x`}>{`${values}`}</div>)
  } else {
    return 'null';
  }
}

export const Text2Color = (value: any, number: number, colors: Array<string>) => {
  if (value === null) {
    return 'null';
  }
  if (!isNaN(value) && value !== 0) {
    let color: string;
    if (value >= number) {
      color = colors[0];
    } else {
      color = colors[1];
    }
    return (
      <div className={`text-center fw-bolder fs-1x ${color}`}>
        {`${value}%`}
      </div>
    );
  } else {
    return 'null';
  }
}

export const background2Color = (value: any, number: number, colors: Array<string>) => {
  if (value === null) {
    return '';
  }
  if (!isNaN(value) && value !== 0) {
    if (value >= number) {
      return colors[0];
    } else {
      return colors[1];
    }
  } else {
    return '';
  }
}

export const background2Color1 = (value: any, number: number, colors: Array<string>) => {
  let numVal = number > 100 ? 300 : number;
  let r, g, b = 54;
  r = numVal < 50 ? 255 : Math.floor(244 - ((numVal * 2 - 100) * 255) / 100);
  g = numVal > 50 ? 255 : Math.floor((numVal * 2 * 255) / 100);
  return `rgba(${r + "," + g + "," + b + "," + 0.5})`;
}

export const backgroundColorHSLDarkToLight = (hsl: Array<string>, value: number, maxValue: number) => {
  let h = hsl[0];
  let s = hsl[1];
  let l = hsl[2];
  var hue = Math.round(((value / maxValue) * 100));

  if (value === null) {
    return [`hsl(${h}, ${s}, 100%)`].join("");
  }
  if (hue >= 0 && hue <= 20) {
    return [`hsl(${h}, ${s}, ${l}, 0.145)`].join("");
  } else if (hue >= 21 && hue <= 40) {
    return [`hsl(${h}, ${s}, ${l}, 0.286)`].join("");
  } else if (hue >= 41 && hue <= 60) {
    return [`hsl(${h}, ${s}, ${l}, 0.42)`].join("");
  } else if (hue >= 61 && hue <= 80) {
    return [`hsl(${h}, ${s}, ${l}, 0.63)`].join("");
  } else if (hue >= 81 && hue <= 100) {
    return [`hsl(${h}, ${s}, ${l}, 1)`].join("");
  }
}


