import React from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import { HighchartsSample } from '../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample';
import { MaterialReactTableDetails } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';
import { Form, Formik } from 'formik';
import DataTableMuiModel from '../../../../models/DataTable/dataTableModal';
import { TargetConversionAPI } from '../../../../api/TargetConversionAPI';
import { LeadsToSpkModel, ModelSalesTargetAchievementModel } from '../../../../models/sales/TargetConversionModel';
import { numberWithPoint } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, Skeleton, TableFooter } from "@mui/material";
import moment from 'moment';
import { MasterDataSalesAPI } from '../../../../api/MasterDataSalesAPI';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#384967',
    color: theme.palette.common.white,
  }, [`&.${tableCellClasses.body}`]: {
    fontSize: 9,
  },
}));

const StyledTableCellTransaparant = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#52617c',
    color: theme.palette.common.white,
  }, [`&.${tableCellClasses.body}`]: {
    fontSize: 9,
  },
}));

const StyledTableCellWhite = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#d9d9d9',
    color: theme.palette.common.black,
  }, [`&.${tableCellClasses.body}`]: {
    fontSize: 9,
  },
}));

const StyledTableCellYellow = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fcc47c',
    color: theme.palette.common.black,
  }, [`&.${tableCellClasses.body}`]: {
    fontSize: 9,
  },
}));
const StyledTableCellGreen = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#43a047',
    color: theme.palette.common.white,
  }, [`&.${tableCellClasses.body}`]: {
    fontSize: 9,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function sumDataSalesAvg(arr: number[], column_name: string) {
  return arr.reduce((accumulator, currentValue) => {
    return accumulator + currentValue[column_name];
  }, 0);
}
function sumDataSPVAvg(arr: object[], column_name: string) {
  let total = 0;
  arr.map((spv: any, i: number) => {
    total += spv['children'].reduce((accumulator: number, currentValue: object) => {
      return accumulator + currentValue[column_name];
    }, 0);
    return total;
  });
  return total.toLocaleString("us-US");
}
function sumAllDataAvg(arr: object[], column_name: string) {
  let total = 0;
  arr.map((spv: any, i: number) => {
    return spv['children'].map((sa: any, i: number) => {
      total += sa['children'].reduce((accumulator: number, currentValue: object) => {
        return accumulator + currentValue[column_name];
      }, 0);
      return total;
    });
  });
  return total.toLocaleString("us-US");
}


type MyState = {
  title: string,
  isLoading: boolean,
  loading: boolean,
  isLoadingLeadsToSpk: boolean,
  isLoadingSpkToDo: boolean,
  loadingTableGroup: boolean,
  coverage: Array<[]>,
  initial_values: Object,
  chart_target_v_actual_spk: Object,
  chart_target_v_actual_do: Object,
  chart_target_v_actual_rs: Object,
  datatable_models: Object;
  dataArraySpkToDo: Object[];
  data_grouping_by_manpower: Object;
  options_asm: Array<[]>,
  options_outlet: Array<[]>,
  options_month: Array<[]>,
  options_range: Array<[]>,
}

export default class SalesTargetVAchievementIndex extends React.Component {
  constructor(props: any) {
    super(props);
    this["api"] = new TargetConversionAPI();
    this["month"] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    this['column_do'] = [];
    this['data_do'] = [];
    this['column_spk'] = [];
    this['data_spk'] = [];
    this['column_testdrive'] = [];
    this['data_testdrive'] = [];
    this['column_leads'] = [];
    this['data_leads'] = [];
    this['option_asm'] = [];
    this['option_sm'] = [];
    this['option_outlet'] = [];
    this['option_model'] = [];
    this['column_leads_to_spk'] = [];
    this['data_leads_to_spk'] = [];
  }
  state: MyState = {
    title: "Sales Target ⓥ Achievement",
    isLoading: true,
    loading: true,
    isLoadingLeadsToSpk: true,
    isLoadingSpkToDo: true,
    loadingTableGroup: true,
    coverage: [],
    initial_values: {
      asm: [],
      sm: [],
      outlet: [],
      model: [],
    }, chart_target_v_actual_spk: {
      series: [],
      categories: this["month"],
      data: []
    }, chart_target_v_actual_do: {
      series: [],
      categories: this["month"],
      data: []

    }, chart_target_v_actual_rs: {
      series: [],
      categories: this["month"],
      data: []
    },
    dataArraySpkToDo: [],
    datatable_models: new DataTableMuiModel({ readOnly: true }),
    data_grouping_by_manpower: [],
    options_asm: [],
    options_outlet: [],
    options_month: [],
    options_range: [],
  }

  //* ----------------------------------------------------------------------------- */
  // BEGIN:: componentDidMount */
  async componentDidMount(): Promise<void> {
    this["setState"]({
      loading: true
    });
    const { initial_values } = this['state'];
    // Filter ASM, SM dan Outlet
    await this['api'].getFilter(initial_values).then(async (res: any) => {
      this['option_asm'] = res['data']['dataAsm'].map((val: any) => {
        return {
          value: val['id_team_asm'],
          label: val['area'],
        };
      });
      this['option_sm'] = res['data']['dataSm'].map((val: any) => {
        return {
          value: val['id_team_sm'],
          label: val['region'],
        };
      });
      this['option_outlet'] = res['data']['dataOutlet'].map((val: any) => {
        return {
          value: val['id_outlet'],
          label: val['text'],
        };
      });
      this.setState({
        isLoading: false,
        coverage: res['data']['coverage'],
      });
    });

    //? Load Filter Options Model
    await this['api'].getModel().then(async (res: any) => {
      this['option_model'] = res['data'].map((val: any) => {
        return {
          value: val['id'],
          label: val['text'],
        };
      });
    });
    await this['FilterLoadTable'](initial_values);
    await this['FilterLoadChart1'](initial_values);
    await this['FilterLoadLeadsToSpk'](initial_values);
    await this['FilterLoadSpkToDo'](initial_values);
    await this['FilterLoadTableGroup'](initial_values);
  }
  // END:: componentDidMount */
  //* ----------------------------------------------------------------------------- */
  async FilterLoadChart1(params?: Object) {
    const { data: data_spk } = await this["api"].getChartTargetVActualSPK(params);
    const { data: data_do } = await this["api"].getChartTargetVActualDO(params);
    const { data: data_rs } = await this["api"].getChartTargetVActualRS(params);
    this["setState"]({
      chart_target_v_actual_spk: {
        series: [{
          name: `Target`,
          type: "column",
          yAxis: 1,
          data: data_spk?.['target'],
        }, {
          name: `Actual`,
          type: "column",
          yAxis: 1,
          data: data_spk?.['aktual'],
        }, {
          name: "Growth",
          type: "line",
          data: data_spk?.['growth'],
          tooltip: {
            valueSuffix: " %",
          },
        }], categories: this["month"],
        data: [
          {
            name: `SPK Ach YTD`,
            value: Math.round(data_spk?.['sumGrowth']),
          },
          {
            name: `Target`,
            value: numberWithPoint(data_spk?.['sumTarget']),
          },
          {
            name: `Actual`,
            value: numberWithPoint(data_spk?.['sumAktual']),
          },
        ]
      },
      chart_target_v_actual_do: {
        series: [{
          name: `Target`,
          type: "column",
          yAxis: 1,
          data: data_do?.['target'],
        }, {
          name: `Actual`,
          type: "column",
          yAxis: 1,
          data: data_do?.['aktual'],
        }, {
          name: "Growth",
          type: "line",
          data: data_do?.['growth'],
          tooltip: {
            valueSuffix: " %",
          },
        }],
        categories: this["month"],
        data: [
          {
            name: `DO Ach YTD`,
            value: Math.round(data_do?.['sumGrowth']),
          },
          {
            name: `Target`,
            value: numberWithPoint(data_do?.['sumTarget']),
          },
          {
            name: `Actual`,
            value: numberWithPoint(data_do?.['sumAktual']),
          },
        ]
      },
      chart_target_v_actual_rs: {
        series: [{
          name: `Target`,
          type: "column",
          yAxis: 1,
          data: data_rs?.['target'],
        }, {
          name: `Actual`,
          type: "column",
          yAxis: 1,
          data: data_rs?.['aktual'],
        }, {
          name: "Growth",
          type: "line",
          data: data_rs?.['growth'],
          tooltip: {
            valueSuffix: " %",
          },
        }], categories: this["month"],
        data: [
          {
            name: `DO Ach YTD`,
            value: Math.round(data_rs?.['sumGrowth']),
          },
          {
            name: `Target`,
            value: numberWithPoint(data_rs?.['sumTarget']),
          },
          {
            name: `Actual`,
            value: numberWithPoint(data_rs?.['sumAktual']),
          },
        ]
      }
    });
  }
  //* ----------------------------------------------------------------------------- */
  async FilterLoadLeadsToSpk(params?: Object) {
    this["setState"]({ isLoadingLeadsToSpk: true });
    const data = await new MasterDataSalesAPI().getDataTargetLeadToSpk();
    // Table Lead To Spk
    this["DataTableMuiModel"] = new DataTableMuiModel({ readOnly: true, NumRows: true });
    data["data"].map((item: any, i: number) => {
      let dataModel = new LeadsToSpkModel(item);
      this["DataTableMuiModel"].add({
        id: i,
        no: i + 1,
        dataModel: dataModel,
        actions: null,
      });
      return item;
    });
    this['column_leads_to_spk'] = await this["DataTableMuiModel"].getColumn();
    this['data_leads_to_spk'] = await this["DataTableMuiModel"].getDatas();
    this["setState"]({
      isLoadingLeadsToSpk: false
    });
  }
  //* ----------------------------------------------------------------------------- */
  async FilterLoadSpkToDo(params?: Object) {
    this["setState"]({ isLoadingSpkToDo: true });
    const { data: spk_to_do } = await new MasterDataSalesAPI().getDataTargetSpkToDo();
    let dataSpkToDo: any = [];
    spk_to_do.map((item: any) => {
      dataSpkToDo.push({
        outlet: item['outlet'],
        salesman: item['salesman'],
        salesspv: item['salesspv'],
        do_target_name: item['do_target_name'],
        contact_number: item['contact_number'],
        model: item['model'],
        do_date_plan: item['do_date_plan'],
        cash: item['cash'],
        credit: item['credit'],
        leasing: item['leasing'],
        submit_order: item['submit_order'],
        survey: item['survey'],
        doc_lengkap: item['doc_lengkap'],
        ca_process: item['ca_process'],
        decision: item['decision'],
        decision_date: item['decision_date'],
        appeal: item['appeal'],
        appeal_date: item['appeal_date'],
        final_result: item['final_result'],
        do: item['do'],
        carry_over: item['carry_over'],
      });
      return item;
    });
    this["setState"]({
      dataArraySpkToDo: dataSpkToDo,
      isLoadingSpkToDo: false
    });
  }
  //* ----------------------------------------------------------------------------- */
  async FilterLoadTable(params?: Object) {
    let month = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    const { data: datas } = await this["api"].getDataTableTargetConversion(params);
    // Table DO Looping
    this["tableDO"] = new DataTableMuiModel({ readOnly: true, NumRows: true });
    Object.entries(datas).map((val: object, i: number) => {
      let model = val[0];
      let sumTotal = val[1]['do'].reduce((accumulator: number, currentValue: number) => {
        return accumulator + currentValue;
      }, 0);
      let datas: any = [];
      for (let i = 0; i < 12; i++) {
        datas['model'] = model;
        datas[month[i]] = val[1]['do'][i]
        datas['total'] = sumTotal;
      }
      let data = new ModelSalesTargetAchievementModel(datas);
      this["tableDO"].add({
        id: i,
        no: i + 1,
        dataModel: data,
        actions: null
      })
      return val;
    });
    this['column_do'] = await this["tableDO"].getColumn();
    this['data_do'] = await this["tableDO"].getDatas();
    // Table SPK Looping
    this["tableSPK"] = new DataTableMuiModel({ readOnly: true, NumRows: true });
    Object.entries(datas).map((val: object, i: number) => {
      let model = val[0];
      let sumTotal = val[1]['spk'].reduce((accumulator: number, currentValue: number) => {
        return accumulator + currentValue;
      }, 0);
      let datas: any = [];
      for (let i = 0; i < 12; i++) {
        datas['model'] = model;
        datas[month[i]] = val[1]['spk'][i]
        datas['total'] = sumTotal;
      }
      let data = new ModelSalesTargetAchievementModel(datas);
      this["tableSPK"].add({
        id: i,
        no: i + 1,
        dataModel: data,
        actions: null
      })
      return val;
    });
    this['column_spk'] = await this["tableSPK"].getColumn();
    this['data_spk'] = await this["tableSPK"].getDatas();
    // Table TestDrive Looping
    this["TestDrive"] = new DataTableMuiModel({ readOnly: true, NumRows: true });
    Object.entries(datas).map((val: object, i: number) => {
      let model = val[0];
      let sumTotal = val[1]['testdrive'].reduce((accumulator: number, currentValue: number) => {
        return accumulator + currentValue;
      }, 0);
      let datas: any = [];
      for (let i = 0; i < 12; i++) {
        datas['model'] = model;
        datas[month[i]] = val[1]['testdrive'][i]
        datas['total'] = sumTotal;
      }
      let data = new ModelSalesTargetAchievementModel(datas);
      this["TestDrive"].add({
        id: i,
        no: i + 1,
        dataModel: data,
        actions: null
      })
      return val;
    });
    this['column_testdrive'] = await this["TestDrive"].getColumn();
    this['data_testdrive'] = await this["TestDrive"].getDatas();
    // Table TestDrive Looping
    this["Leads"] = new DataTableMuiModel({ readOnly: true, NumRows: true });
    Object.entries(datas).map((val: object, i: number) => {
      let model = val[0];
      let sumTotal = val[1]['leads'].reduce((accumulator: number, currentValue: number) => {
        return accumulator + currentValue;
      }, 0);
      let datas: any = [];
      for (let i = 0; i < 12; i++) {
        datas['model'] = model;
        datas[month[i]] = val[1]['leads'][i]
        datas['total'] = sumTotal;
      }
      let data = new ModelSalesTargetAchievementModel(datas);
      this["Leads"].add({
        id: i,
        no: i + 1,
        dataModel: data,
        actions: null
      })
      return val;
    });
    this['column_leads'] = await this["Leads"].getColumn();
    this['data_leads'] = await this["Leads"].getDatas();
    this.setState({ loading: false });
  }
  async FilterLoadTableGroup(params?: Object) {
    this.setState({ loadingTableGroup: true });
    // API : Target Spesific by Manpower
    const { data: table_target_spesific_by_manpower } = await this["api"].getTableTargetSpesificByManpower({
      ...params
    });
    this.setState({ data_grouping_by_manpower: table_target_spesific_by_manpower, loadingTableGroup: false, loading: false })
  }
  //* ----------------------------------------------------------------------------- */
  async loadOptionsSm(array: object) {
    this.setState({ isLoading: true });
    await this['api'].getFilter(array).then(async (res: any) => {
      this['option_sm'] = res['data']['dataSm'].map((val: any) => {
        return {
          value: val['id_team_sm'],
          label: val['region'],
        };
      });
      this['option_outlet'] = res['data']['dataOutlet'].map((val: any) => {
        return {
          value: val['id_outlet'],
          label: val['text'],
        };
      });
      this.setState({
        isLoading: false,
        coverage: res['data']['coverage'],
      });
    });
  }
  async loadOptionsOutlet(array: object) {
    this.setState({ isLoading: true });
    await this['api'].getFilter(array).then(async (res: any) => {
      this['option_outlet'] = res['data']['dataOutlet'].map((val: any) => {
        return {
          value: val['id_outlet'],
          label: val['text'],
        };
      });
      this.setState({
        isLoading: false,
        coverage: res['data']['coverage'],
      });
    });
  }

  render(this: object) {
    const { title, loading, loadingTableGroup, isLoading, isLoadingLeadsToSpk, isLoadingSpkToDo, initial_values, chart_target_v_actual_spk, chart_target_v_actual_do, chart_target_v_actual_rs, data_grouping_by_manpower } = this['state'];
    return (
      <React.Fragment>
        <PageTitle breadcrumbs={[{ title: "Target & Conversion", path: "/sales/sales-target-vs-achievement", isSeparator: false, isActive: false }]}>
          {title}
        </PageTitle>
        {/* Filter Input */}
        <div className="row g-6 g-xl-9">
          <Formik enableReinitialize={true} initialValues={initial_values} onSubmit={() => { }}>
            {({ setFieldValue, values }) => (
              <Form>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-3 mb-5">
                    <FilterMultiSelect
                      label={"ASM"}
                      name="asm"
                      className='z-index-3'
                      options={this['option_asm']}
                      onChange={async (selected: Option[]) => {
                        setFieldValue("asm", selected);
                        const asm = selected.map(
                          (x: object) => x["value"]
                        );
                        let newArrays = {
                          asm: asm,
                          sm: [],
                          outlet: [],
                        }
                        this['loadOptionsSm'](newArrays);
                        const model = values['model'].map(
                          (x: object) => x["value"]
                        );
                        let filterArray = {
                          model: model,
                        }
                        setTimeout(async () => {
                          await this['FilterLoadTable'](filterArray);
                        }, 500);
                      }}
                      value={values["asm"]}
                    />
                  </div>
                  <div className="col-md-3 mb-5">
                    <FilterMultiSelect
                      label={"SM"}
                      name="sm"
                      className='z-index-3'
                      options={this['option_sm']}
                      isLoading={isLoading}
                      onChange={(selected: Option[]) => {
                        setFieldValue("sm", selected);
                        const asm = values["asm"].map(
                          (x: object) => x["value"]
                        );
                        const sm = selected.map(
                          (x: object) => x["value"]
                        );
                        let newArrays = {
                          asm: asm,
                          sm: sm,
                          outlet: [],
                        }
                        this['loadOptionsOutlet'](newArrays);
                        const model = values['model'].map(
                          (x: object) => x["value"]
                        );
                        let filterArray = {
                          model: model,
                        }
                        setTimeout(async () => {
                          await this['FilterLoadTable'](filterArray);
                        }, 500);
                      }}
                      value={values["sm"]}
                    />
                  </div>
                  <div className="col-md-3 mb-5">
                    <FilterMultiSelect
                      label={"Outlet"}
                      name="outlet"
                      className='z-index-3'
                      options={this['option_outlet']}
                      isLoading={isLoading}
                      onChange={(selected: Option[]) => {
                        setFieldValue("outlet", selected);
                        const asm = values["asm"].map(
                          (x: object) => x["value"]
                        );
                        const sm = values["sm"].map(
                          (x: object) => x["value"]
                        );
                        const outlet = selected.map(
                          (x: object) => x["value"]
                        );
                        let newArrays = {
                          asm: asm,
                          sm: sm,
                          outlet: outlet,
                        }
                        this['loadOptionsOutlet'](newArrays);
                        const model = values['model'].map(
                          (x: object) => x["value"]
                        );
                        let filterArray = {
                          model: model,
                        }
                        setTimeout(async () => {
                          await this['FilterLoadTable'](filterArray);
                        }, 500);
                      }}
                      value={values["outlet"]}
                    />
                  </div>
                  <div className="col-md-3 mb-5">
                    <FilterMultiSelect
                      label={"Model"}
                      name="model"
                      className='z-index-3'
                      options={this['option_model']}
                      onChange={(selected: Option[]) => {
                        setFieldValue("model", selected);
                        const asm = values["asm"].map(
                          (x: object) => x["value"]
                        );
                        const sm = values["sm"].map(
                          (x: object) => x["value"]
                        );
                        const outlet = values['outlet'].map(
                          (x: object) => x["value"]
                        );
                        let newArrays = {
                          asm: asm,
                          sm: sm,
                          outlet: outlet,
                        }
                        this['loadOptionsOutlet'](newArrays);
                        const model = selected.map(
                          (x: object) => x["value"]
                        );
                        let filterArray = {
                          model: model,
                        }
                        setTimeout(async () => {
                          await this['FilterLoadTable'](filterArray);
                        }, 500);
                      }}
                      value={values["model"]}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* ----------------------------------------------------------------------------- */}
        {/* DO */}
        <div className="row flex-xxl-row">
          <div className='col-xxl-6 h-md-100 bd-highlight'>
            <KTCard stretch='stretch' className='mb-5 rounded-top-4 rounded-bottom-4'>
              <div className="min-h-100 align-self-center rounded-4 my-4 pt-1" style={{ backgroundColor: '#43a047', width: '30%' }}>
                <h3 className="text-center">
                  <span className="text-white fw-bold fs-4">DO</span>
                </h3>
              </div>
              <KTCardBody className="p-0">
                <div className='mb-6'>
                  <MaterialReactTableDetails
                    columns={this['column_do']}
                    data={this['data_do']}
                    isLoading={loading}
                    maxHeight='300px'
                    enableTopToolbar={false}
                    hover={false}
                    optionsHead={{
                      fontSize: 9,
                    }}
                  />
                </div>
              </KTCardBody>
            </KTCard>
          </div>
          {/* SPK */}
          <div className='col-xxl-6 h-md-100 bd-highlight'>
            <KTCard stretch='stretch' className='mb-5 rounded-top-4 rounded-bottom-4'>
              <div className="min-h-100 align-self-center rounded-4 my-4 pt-1" style={{ backgroundColor: '#00897b', width: '30%' }}>
                <h3 className="text-center">
                  <span className="text-white fw-bold fs-4">SPK</span>
                </h3>
              </div>
              <KTCardBody className="p-0">
                <div className='mb-6'>
                  <MaterialReactTableDetails
                    columns={this['column_spk']}
                    data={this['data_spk']}
                    isLoading={loading}
                    maxHeight='300px'
                    enableTopToolbar={false}
                    hover={false}
                    optionsHead={{
                      fontSize: 9,
                    }}
                  />
                </div>
              </KTCardBody>
            </KTCard>
          </div>
        </div>
        {/* ----------------------------------------------------------------------------- */}
        {/* Test Drive */}
        <div className="row flex-xxl-row">
          <div className='col-xxl-6 h-md-100 bd-highlight'>
            <KTCard stretch='stretch' className='mb-5 rounded-top-4 rounded-bottom-4'>
              <div className="min-h-100 align-self-center rounded-4 my-4 pt-1" style={{ backgroundColor: '#fcc47c', width: '30%' }}>
                <h3 className="text-center">
                  <span className="text-black fw-bold fs-4">Test Drive</span>
                </h3>
              </div>
              <KTCardBody className="p-0">
                <div className='mb-6'>
                  <MaterialReactTableDetails
                    columns={this['column_testdrive']}
                    data={this['data_testdrive']}
                    isLoading={loading}
                    maxHeight='300px'
                    enableTopToolbar={false}
                    hover={false}
                    optionsHead={{
                      fontSize: 9,
                    }}
                  />
                </div>
              </KTCardBody>
            </KTCard>
          </div>
          {/* SPK */}
          <div className='col-xxl-6 h-md-100 bd-highlight'>
            <KTCard stretch='stretch' className='mb-5 rounded-top-4 rounded-bottom-4'>
              <div className="min-h-100 align-self-center rounded-4 my-4 pt-1" style={{ backgroundColor: '#b7b7b7', width: '30%' }}>
                <h3 className="text-center">
                  <span className="text-black fw-bold fs-4">Leads</span>
                </h3>
              </div>
              <KTCardBody className="p-0">
                <div className='mb-6'>
                  <MaterialReactTableDetails
                    columns={this['column_leads']}
                    data={this['data_leads']}
                    isLoading={loading}
                    maxHeight='300px'
                    enableTopToolbar={false}
                    hover={false}
                    optionsHead={{
                      fontSize: 9,
                    }}
                  />
                </div>
              </KTCardBody>
            </KTCard>
          </div>
        </div>
        {/* ----------------------------------------------------------------------------- */}
        {/* SPK (Target ⓥ Actual) */}
        <div className="row flex-xxl-row">
          <KTCard stretch='stretch' className='mb-5'>
            <div className="card-header justify-content-center">
              <h3 className="card-title align-items-center flex-column">
                <span className="card-label fw-bold fs-4">SPK</span>
                <span className="card-label text-muted fw-semibold fs-6">Target ⓥ Actual </span>
              </h3>
            </div>
            <KTCardBody className="p-0 py-5">
              <div className='card-rounded bg-body position-relative'>
                <div className='col g-0 mb-0'>
                  <div className='col mx-5'>
                    <div className="fs-5 text-gray-400">{chart_target_v_actual_spk['data'][0]?.['name']}</div>
                    <div className="fs-1 fw-bold text-warning">{chart_target_v_actual_spk['data'][0]?.['value']}%</div>
                  </div>
                  <div className='d-flex flex-wrap mx-5'>
                    <div className='border border-bottom-0 border-top-0 border-left-0 border-right-5 border-gray-500 min-w-60px py-0 px-0 me-0 mb-3'>
                      <div className="fs-7 text-gray-400">{chart_target_v_actual_spk['data'][1]?.['name']}</div>
                      <div className="fs-4 fw-bold text-dark">{chart_target_v_actual_spk['data'][1]?.['value']}</div>
                    </div>
                    <div className='min-w-60px py-0 px-0 ms-5'>
                      <div className="fs-7 text-primary">{chart_target_v_actual_spk['data'][2]?.['name']}</div>
                      <div className="fs-4 fw-bold text-primary">{chart_target_v_actual_spk['data'][2]?.['value']}</div>
                    </div>
                  </div>
                </div>
              </div>
              <HighchartsSample
                id="retail_sales_chart1"
                type="column_line"
                height="350px"
                series={chart_target_v_actual_spk["series"]}
                categories={chart_target_v_actual_spk["categories"]}
              />
            </KTCardBody>
          </KTCard>
        </div>
        {/* ----------------------------------------------------------------------------- */}
        {/* DO (Target ⓥ Actual) */}
        <div className="row flex-xxl-row">
          <KTCard stretch='stretch' className='mb-5'>
            <div className="card-header justify-content-center">
              <h3 className="card-title align-items-center flex-column">
                <span className="card-label fw-bold fs-4">DO</span>
                <span className="card-label text-muted fw-semibold fs-6">Target ⓥ Actual </span>
              </h3>
            </div>
            <KTCardBody className="p-0 py-5">
              <div className='card-rounded bg-body position-relative'>
                <div className='col g-0 mb-0'>
                  <div className='col mx-5'>
                    <div className="fs-5 text-gray-400">{chart_target_v_actual_do['data'][0]?.['name']}</div>
                    <div className="fs-1 fw-bold text-warning">{chart_target_v_actual_do['data'][0]?.['value']}%</div>
                  </div>
                  <div className='d-flex flex-wrap mx-5'>
                    <div className='border border-bottom-0 border-top-0 border-left-0 border-right-5 border-gray-500 min-w-60px py-0 px-0 me-0 mb-3'>
                      <div className="fs-7 text-gray-400">{chart_target_v_actual_do['data'][1]?.['name']}</div>
                      <div className="fs-4 fw-bold text-dark">{chart_target_v_actual_do['data'][1]?.['value']}</div>
                    </div>
                    <div className='min-w-60px py-0 px-0 ms-5'>
                      <div className="fs-7 text-primary">{chart_target_v_actual_do['data'][2]?.['name']}</div>
                      <div className="fs-4 fw-bold text-primary">{chart_target_v_actual_do['data'][2]?.['value']}</div>
                    </div>
                  </div>
                </div>
              </div>
              <HighchartsSample
                id="retail_sales_chart1"
                type="column_line"
                height="350px"
                series={chart_target_v_actual_do["series"]}
                categories={chart_target_v_actual_do["categories"]}
              />
            </KTCardBody>
          </KTCard>
        </div>
        {/* ----------------------------------------------------------------------------- */}
        {/* RS (Target ⓥ Actual) */}
        <div className="row flex-xxl-row">
          <KTCard stretch='stretch' className='mb-5'>
            <div className="card-header justify-content-center">
              <h3 className="card-title align-items-center flex-column">
                <span className="card-label fw-bold fs-4">RS</span>
                <span className="card-label text-muted fw-semibold fs-6">Target ⓥ Actual </span>
              </h3>
            </div>
            <KTCardBody className="p-0 py-5">
              <div className='card-rounded bg-body position-relative'>
                <div className='col g-0 mb-0'>
                  <div className='col mx-5'>
                    <div className="fs-5 text-gray-400">{chart_target_v_actual_rs['data'][0]?.['name']}</div>
                    <div className="fs-1 fw-bold text-warning">{chart_target_v_actual_rs['data'][0]?.['value']}%</div>
                  </div>
                  <div className='d-flex flex-wrap mx-5'>
                    <div className='border border-bottom-0 border-top-0 border-left-0 border-right-5 border-gray-500 min-w-60px py-0 px-0 me-0 mb-3'>
                      <div className="fs-7 text-gray-400">{chart_target_v_actual_rs['data'][1]?.['name']}</div>
                      <div className="fs-4 fw-bold text-dark">{chart_target_v_actual_rs['data'][1]?.['value']}</div>
                    </div>
                    <div className='min-w-60px py-0 px-0 ms-5'>
                      <div className="fs-7 text-primary">{chart_target_v_actual_rs['data'][2]?.['name']}</div>
                      <div className="fs-4 fw-bold text-primary">{chart_target_v_actual_rs['data'][2]?.['value']}</div>
                    </div>
                  </div>
                </div>
              </div>
              <HighchartsSample
                id="retail_sales_chart1"
                type="column_line"
                height="350px"
                series={chart_target_v_actual_rs["series"]}
                categories={chart_target_v_actual_rs["categories"]}
              />
            </KTCardBody>
          </KTCard>
        </div>
        {/* ----------------------------------------------------------------------------- */}
        {/* Target Spesific by Manpower */}
        <div className="row flex-xxl-row">
          <KTCard stretch='stretch' className='mb-5'>
            <div className="card-header position-md-relative">
              <div className='col-12 card-title bd-highlight'>
                <Formik enableReinitialize={true} initialValues={[]} onSubmit={() => { }}>
                  {({ setFieldValue, values }) => (
                    <Form className='col-md-3 z-index-3'>
                      <FilterMultiSelect
                        label={"Month"}
                        name="month_target_spesific"
                        options={[]}
                        onChange={(selected: Option[]) => {
                          // setFieldValue("asm", selected);
                          // const arrayValueMonth = values["month"].map(
                          //     (x: Object) => x["value"]
                          // );
                          // const arrayValueAsm = selected.map(
                          //     (x: Object) => x["value"]
                          // );
                          // setTimeout(() => {
                          //     fetchingCreditProcessAPI({
                          //         asm: arrayValueAsm,
                          //         month: arrayValueMonth,
                          //     })
                          // }, 500);
                        }}
                        value={values["month_target_spesific"]}
                      />
                    </Form>
                  )}
                </Formik>
                <h3 className="position-md-absolute position-relative top-50 start-50 translate-middle">
                  <span className="card-label fw-bold fs-7 fs-md-4">Target Spesific by Manpower</span>
                </h3>
              </div>
            </div>
            <KTCardBody className="p-0 py-5">
              <div className='table-responsive'>
                {loadingTableGroup ? (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={"100%"}
                    height={600}
                  />
                ) : (
                  <Paper sx={{ width: '100%' }} >
                    <TableContainer sx={{ maxHeight: 600 }}>
                      <Table stickyHeader size="small" aria-label="table with sticky header">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center" style={{ fontSize: 9, minWidth: 100 }} rowSpan={3}>Outlet</StyledTableCell>
                            <StyledTableCell align="center" style={{ fontSize: 9, minWidth: 150 }} rowSpan={3}>SPV</StyledTableCell>
                            <StyledTableCell align="center" style={{ fontSize: 9, minWidth: 150 }} rowSpan={3}>Salesman</StyledTableCell>
                            <StyledTableCellTransaparant align="center" style={{ fontSize: 9, minWidth: 100 }} colSpan={6}>As of 1 - 5 Days</StyledTableCellTransaparant>
                            <StyledTableCell align="center" style={{ fontSize: 9, minWidth: 100 }} colSpan={6}>As of 6 - 10 Days</StyledTableCell>
                            <StyledTableCellTransaparant align="center" style={{ fontSize: 9, minWidth: 100 }} colSpan={6}>As of 11 - 15 Days</StyledTableCellTransaparant>
                            <StyledTableCell align="center" style={{ fontSize: 9, minWidth: 100 }} colSpan={6}>As of 16 - 20 Days</StyledTableCell>
                            <StyledTableCellTransaparant align="center" style={{ fontSize: 9, minWidth: 100 }} colSpan={6}>As of 21 - 25 Days</StyledTableCellTransaparant>
                            <StyledTableCell align="center" style={{ fontSize: 9, minWidth: 100 }} colSpan={6}>As of 26 - 31 Days</StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCellWhite style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>Prospect</StyledTableCellWhite>
                            <StyledTableCellYellow style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>SPK</StyledTableCellYellow>
                            <StyledTableCellGreen style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>DO</StyledTableCellGreen>
                            <StyledTableCellWhite style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>Prospect</StyledTableCellWhite>
                            <StyledTableCellYellow style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>SPK</StyledTableCellYellow>
                            <StyledTableCellGreen style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>DO</StyledTableCellGreen>
                            <StyledTableCellWhite style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>Prospect</StyledTableCellWhite>
                            <StyledTableCellYellow style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>SPK</StyledTableCellYellow>
                            <StyledTableCellGreen style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>DO</StyledTableCellGreen>
                            <StyledTableCellWhite style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>Prospect</StyledTableCellWhite>
                            <StyledTableCellYellow style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>SPK</StyledTableCellYellow>
                            <StyledTableCellGreen style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>DO</StyledTableCellGreen>
                            <StyledTableCellWhite style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>Prospect</StyledTableCellWhite>
                            <StyledTableCellYellow style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>SPK</StyledTableCellYellow>
                            <StyledTableCellGreen style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>DO</StyledTableCellGreen>
                            <StyledTableCellWhite style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>Prospect</StyledTableCellWhite>
                            <StyledTableCellYellow style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>SPK</StyledTableCellYellow>
                            <StyledTableCellGreen style={{ fontSize: 9, top: 32 }} align="center" colSpan={2}>DO</StyledTableCellGreen>
                          </TableRow>
                          <TableRow>
                            <StyledTableCellWhite style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellWhite>
                            <StyledTableCellWhite style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellWhite>
                            <StyledTableCellYellow style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellYellow>
                            <StyledTableCellYellow style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellYellow>
                            <StyledTableCellGreen style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellGreen>
                            <StyledTableCellGreen style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellGreen>
                            <StyledTableCellWhite style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellWhite>
                            <StyledTableCellWhite style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellWhite>
                            <StyledTableCellYellow style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellYellow>
                            <StyledTableCellYellow style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellYellow>
                            <StyledTableCellGreen style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellGreen>
                            <StyledTableCellGreen style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellGreen>
                            <StyledTableCellWhite style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellWhite>
                            <StyledTableCellWhite style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellWhite>
                            <StyledTableCellYellow style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellYellow>
                            <StyledTableCellYellow style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellYellow>
                            <StyledTableCellGreen style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellGreen>
                            <StyledTableCellGreen style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellGreen>
                            <StyledTableCellWhite style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellWhite>
                            <StyledTableCellWhite style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellWhite>
                            <StyledTableCellYellow style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellYellow>
                            <StyledTableCellYellow style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellYellow>
                            <StyledTableCellGreen style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellGreen>
                            <StyledTableCellGreen style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellGreen>
                            <StyledTableCellWhite style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellWhite>
                            <StyledTableCellWhite style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellWhite>
                            <StyledTableCellYellow style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellYellow>
                            <StyledTableCellYellow style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellYellow>
                            <StyledTableCellGreen style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellGreen>
                            <StyledTableCellGreen style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellGreen>
                            <StyledTableCellWhite style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellWhite>
                            <StyledTableCellWhite style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellWhite>
                            <StyledTableCellYellow style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellYellow>
                            <StyledTableCellYellow style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellYellow>
                            <StyledTableCellGreen style={{ fontSize: 9, width: 10, top: 65 }} align="center">T</StyledTableCellGreen>
                            <StyledTableCellGreen style={{ fontSize: 9, width: 10, top: 65 }} align="center">A</StyledTableCellGreen>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* START:: LOPING */}
                          {data_grouping_by_manpower.map((row: any, i: number) => {
                            return (
                              <React.Fragment key={i}>
                                <StyledTableRow hover tabIndex={-1} key={i}>
                                  <StyledTableCell rowSpan={row['full_rows'] + row['rows'] + 2}>{row['outlet']}</StyledTableCell>
                                </StyledTableRow>
                                {row['children'].map((spv: any, sv: number) => {
                                  return (
                                    <React.Fragment key={sv}>
                                      <StyledTableRow>
                                        <StyledTableCell rowSpan={spv['rows'] + 2} >{spv['spv']}</StyledTableCell>
                                      </StyledTableRow>
                                      {spv['children'].map((sa: any, s: number) => {
                                        return (
                                          <React.Fragment key={s}>
                                            <StyledTableRow>
                                              <StyledTableCell>{sa['sales']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['prospek_target_1']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['prospek_actual_1']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['spk_target_1']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['spk_actual_1']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['do_target_1']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['do_actual_1']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['prospek_target_2']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['prospek_actual_2']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['spk_target_2']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['spk_actual_2']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['do_target_2']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['do_actual_2']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['prospek_target_3']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['prospek_actual_3']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['spk_target_3']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['spk_actual_3']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['do_target_3']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['do_actual_3']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['prospek_target_4']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['prospek_actual_4']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['spk_target_4']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['spk_actual_4']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['do_target_4']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['do_actual_4']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['prospek_target_5']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['prospek_actual_5']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['spk_target_5']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['spk_actual_5']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['do_target_5']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['do_actual_5']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['prospek_target_6']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['prospek_actual_6']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['spk_target_6']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['spk_actual_6']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['do_target_6']}</StyledTableCell>
                                              <StyledTableCell align="center">{sa['do_actual_6']}</StyledTableCell>
                                            </StyledTableRow>
                                          </React.Fragment>
                                        )
                                      })}
                                      <StyledTableRow hover tabIndex={-1}>
                                        <StyledTableCell style={{ fontWeight: "bold" }}>Total</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'prospek_target_1')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'prospek_actual_1')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'spk_target_1')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'spk_actual_1')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'do_target_1')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'do_actual_1')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'prospek_target_2')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'prospek_actual_2')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'spk_target_2')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'spk_actual_2')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'do_target_2')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'do_actual_2')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'prospek_target_3')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'prospek_actual_3')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'spk_target_3')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'spk_actual_3')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'do_target_3')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'do_actual_3')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'prospek_target_4')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'prospek_actual_4')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'spk_target_4')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'spk_actual_4')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'do_target_4')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'do_actual_4')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'prospek_target_5')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'prospek_actual_5')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'spk_target_5')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'spk_actual_5')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'do_target_5')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'do_actual_5')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'prospek_target_6')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'prospek_actual_6')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'spk_target_6')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'spk_actual_6')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'do_target_6')}</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSalesAvg(spv['children'], 'do_actual_6')}</StyledTableCell>
                                      </StyledTableRow>
                                    </React.Fragment>
                                  )
                                })}
                                <StyledTableRow hover tabIndex={-1}>
                                  <StyledTableCell style={{ fontWeight: "bold" }} colSpan={2}>Total</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'prospek_target_1')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'prospek_actual_1')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'spk_target_1')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'spk_actual_1')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'do_target_1')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'do_actual_1')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'prospek_target_2')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'prospek_actual_2')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'spk_target_2')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'spk_actual_2')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'do_target_2')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'do_actual_2')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'prospek_target_3')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'prospek_actual_3')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'spk_target_3')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'spk_actual_3')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'do_target_3')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'do_actual_3')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'prospek_target_4')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'prospek_actual_4')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'spk_target_4')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'spk_actual_4')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'do_target_4')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'do_actual_4')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'prospek_target_5')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'prospek_actual_5')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'spk_target_5')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'spk_actual_5')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'do_target_5')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'do_actual_5')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'prospek_target_6')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'prospek_actual_6')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'spk_target_6')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'spk_actual_6')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'do_target_6')}</StyledTableCell>
                                  <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumDataSPVAvg(row['children'], 'do_actual_6')}</StyledTableCell>
                                </StyledTableRow>
                              </React.Fragment>
                            )
                          })}
                        </TableBody>
                        <TableFooter sx={{
                          position: "sticky",
                          bottom: "0px",
                          backgroundColor: "white",
                        }}>
                          <TableRow>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} colSpan={3}>Total Keseluruhan</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'prospek_target_1')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'prospek_actual_1')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'spk_target_1')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'spk_actual_1')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'do_target_1')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'do_actual_1')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'prospek_target_2')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'prospek_actual_2')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'spk_target_2')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'spk_actual_2')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'do_target_2')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'do_actual_2')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'prospek_target_3')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'prospek_actual_3')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'spk_target_3')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'spk_actual_3')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'do_target_3')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'do_actual_3')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'prospek_target_4')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'prospek_actual_4')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'spk_target_4')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'spk_actual_4')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'do_target_4')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'do_actual_4')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'prospek_target_5')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'prospek_actual_5')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'spk_target_5')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'spk_actual_5')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'do_target_5')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'do_actual_5')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'prospek_target_6')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'prospek_actual_6')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'spk_target_6')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'spk_actual_6')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'do_target_6')}</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontSize: 9 }} align="center">{sumAllDataAvg(data_grouping_by_manpower, 'do_actual_6')}</StyledTableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </Paper >
                )
                }
              </div>
            </KTCardBody>
          </KTCard>
        </div>
        {/* ----------------------------------------------------------------------------- */}
        {/* Leads to SPK Target */}
        <div className="row flex-xxl-row">
          <KTCard stretch='stretch' className='mb-5'>
            <div className="card-header position-md-relative">
              <div className='col-12 card-title bd-highlight'>
                <Formik enableReinitialize={true} initialValues={[]} onSubmit={() => { }}>
                  {({ setFieldValue, values }) => (
                    <Form className='col-md-3 z-index-3'>
                      <FilterMultiSelect
                        label={"Month"}
                        name="month_leads_to_spk"
                        options={[]}
                        onChange={(selected: Option[]) => {
                          // setFieldValue("asm", selected);
                          // const arrayValueMonth = values["month"].map(
                          //     (x: Object) => x["value"]
                          // );
                          // const arrayValueAsm = selected.map(
                          //     (x: Object) => x["value"]
                          // );
                          // setTimeout(() => {
                          //     fetchingCreditProcessAPI({
                          //         asm: arrayValueAsm,
                          //         month: arrayValueMonth,
                          //     })
                          // }, 500);
                        }}
                        value={values["month_leads_to_spk"]}
                      />
                    </Form>
                  )}
                </Formik>
                <h3 className="position-md-absolute position-relative top-50 start-50 translate-middle">
                  <span className="card-label fw-bold fs-7 fs-md-4">Leads to SPK Target</span>
                </h3>
              </div>
            </div>
            <KTCardBody className="p-0 py-5">
              <div className='table-responsive'>
                <MaterialReactTableDetails
                  columns={this['column_leads_to_spk']}
                  data={this['data_leads_to_spk']}
                  isLoading={isLoadingLeadsToSpk}
                  maxHeight='500px'
                  enableTopToolbar={true}
                  optionsHead={{
                    fontSize: 9,
                  }}
                />
              </div>
            </KTCardBody>
          </KTCard>
        </div>
        {/* ----------------------------------------------------------------------------- */}
        {/* SPK to DO Target */}
        <div className="row flex-xxl-row">
          <KTCard stretch='stretch' className='mb-5'>
            <div className="card-header position-md-relative">
              <div className='col-12 card-title bd-highlight'>
                <Formik enableReinitialize={true} initialValues={[]} onSubmit={() => { }}>
                  {({ setFieldValue, values }) => (
                    <Form className='col-md-3 z-index-3'>
                      <FilterMultiSelect
                        label={"Month"}
                        name="month_spk_to_do"
                        options={[]}
                        onChange={(selected: Option[]) => {
                          // setFieldValue("asm", selected);
                          // const arrayValueMonth = values["month"].map(
                          //     (x: Object) => x["value"]
                          // );
                          // const arrayValueAsm = selected.map(
                          //     (x: Object) => x["value"]
                          // );
                          // setTimeout(() => {
                          //     fetchingCreditProcessAPI({
                          //         asm: arrayValueAsm,
                          //         month: arrayValueMonth,
                          //     })
                          // }, 500);
                        }}
                        value={values["month_spk_to_do"]}
                      />
                    </Form>
                  )}
                </Formik>
                <h3 className="position-md-absolute position-relative top-50 start-50 translate-middle">
                  <span className="card-label fw-bold fs-7 fs-md-4">SPK to DO Target</span>
                </h3>
              </div>
            </div>
            <KTCardBody className="p-0 py-5">
              <div className='table-responsive'>
                <MaterialReactTableDetails
                  columns={[{
                    columns: [{
                      header: "Outlet",
                      accessorKey: 'outlet',
                      fontSize: 9,
                      size: 50,
                    }, {
                      header: 'Salesman',
                      accessorKey: 'salesman',
                      fontSize: 9,
                      size: 50,
                    }, {
                      header: 'Sales SPV',
                      accessorKey: 'salesspv',
                      fontSize: 9,
                      size: 50,
                    }, {
                      header: 'DO Target Name',
                      accessorKey: 'spk_target_name',
                      fontSize: 9,
                      size: 50,
                    }, {
                      header: 'Contact Number',
                      accessorKey: 'contact_number',
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                      fontSize: 9,
                      size: 50,
                    }, {
                      header: 'Model',
                      accessorKey: 'model',
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                      fontSize: 9,
                      size: 50,
                    }, {
                      header: 'DO DATE PLAN',
                      accessorKey: 'do_date_plan',
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                      fontSize: 9,
                      size: 50,
                    }, {
                      header: 'CASH',
                      accessorKey: 'cash',
                      Cell: ({ cell }) => {
                        if (cell.getValue()) {
                          return "Yes";
                        }
                        if (cell.row.original['credit']) {
                          return "";
                        }
                      },
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                      fontSize: 9,
                      size: 50,
                    }, {
                      header: 'Credit',
                      accessorKey: 'credit',
                      Cell: ({ cell }) => {
                        if (cell.getValue()) {
                          return "Yes";
                        }
                        if (cell.row.original['cash']) {
                          return "";
                        }
                      },
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                      fontSize: 9,
                      size: 50,
                    }, {
                      header: 'Leasing',
                      accessorKey: 'leasing',
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                      fontSize: 9,
                      size: 50,
                    }],
                    header: 'PURCHASE METHOD',
                    id: 'purchase_method',
                  }, {
                    columns: [{
                      header: 'Submit Order',
                      accessorKey: 'submit_order',
                      fontSize: 9,
                      size: 50,
                      Cell: ({ cell }) => {
                        return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
                      },
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                    }, {
                      header: 'Survey',
                      accessorKey: 'survey',
                      fontSize: 9,
                      size: 50,
                      Cell: ({ cell }) => {
                        return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
                      },
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                    }, {
                      header: 'Document Lengkap',
                      accessorKey: 'doc_lengkap',
                      fontSize: 9,
                      size: 50,
                      Cell: ({ cell }) => {
                        return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
                      },
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                    }, {
                      header: 'Ca Process',
                      accessorKey: 'ca_process',
                      fontSize: 9,
                      size: 50,
                      Cell: ({ cell }) => {
                        return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
                      },
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                    }, {
                      header: 'Decision',
                      accessorKey: 'decision',
                      fontSize: 9,
                      size: 50,
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                    }, {
                      header: 'Decision Date',
                      accessorKey: 'decision_date',
                      fontSize: 9,
                      size: 50,
                      Cell: ({ cell }) => {
                        return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
                      },
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                    }, {
                      header: 'Appeal',
                      accessorKey: 'appeal',
                      fontSize: 9,
                      size: 50,
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                    }, {
                      header: 'Appeal Date',
                      accessorKey: 'appeal_date',
                      fontSize: 9,
                      size: 50,
                      Cell: ({ cell }) => {
                        return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
                      },
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                    }, {
                      header: 'Final Result',
                      accessorKey: 'final_result',
                      fontSize: 9,
                      size: 50,
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                    }],
                    header: 'CREDIT PROCESS',
                    id: 'credit_process'
                  }, {
                    columns: [{
                      header: 'Do',
                      accessorKey: 'do',
                      fontSize: 9,
                      size: 50,
                      Cell: ({ cell }) => {
                        return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
                      },
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                    }, {
                      header: 'Carry Over',
                      accessorKey: 'carry_over',
                      fontSize: 9,
                      size: 50,
                      Cell: ({ cell }) => {
                        if (cell.getValue()) {
                          return "Yes";
                        }
                        if (!cell.getValue()) {
                          return "No";
                        }
                        if (cell.getValue() === '' || cell.getValue() === null) {
                          return "";
                        }
                      },
                      muiTableBodyCellProps: ({ cell }) => {
                        return ({
                          align: 'center',
                        })
                      },
                    }],
                    header: 'ACTUAL',
                    id: 'actual'
                  }]}
                  data={this['state']['dataArraySpkToDo']}
                  isLoading={isLoadingSpkToDo}
                  maxHeight='500px'
                  enableTopToolbar={true}
                  optionsHead={{
                    fontSize: 9,
                  }}
                />
              </div>
            </KTCardBody>
          </KTCard>
        </div>
      </React.Fragment>
    )
  }
}
