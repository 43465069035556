import React, { useEffect, useMemo, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core';
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { DateFilter, LeasingFilter } from '../../../../hooks/filterMSCP';
import TypesFilter from '../../../../hooks/filterType';
import { dates } from '../dashboard';
import GetDataFilter from '../../../../hooks/filter';
import { equalsCheck } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';
import { ComponentHelper } from './helper';
import { LinearProgress } from '@mui/material';

const MtdProcessIndex = (props: any) => {
    // Filter ASM & Outlet
    const [asmVal, setAsmVal] = useState<string[]>([]);
    const [tempAsm, setTempAsm] = useState<Option[]>([]);
    const [outletVal, setOutletVal] = useState<string[]>([]);
    const [tempOutlet, setTempOutlet] = useState<Option[]>([]);
    const filterData = GetDataFilter({ asmVal: asmVal, outletVal: outletVal });

    const leasingOpt = LeasingFilter();
    const modelOpt = TypesFilter();
    const [tempModel, setTempModel] = useState<Option[]>([]);
    const [modelValue, setModelValue] = useState<any>([]);
    const [loading, setloading] = useState<boolean>(true);
    // Approval Rate and Rejection Rate by Outlet

    // Date Filter
    const dateOpt = DateFilter();
    const [dateValue, setDateValue] = useState<any>([]);
    const [tempDate, setTempDate] = useState<Option[]>([]);
    const [dateSelected, setDateSelected] = useState<dates>({
        month: [],
        year: [],
    });

    let memoInitialData = useMemo(() => {
        let initialDates = {
            month: dateOpt?.map((val: dates) => val.month),
            year: dateOpt?.map((val: dates) => val.year)
        }
        return {
            dtTempDate: dateSelected?.month.length > 0 ? dateSelected : initialDates,
            dtTempModel: modelValue.length > 0 ? modelValue : modelOpt.map((val: any) => val.value)

        }
    }, [dateOpt, dateSelected, modelOpt, modelValue]);

    useEffect(() => {
        setloading(true);
    }, [memoInitialData]);
    // API:: Credit Process

    return (
        <React.Fragment>
            <PageTitle breadcrumbs={[{ title: "Credit Process", path: "/sales/mtd-process", isSeparator: false, isActive: false }]}>
                {"MTD Process"}
            </PageTitle>
            {/* BEGIN:: Filter Input */}
            <div className="row g-5">
                <div className="row justify-content-center align-items-center"
                style={{zIndex: 4}}>
                    <div className="col-lg-3 mb-5">
                        <FilterMultiSelect
                            label={"Month"}
                            name="month"
                            options={dateOpt}
                            onMenuClose={() => {
                                let toFilter = tempDate?.map((val) => val.value);
                                let forDates = {
                                    month: tempDate?.map((val: Option) => val.month),
                                    year: tempDate?.map((val: Option) => val.year)
                                }
                                let toDates = tempDate.length > 0 ? forDates
                                    : ({
                                        month: dateOpt?.map((val: dates) => val.month),
                                        year: dateOpt?.map((val: dates) => val.year)
                                    })
                                console.log("toDates", toDates);

                                // setDateSelected(toDates as any);

                                return equalsCheck(
                                    toFilter as [],
                                    dateValue
                                )
                                    ? null
                                    : (setDateValue(toFilter as []), setDateSelected(toDates as any));
                            }}
                            onChange={(selected: Option[]) => {
                                setTempDate(selected);
                            }}
                            value={tempDate}
                        />
                    </div>
                    <div className="col-lg-3 mb-5">
                        <FilterMultiSelect
                            label={"ASM"}
                            name="asm"
                            options={filterData?.asmOpt}
                            onMenuClose={() => {
                                let toFilter = tempAsm?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    asmVal
                                )
                                    ? null
                                    : setAsmVal(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempAsm(selected);
                            }}
                            value={tempAsm}
                        />
                    </div>
                    <div className="col-lg-3 mb-5">
                        <FilterMultiSelect
                            label={"Outlet"}
                            name="outlet"
                            options={filterData?.outletOpt}
                            onMenuClose={() => {
                                let toFilter = tempOutlet?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    outletVal
                                )
                                    ? null
                                    : setOutletVal(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempOutlet(selected);
                            }}
                            value={tempOutlet}
                        />
                    </div>
                    <div className="col-lg-3 mb-5">
                        <FilterMultiSelect
                            label={"Model"}
                            name="model"
                            options={modelOpt}
                            onMenuClose={() => {
                                let toFilter = tempModel?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    modelValue
                                )
                                    ? null
                                    : setModelValue(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempModel(selected);
                            }}
                            value={tempModel}
                        />
                    </div>
                </div>
            </div>
            {/* END:: Filter Input */}
            {/* ----------------------------------------------------------------------------- */}
             {dateOpt.length > 0 ?
            (<div className="row g-5 mh-100">
                <ComponentHelper defaultValue={{label: 'WULING FIN', value: 'WULING FIN'}} leasingOpt={leasingOpt} dates={memoInitialData.dtTempDate} coverage={filterData.coverage} model={memoInitialData.dtTempModel}/>
                <ComponentHelper defaultValue={{label: 'ADIRA', value: 'ADIRA'}} leasingOpt={leasingOpt} dates={memoInitialData.dtTempDate} coverage={filterData.coverage} model={memoInitialData.dtTempModel} />
            </div> ): 
            <LinearProgress/>
            }               
        </React.Fragment>
    )
}

export default MtdProcessIndex;
