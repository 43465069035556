/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers";
import { Form, Formik } from "formik";
import { NationalInformationAPI } from "../../../../api/NationalInformationAPI";
import FilterMultiSelect, { Option } from "../../../../../_metronic/helpers/components/form-actions/FilteringInput";
import DataTableMuiModel from "../../../../models/DataTable/dataTableModal";
import { MaterialReactTableDetails } from "../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable";
import { HighchartsSample } from "../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample";
import { GaikindoRSModel, GaikindoWSModel } from "../../../../models/sales/NationalInformationModel";
export class ChartGaikindoIndex extends Component {
    constructor(props: any) {
        super(props);
        this["title"] = "Gaikindo";
        this["api"] = new NationalInformationAPI();
        this["month"] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        this["state"] = {
            loading: true,
            initialValues: {
                month: [],
                brand: [],
            }, initialValuesChartColumnLine: {
                retail_sales: {
                    series: [],
                    categories: props["month"],
                    data: [],
                }, wholesales: {
                    series: [],
                    categories: props["month"],
                    data: [],
                },
            }, initialValuesChartPie: {
                retail_sales: {
                    series_old: [],
                    series_now: []
                }, wholesales: {
                    series_old: [],
                    series_now: []
                },
            }
        };
        this['option_month'] = [];
        this['option_brand'] = [];
        this["datatable_rs"] = [];
        this["columntable_rs"] = [];
        this["datatable_ws"] = [];
        this["columntable_ws"] = [];
    }
    //* ----------------------------------------------------------------------------- */
    // BEGIN:: componentDidMount */
    async componentDidMount() {
        //? Load Filter Options Month
        await this['api'].getMonth().then(async (res: any) => {
            this['option_month'] = res['data'].map((val: any) => {
                return {
                    value: val['id'],
                    label: val['text'],
                    year: val['year']
                };
            });
        });
        //? Load Filter Options Dealer Group
        await this['api'].getBrand().then(async (res: any) => {
            this['option_brand'] = res['data'].map((val: any) => {
                return {
                    value: val['id'],
                    label: val['text'],
                };
            });

        });
        //! Load Chart 1
        await this.LoadChart1(this['state']['initialValues']);
        //! Load Chart 2
        await this.LoadChart2(this['state']['initialValues']);
    }
    //** End: Component */
    //* ----------------------------------------------------------------------------- */
    //! Function Load Chart 1
    async LoadChart1(initial: []) {
        const { data: dataResultColumnLine } = await this["api"].getDataChartGaikindo({ ...initial, tipe: "ChartLineBar" });
        const {
            dataYearOld: dataRSYearOld,
            dataYearNow: dataRSYearNow,
            growth: growthRS,
            sumGrowthYTD: sumRSGrowthYTD,
            sumDataYTDNow: sumDataRSYTDNow,
            sumDataYTDold: sumDataRSYTDold,
        } = dataResultColumnLine["RSChartLine"][0];
        const {
            dataYearOld: dataWSYearOld,
            dataYearNow: dataWSYearNow,
            growth: growthWS,
            sumGrowthYTD: sumWSGrowthYTD,
            sumDataYTDNow: sumDataWSYTDNow,
            sumDataYTDold: sumDataWSYTDold,
        } = dataResultColumnLine["WSChartLine"][0];
        this["setState"]({
            ...this["state"],
            initialValuesChartColumnLine: {
                retail_sales: {
                    series: [
                        {
                            name: `${this["option_month"][0]?.["year"] - 1} RS`,
                            type: "column",
                            yAxis: 1,
                            data: dataRSYearOld,
                        },
                        {
                            name: `${this["option_month"][0]?.["year"]} RS`,
                            type: "column",
                            yAxis: 1,
                            data: dataRSYearNow,
                        },
                        {
                            name: "Growth",
                            type: "line",
                            data: growthRS,
                            tooltip: {
                                valueSuffix: " %",
                            },
                        },
                    ],
                    categories: this["month"],
                    data: [
                        {
                            name: "YTD Growth",
                            value: sumRSGrowthYTD,
                        },
                        {
                            name: `${this["option_month"][0]?.["year"]} YTD RS`,
                            value: sumDataRSYTDNow,
                        },
                        {
                            name: `${this["option_month"][0]?.["year"] - 1} YTD RS`,
                            value: sumDataRSYTDold,
                        },
                    ]
                }, wholesales: {
                    series: [{
                        name: `${this["option_month"][0]?.["year"] - 1} RS`,
                        type: "column",
                        yAxis: 1,
                        data: dataWSYearOld,
                    }, {
                        name: `${this["option_month"][0]?.["year"]} RS`,
                        type: "column",
                        yAxis: 1,
                        data: dataWSYearNow,
                    }, {
                        name: "Growth",
                        type: "line",
                        data: growthWS,
                        tooltip: {
                            valueSuffix: " %",
                        }
                    }],
                    categories: this["month"],
                    data: [{
                        name: "YTD Growth",
                        value: sumWSGrowthYTD,
                    }, {
                        name: `${this["option_month"][0]?.["year"]} YTD RS`,
                        value: sumDataWSYTDNow,
                    }, {
                        name: `${this["option_month"][0]?.["year"] - 1} YTD RS`,
                        value: sumDataWSYTDold,
                    }],
                }
            }
        });
    }
    //! Function Load Chart 2
    async LoadChart2(dataSend: any) {
        this.setState({ loading: true });
        const { data: dataResult } = await this["api"].getDataChartGaikindo({
            ...dataSend,
            tipe: "ChartTablePie"
        });
        const { TableByBrand, chartPie } = dataResult;
        const { tableRSbyBrand, tableWSbyBrand } = TableByBrand;
        //! Table Retail Sales
        this["datatable_rs_model"] = new DataTableMuiModel({ readOnly: true });
        // Table RS        
        const max_rs_old_year = Math.max(...tableRSbyBrand.map((obj: any) => obj['rs_old_year']));
        const max_rs_year_now = Math.max(...tableRSbyBrand.map((obj: any) => obj['rs_year_now']));
        // Share
        const max_rs_share_old_year = Math.max(...tableRSbyBrand.map((obj: any) => obj['share_old_year']));
        const max_rs_share_year_now = Math.max(...tableRSbyBrand.map((obj: any) => obj['share_year_now']));
        tableRSbyBrand.map((item: any, i: number) => {
            let dataModel = new GaikindoRSModel({
                ...item,
                max_rs_old_year: max_rs_old_year,
                max_rs_year_now: max_rs_year_now,
                max_share_old_year: max_rs_share_old_year,
                max_share_year_now: max_rs_share_year_now,
            });
            if (item["status"] === 1) {
                this["datatable_rs_model"].add({
                    id: i,
                    no: i + 1,
                    dataModel: dataModel,
                    actions: null,
                });
            }
            return item;
        });
        this["columntable_rs"] = await this["datatable_rs_model"].getColumn();
        this["datatable_rs"] = await this["datatable_rs_model"].getDatas();
        //! Table WholeSales
        this["datatable_ws_model"] = new DataTableMuiModel({ readOnly: true });
        const max_ws_old_year = Math.max(...tableWSbyBrand.map((obj: any) => obj['ws_old_year']));
        const max_ws_year_now = Math.max(...tableWSbyBrand.map((obj: any) => obj['ws_year_now']));
        // Share
        const max_ws_share_old_year = Math.max(...tableWSbyBrand.map((obj: any) => obj['share_old_year']));
        const max_ws_share_year_now = Math.max(...tableWSbyBrand.map((obj: any) => obj['share_year_now']));
        tableWSbyBrand.map((item: any, i: number) => {
            let dataModel = new GaikindoWSModel({
                ...item,
                max_ws_old_year: max_ws_old_year,
                max_ws_year_now: max_ws_year_now,
                max_share_old_year: max_ws_share_old_year,
                max_share_year_now: max_ws_share_year_now,
            });
            if (item["status"] === 1) {
                this["datatable_ws_model"].add({
                    id: i,
                    no: i + 1,
                    dataModel: dataModel,
                    actions: null,
                });
            }
            return item;
        });
        this["columntable_ws"] = await this["datatable_ws_model"].getColumn();
        this["datatable_ws"] = await this["datatable_ws_model"].getDatas();
        const { YearOldCompRS, YearNowCompRS, YearOldCompWS, YearNowCompWS } = chartPie;
        this.setState({
            ...this["state"],
            initialValuesChartPie: {
                retail_sales: {
                    series_old: YearOldCompRS,
                    series_now: YearNowCompRS
                }, wholesales: {
                    series_old: YearOldCompWS,
                    series_now: YearNowCompWS
                }
            },
            loading: false
        });
    }
    //** End: Load Data List */
    render(this: any) {
        const { initialValues, initialValuesChartColumnLine, initialValuesChartPie } = this["state"];
        const { retail_sales: retail_sales_column_line, wholesales: wholesales_column_line } = initialValuesChartColumnLine;
        const { retail_sales: retail_sales_pie, wholesales: wholesales_pie } = initialValuesChartPie;
        return (
            <React.Fragment>
                <PageTitle breadcrumbs={[{ title: "National Information", path: "/sales/gaikindo", isSeparator: false, isActive: false }]}>
                    {this["title"]}
                </PageTitle>
                {/* BEGIN:: Filter Input */}
                <div className="row g-5">
                    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={() => { }}>
                        {({ setFieldValue, values }) => (
                            <Form>
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-lg-3 mb-5">
                                        <FilterMultiSelect
                                            label={"Month"}
                                            name="month"
                                            options={this['option_month']}
                                            onChange={(selected: Option[]) => {
                                                setFieldValue("month", selected);
                                                const arrayValueMonth = selected.map((x: Object) => x["value"]);
                                                const arrayValueDealerGroup = values["brand"].map((x: Object) => x["value"]);
                                                setTimeout(() => {
                                                    this.LoadChart2({
                                                        month: arrayValueMonth,
                                                        brand: arrayValueDealerGroup,
                                                    });
                                                }, 500);
                                            }}
                                            value={values["month"]}
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-5">
                                        <FilterMultiSelect
                                            label={"Brand"}
                                            name="brand"
                                            options={this['option_brand']}
                                            onChange={(selected: Option[]) => {
                                                setFieldValue("brand", selected);
                                                const arrayValueMonth = values["month"].map((x: object) => x["value"]);
                                                const arrayValueDealerGroup = selected.map((x: object) => x["value"]);
                                                setTimeout(() => {
                                                    this.LoadChart1({
                                                        month: arrayValueMonth,
                                                        brand: arrayValueDealerGroup,
                                                    });
                                                }, 500);
                                            }}
                                            value={values["brand"]}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                {/* END:: Filter Input */}
                {/* ----------------------------------------------------------------------------- */}
                {/* Retail Sales */}
                <div className="row g-5 mh-md-800px">
                    <div className="bd-highlight">
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">Retail Sales</span>
                                </h3>
                            </div>
                            <KTCardBody className="p-3">
                                <HighchartsSample
                                    id="retail_sales"
                                    type="column_line"
                                    positionColumnData={"right"}
                                    height="400px"
                                    series={retail_sales_column_line["series"]}
                                    categories={retail_sales_column_line["categories"]}
                                    data={retail_sales_column_line["data"]}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* ----------------------------------------------------------------------------- */}
                {/* BEGIN:: Card Table & Pie RS */}
                <div className="row g-5 mh-md-700px">
                    {/* YTD RS Growth by Brand */}
                    <div className="col-xxl-6 bd-highlight">
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">YTD RS Growth by Brand</span>
                                </h3>
                            </div>
                            <KTCardBody className="p-3">
                                <MaterialReactTableDetails
                                    data={this["datatable_rs"]}
                                    columns={this["columntable_rs"]}
                                    isLoading={this["state"]["loading"]}
                                    maxHeight={"438px"}
                                    hover={false}
                                    optionsHead={{
                                        fontSize: 10,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                    {/* Pie WS Comp */}
                    <div className="col-xxl-3 bd-highlight">
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">{`${this['option_month'][0]?.['year'] - 1} RS Comp.`}</span>
                                </h3>
                            </div>
                            <KTCardBody>
                                <HighchartsSample
                                    id="retail_sales_pie_chart1"
                                    type="pie"
                                    height="450px"
                                    positionLegend={"BottomCenter"}
                                    series={retail_sales_pie?.["series_old"]}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                    <div className="col-xxl-3 bd-highlight">
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">{`${this['option_month'][0]?.['year']} RS Comp.`}</span>
                                </h3>
                            </div>
                            <KTCardBody>
                                <HighchartsSample
                                    id="retail_sales_pie_chart2"
                                    type="pie"
                                    height="450px"
                                    series={retail_sales_pie?.["series_now"]}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* END:: Card Table & Pie RS */}
                {/* ----------------------------------------------------------------------------- */}
                {/* BEGIN:: Card Chart Column Line WS */}
                <div className="row g-5 mh-md-800px">
                    {/* Wholesales */}
                    <div className="bd-highlight">
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">Wholesales</span>
                                </h3>
                            </div>
                            <KTCardBody className="p-3">
                                <HighchartsSample
                                    id="wholesales_chart1"
                                    type="column_line"
                                    positionColumnData={"right"}
                                    height="400px"
                                    series={wholesales_column_line["series"]}
                                    categories={wholesales_column_line["categories"]}
                                    data={wholesales_column_line["data"]}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,

                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* END:: Card Chart Column Line WS */}
                {/* ----------------------------------------------------------------------------- */}
                {/* BEGIN:: Card Table & Pie WS */}
                <div className="row g-5 mh-md-700px">
                    {/* YTD WS Growth by Brand */}
                    <div className="col-xxl-6 bd-highlight">
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">YTD WS Growth by Brand</span>
                                </h3>
                            </div>
                            <KTCardBody className="p-3">
                                <MaterialReactTableDetails
                                    data={this["datatable_ws"]}
                                    columns={this["columntable_ws"]}
                                    isLoading={this["state"]["loading"]}
                                    maxHeight={"438px"}
                                    hover={false}
                                    optionsHead={{
                                        fontSize: 10,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                    {/* Pie WS Comp */}
                    <div className="col-xxl-3 bd-highlight">
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">{`${this['option_month'][0]?.['year'] - 1} WS Comp.`}</span>
                                </h3>
                            </div>
                            <KTCardBody>
                                <HighchartsSample
                                    id="wholesales_pie_chart1"
                                    type="pie"
                                    height="450px"
                                    series={wholesales_pie?.["series_old"]}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                    <div className="col-xxl-3 bd-highlight">
                        <KTCard flush stretch={"stretch"} >
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">{`${this['option_month'][0]?.['year']} WS Comp.`}</span>
                                </h3>
                            </div>
                            <KTCardBody>
                                <HighchartsSample
                                    id="wholesales_pie_chart2"
                                    type="pie"
                                    height="450px"
                                    series={wholesales_pie?.["series_now"]}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* END:: Card Table & Pie WS */}
            </React.Fragment>
        );
    }
}
export default ChartGaikindoIndex;
