import React from "react";
import { KTIcon } from "../../../../_metronic/helpers";
import { capitalizeWords } from "../../../../_metronic/helpers/helpers-data/AssetHelpers";
export default class MenuConfigurasiModel {
  constructor(
    data = {
      id: "",
      id_parent: "",
      id_sub_parent: "",
      id_sub_child_parent: "",
      alias_brand: "",
      nama_menu: "",
      nama_parent: "",
      menu_inner: "",
      nama_sub_parent: "",
      nama_sub_child_parent: "",
      path: "",
      icon_menu: "",
      urutan: "",
      status: 0,
    }
  ) {
    const { id, menu_inner, id_parent, id_sub_parent, id_sub_child_parent, alias_brand, nama_menu, nama_parent, nama_sub_parent, nama_sub_child_parent, path, icon_menu, urutan, status } = data;
    this["id"] = id;
    this["menu_inner"] = menu_inner;
    this["id_parent"] = id_parent;
    this["id_sub_parent"] = id_sub_parent;
    this["id_sub_child_parent"] = id_sub_child_parent;
    this["alias_brand"] = alias_brand;
    this["nama_menu"] = nama_menu;
    this["nama_parent"] = nama_parent;
    this["nama_sub_parent"] = nama_sub_parent;
    this["nama_sub_child_parent"] = nama_sub_child_parent;
    this["path"] = path;
    this["icon_menu"] = icon_menu;
    this["urutan"] = urutan;
    this["status"] = status;
  }

  async getdata(this: any) {
    const { menu_inner, nama_parent, nama_sub_parent, nama_sub_child_parent, nama_menu, path, alias_brand, icon_menu, urutan } = this;

    return [
      {
        header: "Menu Inner",
        id: "menu_inner",
        value: capitalizeWords("_", menu_inner),
        muiTableBodyCellProps: ({ cell }) => {
          return ({
            align: 'center',  //Type:'center' | 'inherit' | 'justify' | 'left' | 'right'
          })
        },
        size: 200,
      },
      {
        header: "Nama Menu",
        id: "nama_menu",
        value: nama_menu,
        size: 200,
      },

      {
        header: "Parent Menu",
        id: "nama_parent",
        value: nama_parent,
        size: 200,
      },
      {
        header: "Parent Sub Menu",
        id: "nama_sub_parent",
        value: nama_sub_parent,
        size: 200,
      },
      {
        header: "Parent Sub Child Menu",
        id: "nama_sub_child_parent",
        value: nama_sub_child_parent,
        size: 200,
      }, {
        header: "Path",
        id: "path",
        value: path,
        muiTableBodyCellProps: ({ cell }) => {
          return ({
            align: 'justify',  //Type:'center' | 'inherit' | 'justify' | 'left' | 'right'
          })
        },
        enableClickToCopy: true,
        size: 350,
      },
      {
        header: "Brand",
        id: "brand",
        value: capitalizeWords("-", alias_brand),
        muiTableBodyCellProps: ({ cell }) => {
          return ({
            align: 'center',  //Type:'center' | 'inherit' | 'justify' | 'left' | 'right'
          })
        },
        size: 150,
      },
      {
        header: "Icon",
        id: "icon",
        value: icon_menu,
        Cell: ({ cell }) => {
          return (
            <React.Fragment>
              <KTIcon iconName={cell.getValue()} className="fs-1 me-4" />{` ${cell.getValue()}`}
            </React.Fragment>
          )
        },
        muiTableBodyCellProps: ({ cell }) => {
          return ({
            align: 'justify',  //Type:'center' | 'inherit' | 'justify' | 'left' | 'right'
          })
        },
        size: 350,
      },
      {
        header: "Urutan",
        id: "urutan",
        value: urutan,
        muiTableBodyCellProps: ({ cell }) => {
          return ({
            align: 'center',  //Type:'center' | 'inherit' | 'justify' | 'left' | 'right'
          })
        },
        size: 150,
      },
    ];
  }
}
