import React, { useEffect, useState } from 'react';
import { ChartMoM, PieProductivity, TableManPower, TableProductivity } from '../ProductivityHelper';
import { ProductivityAPI } from '../../../../api/ManPower';
import { PerformanceAPI } from '../../../../api/PerformanceAPI';
import { Skeleton } from '@mui/material';
import FilterMultiSelect, { Option, SingleSelect } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { equalsCheck, getDate } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';

type selectType = Array<{ label: string; value: string | number }>
const Productivity = () => {
    const date = getDate();
    const [dtTable, setDtTable] = useState<any>([]);
    const [tableProdOutlet, setDtTableProdOutlet] = useState<any>([]);
    const [dtChartPeriod, setDtChartPeriod] = useState<any>({});
    const [dtChartTrainingLvl, setDtChartTrainingLvl] = useState<any>({});
    const [dtChartProdMoM, setDtChartProdMoM] = useState<any>({});
    const [asmVal, setasmVal] = useState<string[]>([]);
    const [smVal, setsmVal] = useState<string[]>([]);
    const [outletVal, setoutletVal] = useState<string[]>([]);
    const [asmOpt, setasmOpt] = useState<selectType>([]);
    const [smOpt, setsmOpt] = useState<selectType>([]);
    const [outletOpt, setoutletOpt] = useState<selectType>([]);
    const [arrSelect, setArrSelect] = useState<Option[] | null>([]);
    const [smSelect, setSmSelect] = useState<Option[] | null>([]);
    const [outletSelect, setOutletSelect] = useState<Option[] | null>([]);
    const [cov, setCoverage] = useState<string>("");
    const monthOpt = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
    ];
    const [month, setMonth] = useState<number>(date.currMonth + 1);
    const [slcMonth, setSlcMonth] = useState<Option[]>([monthOpt[date.currMonth]]);

    const getFilter = () => {
        let val = {
            asm: asmVal.slice(),
            sm: smVal.slice(),
            outlet: outletVal.slice(),
        };
        new PerformanceAPI().getFilter(val).then((res) => {
            let tempAsm: selectType = [];
            let tempsm: selectType = [];
            let tempoutlet: selectType = [];
            res.data.dataAsm.map((val: { area: string; id_team_asm: string | number }) => {
                return tempAsm.push({
                    label: val.area,
                    value: val.id_team_asm,
                });
            });
            res.data.dataSm.map((val: { region: string; id_team_sm: string | number }) => {
                return tempsm.push({
                    label: val.region,
                    value: val.id_team_sm,
                });
            });
            res.data.dataOutlet.map((val: { text: string; id_outlet: string | number }) => {
                return tempoutlet.push({
                    label: val.text,
                    value: val.id_outlet,
                });
            });
            setasmOpt(tempAsm);
            setsmOpt(tempsm);
            setoutletOpt(tempoutlet);
            setCoverage(res.data.coverage);
            getData(res.data.coverage, month)
        })
    }
    const getData = (cov: string, m: number) => {
        Promise.all([
            new ProductivityAPI().chartprodbyworkperiod(cov, m).then((res) => {
                setDtChartPeriod(res.data)
            }),
            new ProductivityAPI().chartprodbytraininglvl(cov, m).then((res) => {
                setDtChartTrainingLvl(res.data)
            }),
            new ProductivityAPI().chartprodMoM(cov).then((res) => {
                setDtChartProdMoM(res.data)
            }),
            new ProductivityAPI().chartprodbyOutlet(cov, m).then((res) => {
                setDtTableProdOutlet(res.data)
            }),
            new ProductivityAPI().tablemp(cov).then((res) => {
                setDtTable(res.data)
            }),
        ]).then((val) => console.log("Done", val));
        // setLoading(false))
    }

    useEffect(() => {
        const controller = new AbortController();
        getFilter();
        return () => {
            controller.abort();
        };
    }, [month, asmVal, smVal, outletVal])
    return (
        <>
            <div className="row d-flex justify-content-center mb-15 g-5 absolute">
                <div className="col-xxl-3 mb-5">
                    <FilterMultiSelect
                        options={asmOpt}
                        label="ASM"
                        isSelectAll={true}
                        value={arrSelect}
                        onMenuClose={() => {
                            let toFilter = arrSelect?.map((val) => val.value);

                            return equalsCheck(
                                toFilter as [],
                                asmVal
                            )
                                ? null
                                : setasmVal(toFilter as []);
                        }}
                        onChange={(selected: Option[]) => {
                            setArrSelect(selected);
                        }}
                    />
                </div>
                <div className="col-xxl-3 mb-5"
                    style={{ zIndex: 4 }}>
                    <FilterMultiSelect
                        options={smOpt}
                        label="SM"
                        isSelectAll={true}
                        value={smSelect}
                        onMenuClose={() => {
                            let toFilter = smSelect?.map((val) => val.value);
                            return equalsCheck(
                                toFilter as [],
                                smVal
                            )
                                ? null
                                : setsmVal(toFilter as []);
                        }}
                        onChange={(selected: Option[]) => {
                            console.log("selected", selected);
                            setSmSelect(selected);
                        }}
                    />
                </div>
                <div className="col-xxl-3 mb-5">
                    <FilterMultiSelect
                        options={outletOpt}
                        label="Outlet"
                        isSelectAll={true}
                        value={outletSelect}
                        onMenuClose={() => {
                            let toFilter = outletSelect?.map((val) => val.value);
                            return equalsCheck(
                                toFilter as [],
                                outletVal
                            )
                                ? null
                                : setoutletVal(toFilter as []);
                        }}
                        onChange={(selected: Option[]) => {
                            setOutletSelect(selected);
                        }}
                    />
                </div>
                <div className='col-xxl-3 mb-5'
                    style={{ zIndex: 4 }}>
                    <SingleSelect
                        defaultValue={slcMonth}
                        options={monthOpt}
                        label="Month"
                        onChange={(val) => {
                            setMonth(val.value);
                            setSlcMonth(val);
                        }} />
                </div>
            </div>
            {dtTable.length === 0 ? (
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={"100%"}
                    height={550}
                />
            ) :
                (<div className='col col-md-12'>
                    <div className="d-flex align-items-center flex-column my-5 border-0 p-5">
                        {/* begin::Title */}
                        <h3 className="align-items-center flex-column">
                            <span className="card-label fw-bold fs-1 mb-1">Manpower Productivity</span>
                        </h3>
                    </div>
                    <TableManPower dt={dtTable} />
                </div>)
            }
            <div className='row d-flex flex-sm-row flex-column justify-content-evenly my-15'
                style={{
                    padding: "10px 10px 10px 10px",
                }}
            >
                <div className='col col-md-5 card'>
                    <div className="d-flex align-items-center flex-column my-5 border-0 p-5">
                        {/* begin::Title */}
                        <h3 className="align-items-center flex-column">
                            <span className="card-label fw-bold fs-1 mb-1">MoM Productivity</span>
                        </h3>
                    </div>
                    <div style={{ marginBottom: '-50px' }}>
                        <div className='col g-0 mb-0'>
                            <div className='col mx-5'>
                                <div className="fs-5 text-gray-400">Prod</div>
                                <div className="fs-1 fw-bold text-warning">{dtChartProdMoM.totalProd}%</div>
                            </div>
                            <div className='d-flex flex-wrap mx-5'>
                                <div className='border border-bottom-0 border-top-0 border-left-0 border-right-5 border-gray-500 min-w-60px py-0 px-1 me-0 mb-3'>
                                    <div className="fs-7 text-gray-400">MP : {dtChartProdMoM.totalMP}</div>
                                    {/* <div className="fs-4 fw-bold text-dark">6.113</div> */}
                                </div>
                                <div className='min-w-60px py-0 px-0 ms-1'>
                                    <div className="fs-7 text-primary">DO : {dtChartProdMoM.totalDO}</div>
                                    {/* <div className="fs-4 fw-bold text-primary">4.757</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ChartMoM title={'MoM Productivity'} category={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']} dt={{ dtMP: dtChartProdMoM.dtMP, dtDO: dtChartProdMoM.dtDO, dtProd: dtChartProdMoM.dtProd }} />
                </div>
                <div className='col col-md-3 card'>
                    <div className="d-flex align-items-center flex-column my-5 border-0 p-5">
                        {/* begin::Title */}
                        <h3 className="align-items-center flex-column">
                            <span className="card-label fw-bold fs-1 mb-1">Productivity by Training Level</span>
                        </h3>
                    </div>
                    <ChartMoM title={'Productivity by Training Level'} category={['No Level', 'Professional', 'Expert', 'Master']} dt={{ dtMP: dtChartTrainingLvl.dtMP, dtDO: dtChartTrainingLvl.dtDO, dtProd: dtChartTrainingLvl.dtProd }} />
                </div>
                <div className='col col-md-3 card'>
                    <div className="d-flex align-items-center flex-column my-5 border-0 p-5">
                        {/* begin::Title */}
                        <h3 className="align-items-center flex-column">
                            <span className="card-label fw-bold fs-1 mb-1">Productivity by Working Periode</span>
                        </h3>
                    </div>
                    <ChartMoM title={'Productivity by Working Periode'} category={['0-3 Month', '3-6 Month', '6-9 Month', '9-12 Month']} dt={{ dtMP: dtChartPeriod.dtMP, dtDO: dtChartPeriod.dtDO, dtProd: dtChartPeriod.dtProd }} />
                </div>
            </div>
            <div className='row d-flex justify-content-around my-10'>
                <div className='col col-md-6'>
                    <TableProductivity dt={tableProdOutlet.datas} />
                </div>
                <div className='col col-md-5 card'>
                    <PieProductivity dt={tableProdOutlet.dtPie} />
                </div>
            </div>
        </>
    );
};

export default Productivity;
