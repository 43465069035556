import { numberWithCommas } from "../../../_metronic/helpers/helpers-data/AssetHelpers";

export class ModelManPowerSales {
    constructor(
        data = {
            id: "",
            asm: "",
            sm: "",
            bulan: "",
            outlet: "",
            spv: "",
            sales_name: "",
            photo: "",
            gender: "",
            join_date: "",
            sales_grading: 0,
            target_leads: 0,
            actual_leads: 0,
            target_testdrive: 0,
            actual_testdrive: 0,
            target_spk: 0,
            actual_spk: 0,
            target_do: 0,
            actual_do: 0,
            total_score: 0,
            sales_grade: "",
            prod: 0,
            contribution: "",
            total_product_knowledge: 0,
        }
    ) {
        const { id, asm, sm, bulan, outlet, spv, sales_name, photo, gender, join_date, sales_grading, target_leads, actual_leads, target_testdrive, actual_testdrive, target_spk, actual_spk, target_do, actual_do, total_score, sales_grade, prod, contribution, total_product_knowledge  } = data;
        this["id"] = id;
        this["asm"] = asm;
        this["sm"] = sm;
        this["bulan"] = bulan;
        this["outlet"] = outlet;
        this["spv"] = spv;
        this["sales_name"] = sales_name;
        this["photo"] = photo;
        this["gender"] = gender;
        this["join_date"] = join_date;
        this["sales_grading"] = sales_grading;
        this["target_leads"] = target_leads;
        this["actual_leads"] = actual_leads;
        this["target_testdrive"] = target_testdrive;
        this["actual_testdrive"] = actual_testdrive;
        this["target_spk"] = target_spk;
        this["actual_spk"] = actual_spk;
        this["target_do"] = target_do;
        this["actual_do"] = actual_do;
        this["total_score"] = total_score;
        this["sales_grade"] = sales_grade;
        this["prod"] = prod;
        this["contribution"] = contribution;
        this["total_product_knowledge"] = total_product_knowledge;
        this["excel_salesCompetention"] = "";
    }

    async getdata(this: any) {
        const { id, asm, sm, bulan, outlet, spv, sales_name, photo, gender, join_date, sales_grading, target_leads, actual_leads, target_testdrive, actual_testdrive, target_spk, actual_spk, target_do, actual_do, total_score, sales_grade, prod, contribution, total_product_knowledge } = this;
        return [{
            header: "ID",
            id: "id",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: id,
            enableHiding: true,
        },{
            header: "ASM",
            id: "asm",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: asm,
            enableHiding: true,
        },
        {
            header: "SM",
            id: "sm",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: sm,
            enableHiding: true,
        },{
            header: "Outlet",
            id: "outlet",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: outlet,
            enableHiding: true,
        },
        {
            header: "Date",
            id: "bulan",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: bulan,
            enableHiding: true,
        },
        {
            header: "SPV",
            id: "spv",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: spv,
            enableHiding: true,
        }, {
            header: "Nama Sales",
            id: "sales_name",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: sales_name,
            enableHiding: true,
        }, {
            header: "Gender",
            id: "gender",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: gender,
            enableHiding: true,
        },{
            header: "Sales Grading",
            id: "sales_grading",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: sales_grading,
            enableHiding: true,
        },{
            header: "Joined Date",
            id: "join_date",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: join_date,
            enableHiding: true,
        }, {
            header: "Target Leads",
            id: "target_leads",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: target_leads,
            enableHiding: true,
        }, {
            header: "Actual Leads",
            id: "actual_leads",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: actual_leads,
            enableHiding: true,
        }, {
            header: "Target Test Drive",
            id: "target_testdrive",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: target_testdrive,
            enableHiding: true,
        }, {
            header: "Actual Test Drive",
            id: "actual_testdrive",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: actual_testdrive,
            enableHiding: true,
        }, {
            header: "Target SPK",
            id: "target_spk",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: target_spk,
            enableHiding: true,
        }, {
            header: "Actual SPK",
            id: "actual_spk",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: actual_spk,
            enableHiding: true,
        }, {
            header: "Target DO",
            id: "target_do",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: target_do,
            enableHiding: true,
        }, {
            header: "Actual DO",
            id: "actual_do",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: actual_do,
            enableHiding: true,
        }, {
            header: "Total Score",
            id: "total_score",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: total_score,
            enableHiding: true,
        }, {
            header: "Sales Grade",
            id: "sales_grade",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: sales_grade,
            enableHiding: true,
        }, {
            header: "Productivity",
            id: "prod",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: prod,
            enableHiding: true,
        }, {
            header: "Contribution",
            id: "contribution",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: contribution,
            enableHiding: true,
        },
        {
            header: "Total Product Knowledge",
            id: "total_product_knowledge",
            value: numberWithCommas(parseFloat(total_product_knowledge)),
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }];
    }
}