import { Text2Color, TextColor, background2Color, backgroundColorCell, backgroundColorHSLDarkToLight, badgeUpDownPercent, progressBarLine, spanNumberWithPoint, spanString } from "../../../_metronic/helpers/components/MaterialReactTable/ComponentStyleRowsTable";

export class InternalInformationSPKModel {
    constructor(
        data = {
            model: "",
            spk_old_year: "",
            sum_spk_old_year: "",
            spk_new_year: "",
            sum_spk_year_now: "",
            growth: null,
            sum_growth: 0,
            share_old_year: 0,
            sum_share_old_year: 0,
            share_year_now: 0,
            sum_share_year_now: 0,
            year: 0,
            max_spk_old_year: 0,
            max_spk_year_now: 0,
            max_share_old_year: 0,
            max_share_year_now: 0,

        }
    ) {
        const { model, spk_old_year, sum_spk_old_year, spk_new_year, sum_spk_year_now, growth, sum_growth, share_old_year, sum_share_old_year, share_year_now, sum_share_year_now, year, max_spk_old_year, max_spk_year_now, max_share_old_year, max_share_year_now } = data;
        this["model"] = model;
        this["spk_old_year"] = spk_old_year;
        this["sum_spk_old_year"] = sum_spk_old_year;
        this["spk_new_year"] = spk_new_year;
        this["sum_spk_year_now"] = sum_spk_year_now;
        this["growth"] = growth;
        this["sum_growth"] = sum_growth;
        this["share_old_year"] = share_old_year;
        this["sum_share_old_year"] = sum_share_old_year;
        this["share_year_now"] = share_year_now;
        this["sum_share_year_now"] = sum_share_year_now;
        this["year"] = year;
        this["max_spk_old_year"] = max_spk_old_year;
        this["max_spk_year_now"] = max_spk_year_now;
        this["max_share_old_year"] = max_share_old_year;
        this["max_share_year_now"] = max_share_year_now;
    }
    async getdata(this: any) {
        const { model, spk_old_year, sum_spk_old_year, spk_new_year, sum_spk_year_now, growth, sum_growth, share_old_year, sum_share_old_year, share_year_now, sum_share_year_now, year, max_spk_old_year, max_spk_year_now, max_share_old_year, max_share_year_now } = this;
        return [{
            header: "Model",
            id: "model",
            filterVariant: "select",
            value: model,
            enableColumnOrdering: true,
            size: 120,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: `${year - 1} SPK`,
            id: "spk_old_year",
            value: spk_old_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_spk_old_year, '#297f9d');
            },
            enableColumnOrdering: true,
            size: 130,
            footer: spanNumberWithPoint(sum_spk_old_year, false)
        }, {
            header: `${year} SPK`,
            id: "spk_new_year",
            value: spk_new_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_spk_year_now, '#fcc47c');
            },
            enableColumnOrdering: true,
            size: 130,
            footer: spanNumberWithPoint(sum_spk_year_now, false)
        }, {
            header: "Growth",
            id: "growth",
            value: growth,
            Cell: ({ cell }) => {
                return Text2Color(cell.getValue(), 0, ['text-success', 'text-danger'])
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: background2Color(cell.getValue(), 0, ['#c8e6c9', '#f4c7c3'])
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_growth, true)
        }, {
            header: `${year - 1} comp`,
            id: "share_old_year",
            value: share_old_year,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["196", "59%", "39%"], cell.getValue(), max_share_old_year)
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_share_old_year, true)
        }, {
            header: `${year} comp`,
            id: "share_year_now",
            value: share_year_now,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["34", "96%", "74%"], cell.getValue(), max_share_year_now)
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_share_year_now, true)
        }];
    }
}

export class InternalInformationDOModel {
    constructor(
        data = {
            model: "",
            do_old_year: "",
            do_new_year: "",
            growth: "",
            share_old_year: "",
            share_year_now: null,
            sum_do_old_year: 0,
            sum_do_year_now: 0,
            sum_growth: 0,
            sum_share_old_year: 0,
            sum_share_year_now: 0,
            year: 0,
            max_do_old_year: 0,
            max_do_year_now: 0,
            max_share_old_year: 0,
            max_share_year_now: 0,
        }
    ) {
        const { model, do_old_year, do_new_year, growth, share_old_year, share_year_now, sum_do_old_year, sum_do_year_now, sum_growth, sum_share_old_year, sum_share_year_now, year, max_do_old_year, max_do_year_now, max_share_old_year, max_share_year_now } = data;

        this["model"] = model;
        this["do_old_year"] = do_old_year;
        this["do_new_year"] = do_new_year;
        this["growth"] = growth;
        this["share_old_year"] = share_old_year;
        this["share_year_now"] = share_year_now;
        this["sum_do_old_year"] = sum_do_old_year;
        this["sum_do_year_now"] = sum_do_year_now;
        this["sum_growth"] = sum_growth;
        this["sum_share_old_year"] = sum_share_old_year;
        this["sum_share_year_now"] = sum_share_year_now;
        this["year"] = year;
        this["max_do_old_year"] = max_do_old_year;
        this["max_do_year_now"] = max_do_year_now;
        this["max_share_old_year"] = max_share_old_year;
        this["max_share_year_now"] = max_share_year_now;
    }
    async getdata(this: any) {
        const { model, do_old_year, do_new_year, growth, share_old_year, share_year_now, sum_do_old_year, sum_do_year_now, sum_growth, sum_share_old_year, sum_share_year_now, year, max_do_old_year, max_do_year_now, max_share_old_year, max_share_year_now } = this;
        return [{
            header: "Model",
            id: "model",
            filterVariant: "select",
            value: model,
            enableColumnOrdering: true,
            size: 120,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: `${year - 1} DO`,
            id: "do_old_year",
            value: do_old_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_do_old_year, '#297f9d');
            },
            enableColumnOrdering: true,
            size: 130,
            footer: spanNumberWithPoint(sum_do_old_year, false)
        }, {
            header: `${year} DO`,
            id: "do_new_year",
            value: do_new_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_do_year_now, '#fcc47c');
            },
            enableColumnOrdering: true,
            size: 130,
            footer: spanNumberWithPoint(sum_do_year_now, false)
        }, {
            header: "Growth",
            id: "growth",
            value: growth,
            Cell: ({ cell }) => {
                return Text2Color(cell.getValue(), 0, ['text-success', 'text-danger'])
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: background2Color(cell.getValue(), 0, ['#c8e6c9', '#f4c7c3'])
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_growth, true)
        }, {
            header: `${year - 1} comp`,
            id: "share_old_year",
            value: share_old_year,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["196", "59%", "39%"], cell.getValue(), max_share_old_year)
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_share_old_year, true)
        }, {
            header: `${year} comp`,
            id: "share_year_now",
            value: share_year_now,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["34", "96%", "74%"], cell.getValue(), max_share_year_now)
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_share_year_now, true)
        }];
    }
}

export class InternalInformationRSModel {
    constructor(
        data = {
            model: "",
            rs_old_year: "",
            sum_rs_old_year: "",
            rs_new_year: "",
            sum_rs_year_now: "",
            growth: null,
            sum_growth: 0,
            share_old_year: 0,
            sum_share_old_year: 0,
            share_year_now: 0,
            sum_share_year_now: 0,
            year: 0,
            max_rs_old_year: 0,
            max_rs_year_now: 0,
            max_share_old_year: 0,
            max_share_year_now: 0,
        }
    ) {
        const { model, rs_old_year, sum_rs_old_year, rs_new_year, sum_rs_year_now, growth, sum_growth, share_old_year, sum_share_old_year, share_year_now, sum_share_year_now, year, max_rs_old_year, max_rs_year_now, max_share_old_year, max_share_year_now } = data;

        this["model"] = model;
        this["rs_old_year"] = rs_old_year;
        this["sum_rs_old_year"] = sum_rs_old_year;
        this["rs_new_year"] = rs_new_year;
        this["sum_rs_year_now"] = sum_rs_year_now;
        this["growth"] = growth;
        this["sum_growth"] = sum_growth;
        this["share_old_year"] = share_old_year;
        this["sum_share_old_year"] = sum_share_old_year;
        this["share_year_now"] = share_year_now;
        this["sum_share_year_now"] = sum_share_year_now;
        this["year"] = year;
        this["max_rs_old_year"] = max_rs_old_year;
        this["max_rs_year_now"] = max_rs_year_now;
        this["max_share_old_year"] = max_share_old_year;
        this["max_share_year_now"] = max_share_year_now;
    }
    async getdata(this: any) {
        const { model, rs_old_year, sum_rs_old_year, rs_new_year, sum_rs_year_now, growth, sum_growth, share_old_year, sum_share_old_year, share_year_now, sum_share_year_now, year, max_rs_old_year, max_rs_year_now, max_share_old_year, max_share_year_now } = this;
        return [{
            header: "Model",
            id: "model",
            filterVariant: "select",
            value: model,
            enableColumnOrdering: true,
            size: 120,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: `${year - 1} RS`,
            id: "rs_old_year",
            value: rs_old_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_rs_old_year, '#297f9d');
            },
            enableColumnOrdering: true,
            size: 130,
            footer: spanNumberWithPoint(sum_rs_old_year, false)
        }, {
            header: `${year} RS`,
            id: "rs_new_year",
            value: rs_new_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_rs_year_now, '#fcc47c');
            },
            enableColumnOrdering: true,
            size: 130,
            footer: spanNumberWithPoint(sum_rs_year_now, false)
        }, {
            header: "Growth",
            id: "growth",
            value: growth,
            Cell: ({ cell }) => {
                return Text2Color(cell.getValue(), 0, ['text-success', 'text-danger'])
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: background2Color(cell.getValue(), 0, ['#c8e6c9', '#f4c7c3'])
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_growth, true)
        }, {
            header: `${year - 1} comp`,
            id: "share_old_year",
            value: share_old_year,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["196", "59%", "39%"], cell.getValue(), max_share_old_year)
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_share_old_year, true)
        }, {
            header: `${year} comp`,
            id: "share_year_now",
            value: share_year_now,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["34", "96%", "74%"], cell.getValue(), max_share_year_now)
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_share_year_now, true)
        }];
    }
}

export class InternalInformationMsizeModel {
    constructor(
        data = {
            segment: "",
            ms_old_year: "",
            ms_new_year: "",
            growth: "",
            share_old_year: "",
            share_year_now: null,
            sum_ms_old_year: 0,
            sum_ms_year_now: 0,
            sum_growth: 0,
            sum_share_old_year: 0,
            sum_share_year_now: 0,
            year: 0,
            max_ms_old_year: 0,
            max_ms_year_now: 0,
            max_share_old_year: 0,
            max_share_year_now: 0,
        }
    ) {
        const {
            segment,
            ms_old_year,
            ms_new_year,
            growth,
            share_old_year,
            share_year_now,
            sum_ms_old_year,
            sum_ms_year_now,
            sum_growth,
            sum_share_old_year,
            sum_share_year_now,
            year,
            max_ms_old_year,
            max_ms_year_now,
            max_share_old_year,
            max_share_year_now
        } = data;

        this["segment"] = segment;
        this["ms_old_year"] = ms_old_year;
        this["ms_new_year"] = ms_new_year;
        this["growth"] = growth;
        this["share_old_year"] = share_old_year;
        this["share_year_now"] = share_year_now;
        this["sum_ms_old_year"] = sum_ms_old_year;
        this["sum_ms_year_now"] = sum_ms_year_now;
        this["sum_growth"] = sum_growth;
        this["sum_share_old_year"] = sum_share_old_year;
        this["sum_share_year_now"] = sum_share_year_now;
        this["year"] = year;
        this["max_ms_old_year"] = max_ms_old_year;
        this["max_ms_year_now"] = max_ms_year_now;
        this["max_share_old_year"] = max_share_old_year;
        this["max_share_year_now"] = max_share_year_now;
    }
    async getdata(this: any) {
        const {
            segment,
            ms_old_year,
            ms_new_year,
            growth,
            share_old_year,
            share_year_now,
            sum_ms_old_year,
            sum_ms_year_now,
            sum_growth,
            sum_share_old_year,
            sum_share_year_now,
            year,
            max_ms_old_year,
            max_ms_year_now,
            max_share_old_year,
            max_share_year_now
        } = this;
        return [{
            header: "Segment",
            id: "segment",
            filterVariant: "select",
            value: segment,
            enableColumnOrdering: true,
            size: 120,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: `${year - 1} M.Size`,
            id: "msize_old_year",
            value: ms_old_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_ms_old_year, '#297f9d');
            },
            enableColumnOrdering: true,
            size: 130,
            footer: spanNumberWithPoint(sum_ms_old_year, false)
        }, {
            header: `${year} M.Size`,
            id: "ms_new_year",
            value: ms_new_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_ms_year_now, '#fcc47c');
            },
            enableColumnOrdering: true,
            size: 130,
            footer: spanNumberWithPoint(sum_ms_year_now, false)
        }, {
            header: "Growth",
            id: "growth",
            value: growth,
            Cell: ({ cell }) => {
                return Text2Color(cell.getValue(), 0, ['text-success', 'text-danger'])
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: background2Color(cell.getValue(), 0, ['#c8e6c9', '#f4c7c3'])
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_growth, true)
        }, {
            header: `${year - 1} comp`,
            id: "share_old_year",
            value: share_old_year,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["196", "59%", "39%"], cell.getValue(), max_share_old_year)
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_share_old_year, true)
        }, {
            header: `${year} comp`,
            id: "share_year_now",
            value: share_year_now,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["34", "96%", "74%"], cell.getValue(), max_share_year_now)
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_share_year_now, true)
        }];
    }
}

export class InternalInformationVolModel {
    constructor(
        data = {
            segment: "",
            ms_old_year: "",
            ms_new_year: "",
            growth: "",
            share_old_year: "",
            share_year_now: null,
            sum_ms_old_year: 0,
            sum_ms_year_now: 0,
            sum_growth: 0,
            sum_share_old_year: 0,
            sum_share_year_now: 0,
            year: 0,
            max_vol_old_year: 0,
            max_vol_year_now: 0,
            max_share_old_year: 0,
            max_share_year_now: 0,
        }
    ) {
        const {
            segment,
            ms_old_year,
            ms_new_year,
            growth,
            share_old_year,
            share_year_now,
            sum_ms_old_year,
            sum_ms_year_now,
            sum_growth,
            sum_share_old_year,
            sum_share_year_now,
            year,
            max_vol_old_year,
            max_vol_year_now,
            max_share_old_year,
            max_share_year_now
        } = data;

        this["segment"] = segment;
        this["ms_old_year"] = ms_old_year;
        this["ms_new_year"] = ms_new_year;
        this["growth"] = growth;
        this["share_old_year"] = share_old_year;
        this["share_year_now"] = share_year_now;
        this["sum_ms_old_year"] = sum_ms_old_year;
        this["sum_ms_year_now"] = sum_ms_year_now;
        this["sum_growth"] = sum_growth;
        this["sum_share_old_year"] = sum_share_old_year;
        this["sum_share_year_now"] = sum_share_year_now;
        this["year"] = year;
        this["max_vol_old_year"] = max_vol_old_year;
        this["max_vol_year_now"] = max_vol_year_now;
        this["max_share_old_year"] = max_share_old_year;
        this["max_share_year_now"] = max_share_year_now;
    }
    async getdata(this: any) {
        const {
            segment,
            ms_old_year,
            ms_new_year,
            growth,
            share_old_year,
            share_year_now,
            sum_ms_old_year,
            sum_ms_year_now,
            sum_growth,
            sum_share_old_year,
            sum_share_year_now,
            year,
            max_vol_old_year,
            max_vol_year_now,
            max_share_old_year,
            max_share_year_now
        } = this;
        return [{
            header: "Segment",
            id: "segment",
            filterVariant: "select",
            value: segment,
            enableColumnOrdering: true,
            size: 120,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: `${year - 1} Vol`,
            id: "msize_old_year",
            value: ms_old_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_vol_old_year, '#297f9d');
            },
            enableColumnOrdering: true,
            size: 130,
            footer: spanNumberWithPoint(sum_ms_old_year, false)
        }, {
            header: `${year} Vol`,
            id: "ms_new_year",
            value: ms_new_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_vol_year_now, '#fcc47c');
            },
            enableColumnOrdering: true,
            size: 130,
            footer: spanNumberWithPoint(sum_ms_year_now, false)
        }, {
            header: "Growth",
            id: "growth",
            value: growth,
            Cell: ({ cell }) => {
                return Text2Color(cell.getValue(), 0, ['text-success', 'text-danger'])
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: background2Color(cell.getValue(), 0, ['#c8e6c9', '#f4c7c3'])
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_growth, true)
        }, {
            header: `${year - 1} comp`,
            id: "share_old_year",
            value: share_old_year,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["196", "59%", "39%"], cell.getValue(), max_share_old_year)
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_share_old_year, true)
        }, {
            header: `${year} comp`,
            id: "share_year_now",
            value: share_year_now,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["34", "96%", "74%"], cell.getValue(), max_share_year_now)
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_share_year_now, true)
        }];
    }
}
export class InternalInformationMSModel {
    constructor(
        data = {
            segment: "",
            ms_old_year: "",
            ms_new_year: "",
            growth: "",
            share_old_year: "",
            share_year_now: null,
            sum_ms_old_year: 0,
            sum_ms_year_now: 0,
            sum_growth: 0,
            sum_share_old_year: 0,
            sum_share_year_now: 0,
            year: 0,
            max_msg_old_year: 0,
            max_msg_year_now: 0,
        }
    ) {
        const {
            segment,
            ms_old_year,
            ms_new_year,
            growth,
            share_old_year,
            share_year_now,
            sum_ms_old_year,
            sum_ms_year_now,
            sum_growth,
            sum_share_old_year,
            sum_share_year_now,
            year,
            max_msg_old_year,
            max_msg_year_now,
        } = data;

        this["segment"] = segment;
        this["ms_old_year"] = ms_old_year;
        this["ms_new_year"] = ms_new_year;
        this["growth"] = growth;
        this["share_old_year"] = share_old_year;
        this["share_year_now"] = share_year_now;
        this["sum_ms_old_year"] = sum_ms_old_year;
        this["sum_ms_year_now"] = sum_ms_year_now;
        this["sum_growth"] = sum_growth;
        this["sum_share_old_year"] = sum_share_old_year;
        this["sum_share_year_now"] = sum_share_year_now;
        this["year"] = year;
        this["max_msg_old_year"] = max_msg_old_year;
        this["max_msg_year_now"] = max_msg_year_now;
    }
    async getdata(this: any) {
        const {
            segment,
            ms_old_year,
            ms_new_year,
            growth,
            sum_ms_old_year,
            sum_ms_year_now,
            sum_growth,
            year,
            max_msg_old_year,
            max_msg_year_now,
        } = this;
        return [{
            header: "Segment",
            id: "segment",
            filterVariant: "select",
            value: segment,
            enableColumnOrdering: true,
            size: 120,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: `${year - 1} M/S`,
            id: "msize_old_year",
            value: ms_old_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_msg_old_year, '#297f9d');
            },
            enableColumnOrdering: true,
            size: 130,
            footer: spanNumberWithPoint(sum_ms_old_year, false)
        }, {
            header: `${year} M/S`,
            id: "ms_new_year",
            value: ms_new_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_msg_year_now, '#fcc47c');
            },
            enableColumnOrdering: true,
            size: 130,
            footer: spanNumberWithPoint(sum_ms_year_now, false)
        }, {
            header: "M/S Growth",
            id: "growth",
            value: growth,
            Cell: ({ cell }) => {
                return Text2Color(cell.getValue(), 0, ['text-success', 'text-danger'])
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: background2Color(cell.getValue(), 0, ['#c8e6c9', '#f4c7c3'])
                    },
                    align: 'center',
                })
            },
            enableColumnOrdering: true,
            size: 60,
            footer: spanNumberWithPoint(sum_growth, true)
        }];
    }
}