import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
export class ListSpkProcess {
    constructor(data = {
        cabang: "",
        spv: "",
        sales: "",
        status: "",
        nama_customer: "",
        type: "",
        leasing: "",
        dp_percent: "",
        tgl_leads: "",
        tgl_spk: "",
        tgl_collect_document: "",
        tgl_survey: "",
        tgl_input_system: "",
        decision: "",
        tgl_decision: "",
        appeal: "",
        tgl_appeal: "",
        tgl_po: "",
        tgl_dp: "",
        tgl_do: "",
    }) {
        const { cabang, spv, sales, status, nama_customer, type, leasing, dp_percent, tgl_leads, tgl_spk, tgl_collect_document, tgl_survey, tgl_input_system, decision, tgl_decision, appeal, tgl_appeal, tgl_po, tgl_dp, tgl_do } = data
        this["cabang"] = cabang
        this["spv"] = spv
        this["sales"] = sales
        this["status"] = status
        this["nama_customer"] = nama_customer
        this["type"] = type
        this["leasing"] = leasing
        this["dp_percent"] = dp_percent
        this["tgl_leads"] = tgl_leads
        this["tgl_spk"] = tgl_spk
        this["tgl_collect_document"] = tgl_collect_document
        this["tgl_survey"] = tgl_survey
        this["tgl_input_system"] = tgl_input_system
        this["decision"] = decision
        this["tgl_decision"] = tgl_decision
        this["appeal"] = appeal
        this["tgl_appeal"] = tgl_appeal
        this["tgl_po"] = tgl_po
        this["tgl_dp"] = tgl_dp
        this["tgl_do"] = tgl_do
    }
    async getdata(this: any) {
        const { cabang, spv, sales, status, nama_customer, type, leasing, dp_percent, tgl_leads, tgl_spk, tgl_collect_document, tgl_survey, tgl_input_system, decision, tgl_decision, appeal, tgl_appeal, tgl_po, tgl_dp, tgl_do } = this
        return [{
            header: "cabang",
            id: "cabang",
            filterVariant: "select",
            value: cabang,
            size: 60,
            // footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        },
        {
            header: "SPV",
            id: "spv",
            filterVariant: "select",
            value: spv,
            size: 60,
        },
        {
            header: "Sales",
            id: "sales",
            filterVariant: "select",
            value: sales,
            size: 60,
        },
        {
            header: "Status",
            id: "status",
            filterVariant: "select",
            value: status,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        color: cell.getValue() === "Rejected" ? "red" : "green",
                    }
                })
            },
            size: 100,
        },
        {
            header: "Nama Customer",
            id: "nama_customer",
            filterVariant: "select",
            value: nama_customer,
            size: 60,
        },
        {
            header: "Type",
            id: "type",
            filterVariant: "select",
            value: type,
            size: 60,
        },
        {
            header: "Leasing",
            id: "leasing",
            filterVariant: "select",
            value: leasing,
            size: 60,
        },
        {
            header: "DP",
            id: "dp_percent",
            filterVariant: "select",
            value: dp_percent,
            size: 60,
        },
        {
            header: "Tgl Leads",
            id: "tgl_leads",
            filterVariant: "select",
            value: tgl_leads,
            size: 60,
        },
        {
            header: "Tgl SPK",
            id: "tgl_spk",
            filterVariant: "select",
            value: tgl_spk,
            size: 60,
        },
        {
            header: "Tgl Collect Document",
            id: "tgl_collect_document",
            filterVariant: "select",
            value: tgl_collect_document,
            size: 60,
        },
        {
            header: "Tgl Survey",
            id: "tgl_survey",
            filterVariant: "select",
            value: tgl_survey,
            size: 60,
        },
        {
            header: "Tgl Input System",
            id: "tgl_input_system",
            filterVariant: "select",
            value: tgl_input_system,
            size: 60,
        },
        {
            header: "Decision",
            id: "decision",
            filterVariant: "select",
            value: decision,
            size: 60,
        },
        {
            header: "Tgl Decision",
            id: "tgl_decision",
            filterVariant: "select",
            value: tgl_decision,
            size: 60,
        },
        {
            header: "Appeal",
            id: "appeal",
            filterVariant: "select",
            value: appeal,
            size: 60,
        },
        {
            header: "Tgl Appeal",
            id: "tgl_appeal",
            filterVariant: "select",
            value: tgl_appeal,
            size: 60,
        },
        {
            header: "Tgl PO",
            id: "tgl_po",
            filterVariant: "select",
            value: tgl_po,
            size: 60,
        },
        {
            header: "Tgl DP",
            id: "tgl_dp",
            filterVariant: "select",
            value: tgl_dp,
            size: 60,
        },
        {
            header: "Tgl DO",
            id: "tgl_do",
            filterVariant: "select",
            value: tgl_do,
            size: 60,
        },
        ]
    }
};

const bgColor = (val: string | undefined) => {
     switch (val) {
        case "Reject":
            return "#f44336"
        case "DO":
            return "#4daf50"
        case "PO":
            return "#4daf50"
        case "Approve":
            return "#4daf50"
        case "Approve (Deposit)":
            return "#2296f3"
        case "Lunas DP":
            return "#4daf50"
        case "SPK":
            return "#b7b7b7"
        case "Submit Order":
            return "#b7b7b7"
        case "CA PROCESS":
            return "#ffc119"
        case "Surveyed":
            return "#ffc119"
        case "Completed Document":
            return "#ffc119"
        case "Collect Document":
            return "#ffc119"
        default:
            return "transparent"
    }
}

//example data type
type List = {
    cabang: string;
    spv: string;
    sales: string;
    status: string;
    nama_customer: string;
    type: string;
    leasing: string;
    dp_percent: string;
    tgl_leads: string;
    tgl_spk: string;
    tgl_collect_document: string;
    tgl_survey: string;
    tgl_input_system: string;
    decision: string;
    tgl_decision: string;
    appeal: string;
    tgl_appeal: string;
    tgl_po: string;
    tgl_dp: string;
    tgl_do: string;
}

export const ListSpk = ({ data }) => {
  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<List>[]>(
    () => [
      {
        accessorKey: 'cabang', 
        header: 'Cabang',
        size: 60,
      },
      {
        accessorKey: 'spv', 
        header: 'SPV',
        size: 60,
      },
      {
        accessorKey: 'sales', 
        header: 'Sales',
        size: 60,
      },
      {
        accessorKey: 'status', 
        header: 'Status',
        size: 60,
        muiTableBodyCellProps: ({ cell }) => {
            return ({
                sx: {
                    backgroundColor: bgColor(cell.getValue() as string),
                }
            })
        },
      },
      {
        accessorKey: 'nama_customer', 
        header: 'Nama',
        size: 60,
      },
      {
        accessorKey: 'type', 
        header: 'Type',
        size: 60,
      },
      {
        accessorKey: 'leasing', 
        header: 'Leasing',
        size: 60,
      },
      {
        accessorKey: 'dp_percent', 
        header: 'DP (%)',
        size: 60,
      },
      {
        accessorKey: 'tgl_leads', 
        header: 'Tgl Leads',
        size: 60,
      },
      {
        accessorKey: 'tgl_spk', 
        header: 'Tgl SPK',
        size: 60,
      },
      {
        accessorKey: 'tgl_collect_document', 
        header: 'Tgl Collect Document',
        size: 60,
      },
      {
        accessorKey: 'tgl_survei', 
        header: 'Tgl Survey',
        size: 60,
      },
      {
        accessorKey: 'tgl_input_system', 
        header: 'Tgl Input System',
        size: 60,
      },
      {
        accessorKey: 'decision', 
        header: 'Decision',
        size: 60,
      },
      {
        accessorKey: 'tgl_decision', 
        header: 'Tgl Decision',
        size: 60,
      },
      {
        accessorKey: 'appeal', 
        header: 'Appeal',
        size: 60,
      },
      {
        accessorKey: 'tgl_appeal', 
        header: 'Tgl Appeal',
        size: 60,
      },
      {
        accessorKey: 'tgl_po', 
        header: 'Tgl PO',
        size: 60,
      },
      {
        accessorKey: 'tgl_dp', 
        header: 'Tgl DP',
        size: 60,
      },
      {
        accessorKey: 'tgl_do', 
        header: 'Tgl DO',
        size: 60,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    muiTableContainerProps: { sx: { maxHeight: '400px' } },
    enableStickyHeader: true,
    muiTableBodyRowProps: { hover: false },
    memoMode: 'cells',
    state: {
        isLoading: data ? false : true, //cell skeletons and loading overlay
      },
  });

  return <MaterialReactTable table={table}/>;
};