// import { useIntl } from "react-intl";
import React from "react";
import { MenuInnerAPI } from "../../../../../app/api/MenuInnerAPI";
import { useAuth } from "../../../../../app/pages/auth";
import { MenuItemCustome } from "./MenuItemCustome";

export function MenuInner() {
  const { currentUser } = useAuth();
  const ListMenuInner = [{
    title: "Sales",
    icon: "chart-line-up",
    to: "/sales",
    menu_inner: "sales",
    length: currentUser?.[0]['listCountInner']['sales'],
  }, {
    title: "After Sales",
    icon: "gear",
    to: "/after-sales",
    menu_inner: "after_sales",
    length: currentUser?.[0]['listCountInner']['after_sales'],
  }, {
    title: "Financial",
    icon: "dollar",
    to: "/financial",
    menu_inner: "financial",
    length: currentUser?.[0]['listCountInner']['financial'],
  }, {
    title: "Tax",
    icon: "tablet-book",
    to: "/tax",
    menu_inner: "tax",
    length: currentUser?.[0]['listCountInner']['tax'],
  }, {
    title: "Accounting",
    icon: "bank",
    to: "/accounting",
    menu_inner: "accounting",
    length: currentUser?.[0]['listCountInner']['accounting'],
  }, {
    title: "Login History",
    icon: "people",
    to: "/login-history",
    menu_inner: "login_history",
    length: currentUser?.[0]['listCountInner']['login_history'],
  }];
  let menuFilter = ListMenuInner.filter((item) => item['length'] > 0);
  return (
    <>
      {currentUser?.[0]['id_roles'] === 1 && (
        <MenuItemCustome title="System Setting" icon="setting-2" to="/dashboard" active={currentUser?.[0]['menu_inner'] === null}
          onClick={async () => {
            await new MenuInnerAPI().update({ id: currentUser?.[0]['id'], menu_inner: null }).then((res) => {
              setTimeout(async () => {
                window.location.reload();
              }, 500);
            });
          }}
        />
      )}

      {menuFilter.map((val, i) => {
        return (
          <React.Fragment key={i}>
            <MenuItemCustome title={val['title']} icon={val['icon']} to={val['to']} active={currentUser?.[0]['menu_inner'] === val['menu_inner']}
              onClick={async () => {
                await new MenuInnerAPI().update({ id: currentUser?.[0]['id'], menu_inner: val['menu_inner'] }).then((res) => {
                  setTimeout(async () => {
                    window.location.reload();
                  }, 500);
                });
              }}
            />
          </React.Fragment>
        )
      })
      }
    </>
  );
}
