import { fetchingGetData, multiPartFilesFetching } from "./API";

class FilterSegmentAPI {
  async getSegment() {
    let res = await fetchingGetData({
      url: `select/segment/market_share`,
      method: "get",
    });
    return res;
  }

  async getModel () {
    let res = await fetchingGetData({
      url: `select/model/market_share`,
      method: "get",
    });
    return res;
  }
}
class OverviewAPI {
  // Get Table Overview
  async overviewTable(cov,segment) {
    let res = await fetchingGetData({
      url: `market_share/overview/dealer/table?coverage=${cov || ""}&segment=${segment || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }

  async topTen(cov,segment) {
    let res = await fetchingGetData({
      url: `market_share/overview/dealer/table/top?coverage=${cov || ""}&segment=${segment || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }

  async bottomTen(cov,segment) {
    let res = await fetchingGetData({
      url: `market_share/overview/dealer/table/bottom?coverage=${cov || ""}&segment=${segment || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }
  async chartMSAll(cov,segment) {
    let res = await fetchingGetData({
      url: `market_share/overview/market/national?coverage=${cov || ""}&segment=${segment || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }

  async chartMSWuling(cov,segment) {
    let res = await fetchingGetData({
      url: `market_share/overview/market/internal?coverage=${cov || ""}&segment=${segment || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }
  async chartMarketGrowthAll(cov,segment) {
    let res = await fetchingGetData({
      url: `market_share/overview/growth/national?coverage=${cov || ""}&segment=${segment || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }

  async chartMarketGrowthWuling(cov,segment) {
    let res = await fetchingGetData({
      url: `market_share/overview/growth/internal?coverage=${cov || ""}&segment=${segment || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }
}

class SegmentAPI {
  async topBrand(cov, month) {
    let res = await fetchingGetData({
      url: `market_share/segment/brand/table/top?coverage=${cov || ""}&period=${month || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }
  async topModel(cov, month) {
    let res = await fetchingGetData({
      url: `market_share/segment/model/table/top?coverage=${cov || ""}&period=${month || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }
  async box(cov, month) {
    let res = await fetchingGetData({
      url: `market_share/segment/box?coverage=${cov || ""}&period=${month || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }
  async chartSegment(cov, month) {
    let res = await fetchingGetData({
      url: `market_share/segment/growth/chart?coverage=${cov || ""}&period=${month || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }
  async chartMSbrand(cov, month) {
    let res = await fetchingGetData({
      url: `market_share/segment/brand/piechart?coverage=${cov || ""}&period=${month || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }
  async donutSegment(cov, month) {
    let res = await fetchingGetData({
      url: `market_share/segment/model/chart?coverage=${cov || ""}&period=${month || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }
  async movementModel(cov,model) {
    let res = await fetchingGetData({
      url: `market_share/segment/model/movement?coverage=${cov || ""}&model=${model || ""}`,
      method: "get",
    });
    return res;
  }

  async movementSegment(cov) {
    let res = await fetchingGetData({
      url: `market_share/segment/segment/movement?coverage=${cov || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }
  async chartWulingVol(cov) {
    let res = await fetchingGetData({
      url: `market_share/segment/wuling/volume?coverage=${cov || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }

  async sparkLineVol(cov) {
    let res = await fetchingGetData({
      url: `market_share/market/wuling?coverage=${cov || ""}`,
      method: "get",
    });
    return res;
  }

  async filterModel () {
    let res = await fetchingGetData({
      url: `select/model/market_share`,
      method: "get",
    });
    return res;
  }
  
}

class PriceSegmentAPI {
  async priceRange(val) {
    let {island,province,asm,sm,kab,kec,period,segment,model} = val
    let res = await fetchingGetData({
      url: `market_share/price/segment/range?island=${island || ''}&province=${province || ''}&asm=${encodeURIComponent(asm) || ''}&sm=${sm || ''}&kab=${kab || ''}&kec=${kec || ''}&period=${period || ''}&segment=${segment || ''}&model=${model || ''}`,
      method: "get",
    });
    return res;
  }
  async priceRangeWuling(val) {
    let {island,province,asm,sm,kab,kec,period,segment,model} = val
    let res = await fetchingGetData({
      url: `market_share/price/segment/range_model?island=${island || ''}&province=${province || ''}&asm=${encodeURIComponent(asm) || ''}&sm=${sm || ''}&kab=${kab || ''}&kec=${kec || ''}&period=${period || ''}&segment=${segment || ''}&model=${model || ''}`,
      method: "get",
    });
    return res;
  }
  async priceRangeBrand(val) {
    let {island,province,asm,sm,kab,kec,period,segment,model} = val
    let res = await fetchingGetData({
      url: `market_share/price/segment/range_brands?island=${island || ''}&province=${province || ''}&asm=${encodeURIComponent(asm) || ''}&sm=${sm || ''}&kab=${kab || ''}&kec=${kec || ''}&period=${period || ''}&segment=${segment || ''}&model=${model || ''}`,
      method: "get",
    });
    return res;
  }
  async priceRangeType(val) {
    let {island,province,asm,sm,kab,kec,period,segment,model} = val
    let res = await fetchingGetData({
      url: `market_share/price/segment/range_types?island=${island || ''}&province=${province || ''}&asm=${encodeURIComponent(asm) || ''}&sm=${sm || ''}&kab=${kab || ''}&kec=${kec || ''}&period=${period || ''}&segment=${segment || ''}&model=${model || ''}`,
      method: "get",
    });
    return res;
  }
  async priceRangeDetails(val) {
    console.log("API VAL",val);
    let {island,province,asm,sm,kab,kec,period,segment,model} = val
    let res = await fetchingGetData({
      url: `market_share/price/segment/range_region?island=${island || ''}&province=${province || ''}&asm=${encodeURIComponent(asm) || ''}&sm=${sm || ''}&kab=${kab || ''}&kec=${kec || ''}&period=${period || ''}&segment=${segment || ''}&model=${model || ''}`,
      method: "get",
    });
    return res;
  }

  async filterPriceSegment(val) {
    let {island,province,kab} = val
    let res = await fetchingGetData({
      url: `select/price_segment?island=${island || ''}&province=${province || ''}&kab=${kab || ''}`,
      method: "get",
    });
    return res;
  }

  async getDataUpload() {
    let res = await fetchingGetData({
      url: `price_segment/market_share`,
      method: "get",
    });
    return res;
  }

  async UploadFilePriceSegment(file, onUploadProgress) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `activity_vs_market/upload`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }
}

export { OverviewAPI, SegmentAPI, PriceSegmentAPI, FilterSegmentAPI };
