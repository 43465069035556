import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, SideBarModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserBySideBar, getUserByToken } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  sideBar: SideBarModel[]
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  setSideBar: Dispatch<SetStateAction<SideBarModel[] | []>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  sideBar: [],
  setCurrentUser: () => { },
  setSideBar: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [sideBar, setSideBar] = useState<SideBarModel[] | []>([])
  const saveAuth = (auth: AuthModel | undefined) => {

    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    setSideBar([])
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, sideBar, setSideBar, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, setSideBar } = useAuth();
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string, id_roles: number, id: any) => {
      try {
        if (!didRequest.current) {
          const { data: user } = await getUserByToken(apiToken, id)
          if (user) {
            setCurrentUser(user);
          }
          const { data: menu } = await getUserBySideBar(id_roles, user[0]['menu_inner'], user[0]['alias_brand'])
          if (menu) {
            setSideBar(menu)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth['api_token'] && auth['id_roles']) {
      requestUser(auth['api_token'], auth['id_roles'], auth['id'])
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
