/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef } from "react";
import { useThemeMode } from "../../../../partials";
import { getCSS, getCSSVariableValue } from "../../../../assets/ts/_utils";
import { ThemeModeComponent } from "../../../../assets/ts/layout";
import * as Highcharts from 'highcharts';
import HighchartsMap from "highcharts/highmaps";
import map from "@highcharts/map-collection/countries/id/id-all.topo.json";
import { ColumnAverageRight, ColumnAverageTop } from "../ColumnAverageCard.tsx/ColumnAverange";
import exporting from "highcharts/modules/exporting";
import HighchartsMore from 'highcharts/highcharts-more';
import Annotations from "highcharts/modules/annotations";
exporting(Highcharts);
exporting(HighchartsMap);
Annotations(HighchartsMap);
HighchartsMore(Highcharts);


type Props = {
  id: string;
  type: "column_line" | "spline" | "pie" | "column_stacked" | "topology" | "topologyArea" | "bubble" | "pie_donut" | "spider_area" | "line" | 'column_range' | 'bar';
  title?: string;
  subTitle?: string;
  positionColumnData?: string | undefined;
  positionLegend?: "BottomCenter" | "TopCenter" | "TopRight" | undefined;
  stacking?: Highcharts.OptionsStackingValue,
  height?: string;
  series: Highcharts.SeriesOptionsType[] | undefined;
  categories?: [];
  data?: [];
  formatColumnPercent?: "percent0f" | "percent1f" | "percent2f" | undefined;
  pointEvents?: any
  chartStyle?: Highcharts.ChartOptions | undefined
  optionsSetting?: {
    colors?: (string | Highcharts.GradientColorObject | Highcharts.PatternObject)[] | undefined,
    xAxis?: Highcharts.XAxisOptions | Highcharts.XAxisOptions[] | undefined,
    yAxis?: Highcharts.YAxisOptions | Highcharts.YAxisOptions[] | undefined,
    tooltip?: Highcharts.TooltipOptions | undefined,
    legend?: Highcharts.LegendOptions | undefined,
    plotOptions?: Highcharts.PlotOptions | undefined,
    annotations?: Highcharts.AnnotationsOptions | undefined
  }
};

const HighchartsSample: FC<Props> = ({
  id,
  type,
  title = "",
  subTitle = "",
  positionColumnData = undefined,
  positionLegend = "BottomCenter",
  stacking = 'normal',
  height,
  series,
  categories,
  data,
  formatColumnPercent,
  pointEvents,
  chartStyle,
  optionsSetting
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  useEffect(() => {
    const chart = refreshChart();
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode, series, stacking, categories, data, formatColumnPercent, title, subTitle]);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }
    const heights = parseInt(getCSS(chartRef.current, "height"));
    let legend: any;
    if (positionLegend === "BottomCenter" || positionLegend === undefined) {
      legend = {
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal",
      };
    } else if (positionLegend === "TopCenter") {
      legend = {
        align: "center",
        verticalAlign: "top",
        layout: "horizontal",
      };
    } else if (positionLegend === "TopRight") {
      legend = {
        align: "right",
        verticalAlign: "top",
        layout: "vertical",
        x: 0,
        y: 100
      };
    }
    let chart: any;

    let ChartOptions = {
      ...chartStyle,
      height: heights,
      style: {
        fontSize: "10px",
        fontFamily: 'sans-serif'
      }, backgroundColor: 'transparent',
      events: {
        fullscreenOpen: function () {
          this['update']({
            chart: {
              backgroundColor: 'white'
            }
          })
        }, fullscreenClose: function () {
          this['update']({
            chart: {
              backgroundColor: 'transparent'
            }
          })
        }
      }
    }
    if (type === "column_line") {
      let formatDataLabelColumn = {};
      if (formatColumnPercent === "percent0f") {
        formatDataLabelColumn = {
          format: "{point.y:.0f}%",
        }
      } else if (formatColumnPercent === "percent1f") {
        formatDataLabelColumn = {
          format: "{point.y:.1f}%",
        }
      } else if (formatColumnPercent === "percent2f") {
        formatDataLabelColumn = {
          format: "{point.y:.2f}%",
        }
      }
      chart = new Highcharts.Chart(
        chartRef.current,
        getOptionsColumnLine(ChartOptions, title, legend, formatDataLabelColumn, series, categories, optionsSetting)
      );
    } else if (type === "spline") {
      chart = new Highcharts.Chart(
        chartRef.current,
        getOptionsSpline(ChartOptions, title, legend, series, categories)
      );
    } else if (type === "pie") {
      chart = new Highcharts.Chart(
        chartRef.current,
        getOptionsPie(ChartOptions, title, legend, series, optionsSetting)
      );
    } else if (type === "column_stacked") {
      chart = new Highcharts.Chart(
        chartRef.current,
        getOptionsColumnGroupStacked(ChartOptions, title, stacking, legend, series, categories, optionsSetting)
      );
    } else if (type === "topology") {
      chart = new HighchartsMap.MapChart(
        chartRef.current,
        getOptionsTopology(ChartOptions, title, series, optionsSetting)
      );
    } else if (type === "topologyArea") {
      chart = new HighchartsMap.MapChart(
        chartRef.current,
        getOptionsTopologyArea(ChartOptions, title, series)
      );
    } else if (type === "bubble") {
      chart = new Highcharts.Chart(
        chartRef.current,
        getOptionsBubble(ChartOptions, title, series, pointEvents)
      );
    } else if (type === "pie_donut") {
      chart = new Highcharts.Chart(
        chartRef.current,
        getOptionsPieDonut(ChartOptions, title, legend, series, optionsSetting)
      );
    } else if (type === "spider_area") {
      chart = new Highcharts.Chart(
        chartRef.current,
        getOptionsSpiderArea(ChartOptions, title, categories, series)
      );
    } else if (type === "line") {
      chart = new Highcharts.Chart(
        chartRef.current,
        getOptionsLine(ChartOptions, title, series, categories, optionsSetting)
      );
    } else if (type === "column_range") {
      chart = new Highcharts.Chart(
        chartRef.current,
        getOptionsColumnRange(ChartOptions, title, subTitle, series, categories, optionsSetting)
      );
    } else if (type === "bar") {
      chart = new Highcharts.Chart(
        chartRef.current,
        getOptionsBar(ChartOptions, title, subTitle, series, optionsSetting)
      );
    }
    if (chart) {
      chart.redraw();
    }
    return chart;
  };
  return (
    <>
      {/* begin::Body */}
      {positionColumnData === "top" ? (
        <ColumnAverageTop
          id={id}
          chartRef={chartRef}
          data={data}
          height={height}
        />
      ) : positionColumnData === "right" ? (
        <ColumnAverageRight
          id={id}
          chartRef={chartRef}
          data={data}
          height={height}
        />
      ) : (
        <div ref={chartRef} id={id} style={{ height: height }} />
      )}
      {/* end::Body */}
    </>
  );
};
export { HighchartsSample };
// Base Color Default Chart
const baseColors = [
  "#297f9d",
  "#fcc47c",
  "#43a047",
  "#bf392b",
  "#00897b",
  "#404040",
  "#e4e4e4",
  "#7030a0",
  "#384967",
  "#ceab13",
  "#e16a63",
  "#80bb7b",
  "#a67650",
  "#f7b781",
  "#59a7a7",
  "#7c459c",
  "#4c5d34",
  "#6c8cbf",
  "#872551",
  "#8c8751",
];
// Chart BarLine Options
function getOptionsColumnLine(
  chartOptions: any,
  title: string,
  legend: {},
  formatDataLabelColumn: {},
  series: any,
  categories?: [],
  optionsSetting?: any,
): Highcharts.Options {
  const labelColor = getCSSVariableValue("--bs-gray-dark");
  const labelColorForDark = getCSSVariableValue("--bs-gray-800");
  let max = 0;
  let data = series[0]?.["data"]?.concat(series[1]?.["data"]);
  data?.map((d: number) => {
    max = Math.max(max, d);
    return max;
  });

  let min = 0;
  data?.map((d: number) => {
    min = Math.min(min, d);
    return min;
  });

  let data2 = series[2]?.["data"];
  let min2 = 0;
  data2?.map((d: number) => {
    min2 = Math.min(min2, d);
    return min2;
  });

  let max2 = 0;
  data2?.map((d: number) => {
    max2 = Math.max(max2, d);
    return max2;
  });

  return {
    chart: chartOptions,
    // exporting: {
    //   enabled: true,
    // },
    credits: {
      enabled: false,
      text: "KumalaGroup.co.id",
      style: {
        fontFamily: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
    },
    colors: optionsSetting?.['colors'] ? optionsSetting['colors'] : baseColors,
    title: {
      text: title,
      align: "center",
      style: {
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
        font: 'bold 16px "serif"',
      },
    }, xAxis: [{
      crosshair: true,
      ...optionsSetting?.['xAxis'],
      categories: categories,
      labels: {
        style: {
          fontSize: "10px",
          color:
            ThemeModeComponent.getMode() === "dark"
              ? labelColorForDark
              : labelColor,
        },
      },
    }], yAxis: [{
      tickAmount: 7,
      min: -600,
      // min: min2 * 40,
      max: max2 + 1,
      visible: false,
      ...optionsSetting?.['yAxis'],
    }, {
      min: 0,
      max: max + 1,
      visible: false,
      opposite: true,
    }], tooltip: {
      split: false,
      shared: true,
      ...optionsSetting?.['tooltip'],
      style: {
        fontSize: "10px",
      },
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
    }, plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 2,
        groupPadding: 0.1,
        shadow: false,
        dataLabels: {
          ...formatDataLabelColumn,
          enabled: true,
          style: {
            fontSize: "10px",
            color:
              ThemeModeComponent.getMode() === "dark"
                ? labelColorForDark
                : labelColor,
          },
        },
      }, line: {
        lineWidth: 2.5,
        marker: {
          radius: 3.5,
        }, dataLabels: {
          enabled: true,
          format: "{point.y:.0f}%",
          style: {
            fontSize: "10px",
            color:
              ThemeModeComponent.getMode() === "dark"
                ? labelColorForDark
                : labelColor,
          },
        }
      },
    }, legend: {
      ...legend,
      ...optionsSetting?.['legend'],
      itemStyle: {
        font: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
      itemHoverStyle: {
        color: "gray",
      },
    },
    series: series,
    lang: {
      thousandsSep: ".",
      decimalPoint: ",",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ], shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    }, navigation: {
      menuItemStyle: {
        fontSize: "10px",
      },
    },
  };
}
// Chart Line
function getOptionsSpline(
  chartOptions: any,
  title: string,
  legend: {},
  series: any,
  categories?: []
): Highcharts.Options {
  let seriesData: any = [{
    type: "spline",
    name: "",
    data: [],
  }];
  for (let i = 0; i < series['title']?.length; i++) {
    seriesData.push({
      type: "spline",
      name: series['title'][i],
      data: series['values'][i],
      lineWidth: (series['title'][i] === "KUMALA") ? 5 : 2.5
    });
  }
  seriesData.shift();
  const labelColor = getCSSVariableValue("--bs-gray-dark");
  const labelColorForDark = getCSSVariableValue("--bs-gray-800");
  return {
    chart: chartOptions,
    // exporting: {
    //   enabled: true,
    // },
    credits: {
      enabled: false,
      text: "KumalaGroup.co.id",
      style: {
        fontFamily: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
    },
    colors: baseColors,
    title: {
      text: title,
      align: "center",
      style: {
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
        font: 'bold 16px "serif"',
      },
    }, subtitle: {
      text: "",
    }, legend: {
      ...legend,
      itemStyle: {
        font: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
      itemHoverStyle: {
        color: "gray",
      },
    }, xAxis: [{
      categories: categories,
      labels: {
        style: {
          fontSize: "10px",
          color:
            ThemeModeComponent.getMode() === "dark"
              ? labelColorForDark
              : labelColor,
        },
      },
      crosshair: true,
    }], yAxis: {
      visible: false,
      title: {
        text: "",
      },
    }, tooltip: {
      headerFormat: `<span style="font-size:12px"><b>{point.key}</b></span><br>`,
      shared: true,
    }, plotOptions: {
      series: {
        lineWidth: 2.5,
        cursor: "pointer",
        className: "popup-on-click",
        marker: {
          lineWidth: 1,
        },
      },
      spline: {
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "10px",
            color:
              ThemeModeComponent.getMode() === "dark"
                ? labelColorForDark
                : labelColor,
          },
        },
        enableMouseTracking: true,
      },
    }, series: seriesData,
  };
}
// Chart Pie
function getOptionsPie(
  chartOptions: any,
  title: string,
  legend: {},
  series: any,
  optionsSetting?: any,
): Highcharts.Options {
  let seriesData = [{}];
  for (let i = 0; i < series['title']?.length; i++) {
    let obj = {};
    obj["name"] = series['title'][i];
    obj["y"] = series['values'][i];
    seriesData.push(obj);
  }
  seriesData.shift();
  const labelColor = getCSSVariableValue("--bs-gray-dark");
  const labelColorForDark = getCSSVariableValue("--bs-gray-800");
  return {
    chart: chartOptions,
    // exporting: {
    //   enabled: true,
    // },
    credits: {
      enabled: false,
      text: "KumalaGroup.co.id",
      style: {
        fontFamily: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
    },
    colors: optionsSetting?.['colors'] ? optionsSetting['colors'] : baseColors,
    title: {
      text: title,
      align: "center",
      style: {
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
        font: 'bold 16px "serif"',
      },
    }, tooltip: {
      headerFormat: `<span style="font-size:10px"><b>{point.key}</b></span><br>`,
      pointFormat: `<span style="font-size:10px">{point.y} <b>({point.percentage:.1f}%)</b></span>`,
      // pointFormat: `{series.name}: {point.y} <b>({point.percentage:.1f}%)</b>`,
    }, plotOptions: {
      series: {
        cursor: "pointer",
        className: "popup-on-click",
        marker: {
          lineWidth: 1,
        }
      }, pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.percentage:.1f} %</b>",
          distance: -15,
          filter: {
            property: "percentage",
            operator: ">",
            value: 4,
          }, style: {
            fontSize: "10px",
            font: "serif",
            color:
              ThemeModeComponent.getMode() === "dark"
                ? labelColorForDark
                : labelColor,
          },
        },
        showInLegend: true,
      },
    }, legend: {
      ...legend,
      itemStyle: {
        font: "serif",
        fontSize: "9px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
      itemHoverStyle: {
        color: "gray",
      },
    }, series: [{
      type: "pie",
      data: seriesData,
    }],
  };
}
// Chart Pie Donut Percent
function getOptionsPieDonut(
  chartOptions: any,
  title: string,
  legend: {},
  series: any,
  optionsSetting?: any,
): Highcharts.Options {

  let seriesData = [{}];
  for (let i = 0; i < series['title']?.length; i++) {
    let obj = {};
    obj["name"] = series['title'][i];
    obj["y"] = series['values'][i];
    seriesData.push(obj);
  }
  seriesData.shift();


  const labelColor = getCSSVariableValue("--bs-gray-dark");
  const labelColorForDark = getCSSVariableValue("--bs-gray-800");
  return {
    chart: chartOptions,
    credits: {
      enabled: false,
      text: "KumalaGroup.co.id",
      style: {
        fontFamily: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
    },
    colors: optionsSetting?.['colors'] ? optionsSetting['colors'] : baseColors,
    title: {
      text: title,
      align: "center",
      style: {
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
        font: 'bold 16px "serif"',
      },
    }, tooltip: {
      ...optionsSetting?.['tooltip'],
      headerFormat: `<span style="font-size:12px"><b>{point.key}</b></span><br>`,
      pointFormat: `<span style="font-size:10px">{point.y} <b>({point.percentage:.1f}%)</b></span>`,
    }, plotOptions: {
      series: {
        cursor: "pointer",
        className: "popup-on-click",
        marker: {
          lineWidth: 1,
        }
      }, pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.percentage:.1f} %</b>",
          distance: -15,
          filter: {
            property: "percentage",
            operator: ">",
            value: 4,
          }, style: {
            fontSize: "10px",
            font: "serif",
            color:
              ThemeModeComponent.getMode() === "dark"
                ? labelColorForDark
                : labelColor,
          },
        },
        showInLegend: true,
      },
    }, legend: {
      ...legend,
      ...optionsSetting?.['legend'],
      itemStyle: {
        font: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
      itemHoverStyle: {
        color: "gray",
      },
    }, series: [{
      type: 'pie',
      innerSize: '50%',
      data: seriesData,
    }],
  };
}
// Chart ColumnGroupStacked
function getOptionsColumnGroupStacked(
  chartOptions: any,
  title: string,
  stacking: Highcharts.OptionsStackingValue,
  legend: {},
  series: any,
  categories?: [],
  optionsSetting?: any,
): Highcharts.Options {
  let seriesData: any = [{
    type: "column",
    name: "",
    data: [],
  }];
  for (let i = 0; i < series['title']?.length; i++) {
    seriesData.push({
      type: "column",
      name: series['title'][i],
      data: series['values'][i],
    });
  }
  seriesData.shift();
  const labelColor = getCSSVariableValue("--bs-gray-dark");
  const labelColorForDark = getCSSVariableValue("--bs-gray-800");
  return {
    chart: chartOptions,
    // exporting: {
    //   enabled: true,
    // },
    credits: {
      enabled: false,
      text: "KumalaGroup.co.id",
      style: {
        fontFamily: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
    },
    colors: baseColors,
    title: {
      text: title,
      align: "center",
      style: {
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
        font: 'bold 16px "serif"',
      },
    }, xAxis: [{
      ...optionsSetting?.['xAxis'],
      categories: categories,
      labels: {
        style: {
          fontSize: "10px",
          color:
            ThemeModeComponent.getMode() === "dark"
              ? labelColorForDark
              : labelColor,
        },
      },
      crosshair: true,
    }], yAxis: {
      ...optionsSetting?.['yAxis'],
      visible: false,
    }, tooltip: {
      shared: true,
      format:
        '<span style="font-size: 10px;"><b>{key}</b></span><br/>' +
        '<span style="font-size: 10px;">' +
        "{#each points}" +
        '<span style="color:{color};">\u25CF</span> ' +
        "{series.name}: {y}<b> ({point.percentage:.2f}%)</b><br/>" +
        "{/each}" +
        "Total: <b>{point.stackTotal}</b>" +
        '</span>',
    }, legend: {
      ...legend,
      itemStyle: {
        font: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
      itemHoverStyle: {
        color: "gray",
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0.1,
        shadow: false,
        dataLabels: {
          enabled: true,
          format: "<b>{point.percentage:.2f} %</b>",
          filter: {
            property: "percentage",
            operator: ">",
            value: 4,
          }, style: {
            fontSize: "10px",
            font: "serif",
            color:
              ThemeModeComponent.getMode() === "dark"
                ? labelColorForDark
                : labelColor,
          },
        },
        stacking: stacking,
      },
    },
    series: seriesData,
  };
}
// Chart Topology
function getOptionsTopology(
  chartOptions: any,
  title: string,
  series: any,
  optionsSetting: any
): Highcharts.Options {
  const labelColor = getCSSVariableValue("--bs-gray-dark");
  const labelColorForDark = getCSSVariableValue("--bs-gray-800");
  return {
    chart: {
      // zoomType: 'x',
      panning: true,
      panKey: 'shift',
      // scrollablePlotArea: {
      //   minWidth: 600
      // },
      ...chartOptions
    },
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
      text: "KumalaGroup.co.id",
      style: {
        fontFamily: 'serif',
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
    },
    colors: baseColors,
    title: {
      text: title,
      align: "center",
      style: {
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
        font: 'bold 16px "Roboto"',
      },
    },
    // accessibility: {
    //   series: {
    //     descriptionFormat: 'Timezone {series.name} with {series.points.length} countries.'
    //   },
    //   point: {
    //     valueDescriptionFormat: '{point.name}.'
    //   }
    // }, 
    legend: {
      itemStyle: {
        font: '12px "Roboto"',
        color: ThemeModeComponent.getMode() === "dark"
          ? labelColorForDark
          : labelColor,
      },
      align: 'right',
      verticalAlign: 'bottom',
      floating: true,
      labelFormatter: function () {
        if (this['from'] === undefined) {
          return `MS ≤ ${this['to']}%`;
        }
        if (this['to'] === undefined) {
          return `MS ≥ ${this['from']}%`;
        }
        return `${this['from']}% < MS < ${this['to']}%`;
      },
      layout: 'vertical',
      valueDecimals: 0,
      symbolRadius: 0,
      symbolHeight: 14,
      itemHoverStyle: {
        color: "gray",
      },
    }, colorAxis: {
      dataClasses: [{
        color: "#bf392b",
        to: 2
      }, {
        color: "#fcc47c",
        from: 2,
        to: 2.5
      }, {
        color: "#43a047",
        from: 2.5
      }]
    },
    mapNavigation: {
      enabled: false,
      buttonOptions: {
        verticalAlign: 'bottom',
        align: 'left'
      }
    },
    plotOptions: {
      map: {
        // allAreas: false,
        dataLabels: {
          allowOverlap: true,
          enabled: false,
          style: {
            fontSize: "10px",
            font: "serif",
            color:
              ThemeModeComponent.getMode() === "dark"
                ? labelColorForDark
                : labelColor,
          },
          formatter: function () {
            if (this.point.value) {
              return this.point.name;
            }
          },
          format: '{point.name}:<br>{point.value} items'
        },
        tooltip: {
          headerFormat: `<span style="font-size:12px"><b>{point.key}</b></span><br>`,
          pointFormat: `cat_ms: {point.value}%`,
        }
      }
    },
    annotations: [{
      draggable: '',
      labelOptions: {
        useHTML: true,
        shape: 'connector',
        borderColor: '#999999',
        borderWidth: 2,
        style: {
          fontSize: '10px',
          textOutline: '1px white',
          // color: 'red'
        }
      },
      labels: optionsSetting?.['annotations']?.['labels']
    }],
    series: [{
      joinBy: 'name',
      type: "map",
      mapData: map,
      data: series,
    }]
  };
}
// Chart Topology Area
function getOptionsTopologyArea(
  chartOptions: any,
  title: string,
  series: any,
): Highcharts.Options {

  let data: [string, number][] = [['id-bb', 16], ['id-ba', 17],
  ['id-ji', 18], ['id-ks', 19], ['id-nt', 20], ['id-se', 21],
  ['id-kr', 22], ['id-ib', 23], ['id-su', 24], ['id-ri', 25],
  ['id-sw', 26], ['id-ku', 27], ['id-la', 28], ['id-sb', 29],
  ['id-ma', 30], ['id-nb', 31], ['id-sg', 32], ['id-st', 33],
  ['id-pa', 34], ['id-jr', 35], ['id-ki', 36], ['id-1024', 37],
  ['id-jk', 38], ['id-go', 39]];

  const labelColor = getCSSVariableValue("--bs-gray-dark");
  const labelColorForDark = getCSSVariableValue("--bs-gray-800");

  return {
    chart: chartOptions,
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
      text: "KumalaGroup.co.id",
      style: {
        fontFamily: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
    },
    colors: baseColors,
    title: {
      text: "The national-wide M/S YTD 2023 is 2.5% compared to last year and is kept maintained",
      align: "center",
      style: {
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
        font: 'bold 16px "serif"',
      },
    },
    accessibility: {
      // series: {
      //   descriptionFormat: 'Timezone {series.name} with {series.points.length} countries.'
      // },
      // point: {
      //   valueDescriptionFormat: '{point.name}.'
      // }
    }, legend: {
      enabled: false,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom',
        align: 'left'
      }
    },
    plotOptions: {
      series: {
        events: {
          click: function (e) {
            const text = '<b>Clicked</b><br>Series: ' + this.name +
              '<br>Point: ' + e.point.name + ' (' + e.point.value + '/km²)';
            if (!this.chart['clickLabel']) {
              this.chart['clickLabel'] = this.chart.renderer
                .label(text, 0, 500).add();
            } else {
              this.chart['clickLabel'].attr({
                text: text
              });
            }
          }
        }
      },
      map: {
        dataLabels: {
          allowOverlap: true,
          enabled: true,
          // color: '#FFFFFF',
          nullFormat: '',
          style: {
            fontSize: "10px",
            font: "serif",
            color:
              ThemeModeComponent.getMode() === "dark"
                ? labelColorForDark
                : labelColor,
          },
          formatter: function () {
            if (this.point.value) {
              return this.point.name;
            }
          },
          format: '{point.name}:<br>{point.value} items'
        },
        tooltip: {
          headerFormat: `<span style="font-size:12px"><b>{point.key}</b></span><br>`,
          pointFormat: `cat_ms: {point.value}`,
          // pointFormat: `{series.name}: {point.value}`,
        }
      }
    },
    series: [{
      name: 'Indonesia',
      keys: ['hc-key', 'value', 'island'],
      data: data,
      type: "map",
      mapData: map,
      // dataLabels: {
      //   enabled: true,
      //   color: 'red',
      //   format: '{point.value}'
      // },
    }],

    // series: [
    //   {
    //     data: data,
    //     type: "map",
    //     name: "Random data",
    //   },
    //   {
    //     data: data1,
    //     type: "map",
    //     name: "Random data 1",
    //   }
    // ]

  };
}
// Chart Topology
function getOptionsBubble(
  chartOptions: any,
  title: string,
  series: any,
  pointEvents: any
): Highcharts.Options {

  let data: any = [{
    type: "bubble",
    name: 'Kumala',
    // data: [[30381, 800, 2.63, { name: "Kumala", dealer: "Kumala" }]],
    data: [{ x: 30381, y: 800, z: 2.63, name: "Kumala", dealer: "Kumala" }],
  }, {
    type: "bubble",
    name: 'Berkat',
    data: [{ x: 3822, y: 99, z: 2.59, name: "Berkat", dealer: "Berkat" }],
  }, {
    type: "bubble",
    name: 'Maju',
    data: [{ x: 32588, y: 838, z: 2.57, name: "Maju", dealer: "Maju" }],
  }, {
    type: "bubble",
    name: 'Arista',
    data: [{ x: 82510, y: 2081, z: 2.52, name: "Arista", dealer: "Arista" }],
  }, {
    type: "bubble",
    name: 'Prima',
    data: [{ x: 16817, y: 357, z: 2.12, name: "Prima", dealer: "Prima" }],
  }, {
    type: "bubble",
    name: 'Perdana',
    data: [{ x: 9074, y: 183, z: 2.02, name: "Perdana", dealer: "Perdana" }],
  }, {
    type: "bubble",
    name: 'AJM',
    data: [{ x: 18466, y: 364, z: 1.97, name: "AJM", dealer: "AJM" }],
  }];

  const labelColor = getCSSVariableValue("--bs-gray-dark");
  const labelColorForDark = getCSSVariableValue("--bs-gray-800");

  return {
    chart: chartOptions,
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
      text: "KumalaGroup.co.id",
      style: {
        fontFamily: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
    },
    colors: baseColors,
    title: {
      text: "",
      align: "center",
      style: {
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
        font: 'bold 16px "serif"',
      },
    },
    accessibility: {
      series: {
        descriptionFormat: 'Timezone {series.name} with {series.points.length} countries.'
      },
      point: {
        valueDescriptionFormat: '{point.name}.'
      }
    }, legend: {
      itemStyle: {
        font: "serif",
        fontSize: "10px",
        color: ThemeModeComponent.getMode() === "dark"
          ? labelColorForDark
          : labelColor,
      },
      align: 'center',
      verticalAlign: 'top',
      layout: 'horizontal',
      itemHoverStyle: {
        color: "gray",
      },
    }, tooltip: {
      useHTML: true,
      headerFormat: '<table>',
      pointFormat: '<tr><th>Dealer Group</th><td>: {point.dealer}</td></tr>' +
        '<tr><th>Vol</th><td>: {point.x}</td></tr>' +
        '<tr><th>M.Size</th><td>: {point.y}</td></tr>' +
        '<tr><th>Ms All Industry</th><td>: {point.z}%</td></tr>',
      footerFormat: '</table>',
      followPointer: true
    },
    yAxis: {
      // tickAmount: ,
      min: 0,
      // min: min2 * 40,
      // max: max2 + 1,
      visible: true,
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        dataLabels: {
          enabled: true,
          format: '{point.name}'
        },
        cursor: 'pointer',
        point: {
          events: pointEvents
        }, marker: {
          states: {
            select: {
              enabled: true
            }
          }
        }
      },
      bubble: {
        minSize: 80,
        maxSize: 80,
        /* zMin: 0, */
        zMax: 80,

      }
    },
    series: data,

    // series: [
    //   {
    //     data: data,
    //     type: "map",
    //     name: "Random data",
    //   },
    //   {
    //     data: data1,
    //     type: "map",
    //     name: "Random data 1",
    //   }
    // ]

  };
}
// Chart Topology
function getOptionsSpiderArea(
  chartOptions: any,
  title: string,
  categories: any,
  series: any,
): Highcharts.Options {
  let data: number[] = [43000, 19000, 60000, 35000, 17000, 10000, 1900];
  let category: string[] = ['Sales Process', 'DO', 'MSCP', 'Data Entry', 'M/S', 'Test Drive', 'Manpower'];
  const labelColor = getCSSVariableValue("--bs-gray-dark");
  const labelColorForDark = getCSSVariableValue("--bs-gray-800");

  return {
    chart: {
      ...chartOptions,
      polar: true,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
      text: "KumalaGroup.co.id",
      style: {
        fontFamily: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
    },
    colors: baseColors,
    title: {
      text: "",
      align: "center",
      style: {
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
        font: 'bold 16px "serif"',
      },
    },
    xAxis: {
      gridLineWidth: 0,
      categories: category,
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels: {
        style: {
          fontSize: "8px",
          color:
            ThemeModeComponent.getMode() === "dark"
              ? labelColorForDark
              : labelColor,
        },
      },
    }, yAxis: {
      labels: {
        enabled: false
      },
      gridLineWidth: 0,
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
    }, legend: {
      enabled: false,
      itemStyle: {
        font: "serif",
        fontSize: "10px",
        color: ThemeModeComponent.getMode() === "dark"
          ? labelColorForDark
          : labelColor,
      },
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      itemHoverStyle: {
        color: "gray",
      },
    }, tooltip: {
      shared: true,
      useHTML: true,
      pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>',
    }, plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        }
      }, area: {
        fillOpacity: 0.1,
        marker: {
          enabled: false,
          radius: 1,
          states: {
            hover: {
              enabled: true
            }
          }
        }
      }
    },
    series: [{
      type: 'area',
      data: data,
      pointPlacement: 'on',
    }], responsive: {
      rules: [{
        condition: {
          maxWidth: 1000
        }, chartOptions: {
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal'
          }, pane: {
            size: '75%'
          }
        }
      }]
    }
  };
}
// Chart BarLine Options
function getOptionsLine(
  chartOptions: any,
  title: string,
  series: any,
  categories?: [],
  optionsSetting?: any,
): Highcharts.Options {
  const labelColor = getCSSVariableValue("--bs-gray-dark");
  const labelColorForDark = getCSSVariableValue("--bs-gray-800");
  return {
    chart: chartOptions,
    credits: {
      enabled: false,
      text: "KumalaGroup.co.id",
      style: {
        fontFamily: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
    },
    colors: optionsSetting['colors'] ? optionsSetting['colors'] : baseColors,
    title: {
      text: title,
      align: "center",
      style: {
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
        font: 'bold 16px "serif"',
      },
    }, xAxis: [{
      ...optionsSetting?.['xAxis'],
      categories: categories,
      labels: {
        // enabled: false,
        style: {
          fontSize: "10px",
          color:
            ThemeModeComponent.getMode() === "dark"
              ? labelColorForDark
              : labelColor,
        },
      },
      crosshair: true,
    }], yAxis: [{
      ...optionsSetting?.['yAxis'],
    }], tooltip: {
      split: false,
      shared: true,
      ...optionsSetting?.['tooltip'],
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
    }, plotOptions: {
      line: {
        lineWidth: 2.5,
        marker: {
          radius: 3.5,
        }, dataLabels: {
          format: "{point.y:.0f}%",
          ...optionsSetting?.['plotOptions']?.['line']?.['dataLabels'],
          enabled: true,
          style: {
            fontSize: "10px",
            color:
              ThemeModeComponent.getMode() === "dark"
                ? labelColorForDark
                : labelColor,
          },
        }
      },
    }, legend: {
      ...optionsSetting?.['legend'],
      itemStyle: {
        font: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
      itemHoverStyle: {
        color: "gray",
      },
    },
    series: series,
  };
}
// Chart BarLine Options
function getOptionsColumnRange(
  chartOptions: any,
  title: string,
  subTitle: string,
  series1: any,
  categories?: [],
  optionsSetting?: any,
): Highcharts.Options {
  // let series: any = [{
  //   type: 'columnrange',
  //   yAxis: 1,
  //   data: [[-38450, -36111, -272, -125]],
  //   pointWidth: 50,

  // }, {
  //   type: 'columnrange',
  //   data: [[38450, 36111, 272, 125]],
  //   pointWidth: 50,
  // }]
  // let series: any = [{
  //   type: 'columnrange',
  //   data: [
  //     -8.84, -7.42, -6.57, -5.72
  //   ]
  // }, {
  //   type: 'columnrange',
  //   data: [
  //     8.84, 7.42, 6.57, 5.72
  //   ]
  // }]
  // let series: any = [{
  //   type: 'columnrange',
  //   x: 1,
  //   low: -38450,
  //   high: 38450,
  //   name: "Lead",
  // }, {
  //   type: 'columnrange',
  //   x: 1,
  //   low: -36111,
  //   high: 36111,
  //   name: "Lead 2",
  // }]
  // let series: any = [{
  //   type: 'columnrange',
  //   pointWidth: 50,
  //   data: [{
  //     low: -38450,
  //     high: 38450,
  //     name: "['% vs Previous",
  //   }, {
  //     low: -36111,
  //     high: 36111,
  //     name: "94%",
  //   }, {
  //     low: -272,
  //     high: 272,
  //     name: "1%",
  //   }, {
  //     low: -125,
  //     high: 125,
  //     name: "46%",
  //   }]
  // }]
  let series: any = [{
    type: 'columnrange',
    pointWidth: 50,
    data: [
      [-38450, 38450],
      [-36111, 36111],
      [-272, 272],
      [-125, 125],
    ]
  }]
  const labelColor = getCSSVariableValue("--bs-gray-dark");
  const labelColorForDark = getCSSVariableValue("--bs-gray-800");
  return {
    chart: {
      ...chartOptions,
      inverted: true
    },
    credits: {
      enabled: false,
      text: "KumalaGroup.co.id",
      style: {
        fontFamily: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
    },
    colors: optionsSetting['colors'] ? optionsSetting['colors'] : baseColors,
    title: {
      text: title,
      align: "center",
      style: {
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
        font: 'bold 16px "serif"',
      },
    },
    subtitle: {
      text: subTitle,
      align: "center",
      style: {
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
        font: 'bold 14px "serif"',
      },
    }, xAxis: [{
      ...optionsSetting?.['xAxis'],
      opposite: true,
      categories: ['% vs Previous', '94%', '1%', '46%'],
      labels: {
        style: {
          fontSize: "10px",
          color:
            ThemeModeComponent.getMode() === "dark"
              ? labelColorForDark
              : labelColor,
        },
      }
    }, {
      ...optionsSetting?.['xAxis'],
      linkedTo: 0,
      categories: ['Lead', 'Leads w/o TD', 'SPK w/o TD', 'DO w/o TD'],
      labels: {
        style: {
          fontSize: "10px",
          color:
            ThemeModeComponent.getMode() === "dark"
              ? labelColorForDark
              : labelColor,
        },
      }
    }], yAxis: [{
      title: {
        text: ''
      },
      visible: false,
      gridLineWidth: 0,
      labels: {
        enabled: false
      },
      ...optionsSetting?.['yAxis'],
    }], tooltip: {
      split: false,
      shared: true,
      ...optionsSetting?.['tooltip'],
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>',
    }, plotOptions: {
      columnrange: {
        colorByPoint: false,
        dataLabels: {
          enabled: true,
          align: 'center',
          inside: true,
          x: 0,
          y: 0,
          formatter: function () {
            if (this.y) {
              return this.y * 1;
            }
          }, style: {
            fontSize: "10px",
            color:
              ThemeModeComponent.getMode() === "dark"
                ? labelColorForDark
                : labelColor,
          },
        }
      }
    }, legend: {
      ...optionsSetting?.['legend'],
      itemStyle: {
        font: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
      itemHoverStyle: {
        color: "gray",
      },
    },
    series: series,
  };
}

// Chart BarLine Options
function getOptionsBar(
  chartOptions: any,
  title: string,
  legend: {},
  series: any,
  optionsSetting?: any,
): Highcharts.Options {
  const labelColor = getCSSVariableValue("--bs-gray-dark");
  const labelColorForDark = getCSSVariableValue("--bs-gray-800");
  return {
    chart: chartOptions,
    // exporting: {
    //   enabled: true,
    // },
    credits: {
      enabled: false,
      text: "KumalaGroup.co.id",
      style: {
        fontFamily: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
    },
    colors: baseColors,
    title: {
      text: title,
      align: "center",
      style: {
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
        font: 'bold 16px "serif"',
      },
    }, xAxis: [{
      ...optionsSetting?.['xAxis'],
      type: 'category',
      labels: {
        style: {
          fontSize: "10px",
          color:
            ThemeModeComponent.getMode() === "dark"
              ? labelColorForDark
              : labelColor,
        },
      },
    }], yAxis: [{
      ...optionsSetting?.['yAxis'],
    }], tooltip: {
      ...optionsSetting?.['tooltip'],
      format:
        '<span style="font-size:12px"><b>{point.name}</b></span><br>' +
        '<span style="font-size:10px; color:{point.color}">{series.name}</span>: <b>{point.y:.0f}</b><br/>',
    }, plotOptions: {
      ...optionsSetting?.['plotOptions'],
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          align: 'right',
          inside: true,
          style: {
            fontSize: "10px",
            color:
              ThemeModeComponent.getMode() === "dark"
                ? labelColorForDark
                : labelColor,
          },
          format: '{point.y:.0f}'
        }
      }
    }, legend: {
      ...optionsSetting?.['legend'],
      ...legend,
      itemStyle: {
        font: "serif",
        fontSize: "10px",
        color:
          ThemeModeComponent.getMode() === "dark"
            ? labelColorForDark
            : labelColor,
      },
      itemHoverStyle: {
        color: "gray",
      },
    },
    series: series,
    lang: {
      thousandsSep: ".",
      decimalPoint: ",",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ], shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    }, navigation: {
      menuItemStyle: {
        fontSize: "10px",
      },
    },
  };
}

