import { type } from 'os'
import React, { Component } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { Card2 } from '../../../../../_metronic/helpers/components/widgets/CardTemplate';
import { KTCard, KTCardBody, KTIcon } from '../../../../../_metronic/helpers';
import { HighchartsSample } from '../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample';
import { MaterialReactTableDetails } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';
import { Form, Formik } from 'formik';

export default class RootCauseAnalysisIndex extends React.Component {
    render() {
        return (
            <>
                <PageTitle breadcrumbs={[{ title: "KPI Dashboard", path: "/sales/root-cause-analysis", isSeparator: false, isActive: false }]}>
                    {"Root Cause Analysis"}
                </PageTitle>
                {/* BEGIN:: Filter Input */}
                <div className="col-xxl-12 mb-5">
                    <KTCard flush stretch={"stretch"}>
                        <KTCardBody>
                            <Formik
                                enableReinitialize={true}
                                initialValues={[]}
                                onSubmit={() => { }}
                            >
                                {({ setFieldValue, values }) => (
                                    <Form>
                                        <div className="row m-auto">
                                            <div className="col-md-3 mb-5">
                                                <FilterMultiSelect
                                                    label={"ASM"}
                                                    name="asm"
                                                    options={[]}
                                                    onChange={(selected: Option[]) => {
                                                        // setFieldValue("asm", selected);
                                                        // const arrayValueMonth = values["month"].map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // const arrayValueAsm = selected.map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // setTimeout(() => {
                                                        //     fetchingCreditProcessAPI({
                                                        //         asm: arrayValueAsm,
                                                        //         month: arrayValueMonth,
                                                        //     })
                                                        // }, 500);
                                                    }}
                                                    value={values["asm"]}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-5">
                                                <FilterMultiSelect
                                                    label={"Outlet"}
                                                    name="outlet"
                                                    options={[]}
                                                    onChange={(selected: Option[]) => {
                                                        // setFieldValue("asm", selected);
                                                        // const arrayValueMonth = values["month"].map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // const arrayValueAsm = selected.map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // setTimeout(() => {
                                                        //     fetchingCreditProcessAPI({
                                                        //         asm: arrayValueAsm,
                                                        //         month: arrayValueMonth,
                                                        //     })
                                                        // }, 500);
                                                    }}
                                                    value={values["outlet"]}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-5">
                                                <FilterMultiSelect
                                                    label={"Month"}
                                                    name="month"
                                                    options={[]}
                                                    onChange={(selected: Option[]) => {
                                                        // setFieldValue("month", selected);
                                                        // const arrayValueMonth = selected.map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // const arrayValueAsm = values["asm"].map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // setTimeout(() => {
                                                        //     fetchingCreditProcessAPI({
                                                        //         asm: arrayValueAsm,
                                                        //         month: arrayValueMonth,
                                                        //     })
                                                        // }, 500);
                                                    }}
                                                    value={values["month"]}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-5">
                                                <FilterMultiSelect
                                                    label={"Range"}
                                                    name="range"
                                                    options={[]}
                                                    onChange={(selected: Option[]) => {
                                                        // setFieldValue("month", selected);
                                                        // const arrayValueMonth = selected.map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // const arrayValueAsm = values["asm"].map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // setTimeout(() => {
                                                        //     fetchingCreditProcessAPI({
                                                        //         asm: arrayValueAsm,
                                                        //         month: arrayValueMonth,
                                                        //     })
                                                        // }, 500);
                                                    }}
                                                    value={values["range"]}
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </KTCardBody>
                    </KTCard>
                </div>
                {/* END:: Filter Input */}
                {/* ----------------------------------------------------------------------------- */}
                {/* BEGIN:: Card Score Profile, Summary & KPI Chart  */}
                <div className="row g-5 mb-5">
                    <KTCard flush>
                        <KTCardBody className='px-0'>
                            {/* begin::Row */}
                            <div className='flex-sm-row mx-5 mb-md-11 g-sm-5 d-sm-flex flex-center d-md-flex justify-content-start bd-highlight h-md-auto'>
                                <div className='flex-fill bd-highlight mb-sm-10 mb-md-0 mt-5'>
                                    <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-danger">
                                        <span className="card-label fw-bold text-white fs-md-7 text-center">Manpower</span>
                                    </div>
                                    <div className="card-body rounded-bottom-4 bg-light-dark">
                                        <div className='d-flex flex-row-fluid align-items-center bd-highlight flex-center '>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <MaterialReactTableDetails
                                            columns={[]}
                                            data={[]}
                                            isLoading={false}
                                            enableTopToolbar={false}
                                        />
                                    </div>
                                </div>
                                <div className='d-none d-lg-block d-flex flex-shrink-0 flex-center'>
                                    <svg width="100" height="25" viewBox="0 0 51 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M50.3536 4.35355C50.5488 4.15829 50.5488 3.84171 50.3536 3.64645L47.1716 0.464466C46.9763 0.269204 46.6597 0.269204 46.4645 0.464466C46.2692 0.659728 46.2692 0.976311 46.4645 1.17157L49.2929 4L46.4645 6.82843C46.2692 7.02369 46.2692 7.34027 46.4645 7.53553C46.6597 7.7308 46.9763 7.7308 47.1716 7.53553L50.3536 4.35355ZM0 4.5H50V3.5H0V4.5Z" fill="black" />
                                    </svg>
                                </div>
                                <div className='flex-fill bd-highlight mb-sm-10 mb-md-0 mt-5'>
                                    <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-primary">
                                        <span className="card-label fw-bold text-white fs-md-7 text-center">Activity</span>
                                    </div>
                                    <div className="card-body rounded-bottom-4 bg-light-dark">
                                        <div className='d-flex flex-row-fluid align-items-center bd-highlight flex-center '>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <MaterialReactTableDetails
                                            columns={[]}
                                            data={[]}
                                            isLoading={false}
                                            enableTopToolbar={false}
                                        />
                                    </div>
                                </div>
                                <div className='d-none d-lg-block d-flex flex-shrink-0 flex-center'>
                                    <svg width="100" height="25" viewBox="0 0 51 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M50.3536 4.35355C50.5488 4.15829 50.5488 3.84171 50.3536 3.64645L47.1716 0.464466C46.9763 0.269204 46.6597 0.269204 46.4645 0.464466C46.2692 0.659728 46.2692 0.976311 46.4645 1.17157L49.2929 4L46.4645 6.82843C46.2692 7.02369 46.2692 7.34027 46.4645 7.53553C46.6597 7.7308 46.9763 7.7308 47.1716 7.53553L50.3536 4.35355ZM0 4.5H50V3.5H0V4.5Z" fill="black" />
                                    </svg>
                                </div>
                                <div className='flex-fill bd-highlight mb-sm-10 mb-md-0 mt-5'>
                                    <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-success">
                                        <span className="card-label fw-bold text-white fs-md-7 text-center">Execution Gap</span>
                                    </div>
                                    <div className="card-body rounded-bottom-4 bg-light-dark">
                                        <div className='d-flex flex-row-fluid align-items-center bd-highlight flex-center '>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <MaterialReactTableDetails
                                            columns={[]}
                                            data={[]}
                                            isLoading={false}
                                            enableTopToolbar={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* end::Row */}
                        </KTCardBody>
                        {/* Arrow Down */}
                        <div className='d-none d-lg-block d-flex flex-shrink-0 flex-center'>
                            <div className="row flex-center d-flex justify-content-center bd-highlight">
                                <div className="col-4 bd-highlight"></div>
                                <div className="col-4 bd-highlight"></div>
                                <div className="col-4 bd-highlight d-flex justify-content-center">
                                    <svg width="100" transform='rotate(90 10 10)' height="25" viewBox="10 0 45 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M50.3536 4.35355C50.5488 4.15829 50.5488 3.84171 50.3536 3.64645L47.1716 0.464466C46.9763 0.269204 46.6597 0.269204 46.4645 0.464466C46.2692 0.659728 46.2692 0.976311 46.4645 1.17157L49.2929 4L46.4645 6.82843C46.2692 7.02369 46.2692 7.34027 46.4645 7.53553C46.6597 7.7308 46.9763 7.7308 47.1716 7.53553L50.3536 4.35355ZM0 4.5H50V3.5H0V4.5Z" fill="black" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <KTCardBody className='px-0'>
                            <div className='flex-sm-row mx-5 mb-md-11 g-sm-5 d-sm-flex flex-center d-md-flex justify-content-start bd-highlight h-md-auto'>
                                <div className='flex-fill bd-highlight'>
                                    <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-danger">
                                        <span className="card-label fw-bold text-white fs-md-7 text-center">SPK</span>
                                    </div>
                                    <div className="card-body rounded-bottom-4 bg-light-dark">
                                        <div className='d-flex flex-row-fluid align-items-center bd-highlight flex-center '>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <MaterialReactTableDetails
                                            columns={[]}
                                            data={[]}
                                            isLoading={false}
                                            enableTopToolbar={false}
                                        />
                                    </div>
                                </div>
                                <div className='d-none d-lg-block d-flex flex-shrink-0 flex-center'>
                                    <svg width="100" transform="scale(-1 1)" height="25" viewBox="0 0 51 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M50.3536 4.35355C50.5488 4.15829 50.5488 3.84171 50.3536 3.64645L47.1716 0.464466C46.9763 0.269204 46.6597 0.269204 46.4645 0.464466C46.2692 0.659728 46.2692 0.976311 46.4645 1.17157L49.2929 4L46.4645 6.82843C46.2692 7.02369 46.2692 7.34027 46.4645 7.53553C46.6597 7.7308 46.9763 7.7308 47.1716 7.53553L50.3536 4.35355ZM0 4.5H50V3.5H0V4.5Z" fill="black" />
                                    </svg>
                                </div>
                                <div className='flex-fill bd-highlight'>
                                    <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-primary">
                                        <span className="card-label fw-bold text-white fs-md-7 text-center">Test Drive</span>
                                    </div>
                                    <div className="card-body rounded-bottom-4 bg-light-dark">
                                        <div className='d-flex flex-row-fluid align-items-center bd-highlight flex-center '>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <MaterialReactTableDetails
                                            columns={[]}
                                            data={[]}
                                            isLoading={false}
                                            enableTopToolbar={false}
                                        />
                                    </div>
                                </div>
                                <div className='d-none d-lg-block d-flex flex-shrink-0 flex-center'>
                                    <svg width="100" transform="scale(-1 1)" height="25" viewBox="0 0 51 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M50.3536 4.35355C50.5488 4.15829 50.5488 3.84171 50.3536 3.64645L47.1716 0.464466C46.9763 0.269204 46.6597 0.269204 46.4645 0.464466C46.2692 0.659728 46.2692 0.976311 46.4645 1.17157L49.2929 4L46.4645 6.82843C46.2692 7.02369 46.2692 7.34027 46.4645 7.53553C46.6597 7.7308 46.9763 7.7308 47.1716 7.53553L50.3536 4.35355ZM0 4.5H50V3.5H0V4.5Z" fill="black" />
                                    </svg>
                                </div>
                                <div className='flex-fill bd-highlight'>
                                    <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-success">
                                        <span className="card-label fw-bold text-white fs-md-7 text-center">Leads</span>
                                    </div>
                                    <div className="card-body rounded-bottom-4 bg-light-dark">
                                        <div className='d-flex flex-row-fluid align-items-center bd-highlight flex-center '>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <MaterialReactTableDetails
                                            columns={[]}
                                            data={[]}
                                            isLoading={false}
                                            enableTopToolbar={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* end::Row */}
                        </KTCardBody>
                        {/* Arrow Down */}
                        <div className='d-none d-lg-block h-50px d-flex flex-shrink-0 flex-center'>
                            <div className="row flex-center d-flex justify-content-center bd-highlight">
                                <div className="col-4 pe-20 ps-0 bd-highlight d-flex m-0 justify-content-center">
                                    <svg width="135" transform='rotate(90 10 10)' height="25" viewBox="10 0 45 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M50.3536 4.35355C50.5488 4.15829 50.5488 3.84171 50.3536 3.64645L47.1716 0.464466C46.9763 0.269204 46.6597 0.269204 46.4645 0.464466C46.2692 0.659728 46.2692 0.976311 46.4645 1.17157L49.2929 4L46.4645 6.82843C46.2692 7.02369 46.2692 7.34027 46.4645 7.53553C46.6597 7.7308 46.9763 7.7308 47.1716 7.53553L50.3536 4.35355ZM0 4.5H50V3.5H0V4.5Z" fill="black" />
                                    </svg>
                                </div>
                                <div className="col-4 bd-highlight"></div>
                                <div className="col-4 bd-highlight"></div>
                            </div>
                        </div>
                        <KTCardBody className='py-6 px-0'>
                            <div className='flex-sm-row mx-5 mt-0 mb-md-11 g-sm-5 d-sm-flex flex-center d-md-flex justify-content-start bd-highlight h-md-auto'>
                                <div className='flex-fill bd-highlight'>
                                    <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-danger">
                                        <span className="card-label fw-bold text-white fs-md-7 text-center">SPK</span>
                                    </div>
                                    <div className="card-body rounded-bottom-4 bg-light-dark">
                                        <div className='d-flex flex-row-fluid align-items-center bd-highlight flex-center '>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <MaterialReactTableDetails
                                            columns={[]}
                                            data={[]}
                                            isLoading={false}
                                            enableTopToolbar={false}
                                        />
                                    </div>
                                </div>
                                <div className='d-none d-lg-block d-flex flex-shrink-0 flex-center'>
                                    <svg width="100" height="25" viewBox="0 0 51 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M50.3536 4.35355C50.5488 4.15829 50.5488 3.84171 50.3536 3.64645L47.1716 0.464466C46.9763 0.269204 46.6597 0.269204 46.4645 0.464466C46.2692 0.659728 46.2692 0.976311 46.4645 1.17157L49.2929 4L46.4645 6.82843C46.2692 7.02369 46.2692 7.34027 46.4645 7.53553C46.6597 7.7308 46.9763 7.7308 47.1716 7.53553L50.3536 4.35355ZM0 4.5H50V3.5H0V4.5Z" fill="black" />
                                    </svg>
                                </div>
                                <div className='flex-fill bd-highlight'>
                                    <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-primary">
                                        <span className="card-label fw-bold text-white fs-md-7 text-center">Test Drive</span>
                                    </div>
                                    <div className="card-body rounded-bottom-4 bg-light-dark">
                                        <div className='d-flex flex-row-fluid align-items-center bd-highlight flex-center '>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <MaterialReactTableDetails
                                            columns={[]}
                                            data={[]}
                                            isLoading={false}
                                            enableTopToolbar={false}
                                        />
                                    </div>
                                </div>
                                <div className='d-none d-lg-block d-flex flex-shrink-0 flex-center'>
                                    <svg width="100" height="25" viewBox="0 0 51 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M50.3536 4.35355C50.5488 4.15829 50.5488 3.84171 50.3536 3.64645L47.1716 0.464466C46.9763 0.269204 46.6597 0.269204 46.4645 0.464466C46.2692 0.659728 46.2692 0.976311 46.4645 1.17157L49.2929 4L46.4645 6.82843C46.2692 7.02369 46.2692 7.34027 46.4645 7.53553C46.6597 7.7308 46.9763 7.7308 47.1716 7.53553L50.3536 4.35355ZM0 4.5H50V3.5H0V4.5Z" fill="black" />
                                    </svg>
                                </div>
                                <div className='flex-fill bd-highlight'>
                                    <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-success">
                                        <span className="card-label fw-bold text-white fs-md-7 text-center">Leads</span>
                                    </div>
                                    <div className="card-body rounded-bottom-4 bg-light-dark">
                                        <div className='d-flex flex-row-fluid align-items-center bd-highlight flex-center '>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6 mb-5 mx-2'>
                                                <div className="card-body rounded-4 bg-light">
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-5 text-gray-500 fw-bold">Bobot</span>
                                                    </div>
                                                    <div className='d-flex flex-center'>
                                                        <span className="fs-2 text-dark fw-bold">8,21%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <MaterialReactTableDetails
                                            columns={[]}
                                            data={[]}
                                            isLoading={false}
                                            enableTopToolbar={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* end::Row */}
                        </KTCardBody>
                    </KTCard>
                </div>
                {/* END:: Card Score Profile, Summary & KPI Chart  */}
            </>
        )
    }
}
