import { useState, useRef } from "react";
import Select from 'react-select';
import { default as ReactSelect, components, InputAction } from "react-select";
import { ThemeModeComponent } from "../../../assets/ts/layout";
import { useThemeMode } from "../../../partials";
const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";


export type Option = {
  value: number | string;
  label: string;
};

const FilterMultiSelect = (props: any) => {
  const { mode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  const [selectInput, setSelectInput] = useState<string>("");
  const isAllSelected = useRef<boolean>(false);
  const selectAllLabel = useRef<string>(`Select All ${props['label']}`);
  const allOption = { value: "*", label: selectAllLabel.current };

  const filterOptions = (options: Option[], input: string) =>
    options?.filter(({ label }: Option) =>
      label.toLowerCase().includes(input.toLowerCase())
    );

  const comparator = (v1: Option, v2: Option) =>
    (v1.value as number) - (v2.value as number);

  let filteredOptions = filterOptions(props.options, selectInput);
  let filteredSelectedOptions = filterOptions(props.value, selectInput);

  const Option = (props: any) => (
    <components.Option {...props}>
      {props.value === "*" && !isAllSelected.current && filteredSelectedOptions?.length > 0 ? (
        <input
          key={props.value}
          type="checkbox"
          ref={(input) => {
            if (input) input.indeterminate = true;
          }}
        />
      ) : (
        <input
          key={props.value}
          type="checkbox"
          checked={props.isSelected || isAllSelected.current}
          onChange={() => { }}
        />
      )}
      <label style={{ marginLeft: "5px" }}>{props.label}</label>
    </components.Option>
  );

  const MultiValue = props => {
    let labelToBeDisplayed = `${props.data.label}, `;
    if (props.data.value === isAllSelected.current) {
      labelToBeDisplayed = "All is selected";
    }
    return (
      <components.MultiValue {...props}>
        <span>{labelToBeDisplayed}</span>
      </components.MultiValue>
    );
  };

  // const Input = (props: any) => (
  //   <>
  //     {selectInput.length === 0 ? (
  //       <components.Input autoFocus={!props.selectProps.menuIsOpen} {...props}>
  //         {props.children}
  //       </components.Input>
  //     ) : (
  //       <div style={{ border: "1px dotted gray" }}>
  //         <components.Input autoFocus={!props.selectProps.menuIsOpen} {...props}>
  //           {props.children}
  //         </components.Input>
  //       </div>
  //     )}
  //   </>
  // );

  const customFilterOption = ({ value, label }: Option, input: string) =>
    (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === "*" && filteredOptions?.length > 0);

  const onInputChange = (
    inputValue: string,
    event: { action: InputAction }
  ) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if ((e.key === " " || e.key === "Enter") && !selectInput)
      e.preventDefault();
  };

  const handleChange = (selected: Option[]) => {
    if (selected.length > 0 && !isAllSelected.current && (selected[selected.length - 1].value === allOption.value || JSON.stringify(filteredOptions) === JSON.stringify(selected.sort(comparator))))
      return props.onChange(
        [
          ...(props.value ?? []),
          ...props.options.filter(
            ({ label }: Option) =>
              label.toLowerCase().includes(selectInput?.toLowerCase()) &&
              (props.value ?? []).filter((opt: Option) => opt.label === label).length === 0
          ),
        ].sort(comparator)
      );
    else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !==
      JSON.stringify(filteredOptions)
    )

      return props.onChange(selected);
    else
      return props.onChange([
        ...props.value?.filter(
          ({ label }: Option) =>
            !label.toLowerCase().includes(selectInput?.toLowerCase())
        ),
      ]);
  };

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? 'var(--bs-gray-300)' : 'var(--bs-gray-300)',
      backgroundColor: "var(--bs-body-bg)",
      backgroundClip: "padding-box",
      color: "#92929f"
    }),
    multiValueLabel: (def: any) => ({
      ...def,
      color: "#92929f",
    }),
    multiValueRemove: (def: any) => ({
      ...def,
      color: "#92929f",
    }),
    valueContainer: (base: any) => ({
      ...base,
      padding: "4.0px 5px",
      color: "#92929f",
      maxHeight: "40px",
      fontSize: "0.8rem",
      overflow: "auto",
    }),
    option: (styles: any, { isSelected, isFocused }: any) => {
      return {
        ...styles,
        backgroundColor:
          isSelected && !isFocused
            ? null
            : isFocused && !isSelected
              ? styles.backgroundColor
              : isFocused && isSelected
                ? "#DEEBFF"
                : null,
        color: isSelected ? null : null,
      };
    },
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--bs-body-bg)",
      backgroundClip: "padding-box",
      fontWeight: "400",
      lineHeight: "1.0",
      fontSize: "0.8rem",
      color: "#92929f"
    })
    // menu: (def: any) => ({ ...def, zIndex: 9999 }),
  };

  isAllSelected.current = JSON.stringify(filteredSelectedOptions) === JSON.stringify(filteredOptions);
  if (filteredSelectedOptions?.length > 0) {
    if (filteredSelectedOptions?.length === filteredOptions?.length)
      selectAllLabel.current = `${props['label']} (${filteredOptions.length})`;
    else
      selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} ${props['label']} Selected`;
  } else selectAllLabel.current = `Select All ${props['label']}`;

  allOption.label = selectAllLabel.current;

  return (
    <ReactSelect
      {...props}
      closeMenuOnSelect={false}
      placeholder={"--- Select " + props['label'] + " ---"}
      inputValue={selectInput}
      onInputChange={onInputChange}
      onKeyDown={onKeyDown}
      options={[allOption, ...props.options]}
      onChange={handleChange}
      theme={calculatedMode === "dark" ? (theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'var(--bs-gray-300)',
          primary: 'var(--bs-gray-200)',
        },
      }) : undefined}
      components={{
        Option: Option,
        MultiValue: MultiValue,
        // Input: Input,
        ...props.components,
      }}
      filterOption={customFilterOption}
      styles={customStyles}
      isMulti
      tabSelectsValue={false}
      backspaceRemovesValue={false}
      hideSelectedOptions={false}
      blurInputOnSelect={false}
    />
  );

  // if (props.isSelectAll && props.options.length !== 0) {

  // }

  // return (
  //   <ReactSelect
  //     {...props}
  //     inputValue={selectInput}
  //     onInputChange={onInputChange}
  //     filterOption={customFilterOption}
  //     components={{
  //       Input: Input,
  //       ...props.components,
  //     }}
  //     menuPlacement={props.menuPlacement ?? "auto"}
  //     onKeyDown={onKeyDown}
  //     tabSelectsValue={false}
  //     hideSelectedOptions={true}
  //     backspaceRemovesValue={false}
  //     blurInputOnSelect={true}
  //   />
  // );
};

export default FilterMultiSelect;

export const SingleSelect = (props: any) => {

  return (
    <>
      <Select
        className="basic-single"
        classNamePrefix="select"
        placeholder={"--- Select " + props['label'] + " ---"}
        defaultValue={props.defaultValue}
        isClearable={false}
        onChange={props.onChange}
        isSearchable={true}
        name="singleSelect"
        options={props.options}
      />
    </>
  )
}