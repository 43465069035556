/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { KTCard, KTCardBody, KTIcon } from '../../../../../_metronic/helpers'
import { MaterialReactEditTable } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactEditTable'
import { ReportAPI } from '../../../../api/ReportAPI'
import { MRT_ColumnDef } from 'material-react-table'
import { Box, IconButton, Tooltip, createSvgIcon } from '@mui/material'
import { numberWithPoint } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';
import moment from "moment";
import { Form, Formik } from 'formik'
import { InputSelect2 } from '../../../../../_metronic/helpers/components/form-actions/FormInput'
import { useAuth } from '../../../auth'
import { Add } from '@mui/icons-material'
import SimpleAlert from '../../../../../_metronic/helpers/components/alert/SimpleAlert'
// import "flatpickr/dist/flatpickr.css";

const FlightLandIcon = createSvgIcon(
  <path d="M2.5 19h19v2h-19v-2zm16.84-3.15c.8.21 1.62-.26 1.84-1.06.21-.8-.26-1.62-1.06-1.84l-5.31-1.42-2.76-9.02L10.12 2v8.28L5.15 8.95l-.93-2.32-1.45-.39v5.17l16.57 4.44z" />,
  'FlightLandIcon',
);

type Datas = {
  id: number;
  status: string;
  year: string;
  bulan: string;
  region: string;
  cabang: string;
  spv: string;
  sales: string;
  sumber_prospek: string;
  reject_1: string;
  reject_2: string;
  reject_3: string;
  no_spk: string;
  nama_customer: string;
  nama_pemohon_kredit: string;
  pekerjaan: string;
  jabatan: string;
  bidang_usaha: string;
  jenis_kelamin: string;
  type: string;
  status_unit: string,
  leasing: string,
  dp_percent: string,
  angsuran: string,
  tenor: string,
  tgl_leads: string,
  tgl_spk: string,
  tgl_collect_document: string,
  cek_slik: string,
  tgl_submit_order: string,
  tgl_survey: string,
  tgl_document_lengkap: string,
  tgl_input_system: string,
  decision: string, //status
  tgl_decision: string,
  ket_decision: string,
  appeal: string,
  tgl_appeal: string,
  ket_appeal: string,
  tgl_final_decision: string,
  dp_approved_percent: string,
  tgl_po: string,
  tgl_dp: string,
  tgl_do: string,
  tgl_deposit_to_leasing: string,
  tgl_prepare_invoice: string,
  tgl_invoice_to_leasing: string,
  tgl_go_live: string,
  reject_leasing: string,
  kendala_customer: string,
  keterangan: string,
};

interface MyFormValues {
  month: string;
  coverage: any;
}
export const CreditProcessReportIndex = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [alert, setalert] = useState<any>(
    {
      show: false,
      data: [],
      title: '',
      message: '',
    }
  );
  const [editedData, setEditedData] = useState<Record<string, Datas>>({});
  const [data, setData] = useState([]);
  const [leasing, setleasing] = useState<string[]>([]);
  var curDateMonth = new Date();
  var lastMonth = curDateMonth.getMonth() - 1;
  var lastCurrentMonth = new Date(curDateMonth.getFullYear(), lastMonth, curDateMonth.getMonth());
  var lastMonth2 = lastMonth - 1;
  var lastCurrentMonth2 = new Date(curDateMonth.getFullYear(), lastMonth2, curDateMonth.getMonth());

  let months = [{
    label: curDateMonth.toLocaleString('default', { month: 'long' }),
    value: moment(curDateMonth).format('YYYY-MM-01'),
  }, {
    label: lastCurrentMonth.toLocaleString('default', { month: 'long' }),
    value: moment(lastCurrentMonth).format('YYYY-MM-01'),
  }, {
    label: lastCurrentMonth2.toLocaleString('default', { month: 'long' }),
    value: moment(lastCurrentMonth2).format('YYYY-MM-01'),
  }];

  const initialValues: MyFormValues = { month: '', coverage: currentUser?.[0]['coverage'] };


  const ApproveReject = [
    'Approve',
    'Approve (Up DP)',
    'Approve (Deposit)',
    'Approve (Up DP & Deposit)',
    'Reject',
    'Reject Slik',
    'Batal',
  ];
  const optionsDp = [
    '10%',
    '12.5%',
    '15%',
    '17.5%',
    '20',
    '22.5%',
    '25%',
    '27.5%',
    '30%',
    '32.5%',
    '40%',
    '42.5%',
    '45%',
    '45% Up',
  ];

  const columns = useMemo<MRT_ColumnDef<Datas>[]>(() => [
    {
      accessorKey: 'id',
      header: 'Id',
      enableEditing: false,
      size: 80,
    }, {
      accessorKey: 'status',
      header: 'Status',
      enableEditing: false,
      Cell: ({ cell, row }) => {
        let text = '';
        if (cell.row.original['tgl_spk'] !== '') {
          text = 'SPK';
        } if (cell.row.original['tgl_collect_document'] !== '') {
          text = 'Collect Document';
        } if (cell.row.original['tgl_submit_order'] !== '') {
          text = 'Submit Order';
        } if (cell.row.original['tgl_survey'] !== '') {
          text = 'Survey';
        } if (cell.row.original['tgl_document_lengkap'] !== '') {
          text = 'Document Lengkap';
        } if (cell.row.original['tgl_input_system'] !== '') {
          text = 'Input System';
        } if (cell.row.original['decision'] !== '') {
          text = cell.row.original['decision'];
        } if (cell.row.original['appeal'] !== '') {
          text = cell.row.original['appeal'];
        } if (cell.row.original['tgl_po'] !== '') {
          text = 'PO';
        } if (cell.row.original['tgl_dp'] !== '') {
          text = 'DP';
        } if (cell.row.original['tgl_do'] !== '') {
          text = 'DO';
        } if (cell.row.original['tgl_deposit_to_leasing'] !== '') {
          text = 'Deposit to Leasing';
        } if (cell.row.original['tgl_prepare_invoice'] !== '') {
          text = 'Prepare Invoice';
        } if (cell.row.original['tgl_invoice_to_leasing'] !== '') {
          text = 'Invoice to Leasing';
        } if (cell.row.original['tgl_go_live'] !== '') {
          text = 'Go Live';
        }
        return text;
      },
      muiTableBodyCellProps: ({ cell }) => {
        let colors = 'black';
        // TGL : SPK, Collect Document, Submit Order, 
        let backgroundColor = '';
        if (cell.row.original['tgl_spk'] !== '') {
          backgroundColor = '#f2f2f2';
          colors = 'black';
        } if (cell.row.original['tgl_collect_document'] !== '') {
          backgroundColor = '#f2f2f2';
          colors = 'black';
        } if (cell.row.original['tgl_submit_order'] !== '') {
          backgroundColor = '#f2f2f2';
          colors = 'black';
        } if (cell.row.original['tgl_survey'] !== '') {
          backgroundColor = 'yellow';
          colors = 'white';
        } if (cell.row.original['tgl_document_lengkap'] !== '') {
          backgroundColor = 'yellow';
          colors = 'white';
        } if (cell.row.original['tgl_input_system'] !== '') {
          backgroundColor = 'yellow';
          colors = 'white';
        } if (cell.row.original['decision'] === 'Approve') {
          backgroundColor = 'green';
          colors = 'white';
        } if (cell.row.original['decision'] === 'Approve (Up DP)' || cell.row.original['decision'] === 'Approve (Deposit)' || cell.row.original['decision'] === 'Approve (Up DP & Deposit)') {
          backgroundColor = 'Blue';
          colors = 'white';
        } if (cell.row.original['decision'] === 'Reject') {
          backgroundColor = 'red';
          colors = 'white';
        } if (cell.row.original['appeal'] === 'Approve') {
          backgroundColor = 'green';
          colors = 'white';
        } if (cell.row.original['appeal'] === 'Approve (Up DP)' || cell.row.original['appeal'] === 'Approve (Deposit)' || cell.row.original['appeal'] === 'Approve (Up DP & Deposit)') {
          backgroundColor = 'Blue';
          colors = 'white';
        } if (cell.row.original['appeal'] === 'Reject') {
          backgroundColor = 'red';
          colors = 'white';
        } if (cell.row.original['tgl_po'] !== '') {
          backgroundColor = 'green';
          colors = 'white';
        } if (cell.row.original['tgl_dp'] !== '') {
          backgroundColor = 'green';
          colors = 'white';
        } if (cell.row.original['tgl_do'] !== '') {
          backgroundColor = 'green';
          colors = 'white';
        } if (cell.row.original['tgl_deposit_to_leasing'] !== '') {
          backgroundColor = 'black';
          colors = 'white';
        } if (cell.row.original['tgl_prepare_invoice'] !== '') {
          backgroundColor = 'black';
          colors = 'white';
        } if (cell.row.original['tgl_invoice_to_leasing'] !== '') {
          backgroundColor = 'black';
          colors = 'white';
        } if (cell.row.original['tgl_go_live'] !== '') {
          backgroundColor = 'black';
          colors = 'white';
        }
        return ({
          sx: {
            backgroundColor: backgroundColor,
            color: colors,
          },
          align: 'center',
        })
      },
    }, {
      accessorKey: 'year',
      header: 'Year',
      enableEditing: false,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
    }, {
      accessorKey: 'bulan',
      header: 'Bulan',
      enableEditing: false,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
    }, {
      accessorKey: 'region',
      header: 'Region',
      enableEditing: false,
    }, {
      accessorKey: 'cabang',
      header: 'Cabang',
      enableEditing: false,
    }, {
      accessorKey: 'spv',
      header: 'Supervisor',
      enableEditing: false,
    }, {
      accessorKey: 'sales',
      header: 'Sales',
      enableEditing: false,
    }, {
      accessorKey: 'sumber_prospek',
      header: 'Sumber Prospek',
      enableEditing: false,
    }, {
      accessorKey: 'reject_1',
      header: 'Reject 1',
      enableEditing: false,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'reject_2',
      header: 'Reject 2',
      enableEditing: false,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'reject_3',
      header: 'Reject 3',
      enableEditing: false,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'no_spk',
      header: 'No SPK',
      enableEditing: false,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'nama_customer',
      header: 'Nama Customer Sesuai KTP',
      enableEditing: false,
    }, {
      accessorKey: 'nama_pemohon_kredit',
      header: 'Nama Pemohon Kredit',
      enableEditing: false,
    }, {
      accessorKey: 'pekerjaan',
      header: 'Pekerjaan',
      enableEditing: false,
    }, {
      accessorKey: 'jabatan',
      header: 'Jabatan',
      enableEditing: false,
    }, {
      accessorKey: 'bidang_usaha',
      header: 'Bidang Usaha',
      enableEditing: false,
    }, {
      accessorKey: 'jenis_kelamin',
      header: 'Jenis Kelamin',
      enableEditing: false,
    }, {
      accessorKey: 'type',
      header: 'Type',
      enableEditing: false,
    }, {
      accessorKey: 'status_unit',
      header: 'Status Unit',
      editVariant: 'select',
      editSelectOptions: [
        'Ada Alokasi Unit',
        'Tidak Asa Alokasi Unit',
        'Tunggu Unit Tiba'
      ],
      muiEditTextFieldProps: ({ row }) => ({
        select: true,
        variant: 'outlined',
        onChange: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, year: `'${event.target.value}'` },
          })
        }
      }), muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
    }, {
      accessorKey: 'leasing',
      header: 'Leasing',
      editVariant: 'select',
      editSelectOptions: leasing,
      muiEditTextFieldProps: ({ row }) => ({
        select: true,
        variant: 'outlined',
        onChange: (event) =>
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, leasing: event.target.value },
          }),
      }), muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'dp_percent',
      header: 'DP (%)',
      editVariant: 'select',
      editSelectOptions: optionsDp,
      muiEditTextFieldProps: ({ row }) => ({
        select: true,
        variant: 'outlined',
        onChange: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, dp_percent: `'${event.target.value}'` },
          })
        }
      }), muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'angsuran',
      header: 'Angsuran',
      enableEditing: false,
      Cell: ({ cell }) => {
        return `Rp. ${numberWithPoint(parseInt(cell.getValue<string>()))}`;
      }, muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'right',
        })
      }
    }, {
      accessorKey: 'tenor',
      header: 'Tenor',
      enableEditing: false,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_leads',
      header: 'Tanggal Leads',
      enableEditing: false,
      Cell: ({ cell }) => {
        return cell.getValue<string>() !== '' ? moment(cell.getValue<string>()).format('MM-DD-YYYY') : '';
      },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_spk',
      header: 'Tanggal SPK',
      enableEditing: false,
      Cell: ({ cell }) => {
        return cell.getValue<string>() !== '' ? moment(cell.getValue<string>()).format('MM-DD-YYYY') : '';
      },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_collect_document',
      header: 'Tgl Collect Document',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'date',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, tgl_collect_document: `${event.currentTarget.value}` },
          })
        },
      }),
      // Edit: ({ cell, column, row, table }) => {
      //   const onBlur = (selectedDates, dateStr, instance) => {
      //     setEditedData({
      //       ...editedData, [row.id]: { ...row.original, tgl_collect_document: `${moment(selectedDates[0]).format('YYYY-MM-DD')}` },
      //     })
      //   };
      //   return <Flatpickr
      //     className='form-control py-2'
      //     value={cell.getValue<string>()}
      //     placeholder="Select Date.."
      //     options={{
      //       dateFormat: "d-m-Y",
      //     }}
      //     onChange={onBlur}
      //   />;
      // },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'cek_slik',
      header: 'Cek SLIK',
      editVariant: 'select',
      editSelectOptions: ['di Proses', 'di Reject'],
      muiEditTextFieldProps: ({ row }) => ({
        select: true,
        variant: 'outlined',
        onChange: (event) =>
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, cek_slik: event.target.value },
          }),
      }), muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_submit_order',
      header: 'Tgl Submit Order',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'date',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, tgl_submit_order: `${event.currentTarget.value}` },
          })
        },
      }),
      // Edit: ({ cell, column, row, table }) => {
      //   const onBlur = (selectedDates, dateStr, instance) => {
      //     setEditedData({
      //       ...editedData,
      //       [row.id]: { ...row.original, tgl_submit_order: `${moment(selectedDates[0]).format('YYYY-MM-DD')}` },
      //     })
      //   };
      //   return <Flatpickr
      //     className='form-control py-2'
      //     value={cell.getValue<string>()}
      //     placeholder="Select Date.."
      //     options={{
      //       dateFormat: "d-m-Y",
      //     }}
      //     onChange={onBlur}
      //   />;
      // },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_survey',
      header: 'Tgl Survei',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'date',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, tgl_survey: `${event.currentTarget.value}` },
          })
        },
      }),
      // Edit: ({ cell, column, row, table }) => {
      //   const onBlur = (event) => {
      //     // var dateValues = moment(event.currentTarget.value, 'MM-DD-YYYY').format("YYYY-MM-DD");
      //     setEditedData({
      //       ...editedData,
      //       [row.id]: { ...row._valuesCache, tgl_survey: `${event.currentTarget.value}` },
      //     })
      //   };
      //   return <Flatpickr
      //     className='form-control py-2'
      //     value={cell.getValue<string>()}
      //     placeholder="Select Date.."
      //     options={{
      //       dateFormat: "d-m-Y",
      //     }}
      //     onBlur={onBlur}
      //   />;
      // },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_document_lengkap',
      header: 'Tgl Document Lengkap',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'date',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, tgl_document_lengkap: `${event.currentTarget.value}` },
          })
        },
      }),
      // Edit: ({ cell, column, row, table }) => {
      //   const onBlur = (event) => {
      //     // var dateValues = moment(event.currentTarget.value, 'MM-DD-YYYY').format("YYYY-MM-DD");
      //     setEditedData({
      //       ...editedData,
      //       [row.id]: { ...row._valuesCache, tgl_document_lengkap: `${event.currentTarget.value}` },
      //     })
      //   };
      //   return <Flatpickr
      //     className='form-control py-2'
      //     value={cell.getValue<string>()}
      //     placeholder="Select Date.."
      //     options={{
      //       dateFormat: "d-m-Y",
      //     }}
      //     onBlur={onBlur}
      //   />;
      // },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_input_system',
      header: 'Tgl Input System/ CA Proses',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'date',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, tgl_input_system: `${event.currentTarget.value}` },
          })
        },
      }),
      // Edit: ({ cell, column, row, table }) => {
      //   const onBlur = (event) => {
      //     // var dateValues = moment(event.currentTarget.value, 'MM-DD-YYYY').format("YYYY-MM-DD");
      //     setEditedData({
      //       ...editedData,
      //       [row.id]: { ...row._valuesCache, tgl_input_system: `${event.currentTarget.value}` },
      //     })
      //   };
      //   return <Flatpickr
      //     className='form-control py-2'
      //     value={cell.getValue<string>()}
      //     placeholder="Select Date.."
      //     options={{
      //       dateFormat: "d-m-Y",
      //     }}
      //     onBlur={onBlur}
      //   />;
      // },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'decision',
      header: 'Decision',
      editVariant: 'select',
      editSelectOptions: ApproveReject,
      muiEditTextFieldProps: ({ row }) => ({
        select: true,
        variant: 'outlined',
        onChange: (event) =>
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, decision: event.target.value },
          }),
      }), muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_decision',
      header: 'Tgl Decision',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'date',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, tgl_decision: `${event.currentTarget.value}` },
          })
        },
      }),
      // Edit: ({ cell, column, row, table }) => {
      //   const onBlur = (event) => {
      //     // var dateValues = moment(event.currentTarget.value, 'MM-DD-YYYY').format("YYYY-MM-DD");
      //     setEditedData({
      //       ...editedData,
      //       [row.id]: { ...row._valuesCache, tgl_decision: `${event.currentTarget.value}` },
      //     })
      //   };
      //   return <Flatpickr
      //     className='form-control py-2'
      //     value={cell.getValue<string>()}
      //     placeholder="Select Date.."
      //     options={{
      //       dateFormat: "d-m-Y",
      //     }}
      //     onBlur={onBlur}
      //   />;
      // },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'ket_decision',
      header: 'Ket Decision',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'text',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, ket_decision: `${event.currentTarget.value}` },
          })
        },
      }),
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'appeal',
      header: 'Appeal',
      editVariant: 'select',
      editSelectOptions: ApproveReject,
      muiEditTextFieldProps: ({ row }) => ({
        select: true,
        variant: 'outlined',
        onChange: (event) =>
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, appeal: event.target.value },
          }),
      }), muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_appeal',
      header: 'Tgl Appeal',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'date',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, tgl_appeal: `${event.currentTarget.value}` },
          })
        },
      }),
      // Edit: ({ cell, column, row, table }) => {
      //   const onBlur = (event) => {
      //     // var dateValues = moment(event.currentTarget.value, 'MM-DD-YYYY').format("YYYY-MM-DD");
      //     setEditedData({
      //       ...editedData,
      //       [row.id]: { ...row._valuesCache, tgl_appeal: `${event.currentTarget.value}` },
      //     })
      //   };
      //   return <Flatpickr
      //     className='form-control py-2'
      //     value={cell.getValue<string>()}
      //     placeholder="Select Date.."
      //     options={{
      //       dateFormat: "d-m-Y",
      //     }}
      //     onBlur={onBlur}
      //   />;
      // },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'ket_appeal',
      header: 'Ket Appeal',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'text',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, ket_appeal: `${event.currentTarget.value}` },
          })
        },
      }),
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_final_decision',
      header: 'Tgl Final Decision',
      enableEditing: false,
      Cell: ({ cell }) => {
        return cell.getValue<string>() !== '' ? moment(cell.getValue<string>()).format('MM-DD-YYYY') : '';
      },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'dp_approved_percent',
      header: 'DP Approved (%)',
      editVariant: 'select',
      editSelectOptions: optionsDp,
      muiEditTextFieldProps: ({ row }) => ({
        select: true,
        variant: 'outlined',
        onChange: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, dp_approved_percent: `${event.target.value}` },
          })
        }
      }), muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_po',
      header: 'Tanggal PO',
      enableEditing: false,
      Cell: ({ cell }) => {
        return cell.getValue<string>() !== '' ? moment(cell.getValue<string>()).format('MM-DD-YYYY') : '';
      },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_dp',
      header: 'Tanggal DP',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'date',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, tgl_dp: `${event.currentTarget.value}` },
          })
        },
      }),
      // Edit: ({ cell, column, row, table }) => {
      //   const onBlur = (event) => {
      //     // var dateValues = moment(event.currentTarget.value, 'MM-DD-YYYY').format("YYYY-MM-DD");
      //     setEditedData({
      //       ...editedData,
      //       [row.id]: { ...row._valuesCache, tgl_dp: `${event.currentTarget.value}` },
      //     })
      //   };
      //   return <Flatpickr
      //     className='form-control py-2'
      //     value={cell.getValue<string>()}
      //     placeholder="Select Date.."
      //     options={{
      //       dateFormat: "d-m-Y",
      //     }}
      //     onBlur={onBlur}
      //   />;
      // },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_do',
      header: 'Tanggal DO',
      enableEditing: false,
      Cell: ({ cell }) => {
        return cell.getValue<string>() !== '' ? moment(cell.getValue<string>()).format('MM-DD-YYYY') : '';
      },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_deposit_to_leasing',
      header: 'Tgl Deposit to Leasing',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'date',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, tgl_deposit_to_leasing: `${event.currentTarget.value}` },
          })
        },
      }),
      // Edit: ({ cell, column, row, table }) => {
      //   const onBlur = (event) => {
      //     // var dateValues = moment(event.currentTarget.value, 'MM-DD-YYYY').format("YYYY-MM-DD");
      //     setEditedData({
      //       ...editedData,
      //       [row.id]: { ...row._valuesCache, tgl_deposit_to_leasing: `${event.currentTarget.value}` },
      //     })
      //   };
      //   return <Flatpickr
      //     className='form-control py-2'
      //     value={cell.getValue<string>()}
      //     placeholder="Select Date.."
      //     options={{
      //       dateFormat: "d-m-Y",
      //     }}
      //     onBlur={onBlur}
      //   />;
      // },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_prepare_invoice',
      header: 'Tgl Prepare Invoice',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'date',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, tgl_prepare_invoice: `${event.currentTarget.value}` },
          })
        },
      }),
      // Edit: ({ cell, column, row, table }) => {
      //   const onBlur = (event) => {
      //     // var dateValues = moment(event.currentTarget.value, 'MM-DD-YYYY').format("YYYY-MM-DD");
      //     setEditedData({
      //       ...editedData,
      //       [row.id]: { ...row._valuesCache, tgl_prepare_invoice: `${event.currentTarget.value}` },
      //     })
      //   };
      //   return <Flatpickr
      //     className='form-control py-2'
      //     value={cell.getValue<string>()}
      //     placeholder="Select Date.."
      //     options={{
      //       dateFormat: "d-m-Y",
      //     }}
      //     onBlur={onBlur}
      //   />;
      // },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_invoice_to_leasing',
      header: 'Tgl Invoice to Leasing',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'date',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, tgl_invoice_to_leasing: `${event.currentTarget.value}` },
          })
        },
      }),
      // Edit: ({ cell, column, row, table }) => {
      //   const onBlur = (event) => {
      //     // var dateValues = moment(event.currentTarget.value, 'MM-DD-YYYY').format("YYYY-MM-DD");
      //     setEditedData({
      //       ...editedData,
      //       [row.id]: { ...row._valuesCache, tgl_invoice_to_leasing: `${event.currentTarget.value}` },
      //     })
      //   };
      //   return <Flatpickr
      //     className='form-control py-2'
      //     value={cell.getValue<string>()}
      //     placeholder="Select Date.."
      //     options={{
      //       dateFormat: "d-m-Y",
      //     }}
      //     onBlur={onBlur}
      //   />;
      // },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'tgl_go_live',
      header: 'Tgl Go Live',
      enableEditing: false,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'reject_leasing',
      header: 'Pernah Reject di Leasing',
      enableEditing: false,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'kendala_customer',
      header: 'Kendala Customer',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'text',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, kendala_customer: `${event.currentTarget.value}` },
          })
        },
      }),
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    }, {
      accessorKey: 'keterangan',
      header: 'Keterangan',
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: 'text',
        variant: 'outlined',
        onBlur: (event) => {
          setEditedData({
            ...editedData,
            [row.id]: { ...row._valuesCache, keterangan: `${event.currentTarget.value}` },
          })
        },
      }),
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      }
    },
  ], [editedData, ApproveReject, optionsDp, FlightLandIcon]);


  const fetchingAPI = useCallback(async (values) => {
    setLoading(true);
    const data = await new ReportAPI().getDataTableMscp(values);
    setData(data['data']);
    setLoading(false);
  }, []);

  const fetchingDatasFilterAPI = useCallback(async () => {
    const data = await new ReportAPI().getLeasing();
    let pushLeasing: string[] = [];
    data['data'].map((item: any, i: number) => {
      pushLeasing.push(item['nama_singkat_leasing']);
      return item;
    });
    setleasing(pushLeasing);

  }, []);

  useEffect(() => {
    fetchingAPI(initialValues);
    fetchingDatasFilterAPI();
  }, [fetchingAPI, fetchingDatasFilterAPI]);

  const handleSaveUsers = async () => {
    await new ReportAPI().setMscpProcess(Object.values(editedData));
    setEditedData({});
    setTimeout(() => {
      fetchingAPI(initialValues);
    }, 1000);
  };


  return (
    <React.Fragment>
      <PageTitle breadcrumbs={[{ title: "Credit Process Report", path: "/sales/credit-process-report", isSeparator: false, isActive: false }]}>
        {`Credit Process Report`}
      </PageTitle>
      {/* BEGIN:: Filter Input */}
      <div className="row g-5">
        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={() => { }}>
          {(formik) => (
            <Form>
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-3 mb-5">
                  <InputSelect2
                    name="month"
                    label={"Month"}
                    options={months}
                    isClearable
                    className='z-index-3'
                    isSearchable
                    formik={formik}
                    onChange={async (e: number) => {
                      const values = e?.["value"];
                      await fetchingAPI({ month: values ? values : '', coverage: currentUser?.[0]['coverage'] });
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* END:: Filter Input */}
      {/* ----------------------------------------------------------------------------- */}
      <div className="row g-5">
        <div className="bd-highlight">
          <KTCard className='mb-5'>
            <KTCardBody className="p-3">
              <MaterialReactEditTable
                data={data}
                columns={columns}
                isLoading={loading}
                showColumnFilters={true}
                columnPinningleft={['mrt-row-actions', 'status']}
                renderTopToolbarCustomActions={() => (
                  <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <button
                      type={"button"}
                      className={"btn btn-sm btn-light-primary"}
                      disabled={
                        Object.keys(editedData).length === 0
                      }
                      onClick={handleSaveUsers}
                    >
                      <KTIcon iconName={"save-2"} className={"fs-4 me-2"} /> Save
                    </button>
                  </Box>
                )}
                renderRowActions={({ row, table }) => {
                  let text = '';
                  if (row.original['decision'] !== '') {
                    text = row.original['decision'];
                  } if (row.original['appeal'] !== '') {
                    text = row.original['appeal'];
                  }
                  return text === 'Reject' && row.original['status_view'] === 1 ? (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                      <Tooltip title="Add">
                        <IconButton
                          onClick={async () => {
                            setalert({
                              show: true,
                              data: row.original,
                              title: 'Add Data',
                              message: 'Duplicate data untuk pengajuan leasing lain...'
                            });
                            
                          }}>
                          <Add />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : null;
                }}
              />
            </KTCardBody>
          </KTCard>
        </div>
      </div>
      <SimpleAlert
        label={'Duplicate'}
        variant={'success'}
        title={alert['title']}
        content={alert['message']}
        show={alert['show']}
        onHide={() => setalert({ ...alert, show: false })}
        onConfirmed={async () => {
          await new ReportAPI().setDuplicateMscpProcess(alert['data']);
          setTimeout(() => {
            fetchingAPI(initialValues);
          }, 1000);
          setalert({ ...alert, show: false });
        }}
      />
    </React.Fragment>
  )
}
