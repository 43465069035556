import { useEffect, useRef } from 'react'
import { MscpAPI } from '../api/MscpAPI';

type leasingProp = {
    leasing: string
    nama_singkat_leasing: string
    id_leasing: number
} 
const LeasingFilter = () => {
    const leasingData = useRef([]);

    useEffect(() => {
        const getLeasing = async () => {
            const res = await new MscpAPI().getLeasing()
            
            if (res && res.data) {
                leasingData.current = res.data.map((val: leasingProp) => {
                    return ({
                        label: val.leasing,
                        value: val.leasing
                    })
                })
            };
        }
        getLeasing();
    }, []);

    return leasingData.current
}

const DateFilter = () => {
    const dateData = useRef([]);

    useEffect(() => {
        const getDate = async () => {
            const res = await new MscpAPI().getDate()
            
            if (res && res.data) {
                dateData.current = res.data.map((val: { month: string; year: string }) => {
                    return ({
                        label: `${val.month} ${val.year}`, //val.month,
                        value: `${val.month} ${val.year}`,
                        month: val.month,
                        year: val.year
                    })
                })
            };
        }
        getDate();
    }, []);

    return dateData.current
}

export {LeasingFilter , DateFilter}