import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { StickyHeadTable } from '../ActivityMarketHelper';
import { PerformanceAPI } from '../../../../api/PerformanceAPI';
import { ActivityAPI } from '../../../../api/ActivityAPI';
import { MultipleSelectCheckmarks } from '../../performance/SelectHelper';

type selectType = Array<{ label: string; value: string | number }>

const ActivityMarket = () => {
    const currDate = new Date();
    const currMonth = currDate.getMonth();
    const monthOpt = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
    ];
    const [asmVal, setasmVal] = useState<string>("");
    const [smVal, setsmVal] = useState <string>("");
    const [outletVal, setoutletVal] = useState <string>("");
    const [asmOpt, setasmOpt] = useState<selectType>([]);
    const [smOpt, setsmOpt] = useState<selectType>([]);
    const [outletOpt, setoutletOpt] = useState<selectType>([]);
    const [slcMonth, setSlcMonth] = useState<number[]>([currMonth+1]);
    const [cov, setCoverage] = useState <string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [dataTable, setDataTable] = useState<[]>([]);

    async function getFilter() {
        let val = {
            asm: asmVal.slice(),
            sm: smVal.slice(),
            outlet: outletVal.slice(),
        };
        return await new PerformanceAPI().getFilter(val).then((res) => {
            let tempAsm: selectType = [];
            let tempsm: selectType = [];
            let tempoutlet: selectType = [];
            res.data.dataAsm.map((val: { area: string; id_team_asm: string | number }) => {
                return tempAsm.push({
                    label: val.area,
                    value: val.id_team_asm,
                });
            });
            res.data.dataSm.map((val: { region: string; id_team_sm: string | number }) => {
                return tempsm.push({
                    label: val.region,
                    value: val.id_team_sm,
                });
            });
            res.data.dataOutlet.map((val: { text: string; id_outlet: string | number }) => {
                return tempoutlet.push({
                    label: val.text,
                    value: val.id_outlet,
                });
            });
            setasmOpt(tempAsm);
            setsmOpt(tempsm);
            setoutletOpt(tempoutlet);
            setCoverage(res.data.coverage);
            return res.data.coverage
        }).then((val) => {
            GetData(val, slcMonth.toString());
        });
    }

    const GetData = async (v: string, m?: string) => {
        Promise.all([
            await new ActivityAPI().activitymarket(v, m).then((res) => {
                setDataTable(res.data)
            }),
        ]).then(() =>
            setLoading(false))
    }

    useEffect(() => {
        getFilter()
        // GetData()
    }, [asmVal, smVal, outletVal, slcMonth]);
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    {
                        title: "Activity",
                        path: "/sales/activity-vs-market",
                        isSeparator: false,
                        isActive: false,
                    },
                ]}
            >
                {"Activity v Market"}
            </PageTitle>
            <div className="col d-flex justify-content-around mb-15">
                    <MultipleSelectCheckmarks
                        title={"ASM"}
                        datas={asmOpt}
                        dt={asmVal}
                        setDt={setasmVal}
                        dtwidth={300}
                    />
                    <MultipleSelectCheckmarks
                        title={"SM"}
                        datas={smOpt}
                        dt={smVal}
                        setDt={setsmVal}
                        dtwidth={300}
                    />
                    <MultipleSelectCheckmarks
                        title={"Outlet"}
                        datas={outletOpt}
                        dt={outletVal}
                        setDt={setoutletVal}
                        dtwidth={300}
                    />
                    <MultipleSelectCheckmarks
                        title={"Month"}
                        datas={monthOpt}
                        dt={slcMonth}
                        setDt={setSlcMonth}
                        dtwidth={170}
                    />
                </div>
            <div>
                <StickyHeadTable data={dataTable}/>
            </div>
        </>
    );
};

export default ActivityMarket;
