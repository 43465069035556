import React, { useEffect, useMemo, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core';
import { KTCard, KTCardBody } from '../../../../_metronic/helpers';
import { Form, Formik } from 'formik';
import FilterMultiSelect, { Option } from '../../../../_metronic/helpers/components/form-actions/FilteringInput';
import moment from "moment";
import { HighchartsSample } from '../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample';
import { MaterialReactTableDetails } from '../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';
import DataTable from './dataTable';

type dataType = {
    sm: string;
    days: number[];
    date: string;
};
type initialValues = {
    month: number[];
    asm: string[];
};

const SalesTargetManagementIndex = (props: any) => {
    const [initialValues] = useState<initialValues>({ asm: [], month: [] });
    const [loading, setloading] = useState<boolean>(true);
    const [week] = useState<any>(['W1', 'W2', 'W3', 'W4', 'W5']);
    // Approval Rate and Rejection Rate by Outlet
    const [columnApproveRejectOutlet, setColumnApproveRejectOutlet] = useState<any>();
    const [dataApproveRejectOutlet, setDataApproveRejectOutlet] = useState<any>([]);


    const [columnMme, setColumnMme] = useState<any>();
    const [dataMme, setDataMme] = useState<any>([]);
    const [columnStm, setColumnStm] = useState<any>();
    const [dataStm, setDataStm] = useState<any>([]);
    const [categoryLby] = useState<any>([
        "Gowa",
        "Kendari",
        "Kotamobagu",
        "Bulukumba",
        "Minut",
        "Bone",
        "Ternate",
        "Gianyar",
        "Gorontalo",
        "Malalayang",
        "Bandung BKR",
        "Maros",
        "Parepare",
        "Bau-bau",
        "Kolaka",
        "Perintis",
        "Pettarani",
        "Sengkang",
        "Palopo",
        "Parung",
        "Mamuju",
        "Palu",
        "Denpasar",
        "Ringroad",
        "Tomohon",
        "Morowali",
        "Denpasar",
        "Alauddin",
        "TSM",
        "Pondok Indah",
    ]);
    // API:: Credit Process
    const fetchingCreditProcessAPI = (values: object) => {
        setloading(true);
        const data: dataType[] = [{
            sm: "Pettarani - Perintis",
            days: [1, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }, {
            sm: "Gowa - Maros",
            days: [7, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }];
        let datasArray: any = [];
        data.map((item: any) => {
            let objData: any = {};
            for (let i = 0; i < item['days'].length; i++) {
                objData[`days${i + 1}`] = item['days'][i];
            }
            datasArray.push({
                sm: item['sm'],
                ...objData
            });
            return item;
        });
        let columns: any = [{
            header: 'SM',
            accessorKey: 'sm',
            size: 150,
            muiTableHeadCellProps: {
                align: "center"
            },
        }];
        // Berdasarkan Day in Month
        for (let i = 0; i < parseInt(moment('2023-09-01').endOf("month").format("D")); i++) {
            let objColumn: any = {};
            objColumn['header'] = `${i + 1}`;
            objColumn['accessorKey'] = `days${i + 1}`;
            objColumn['size'] = 10;
            objColumn['muiTableBodyCellProps'] = ({ cell }) => ({
                align: "center",
                sx: {
                    backgroundColor: cell.getValue() >= 5 ? "green" : "red",
                    color: cell.getValue() >= 5 ? "green" : "red",
                },
            });
            objColumn['muiTableHeadCellProps'] = {
                align: "center",
            };
            columns.push(objColumn);
        }
        // setColumnCreditProcess(columns);
        // setDataCreditProcess(datasArray);
        setloading(false);
    };
    // API:: MME
    const fetchingMmeAPI = (values: object) => {
        setloading(true);
        const data: dataType[] = [{
            sm: "Pondok Indah",
            days: [1, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }, {
            sm: "Parung",
            days: [7, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }];
        let datasArray: any = [];
        data.map((item: any) => {
            let objData: any = {};
            for (let i = 0; i < item['days'].length; i++) {
                objData[`days${i + 1}`] = item['days'][i];
            }
            datasArray.push({
                sm: item['sm'],
                ...objData
            });
            return item;
        });
        let columns: any = [{
            header: 'SM',
            accessorKey: 'sm',
            size: 150,
            muiTableHeadCellProps: {
                align: "center"
            },
        }];
        // Berdasarkan Data Ada
        for (let i = 0; i < parseInt(moment('2023-09-01').endOf("month").format("D")); i++) {
            let objColumn: any = {};
            objColumn['header'] = `${i + 1}`;
            objColumn['accessorKey'] = `days${i + 1}`;
            objColumn['size'] = 10;
            objColumn['muiTableBodyCellProps'] = ({ cell }) => ({
                align: "center",
                sx: {
                    backgroundColor: cell.getValue() >= 5 ? "green" : "red",
                    color: cell.getValue() >= 5 ? "green" : "red",
                },
            });
            objColumn['muiTableHeadCellProps'] = {
                align: "center",
            };
            columns.push(objColumn);
        }
        setColumnMme(columns);
        setDataMme(datasArray);
        setloading(false);
    };
    // API:: STM & Salesman Activity
    const fetchingStmAPI = () => {
        setloading(true);
        const data: dataType[] = [{
            sm: "Bandung BKR",
            days: [1, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }, {
            sm: "Parung",
            days: [7, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }];
        let datasArray: any = [];
        data.map((item: any) => {
            let objData: any = {};
            for (let i = 0; i < item['days'].length; i++) {
                objData[`days${i + 1}`] = item['days'][i];
            }
            datasArray.push({
                sm: item['sm'],
                ...objData
            });
            return item;
        });
        let columns: any = [{
            header: 'SM',
            accessorKey: 'sm',
            size: 100,
            muiTableHeadCellProps: {
                align: "center"
            },
        }];
        // Berdasarkan Year In MONTH
        let arrayMonth = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        for (let i = 0; i < arrayMonth.length; i++) {
            let objColumn: any = {};
            objColumn['header'] = arrayMonth[i];
            objColumn['accessorKey'] = `days${i + 1}`;
            objColumn['size'] = 10;
            // objColumn['maxSize'] = 10;
            objColumn['muiTableBodyCellProps'] = ({ cell }) => ({
                align: "center",
                sx: {
                    backgroundColor: cell.getValue() >= 5 ? "green" : "red",
                    color: cell.getValue() >= 5 ? "green" : "red",
                },
            });
            objColumn['muiTableHeadCellProps'] = {
                align: "center",
            };
            columns.push(objColumn);
        }
        setColumnStm(columns);
        setDataStm(datasArray);
        setloading(false);
    };
    // USEMEMO:: Credit Process Columns
    // const creditProcessColumns = useMemo(() => [{
    //     id: 'dayCreditProcess',
    //     header: "Day/is Present",
    //     columns: columnCreditProcess,
    //     muiTableHeadCellProps: {
    //         align: "right"
    //     },
    // }], [columnCreditProcess]);
    // USEMEMO:: MME Columns
    const MmeColumns = useMemo(() => [{
        id: 'dayMME',
        header: "Day/is Present",
        columns: columnMme,
        muiTableHeadCellProps: {
            align: "right"
        },
    }], [columnMme]);
    // USEMEMO:: STM & Salesman Activity
    const StmColumns = useMemo(() => [{
        id: 'daySTM',
        header: "Day/is Present",
        columns: columnStm,
        muiTableHeadCellProps: {
            align: "right"
        },
    }], [columnStm]);
    useEffect(() => {
        fetchingCreditProcessAPI(initialValues);
        fetchingMmeAPI(initialValues);
        fetchingStmAPI();
    }, [initialValues]);

    const [rejectSummary, setRejectSummary] = useState<any>({
        title: ['New SPK', 'O/S SPK'],
        values: [73.2, 26.8]
    });

    return (
        <React.Fragment>
            <PageTitle breadcrumbs={[{ title: "Sales Target Management", path: "/sales/sales-target-management", isSeparator: false, isActive: false }]}>
                {"Sales Target Management"}
            </PageTitle>
            {/* BEGIN:: Filter Input */}
            <div className="row g-5">
                <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={() => { }}>
                    {({ setFieldValue, values }) => (
                        <Form>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-lg-3 mb-5">
                                    <FilterMultiSelect
                                        label={"Outlet"}
                                        name="outlet"
                                        options={[]}
                                        onChange={(selected: Option[]) => {
                                            // setFieldValue("outlet", selected);
                                            // const arrayValueMonth = selected.map(
                                            //     (x: Object) => x["value"]
                                            // );
                                            // const arrayValueAsm = values["asm"].map(
                                            //     (x: Object) => x["value"]
                                            // );
                                            // setTimeout(() => {
                                            //     fetchingCreditProcessAPI({
                                            //         asm: arrayValueAsm,
                                            //         month: arrayValueMonth,
                                            //     })
                                            // }, 500);
                                        }}
                                        value={values["outlet"]}
                                    />
                                </div>
                                <div className="col-lg-3 mb-5">
                                    <FilterMultiSelect
                                        label={"Month"}
                                        name="month"
                                        options={[]}
                                        onChange={(selected: Option[]) => {
                                            // setFieldValue("month", selected);
                                            // const arrayValueMonth = selected.map(
                                            //     (x: Object) => x["value"]
                                            // );
                                            // const arrayValueAsm = values["asm"].map(
                                            //     (x: Object) => x["value"]
                                            // );
                                            // setTimeout(() => {
                                            //     fetchingCreditProcessAPI({
                                            //         asm: arrayValueAsm,
                                            //         month: arrayValueMonth,
                                            //     })
                                            // }, 500);
                                        }}
                                        value={values["month"]}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {/* END:: Filter Input */}
            {/* ----------------------------------------------------------------------------- */}
            <div className="row justify-content-center align-items-center">
                {/* On Process */}
                <div className="col-xxl-8 bd-highlight">
                    <KTCard className='mb-5 rounded-top-4'>
                        <KTCardBody className="row py-0 px-3">
                            <DataTable
                                maxHeight={"600px"}
                                labelHeadColor={'#316076'}
                                labelColor={'white'}
                                style={{
                                    borderTopLeftRadius: 15,
                                    borderTopRightRadius: 15,
                                    padding: 0
                                }}
                                data={{
                                    columns: [
                                        { id: 'model', label: '', align: 'left' },
                                        { id: 'jan', label: 'Jan', align: 'center' },
                                        { id: 'feb', label: 'Feb', align: 'center' },
                                        { id: 'mar', label: 'Mar', align: 'center' },
                                        { id: 'apr', label: 'Apr', align: 'center' },
                                        { id: 'may', label: 'May', align: 'center' },
                                        { id: 'jun', label: 'Jun', align: 'center' },
                                        { id: 'jul', label: 'Jul', align: 'center' },
                                        { id: 'aug', label: 'Aug', align: 'center' },
                                        { id: 'sep', label: 'Sep', align: 'center' },
                                        { id: 'oct', label: 'Oct', align: 'center' },
                                        { id: 'nov', label: 'Nov', align: 'center' },
                                        { id: 'dec', label: 'Dec', align: 'center' },
                                        { id: 'total', label: 'Total', align: 'center' },
                                    ],
                                    rows: [{
                                        model: 'Target',
                                        jan: 271,
                                        feb: 286,
                                        mar: 320,
                                        apr: 430,
                                        may: 354,
                                        jun: 365,
                                        jul: 399,
                                        aug: 521,
                                        sep: 473,
                                        oct: 276,
                                        nov: 531,
                                        dec: 604,
                                        total: '5.030',
                                    }, {
                                        model: 'Forecast',
                                        jan: 426,
                                        feb: 368,
                                        mar: 361,
                                        apr: 347,
                                        may: 429,
                                        jun: 468,
                                        jul: 393,
                                        aug: 416,
                                        sep: 383,
                                        oct: 347,
                                        nov: 17,
                                        dec: 0,
                                        total: '3.955',
                                    }, {
                                        model: 'Actual',
                                        jan: 350,
                                        feb: 285,
                                        mar: 263,
                                        apr: 282,
                                        may: 291,
                                        jun: 335,
                                        jul: 305,
                                        aug: 303,
                                        sep: 318,
                                        oct: 269,
                                        nov: '-',
                                        dec: '-',
                                        total: '3.001',
                                    }],
                                }}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* ----------------------------------------------------------------------------- */}
            <div className="row g-5">
                <div className='d-xxl-flex justify-content-center'>
                    <div className='d-flex justify-content-center flex-column w-110px'>
                        <div className='border border-gray-300 rounded bg-light'>
                            <div className='fw-bold fs-5 text-center text-gray-500 mx-2 mt-2'>Target DO</div>
                            <div className='text-center fs-2 fw-bolder text-gray-700 mx-2 mb-2'>2.515</div>
                        </div>
                        <div className='d-none text-center d-lg-block'>
                            <svg height="35" transform='rotate(90 10 10)' width="35">
                                <g fill="none" stroke="black" stroke-width="1">
                                    <line stroke-dasharray="0" x1="35" x2="0" y1="35" y2="35" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className='d-none d-lg-block'>
                        <svg height="50" width="40">
                            <g fill="none" stroke="black" stroke-width="1">
                                <line stroke-dasharray="2" x1="0" x2="99" y1="35" y2="35" />
                            </g>
                        </svg>
                    </div>
                    <div className='d-flex justify-content-center mt-3'>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2 me-3'>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Actual DO</div>
                            <div className='text-center fs-7 fw-bolder text-gray-700 mb-2'>892</div>
                        </div>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2'>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Gap DO</div>
                            <div className='text-center fs-7 fw-bolder text-danger mb-2'>-1.623</div>
                        </div>
                    </div>
                </div>
                <div className='d-none d-lg-block d-xxl-flex my-0 justify-content-start'>
                    <svg height="1" className='w-100'>
                        <g fill="none" stroke="black" stroke-width="1">
                        <line stroke-dasharray="0" x1="255" x2="1055" y1="0" y2="0" />
                        </g>
                    </svg>
                </div>
                {/* Column 2 */}
                <div className='d-xxl-flex justify-content-start mt-0'>
                    <div className=' d-flex justify-content-center mt-20'>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2 me-3'>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Gap DO</div>
                            <div className='text-center fs-7 fw-bolder text-danger mb-2'>-6</div>
                        </div>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2 '>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Actual DO</div>
                            <div className='text-center fs-7 fw-bolder text-gray-700 mb-2'>10</div>
                        </div>
                    </div>
                    <div className='d-none d-lg-block'>
                        <svg height="100" width="40">
                            <g fill="none" stroke="black" stroke-width="1">
                                <line stroke-dasharray="2" x1="0" x2="99" y1="90" y2="90" />
                            </g>
                        </svg>
                    </div>
                    {/* O/S SPK */}
                    <div className='d-flex flex-column w-120px'>
                        <div className='d-none text-center mt-0 d-lg-block'>
                            <svg height="35" transform='rotate(90 10 10)' width="35">
                                <g fill="none" stroke="black" stroke-width="1">
                                    <line stroke-dasharray="0" x1="35" x2="0" y1="35" y2="35" />
                                </g>
                            </svg>
                        </div>
                        <div className='border border-gray-300 rounded bg-light'>
                            <div className='fw-bold fs-6 text-center text-gray-500 mx-5 mt-2'>O/S SPK</div>
                            <DataTable
                                maxHeight={"600px"}
                                labelHeadColor={'#297f9d'}
                                labelColor={'white'}
                                style={{
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    padding: 1,
                                }}
                                data={{
                                    columns: [
                                        { id: 'do', label: 'DO', align: 'center' },
                                        { id: 'spk', label: 'SPK', align: 'center' },
                                        { id: 'conv', label: 'Conv.', align: 'center' },
                                    ],
                                    rows: [{
                                        do: 16,
                                        spk: 23,
                                        conv: '69%',
                                    }],
                                }}
                            />
                        </div>
                    </div>
                    {/* Line Null */}
                    <svg className='' height="50" width="450">
                        <g fill="none" stroke="black" stroke-width="1">
                            <line stroke-dasharray="2" x1="0" x2="0" y1="30" y2="30" />
                        </g>
                    </svg>
                    <div className='d-flex justify-content-center mt-20'>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2 me-3'>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Gap DO</div>
                            <div className='text-center fs-7 fw-bolder text-danger mb-2'>-41</div>
                        </div>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2 '>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Actual DO</div>
                            <div className='text-center fs-7 fw-bolder text-gray-700 mb-2'>33</div>
                        </div>
                    </div>
                    <div className='d-none d-lg-block'>
                        <svg height="100" width="40">
                            <g fill="none" stroke="black" stroke-width="1">
                                <line stroke-dasharray="2" x1="0" x2="99" y1="90" y2="90" />
                            </g>
                        </svg>
                    </div>
                    {/* New SPK */}
                    <div className='d-flex justify-content-center flex-column w-120px'>
                        <div className='d-none text-center mt-0 d-lg-block'>
                            <svg height="35" transform='rotate(90 10 10)' width="35">
                                <g fill="none" stroke="black" stroke-width="1">
                                    <line stroke-dasharray="0" x1="35" x2="0" y1="35" y2="35" />
                                </g>
                            </svg>
                        </div>
                        <div className='border border-gray-300 rounded bg-light'>
                            <div className='fw-bold fs-6 text-center text-gray-500 mx-5 mt-2'>New SPK</div>
                            <DataTable
                                maxHeight={"600px"}
                                labelHeadColor={'#388e3c'}
                                labelColor={'white'}
                                style={{
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    padding: 1,
                                }}
                                data={{
                                    columns: [
                                        { id: 'do', label: 'DO', align: 'center' },
                                        { id: 'spk', label: 'SPK', align: 'center' },
                                        { id: 'conv', label: 'Conv.', align: 'center' },
                                    ],
                                    rows: [{
                                        do: 74,
                                        spk: 112,
                                        conv: '66%',
                                    }],
                                }}
                            />
                        </div>
                        <div className='d-none text-center d-lg-block'>
                            <svg height="35" transform='rotate(90 10 10)' width="35">
                                <g fill="none" stroke="black" stroke-width="1">
                                    <line stroke-dasharray="0" x1="35" x2="0" y1="35" y2="35" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className='d-none d-lg-block'>
                        <svg height="100" width="40">
                            <g fill="none" stroke="black" stroke-width="1">
                                <line stroke-dasharray="2" x1="0" x2="99" y1="90" y2="90" />
                            </g>
                        </svg>
                    </div>
                    <div className=' d-flex justify-content-center mt-20'>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2 me-3'>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Actual SPK</div>
                            <div className='text-center fs-7 fw-bolder text-gray-700 mb-2'>56</div>
                        </div>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2'>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Gap SPK</div>
                            <div className='text-center fs-7 fw-bolder text-danger mb-2'>-56</div>
                        </div>
                    </div>
                </div>
                <div className='d-none d-lg-block d-xxl-flex my-0 justify-content-end'>
                    <svg height="1" className='w-1000px'>
                        <g fill="none" stroke="black" stroke-width="1">
                        <line stroke-dasharray="0" x1="210" x2="723" y1="0" y2="0" />
                        </g>
                    </svg>
                </div>
                {/* Column 3 */}
                <div className='d-xxl-flex justify-content-end mt-0'>
                    <div className=' d-flex justify-content-center mt-20'>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2 me-3'>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Gap DO</div>
                            <div className='text-center fs-7 fw-bolder text-danger mb-2'>-2</div>
                        </div>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2 '>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Actual DO</div>
                            <div className='text-center fs-7 fw-bolder text-gray-700 mb-2'>0</div>
                        </div>
                    </div>
                    <div className='d-none d-lg-block'>
                        <svg height="100" width="40">
                            <g fill="none" stroke="black" stroke-width="1">
                                <line stroke-dasharray="2" x1="0" x2="99" y1="90" y2="90" />
                            </g>
                        </svg>
                    </div>
                    {/* O/S Leads */}
                    <div className='d-flex flex-column w-120px'>
                        <div className='d-none text-center mt-0 d-lg-block'>
                            <svg height="35" transform='rotate(90 10 10)' width="35">
                                <g fill="none" stroke="black" stroke-width="1">
                                    <line stroke-dasharray="0" x1="35" x2="0" y1="35" y2="35" />
                                </g>
                            </svg>
                        </div>
                        <div className='border border-gray-300 rounded bg-light'>
                            <div className='fw-bold fs-6 text-center text-gray-500 mx-5 mt-2'>O/S Leads</div>
                            <DataTable
                                maxHeight={"600px"}
                                labelHeadColor={'#384967'}
                                labelColor={'white'}
                                style={{
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    padding: 1,
                                }}
                                data={{
                                    columns: [
                                        { id: 'spk', label: 'SPK', align: 'center' },
                                        { id: 'leads', label: 'Leads', align: 'center' },
                                        { id: 'conv', label: 'Conv.', align: 'center' },
                                    ],
                                    rows: [{
                                        spk: 2,
                                        leads: '3.594',
                                        conv: '0,1%',
                                    }],
                                }}
                            />
                        </div>
                    </div>
                    {/* Line Null */}
                    <svg height="50" width="150">
                        <g fill="none" stroke="black" stroke-width="1">
                            <line stroke-dasharray="2" x1="0" x2="0" y1="30" y2="30" />
                        </g>
                    </svg>
                    <div className=' d-flex justify-content-center mt-20'>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2 me-3'>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Gap SPK</div>
                            <div className='text-center fs-7 fw-bolder text-danger mb-2'>-54</div>
                        </div>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2 '>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Actual SPK</div>
                            <div className='text-center fs-7 fw-bolder text-gray-700 mb-2'>56</div>
                        </div>
                    </div>
                    <div className='d-none d-lg-block'>
                        <svg height="100" width="40">
                            <g fill="none" stroke="black" stroke-width="1">
                                <line stroke-dasharray="2" x1="0" x2="99" y1="90" y2="90" />
                            </g>
                        </svg>
                    </div>
                    {/* New Leads */}
                    <div className='d-flex justify-content-center flex-column w-120px'>
                        <div className='d-none text-center mt-0 d-lg-block'>
                            <svg height="35" transform='rotate(90 10 10)' width="35">
                                <g fill="none" stroke="black" stroke-width="1">
                                    <line stroke-dasharray="0" x1="35" x2="0" y1="35" y2="35" />
                                </g>
                            </svg>
                        </div>
                        <div className='border border-gray-300 rounded bg-light'>
                            <div className='fw-bold fs-6 text-center text-gray-500 mx-5 mt-2'>New Leads</div>
                            <DataTable
                                maxHeight={"600px"}
                                labelHeadColor={'#00897b'}
                                labelColor={'white'}
                                style={{
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    padding: 1,
                                }}
                                data={{
                                    columns: [
                                        { id: 'do', label: 'DO', align: 'center' },
                                        { id: 'spk', label: 'SPK', align: 'center' },
                                        { id: 'conv', label: 'Conv.', align: 'center' },
                                    ],
                                    rows: [{
                                        do: 74,
                                        spk: 112,
                                        conv: '66%',
                                    }],
                                }}
                            />
                        </div>
                        <div className='d-none text-center d-lg-block'>
                            <svg height="35" transform='rotate(90 10 10)' width="35">
                                <g fill="none" stroke="black" stroke-width="1">
                                    <line stroke-dasharray="0" x1="35" x2="0" y1="35" y2="35" />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className='d-none d-lg-block'>
                        <svg height="100" width="40">
                            <g fill="none" stroke="black" stroke-width="1">
                                <line stroke-dasharray="2" x1="0" x2="99" y1="90" y2="90" />
                            </g>
                        </svg>
                    </div>
                    <div className=' d-flex justify-content-center mt-20'>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2 me-3'>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Actual Leads</div>
                            <div className='text-center fs-7 fw-bolder text-gray-700 mb-2'>3.658</div>
                        </div>
                        <div className='border border-gray-300 rounded bg-light h-50px px-2'>
                            <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Gap Leads</div>
                            <div className='text-center fs-7 fw-bolder text-danger mb-2'>-3.422</div>
                        </div>
                    </div>
                </div>
                <div className='d-none d-lg-block d-xxl-flex mt-0 justify-content-end'>
                    <svg height="1" width="900">
                        <g fill="none" stroke="black" stroke-width="1">
                            <line stroke-dasharray="0" x1="173" x2="758" y1="0" y2="0" />
                        </g>
                    </svg>
                </div>
                <div className='d-xxl-flex justify-content-end mt-0'>
                    <div className='d-flex flex-column w-120px'>
                        <div className='d-none text-center mt-0 d-lg-block'>
                            <svg height="35" transform='rotate(90 10 10)' width="35">
                                <g fill="none" stroke="black" stroke-width="1">
                                    <line stroke-dasharray="0" x1="35" x2="0" y1="35" y2="35" />
                                </g>
                            </svg>
                        </div>
                        <div className='border border-gray-300 rounded-top-4 me-xxl-3 mb-5 bg-light'>
                            <div className='fw-bold fs-7 text-center text-white py-2 rounded-top-4' style={{ backgroundColor: '#297f9d' }}>Exhibition</div>
                            <div className='row align-content-center justify-content-center'>
                                <div className='d-flex flex-center flex-wrap m-0 mw-150px'>
                                    <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>SPK</div>
                                    <DataTable
                                        maxHeight={"600px"}
                                        labelHeadColor={'#d9d9d9'}
                                        labelColor={'black'}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            padding: 1,
                                            fontSize: 8.5,
                                            fontSizeBody: 9,
                                        }}
                                        data={{
                                            columns: [
                                                { id: 'spk', label: 'SPK', align: 'center' },
                                                { id: 'conv', label: 'Conv.', align: 'center' },
                                            ],
                                            rows: [{
                                                spk: 'null',
                                                conv: '0,0%',
                                            }],
                                        }}
                                    />
                                </div>
                                <div className='d-flex flex-center flex-wrap mb-0 mw-150px'>
                                    <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Leads</div>
                                    <DataTable
                                        maxHeight={"600px"}
                                        labelHeadColor={'#d9d9d9'}
                                        labelColor={'black'}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            padding: 1,
                                            fontSize: 8.5,
                                            fontSizeBody: 9,
                                        }}
                                        data={{
                                            columns: [
                                                { id: 'leads', label: 'Leads', align: 'center' },
                                                { id: 'conv', label: 'Conv.', align: 'center' },
                                            ],
                                            rows: [{
                                                leads: 'null',
                                                conv: 'null',
                                            }],
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row align-content-center justify-content-center'>
                                <div className='d-flex flex-center flex-wrap m-0 mw-300px'>
                                    <div className='fw-bold fs-7 text-center text-gray-500 mt-2'>Manpower</div>
                                    <DataTable
                                        maxHeight={"600px"}
                                        labelHeadColor={'#d9d9d9'}
                                        labelColor={'black'}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            padding: 1,
                                            fontSize: 8.5,
                                            fontSizeBody: 9,
                                        }}
                                        data={{
                                            columns: [
                                                { id: 'mpq', label: 'MP Qty', align: 'center' },
                                                { id: 'freq', label: 'Freq', align: 'center' },
                                                { id: 'total', label: 'Total MP', align: 'center' },
                                                { id: 'spk', label: 'SPK/MP', align: 'center' },
                                            ],
                                            rows: [{
                                                mpq: 'null',
                                                freq: 'null',
                                                total: 'null',
                                                spk: 'null',
                                            }],
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row align-content-center justify-content-center'>
                                <div className='d-flex flex-center flex-wrap m-0 mw-300px'>
                                    <div className='fw-bold fs-7 text-center text-gray-500 mt-2'>Cost</div>
                                    <DataTable
                                        maxHeight={"600px"}
                                        labelHeadColor={'#d9d9d9'}
                                        labelColor={'black'}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            padding: 1,
                                            fontSize: 8.5,
                                            fontSizeBody: 9,
                                        }}
                                        data={{
                                            columns: [
                                                { id: 'freq', label: 'Freq', align: 'center' },
                                                { id: 'event', label: 'Event Cost', align: 'center' },
                                                { id: 'total', label: 'Total Cost', align: 'center' },
                                                { id: 'cost', label: 'Cost/SPK', align: 'center' },
                                            ],
                                            rows: [{
                                                freq: 'null',
                                                event: 'null',
                                                total: 'null',
                                                cost: 'null',
                                            }],
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column w-120px'>
                        <div className='d-none text-center mt-0 d-lg-block'>
                            <svg height="35" transform='rotate(90 10 10)' width="35">
                                <g fill="none" stroke="black" stroke-width="1">
                                    <line stroke-dasharray="0" x1="35" x2="0" y1="35" y2="35" />
                                </g>
                            </svg>
                        </div>
                        <div className='border border-gray-300 rounded-top-4 me-xxl-3 mb-5 bg-light'>
                            <div className='fw-bold fs-7 text-center text-white py-2 rounded-top-4' style={{ backgroundColor: '#00897b' }}>Moving Event</div>
                            <div className='row align-content-center justify-content-center'>
                                <div className='d-flex flex-center flex-wrap m-0 mw-150px'>
                                    <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>SPK</div>
                                    <DataTable
                                        maxHeight={"600px"}
                                        labelHeadColor={'#d9d9d9'}
                                        labelColor={'black'}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            padding: 1,
                                            fontSize: 8.5,
                                            fontSizeBody: 9,
                                        }}
                                        data={{
                                            columns: [
                                                { id: 'spk', label: 'SPK', align: 'center' },
                                                { id: 'conv', label: 'Conv.', align: 'center' },
                                            ],
                                            rows: [{
                                                spk: 'null',
                                                conv: '0,0%',
                                            }],
                                        }}
                                    />
                                </div>
                                <div className='d-flex flex-center flex-wrap mb-0 mw-150px'>
                                    <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Leads</div>
                                    <DataTable
                                        maxHeight={"600px"}
                                        labelHeadColor={'#d9d9d9'}
                                        labelColor={'black'}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            padding: 1,
                                            fontSize: 8.5,
                                            fontSizeBody: 9,
                                        }}
                                        data={{
                                            columns: [
                                                { id: 'leads', label: 'Leads', align: 'center' },
                                                { id: 'conv', label: 'Conv.', align: 'center' },
                                            ],
                                            rows: [{
                                                leads: 'null',
                                                conv: 'null',
                                            }],
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row align-content-center justify-content-center'>
                                <div className='d-flex flex-center flex-wrap m-0 mw-300px'>
                                    <div className='fw-bold fs-7 text-center text-gray-500 mt-2'>Manpower</div>
                                    <DataTable
                                        maxHeight={"600px"}
                                        labelHeadColor={'#d9d9d9'}
                                        labelColor={'black'}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            padding: 1,
                                            fontSize: 8.5,
                                            fontSizeBody: 9,
                                        }}
                                        data={{
                                            columns: [
                                                { id: 'mpq', label: 'MP Qty', align: 'center' },
                                                { id: 'freq', label: 'Freq', align: 'center' },
                                                { id: 'total', label: 'Total MP', align: 'center' },
                                                { id: 'spk', label: 'SPK/MP', align: 'center' },
                                            ],
                                            rows: [{
                                                mpq: 'null',
                                                freq: 'null',
                                                total: 'null',
                                                spk: 'null',
                                            }],
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row align-content-center justify-content-center'>
                                <div className='d-flex flex-center flex-wrap m-0 mw-300px'>
                                    <div className='fw-bold fs-7 text-center text-gray-500 mt-2'>Cost</div>
                                    <DataTable
                                        maxHeight={"600px"}
                                        labelHeadColor={'#d9d9d9'}
                                        labelColor={'black'}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            padding: 1,
                                            fontSize: 8.5,
                                            fontSizeBody: 9,
                                        }}
                                        data={{
                                            columns: [
                                                { id: 'freq', label: 'Freq', align: 'center' },
                                                { id: 'event', label: 'Event Cost', align: 'center' },
                                                { id: 'total', label: 'Total Cost', align: 'center' },
                                                { id: 'cost', label: 'Cost/SPK', align: 'center' },
                                            ],
                                            rows: [{
                                                freq: 'null',
                                                event: 'null',
                                                total: 'null',
                                                cost: 'null',
                                            }],
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column w-120px'>
                        <div className='d-none text-center mt-0 d-lg-block'>
                            <svg height="35" transform='rotate(90 10 10)' width="35">
                                <g fill="none" stroke="black" stroke-width="1">
                                    <line stroke-dasharray="0" x1="35" x2="0" y1="35" y2="35" />
                                </g>
                            </svg>
                        </div>
                        <div className='border border-gray-300 rounded-top-4 me-xxl-3 mb-5 bg-light'>
                            <div className='fw-bold fs-7 text-center text-white py-2 rounded-top-4' style={{ backgroundColor: '#43a047' }}>Closing Event</div>
                            <div className='row align-content-center justify-content-center'>
                                <div className='d-flex flex-center flex-wrap m-0 mw-150px'>
                                    <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>SPK</div>
                                    <DataTable
                                        maxHeight={"600px"}
                                        labelHeadColor={'#d9d9d9'}
                                        labelColor={'black'}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            padding: 1,
                                            fontSize: 8.5,
                                            fontSizeBody: 9,
                                        }}
                                        data={{
                                            columns: [
                                                { id: 'spk', label: 'SPK', align: 'center' },
                                                { id: 'conv', label: 'Conv.', align: 'center' },
                                            ],
                                            rows: [{
                                                spk: 'null',
                                                conv: '0,0%',
                                            }],
                                        }}
                                    />
                                </div>
                                <div className='d-flex flex-center flex-wrap mb-0 mw-150px'>
                                    <div className='fw-bold fs-9 text-center text-gray-500 mt-2'>Leads</div>
                                    <DataTable
                                        maxHeight={"600px"}
                                        labelHeadColor={'#d9d9d9'}
                                        labelColor={'black'}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            padding: 1,
                                            fontSize: 8.5,
                                            fontSizeBody: 9,
                                        }}
                                        data={{
                                            columns: [
                                                { id: 'leads', label: 'Leads', align: 'center' },
                                                { id: 'conv', label: 'Conv.', align: 'center' },
                                            ],
                                            rows: [{
                                                leads: 'null',
                                                conv: 'null',
                                            }],
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row align-content-center justify-content-center'>
                                <div className='d-flex flex-center flex-wrap m-0 mw-300px'>
                                    <div className='fw-bold fs-7 text-center text-gray-500 mt-2'>Manpower</div>
                                    <DataTable
                                        maxHeight={"600px"}
                                        labelHeadColor={'#d9d9d9'}
                                        labelColor={'black'}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            padding: 1,
                                            fontSize: 8.5,
                                            fontSizeBody: 9,
                                        }}
                                        data={{
                                            columns: [
                                                { id: 'mpq', label: 'MP Qty', align: 'center' },
                                                { id: 'freq', label: 'Freq', align: 'center' },
                                                { id: 'total', label: 'Total MP', align: 'center' },
                                                { id: 'spk', label: 'SPK/MP', align: 'center' },
                                            ],
                                            rows: [{
                                                mpq: 'null',
                                                freq: 'null',
                                                total: 'null',
                                                spk: 'null',
                                            }],
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row align-content-center justify-content-center'>
                                <div className='d-flex flex-center flex-wrap m-0 mw-300px'>
                                    <div className='fw-bold fs-7 text-center text-gray-500 mt-2'>Cost</div>
                                    <DataTable
                                        maxHeight={"600px"}
                                        labelHeadColor={'#d9d9d9'}
                                        labelColor={'black'}
                                        style={{
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            padding: 1,
                                            fontSize: 8.5,
                                            fontSizeBody: 9,
                                        }}
                                        data={{
                                            columns: [
                                                { id: 'freq', label: 'Freq', align: 'center' },
                                                { id: 'event', label: 'Event Cost', align: 'center' },
                                                { id: 'total', label: 'Total Cost', align: 'center' },
                                                { id: 'cost', label: 'Cost/SPK', align: 'center' },
                                            ],
                                            rows: [{
                                                freq: 'null',
                                                event: 'null',
                                                total: 'null',
                                                cost: 'null',
                                            }],
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default SalesTargetManagementIndex;