import * as React from "react";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  ListSubheader,
  InputAdornment,
  TextField,
  Autocomplete,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
// import Button from "@mui/material/Button";
// import CheckIcon from "@mui/icons-material/Check";
// import CancelIcon from "@mui/icons-material/Cancel";
// import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MultipleSelectCheckmarks({ title, datas, dt, setDt, dtwidth }) {
  const [searchText, setSearchText] = React.useState("");
  const [tempValue,setTempValue] = React.useState(dt ? dt : [])
  const prev = React.useRef(null);
  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  // React.useEffect (() => {
  //   console.log("Cek datas",datas);
  //   console.log("Cek selected",dt);
  // }, [selected]);
  const displayedOptions = datas.filter((option) => containsText(option.label, searchText));
  const isAllSelected = datas.length > 0 && tempValue.length === datas.length;

  const handleChange = (event) => {
    event.stopPropagation()
    const value = event.target.value;
    if (value[Object.keys(value).length - 1] === "all") {
      setTempValue(tempValue.length === datas.length ? [] : datas.map((v) => v.value));
      return;
    }
    setTempValue(value);
  };

  React.useEffect(() => {
    prev.current = tempValue;
  }, [dt, tempValue]);

  return (
    <>
      <FormControl sx={{ m: 1, width: dtwidth ? dtwidth : 300 }}>
        <InputLabel id="demo-multiple-checkbox-label" sx={{color: "blueviolet"}}>{title}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={tempValue.label ? tempValue.label : tempValue}
          onChange={handleChange}
          onClose={(e) => {
            e.preventDefault()
            setDt(tempValue)
            setSearchText("")
          }}
          input={<OutlinedInput label={title} />}
          // renderValue={
          //   selected.length > 0
          //     ? undefined
          //     : () => <em>Placeholder</em>
          // }
          // renderValue={(selected) => selected.map(v => v.label).join(", ")}
          renderValue={(selected) => `Data Selected (${tempValue.length})`}
          MenuProps={MenuProps}
          sx={{
            color: "rgb(85,85,85)",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "rgb(85,85,85)",
            },
            // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            //   borderColor: "rgba(228, 219, 233, 0.25)",
            // },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgb(85,85,85)",
            },
            ".MuiSvgIcon-root ": {
              fill: "blue !important",
            },
          }}
        >
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={tempValue.length > 0 && tempValue.length < datas.length}
                // onChange={(e) => handleChange1(e.target.checked)}
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>
          {displayedOptions.map((name) => (
            <MenuItem className="justify-content-start" key={name.value} value={name.value}>
              <Checkbox checked={tempValue.indexOf(name.value) > -1} />
              {name.label}
              {/* <Button variant="contained" onClick={(e) => clickOnly(name.value)}>Only</Button> */}
              {/* <ListItemText primary={name} /> */}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* {console.log("Selected Value", selected)} */}
    </>
  );
}

export default function SingleAutocomplete({ title, datas, dt, setDt }) {
  const [inputValue, setInputValue] = React.useState("")
  React.useEffect(() => {
    // console.log(datas.filter((v) => v.value === dt));
  },[dt])
  return (
    <Autocomplete
        value={datas.filter((v) => v.value === dt)[0] || null}
        onChange={(event, newValue) => {
          console.log("New Value", newValue,dt);
          setDt(newValue !== null ? newValue.value : null);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={datas}
        sx={{m:1, width: 300 }}
        renderInput={(params) => <TextField {...params} label={title} />}
      />
  );
}

// const MultiAutocomplete = () => {
//   return (
//     <Autocomplete
//       sx={{ m: 1, width: 500 }}
//       multiple
//       id="tags-standard"
//       options={datas}
//       getOptionLabel={(option) => option}
//       // defaultValue={[datas[0], datas[1]]}
//       disableCloseOnSelect
//       renderOption={(props, option, { selected }) => (
//         <MenuItem
//           key={option}
//           value={option}
//           sx={{ justifyContent: "space-between" }}
//           {...props}
//         >
//           {option}
//           {selected ? <CheckIcon color="info" /> : null}
//         </MenuItem>
//       )}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           variant="outlined"
//           label="Multiple Autocomplete"
//           placeholder=""
//         />
//       )}
//     />
//   );
// };

export { MultipleSelectCheckmarks, SingleAutocomplete };
