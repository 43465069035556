import React from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { AvatarSalesCompetency, ImprovementTable, SalesmanTable, SmallTableCompetency, TrainingTable } from '../SalesCompetencyHelper'

function TrainingRecomendation() {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    {
                        title: "Man Power",
                        path: "/sales/training-recomendation",
                        isSeparator: false,
                        isActive: false,
                    },
                ]}
            >
                {"Training Recomendation"}
            </PageTitle>
            <div className='row d-flex justify-content-between my-10'>
                <div className='card col-lg-4' style={{ maxWidth: "35rem" }}>
                    <AvatarSalesCompetency area={"Java"}/>
                </div>
                <div className='card col-lg-4' style={{ maxWidth: "35rem" }}>
                    <AvatarSalesCompetency area={"Sulbagsel-Bali"}/>
                </div>
                <div className='card col-lg-4' style={{ maxWidth: "35rem" }}>
                    <AvatarSalesCompetency area={"Sulbagut Ternate"}/>
                </div>
            </div>

            <div className='row d-flex justify-content-between'>
                <div className='col-lg-4'>
                <SmallTableCompetency title={"Sales Manager"} head={"SM"}/>
                </div>
                <div className='col-lg-4'>
                <SmallTableCompetency title={"Outlet"} head={"Outlet"}/>
                </div>
                <div className='col-lg-4'>
                <ImprovementTable title={"SPV"} />
                </div>
            </div>
            <div className='my-10'>
                <TrainingTable title={"Training Recomendation v Execution"}/>
            </div>
        </>
    )
}

export default TrainingRecomendation

