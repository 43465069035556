import React, { useEffect, useRef } from 'react'
import { TextColor, backgroundColorHSLDarkToLight, spanString } from '../../../../../_metronic/helpers/components/MaterialReactTable/ComponentStyleRowsTable';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from "highcharts";

type MoM = {
    month: string;
    processed: null;
    approve: null;
    approve_regular: null;
    approve_up_dp: null;
    approve_up_dp_deposit: null;
    approve_up_deposit: null;
    rejection: null;
    inProcess: null;
  }
class RatebyOutlet {
    constructor(data = {
        appin: 0,
        approve: 0,
        approve_regular: 0,
        approve_up_deposit: 0,
        approve_up_dp: 0,
        approve_up_dp_deposit: 0,
        cabang: 0,
        inProcess: 0,
        processed: 0,
        rejection: 0,
        totalAppin: 0,
        totalProcessed: 0,
        totalApprove: 0,
        maxApproveRegular: 0,
        maxApproveUpDp: 0,
        maxApproveUpDeposit: 0,
        maxRejection: 0,
        maxInProcess: 0
    }) {
        const { appin, approve, approve_regular, approve_up_deposit, approve_up_dp, approve_up_dp_deposit, cabang, inProcess, processed, rejection, totalAppin, totalProcessed, totalApprove, maxApproveRegular, maxApproveUpDp, maxApproveUpDeposit, maxRejection, maxInProcess } = data
        this['appin'] = appin;
        this["processed"] = processed
        this["approve"] = approve;
        this["approve_regular"] = approve_regular
        this["approve_up_deposit"] = approve_up_deposit
        this["approve_up_dp"] = approve_up_dp
        this["approve_up_dp_deposit"] = approve_up_dp_deposit
        this["cabang"] = cabang
        this["inProcess"] = inProcess
        this["rejection"] = rejection;
        this["totalAppin"] = totalAppin
        this["totalProcessed"] = totalProcessed
        this["totalApprove"] = totalApprove
        this["maxApproveRegular"] = maxApproveRegular
        this["maxApproveUpDp"] = maxApproveUpDp
        this["maxApproveUpDeposit"] = maxApproveUpDeposit
        this["maxRejection"] = maxRejection
        this["maxInProcess"] = maxInProcess
    }
    async getdata(this: any) {
        const { appin, approve, approve_regular, approve_up_deposit, approve_up_dp, approve_up_dp_deposit, cabang, inProcess, processed, rejection, totalAppin, totalProcessed, totalApprove, maxApproveRegular, maxApproveUpDp, maxApproveUpDeposit, maxRejection, maxInProcess } = this
        return [{
            header: "cabang",
            id: "cabang",
            filterVariant: "select",
            value: cabang,
            size: 60,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: `App In`,
            id: "appin",
            value: appin,
            size: 20,
            footer: totalAppin
        }, {
            header: `Processed`,
            id: "processed",
            value: processed,
            size: 20,
            footer: totalProcessed
        }, {
            header: "Approve",
            id: "approve",
            value: approve,
            Cell: ({ cell }) => {
                return cell.getValue()
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: cell.getValue() === 0 ? '#f4c7c3' : '#c8e6c9'
                    },
                    align: 'center',
                })
            },
            size: 20,
            footer: totalApprove
        }, {
            header: `Approval Rate (Regular)`,
            id: "approval_rate_regular",
            value: approve_regular === 0 || processed === 0 ? 0 : ((approve_regular / processed) * 100).toFixed(1),
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["206.53", "89.09%", "67.65%"], cell.getValue(), maxApproveRegular)
                    },
                    align: 'center',
                })
            },
            size: 50,
            // footer: 
        }, {
            header: `Approval Rate (Up DP)`,
            id: "approval_rate_up_dp",
            value: approve_up_dp === 0 || processed === 0 ? 0 : ((approve_up_dp / processed) * 100).toFixed(1),
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["45.68", "100%", "65.49%"], cell.getValue(), maxApproveUpDp)
                    },
                    align: 'center',
                })
            },
            size: 50,
            // footer: sum_approval_rate_up_dp
        }, {
            header: `Approval Rate (Up DP & Deposit)`,
            id: "approval_rate_dp_deposit",
            value: approve_up_dp_deposit === 0 || processed === 0 ? 0 : ((approve_up_dp_deposit / processed) * 100).toFixed(1),
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["186.89", "71.15%", "59.22%"], cell.getValue(), maxApproveUpDeposit)
                    },
                    align: 'center',
                })
            },
            size: 50,
            // footer: sum_approval_rate_dp_deposit
        }, {
            header: `Approval Rate (Deposit)`,
            id: "approval_rate_deposit",
            value: approve_up_deposit === 0 || processed === 0 ? 0 : ((approve_up_deposit / processed) * 100).toFixed(1),
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["0", "52.53%", "61.18%"], cell.getValue(), maxApproveUpDeposit)
                    },
                    align: 'center',
                })
            },
            size: 50,
            // footer: sum_approval_rate_deposit
        }, {
            header: `Rejection Rate`,
            id: "rejection_rate",
            value: rejection === 0 || processed === 0 ? 0 : ((rejection / processed) * 100).toFixed(1),
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["0", "0%", "80%"], cell.getValue(), maxRejection)
                    },
                    align: 'center',
                })
            },
            size: 50,
            // footer: sum_rejection_rate
        }, {
            header: `In Process`,
            id: "in_process",
            value: inProcess === 0 || processed === 0 ? 0 : ((inProcess / processed) * 100).toFixed(1),
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["34", "96%", "74%"], cell.getValue(), maxInProcess)
                    },
                    align: 'center',
                })
            },
            size: 50,
            // footer: sum_in_process
        }]
    }
};

class RatebyModel {
    constructor(data = {
        appin: 0,
        approve: 0,
        approve_regular: 0,
        approve_up_deposit: 0,
        approve_up_dp: 0,
        approve_up_dp_deposit: 0,
        model: 0,
        inProcess: 0,
        processed: 0,
        rejection: 0,
        totalAppin: 0,
        totalProcessed: 0,
        totalApprove: 0,
        maxApproveRegular: 0,
        maxApproveUpDp: 0,
        maxApproveUpDeposit: 0,
        maxRejection: 0,
        maxInProcess: 0
    }) {
        const { appin, approve, approve_regular, approve_up_deposit, approve_up_dp, approve_up_dp_deposit, model, inProcess, processed, rejection, totalAppin, totalProcessed, totalApprove, maxApproveRegular, maxApproveUpDp, maxApproveUpDeposit, maxRejection, maxInProcess } = data
        this['appin'] = appin;
        this["processed"] = processed
        this["approve"] = approve;
        this["approve_regular"] = approve_regular
        this["approve_up_deposit"] = approve_up_deposit
        this["approve_up_dp"] = approve_up_dp
        this["approve_up_dp_deposit"] = approve_up_dp_deposit
        this["model"] = model
        this["inProcess"] = inProcess
        this["rejection"] = rejection;
        this["totalAppin"] = totalAppin
        this["totalProcessed"] = totalProcessed
        this["totalApprove"] = totalApprove
        this["maxApproveRegular"] = maxApproveRegular
        this["maxApproveUpDp"] = maxApproveUpDp
        this["maxApproveUpDeposit"] = maxApproveUpDeposit
        this["maxRejection"] = maxRejection
        this["maxInProcess"] = maxInProcess
    }
    async getdata(this: any) {
        const { appin, approve, approve_regular, approve_up_deposit, approve_up_dp, approve_up_dp_deposit, model, inProcess, processed, rejection, totalAppin, totalProcessed, totalApprove, maxApproveRegular, maxApproveUpDp, maxApproveUpDeposit, maxRejection, maxInProcess } = this
        return [{
            header: "model",
            id: "model",
            filterVariant: "select",
            value: model,
            size: 60,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: `App In`,
            id: "appin",
            value: appin,
            size: 20,
            footer: totalAppin
        }, {
            header: `Processed`,
            id: "processed",
            value: processed,
            size: 20,
            footer: totalProcessed
        }, {
            header: `Approval Rate (Regular)`,
            id: "approval_rate_regular",
            value: approve_regular === 0 || processed === 0 ? 0 : ((approve_regular / processed) * 100).toFixed(1),
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["206.53", "89.09%", "67.65%"], cell.getValue(), maxApproveRegular)
                    },
                    align: 'center',
                })
            },
            size: 50,
            // footer: 
        }, {
            header: `Approval Rate (Up DP)`,
            id: "approval_rate_up_dp",
            value: approve_up_dp === 0 || processed === 0 ? 0 : ((approve_up_dp / processed) * 100).toFixed(1),
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["45.68", "100%", "65.49%"], cell.getValue(), maxApproveUpDp)
                    },
                    align: 'center',
                })
            },
            size: 50,
            // footer: sum_approval_rate_up_dp
        }, {
            header: `Approval Rate (Up DP & Deposit)`,
            id: "approval_rate_dp_deposit",
            value: approve_up_dp_deposit === 0 || processed === 0 ? 0 : ((approve_up_dp_deposit / processed) * 100).toFixed(1),
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["186.89", "71.15%", "59.22%"], cell.getValue(), maxApproveUpDeposit)
                    },
                    align: 'center',
                })
            },
            size: 50,
            // footer: sum_approval_rate_dp_deposit
        }, {
            header: `Approval Rate (Deposit)`,
            id: "approval_rate_deposit",
            value: approve_up_deposit === 0 || processed === 0 ? 0 : ((approve_up_deposit / processed) * 100).toFixed(1),
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["0", "52.53%", "61.18%"], cell.getValue(), maxApproveUpDeposit)
                    },
                    align: 'center',
                })
            },
            size: 50,
            // footer: sum_approval_rate_deposit
        }, {
            header: `Rejection Rate`,
            id: "rejection_rate",
            value: rejection === 0 || processed === 0 ? 0 : ((rejection / processed) * 100).toFixed(1),
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["0", "0%", "80%"], cell.getValue(), maxRejection)
                    },
                    align: 'center',
                })
            },
            size: 50,
            // footer: sum_rejection_rate
        }, {
            header: `In Process`,
            id: "in_process",
            value: inProcess === 0 || processed === 0 ? 0 : ((inProcess / processed) * 100).toFixed(1),
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["34", "96%", "74%"], cell.getValue(), maxInProcess)
                    },
                    align: 'center',
                })
            },
            size: 50,
            // footer: sum_in_process
        }]
    }
};

class RejectBeforeProcessing {
    constructor(data = {
        cabang: 0,
        model: 0,
        rejection: 0,
        totalRejection: 0
    }) {
        const { cabang, rejection, model, totalRejection } = data
        this["cabang"] = cabang;
        this["model"] = model;
        this["rejection"] = rejection;
        this["totalRejection"] = totalRejection
    }
    async getdata(this: any) {
        const { cabang, rejection, model, totalRejection } = this
        return [{
            header: cabang ? "Cabang" : "Model",
            id: cabang ? "cabang" : "model",
            filterVariant: "select",
            value: cabang ? cabang : model,
            size: 100,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: `Reject Before Processing`,
            id: "rejectbeforeprocessing",
            value: rejection,
            size: 160,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            muiTableFooterCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            footer: totalRejection,
        }]
    }
};

function ChartLeadtimeHelper({ dt }) {
    const chartComponent = useRef(null);

    const options = {
        chart: {
            type: 'bar',
            height: 600,
            backgroundColor: "transparent",

        },
        credits: {
            enabled: false,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: dt.allCabang
        },
        yAxis: {
            gridLineWidth: 0,
            min: 0,
            title: {
                text: ''
            }
        },
        legend: {
            align: 'right',
            verticalAlign: 'top',
            layout: 'vertical',
            x: 0,
            y: 100
        },
        tooltip: {
            shared: true,
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                pointWidth: 20,
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            name: 'Leads to SPK',
            data: dt.lead_to_spk
        }, {
            name: 'SPK to Process Leasing',
            data: dt.spk_to_process
        }, {
            name: 'Submit Order to Survey',
            data: dt.process_to_survei
        }, {
            name: 'Survei to CA Process',
            data: dt.sur_to_ca
        }, {
            name: 'CA Process to Decision',
            data: dt.ca_to_decision
        }, {
            name: 'Decision to Appeal',
            data: dt.decision_to_appeal
        }, {
            name: 'Appeal to Final Decision',
            data: dt.appeal_to_final
        }, {
            name: 'Final Decision to PO',
            data: dt.final_to_po
        }, {
            name: 'PO to Lunas DP',
            data: dt.po_to_dp
        }, {
            name: 'Lunas DP to DO',
            data: dt.dp_to_do
        }]
    }
    useEffect(() => {
        // const chart = chartComponent.current.chart;
    }, [dt]);
    return (
        <>
            <div style={{ marginBottom: "-4rem", overflow: "visible" }}>
                <HighchartsReact
                    ref={chartComponent}
                    highcharts={Highcharts}
                    options={options}
                // series={series}
                />
            </div>
        </>
    );
}
function ChartMoMHelper({ dt }) {
    const chartComponent = useRef(null);

    const options = {
        chart: {
            type: 'column',
            height: 600,
            backgroundColor: "transparent",

        },
        credits: {
            enabled: false,
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: dt.fullMonth
        },
        yAxis: {
            gridLineWidth: 0,
            min: 0,
            title: {
                text: ''
            }
        },
        legend: {
            align: 'center',
            verticalAlign: 'bottom',
            layout: 'horizontal',
            x: 0,
            y: 0,
        },
        tooltip: {
            shared: true,
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                pointWidth: 80,
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            name: 'Approve',
            data: dt?.datas?.map((v : MoM) => v.approve_regular),
            color: '#43a047'
        }, {
            name: 'Appr Up Dp',
            data: dt?.datas?.map((v : MoM) => v.approve_up_dp),
            color: '#00acc1'
        }, {
            name: 'Appr Deposit',
            data: dt?.datas?.map((v : MoM) => v.approve_up_deposit),
            color: '#ffc119'
        }, {
            name: 'Appr UP DP & Deposit',
            data: dt?.datas?.map((v : MoM) => v.approve_up_dp_deposit),
            color: '#00897b'
        }, {
            name: 'Reject',
            data: dt?.datas?.map((v : MoM) => v.rejection),
            color: '#bf392b'
        }].reverse()
    }
    useEffect(() => {
        // const chart = chartComponent.current.chart;
    }, [dt]);
    return (
        <>
            <div style={{ marginBottom: "-4rem", overflow: "visible" }}>
                <HighchartsReact
                    ref={chartComponent}
                    highcharts={Highcharts}
                    options={options}
                // series={series}
                />
            </div>
        </>
    );
}

class ApprovalDp {
    constructor(data = {
        dp_percent: 0,
        dp_approved_percent: 0,
        approve: 0,
        approve_up_dp: 0,
        approve_up_deposit: 0,
        rejection: 0,
    }) {
        const { dp_percent, rejection, dp_approved_percent, approve, approve_up_dp, approve_up_deposit } = data
        this["dp_percent"] = dp_percent;
        this["dp_approved_percent"] = dp_approved_percent;
        this["rejection"] = rejection;
        this["approve"] = approve;
        this["approve_up_dp"] = approve_up_dp;
        this["approve_up_deposit"] = approve_up_deposit
    }
    async getdata(this: any) {
        const { dp_percent, rejection, dp_approved_percent, approve, approve_up_dp, approve_up_deposit } = this
        return [{
            header: "DP Application",
            id: "dp_percent",
            filterVariant: "select",
            value: dp_percent,
            size: 100,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: "DP Approved",
            id: "dp_approved_percent",
            filterVariant: "select",
            value: dp_approved_percent,
            size: 100,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }, {
            header: "Approve",
            id: "approve",
            filterVariant: "select",
            value: approve,
            size: 100,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }, {
            header: "Approve(Deposit)",
            id: "approve_up_deposit",
            filterVariant: "select",
            value: approve_up_deposit,
            size: 100,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }, {
            header: "Approve(UP DP)",
            id: "approve_up_dp",
            filterVariant: "select",
            value: approve_up_dp,
            size: 100,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        },
        {
            header: "Reject",
            id: "rejection",
            filterVariant: "select",
            value: rejection,
            size: 100,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }]
    }
};



export { RatebyOutlet, RatebyModel, RejectBeforeProcessing, ChartLeadtimeHelper, ApprovalDp, ChartMoMHelper };
