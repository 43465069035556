import { fetchingGetData, simpleFetching } from "./API";

export class SystemConfigurationAPI {
  //** Begin:: Menu Configuration */
  // Get Data
  async getMenuConfiguration() {
    let res = await fetchingGetData({
      url: `system-configuration/menu-configuration?tipe=getList`,
      method: "get",
    });
    return res;
  }

  // Add (Store Data Insert Query)
  async setDataMenuConfiguration(values: any) {
    let res = await simpleFetching({
      url: `system-configuration/menu-configuration`,
      method: "post",
      data: values,
    });
    return res;
  }

  // Update (Update Data Insert Query)
  async updateDataMenuConfiguration(values: any) {
    let res = await simpleFetching({
      url: `system-configuration/menu-configuration`,
      method: "put",
      data: values,
    });
    return res;
  }

  // Update (Update Data Insert Query)
  async deleteDataMenuConfiguration(values: any) {
    let res = await simpleFetching({
      url: `system-configuration/menu-configuration`,
      method: "delete",
      data: values,
    });
    return res;
  }
  //**End:: Menu Configuration */

  //** Begin:: Role Configuration */
  // Get Data
  async getRoleConfiguration() {
    let res = await fetchingGetData({
      url: `system-configuration/role-configuration?tipe=getList`,
      method: "get",
    });
    return res;
  }

  // Get Role Data Per Menu Inner
  async getMenuRole(val: {}) {
    let res = await fetchingGetData({
      url: `system-configuration/role-configuration?tipe=role_menu_tree&menu_inner=${val['menu_inner']}`,
      method: "get",
    });
    return res;
  }

  // Get Role Data Per Menu Inner
  async getAllSideBarSingleRole(values: {}) {
    let res = await fetchingGetData({
      url: `system-configuration/role-configuration?tipe=get_role_menu_tree&id_roles=${values['id_roles']}&menu_inner=${values['menu_inner']}`,
      method: "get",
    });
    return res;
  }

  // Add (Store Data Insert Query)
  async setDataRoleConfiguration(values: any) {
    let res = await simpleFetching({
      url: `system-configuration/role-configuration`,
      method: "post",
      data: values,
    });
    return res;
  }

  // Update (Update Data Insert Query)
  async updateDataRoleConfiguration(values: any) {
    let res = await simpleFetching({
      url: `system-configuration/role-configuration`,
      method: "put",
      data: values,
    });
    return res;
  }
  // Update (Update Data Insert Query)
  async updateDataRoleConfigurationSetting(values: any) {
    let res = await simpleFetching({
      url: `system-configuration/role-configuration/update-role-setting`,
      method: "put",
      data: values,
    });
    return res;
  }

  // Update (Update Data Insert Query)
  async deleteDataRoleConfiguration(values: any) {
    let res = await simpleFetching({
      url: `system-configuration/role-configuration`,
      method: "delete",
      data: values,
    });
    return res;
  }
  //**End:: Role Configuration */

  //**Begin:: Select Option Get API */
  // Get Menu Parent
  async getMenuParent() {
    let res = await fetchingGetData({
      url: `select/menu-configuration?tipe=menu_parent`,
      method: "get",
    });
    return res;
  }

  async getSelectMasterBrand() {
    let res = await fetchingGetData({
      url: `select/master-brand`,
      method: "get",
    });
    return res;
  }

  async getMenuSubParent(id: any) {
    let res = await fetchingGetData({
      url: `select/menu-configuration?tipe=menu_sub_parent&id_parent=${id}`,
      method: "get",
    });
    return res;
  }
  async getMenuSubChildParent(id: any) {
    let res = await fetchingGetData({
      url: `select/menu-configuration?tipe=menu_sub_child_parent&id_sub_parent=${id}`,
      method: "get",
    });
    return res;
  }
  //**End:: Select Option Get API */
}
