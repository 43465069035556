import React from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { RadialBarChart } from '../../../../_metronic/helpers/components/chart/radialBar'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#384966",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#EFEFEF",
    },
    "& .MuiTableCell-root": {
        borderLeft: "1px solid rgba(224, 224, 224, 1)"
    },
    // hide last border
    // '&:last-child td, &:last-child th': {
    //     border: 1,
    // },
}));

function salesGradeColor(color: string) {
    switch (color) {
        case 'A':
            return '#388e3c';
        case 'B':
            return '#FF8F0D';
        case 'C':
            return '#B71B1C';
        default:
            return 'white'
    }
}

type Props = {
    area: string;
    total?: number
}
function AvatarSalesCompetency({ area, total }: Props) {
    return (
        <>
            <div className='d-flex align-items-center bd-highlight p-5'>
                <div className='d-flex m-0 mx-xl-2 align-items-center bd-highlight' style={{ width: '50rem' }}>
                    <div className='symbol symbol-circle symbol-100px symbol-fixed'>
                        <img alt='Pic' className="img-fluid img-thumbnail" src={toAbsoluteUrl('/media/avatars/blank.png')} />
                    </div>
                    <span className='text-gray-800 fs-3 text-wrap fw-bolder ms-3'>{area}</span>
                </div>
                <div className='d-flex flex-row-fluid w-100 flex-center '>
                    {!total ? <span className='text-gray-800 fs-3 text-wrap fw-bolder ms-3'>No Data</span> :
                        <RadialBarChart
                            chartColor='primary'
                            chartHeight='95px'
                            className=' me-0'
                            value={total ? total : 70}
                        />
                    }
                </div>
            </div>
        </>
    )
}

function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

type tableProps = {
    head?: string;
    title: string;
    dt?: []
}

type SalesmanProps = {
    dt: []
    title: string,
}

type SalesmanTableProps = {
    id: number;
    asm: string;
    sm: string;
    bulan: string;
    outlet: string;
    spv: string;
    sales_name: string;
    photo: string;
    gender: string;
    join_date: string;
    sales_grading: string;
    target_leads: number;
    actual_leads: number;
    target_testdrive: number;
    actual_testdrive: number;
    target_spk: number;
    actual_spk: number;
    target_do: number;
    actual_do: number;
    total_score: number;
    score_sales_process: number;
    contribution: string;
    sales_grade: string;
    prod: number;
    total_product_knowledge: number;
}
function SmallTableCompetency({ head, title, dt }: tableProps) {

    return (
        <>
            <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
                {/* begin::Title */}
                <h3 className="align-items-center flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">{title}</span>
                </h3>
            </div>
            <div className='shadow-sm p-3 mb-5 bg-body rounded'>
                <TableContainer sx={{ borderRadius: 5 }}>
                    <div style={{ position: "sticky", top: 0 }}>
                        <Table stickyHeader aria-label="customized table">
                            <TableHead>
                                <TableRow key={`competency ${title}`}>
                                    <StyledTableCell>{head}</StyledTableCell>
                                    <StyledTableCell sx={{ textAlign: "center" }}>Score</StyledTableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </div>
                    <div style={{ height: "300px", overflow: "auto" }}>
                        <Table style={{ tableLayout: "fixed" }}>
                            <TableBody>
                                {dt?.map((row: { nama: string; total: number; }, i) => (
                                    <StyledTableRow key={i}>
                                        <StyledTableCell align="left">{row.nama}</StyledTableCell>
                                        <StyledTableCell align="center">{Math.round(row.total * 100)} %</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
            </div>
        </>
    )
}

function ImprovementTable({ title }: tableProps) {
    return (
        <>
            <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
                {/* begin::Title */}
                <h3 className="align-items-center flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">{title}</span>
                </h3>
            </div>
            <TableContainer style={{ maxHeight: 440 }} component={Paper}>
                <Table sx={{ minWidth: 100 }} aria-label="customized table">
                    <TableHead>
                        <TableRow key={"improvement"}>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0, zIndex: 1 }}>Training Recomendation Cat</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0, }}>Bobot Target</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0, }}>Bobot Actual</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0, }}>Diff</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <>
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                                    <StyledTableCell align="left">{row.fat}</StyledTableCell>
                                </StyledTableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

function SalesmanTable({ dt, title }: SalesmanProps) {

    return (
        <>
            <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
                {/* begin::Title */}
                <h3 className="align-items-center flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">{title}</span>
                </h3>
            </div>
            <div className='shadow-sm p-3 mb-5 bg-body rounded'>
                <TableContainer style={{ maxHeight: 440, borderRadius: 12 }} component={Paper}>
                    <Table stickyHeader sx={{ minWidth: 700 }} size='small' aria-label="customized table">
                        <TableHead>
                            <TableRow key={"sales"}>
                                <StyledTableCell style={{ zIndex: 1 }}>Sales Name</StyledTableCell>
                                <StyledTableCell style={{}}><i className="fas fa-venus-mars"></i></StyledTableCell>
                                <StyledTableCell style={{}} align="center">Training Level</StyledTableCell>
                                <StyledTableCell style={{}} align="center">Join Date</StyledTableCell>
                                <StyledTableCell style={{}} align="center">Contribution</StyledTableCell>
                                <StyledTableCell style={{}} align="center">Sales Grade</StyledTableCell>
                                <StyledTableCell style={{}} align="center">Prod</StyledTableCell>
                                <StyledTableCell style={{}} align="center">Product Knowledge</StyledTableCell>
                                <StyledTableCell style={{}} align="center">Sales Process</StyledTableCell>
                                <StyledTableCell style={{}} align="center">Leads</StyledTableCell>
                                <StyledTableCell style={{}} align="center">Leads Scoring</StyledTableCell>
                                <StyledTableCell style={{}} align="center">Test Drive</StyledTableCell>
                                <StyledTableCell style={{}} align="center">Test Drive Scoring</StyledTableCell>
                                <StyledTableCell style={{}} align="center">SPK</StyledTableCell>
                                <StyledTableCell style={{}} align="center">SPK Scoring</StyledTableCell>
                                <StyledTableCell style={{}} align="center">DO</StyledTableCell>
                                <StyledTableCell style={{}} align="center">DO Scoring</StyledTableCell>
                                <StyledTableCell style={{}} align="center">Score</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dt.map((row: SalesmanTableProps, i) => (
                                <React.Fragment key={i}>
                                    <StyledTableRow>
                                        <StyledTableCell style={{ position: 'sticky', left: 0, backgroundColor: 'white' }} component="th" scope="row">
                                            {row.sales_name}
                                        </StyledTableCell>
                                        <StyledTableCell style={{}} align="center">
                                            {row.gender === "Male" ? <i className="fas fa-mars"></i> : <i className="fas fa-venus"></i>}
                                        </StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{row.sales_grading}</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{row.join_date}</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{row.contribution}</StyledTableCell>
                                        <StyledTableCell style={{ backgroundColor: salesGradeColor(row.sales_grade), textAlign: 'center' }}>{row.sales_grade}</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{row.prod}</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{row.total_product_knowledge}</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{Math.round(row.score_sales_process * 100)} %</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{row.actual_leads}</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{Math.round((row.actual_leads / row.target_leads) * 100)} %</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{row.actual_testdrive}</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{Math.round((row.actual_testdrive / row.target_testdrive) * 100)} %</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{row.actual_spk}</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{Math.round((row.actual_spk / row.target_spk) * 100)} %</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{row.actual_do}</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{Math.round((row.actual_do / row.target_do) * 100)} %</StyledTableCell>
                                        <StyledTableCell style={{ textAlign: 'center' }}>{Math.round(row.total_score * 100)} %</StyledTableCell>
                                    </StyledTableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}
function TrainingTable({ title }: tableProps) {
    return (
        <>
            <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
                {/* begin::Title */}
                <h3 className="align-items-center flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">{title}</span>
                </h3>
            </div>
            <TableContainer style={{ maxHeight: 440 }} component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow key={"key1"}>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0, zIndex: 1 }}>Outlet</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0, }}>Training Recomendation</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Bobot Target</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Bobot Actual</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Bobot Diff</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Training Plan Date</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Training Actual Date</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Status</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Note</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Pre Test</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Post Test</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">New Bobot Actual</StyledTableCell>
                            <StyledTableCell style={{ position: 'sticky', left: 0, top: 0 }} align="center">Result</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <>
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell style={{ position: 'sticky', left: 0, backgroundColor: 'white' }} component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">{row.calories}</StyledTableCell>
                                    <StyledTableCell align="right">{row.fat}</StyledTableCell>
                                    <StyledTableCell align="right">{row.carbs}</StyledTableCell>
                                    <StyledTableCell align="right">{row.protein}</StyledTableCell>
                                </StyledTableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export { AvatarSalesCompetency, SmallTableCompetency, ImprovementTable, SalesmanTable, TrainingTable }