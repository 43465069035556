import React from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import { HighchartsSample } from '../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample';
import { Form, Formik } from 'formik';
import DataTableMuiModel from '../../../../models/DataTable/dataTableModal';
import { SubCatModel } from '../../../../models/sales/KPIDashboardModel';
import { NationalInformationAPI } from '../../../../api/NationalInformationAPI';
import { MaterialReactTableDetails } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';

type MyState = {
    title: string,
    initialValues: Object,
    data_chart: Object,
    options_asm: Array<[]>,
    options_outlet: Array<[]>,
    options_month: Array<[]>,
    options_range: Array<[]>,
    type_chart: Array<{}>,
}

export default class LeadsQualityIndex extends React.Component {
    constructor(props: any) {
        super(props);
        this["api"] = new NationalInformationAPI();
        this["month"] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }
    state: MyState = {
        title: "Leads Quality",
        initialValues: {
            month: [],
            brand: [],
        }, data_chart: {
            line: {
                series: []
            }, column: {
                series: []
            }
        },
        type_chart: [{
            type: "Formo",
            color: '#d9d9d9',
            data: []
        }, {
            type: "Formo Max",
            color: '#43a047',
            data: []
        }, {
            type: "EV",
            color: '#00897b',
            data: []
        }, {
            type: "Confero",
            color: '#297f9d',
            data: []
        }, {
            type: "Cortez",
            color: '#384967',
            data: []
        }, {
            type: "Alvez",
            color: '#fcc47c',
            data: []
        }, {
            type: "Alvez",
            color: '#bf392b',
            data: []
        }],
        options_asm: [],
        options_outlet: [],
        options_month: [],
        options_range: [],
    }

    //* ----------------------------------------------------------------------------- */
    // BEGIN:: componentDidMount */
    async componentDidMount(): Promise<void> {
        await this['FilterLoadChart'](this['state']['initialValues']);
    }
    // END:: componentDidMount */
    //* ----------------------------------------------------------------------------- */
    async FilterLoadChart(params?: Object) {
        const { data: dataResult } = await this["api"].getDataChartGaikindo({
            ...params, tipe: "ChartLineBar"
        });
        const {
            dataYearOld: dataRSYearOld,
            dataYearNow: dataRSYearNow,
            growth: growthRS,
        } = dataResult["RSChartLine"][0];
        this["setState"]({
            ...this["state"],
            data_chart: {
                line: {
                    series: [{
                        name: "Diff",
                        type: "line",
                        data: growthRS,
                        tooltip: {
                            valueSuffix: " %",
                        },
                    }]
                }, column: {
                    series: [{
                        name: `Select Leasing1`,
                        type: "column",
                        yAxis: 1,
                        data: dataRSYearOld,
                    }, {
                        name: "Growth",
                        type: "spline",
                        data: growthRS,
                        tooltip: {
                            valueSuffix: " %",
                        },
                    },]
                }
            },
        });
    }
    render(this: object) {
        const { title, initialValues, data_chart, type_chart, options_asm, options_outlet, options_month, options_range } = this['state'];
        return (
            <React.Fragment>
                <PageTitle breadcrumbs={[{ title: "Credit Process", path: "/sales/leads-quality", isSeparator: false, isActive: false }]}>
                    {title}
                </PageTitle>
                {/* Filter Input */}
                <div className="row g-5">
                    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={() => { }}>
                        {({ setFieldValue, values }) => (
                            <Form>
                                <div className="row justify-content-center align-items-center mb-5">
                                    <div className="col-xxl-2 mb-5">
                                        <FilterMultiSelect
                                            label={"Cat"}
                                            name="cat"
                                            options={[]}
                                            onChange={(selected: Option[]) => {
                                                // setFieldValue("asm", selected);
                                                // const arrayValueMonth = values["month"].map(
                                                //     (x: Object) => x["value"]
                                                // );
                                                // const arrayValueAsm = selected.map(
                                                //     (x: Object) => x["value"]
                                                // );
                                                // setTimeout(() => {
                                                //     fetchingCreditProcessAPI({
                                                //         asm: arrayValueAsm,
                                                //         month: arrayValueMonth,
                                                //     })
                                                // }, 500);
                                            }}
                                            value={values["cat"]}
                                        />
                                    </div>
                                    <div className="col-xxl-2 mb-5">
                                        <FilterMultiSelect
                                            label={"Leasing"}
                                            name="leasing"
                                            options={[]}
                                            onChange={(selected: Option[]) => {
                                                // setFieldValue("leasing", selected);
                                                // const arrayValueMonth = selected.map(
                                                //     (x: Object) => x["value"]
                                                // );
                                                // const arrayValueAsm = values["asm"].map(
                                                //     (x: Object) => x["value"]
                                                // );
                                                // setTimeout(() => {
                                                //     fetchingCreditProcessAPI({
                                                //         asm: arrayValueAsm,
                                                //         month: arrayValueMonth,
                                                //     })
                                                // }, 500);
                                            }}
                                            value={values["leasing"]}
                                        />
                                    </div>
                                    <div className="col-xxl-2 mb-5">
                                        <FilterMultiSelect
                                            label={"ASM"}
                                            name="asm"
                                            options={[]}
                                            onChange={(selected: Option[]) => {
                                                // setFieldValue("asm", selected);
                                                // const arrayValueMonth = values["month"].map(
                                                //     (x: Object) => x["value"]
                                                // );
                                                // const arrayValueAsm = selected.map(
                                                //     (x: Object) => x["value"]
                                                // );
                                                // setTimeout(() => {
                                                //     fetchingCreditProcessAPI({
                                                //         asm: arrayValueAsm,
                                                //         month: arrayValueMonth,
                                                //     })
                                                // }, 500);
                                            }}
                                            value={values["asm"]}
                                        />
                                    </div>
                                    <div className="col-xxl-2 mb-5">
                                        <FilterMultiSelect
                                            label={"SM"}
                                            name="sm"
                                            options={[]}
                                            onChange={(selected: Option[]) => {
                                                // setFieldValue("sm", selected);
                                                // const arrayValueMonth = values["month"].map(
                                                //     (x: Object) => x["value"]
                                                // );
                                                // const arrayValueAsm = selected.map(
                                                //     (x: Object) => x["value"]
                                                // );
                                                // setTimeout(() => {
                                                //     fetchingCreditProcessAPI({
                                                //         asm: arrayValueAsm,
                                                //         month: arrayValueMonth,
                                                //     })
                                                // }, 500);
                                            }}
                                            value={values["sm"]}
                                        />
                                    </div>
                                    <div className="col-xxl-2 mb-5">
                                        <FilterMultiSelect
                                            label={"Outlet"}
                                            name="outlet"
                                            options={[]}
                                            onChange={(selected: Option[]) => {
                                                // setFieldValue("outlet", selected);
                                                // const arrayValueMonth = values["month"].map(
                                                //     (x: Object) => x["value"]
                                                // );
                                                // const arrayValueAsm = selected.map(
                                                //     (x: Object) => x["value"]
                                                // );
                                                // setTimeout(() => {
                                                //     fetchingCreditProcessAPI({
                                                //         asm: arrayValueAsm,
                                                //         month: arrayValueMonth,
                                                //     })
                                                // }, 500);
                                            }}
                                            value={values["outlet"]}
                                        />
                                    </div>
                                    <div className="col-xxl-2 mb-5">
                                        <FilterMultiSelect
                                            label={"Month"}
                                            name="month"
                                            options={[]}
                                            onChange={(selected: Option[]) => {
                                                // setFieldValue("month", selected);
                                                // const arrayValueMonth = values["month"].map(
                                                //     (x: Object) => x["value"]
                                                // );
                                                // const arrayValueAsm = selected.map(
                                                //     (x: Object) => x["value"]
                                                // );
                                                // setTimeout(() => {
                                                //     fetchingCreditProcessAPI({
                                                //         asm: arrayValueAsm,
                                                //         month: arrayValueMonth,
                                                //     })
                                                // }, 500);
                                            }}
                                            value={values["month"]}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                {/* ----------------------------------------------------------------------------- */}
                <div className="row g-5">
                    {type_chart.map((value: any, i: number) => {
                        return (
                            <React.Fragment key={i}>
                                <div className='col-xxl-3 bd-highlight'>
                                    <KTCard className='mb-5 mt-n5 h-500px'>
                                        <div className="card-header justify-content-center">
                                            <h3 className="card-title align-items-center flex-column">
                                                <span className="card-label fw-bold fs-1">{value['type']}</span>
                                            </h3>
                                        </div>
                                        <KTCardBody className="p-3">
                                            <HighchartsSample
                                                id={`type${i}`}
                                                type="column_range"
                                                series={[]}
                                                height='400px'
                                                categories={[]}
                                                optionsSetting={{
                                                    colors: [value['color']],
                                                    legend: {
                                                        enabled: false
                                                    }, tooltip: {
                                                        enabled: false,
                                                    }
                                                }}
                                            />
                                        </KTCardBody>
                                    </KTCard>
                                </div>
                            </React.Fragment>
                        )
                    })}
                </div>
                {/* ----------------------------------------------------------------------------- */}
                <div className="row g-5">
                    {/* Conversion by Model */}
                    <div className='col-xxl-6 bd-highlight'>
                        <KTCard className='mb-5 h-500px'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1 mb-1">Conversion by Model</span>
                                </h3>
                            </div>
                            <KTCardBody className="p-3">
                                <HighchartsSample
                                    id="chart_line"
                                    type="line"
                                    height='70px'
                                    series={data_chart['line']['series']}
                                    categories={this["month"]}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                    optionsSetting={{
                                        colors: ["#43a047"],
                                        xAxis: {
                                            visible: false
                                        }, yAxis: {
                                            visible: false
                                        }, legend: {
                                            enabled: false
                                        }
                                    }}
                                />
                                <HighchartsSample
                                    id="chart_column"
                                    type="column_line"
                                    series={data_chart['column']['series']}
                                    categories={this["month"]}
                                    height='300px'
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                    optionsSetting={{
                                        legend: {
                                            enabled: true
                                        }, xAxis: {
                                            crosshair: false,
                                        }, tooltip: {
                                            shared: false,
                                        }
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                    {/* Sales Funnel + Credit Process by Model */}
                    <div className='col-xxl-6 bd-highlight'>
                        <KTCard className='mb-5 h-500px'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1 mb-1">Sales Funnel + Credit Process by Model</span>
                                </h3>
                            </div>
                            <KTCardBody className="p-3">
                                <MaterialReactTableDetails
                                    data={[]}
                                    columns={[]}
                                    isLoading={false}
                                    maxHeight={"300px"}
                                    hover={true}
                                    optionsHead={{
                                        backgroundColor: '#0097a7',
                                        fontSize: 10,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* ----------------------------------------------------------------------------- */}
                <div className="row g-5 ">
                    {/* MoM SPK DO */}
                    <div className='bd-highlight'>
                        <KTCard className='mb-5 h-650px'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1 mb-1">MoM SPK DO</span>
                                </h3>
                            </div>
                            <KTCardBody className="p-3 align-items-start flex-column">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={[]}
                                    onSubmit={() => { }}
                                >
                                    {({ setFieldValue, values }) => (
                                        <Form>
                                            <div className="row justify-content-start align-items-center">
                                                <div className="col-md-3 mb-5">
                                                    <FilterMultiSelect
                                                        label={"Model"}
                                                        name="model"
                                                        options={[]}
                                                        onChange={(selected: Option[]) => {
                                                            // setFieldValue("asm", selected);
                                                            // const arrayValueMonth = values["month"].map(
                                                            //     (x: Object) => x["value"]
                                                            // );
                                                            // const arrayValueAsm = selected.map(
                                                            //     (x: Object) => x["value"]
                                                            // );
                                                            // setTimeout(() => {
                                                            //     fetchingCreditProcessAPI({
                                                            //         asm: arrayValueAsm,
                                                            //         month: arrayValueMonth,
                                                            //     })
                                                            // }, 500);
                                                        }}
                                                        value={values["model"]}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                                <HighchartsSample
                                    id="chart_line"
                                    type="line"
                                    height='70px'
                                    series={data_chart['line']['series']}
                                    categories={this["month"]}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                    optionsSetting={{
                                        colors: ["#43a047"],
                                        xAxis: {
                                            visible: false
                                        }, yAxis: {
                                            visible: false
                                        }, legend: {
                                            enabled: false
                                        }
                                    }}
                                />
                                <HighchartsSample
                                    id="chart_column"
                                    type="column_line"
                                    series={data_chart['column']['series']}
                                    categories={this["month"]}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                    optionsSetting={{
                                        legend: {
                                            enabled: false
                                        }, xAxis: {
                                            crosshair: false
                                        }, tooltip: {
                                            shared: false,
                                        }
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
