import React from "react";
import { OverviewAPI } from "../../../../api/MarketShareAPI";
import { TableOverview, TableTen } from "../OverviewHelper";
import { equalsCheck } from "../../../../../_metronic/helpers/helpers-data/AssetHelpers";
import FilterMultiSelect from "../../../../../_metronic/helpers/components/form-actions/FilteringInput";
import GetDataFilter from "../../../../hooks/filter";
import SegmentFilter from "../../../../hooks/segment";

const KumalaRegional = () => {
  const [gTotal, setGtotal] = React.useState([]);
  const [ytdKumala, setytdKumala] = React.useState([]);
  const [ytdAll, setytdAll] = React.useState([]);
  const [ytdVolKumala, setytdVolKumala] = React.useState([]);
  const [ytdVolNational, setytdVolNational] = React.useState([]);
  const [ytdMsKumala, setytdMsKumala] = React.useState([]);
  // Data Table
  const [dtTable, setDtTable] = React.useState([]);
  const [topTable, setTopTable] = React.useState([]);
  const [botTable, setBotTable] = React.useState([]);
  // Filter
  const [asmVal, setasmVal] = React.useState([]);
  const data = GetDataFilter({asmVal:asmVal});
  // const [asmOpt, setasmOpt] = React.useState(data.asmOpt);
  const [arrSelect, setArrSelect] = React.useState([]);
  const [slcSegment, setSlcSegment] = React.useState([]);
  const [segmentSlc, setSegmentSlc] = React.useState([]);
  const segmentOpt = SegmentFilter();
  // console.log("Segment", segmentOpt);
  
  async function getData(cov, segment) {
     new OverviewAPI().topTen(cov, segment).then((res) => {
    setTopTable(res.data);
    });
     new OverviewAPI().bottomTen(cov, segment).then((res) => {
      setBotTable(res.data);
    });
    new OverviewAPI().chartMSAll(cov, segment).then((res) => {
      let wulingN;
      let marketN;
      res.data.map((val) => {
        marketN = val.market.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        wulingN = val.vol.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        return { marketN, wulingN };
      });
      setytdAll(marketN);
      setytdVolNational(wulingN);
    });

    new OverviewAPI().overviewTable(cov, segment).then((res) => {
      let onTable = [];
      for (const [key, value] of Object.entries(res.data.result)) {
        // console.log("Cek Val", Object.values(value));
        onTable.push({
          name: key,
          outlet: Object.keys(value),
          total: Object.values(value).map((item) => {
            return item.arrAchiv;
          }),
        });
      }

      setDtTable(onTable);
      setGtotal(res.data.total);
      setytdKumala(res.data.total[0]);
      setytdVolKumala(res.data.total[1]);
      setytdMsKumala((res.data.total[1] / res.data.total[0]) * 100);
    });
  }

  React.useEffect(() => {
    getData(data.coverage, slcSegment);
    return () => {};
    // getSegment();
  }, [data.coverage, slcSegment]);

  return (
    <>
      <div className="row d-flex justify-content-center mb-15 g-5 absolute">
        <div className="col-xxl-3 mb-5">
          <FilterMultiSelect
            options={data.asmOpt}
            label="ASM"
            isSelectAll={true}
            value={arrSelect}
            onMenuClose={() => {
              let toFilter = arrSelect?.map((val) => val.value);
              return equalsCheck(toFilter, asmVal) ? null : setasmVal(toFilter);
            }}
            onChange={(selected) => {
              setArrSelect(selected);
            }}
          />
        </div>
        <div className="col-xxl-3 mb-5" style={{ zIndex: 4 }}>
          <FilterMultiSelect
            options={segmentOpt}
            label="Segment"
            isSelectAll={true}
            value={segmentSlc}
            onMenuClose={() => {
              let toFilter = segmentSlc?.map((val) => val.value);
              return equalsCheck(toFilter, slcSegment)
                ? null
                : setSlcSegment(toFilter);
            }}
            onChange={(selected) => {
              setSegmentSlc(selected);
            }}
          />
        </div>
      </div>
      <div
        id="container-box1"
        className="row d-flex justify-content-around mt-10 text-center gap-2"
      >
        {/* <div className="row d-flex"> */}
        <div
          className="card col-md4 bg-secondary p-4"
          style={{ height: "10rem", maxWidth: "17rem" }}
        >
          <h5>M/S YTD All Industry National</h5>
          <p style={{ fontWeight: "bold", fontSize: "30px" }}>
            {((ytdVolNational / ytdAll) * 100).toFixed(1)}%
          </p>
        </div>
        <div
          className="card col-md4 bg-secondary p-4"
          style={{ height: "10rem", maxWidth: "17rem" }}
        >
          <h5>M/S YTD All Industry Kumala</h5>
          <p style={{ fontWeight: "bold", fontSize: "30px" }}>
            {ytdMsKumala ? parseFloat(ytdMsKumala).toFixed(1) : ""}%
          </p>
        </div>
        {/* </div> */}
        <div
          className="card col-md4 bg-secondary p-4"
          style={{ height: "10rem", maxWidth: "17rem" }}
        >
          <h5>YTD Market All Industry National</h5>
          <p style={{ fontWeight: "bold", fontSize: "30px" }}>
            {ytdAll.toLocaleString("us-US")}
          </p>
        </div>
        <div
          className="card col-md4 bg-secondary p-4"
          style={{ height: "10rem", maxWidth: "17rem" }}
        >
          <h5>YTD Market All Industry Kumala</h5>
          <p style={{ fontWeight: "bold", fontSize: "30px" }}>
            {ytdKumala.toLocaleString("us-US")}
          </p>
        </div>
        <div
          className="card col-md4 bg-secondary p-4"
          style={{ height: "10rem", maxWidth: "17rem" }}
        >
          <h5 className="mt-5">YTD Vol Wuling National</h5>
          <p style={{ fontWeight: "bold", fontSize: "30px" }}>
            {ytdVolNational.toLocaleString("us-US")}
          </p>
        </div>
        <div
          className="card col-md4 bg-secondary p-4"
          style={{ height: "10rem", maxWidth: "17rem" }}
        >
          <h5 className="mt-5">YTD Vol Wuling Kumala</h5>
          <p style={{ fontWeight: "bold", fontSize: "30px" }}>
            {ytdVolKumala.toLocaleString("us-US")}
          </p>
        </div>
      </div>
      <div className="card mt-10">
        <TableOverview title={""} dt={dtTable} gt={gTotal} />
      </div>
      <div className="row d-flex">
        <div className="col col-lg-6">
          <TableTen
            title={"Top 10 M/S"}
            color={"rgba(0,137,123,0.5)"}
            dt={topTable}
          />
        </div>
        <div className="col col-lg-6">
          <TableTen
            title={"Bottom 10 M/S"}
            color={"rgba(163,26,26,0.5)"}
            dt={botTable}
          />
        </div>
      </div>
    </>
  );
};

export default KumalaRegional;
