import { fetchingGetData } from "./API";

export class MenuInnerAPI {
  //** Begin:: Menu Inner */
  // Update (Update Data Insert Query)
  async update(values: any) {
    let res = await fetchingGetData({
      url: `menu_inner`,
      method: "put",
      data: values,
    });
    return res;
  }
  // Update (Update Data Insert Query)
  async updateBrandID(values: any) {
    let res = await fetchingGetData({
      url: `brand_switcher`,
      method: "put",
      data: values,
    });
    return res;
  }

  //**End:: Menu Inner */
}
