export class ModelUploadCreditProcess {
    constructor(
        data = {
            id: 0,
            status: "",
            year: "",
            bulan: "",
            region: "",
            cabang: "",
            spv: "",
            sales: "",
            sumber_prospek: "",
            reject_1: "",
            reject_2: "",
            reject_3: "",
            no_spk: "",
            nama_customer: "",
            nama_pemohon_kredit: "",
            pekerjaan: "",
            jabatan: "",
            bidang_usaha: "",
            jenis_kelamin: "",
            type: "",
            status_unit: "",
            leasing: "",
            dp_percent: "",
            angsuran: "",
            tenor: "",
            tgl_leads: "",
            tgl_spk: "",
            tgl_collect_document: "",
            cek_slik: "",
            tgl_submit_order: "",
            tgl_survey: "",
            tgl_document_lengkap: "",
            tgl_input_system: "",
            decision: "", //status
            tgl_decision: "",
            ket_decision: "",
            appeal: "",
            tgl_appeal: "",
            ket_appeal: "",
            tgl_final_decision: "",
            dp_approved_percent: "",
            tgl_po: "",
            tgl_dp: "",
            tgl_do: "",
            tgl_deposit_to_leasing: "",
            tgl_prepare_invoice: "",
            tgl_invoice_to_leasing: "",
            tgl_go_live: "",
            reject_leasing: "",
            kendala_customer: "",
            keterangan: "",
        }
    ) {
        const { id, status, year, bulan, region, cabang, spv, sales, sumber_prospek, reject_1, reject_2, reject_3, no_spk, nama_customer, nama_pemohon_kredit,
            pekerjaan, jabatan, bidang_usaha, jenis_kelamin, type, status_unit, leasing, dp_percent, angsuran, tenor, tgl_leads, tgl_spk, tgl_collect_document, cek_slik, tgl_submit_order,
            tgl_survey, tgl_document_lengkap, tgl_input_system, decision, tgl_decision, ket_decision, appeal, tgl_appeal, ket_appeal, tgl_final_decision, dp_approved_percent,
            tgl_po, tgl_dp, tgl_do, tgl_deposit_to_leasing, tgl_prepare_invoice, tgl_invoice_to_leasing, tgl_go_live, reject_leasing, kendala_customer, keterangan } = data;
        // const { asm, sm, month, outlet, type, varian, week, deleted_at, spk_target, spk_total, do_target, actual_do, forecast, model, created_at, updated_at } = data;
        this["id"] = id;
        this["status"] = status;
        this["year"] = year;
        this["bulan"] = bulan;
        this["region"] = region;
        this["cabang"] = cabang;
        this["spv"] = spv;
        this["sales"] = sales;
        this["sumber_prospek"] = sumber_prospek;
        this["reject_1"] = reject_1;
        this["reject_2"] = reject_2;
        this["reject_3"] = reject_3;
        this["no_spk"] = no_spk;
        this["nama_customer"] = nama_customer;
        this["nama_pemohon_kredit"] = nama_pemohon_kredit;
        this["pekerjaan"] = pekerjaan;
        this["jabatan"] = jabatan;
        this["bidang_usaha"] = bidang_usaha;
        this["jenis_kelamin"] = jenis_kelamin;
        this["type"] = type;
        this["status_unit"] = status_unit;
        this["leasing"] = leasing;
        this["dp_percent"] = dp_percent;
        this["angsuran"] = angsuran;
        this["tenor"] = tenor;
        this["tgl_leads"] = tgl_leads;
        this["tgl_spk"] = tgl_spk;
        this["tgl_collect_document"] = tgl_collect_document;
        this["cek_slik"] = cek_slik;
        this["tgl_submit_order"] = tgl_submit_order;
        this["tgl_survey"] = tgl_survey;
        this["tgl_document_lengkap"] = tgl_document_lengkap;
        this["tgl_input_system"] = tgl_input_system;
        this["decision"] = decision;
        this["tgl_decision"] = tgl_decision;
        this["ket_decision"] = ket_decision;
        this["appeal"] = appeal;
        this["tgl_appeal"] = tgl_appeal;
        this["ket_appeal"] = ket_appeal;
        this["tgl_final_decision"] = tgl_final_decision;
        this["dp_approved_percent"] = dp_approved_percent;
        this["tgl_po"] = tgl_po;
        this["tgl_dp"] = tgl_dp;
        this["tgl_do"] = tgl_do;
        this["tgl_deposit_to_leasing"] = tgl_deposit_to_leasing;
        this["tgl_prepare_invoice"] = tgl_prepare_invoice;
        this["tgl_invoice_to_leasing"] = tgl_invoice_to_leasing;
        this["tgl_go_live"] = tgl_go_live;
        this["reject_leasing"] = reject_leasing;
        this["kendala_customer"] = kendala_customer;
        this["keterangan"] = keterangan;

    }

    async getdata(this: any) {
        const { id, status, year, bulan, region, cabang, spv, sales, sumber_prospek, reject_1, reject_2, reject_3, no_spk, nama_customer, nama_pemohon_kredit,
            pekerjaan, jabatan, bidang_usaha, jenis_kelamin, type, status_unit, leasing, dp_percent, angsuran, tenor, tgl_leads, tgl_spk, tgl_collect_document, cek_slik, tgl_submit_order,
            tgl_survey, tgl_document_lengkap, tgl_input_system, decision, tgl_decision, ket_decision, appeal, tgl_appeal, ket_appeal, tgl_final_decision, dp_approved_percent,
            tgl_po, tgl_dp, tgl_do, tgl_deposit_to_leasing, tgl_prepare_invoice, tgl_invoice_to_leasing, tgl_go_live, reject_leasing, kendala_customer, keterangan } = this;

        return [{
            header: "ID",
            id: "id",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: id,
            enableHiding: true,
        },
        {
            header: "Status",
            id: "status",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: status,
            enableHiding: true,
        },
        {
            header: "Year",
            id: "year",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: year,
            enableHiding: true,
        },
        {
            header: "Bulan",
            id: "bulan",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: bulan,
            enableHiding: true,
        },
        {
            header: "Region",
            id: "region",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: region,
            enableHiding: true,
        },
        {
            header: "Cabang",
            id: "cabang",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: cabang,
            enableHiding: true,
        },
        {
            header: "SPV",
            id: "spv",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: spv,
            enableHiding: true,
        },
        {
            header: "Sales",
            id: "sales",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: sales,
            enableHiding: true,
        },
        {
            header: "Sumber Prospek",
            id: "sumber_prospek",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: sumber_prospek,
            enableHiding: true,
        },
        {
            header: "Reject 1",
            id: "reject_1",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: reject_1,
            enableHiding: true,
        },
        {
            header: "Reject 2",
            id: "reject_2",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: reject_2,
            enableHiding: true,
        },
        {
            header: "Reject 3",
            id: "reject_3",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: reject_3,
            enableHiding: true,
        },
        {
            header: "No. SPK",
            id: "no_spk",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: no_spk,
            enableHiding: true,
        },
        {
            header: "Nama Customer",
            id: "nama_customer",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: nama_customer,
            enableHiding: true,
        },
        {
            header: "Nama Pemohon Kredit",
            id: "nama_pemohon_kredit",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: nama_pemohon_kredit,
            enableHiding: true,
        },
        {
            header: "Pekerjaan",
            id: "pekerjaan",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: pekerjaan,
            enableHiding: true,
        },
        {
            header: "Jabatan",
            id: "jabatan",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: jabatan,
            enableHiding: true,
        },
        {
            header: "Bidang Usaha",
            id: "bidang_usaha",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: bidang_usaha,
            enableHiding: true,
        },
        {
            header: "Jenis Kelamin",
            id: "jenis_kelamin",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: jenis_kelamin,
            enableHiding: true,
        },
        {
            header: "Type",
            id: "type",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: type,
            enableHiding: true,
        },
        {
            header: "Status Unit",
            id: "status_unit",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: status_unit,
            enableHiding: true,
        },
        {
            header: "Leasing",
            id: "leasing",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: leasing,
            enableHiding: true,
        },
        {
            header: "Dp Percent",
            id: "dp_percent",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: dp_percent,
            enableHiding: true,
        },
        {
            header: "Angsuran",
            id: "angsuran",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: angsuran,
            enableHiding: true,
        },
        {
            header: "Tenor",
            id: "tenor",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tenor,
            enableHiding: true,
        },
        {
            header: "Tgl. Leads",
            id: "tgl_leads",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_leads,
            enableHiding: true,
        },
        {
            header: "Tgl. SPK",
            id: "tgl_spk",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_spk,
            enableHiding: true,
        },
        {
            header: "Tgl Collect Document",
            id: "tgl_collect_document",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_collect_document,
            enableHiding: true,
        },
        {
            header: "Cek Slik",
            id: "cek_slik",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: cek_slik,
            enableHiding: true,
        },
        {
            header: "Tgl Submit Order",
            id: "tgl_submit_order",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_submit_order,
            enableHiding: true,
        },
        {
            header: "Tgl Survey",
            id: "tgl_survey",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_survey,
            enableHiding: true,
        },
        {
            header: "Tgl Document Lengkap",
            id: "tgl_document_lengkap",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_document_lengkap,
            enableHiding: true,
        },
        {
            header: "Tgl Input System",
            id: "tgl_input_system",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_input_system,
            enableHiding: true,
        },
        {
            header: "Decision",
            id: "decision",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: decision,
            enableHiding: true,
        },
        {
            header: "Tgl Decision",
            id: "tgl_decision",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_decision,
            enableHiding: true,
        },
        {
            header: "Ket Decision",
            id: "ket_decision",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: ket_decision,
            enableHiding: true,
        },
        {
            header: "Appeal",
            id: "appeal",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: appeal,
            enableHiding: true,
        },
        {
            header: "Tgl Appeal",
            id: "tgl_appeal",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_appeal,
            enableHiding: true,
        },
        {
            header: "Ket Appeal",
            id: "ket_appeal",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_appeal,
            enableHiding: true,
        },
        {
            header: "Tgl Final Decision",
            id: "tgl_final_decision",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_final_decision,
            enableHiding: true,
        },
        {
            header: "Dp Approved Percent",
            id: "dp_approved_percent",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: dp_approved_percent,
            enableHiding: true,
        },
        {
            header: "Tgl PO",
            id: "tgl_po",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_po,
            enableHiding: true,
        },
        {
            header: "Tgl DP",
            id: "tgl_dp",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_dp,
            enableHiding: true,
        },
        {
            header: "Tgl DO",
            id: "tgl_do",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_do,
            enableHiding: true,
        },
        {
            header: "Tgl Deposit to Leasing",
            id: "tgl_deposit_to_leasing",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_deposit_to_leasing,
            enableHiding: true,
        },
        {
            header: "Tgl Prepare Invoice",
            id: "tgl_prepare_invoice",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_prepare_invoice,
            enableHiding: true,
        },
        {
            header: "Tgl Invoice to Leasing",
            id: "tgl_invoice_to_leasing",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_invoice_to_leasing,
            enableHiding: true,
        },
        {
            header: "Tgl go Live",
            id: "tgl_go_live",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: tgl_go_live,
            enableHiding: true,
        },
        {
            header: "Reject Leasing",
            id: "reject_leasing",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: reject_leasing,
            enableHiding: true,
        },
        {
            header: "Kendala Customer",
            id: "kendala_customer",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: kendala_customer,
            enableHiding: true,
        },
        {
            header: "Keterangan",
            id: "keterangan",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: keterangan,
            enableHiding: true,
        },

        ];
    }
}