/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import UpDownHelper from "../../performance/UpDownHelper";
import {
  ChartMarketSeg,
  SegmentDonutChart,
  SegmentPieChart,
  TableSegment,
  TableTopBrand,
  TableTopModel,
} from "../SegmentHelper";
import { SegmentAPI } from "../../../../api/MarketShareAPI";
import { LinearProgress } from "@mui/material";
import FilterMultiSelect from "../../../../../_metronic/helpers/components/form-actions/FilteringInput";
import {
  equalsCheck,
  monthOpt,
} from "../../../../../_metronic/helpers/helpers-data/AssetHelpers";
import GetDataFilter from "../../../../hooks/filter";

function MarketSegment() {
  const currDate = new Date();
  const currMonth = currDate.getMonth();
  const [asmVal, setasmVal] = React.useState([]);
  const [smVal, setsmVal] = React.useState([]);
  const filterData = GetDataFilter({ asmVal: asmVal, smVal: smVal });
  const [arrSelect, setArrSelect] = useState([]);
  const [smSelect, setSmSelect] = useState([]);
  // const currMonth = new Date();
  const monthOps = monthOpt;
  const [slcMonth, setSlcMonth] = React.useState([]);
  const [monthSlc, setMonthSlc] = useState([]);
  const [box, setBox] = useState();
  const [dt, setDt] = useState([]);
  const [chartSegment, setChartSegment] = useState([]);
  const [pieMSbrand, setPieMSbrand] = useState([]);
  const [donutK, setDonutK] = useState([]);
  const [donutChart, setDonutChart] = useState();
  const [dtModel, setDtModel] = useState([]);
  const [prevMax, setPrevMax] = useState([]);
  const [max, setMax] = useState([]);
  const [modelMax, setModelMax] = useState([]);
  const [modelPrevMax, setModelPrevMax] = useState([]);

  async function tableData(cov, month) {
    new SegmentAPI().topBrand(cov, month).then((res) => {
      const maxCurr = res.response.reduce(function (prev, current) {
        return prev && prev.marketMaxYear > current.marketMaxYear
          ? prev
          : current;
      });
      setMax(maxCurr.marketMaxYear);
      const maxPrev = res.response.reduce(function (prev, current) {
        return prev && prev.marketMinYear > current.marketMinYear
          ? prev
          : current;
      });
      setPrevMax(maxPrev.marketMinYear);
      setDt(res.response);
    });

    new SegmentAPI().topModel(cov, month).then((res) => {
      const maxCurr = res.response.reduce(function (prev, current) {
        return prev && prev.marketMaxYear > current.marketMaxYear
          ? prev
          : current;
      });
      setModelMax(maxCurr.marketMaxYear);
      const maxPrev = res.response.reduce(function (prev, current) {
        return prev && prev.marketMinYear > current.marketMinYear
          ? prev
          : current;
      });
      setModelPrevMax(maxPrev.marketMinYear);
      setDtModel(res.response);
    });
  }

  async function boxData(cov, month) {
    new SegmentAPI().box(cov, month).then((res) => {
      setBox(res.response);
    });
  }
  async function chartSegmentGrowth(cov, month) {
    new SegmentAPI().chartSegment(cov, month).then((res) => {
      setChartSegment(res.response);
    });
    new SegmentAPI().chartMSbrand(cov, month).then((res) => {
      setPieMSbrand(res.response);
    });
    new SegmentAPI().donutSegment(cov, month).then((res) => {
      setDonutK(Object.keys(res.response));
      setDonutChart(res.response);
    });
  }

  useEffect(() => {
    chartSegmentGrowth(filterData.coverage, slcMonth);
    boxData(filterData.coverage, slcMonth);
    tableData(filterData.coverage, slcMonth);
  }, [filterData.coverage, slcMonth]);

  if (donutChart) {
    return (
      <>
        <PageTitle
          breadcrumbs={[
            {
              title: "Market Segment",
              path: "/sales/market-segment",
              isSeparator: false,
              isActive: false,
            },
          ]}
        >
          {"Market Segment"}
        </PageTitle>
        <div className="row d-flex justify-content-center mb-15 g-5 absolute">
          <div className="col-xxl-3 mb-5">
            <FilterMultiSelect
              options={filterData.asmOpt}
              label="ASM"
              isSelectAll={true}
              value={arrSelect}
              onMenuClose={() => {
                let toFilter = arrSelect?.map((val) => val.value);

                return equalsCheck(toFilter, asmVal)
                  ? null
                  : setasmVal(toFilter);
              }}
              onChange={(selected) => {
                setArrSelect(selected);
              }}
            />
          </div>
          <div className="col-xxl-3 mb-5" style={{ zIndex: 2 }}>
            <FilterMultiSelect
              options={filterData.smOpt}
              label="SM"
              isSelectAll={true}
              value={smSelect}
              onMenuClose={() => {
                let toFilter = smSelect?.map((val) => val.value);
                return equalsCheck(toFilter, smVal) ? null : setsmVal(toFilter);
              }}
              onChange={(selected) => {
                console.log("selected", selected);
                setSmSelect(selected);
              }}
            />
          </div>
          <div className="col-xxl-3 mb-5" style={{ zIndex: 2 }}>
            <FilterMultiSelect
              options={monthOpt}
              label="Type"
              isSelectAll={true}
              value={monthSlc}
              onMenuClose={() => {
                let toFilter = monthSlc?.map((val) => val.value);
                return equalsCheck(toFilter, slcMonth)
                  ? null
                  : setSlcMonth(toFilter);
              }}
              onChange={(selected) => {
                setMonthSlc(selected);
              }}
            />
          </div>
        </div>
        <div
          id="container-box1"
          className="row d-flex justify-content-between mt-10 gap-2"
        >
          {/* <div className="row d-flex"> */}
          <>
            {Object.keys(box).map((key) => {
              return (
                <div
                  key={key}
                  className="card col-sm mx-6 p-4"
                  // style={{ height: "10rem", maxWidth: "17rem" }}
                >
                  <h5>{key}</h5>
                  <h3>{box[key]?.achive?.toLocaleString("us-US")}</h3>
                  <div>
                    <UpDownHelper val={box[key].momGrowth} /> MoM
                  </div>
                  <div>
                    <UpDownHelper val={box[key].yoyGrowth} /> YoY
                  </div>
                </div>
              );
            })}
          </>
        </div>
        <div className="row d-flex justify-content-between mt-10">
          <div className="card col-md-3">
            <ChartMarketSeg title={"Segment Growth"} dt={chartSegment} />
          </div>
          <div className="card col-md-4">
            <TableTopBrand
              title={"Top Brand"}
              dt={dt}
              prevMax={prevMax}
              currMax={max}
            />
          </div>
          <div className="card col-md-4">
            <TableTopModel
              title={"Top Model"}
              dt={dtModel}
              prevMax={modelPrevMax}
              currMax={modelMax}
            />
          </div>
        </div>
        <div className="row d-flex flex-sm-row flex-column mt-10 justify-content-between">
          <div className="card col-md-6">
            <div className="row col-sm-12 align-items-center">
              <div className="col-md-9 my-10">
                <SegmentPieChart dt={pieMSbrand[0].data} />
              </div>
              <div
                className="col-md-3"
                style={{
                  borderLeft: "2px solid #dee2e6",
                }}
              >
                <div
                  style={
                    {
                      // borderLeft: "2px solid #dee2e6",
                    }
                  }
                >
                  <div align="left">
                    MS Wuling Kumala <br />
                    <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                      {pieMSbrand[0]?.local?.toFixed(1)} %
                    </span>
                    <br />
                    Vol Wuling Kumala <br />
                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                      {pieMSbrand[0]?.kmg_local}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    borderTop: "2px solid #dee2e6",
                    paddingTop: "15px",
                  }}
                >
                  <div align="left">
                    MS Wuling non-Kumala <br />
                    <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                      {pieMSbrand[0]?.global?.toFixed(1)} %
                    </span>
                    <br />
                    Vol Wuling non-Kumala <br />
                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                      {pieMSbrand[0]?.kmg_global}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {donutK.map((val, i) => {
            let a = donutChart[val].tableChart.map((val, i) => {
              return val.market;
            });
            return (
              <div
                key={i}
                className="card col-md-6"
                style={{
                  maxWidth: "49%",
                  margin: `${i > 0 ? "15px 0px" : "0px"}`,
                }}
              >
                <div className="row col-sm-12 align-items-center">
                  <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
                    {/* begin::Title */}
                    <h3 className="align-items-center flex-column">
                      <span className="card-label fw-bold fs-1 mb-1">
                        {val}
                      </span>
                    </h3>
                  </div>
                  <div className="col-md-7">
                    <TableSegment dt={donutChart[val].tableChart} max={a} />
                  </div>
                  <div className="col-md-5">
                    <SegmentDonutChart
                      dt={donutChart[val].pieChart.data}
                      kmg={donutChart[val].pieChart.kmg}
                      nonkmg={donutChart[val].pieChart.nonkmg}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  } else {
    return <LinearProgress />;
  }
}

export default MarketSegment;
