/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../../partials'

type Props = {
    chartColor: string
    chartHeight: string
    className?: string
    value?: number
}

const RadialBarChart: React.FC<Props> = ({ chartColor, chartHeight, className, value }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()
    const refreshChart = () => {
        
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, value))
        if (chart) {
            chart.render()
        }

        return chart
    }

    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef, mode, value])

    return (
        <div ref={chartRef} className={className}></div>
    )
}

const chartOptions = (chartColor: string, chartHeight: string, value: number | undefined): ApexOptions => {
    const baseColor = getCSSVariableValue('--bs-' + chartColor)
    const dangerLight = getCSSVariableValue('--bs-danger-light')
    const danger = getCSSVariableValue('--bs-danger')
    const warningLight = getCSSVariableValue('--bs-warning-light')
    const warning = getCSSVariableValue('--bs-warning')
    const successLight = getCSSVariableValue('--bs-success-light')
    const success = getCSSVariableValue('--bs-success')
    const labelColor = getCSSVariableValue('--bs-gray-700')
    
    return {
        series: value ? [value] : [74],
        chart: {
            fontFamily: 'inherit',
            height: chartHeight,
            width: chartHeight,
            type: 'radialBar',
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: '50%',
                    background: dangerLight,
                },
                dataLabels: {
                    name: {
                        show: false,
                        fontWeight: '700',
                    },
                    value: {
                        color: labelColor,
                        fontSize: '15px',
                        fontWeight: '700',
                        offsetY: 5,
                        show: true,
                        formatter: function (val) {
                            return val + '%'
                        },
                    },
                },
                track: {
                    background: dangerLight,
                    strokeWidth: '97%',
                },
            },
        },
        colors: [danger],
        stroke: {
            lineCap: 'round',
        },
        labels: ['Progress'],
    }
}

export { RadialBarChart }
