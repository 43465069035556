/* eslint-disable no-loop-func */
import React, { Component } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import { HighchartsSample } from '../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample';
import { MaterialReactTableDetails } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';
import { Form, Formik } from 'formik';
import { MscpAPI } from '../../../../api/MscpAPI';
import { InputSelect2 } from '../../../../../_metronic/helpers/components/form-actions/FormInput';
import { progressBarLine, spanNumberWithPointTotal, spanString } from '../../../../../_metronic/helpers/components/MaterialReactTable/ComponentStyleRowsTable';
import moment from 'moment';

export default class MscpIndex extends Component {
  constructor(props: any) {
    super(props);
    this["api"] = new MscpAPI();
    this["month"] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    this['state'] = {
      title: "Market Share Credit Portion",
      loadingState: {
        loading_sm: false,
        loading_outlet: false,
        loading_month: false,
        loading_table: true,
      }, filtering_values: {
        coverage: [],
        year: '',
        month: [],
        leasing: [],
      }, initial_values_pie: {
        status: 'leasing',
      },




      isLoading: true,
      loading: true,
      initial_values: {
        asm: [],
        sm: [],
        outlet: [],
        month: [],
        year: '',
        leasing: [],
      },

      initial_values_stacked_leasing: {
        leasing: [],
      },
      initial_values_chart_column_line: {
        chart_col_approve_v_do: {
          series: [],
          categories: this.props["month"],
        }
      },
      chart_pie_leasing_m_s: {
        series: []
      },
      chart_pie_contribution: {
        series: []
      },
      mom_model_absorption: {
        series: [],
        categories: this["month"],
      },
      mom_approval_rate_v_rejection_rate: {
        series: [],
        categories: this["month"],
      },
      options_asm: [],
      options_outlet: [],
      options_month: [],
      options_range: [],
    }
    this['column_model_absorption'] = [];
    this['data_model_absorption'] = [];
    this['option_asm'] = [];
    this['option_sm'] = [];
    this['option_outlet'] = [];
    this['option_year'] = [];
    this['option_month'] = [];
    this['option_leasing'] = [];
  }

  // state: MyState = {
  //   title: "Market Share Credit Portion",
  //   loading: true,
  //   initial_values: {
  //     month: [],
  //     brand: [],
  //   },
  //   initial_values_pie: {
  //     status: 'leasing',
  //   },
  //   initial_values_stacked_leasing: {
  //     leasing: [],
  //   },
  //   initial_values_chart_column_line: {
  //     chart_col_approve_v_do: {
  //       series: [],
  //       categories: this.props["month"],
  //     }
  //   },
  //   chart_pie_leasing_m_s: {
  //     series: []
  //   },
  //   chart_pie_contribution: {
  //     series: []
  //   },
  //   mom_model_absorption: {
  //     series: [],
  //     categories: this["month"],
  //   },
  //   mom_approval_rate_v_rejection_rate: {
  //     series: [],
  //     categories: this["month"],
  //   },
  //   options_asm: [],
  //   options_outlet: [],
  //   options_month: [],
  //   options_range: [],
  // }
  //* ----------------------------------------------------------------------------- */
  // BEGIN:: componentDidMount */
  async componentDidMount() {
    // //? Load Filter Options ASM, SM, OUTLET
    await this['api'].getFilter(this['state']['initial_values']).then(async (res: any) => {
      this['option_asm'] = res['data']['dataAsm'].map((val: any) => {
        return {
          value: val['id_team_asm'],
          label: val['area'],
        };
      });
      this['option_sm'] = res['data']['dataSm'].map((val: any) => {
        return {
          value: val['id_team_sm'],
          label: val['region'],
        };
      });
      this['option_outlet'] = res['data']['dataOutlet'].map((val: any) => {
        return {
          value: val['id_outlet'],
          label: val['text'],
        };
      });
      this.setState({
        ...this["state"],
        isLoading: false,
        filtering_values: {
          coverage: res['data']['coverage'].split(","),
          year: '',
          month: [],
          leasing: [],
        },
      });
    });
    // LEASING
    await this['api'].getLeasing().then(async (res: any) => {
      this['option_leasing'] = res['data'].map((val: any) => {
        return {
          value: val['nama_singkat_leasing'],
          label: val['nama_singkat_leasing'],
        };
      });
    });
    // YEARS
    const year = (new Date()).getFullYear();
    this['years'] = Array.from(new Array(4), (val, index) => year - index);
    this['option_year'] = this['years'].map((val: any) => {
      return {
        value: val,
        label: val,
      };
    });
    // MONTH
    const months = Array.from(`${12}`, (item, i) => {
      return new Date(1, i).toLocaleString('en-US', { month: 'long' })
    });
    this['option_month'] = months.map((val: any) => {
      return {
        value: moment().month(val).format("MM"),
        label: val,
      };
    });
    await this.FilterLoadChartColumn(this['state']['filtering_values']);
    await this.FilterLoadChartPie(this['state']['filtering_values']);
    await this.FilterLoadChartPieContribution(this['state']['filtering_values'], this['state']['initial_values_pie']['status']);
    await this.FilterLoadChartColumnStacked(this['state']['filtering_values']);
    await this.FilterLoadChartColumnStackedLeasing(this['state']['filtering_values'], this['state']['initial_values_stacked_leasing']['leasing']);
    await this.FilterLoadTable(this['state']['filtering_values']);
  }

  // END:: componentDidMount */
  //* ----------------------------------------------------------------------------- */
  async loadOptionsSm(array: object) {
    this.setState({ loadingState: { loading_sm: true } });
    await this['api'].getFilter({ asm: array['asm'], sm: array['sm'], outlet: array['outlet'], }).then(async (res: any) => {
      this['option_sm'] = res['data']['dataSm'].map((val: any) => {
        return {
          value: val['id_team_sm'],
          label: val['region'],
        };
      });
      this['option_outlet'] = res['data']['dataOutlet'].map((val: any) => {
        return {
          value: val['id_outlet'],
          label: val['text'],
        };
      });
      this.setState({
        filtering_values: {
          coverage: res['data']['coverage'].split(","),
          year: array['year'],
          month: array['month'],
          leasing: array['leasing'],
        }, loadingState: {
          loading_sm: false
        }
      });
    });
  }
  async loadOptionsOutlet(array: object) {
    this.setState({ loadingState: { loading_outlet: true } });
    await this['api'].getFilter(array).then(async (res: any) => {
      this['option_outlet'] = res['data']['dataOutlet'].map((val: any) => {
        return {
          value: val['id_outlet'],
          label: val['text'],
        };
      });
      this.setState({
        loadingState: {
          loading_outlet: false
        }, filtering_values: {
          coverage: res['data']['coverage'].split(","),
          year: array['year'],
          month: array['month'],
          leasing: array['leasing'],
        }
      });
      // this.setState({
      //   isLoading: false,
      //   coverage: res['data']['coverage'],
      // });
    });
  }

  async loadOptionsMonth(years: number) {
    this.setState({ loadingState: { loading_month: true } });
    // MONTH
    const year = (new Date()).getFullYear();
    let months: any;
    if (years === year) {
      months = Array.from(`12`, (item, i) => {
        return new Date(1, i).toLocaleString('en-US', { month: 'long' })
      });
    } else {
      months = Array.from({ length: 12 }, (item, i) => {
        return new Date(years, i).toLocaleString('en-US', { month: 'long' })
      });
    }
    this['option_month'] = months.map((val: any) => {
      return {
        value: moment().month(val).format("MM"),
        label: val,
      };
    });
    this.setState({
      loadingState: {
        loading_month: false
      }
    });
  }
  //* ----------------------------------------------------------------------------- */

  async FilterLoadChartColumn(params?: Object) {
    const { data: MomApproveVdo } = await this["api"].getMarketShareCreditPortion({ ...params, tipe: "MoMApproveVDo" });
    const { approve_chart, do_chart } = MomApproveVdo['MoM_Approve_V_Do'];
    this["setState"]({
      ...this["state"],
      initial_values_chart_column_line: {
        chart_col_approve_v_do: {
          series: [{
            name: `Approve`,
            type: "column",
            yAxis: 1,
            data: approve_chart,
          }, {
            name: `DO`,
            type: "column",
            yAxis: 1,
            data: do_chart,
          }],
          categories: this["month"],
        }
      }
    });
  }
  //* ----------------------------------------------------------------------------- */
  async FilterLoadChartPie(params?: Object) {
    console.warn("params", params);
    const { data: LeasingMS } = await this["api"].getMarketShareCreditPortion({ ...params, tipe: "LeasingMarketShare" });
    this["setState"]({
      ...this["state"],
      chart_pie_leasing_m_s: {
        series: LeasingMS['Leasing_Market_Share']
      },
    });
  }
  async FilterLoadChartPieContribution(params?: Object, status?: string) {
    const { data: LeasingMS } = await this["api"].getMarketShareCreditPortion({ ...params, tipe: "ModelAbsorptionContribution", status: status });
    this["setState"]({
      chart_pie_contribution: {
        series: LeasingMS['model_absorption_contribution_pie']
      },
    });
  }

  async FilterLoadChartColumnStacked(params?: Object) {
    const { data: datas } = await this["api"].getMarketShareCreditPortion({ ...params, tipe: "MoMModelAbsorption" });
    this["setState"]({
      ...this["state"],
      mom_model_absorption: {
        series: datas['mom_model_absorption']['data'],
        categories: datas['mom_model_absorption']['category'],
      }
    });
  }

  async FilterLoadChartColumnStackedLeasing(params?: Object, leasing?: string[]) {
    const { data: datas } = await this["api"].getMarketShareCreditPortion({ ...params, tipe: "MoMApprovalRateVRejectionRate", leasing: leasing });
    this["setState"]({
      ...this["state"],
      mom_approval_rate_v_rejection_rate: {
        series: datas['mom_approval_rate_v_rejection_rate']['data'],
        categories: datas['mom_approval_rate_v_rejection_rate']['category'],
      }
    });
  }
  //* ----------------------------------------------------------------------------- */
  async FilterLoadTable(params?: Object) {
    this.setState({ loading: true });
    const { data: modelAbsorption } = await this["api"].getMarketShareCreditPortion({ ...params, tipe: "ModelAbsorption" });
    const { type_field, data_table } = modelAbsorption['table_model_absorption'];
    let nama_leasing: any = [];
    let max_data_array = 0;
    data_table.map((item: any) => {
      let objData: any = {};
      let average = 0;
      for (let i = 0; i < type_field.length; i++) {
        max_data_array = Math.max(max_data_array, item['type'][i]);
        objData[`${type_field[i]}`] = item['type'][i];
        average += item['type'][i];
      }
      objData[`total`] = average;
      nama_leasing.push({
        leasing: item['name'],
        ...objData
      });
      return item;
    });
    let columns: any = [{
      header: 'Leasing',
      accessorKey: 'leasing',
      size: 50,
      muiTableHeadCellProps: {
        align: "center"
      },
      Footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
    }];
    for (let i = 0; i < type_field.length; i++) {
      let objColumn: any = {};
      objColumn['header'] = `${type_field[i]}`;
      objColumn['accessorKey'] = `${type_field[i]}`;
      objColumn['size'] = 10;
      objColumn['Cell'] = ({ cell }) => {
        return progressBarLine(cell.getValue(), max_data_array, '#388e3c');
      };
      objColumn['muiTableHeadCellProps'] = {
        align: "center",
      };
      objColumn['Footer'] = ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue(`${type_field[i]}`), 0);
      columns.push(objColumn);
    }
    columns.push({
      header: 'Total',
      accessorKey: 'total',
      size: 20,
      muiTableHeadCellProps: {
        align: "center"
      },
      Cell: ({ cell }) => {
        return spanNumberWithPointTotal(cell.getValue());
      },
      Footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('total'), 0)
    });
    this['column_model_absorption'] = columns;
    this['data_model_absorption'] = nama_leasing;
    this.setState({ loading: false });
  }
  render(this: any) {
    const { loading, loadingState, initial_values, initial_values_pie, initial_values_stacked_leasing, initial_values_chart_column_line, chart_pie_leasing_m_s, chart_pie_contribution, mom_model_absorption, mom_approval_rate_v_rejection_rate } = this['state'];
    const { chart_col_approve_v_do } = initial_values_chart_column_line;
    const { loading_sm, loading_outlet, loading_month, loading_table } = loadingState;
    return (
      <React.Fragment>
        <PageTitle breadcrumbs={[{ title: "MSCP", path: "/sales/mscp", isSeparator: false, isActive: false }]}>
          {this["title"]}
        </PageTitle>
        {/* Filter Input */}
        <div className="row g-6 g-xl-9">
          <Formik
            enableReinitialize={true}
            initialValues={initial_values}
            onSubmit={() => { }}
          >
            {(formik) => (
              <Form>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-3 mb-5">
                    <FilterMultiSelect
                      label={"ASM"}
                      name="asm"
                      options={this['option_asm']}
                      onChange={async (selected: Option[]) => {
                        formik.setFieldValue("sm", []);
                        formik.setFieldValue("outlet", []);
                        formik.setFieldValue("asm", selected);
                        const asm = selected.map((x: object) => `${x["value"]}`);
                        if (selected.length === 0) {
                          formik.setFieldValue("sm", []);
                          formik.setFieldValue("outlet", []);
                          await this['loadOptionsOutlet']({
                            asm: asm,
                            sm: [],
                            outlet: [],
                            year: formik.values['year'],
                            month: formik.values['month'].map((x: Object) => `${x["value"]}`),
                            leasing: formik.values['leasing'].map((x: Object) => `${x["value"]}`),
                          });
                          setTimeout(() => {
                            this.FilterLoadChartColumn(this['state']['filtering_values']);
                            this.FilterLoadChartPie(this['state']['filtering_values']);
                            this.FilterLoadTable(this['state']['filtering_values']);
                          }, 1000);
                          return;
                        }
                        await this['loadOptionsSm']({
                          asm: asm,
                          sm: [],
                          outlet: [],
                          year: formik.values['year'],
                          month: formik.values['month'].map((x: Object) => `${x["value"]}`),
                          leasing: formik.values['leasing'].map((x: Object) => `${x["value"]}`),
                        });
                        setTimeout(() => {
                          this.FilterLoadChartColumn(this['state']['filtering_values']);
                          this.FilterLoadChartPie(this['state']['filtering_values']);
                          this.FilterLoadTable(this['state']['filtering_values']);
                          this.FilterLoadChartPieContribution(this['state']['filtering_values'], this['state']['initial_values_pie']['status']);
                          this.FilterLoadChartColumnStacked(this['state']['filtering_values']);
                          this.FilterLoadChartColumnStackedLeasing(this['state']['filtering_values'], this['state']['initial_values_stacked_leasing']['leasing']);
                        }, 1000);
                      }}
                      value={formik.values["asm"]}
                    />
                  </div>
                  <div className="col-md-3 mb-5">
                    <FilterMultiSelect
                      label={"SM"}
                      name="sm"
                      options={this['option_sm']}
                      isLoading={loading_sm}
                      onChange={async (selected: Option[]) => {
                        formik.setFieldValue("outlet", []);
                        formik.setFieldValue("sm", selected);
                        const asm = formik.values["asm"].map((x: object) => `${x["value"]}`);
                        const sm = selected.map((x: object) => `${x["value"]}`);
                        if (selected.length === 0) {
                          formik.setFieldValue("outlet", []);
                          await this['loadOptionsOutlet']({
                            asm: asm,
                            sm: sm,
                            outlet: [],
                            year: formik.values['year'],
                            month: formik.values['month'].map((x: Object) => `${x["value"]}`),
                            leasing: formik.values['leasing'].map((x: Object) => `${x["value"]}`),
                          });
                          setTimeout(() => {
                            this.FilterLoadChartColumn(this['state']['filtering_values']);
                            this.FilterLoadChartPie(this['state']['filtering_values']);
                            this.FilterLoadTable(this['state']['filtering_values']);
                          }, 1000);
                          return;
                        }
                        await this['loadOptionsOutlet']({
                          asm: asm,
                          sm: sm,
                          outlet: [],
                          year: formik.values['year'],
                          month: formik.values['month'].map((x: Object) => `${x["value"]}`),
                          leasing: formik.values['leasing'].map((x: Object) => `${x["value"]}`),
                        });
                        setTimeout(() => {
                          this.FilterLoadChartColumn(this['state']['filtering_values']);
                          this.FilterLoadChartPie(this['state']['filtering_values']);
                          this.FilterLoadTable(this['state']['filtering_values']);
                          this.FilterLoadChartPieContribution(this['state']['filtering_values'], this['state']['initial_values_pie']['status']);
                          this.FilterLoadChartColumnStacked(this['state']['filtering_values']);
                          this.FilterLoadChartColumnStackedLeasing(this['state']['filtering_values'], this['state']['initial_values_stacked_leasing']['leasing']);
                        }, 1000);
                      }}
                      value={formik.values["sm"]}
                    />
                  </div>
                  <div className="col-md-3 mb-5">
                    <FilterMultiSelect
                      label={"Outlet"}
                      name="outlet"
                      options={this['option_outlet']}
                      isLoading={loading_outlet}
                      onChange={async (selected: Option[]) => {
                        formik.setFieldValue("outlet", selected);
                        const coverage = selected.map((x: object) => `${x["value"]}`);
                        if (selected.length === 0) {
                          formik.setFieldValue("outlet", []);
                          const asm = formik.values["asm"].map((x: object) => `${x["value"]}`);
                          const sm = formik.values["sm"].map((x: object) => `${x["value"]}`);
                          await this['loadOptionsOutlet']({
                            asm: asm,
                            sm: sm,
                            outlet: [],
                            year: formik.values['year'],
                            month: formik.values['month'].map((x: Object) => `${x["value"]}`),
                            leasing: formik.values['leasing'].map((x: Object) => `${x["value"]}`),
                          });
                          setTimeout(() => {
                            this.FilterLoadChartColumn(this['state']['filtering_values']);
                            this.FilterLoadChartPie(this['state']['filtering_values']);
                            this.FilterLoadTable(this['state']['filtering_values']);
                            this.FilterLoadChartPieContribution(this['state']['filtering_values'], this['state']['initial_values_pie']['status']);
                            this.FilterLoadChartColumnStacked(this['state']['filtering_values']);
                            this.FilterLoadChartColumnStackedLeasing(this['state']['filtering_values'], this['state']['initial_values_stacked_leasing']['leasing']);
                          }, 1000);
                          return;
                        }
                        this.setState({
                          filtering_values: {
                            coverage: coverage,
                            year: formik.values['year'],
                            month: formik.values['month'].map((x: Object) => `${x["value"]}`),
                            leasing: formik.values['leasing'].map((x: Object) => `${x["value"]}`),
                          }
                        });
                        setTimeout(() => {
                          this.FilterLoadChartColumn(this['state']['filtering_values']);
                          this.FilterLoadChartPie(this['state']['filtering_values']);
                          this.FilterLoadTable(this['state']['filtering_values']);
                          this.FilterLoadChartPieContribution(this['state']['filtering_values'], this['state']['initial_values_pie']['status']);
                          this.FilterLoadChartColumnStacked(this['state']['filtering_values']);
                          this.FilterLoadChartColumnStackedLeasing(this['state']['filtering_values'], this['state']['initial_values_stacked_leasing']['leasing']);
                        }, 1000);
                      }}
                      value={formik.values["outlet"]}
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <InputSelect2
                      label={"Year"}
                      name="year"
                      options={this['option_year']}
                      onChange={async (e: number) => {
                        let leasing = (formik.values['leasing'].length > 0) ? formik.values['leasing'] : this['option_leasing'];
                        const values = e?.["value"];
                        formik.setFieldValue("year", values);
                        await this['loadOptionsMonth'](parseInt(values));
                        const asm = formik.values["asm"].map((x: object) => `${x["value"]}`);
                        const sm = formik.values["sm"].map((x: object) => `${x["value"]}`);
                        await this['loadOptionsOutlet']({
                          asm: asm,
                          sm: sm,
                          outlet: [],
                          year: values,
                          month: formik.values['month'].map((x: Object) => `${x["value"]}`),
                          leasing: leasing.map((x: Object) => `${x["value"]}`),
                        });
                        setTimeout(() => {
                          this.FilterLoadChartColumn(this['state']['filtering_values']);
                          this.FilterLoadChartPie(this['state']['filtering_values']);
                          this.FilterLoadTable(this['state']['filtering_values']);
                          this.FilterLoadChartPieContribution(this['state']['filtering_values'], this['state']['initial_values_pie']['status']);
                          this.FilterLoadChartColumnStacked(this['state']['filtering_values']);
                          this.FilterLoadChartColumnStackedLeasing(this['state']['filtering_values'], this['state']['initial_values_stacked_leasing']['leasing']);
                        }, 1000);
                      }}
                      formik={formik}
                    />
                  </div>
                  <div className="col-md-3 mb-5">
                    <FilterMultiSelect
                      label={"Month"}
                      name="month"
                      options={this['option_month']}
                      isLoading={loading_month}
                      onChange={async (selected: Option[]) => {
                        formik.setFieldValue("month", selected);
                        const asm = formik.values["asm"].map((x: object) => `${x["value"]}`);
                        const sm = formik.values["sm"].map((x: object) => `${x["value"]}`);
                        await this['loadOptionsOutlet']({
                          asm: asm,
                          sm: sm,
                          outlet: [],
                          year: formik.values['year'],
                          month: selected.map((x: Object) => `${x["value"]}`),
                          leasing: formik.values['leasing'].map((x: Object) => `${x["value"]}`),
                        });
                        setTimeout(() => {
                          this.FilterLoadChartPie(this['state']['filtering_values']);
                          this.FilterLoadTable(this['state']['filtering_values']);
                          this.FilterLoadChartPieContribution(this['state']['filtering_values'], this['state']['initial_values_pie']['status']);
                        }, 1000);
                      }}
                      value={formik.values["month"]}
                    />
                  </div>
                  <div className="col-md-3 mb-5">
                    <FilterMultiSelect
                      label={"Leasing"}
                      name="leasing"
                      options={this['option_leasing']}
                      onChange={async (selected: Option[]) => {
                        formik.setFieldValue("leasing", selected);
                        const asm = formik.values["asm"].map((x: object) => `${x["value"]}`);
                        const sm = formik.values["sm"].map((x: object) => `${x["value"]}`);
                        await this['loadOptionsOutlet']({
                          asm: asm,
                          sm: sm,
                          outlet: [],
                          year: formik.values['year'],
                          month: formik.values['month'].map((x: Object) => `${x["value"]}`),
                          leasing: selected.map((x: Object) => `${x["value"]}`),
                        });
                        setTimeout(() => {
                          this.FilterLoadChartColumn(this['state']['filtering_values']);
                          this.FilterLoadChartColumnStacked(this['state']['filtering_values']);
                        }, 1000);
                      }}
                      value={formik.values["leasing"]}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* ----------------------------------------------------------------------------- */}
        <div className="row flex-xxl-row">
          {/* Card MoM Approve ⓥ DO */}
          <div className='col-xxl-7 h-md-100 bd-highlight'>
            <KTCard className='card-xl-stretch mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1 mb-1">MoM Approve ⓥ DO</span>
                </h3>
              </div>
              <KTCardBody className="p-3">
                <HighchartsSample
                  id="mom_approve_do"
                  type="column_line"
                  series={chart_col_approve_v_do["series"]}
                  categories={chart_col_approve_v_do["categories"]}
                  optionsSetting={{
                    yAxis: {
                      tickAmount: 8,
                      min: 0,
                      gridLineWidth: 0,
                      gridZIndex: 0,
                      title: {
                        text: ''
                      },
                      visible: true,
                    },
                    xAxis: {
                      crosshair: false
                    }, tooltip: {
                      shared: false,
                    }
                  }}
                />
              </KTCardBody>
            </KTCard>
          </div>
          {/* Leasing M/S */}
          <div className='col-xxl-5 h-md-100 bd-highlight'>
            <KTCard className='card-xl-stretch mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title">
                  <span className="card-label fw-bold fs-1 mb-1">Leasing M/S</span>
                </h3>
              </div>
              <KTCardBody className="p-3">
                <HighchartsSample
                  id="leasing_m_s"
                  type="pie"
                  series={chart_pie_leasing_m_s['series']}
                  positionLegend='TopRight'
                  optionsSetting={{
                    colors: ['#c43740', "#ffde00", "#039cb5", "#43a047", "#e4e4e4", "#ffc600", "#0072bc", "#194077", "#bf392b", "#00897b"]
                  }}
                  chartStyle={{
                    spacingLeft: 10,
                    spacingRight: 10,

                  }}
                />
              </KTCardBody>
            </KTCard>
          </div>
        </div>
        {/* ----------------------------------------------------------------------------- */}
        <div className="row flex-xxl-row">
          {/* Model Absorption */}
          <div className='h-md-100 bd-highlight'>
            <KTCard className='card-xl-stretch mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1 mb-1">Model Absorption</span>
                </h3>
              </div>
              <KTCardBody className="p-3">
                <div className='row g-sm-5 flex-fill'>
                  <div className="col col-lg-7">
                    <MaterialReactTableDetails
                      columns={this['column_model_absorption']}
                      data={this['data_model_absorption']}
                      isLoading={loading}
                      enableTopToolbar={false}
                      hover={false}
                      optionsHead={{
                        fontSize: 9,
                      }}
                    />
                  </div>
                  <div className="col-md-5">
                    <Formik
                      enableReinitialize={true}
                      initialValues={[]}
                      onSubmit={() => { }}
                    >
                      {(formik) => (
                        <Form className='row justify-content-center align-items-center'>
                          <div className="col-md-6  mb-5 ">
                            <InputSelect2
                              label={"Contribution of"}
                              name="contribution"
                              hideLabel
                              options={[{
                                value: "leasing",
                                label: "Leasing",
                              }, {
                                value: "model",
                                label: "Model",
                              }]}
                              isClearable
                              onChange={(e: string) => {
                                this["setState"]({
                                  initial_values_pie: {
                                    status: e['value']
                                  }
                                });
                                setTimeout(async () => {
                                  this.FilterLoadChartPieContribution(this['state']['filtering_values'], e?.['value']);
                                }, 1000);
                              }}
                              formik={formik}
                            />
                          </div>
                        </Form>
                      )}
                    </Formik>
                    <HighchartsSample
                      id="contribution"
                      type="pie"
                      // positionLegend={'TopRight'}
                      series={chart_pie_contribution['series']}
                      positionLegend='TopRight'
                      optionsSetting={{
                        colors: initial_values_pie['status'] === 'leasing' ?
                          ['#c43740', "#ffde00", "#039cb5", "#43a047", "#e4e4e4", "#ffc600", "#0072bc", "#194077", "#bf392b", "#00897b"]
                          : ['#00897b', "#5e35b1", "#039cb5", "#404040", "#43a047", "#bf392b", "#fcc47c", "#297f9d"]
                      }}
                      chartStyle={{
                        spacingLeft: 10,
                        spacingRight: 10,

                      }}
                    />
                  </div>
                </div>
              </KTCardBody>
            </KTCard>
          </div>
        </div>
        {/* ----------------------------------------------------------------------------- */}
        <div className="row g-5">
          {/* Card MoM Model Absorption */}
          <div className='col-xxl-6'>
            <KTCard className='card-xl-stretch mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1 mb-1">MoM Model Absorption</span>
                </h3>
              </div>
              <KTCardBody className="p-3">
                <HighchartsSample
                  id="model_absorption"
                  type="column_stacked"
                  positionLegend={"TopCenter"}
                  series={mom_model_absorption['series']}
                  categories={mom_model_absorption['categories']}
                  optionsSetting={{
                    yAxis: {
                      tickAmount: 8,
                      min: 0,
                      gridLineWidth: 0,
                      gridZIndex: 0,
                      title: {
                        text: ''
                      },
                      visible: true,
                    }
                  }}
                />
              </KTCardBody>
            </KTCard>
          </div>
          {/* MoM Approval Rate ⓥ Rejection Rate */}
          <div className='col-xxl-6'>
            <KTCard className='card-xl-stretch mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title">
                  <span className="card-label fw-bold fs-1 mb-1">MoM Approval Rate ⓥ Rejection Rate</span>
                </h3>
              </div>
              <KTCardBody className="p-3">
                <Formik
                  enableReinitialize={true}
                  initialValues={initial_values_stacked_leasing}
                  onSubmit={() => { }}
                >
                  {({ setFieldValue, values }) => (
                    <Form className='row justify-content-center align-items-center'>
                      <div className="col-md-6  mb-5 ">
                        <FilterMultiSelect
                          label={"Leasing"}
                          name="leasing_col"
                          options={[
                            ...this['option_leasing'],
                            {
                              value: null,
                              label: 'Null',
                            }]}
                          onChange={(selected: Option[]) => {
                            setFieldValue("leasing", selected);
                            const arrayLeasing = values["leasing"].map(
                              (x: Object) => x["value"]
                            );
                            setTimeout(async () => {
                              await this.FilterLoadChartColumnStackedLeasing(this['state']['filtering_values'], this['state']['initial_values_stacked_leasing']['leasing']);
                              await this['FilterLoadChartColumnStackedLeasing'](initial_values, arrayLeasing);
                            }, 1000);
                          }}
                          value={values["leasing_col"]}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
                <HighchartsSample
                  id="leasing_m_s"
                  type="column_stacked"
                  stacking="percent"
                  series={mom_approval_rate_v_rejection_rate['series']}
                  categories={mom_approval_rate_v_rejection_rate['categories']}
                  optionsSetting={{
                    yAxis: {
                      tickAmount: 8,
                      min: 0,
                      gridLineWidth: 0,
                      gridZIndex: 0,
                      title: {
                        text: ''
                      }, labels: {
                        format: '{text}%' // The $ is literally a dollar unit
                      },
                      visible: true,
                    }
                  }}
                />
              </KTCardBody>
            </KTCard>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
