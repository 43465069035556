import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import 'typeface-roboto'
import { MRT_Cell, MRT_DensityState, MRT_Row, MRT_RowData, MRT_RowSelectionState, MRT_SortingState, MRT_TableInstance, MRT_Virtualizer, MaterialReactTable, createRow } from "material-react-table";
// import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import { Box, CircularProgress, createTheme, IconButton, ThemeProvider, Tooltip, Typography, useTheme } from "@mui/material";
import { KTIcon } from "../KTIcon";
// import { MRT_ColumnDef } from "material-react-table";
import { ThemeModeComponent } from "../../../assets/ts/layout";
import { useThemeMode } from "../../../partials";
type PropsSimple = {
  data: any;
  columns: any;
  isLoading: boolean;
  enableRowActions?: boolean;
  enableRowSelection?: boolean;
  showColumnFilters?: boolean;
  enableEditing?: boolean;
  renderTopToolbarCustomActions?: any;
  onCreatingRowCancel?: any;
  columnPinningleft?: string[] | undefined;
  sizeaction?: number;
  renderRowActions?: ((props: {
    cell: MRT_Cell<MRT_RowData, unknown>;
    row: MRT_Row<MRT_RowData>;
    table: MRT_TableInstance<MRT_RowData>;
  }) => React.ReactNode) | undefined;
  columnVisibility?: any
};
const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";
const MaterialReactEditTable: FC<PropsSimple> = ({
  data,
  columns,
  isLoading,
  enableRowActions = true,
  enableRowSelection = false,
  showColumnFilters = false,
  enableEditing = false,
  renderTopToolbarCustomActions,
  onCreatingRowCancel,
  columnPinningleft,
  sizeaction = 75,
  renderRowActions,
  columnVisibility = {
    id: false,
  }
}) => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const { mode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  // const rowVirtualizerInstanceRef = useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: calculatedMode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              calculatedMode === 'light'
                ? 'rgb(255,255,255)' //random light yellow color for the background in light mode
                : '#000', //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          // MuiSwitch: {
          //   styleOverrides: {
          //     thumb: {
          //       color: "pink", //change the color of the switch thumb in the columns show/hide menu to pink
          //     },
          //   },
          // },
        },
      }),
    [calculatedMode, globalTheme.palette.secondary]
  );
  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable
        columns={columns}
        data={data}
        // createDisplayMode={'cell'} // ('modal', and 'custom' are also available)
        editDisplayMode={'table'} // ('modal', 'row', 'table', and 'custom' are also available)
        enableEditing
        onCreatingRowCancel={onCreatingRowCancel}
        getRowId={(row) => row['id']} //give each row a more useful id
        muiPaginationProps={{
          color: 'primary',
          shape: 'rounded',
          showRowsPerPage: false,
          variant: 'outlined',
        }}
        enableRowActions={enableRowActions}
        paginationDisplayMode={'pages'}
        enableRowSelection={enableRowSelection}
        enableGlobalFilter={true} //turn off a feature
        defaultDisplayColumn={{ enableResizing: true }}
        // enableBottomToolbar={true}
        enableColumnResizing
        enableGlobalFilterModes
        enablePagination={true}
        enablePinning
        enableFullScreenToggle
        // Freeze Header and Footer
        enableStickyHeader
        enableStickyFooter
        enableSelectAll={enableRowSelection}
        // enableMultiRowSelection
        //   defaultColumn={{
        //   maxSize: 400,
        //   minSize: 80,
        //   size: 150, //default size is usually 180
        // }}
        selectAllMode={"all"}
        positionToolbarAlertBanner="bottom"
        // enableRowNumbers
        muiTableContainerProps={{ sx: { maxHeight: "800px" } }}
        onSortingChange={setSorting}
        state={{ isLoading, sorting, rowSelection }}
        // tableInstanceRef={tableInstanceRef}
        enableFacetedValues
        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        // rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
        initialState={{
          showColumnFilters: showColumnFilters,
          columnVisibility: columnVisibility,
          density: "compact",
          // pagination: { pageSize: 30, pageIndex: 1 },
          columnPinning: {
            left: columnPinningleft,
            right: ["actions"],
          },
        }}
        muiCircularProgressProps={{
          color: 'secondary',
          thickness: 5,
          size: 55,
        }}
        muiSkeletonProps={{
          animation: 'pulse',
          height: 28,
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            size: sizeaction, //set custom width
            muiTableHeadCellProps: {
              align: "center", //change head cell props
            },
          },
          "mrt-row-numbers": {
            enableColumnOrdering: true, //turn on some features that are usually off
            enableResizing: false,
          },
          "mrt-row-select": {
            enableColumnActions: true,
            enableHiding: true,
            size: 30,
            muiTableBodyCellProps: {
              align: "center", //change head cell props
            },
          },
        }}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        muiTableHeadCellProps={{
          variant: "head",
          sx: {
            border: "1px dashed #e0e0e0",
            paddingTop: "15px",
            textTransform: "uppercase",
            whiteSpace: "normal",
            paddingY: 2,
            lineHeight: 1.5,
            wordWrap: "break-word",
          },
          align: "center",
        }}
        muiTableBodyCellProps={{
          sx: {
            width: '2rem',
            border: "1px dashed #e0e0e0",
            fontSize: "1.0rem",
            whiteSpace: "normal",
            wordWrap: "break-word",
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "0",
            border: "0px dashed #e0e0e0",
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          hover: false,
          //implement row selection click events manually
          // onClick: () =>
          //   setRowSelection((prev) => ({
          //     ...prev,
          //     [row.id]: !prev[row.id],
          //   })),
          // selected: rowSelection[row.id],
          sx: {
            // cursor: 'pointer',
            '&:nth-of-type(even)': {
              backgroundColor: "#f3f3f3 !important",
            },
          },
        })}
        // muiPaginationProps={{
        //   rowsPerPageOptions: [10, 30, 50, 100],
        //   showFirstButton: false,
        //   showLastButton: false,
        // }}
        icons={{
          //change sort icon, connect internal props so that it gets styled correctly
          ArrowDownwardIcon: (props) => <KTIcon iconName="down" {...props} />,
          // SearchIcon: () => <KTIcon iconName="down" />,
          // SortIcon: (props) => <KTIcon iconName="down" {...props} />, //best practice
        }}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        renderRowActions={renderRowActions}
      />
    </ThemeProvider>
  );
};
export { MaterialReactEditTable };
