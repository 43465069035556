import { fetchingGetData, multiPartFilesFetching } from "./API";

export class MmeAPI {
  async getMME() {
    let res = await fetchingGetData({
      url: `mme`,
      method: "get",
    });
    return res;
  }
  // MME Chart
  async getSMdoForecast(cov: string,m?: number | string) {
    let res = await fetchingGetData({
      url: `mme/sm_do_forecast/chart?coverage=${cov || ""}&month=${m || ""}`,
      method: "get",
    });
    return res;
  }
  async getSmSpkForecast(cov: string,m?: number | string) {
    let res = await fetchingGetData({
      url: `mme/sm_spk_forecast/chart?coverage=${cov || ""}&month=${m || ""}`,
      method: "get",
    });
    return res;
  }
  async getDOForecast(cov: string,m?: number | string) {
    let res = await fetchingGetData({
      url: `mme/do_forecast_actual/chart?coverage=${cov || ""}&month=${m || ""}`,
      method: "get",
    });
    return res;
  }
  // MME Table
  async getTableSMdoForecast(cov: string,m?: number | string) {
    let res = await fetchingGetData({
      url: `mme/sm_do_forecast/table?coverage=${cov || ""}&month=${m || ""}`,
      method: "get",
    });
    return res;
  }
  async getTableSMspkForecast(cov: string,m?: number | string) {
    let res = await fetchingGetData({
      url: `mme/sm_spk_forecast/table?coverage=${cov || ""}&month=${m || ""}`,
      method: "get",
    });
    return res;
  }
  async getTableDOForecast(cov: string,m?: number | string) {
    let res = await fetchingGetData({
      url: `mme/do_forecast_actual/table?coverage=${cov || ""}&month=${m || ""}`,
      method: "get",
    });
    return res;
  }

  async UploadFilesMME(file: any, onUploadProgress: any) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `mme/upload`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }
}
