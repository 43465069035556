/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers";
import { Form, Formik } from "formik";
import { NationalInformationAPI } from "../../../../api/NationalInformationAPI";
import FilterMultiSelect, { Option } from "../../../../../_metronic/helpers/components/form-actions/FilteringInput";
import DataTableMuiModel from "../../../../models/DataTable/dataTableModal";
import { MaterialReactTableDetails } from "../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable";
import { HighchartsSample } from "../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample";
import { GaikindoRSModel, GaikindoWSModel } from "../../../../models/sales/NationalInformationModel";
import DataTable from "./dataTable";
export class SalesFunnelNTargetAchievementIndex extends Component {
    constructor(props: any) {
        super(props);
        this["title"] = "Sales Funnel & Target Achievement";
        this["api"] = new NationalInformationAPI();
        this["month"] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        this["asm"] = ['Java', 'Sulbagsel - Bali', 'Sulbagut - Ternate'];
        this["state"] = {
            loading: true,
            initialValues: {
                month: [],
                brand: [],
            }, initialValuesChartColumnLine: {
                retail_sales: {
                    series: [],
                    categories: props["month"],
                    data: [],
                }, wholesales: {
                    series: [],
                    categories: props["month"],
                    data: [],
                },
            }, initialValuesChartPie: {
                retail_sales: {
                    series_old: [],
                    series_now: []
                }, wholesales: {
                    series_old: [],
                    series_now: []
                },
            }
        };
        this['option_asm'] = [];
        this['option_sm'] = [];
        this['option_month'] = [];
        this["datatable_rs"] = [];
        this["columntable_rs"] = [];
        this["datatable_ws"] = [];
        this["columntable_ws"] = [];
    }
    //* ----------------------------------------------------------------------------- */
    // BEGIN:: componentDidMount */
    async componentDidMount() {
        //? Load Filter Options Month
        await this['api'].getMonth().then(async (res: any) => {
            this['option_month'] = res['data'].map((val: any) => {
                return {
                    value: val['id'],
                    label: val['text'],
                    year: val['year']
                };
            });
        });
        //? Load Filter Options Dealer Group
        await this['api'].getBrand().then(async (res: any) => {
            this['option_brand'] = res['data'].map((val: any) => {
                return {
                    value: val['id'],
                    label: val['text'],
                };
            });

        });
        //! Load Chart 1
        await this.LoadChart1(this['state']['initialValues']);
        //! Load Chart 2
    }
    //** End: Component */
    //* ----------------------------------------------------------------------------- */
    //! Function Load Chart 1
    async LoadChart1(initial: []) {
        const { data: dataResultColumnLine } = await this["api"].getDataChartGaikindo({ ...initial, tipe: "ChartLineBar" });
        const {
            dataYearOld: dataRSYearOld,
            dataYearNow: dataRSYearNow,
            growth: growthRS,
            sumGrowthYTD: sumRSGrowthYTD,
            sumDataYTDNow: sumDataRSYTDNow,
            sumDataYTDold: sumDataRSYTDold,
        } = dataResultColumnLine["RSChartLine"][0];
        const {
            dataYearOld: dataWSYearOld,
            dataYearNow: dataWSYearNow,
            growth: growthWS,
            sumGrowthYTD: sumWSGrowthYTD,
            sumDataYTDNow: sumDataWSYTDNow,
            sumDataYTDold: sumDataWSYTDold,
        } = dataResultColumnLine["WSChartLine"][0];
        this["setState"]({
            ...this["state"],
            initialValuesChartColumnLine: {
                retail_sales: {
                    series: [
                        {
                            name: "Growth",
                            type: "line",
                            data: growthRS,
                            tooltip: {
                                valueSuffix: " %",
                            },
                        },
                    ],
                    categories: this["month"],
                }, wholesales: {
                    series: [{
                        name: `${this["option_month"][0]?.["year"] - 1} RS`,
                        type: "column",
                        yAxis: 1,
                        data: dataWSYearOld,
                    }, {
                        name: `${this["option_month"][0]?.["year"]} RS`,
                        type: "column",
                        yAxis: 1,
                        data: dataWSYearNow,
                    }, {
                        name: "Growth",
                        type: "line",
                        data: growthWS,
                        tooltip: {
                            valueSuffix: " %",
                        }
                    }],
                    categories: this["month"],
                    data: [{
                        name: "YTD Growth",
                        value: sumWSGrowthYTD,
                    }, {
                        name: `${this["option_month"][0]?.["year"]} YTD RS`,
                        value: sumDataWSYTDNow,
                    }, {
                        name: `${this["option_month"][0]?.["year"] - 1} YTD RS`,
                        value: sumDataWSYTDold,
                    }],
                }
            }
        });
    }
    //** End: Load Data List */
    render(this: any) {
        const { initialValues, initialValuesChartColumnLine, initialValuesChartPie } = this["state"];
        const { retail_sales: retail_sales_column_line, wholesales: wholesales_column_line } = initialValuesChartColumnLine;
        const { retail_sales: retail_sales_pie, wholesales: wholesales_pie } = initialValuesChartPie;
        return (
            <React.Fragment>
                <PageTitle breadcrumbs={[{ title: "Fleet", path: "/sales/sales-funnel-n-target-achievement", isSeparator: false, isActive: false }]}>
                    {this["title"]}
                </PageTitle>
                {/* BEGIN:: Filter Input */}
                <div className="row g-5">
                    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={() => { }}>
                        {({ setFieldValue, values }) => (
                            <Form>
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-lg-3 mb-5">
                                        <FilterMultiSelect
                                            label={"ASM"}
                                            name="asm"
                                            options={this['option_asm']}
                                            onChange={(selected: Option[]) => {
                                                setFieldValue("asm", selected);
                                                // const arrayValueMonth = selected.map((x: Object) => x["value"]);
                                                // const arrayValueDealerGroup = values["brand"].map((x: Object) => x["value"]);
                                                // setTimeout(() => {
                                                //     this.LoadChart2({
                                                //         month: arrayValueMonth,
                                                //         brand: arrayValueDealerGroup,
                                                //     });
                                                // }, 500);
                                            }}
                                            value={values["asm"]}
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-5">
                                        <FilterMultiSelect
                                            label={"SM"}
                                            name="sm"
                                            options={this['option_sm']}
                                            onChange={(selected: Option[]) => {
                                                setFieldValue("sm", selected);
                                                // const arrayValueMonth = values["month"].map((x: object) => x["value"]);
                                                // const arrayValueDealerGroup = selected.map((x: object) => x["value"]);
                                                // setTimeout(() => {
                                                //     this.LoadChart1({
                                                //         month: arrayValueMonth,
                                                //         brand: arrayValueDealerGroup,
                                                //     });
                                                // }, 500);
                                            }}
                                            value={values["sm"]}
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-5">
                                        <FilterMultiSelect
                                            label={"Month"}
                                            name="month"
                                            options={this['option_month']}
                                            onChange={(selected: Option[]) => {
                                                setFieldValue("month", selected);
                                                // const arrayValueMonth = values["month"].map((x: object) => x["value"]);
                                                // const arrayValueDealerGroup = selected.map((x: object) => x["value"]);
                                                // setTimeout(() => {
                                                //     this.LoadChart1({
                                                //         month: arrayValueMonth,
                                                //         brand: arrayValueDealerGroup,
                                                //     });
                                                // }, 500);
                                            }}
                                            value={values["month"]}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                {/* END:: Filter Input */}
                {/* ----------------------------------------------------------------------------- */}
                {/* Sales Funnel */}
                <div className="row g-5">
                    <div className='col-xxl-6 h-md-100 bd-highlight'>
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="fw-bold fs-4">Sales Funnel</span>
                                </h3>
                            </div>
                            <KTCardBody className="p-0">
                                <div className='mb-6'>
                                    <HighchartsSample
                                        id="w_o_test_drive"
                                        type="column_range"
                                        series={[]}
                                        height='500px'
                                        categories={[]}
                                        optionsSetting={{
                                            colors: ['#43a047'],
                                            legend: {
                                                enabled: false
                                            }, tooltip: {
                                                enabled: false,
                                            }
                                        }}
                                    />
                                </div>
                            </KTCardBody>
                        </KTCard>
                    </div>
                    {/* MoM Sales Funnel */}
                    <div className='col-xxl-6 h-md-100 bd-highlight'>
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="fw-bold fs-4">MoM Sales Funnel</span>
                                </h3>
                            </div>
                            <KTCardBody className="p-0">
                                <div className='mb-6'>
                                    <HighchartsSample
                                        id="chart_line"
                                        type="line"
                                        height='500px'
                                        series={[{
                                            name: "Leads",
                                            type: "line",
                                            data: [58, 15, 18, 11, 398, 275, 179, 61, 122, 30],
                                        }, {
                                            name: "Prospect",
                                            type: "line",
                                            data: [25, 8, 10, 10, 92, 28, 26, 5, 10, 11],
                                        }, {
                                            name: "Hot Prospect",
                                            type: "line",
                                            data: [20, 8, 10, 10, 69, 12, 15, 5, 10, 11],
                                        }, {
                                            name: "SPK",
                                            type: "line",
                                            data: [16, 8, 11, 9, 56, 10, 8, 6, 10, 9],
                                        }, {
                                            name: "DO",
                                            type: "line",
                                            data: [6, 7, 7, 6, 8, 4, 5, 5, 54, 3],
                                        }]}
                                        categories={this["month"]}
                                        chartStyle={{
                                            spacingLeft: 5,
                                            spacingRight: 5,
                                        }}
                                        optionsSetting={{
                                            xAxis: {
                                                visible: true
                                            }, yAxis: {
                                                gridLineWidth: 0,
                                                visible: true,
                                                min: 0,
                                                title: {
                                                    text: null
                                                }
                                            }, legend: {
                                                align: "center",
                                                verticalAlign: "top",
                                                layout: "horizontal",
                                                y: 16
                                            }, plotOptions: {
                                                line: {
                                                    dataLabels: {
                                                        format: "{point.y:.0f}",
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* ----------------------------------------------------------------------------- */}
                {/* DO Achievement by ASM */}
                <div className="row g-5">
                    <div className='col-xxl-6 h-md-100 bd-highlight'>
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="fw-bold fs-4">DO Achievement by ASM</span>
                                </h3>
                            </div>
                            <KTCardBody className="p-0">
                                <div className='mb-6'>
                                    <HighchartsSample
                                        id="chart_line"
                                        type="line"
                                        height='70px'
                                        series={[{
                                            name: "Leads",
                                            type: "line",
                                            data: [0, 16, 4.5],
                                            tooltip: {
                                                valueSuffix: " %",
                                            },
                                        }]}
                                        categories={this["asm"]}
                                        chartStyle={{
                                            spacingLeft: 5,
                                            spacingRight: 5,
                                        }}
                                        optionsSetting={{
                                            colors: ["#297f9d"],
                                            xAxis: {
                                                visible: false
                                            }, yAxis: {
                                                visible: false
                                            }, legend: {
                                                enabled: false
                                            }
                                        }}
                                    />
                                    <HighchartsSample
                                        id="chart_column"
                                        type="column_line"
                                        height='500px'
                                        series={[{
                                            name: `Target`,
                                            type: "column",
                                            yAxis: 1,
                                            data: [200, 600, 200],
                                        }, {
                                            name: `Actual`,
                                            type: "column",
                                            yAxis: 1,
                                            data: [0, 96, 9],
                                        }]}
                                        categories={this["asm"]}
                                        chartStyle={{
                                            spacingLeft: 5,
                                            spacingRight: 5,
                                        }}
                                        optionsSetting={{
                                            colors: ["#404040", "#43a047"],
                                            legend: {
                                                enabled: true
                                            }, xAxis: {
                                                crosshair: false
                                            }, tooltip: {
                                                shared: false,
                                            }
                                        }}
                                    />
                                </div>
                            </KTCardBody>
                        </KTCard>
                    </div>
                    {/* MoM DO Achievement */}
                    <div className='col-xxl-6 h-md-100 bd-highlight'>
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="fw-bold fs-4">MoM DO Achievement</span>
                                </h3>
                            </div>
                            <KTCardBody className="p-0">
                                <div className='mb-6'>
                                    <HighchartsSample
                                        id="chart_line"
                                        type="line"
                                        height='70px'
                                        series={[{
                                            name: "Leads",
                                            type: "line",
                                            data: [6, 7, 7, 6, 8, 4, 5, 5, 54, 3],
                                            tooltip: {
                                                valueSuffix: " %",
                                            },
                                        }]}
                                        categories={this["month"]}
                                        chartStyle={{
                                            spacingLeft: 5,
                                            spacingRight: 5,
                                        }}
                                        optionsSetting={{
                                            colors: ["#297f9d"],
                                            xAxis: {
                                                visible: false
                                            }, yAxis: {
                                                visible: false
                                            }, legend: {
                                                enabled: false
                                            }
                                        }}
                                    />
                                    <HighchartsSample
                                        id="chart_column"
                                        type="column_line"
                                        height='500px'
                                        series={[{
                                            name: `Target`,
                                            type: "column",
                                            yAxis: 1,
                                            data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
                                        }, {
                                            name: `Actual`,
                                            type: "column",
                                            yAxis: 1,
                                            data: [6, 7, 7, 6, 8, 4, 5, 5, 54, 3],
                                        }]}
                                        categories={this["month"]}
                                        chartStyle={{
                                            spacingLeft: 5,
                                            spacingRight: 5,
                                        }}
                                        optionsSetting={{
                                            colors: ["#404040", "#43a047"],
                                            legend: {
                                                enabled: true
                                            }, xAxis: {
                                                crosshair: false
                                            }, tooltip: {
                                                shared: false,
                                            }
                                        }}
                                    />
                                </div>
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* ----------------------------------------------------------------------------- */}
                {/* DO Achievement by ASM */}
                <div className="row g-5">
                    <div className='col-xxl-7 h-md-100 bd-highlight'>
                        <div className="bd-highlight">
                            <KTCard className='mb-5'>
                                <div className="card-header justify-content-center">
                                    <h3 className="card-title align-items-center flex-column">
                                        <span className="card-label fw-bold fs-1x">Reject Before Processing by Outlet</span>
                                    </h3>
                                </div>
                                <KTCardBody className="pl-5 px-2 pt-0">
                                    <DataTable
                                        maxHeight={"150px"}
                                        data={{
                                            columns: ['SM', 'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Dec', 'Total'],
                                            rows: [],
                                        }}
                                        backgroundColor={["6", "63%", "50%"]}
                                    />
                                </KTCardBody>
                            </KTCard>
                            <KTCard className='mb-5'>
                                <div className="card-header justify-content-center">
                                    <h3 className="card-title align-items-center flex-column">
                                        <span className="card-label fw-bold fs-1x">Reject Before Processing by Outlet</span>
                                    </h3>
                                </div>
                                <KTCardBody className="pl-5 px-2 pt-0">
                                    <DataTable
                                        maxHeight={"400px"}
                                        data={{
                                            columns: ['SM', 'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Dec', 'Total'],
                                            rows: [],
                                        }}
                                        backgroundColor={["6", "63%", "50%"]}
                                    />
                                </KTCardBody>
                            </KTCard>
                        </div>
                    </div>
                    {/* MoM DO Achievement */}
                    <div className='col-xxl-5 h-md-100 bd-highlight'>
                        <div className="bd-highlight">
                            <KTCard className='mb-5'>
                                <div className="card-header justify-content-center">
                                    <h3 className="card-title align-items-center flex-column">
                                        <span className="card-label fw-bold fs-1x">Reject Before Processing by Outlet</span>
                                    </h3>
                                </div>
                                <KTCardBody className="pl-5 px-2 pt-0">
                                <HighchartsSample
                                        id="source_leads"
                                        type="bar"
                                        series={[{
                                            type: 'bar',
                                            name: 'Leads',
                                            colorByPoint: false,
                                            data: [{
                                                name: 'DINAS KESEHATAN KOTA MAKASSAR',
                                                y: 47,
                                            }, {
                                                name: 'DINAS KESEHATAN KABUPATEN KEPULAUAN SANGIHE',
                                                y: 3,
                                            }, {
                                                name: 'DINAS KEPENDUDUKAN DAN PENCATATAN SIPIL KOTA MAKASSAR',
                                                y: 3,
                                            }, {
                                                name: 'PT. ALAM MUNDUK LESTARI',
                                                y: 2,
                                            }, {
                                                name: 'DINAS KESEHATAN KOTA BITUNG',
                                                y: 2,
                                            }, {
                                                name: 'PT. DEVASYA BALI TOURS',
                                                y: 2,
                                            }, {
                                                name: 'IDA BAGUS EKA BUDI PRIHANTARA',
                                                y: 2,
                                            }, {
                                                name: 'I NYOMAN SUDIARTHA',
                                                y: 2,
                                            }, {
                                                name: 'I GEDE ASTIKA',
                                                y: 1,
                                            }]
                                        }]}
                                        height='710px'
                                        optionsSetting={{
                                            colors: ['#43a047'],
                                            yAxis: {
                                                visible: false
                                            }, legend: {
                                                enabled: false
                                            }, tooltip: {
                                                enabled: true,
                                            }
                                        }}
                                    />
                                </KTCardBody>
                            </KTCard>
                        </div>
                    </div>
                </div>
                {/* ----------------------------------------------------------------------------- */}
            </React.Fragment>
        );
    }
}
export default SalesFunnelNTargetAchievementIndex;
