import { fetchingGetData } from "./API";

export class NationalInformationAPI {

  //! Get Chart Data Gaikindo
  async getDataChartGaikindo(values: any) {
    let res = await fetchingGetData({
      url: `sales/national-information/gaikindo?tipe=${values['tipe']}&brand=${values['brand']}&month=${values['month']}`,
      method: "get",
    });
    return res;
  }

  //! Get Chart Column Line Data Retail Sales & Whole Sale (RS WS)
  // Chart Column Line
  async getDataChartColumnLineRSWS(values: any) {
    let res = await fetchingGetData({
      url: `retail_whole_sales/national/chart?dealer=${values['dealer_group']}&month=${values['month']}&model=${values['model']}`,
      method: "get",
    });
    return res;
  }

  // Table RS WS
  //! Get Table Data Retail Sales & Whole Sale (RS WS)
  async getDataTableRsWs(values: any) {
    let res = await fetchingGetData({
      url: `retail_whole_sales/national/chart_table?dealer=${values['dealer_group']}&month=${values['month']}&model=${values['model']}`,
      method: "get",
    });
    return res;
  }

  // Chart Spline
  //! Get Chart Pie Retail Sales & Whole Sale (RS WS)
  async getDataChartSplineRSWS(values: any) {
    let res = await fetchingGetData({
      url: `retail_whole_sales/national/chart_spline?model=${values['model']}`,
      method: "get",
    });
    return res;
  }
  // Chart Pie
  //! Get Chart Pie Retail Sales & Whole Sale (RS WS)
  async getDataChartPieRsWs(values: any) {
    let res = await fetchingGetData({
      url: `retail_whole_sales/national/chart_pie?dealer=${values['dealer_group']}&month=${values['month']}&model=${values['model']}`,
      method: "get",
    });
    return res;
  }

  // Chart Column Stacked
  //! Get Chart Column Stacked Retail Sales & Whole Sale (RS WS)
  async getDataChartColumnStackedRSWS(values: any) {
    let res = await fetchingGetData({
      url: `retail_whole_sales/national/chart_column_group_stacked?dealer=${values['dealer_group']}&month=${values['month']}&model=${values['model']}`,
      method: "get",
    });
    return res;
  }

  //! Get Chart MAP National MS
  async getNationalMSMapChart() {
    let res = await fetchingGetData({
      url: `market_share/national/maps_national/chart`,
      method: "get",
    });
    return res;
  }

  //! Get Table Island & Province
  async getNationalMSTable() {
    let res = await fetchingGetData({
      url: `market_share/national/table/island_province`,
      method: "get",
    });
    return res;
  }

  //! Get Chart MAP National MS Area
  async getNationalMSArea() {
    let res = await fetchingGetData({
      url: `market_share/national/maps_area/chart`,
      method: "get",
    });
    return res;
  }

  //**Begin:: Get Select Data */
  //! Gaikindo Select API
  // Get Month
  async getMonth() {
    let res = await fetchingGetData({
      url: `select/national-information-gaikindo?tipe=getMonth`,
      method: "get",
    });
    return res;
  }
  // Get Brand
  async getBrand() {
    let res = await fetchingGetData({
      url: `select/national-information-gaikindo?tipe=getBrand`,
      method: "get",
    });
    return res;
  }
  //! Gaikindo Select API
  // Get Month
  async getMonthRSWS() {
    let res = await fetchingGetData({
      url: `select_retail_whole_sales/national/month`,
      method: "get",
    });
    return res;
  }
  // Get Brand
  async getBrandRSWS() {
    let res = await fetchingGetData({
      url: `select_retail_whole_sales/national/dealer_group`,
      method: "get",
    });
    return res;
  }
  // Get Model
  async getModelRSWS() {
    let res = await fetchingGetData({
      url: `select/national/model`,
      method: "get",
    });
    return res;
  }
  //**End:: Get Select Data */
}
