import React, { useEffect, useMemo, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import { Form, Formik } from 'formik';
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { MaterialReactTableDetails } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';
import moment from "moment";
import { Card3 } from '../../../../../_metronic/helpers/components/widgets/CardTemplate';

type dataType = {
    sm: string;
    days: number[];
    date: string;
};
type initialValues = {
    month: number[];
    asm: string[];
};

const StandingsIndex = (props: any) => {
    const [initialValues] = useState<initialValues>({ asm: [], month: [] });
    const [loading, setloading] = useState<boolean>(true);
    const [columnCreditProcess, setColumnCreditProcess] = useState<any>();
    const [dataCreditProcess, setDataCreditProcess] = useState<any>([]);
    const [columnMme, setColumnMme] = useState<any>();
    const [dataMme, setDataMme] = useState<any>([{
        name: "Kumala",
        up_down: 50,
        all_industry: 30381,
        wuling_vol: 800,
    }, {
        name: "Berkat",
        up_down: 40,
        all_industry: 3822,
        wuling_vol: 99,
    }, {
        name: "Maju",
        up_down: 10,
        all_industry: 32588,
        wuling_vol: 838,
    }, {
        name: "Arista",
        up_down: 20,
        all_industry: 82510,
        wuling_vol: 2081,
    }, {
        name: "Prima",
        up_down: 40,
        all_industry: 16817,
        wuling_vol: 357,
    }, {
        name: "Perdana",
        up_down: 70,
        all_industry: 9074,
        wuling_vol: 183,
    }, {
        name: "AJM",
        up_down: 60,
        all_industry: 18466,
        wuling_vol: 364,
    }]);
    const [columnStm, setColumnStm] = useState<any>();
    const [dataStm, setDataStm] = useState<any>([]);
    // API:: Credit Process
    const fetchingCreditProcessAPI = (values: object) => {
        setloading(true);
        const data: dataType[] = [{
            sm: "Pettarani - Perintis",
            days: [1, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }, {
            sm: "Gowa - Maros",
            days: [7, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }];
        let datasArray: any = [];
        data.map((item: any) => {
            let objData: any = {};
            for (let i = 0; i < item['days'].length; i++) {
                objData[`days${i + 1}`] = item['days'][i];
            }
            datasArray.push({
                sm: item['sm'],
                ...objData
            });
            return item;
        });
        let columns: any = [{
            header: 'SM',
            accessorKey: 'sm',
            size: 150,
            muiTableHeadCellProps: {
                align: "center"
            },
        }];
        // Berdasarkan Day in Month
        for (let i = 0; i < parseInt(moment('2023-09-01').endOf("month").format("D")); i++) {
            let objColumn: any = {};
            objColumn['header'] = `${i + 1}`;
            objColumn['accessorKey'] = `days${i + 1}`;
            objColumn['size'] = 10;
            objColumn['muiTableBodyCellProps'] = ({ cell }) => ({
                align: "center",
                sx: {
                    backgroundColor: cell.getValue() >= 5 ? "green" : "red",
                    color: cell.getValue() >= 5 ? "green" : "red",
                },
            });
            objColumn['muiTableHeadCellProps'] = {
                align: "center",
            };
            columns.push(objColumn);
        }
        setColumnCreditProcess(columns);
        setDataCreditProcess(datasArray);
        setloading(false);
    };
    // API:: MME
    // const fetchingMmeAPI = (values: object) => {
    //     setloading(true);
    //     const data: dataType[] = [{
    //         sm: "Pondok Indah",
    //         days: [1, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
    //         date: '2023-08-01'
    //     }, {
    //         sm: "Parung",
    //         days: [7, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
    //         date: '2023-08-01'
    //     }];
    //     let datasArray: any = [];
    //     data.map((item: any) => {
    //         let objData: any = {};
    //         for (let i = 0; i < item['days'].length; i++) {
    //             objData[`days${i + 1}`] = item['days'][i];
    //         }
    //         datasArray.push({
    //             sm: item['sm'],
    //             ...objData
    //         });
    //         return item;
    //     });
    //     let columns: any = [{
    //         header: 'SM',
    //         accessorKey: 'sm',
    //         size: 150,
    //         muiTableHeadCellProps: {
    //             align: "center"
    //         },
    //     }];
    //     // Berdasarkan Data Ada
    //     for (let i = 0; i < parseInt(moment('2023-09-01').endOf("month").format("D")); i++) {
    //         let objColumn: any = {};
    //         objColumn['header'] = `${i + 1}`;
    //         objColumn['accessorKey'] = `days${i + 1}`;
    //         objColumn['size'] = 10;
    //         objColumn['muiTableBodyCellProps'] = ({ cell }) => ({
    //             align: "center",
    //             sx: {
    //                 backgroundColor: cell.getValue() >= 5 ? "green" : "red",
    //                 color: cell.getValue() >= 5 ? "green" : "red",
    //             },
    //         });
    //         objColumn['muiTableHeadCellProps'] = {
    //             align: "center",
    //         };
    //         columns.push(objColumn);
    //     }
    //     setColumnMme(columns);
    //     setDataMme(datasArray);
    //     setloading(false);
    // };
    // API:: STM & Salesman Activity
    const fetchingStmAPI = () => {
        setloading(true);
        const data: dataType[] = [{
            sm: "Bandung BKR",
            days: [1, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }, {
            sm: "Parung",
            days: [7, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }];
        let datasArray: any = [];
        data.map((item: any) => {
            let objData: any = {};
            for (let i = 0; i < item['days'].length; i++) {
                objData[`days${i + 1}`] = item['days'][i];
            }
            datasArray.push({
                sm: item['sm'],
                ...objData
            });
            return item;
        });
        let columns: any = [{
            header: 'SM',
            accessorKey: 'sm',
            size: 100,
            muiTableHeadCellProps: {
                align: "center"
            },
        }];
        // Berdasarkan Year In MONTH
        let arrayMonth = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        for (let i = 0; i < arrayMonth.length; i++) {
            let objColumn: any = {};
            objColumn['header'] = arrayMonth[i];
            objColumn['accessorKey'] = `days${i + 1}`;
            objColumn['size'] = 10;
            // objColumn['maxSize'] = 10;
            objColumn['muiTableBodyCellProps'] = ({ cell }) => ({
                align: "center",
                sx: {
                    backgroundColor: cell.getValue() >= 5 ? "green" : "red",
                    color: cell.getValue() >= 5 ? "green" : "red",
                },
            });
            objColumn['muiTableHeadCellProps'] = {
                align: "center",
            };
            columns.push(objColumn);
        }
        setColumnStm(columns);
        setDataStm(datasArray);
        setloading(false);
    };
    // USEMEMO:: Credit Process Columns
    const creditProcessColumns = useMemo(() => [{
        id: 'dayCreditProcess',
        header: "Day/is Present",
        columns: columnCreditProcess,
        muiTableHeadCellProps: {
            align: "right"
        },
    }], [columnCreditProcess]);
    // USEMEMO:: MME Columns
    const MmeColumns = useMemo(() => [{
        header: "Rank",
        accessorKey: 'name',
        size: 15,
        muiTableHeadCellProps: {
            align: "center"
        }, muiTableBodyCellProps: {
            align: "center"
        }
    }, {
        header: "",
        accessorKey: 'up_down',
        size: 5,
        Cell: ({ cell }) => {
            return (
                // <i className="ki-duotone ki-arrow-up fs-2 text-success me-2">
                //     <span className="path1"></span>
                //     <span className="path2"></span>
                // </i>
                // <i className="ki-duotone ki-arrow-down fs-2 text-danger me-2">
                //     <span className="path1"></span>
                //     <span className="path2"></span>
                // </i>
                <span className="bullet w-15px me-5"></span>
            )
        }
    }, {
        header: "SM Name",
        accessorKey: 'all_industry',
        size: 95,
        muiTableHeadCellProps: {
            align: "center"
        }
    }, {
        header: "Score",
        accessorKey: 'wuling_vol',
        size: 95,
        muiTableHeadCellProps: {
            align: "center"
        }, muiTableBodyCellProps: {
            align: "center"
        }
    }], [columnMme]);
    // USEMEMO:: STM & Salesman Activity
    const StmColumns = useMemo(() => [{
        id: 'daySTM',
        header: "Day/is Present",
        columns: columnStm,
        muiTableHeadCellProps: {
            align: "right"
        },
    }], [columnStm]);
    useEffect(() => {
        fetchingCreditProcessAPI(initialValues);
        // fetchingMmeAPI(initialValues);
        fetchingStmAPI();
    }, [initialValues]);


    return (
        <>
            <PageTitle breadcrumbs={[{ title: "KPI Dashboard", path: "/sales/standings", isSeparator: false, isActive: false }]}>
                {"Standings"}
            </PageTitle>
            {/* BEGIN:: Card Filter Input */}
            <div className="col-xxl-12 mb-5">
                <KTCard flush stretch={"stretch"}>
                    <KTCardBody>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            onSubmit={() => { }}
                        >
                            {({ setFieldValue, values }) => (
                                <Form>
                                    <div className="row m-auto">
                                        <div className="col-lg-3 mb-5">
                                            <FilterMultiSelect
                                                label={"Month"}
                                                name="month"
                                                options={[]}
                                                onChange={(selected: Option[]) => {
                                                    setFieldValue("month", selected);
                                                    const arrayValueMonth = selected.map(
                                                        (x: Object) => x["value"]
                                                    );
                                                    const arrayValueAsm = values["range"].map(
                                                        (x: Object) => x["value"]
                                                    );
                                                    setTimeout(() => {
                                                        fetchingCreditProcessAPI({
                                                            range: arrayValueAsm,
                                                            month: arrayValueMonth,
                                                        })
                                                    }, 500);
                                                }}
                                                value={values["month"]}
                                            />
                                        </div>
                                        <div className="col-lg-3 mb-5">
                                            <FilterMultiSelect
                                                label={"Range"}
                                                name="range"
                                                options={[]}
                                                onChange={(selected: Option[]) => {
                                                    setFieldValue("range", selected);
                                                    const arrayValueMonth = values["month"].map(
                                                        (x: Object) => x["value"]
                                                    );
                                                    const arrayValueAsm = selected.map(
                                                        (x: Object) => x["value"]
                                                    );
                                                    setTimeout(() => {
                                                        fetchingCreditProcessAPI({
                                                            range: arrayValueAsm,
                                                            month: arrayValueMonth,
                                                        })
                                                    }, 500);
                                                }}
                                                value={values["dealer_group"]}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </KTCardBody>
                </KTCard>
            </div>
            {/* END:: Card Filter Input */}
            {/* ----------------------------------------------------------------------------- */}
            {/* BEGIN:: Table Credit Process  */}
            <div className="row g-5 mb-5">
                {/* Credit Process */}
                <div className="col-md-6 col-xl-4">
                    <Card3
                        avatar='/media/avatars/blank.png'
                        name='Sulbagsel Bali niyg ghgdhg'
                        job='Art Director'
                        avgEarnings='$14,560'
                        totalEarnings='$236,400'
                    />
                </div>
                {/* Credit Process */}
                <div className="col-md-6 col-xl-4">
                    <Card3
                        avatar='/media/avatars/blank.png'
                        name='Sulbagsel Bali'
                        job='Art Director'
                        avgEarnings='$14,560'
                        totalEarnings='$236,400'
                    />
                </div>
                {/* Credit Process */}
                <div className="col-md-6 col-xl-4">
                    <Card3
                        avatar='/media/avatars/blank.png'
                        name='Sulbagsel Bali'
                        job='Art Director'
                        avgEarnings='$14,560'
                        totalEarnings='$236,400'
                    />
                </div>
            </div>
            {/* END:: Table Credit Process  */}
            {/* ----------------------------------------------------------------------------- */}
            {/* BEGIN:: Table Sales Manager Standings & Outlet Standings */}
            <div className="row flex-xxl-row h-md-100">
                {/* Sales Manager Standings */}
                <div className="col-xxl-6 h-md-100 mb-5 bd-highlight">
                    <KTCard flush stretch={"stretch-75"}>
                        <div className="card-header d-flex align-items-center flex-column w-100 border-0 pt-5 pb-0">
                            {/* begin::Title */}
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1 mb-1">Table Sales Manager Standings</span>
                            </h3>
                            {/* end::Title */}
                        </div>
                        <KTCardBody className="pl-5 pt-0">
                            <MaterialReactTableDetails
                                columns={MmeColumns}
                                data={dataMme}
                                isLoading={loading}
                                maxHeight={"600px"}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
                {/* Outlet Standings */}
                <div className="col-xxl-6 h-md-100 mb-5 bd-highlight">
                    <KTCard flush stretch={"stretch-75"}>
                        <div className="card-header d-flex align-items-center flex-column w-100 border-0 pt-5 pb-0">
                            {/* begin::Title */}
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1 mb-1">Outlet Standings</span>
                            </h3>
                            {/* end::Title */}
                        </div>
                        <KTCardBody className="pl-5 pt-0">
                            <MaterialReactTableDetails
                                columns={MmeColumns}
                                data={dataMme}
                                isLoading={loading}
                                maxHeight={"600px"}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* END:: Table Sales Manager Standings & Outlet Standings  */}
        </>
    )
}

export default StandingsIndex;