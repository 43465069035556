import * as React from 'react';
import { ThemeProvider, makeStyles, createTheme, useTheme, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { TableFooter } from '@mui/material';
import { backgroundColorHSLDarkToLight } from '../../../../../_metronic/helpers/components/MaterialReactTable/ComponentStyleRowsTable';
import { ThemeModeComponent } from '../../../../../_metronic/assets/ts/layout';
import { useThemeMode } from '../../../../../_metronic/partials';

const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";
export default function DataTable({
    maxHeight,
    data
}) {
    const { mode } = useThemeMode();
    const calculatedMode = mode === "system" ? systemMode : mode;
    const globalTheme = useTheme();
    const tableTheme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: calculatedMode, //let's use the same dark/light mode as the global theme
                    primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
                    info: {
                        main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            calculatedMode === 'light'
                                ? 'rgb(255,255,255)' //random light yellow color for the background in light mode
                                : '#000', //pure black table in dark mode for fun
                    },
                }
            }),
        [calculatedMode, globalTheme.palette.secondary]
    );

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            // backgroundColor: '#00897b',
            fontFamily: 'serif',
            fontSize: 9.5,
        }, [`&.${tableCellClasses.body}`]: {
            fontSize: 9.5,
            fontFamily: 'serif',
        },

    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            border: 0,
            fontSize: 9.5,
            fontFamily: 'serif',
            color: calculatedMode === 'light' ? 'black' : 'white',
        },
        // hide last border
        '&:last-child(2) td': {
            backgroundColor: theme.palette.action.hover,
            border: 0,
            fontSize: 9.5,
            fontFamily: 'serif',
            color: calculatedMode === 'light' ? 'black' : 'white',
        },
    }));

    const column_header = ['Model', 'Varian', '> 1 Year', '7 - 12 Month', '4 - 6 Month', '1 - 3 Month', '4 - 6 Month', '7 - 12 Month', '> 1 Year']

    return (
        <ThemeProvider theme={tableTheme}>
            <TableContainer sx={{
                maxHeight: maxHeight,
                padding: 0,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                fontFamily: 'serif',
            }}>
                <Table size="small" aria-label="table with sticky header">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell colSpan={2} style={{
                                top: 0,
                                position: "sticky",
                                fontSize: 9.5,
                                fontFamily: 'serif',
                                backgroundColor: '#fbfcfe',
                                zIndex: 1
                            }}></StyledTableCell>
                            <StyledTableCell colSpan={3} style={{
                                top: 0,
                                position: "sticky",
                                fontSize: 9.5,
                                fontFamily: 'serif',
                                backgroundColor: '#fbfcfe',
                                zIndex: 1
                            }} align="right">2022</StyledTableCell>
                            <StyledTableCell colSpan={4} style={{
                                top: 0,
                                position: "sticky",
                                fontSize: 9.5,
                                fontFamily: 'serif',
                                backgroundColor: '#fbfcfe',
                                zIndex: 1
                            }} align="right">2023</StyledTableCell>
                        </StyledTableRow>
                        <TableRow>
                            {column_header.map(val => {
                                return (
                                    <StyledTableCell style={{
                                        top: 31,
                                        position: "sticky",
                                        fontSize: 9.5,
                                        fontFamily: 'serif',
                                        backgroundColor: '#fbfcfe',
                                        zIndex: 1
                                    }} align="center">{val}</StyledTableCell>
                                )
                            })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* START:: LOPING */}
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={2}>FORMO</StyledTableCell>
                            <StyledTableCell>FORMO 1.5 MAX AC LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">19</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">20</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>FORMO 1.5 MAX STANDARD LV0</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">18</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        {/* END:: LOPING */}
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell rowSpan={3}>CORTEZ</StyledTableCell>
                            <StyledTableCell>Cortez 1.5L T Lux+ CVT A/T</StyledTableCell>
                            <StyledTableCell align="right">13</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>Cortez CE Lux+ CVT</StyledTableCell>
                            <StyledTableCell align="right">8</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>

                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>Cortez EX Lux+ CVT</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">3</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">1</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>
                            <StyledTableCell align="right">-</StyledTableCell>

                        </StyledTableRow>
                    </TableBody>
                    <TableFooter sx={{
                        position: "sticky",
                        bottom: 0,
                        fontFamily: 'serif',
                        fontSize: 9.5,
                        backgroundColor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                        color: calculatedMode === 'light' ? 'black' : 'white',
                    }}>
                        <TableRow hover tabIndex={-1}>
                            <StyledTableCell colSpan={2} style={{ fontWeight: "bold", fontFamily: 'serif' }}>Total Keseluruhan</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", }} align="right">53</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", }} align="right">16</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", }} align="right">3</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", }} align="right">508</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", }} align="right">205</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", }} align="right">153</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", }} align="right">23</StyledTableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </ThemeProvider >
    );
}
