/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import { Component } from "react";

import * as Yup from "yup";

import { Formik } from "formik";

import { SystemConfigurationAPI } from "../../../api/SystemConfigurationAPI";
import { KTCard, KTCardBody, KTIcon } from "../../../../_metronic/helpers";
import DataTableMuiModel from "../../../models/DataTable/dataTableModal";
import MenuConfigurasiModel from "../../../models/SystemConfiguration/menu-configurations/menuConfigurasiModel";
import { PageTitle } from "../../../../_metronic/layout/core";
import { MaterialReactTableSimple } from "../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable";
import { ButtonAction } from "../../../../_metronic/helpers/components/form-actions/FormButton";
import {
  InputNumber,
  InputSelect2,
  InputText,
  InputTextArea,
} from "../../../../_metronic/helpers/components/form-actions/FormInput";
import { FormModal } from "../../../../_metronic/helpers/components/form-actions/ModalForm";
import SimpleAlert from "../../../../_metronic/helpers/components/alert/SimpleAlert";
import { Box } from "@mui/material";
import { toSlug } from "../../../../_metronic/helpers/helpers-data/AssetHelpers";
import iconsArray from "../../../../_metronic/helpers/icons-config/iconsArrays";

function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 1000));
}

export class MenuConfiguration extends Component {
  constructor(props) {
    super(props);
    this["title"] = "Menu Configuration";
    this["api"] = new SystemConfigurationAPI();
    this["initialData"] = new MenuConfigurasiModel();
    this["option_icon"] = [];
    this["option_parent"] = [];
    this["option_sub_parent"] = [];
    this["option_sub_child_parent"] = [];
    this["option_brand"] = [];
    this["datatable"] = [];
    this["columntable"] = [];
    this["state"] = {
      disabledIcon: false,
      disabledBrand: true,
      isLoading: true,
      loading: false,
      modal: {
        loading: false,
        show: false,
        initialData: this["initialData"],
        isEdit: false,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
  }
  //** Start: Component */
  async componentDidMount() {
    await this.LoadData();
  }
  //** End: Component */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Data List */
  async LoadData() {
    this.setState({ loading: true });
    let data = await this["api"].getMenuConfiguration();
    this["DataTableMuiModel"] = new DataTableMuiModel(this.props);
    data["data"].map((item: any, i: number) => {
      let dataModel = new MenuConfigurasiModel(item);
      if (item["status"] === 1) {
        this["DataTableMuiModel"].add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              <div className="d-flex justify-content-center flex-shrink-0">
                <ButtonAction
                  title={"Edit"}
                  iconName="pencil"
                  onClick={() => this["handleModal"].edit(dataModel)}
                />
                <ButtonAction title={"Delete"} iconName="trash"
                  onClick={() => {
                    this['handleAlert'].show({ id: item['id'] });
                  }} />
              </div>
            </>
          ),
        });
      }
      return item;
    });
    this["datatable"] = await this["DataTableMuiModel"].getDatas();
    this["columntable"] = await this["DataTableMuiModel"].getColumn();
    this.setState({ ...this["state"], loading: false });
  }
  //** End: Load Data List */

  //** Begin:: Handle Modal && Alert */
  handleModal = {
    loadOptionParent: async () => {
      this.setState({ isLoading: true }, () => {
        simulateNetworkRequest().then(() => {
          this["api"].getMenuParent().then((res: any) => {
            this["option_parent"] = res["data"].map((val: string) => {
              return {
                value: val["id"],
                label: val["text"],
              };
            });
            this.setState({ isLoading: false });
          });
        });
      });
    },
    loadOptionBrand: async () => {
      this.setState({ isLoading: true }, () => {
        simulateNetworkRequest().then(() => {
          this["api"].getSelectMasterBrand().then((res: any) => {
            this["option_brand"] = res["data"].map((val: string) => {
              return {
                value: val["alias"],
                label: (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                      }}
                    >
                      <img
                        alt="logos"
                        height={20}
                        src={val["logos"]}
                        loading="lazy"
                        style={{ borderRadius: '50%' }}
                      />
                      <span style={{ marginLeft: "5px", textTransform: "capitalize" }}>
                        {val["text"]}
                      </span>
                    </Box>

                  </>
                ),
                // label: val["text"],
              };
            });
            this.setState({ isLoading: false });
          });
        });
      });
    },
    loadOptionSubParent: async (id: any) => {
      this.setState({ isLoading: true }, () => {
        simulateNetworkRequest().then(() => {
          this["api"].getMenuSubParent(id).then((res: any) => {
            this["option_sub_parent"] = res["data"].map((val: string) => {
              return {
                value: val["id"],
                label: val["text"],
              };
            });
            this.setState({ isLoading: false });
          });
        });
      });
    },
    loadOptionSubChildParent: async (id: any) => {
      this.setState({ isLoading: true }, () => {
        simulateNetworkRequest().then(() => {
          this["api"].getMenuSubChildParent(id).then((res: any) => {
            this["option_sub_child_parent"] = res["data"].map((val: string) => {
              return {
                value: val["id"],
                label: val["text"],
              };
            });
            this.setState({ isLoading: false });
          });
        });
      });
    },
    loadOptionCheateSheet: async () => {
      this["option_icon"] = iconsArray().map((val) => {
        return {
          value: val,
          label: (
            <>
              <KTIcon className={"fs-1"} iconName={val} />{" "}
              <span style={{ marginLeft: "5px", textTransform: "capitalize" }}>
                {val}
              </span>
            </>
          ),
        };
      });
    },
    tambah: async () => {
      let state = { ...this["state"] };
      state = {
        modal: {
          show: true,
          title: `Tambah ${this["title"]}`,
          isEdit: false,
          initialData: this["initialData"],
        },
      };
      this.setState({ ...state });
      this["handleModal"].loadOptionParent();
      this["handleModal"].loadOptionCheateSheet();
      this["handleModal"].loadOptionBrand();
    },
    edit: async (dataFromModel: any) => {
      let state = { ...this["state"] };
      state = {
        modal: {
          show: true,
          title: `Edit ${this["title"]}`,
          isEdit: true,
          initialData: dataFromModel,
        },
      };
      console.warn('dataFromModel', dataFromModel);
      
      if (dataFromModel['id_parent'] !== '') {
        this.setState({ disabledIcon: false });
      } else {
        this.setState({ disabledIcon: true });
      }
      if (dataFromModel['id_sub_parent'] !== '') {
        this.setState({ disabledIcon: true });
      } else {
        this.setState({ disabledIcon: false });
      }
      if (dataFromModel['alias_brand'] !== '') {
        this.setState({ disabledBrand: false });
      } else {
        this.setState({ disabledBrand: true });
      }
      if (dataFromModel['nama_sub_parent'] === undefined && dataFromModel['nama_sub_child_parent'] === undefined) {
        this.setState({ disabledIcon: false });
      } else {
        this.setState({ disabledIcon: true });
      }
      this.setState({ ...state });
      this["handleModal"].loadOptionParent();
      this["handleModal"].loadOptionSubParent(dataFromModel['id_parent']);
      this["handleModal"].loadOptionSubChildParent(dataFromModel['id_sub_parent']);
      this["handleModal"].loadOptionCheateSheet();
      this["handleModal"].loadOptionBrand();
    },
    hide: () => {
      let state = { ...this["state"] };
      state = {
        modal: {
          show: false,
          initialData: this["initialData"],
        },
      };
      this.setState({ ...state });
    }
  };

  handleAlert = {
    show: (id) => {

      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: true,
          activeId: id
        }
      }
      this.setState({ ...alert });
    },
    hide: () => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: false,
          activeId: null
        }
      }
      this.setState({ ...alert });
    },
    confirmed: async () => {
      await this["api"].deleteDataMenuConfiguration(this['state']['alert']['activeId']);
      this['handleAlert'].hide();
      setTimeout(() => {
        this.LoadData();
      }, 2000);
    },
  };
  //** End:: Handle Modal && Alert */
  render() {
    return (
      <>
        <PageTitle breadcrumbs={[]}>{this["title"]}</PageTitle>
        {/* Begin:: Card */}
        <KTCard>
          <div className="card-header border-0 pt-6">
            {/* Card Title */}
            <div className="card-title"></div>
            {/* Begin:: Card Toolbar */}
            <div className="card-toolbar">
              <div
                className="d-flex justify-content-end"
                data-kt-user-table-toolbar="base"
              >
                <button
                  type={"button"}
                  className={"btn btn-sm btn-light-primary"}
                  onClick={() => {
                    this["handleModal"].tambah();
                  }}
                >
                  <KTIcon iconName={"plus"} className={"fs-4 me-2"} /> Tambah
                </button>
              </div>
            </div>
            {/* End:: Card Toolbar */}
          </div>
          {/* Begin:: Card Body */}
          <KTCardBody className="py-4">
            <div className="table-responsive">
              <MaterialReactTableSimple
                data={this["datatable"]}
                columns={this["columntable"]}
                isLoading={this["state"]["loading"]}
              />
            </div>
          </KTCardBody>
          {/* End:: Card Body */}
        </KTCard>
        {/* End:: Card */}

        {/* Begin:: Modal */}
        <Formik
          enableReinitialize={true}
          initialValues={this["state"]["modal"]["initialData"]}
          validationSchema={Yup.object().shape({
            alias_brand: Yup.array().when('menu_inner', {
              is: (v: any) => v !== undefined,
              then: (schema) => { return schema.min(1, "Brand is required").required('Brand is required') }
            }),
            icon_menu: Yup.string().when('id_sub_parent', {
              is: (v: any) => v === undefined,
              then: (schema) => { return schema.required('Icon is required') }
            }),
            nama_menu: Yup.string().required("Nama Menu is required"),
            path: Yup.string().required("Path is required"),
          })}
          onSubmit={async (values, { resetForm }) => {
            const data = {
              menu_inner: values["menu_inner"],
              alias_brand: values["alias_brand"],
              id_parent: values["id_parent"] ? values["id_parent"] : null,
              id_sub_parent: values["id_sub_parent"] ? values["id_sub_parent"] : null,
              id_sub_child_parent: values["id_sub_child_parent"] ? values["id_sub_child_parent"] : null,
              nama: values["nama_menu"],
              path: values["path"],
              icon: values["icon_menu"],
              urutan: values["urutan"],
            };
            if (this["state"]["modal"]['isEdit']) {
              // Update Data
              const dataSend = { id: values["id"], ...data, }
              await this["api"].updateDataMenuConfiguration(dataSend);
            } else {
              await this["api"].setDataMenuConfiguration(data);
            }
            this['handleModal'].hide();
            setTimeout(() => {
              resetForm();
              this.LoadData();
            }, 2000);
          }}
        >
          {(formik) => (
            <FormModal
              title={this["state"]["modal"]["title"]}
              show={this["state"]["modal"]["show"]}
              onHide={this["handleModal"]["hide"]}
              onConfirm={formik.handleSubmit}
              content={
                <>
                  <div className="row mb-1">
                    <div className="col-lg-6">
                      <InputSelect2
                        name="menu_inner"
                        label={"Menu Inner"}
                        options={[
                          {
                            value: "sales",
                            label: "Sales",
                          },
                          {
                            value: "after_sales",
                            label: "After Sales",
                          },
                          {
                            value: "financial",
                            label: "Financial",
                          },
                          {
                            value: "tax",
                            label: "Tax",
                          },
                          {
                            value: "accounting",
                            label: "Accounting",
                          },
                          {
                            value: "login_history",
                            label: "Login History",
                          },
                        ]}
                        isClearable
                        isSearchable
                        formik={formik}
                        onChange={(e: number) => {
                          const { nama_menu, id_parent, id_sub_parent } = formik["values"];
                          const value = e?.["value"];
                          if (value) {
                            // Condition
                            this.setState({ disabledBrand: false });
                            if (!id_parent && !id_sub_parent && !nama_menu) {
                              this.setState({ disabledIcon: false });
                              formik.setFieldValue("path", `/${toSlug(value)}`);
                            } else if (!id_parent && !id_sub_parent && nama_menu) {
                              formik.setFieldValue("icon_menu", "");
                              this.setState({ disabledIcon: true });
                              formik.setFieldValue("path", `/${toSlug(value)}/${toSlug(nama_menu)}`);
                            } else if (id_parent && !id_sub_parent && !nama_menu) {
                              this.setState({ disabledIcon: false });
                              formik.setFieldValue("path", `/${toSlug(value)}/${toSlug(nama_menu)}`);
                            } else if (id_parent && !id_sub_parent && nama_menu) {
                              this.setState({ disabledIcon: false });
                              formik.setFieldValue("path", `/${toSlug(value)}/${toSlug(nama_menu)}`);
                            } else if (id_parent && id_sub_parent && nama_menu) {
                              formik.setFieldValue("icon_menu", "");
                              this.setState({ disabledIcon: true });
                              formik.setFieldValue("path", `/${toSlug(value)}/${toSlug(nama_menu)}`);
                            }
                          } else {
                            this.setState({ disabledBrand: true });
                            formik.setFieldValue("alias_brand", "");
                            if (!id_parent && !id_sub_parent) {
                              this.setState({ disabledIcon: false });
                            } else if (id_parent && !id_sub_parent) {
                              this.setState({ disabledIcon: false });
                            } else {
                              formik.setFieldValue("icon_menu", "");
                              this.setState({ disabledIcon: true });
                            }
                            formik.setFieldValue("path", `/${toSlug(nama_menu)}`);
                          }
                        }}
                      />
                      <InputSelect2
                        name="alias_brand"
                        label={"Brand"}
                        required={formik["values"]['menu_inner'] !== ""}
                        options={this["option_brand"]}
                        isClearable
                        disabled={this.state["disabledBrand"]}
                        isMulti
                        isSearchable
                        formik={formik}
                      />
                      <InputSelect2
                        name="id_parent"
                        label={"Parent Menu"}
                        options={this["option_parent"]}
                        isLoading={this["state"]["isLoading"]}
                        isClearable
                        isSearchable
                        formik={formik}
                        onChange={(e: number) => {
                          const { menu_inner, id_sub_parent, nama_menu } = formik["values"];
                          const value = e?.["value"];
                          if (value) {
                            this["handleModal"].loadOptionSubParent(value);
                            // Condition
                            if (!menu_inner && !id_sub_parent && !nama_menu) {
                              this.setState({ disabledIcon: false });
                              formik.setFieldValue("path", `/`);
                            } else if (menu_inner && !id_sub_parent && !nama_menu) {
                              this.setState({ disabledIcon: false });
                              formik.setFieldValue("path", `/${toSlug(menu_inner)}`);
                            } else if (menu_inner && id_sub_parent && !nama_menu) {
                              formik.setFieldValue("icon_menu", "");
                              this.setState({ disabledIcon: true });
                              formik.setFieldValue("path", `/${toSlug(menu_inner)}`);
                            } else if (menu_inner && id_sub_parent && nama_menu) {
                              formik.setFieldValue("icon_menu", "");
                              this.setState({ disabledIcon: true });
                              formik.setFieldValue("path", `/${toSlug(menu_inner)}/${toSlug(nama_menu)}`);
                            }
                          } else {
                            this["handleModal"].loadOptionSubParent("");
                            if (!menu_inner && !nama_menu) {
                              this.setState({ disabledIcon: false });
                              formik.setFieldValue("path", `/`);
                            } else if (menu_inner && nama_menu) {
                              this.setState({ disabledIcon: false });
                              formik.setFieldValue("path", `/${toSlug(menu_inner)}/${toSlug(nama_menu)}`);
                            }
                          }
                        }}
                      />
                      <InputSelect2
                        name="id_sub_parent"
                        label={"Parent Sub Menu"}
                        options={this["option_sub_parent"]}
                        isLoading={this["state"]["isLoading"]}
                        isClearable
                        isSearchable
                        formik={formik}
                        onChange={(e: number) => {
                          const value = e?.["value"];
                          this["handleModal"].loadOptionSubChildParent(value);
                          if (value) {
                            // Condition
                            formik.setFieldValue("icon_menu", "");
                            this.setState({ disabledIcon: true });
                          } else {
                            this.setState({ disabledIcon: false });
                          }
                        }}
                      />
                      <InputSelect2
                        name="id_sub_child_parent"
                        label={"Parent Sub Child Menu"}
                        options={this["option_sub_child_parent"]}
                        isLoading={this["state"]["isLoading"]}
                        isClearable
                        isSearchable
                        formik={formik}
                        onChange={(e: number) => {
                          const value = e?.["value"];
                          if (value) {
                            // Condition
                            formik.setFieldValue("icon_menu", "");
                            this.setState({ disabledIcon: true });
                          } else {
                            this.setState({ disabledIcon: false });
                          }
                        }}
                      />
                      <InputText
                        name="nama_menu"
                        label={"Nama Menu"}
                        required
                        formik={formik}
                        onChange={(e: string) => {
                          const { menu_inner, id_parent, id_sub_parent } = formik["values"];
                          const value = e["target"]["value"];
                          if (e["target"]["value"]) {
                            // Condition
                            if (!menu_inner && !id_parent && !id_sub_parent) {
                              this.setState({ disabledIcon: false });
                              formik.setFieldValue("path", `/${toSlug(value)}`);
                            } else if (!menu_inner && id_parent && !id_sub_parent) {
                              this.setState({ disabledIcon: false });
                              formik.setFieldValue("path", `/${toSlug(value)}`);
                            } else if (!menu_inner && id_parent && id_sub_parent) {
                              formik.setFieldValue("icon_menu", "");
                              this.setState({ disabledIcon: true });
                              formik.setFieldValue("path", `/${toSlug(value)}`);
                            } else if (menu_inner && !id_parent && !id_sub_parent) {
                              this.setState({ disabledIcon: false });
                              formik.setFieldValue("path", `/${toSlug(menu_inner)}/${toSlug(value)}`);
                            } else if (menu_inner && id_parent && id_sub_parent) {
                              formik.setFieldValue("icon_menu", "");
                              this.setState({ disabledIcon: true });
                              formik.setFieldValue("path", `/${toSlug(menu_inner)}/${toSlug(value)}`);
                            } else {
                              this.setState({ disabledIcon: false });
                              formik.setFieldValue("path", `/${toSlug(menu_inner)}/${toSlug(value)}`);
                            }
                          } else {
                            formik.setFieldValue("path", menu_inner ? `/${toSlug(menu_inner)}` : "/");
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <InputSelect2
                        name="icon_menu"
                        label={"Icon Menu"}
                        options={this["option_icon"]}
                        isClearable
                        disabled={this.state["disabledIcon"]}
                        isSearchable
                        formik={formik}
                      />
                      <InputTextArea
                        name="path"
                        label={"Path"}
                        required
                        disabled
                        formik={formik}
                        value={formik["values"]}
                      />
                      <InputNumber
                        name="urutan"
                        label={"Nomor Urut"}
                        formik={formik}
                      />
                    </div>
                  </div>
                </>
              }
            />
          )}
        </Formik>
        {/* End:: Modal */}
        {/* Alert Hapus */}
        <SimpleAlert
          title={"Hapus Data"}
          content={"Anda yakin hapus data ini...?"}
          show={this['state']['alert']['show']}
          onHide={() => this['handleAlert'].hide()}
          onConfirmed={this['handleAlert']['confirmed']}
        />
      </>
    );
  }
}

export default MenuConfiguration;
