import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);

function PieTeamFocus() {
    const chartComponent = React.useRef(null);

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            backgroundColor: "transparent",
            type: "pie",
            height: 400
        },
        title: {
            text: "Browser market shares in March, 2022",
            align: "center",
        },
        tooltip: {
            // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            pointFormat: "{point.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
            point: {
                valueSuffix: "%",
            },
        },
        legend: {
            layout: "vertical",
            align: "right",
            verticalAlign: "middle",
            // itemMarginTop: 10,
            // itemMarginBottom: 10
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    format: "{point.y:.1f} %",
                    distance: -40,
                    align: "center",
                    style: {
                        fontSize: "15px",
                    }
                },
                slicedOffset: 0,
                showInLegend: true,
            },
        },
        series: [
            {
                // colorByPoint: true,
                name: "Brands",
                colorByPoint: true,
                states: {
                    hover: {
                        enabled: true,
                    },
                    inactive: {
                        enabled: false,
                    },
                },
                data: [
                    {
                        name: "Team Model",
                        color: "#439F47",
                        y: 57.1,
                        // sliced: true,
                        // selected: true,
                    },
                    {
                        name: "Non Team Model",
                        color: "#BF392B",
                        y: 42.9,
                    },
                ],
            },
        ],
    };
    return (
        <>
            <HighchartsReact
                ref={chartComponent}
                highcharts={Highcharts}
                options={options}
            // series={series}
            />
        </>
    );
}




type Column = {
    id: 'name' | 'code' | 'population' | 'size' | 'density';
    label: string;
    minWidth?: number;
    align?: 'right';
    style?: { [key: string]: string };
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'name', label: 'Name', minWidth: 150 },
    { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    {
        id: 'population',
        label: 'Population',
        minWidth: 150,
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'size',
        label: 'Size\u00a0(km\u00b2)',
        minWidth: 150,
        align: 'right',
        style: { color: 'green' },
        format: (value: number) => value.toLocaleString('en-US'),
    },
    // {
    //     id: 'density',
    //     label: 'Density',
    //     minWidth: 170,
    //     align: 'right',
    //     format: (value: number) => value.toFixed(2),
    // },
];

type Data = {
    name: string;
    code: string;
    population: number;
    size: number;
    // density: number;
}

function createData(
    name: string,
    code: string,
    population: number,
    size: number,
): Data {
    // const density = population / size;
    return { name, code, population, size };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];

type TeamFocusProp = {
    title: string;
}

function TableTeamFocus({ title }: TeamFocusProp) {
    return (
        <>
            <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
                {/* begin::Title */}
                <h3 className="align-items-center flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">{title}</span>
                </h3>
            </div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 700 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align} style={column.style} >
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter
                        style={{
                            position: "sticky",
                            bottom: "0px",
                            backgroundColor: "white",
                          }}>
                            <TableRow>
                                <TableCell align="left"
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        color: "black",
                                    }}
                                    colSpan={2}>Test</TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}

export { PieTeamFocus, TableTeamFocus }