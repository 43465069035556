export class ModelUploadMarketSharePriceSegment {
    constructor(
        data = {
            id_price_segment: "",
            date: "",
            asm: "",
            sm: "",
            island: "",
            merk: "",
            type: "",
            type_sgmw: "",
            tidy_type: "",
            price: "",
            price_range: "",
            proper_type: "",
            area: "",
            order_price_range: "",
            wuling_price_segment: "",
            province: "",
            kab: "",
            kec: "",
            segment: "",
            vol: 0,
            created_at: "",
            updated_at: "",
            deleted_at: "",
        }
    ) {
        const { id_price_segment, date, asm, sm, island, merk, type, type_sgmw, tidy_type, price, price_range, proper_type, area, order_price_range, wuling_price_segment, province, kab, kec, segment, vol, created_at, updated_at, deleted_at } = data;
        this["id_price_segment"] = id_price_segment;
        this["date"] = date;
        this["asm"] = asm;
        this["sm"] = sm;
        this["island"] = island;
        this["merk"] = merk;
        this["type"] = type;
        this["type_sgmw"] = type_sgmw;
        this["tidy_type"] = tidy_type;
        this["price"] = price;
        this["price_range"] = price_range;
        this["proper_type"] = proper_type;
        this["order_price_range"] = order_price_range;
        this["wuling_price_segment"] = wuling_price_segment;
        this["province"] = province;
        this["area"] = area;
        this["province"] = province;
        this["kab"] = kab;
        this["kec"] = kec;
        this["segment"] = segment;
        this["vol"] = vol;
        this["created_at"] = created_at;
        this["updated_at"] = updated_at;
        this["deleted_at"] = deleted_at;
    }

    async getdata(this: any) {
        const { asm, sm, area, date, province, island, kab, merk, kec, segment, type, type_sgmw, tidy_type, price, price_range, proper_type, order_price_range, wuling_price_segment, vol, created_at, updated_at, deleted_at } = this;
        return [
            {
                header: "Date",
                id: "date",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: date,
                enableHiding: true,
            },
            {
                header: "ASM",
                id: "asm",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: asm,
                enableHiding: true,
            },
            {
                header: "SM",
                id: "sm",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: sm,
                enableHiding: true,
            },
            {
                header: "Island",
                id: "island",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: island,
                enableHiding: true,
            },
            {
                header: "Area",
                id: "area",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: area,
                enableHiding: true,
            },
            {
                header: "Province",
                id: "province",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: province,
                enableHiding: true,
            },
            {
                header: "Kab",
                id: "kab",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: kab,
                enableHiding: true,
            },
            {
                header: "Kec",
                id: "kec",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: kec,
                enableHiding: true,
            },
            {
                header: "Segment",
                id: "segment",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: segment,
                enableHiding: true,
            },
            {
                header: "Merk",
                id: "merk",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: merk,
                enableHiding: true,
            },
            {
                header: "Type",
                id: "type",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: type,
                enableHiding: true,
            },
            {
                header: "Type SGMW",
                id: "type_sgmw",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: type_sgmw,
                enableHiding: true,
            },
            {
                header: "Tidy Type",
                id: "tidy_type",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: tidy_type,
                enableHiding: true,
            },
            {
                header: "Proper Type",
                id: "proper_type",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: proper_type,
                enableHiding: true,
            },
            {
                header: "Price",
                id: "price",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: price,
                enableHiding: true,
            },
            {
                header: "Price Range",
                id: "price_range",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: price_range,
                enableHiding: true,
            },
            {
                header: "Vol",
                id: "vol",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: vol,
                enableHiding: true,
            },
            {
                header: "Order Price Range",
                id: "order_price_range",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: order_price_range,
                enableHiding: true,
            },
            {
                header: "Wuling Price Segment",
                id: "wuling_price_segment",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: wuling_price_segment,
                enableHiding: true,
            },
            {
                header: "created_at",
                id: "created_at",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: created_at,
                enableHiding: true,
            },
            {
                header: "updated_at",
                id: "updated_at",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: updated_at,
                enableHiding: true,
            },
            {
                header: "Deleted At",
                id: "deleted_at",
                muiTableBodyCellProps: ({ cell }) => {
                    return ({
                        align: 'center',
                    })
                },
                value: deleted_at,
                enableHiding: true,
            },
        ];
    }
}