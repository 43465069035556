import React, { useEffect } from "react";
// import { MultipleSelectCheckmarks } from "../SelectHelper";
import { TablePerformance, TableRS } from "../TableRS";
import { PerformanceAPI } from "../../../../api/PerformanceAPI";
import { LinearProgress } from "@mui/material";
import FilterMultiSelect from "../../../../../_metronic/helpers/components/form-actions/FilteringInput";
import GetDataFilter from "../../../../hooks/filter";
import TypesFilter from "../../../../hooks/filterType";
import { equalsCheck } from "../../../../../_metronic/helpers/helpers-data/AssetHelpers";

function Rs() {
  const [loading, setLoading] = React.useState(true);
  const [loading2, setLoading2] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [dtTarget, setDtTarget] = React.useState([]);
  const [dtPerfor, setDtPerfor] = React.useState([]);
  const [gtActual, setGtActual] = React.useState([]);
  const [gtTarget, setGtTarget] = React.useState([]);
  const [gtPerfor, setGtPerfor] = React.useState([]);
  const [asmVal, setasmVal] = React.useState([]);
  const [arrSelect, setArrSelect] = React.useState([]);
  const filterData = GetDataFilter({ asmVal });
  const typesData = TypesFilter();
  const [typeSelected, setTypeSelected] = React.useState([]);
  const [typeValue, setTypeValue] = React.useState([]);


  async function getData(val, model) {
    setLoading2(true);
    await new PerformanceAPI()
      .getRSactual(val, model)
      .then((res) => {
        let dtPerformance = [];
        let dtActual = [];
        let dtTarget = [];
        for (const [key, value] of Object.entries(res.data.result)) {
          // console.log("Cek Value",key);
          dtPerformance.push({
            name: key,
            outlet: Object.keys(value),
            value: Object.values(value).map((item) => {
              return item.arrPerfor;
            }),
            total: Object.values(value).map((item) => {
              return item.totalPerfor;
            }),
            // gtotal: res.data.totalPerfor
          });
          dtActual.push({
            name: key,
            outlet: Object.keys(value),
            value: Object.values(value).map((item) => {
              return item.arrActual;
            }),
            total: Object.values(value).map((item) => {
              return item.totalActual;
            }),
            // gtotal: res.data.totalActual
          });

          dtTarget.push({
            name: key,
            outlet: Object.keys(value),
            value: Object.values(value).map((item) => {
              return item.arrTarget;
            }),
            total: Object.values(value).map((item) => {
              return item.totalTarget;
            }),
          });
        }
        // let gtot = dtActual.map((val) => val.gtotal)
        setDtPerfor(dtPerformance);
        setData(dtActual);
        setDtTarget(dtTarget);
        setGtActual(res.data.totalActual);
        setGtTarget(res.data.totalTarget);
        setGtPerfor(res.data.totalPerfor);
        return res;
        // setData(res.data);
      })
      .then((res) => {
        if (res.status === "success") {
          setLoading(false);
          setLoading2(false);
        }
      });
  }
  useEffect(() => {
    getData(filterData.coverage, typeValue)
  }, [filterData.coverage, typeValue]);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <>
        <div className="row d-flex justify-content-center mb-15 g-5">
        <div className="col-xxl-3 mb-5">
          <FilterMultiSelect
            options={filterData.asmOpt}
            label="ASM"
            isSelectAll={true}
            value={arrSelect}
            onMenuClose={() => {
              return equalsCheck(
                arrSelect.map((val) => val.value),
                asmVal
              )
                ? null
                : setasmVal(arrSelect.map((val) => val.value));
            }}
            onChange={(selected) => {
              setArrSelect(selected);
            }}
          />
        </div>
        <div className="col-xxl-3 mb-5">
          <FilterMultiSelect
            options={typesData}
            label="Type"
            isSelectAll={true}
            value={typeSelected}
            onMenuClose={() => {
              return equalsCheck(
                typeSelected.map((val) => val.value),
                typeValue
              )
                ? null
                : setTypeValue(typeSelected.map((val) => val.value));
            }}
            onChange={(selected) => {
              setTypeSelected(selected);
            }}
          />
        </div>
      </div>
        {!loading2 ? (
          <>
            <div className="col d-flex justify-content-around">
              {/* <MultipleSelectCheckmarks title={"ASM"} />
        <MultipleSelectCheckmarks title={"Type"} /> */}
            </div>
            <div className="card mt-15">
              <TablePerformance title={"Dealer Performance"} dt={dtPerfor} />
            </div>
            <div className="card mt-15">
              <TableRS title={"RS Target"} dt={dtTarget} gt={gtTarget} />
            </div>
            <div className="card mt-15">
              <TableRS title={"RS Actual"} dt={data} gt={gtActual} />
            </div>
          </>
        ) : (
          <LinearProgress />
        )}
      </>
    );
  }
}

export default Rs;
