/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers";
import { Form, Formik } from "formik";
import FilterMultiSelect, { Option } from "../../../../../_metronic/helpers/components/form-actions/FilteringInput";
import DataTableMuiModel from "../../../../models/DataTable/dataTableModal";
import { MaterialReactTableDetails } from "../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable";
import { HighchartsSample } from "../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample";
import { StatisticsWidget } from "../../../../../_metronic/helpers/components/widgets/StatisticsWidget";
import { InternalInformationAPI } from "../../../../api/InternalInformationAPI";
import { InternalInformationDOModel, InternalInformationMSModel, InternalInformationMsizeModel, InternalInformationRSModel, InternalInformationSPKModel, InternalInformationVolModel } from "../../../../models/sales/InternalInformationModel";

export class ChartSalesGrowthIndex extends Component {
    constructor(props: any) {
        super(props);
        this["title"] = "Sales Growth";
        this["api"] = new InternalInformationAPI();
        this["month"] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        this["state"] = {
            isLoading: true,
            loading: false,
            dashboardValues: [],
            coverage: '',
            initialValues: {
                asm: [],
                sm: [],
                outlet: [],
                model: [],
                month: [],
            }, initialValuesChartColumnLine: {
                spk: {
                    series: [],
                    categories: props["month"],
                }, do: {
                    series: [],
                    categories: props["month"],
                }, retail_sales: {
                    series: [],
                    categories: props["month"],
                }, market_share: {
                    series: [],
                    categories: props["month"],
                }
            }
        };
        this['option_asm'] = [];
        this['option_sm'] = [];
        this['option_outlet'] = [];
        this['option_model'] = [];
        this['option_month'] = [];
        this["datatable_ytd_spk"] = [];
        this["columntable_ytd_spk"] = [];
        this["datatable_ytd_do"] = [];
        this["columntable_ytd_do"] = [];
        this["datatable_ytd_rs"] = [];
        this["columntable_ytd_rs"] = [];
        this["datatable_ytd_msize"] = [];
        this["columntable_ytd_msize"] = [];
        this["datatable_ytd_vol"] = [];
        this["columntable_ytd_vol"] = [];
        this["datatable_ytd_ms"] = [];
        this["columntable_ytd_ms"] = [];
    }
    //* ----------------------------------------------------------------------------- */
    // BEGIN:: componentDidMount */
    async componentDidMount() {
        //? Load Filter Options ASM, SM, OUTLET
        await this['api'].getFilter(this['state']['initialValues']).then(async (res: any) => {
            this['option_asm'] = res['data']['dataAsm'].map((val: any) => {
                return {
                    value: val['id_team_asm'],
                    label: val['area'],
                };
            });
            this['option_sm'] = res['data']['dataSm'].map((val: any) => {
                return {
                    value: val['id_team_sm'],
                    label: val['region'],
                };
            });
            this['option_outlet'] = res['data']['dataOutlet'].map((val: any) => {
                return {
                    value: val['id_outlet'],
                    label: val['text'],
                };
            });
            this.setState({
                isLoading: false,
                coverage: res['data']['coverage'],
            });
        });
        //? Load Filter Options Month
        await this['api'].getMonth().then(async (res: any) => {
            this['option_month'] = res['data'].map((val: any) => {
                return {
                    value: val['id'],
                    label: val['text'],
                    year: val['year']
                };
            });
        });

        //? Load Filter Options Model
        await this['api'].getModel().then(async (res: any) => {
            this['option_model'] = res['data'].map((val: any) => {
                return {
                    value: val['id'],
                    label: val['text'],
                };
            });
        });
        //! Load Chart 1
        await this.LoadChart1(this['state']['initialValues']);
        //! Load Chart 2
        await this.LoadChart2(this['state']['initialValues']);
        //! Load Chart 3
        await this.LoadChart3(this['state']['initialValues']);
    } //END:: componentDidMount */
    //** End: Component */
    //* ----------------------------------------------------------------------------- */
    async loadOptionsSm(array: object) {
        this.setState({ isLoading: true });
        await this['api'].getFilter(array).then(async (res: any) => {
            this['option_sm'] = res['data']['dataSm'].map((val: any) => {
                return {
                    value: val['id_team_sm'],
                    label: val['region'],
                };
            });
            this['option_outlet'] = res['data']['dataOutlet'].map((val: any) => {
                return {
                    value: val['id_outlet'],
                    label: val['text'],
                };
            });
            this.setState({
                isLoading: false,
                coverage: res['data']['coverage'],
            });
        });
    }
    async loadOptionsOutlet(array: object) {
        this.setState({ isLoading: true });
        await this['api'].getFilter(array).then(async (res: any) => {
            this['option_outlet'] = res['data']['dataOutlet'].map((val: any) => {
                return {
                    value: val['id_outlet'],
                    label: val['text'],
                };
            });
            this.setState({
                isLoading: false,
                coverage: res['data']['coverage'],
            });
        });
    }
    //! Function Load Chart 1
    async LoadChart1(initial: []) {
        const { data: dataResult } = await this["api"].getDataDashboardBox({
            coverage: this["state"]['coverage'],
            model: initial['model'],
            month: initial['month']
        });
        dataResult.map((item: any) => {
            this["setState"]({
                ...this["state"],
                dashboardValues: [{
                    title: "SPK",
                    yearNow: item['spk']['new_year'],
                    subtitle: item['spk']['text'],
                    yearOld: item['spk']['old_year'],
                }, {
                    title: "DO",
                    yearNow: item['do']['new_year'],
                    subtitle: item['do']['text'],
                    yearOld: item['do']['old_year'],
                }, {
                    title: "RS",
                    yearNow: item['rs']['new_year'],
                    subtitle: item['rs']['text'],
                    yearOld: item['rs']['old_year'],
                }, {
                    title: "Market Size Kumala",
                    yearNow: item['m_size']['new_year'],
                    subtitle: item['m_size']['text'],
                    yearOld: item['m_size']['old_year'],
                }, {
                    title: "Volume Kumala",
                    yearNow: item['m_vol']['new_year'],
                    subtitle: item['m_vol']['text'],
                    yearOld: item['m_vol']['old_year'],
                }, {
                    title: "Market Share Kumala",
                    yearNow: item['m_s']['new_year'],
                    subtitle: item['m_s']['text'],
                    yearOld: item['m_s']['old_year'],
                }]
            });
        });
    }
    async LoadChart2(initial: []) {
        const { data: dataResultSPK } = await this["api"].getDataChartColumnLineSPK({
            coverage: this["state"]['coverage'],
            model: initial['model'],
            month: initial['month']
        });
        const {
            dataYearOld: dataSPKYearOld,
            dataYearNow: dataSPKYearNow,
            growth: growthSPK,
        } = dataResultSPK;
        const { data: dataResultDO } = await this["api"].getDataChartColumnLineDO({
            coverage: this["state"]['coverage'],
            model: initial['model'],
            month: initial['month']
        });
        const {
            dataYearOld: dataDOYearOld,
            dataYearNow: dataDOYearNow,
            growth: growthDO,
        } = dataResultDO;
        const { data: dataResultRetailSales } = await this["api"].getDataChartColumnLineRS({
            coverage: this["state"]['coverage'],
            model: initial['model'],
            month: initial['month']
        });
        const {
            dataYearOld: dataRSYearOld,
            dataYearNow: dataRSYearNow,
            growth: growthRS,
        } = dataResultRetailSales;
        const { data: dataResultMarketShare } = await this["api"].getDataChartColumnLineMS({
            coverage: this["state"]['coverage'],
            model: initial['model'],
            month: initial['month']
        });
        const {
            dataYearOld: dataMSYearOld,
            dataYearNow: dataMSYearNow,
            growth: growthMS,
        } = dataResultMarketShare;
        this["setState"]({
            // ...this["state"],
            initialValuesChartColumnLine: {
                spk: {
                    series: [{
                        name: `${this["option_month"][0]?.["year"] - 1} SPK`,
                        type: "column",
                        yAxis: 1,
                        data: dataSPKYearOld,
                    },
                    {
                        name: `${this["option_month"][0]?.["year"]} SPK`,
                        type: "column",
                        yAxis: 1,
                        data: dataSPKYearNow,
                    },
                    {
                        name: "Growth",
                        type: "line",
                        data: growthSPK,
                        tooltip: {
                            valueSuffix: " %",
                        },
                    }],
                    categories: this["month"],
                }, do: {
                    series: [{
                        name: `${this["option_month"][0]?.["year"] - 1} DO`,
                        type: "column",
                        yAxis: 1,
                        data: dataDOYearOld,
                    },
                    {
                        name: `${this["option_month"][0]?.["year"]} DO`,
                        type: "column",
                        yAxis: 1,
                        data: dataDOYearNow,
                    },
                    {
                        name: "Growth",
                        type: "line",
                        data: growthDO,
                        tooltip: {
                            valueSuffix: " %",
                        },
                    }],
                    categories: this["month"],
                }, retail_sales: {
                    series: [
                        {
                            name: `${this["option_month"][0]?.["year"] - 1} RS`,
                            type: "column",
                            yAxis: 1,
                            data: dataRSYearOld,
                        },
                        {
                            name: `${this["option_month"][0]?.["year"]} RS`,
                            type: "column",
                            yAxis: 1,
                            data: dataRSYearNow,
                        },
                        {
                            name: "Growth",
                            type: "line",
                            data: growthRS,
                            tooltip: {
                                valueSuffix: " %",
                            },
                        },
                    ],
                    categories: this["month"],
                }, market_share: {
                    series: [
                        {
                            name: `${this["option_month"][0]?.["year"] - 1} M/S`,
                            type: "column",
                            yAxis: 1,
                            data: dataMSYearOld,
                            tooltip: {
                                valueSuffix: " %",
                            },
                        },
                        {
                            name: `${this["option_month"][0]?.["year"]} M/S`,
                            type: "column",
                            yAxis: 1,
                            data: dataMSYearNow,
                            tooltip: {
                                valueSuffix: " %",
                            },
                        },
                        {
                            name: "Growth",
                            type: "line",
                            data: growthMS,
                            tooltip: {
                                valueSuffix: " %",
                            },
                        },
                    ],
                    categories: this["month"],
                }
            }
        });
    }
    //! Function Load Chart 2
    async LoadChart3(initial: any) {
        this.setState({ loading: true });
        //! Table YTD SPK
        const { data: dataResultSpk } = await this["api"].getDataChartTableYTDSPK({
            coverage: this["state"]['coverage'],
            model: initial['model'],
            month: initial['month']
        });
        const { TableByBrand: TableSpkByModel } = dataResultSpk[0];
        this["datatable_ytd_spk_model"] = new DataTableMuiModel({ readOnly: true });
        const max_spk_old_year = Math.max(...TableSpkByModel.map((obj: any) => obj['spk_old_year']));
        const max_spk_year_now = Math.max(...TableSpkByModel.map((obj: any) => obj['spk_new_year']));
        const max_spk_share_old_year = Math.max(...TableSpkByModel.map((obj: any) => obj['share_old_year']));
        const max_spk_share_year_now = Math.max(...TableSpkByModel.map((obj: any) => obj['share_year_now']));
        TableSpkByModel.map((item: any, i: number) => {
            let dataModel = new InternalInformationSPKModel({
                ...item,
                max_spk_old_year: max_spk_old_year,
                max_spk_year_now: max_spk_year_now,
                max_share_old_year: max_spk_share_old_year,
                max_share_year_now: max_spk_share_year_now,
            });
            this["datatable_ytd_spk_model"].add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null,
            });
            return item;
        });
        this["datatable_ytd_spk"] = await this["datatable_ytd_spk_model"].getDatas();
        this["columntable_ytd_spk"] = await this["datatable_ytd_spk_model"].getColumn();
        //! Table YTD DO
        const { data: dataResultDo } = await this["api"].getDataChartTableYTDDO({
            coverage: this["state"]['coverage'],
            model: initial['model'],
            month: initial['month']
        });
        const { TableByBrand: TableDoByModel } = dataResultDo[0];
        this["datatable_ytd_do_model"] = new DataTableMuiModel({ readOnly: true });
        const max_do_old_year = Math.max(...TableDoByModel.map((obj: any) => obj['do_old_year']));
        const max_do_year_now = Math.max(...TableDoByModel.map((obj: any) => obj['do_new_year']));
        const max_do_share_old_year = Math.max(...TableDoByModel.map((obj: any) => obj['share_old_year']));
        const max_do_share_year_now = Math.max(...TableDoByModel.map((obj: any) => obj['share_year_now']));
        TableDoByModel.map((item: any, i: number) => {
            let dataModel = new InternalInformationDOModel({
                ...item,
                max_do_old_year: max_do_old_year,
                max_do_year_now: max_do_year_now,
                max_share_old_year: max_do_share_old_year,
                max_share_year_now: max_do_share_year_now,
            });
            this["datatable_ytd_do_model"].add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null,
            });
            return item;
        });
        this["datatable_ytd_do"] = await this["datatable_ytd_do_model"].getDatas();
        this["columntable_ytd_do"] = await this["datatable_ytd_do_model"].getColumn();
        //! Table YTD Retail Sales
        const { data: dataResultRS } = await this["api"].getDataChartTableYTDRS({
            coverage: this["state"]['coverage'],
            model: initial['model'],
            month: initial['month']
        });
        const { TableByBrand: TableRSByModel } = dataResultRS[0];
        this["datatable_ytd_rs_model"] = new DataTableMuiModel({ readOnly: true });
        const max_rs_old_year = Math.max(...TableRSByModel.map((obj: any) => obj['rs_old_year']));
        const max_rs_year_now = Math.max(...TableRSByModel.map((obj: any) => obj['rs_new_year']));
        const max_rs_share_old_year = Math.max(...TableRSByModel.map((obj: any) => obj['share_old_year']));
        const max_rs_share_year_now = Math.max(...TableRSByModel.map((obj: any) => obj['share_year_now']));
        TableRSByModel.map((item: any, i: number) => {
            let dataModel = new InternalInformationRSModel({
                ...item,
                max_rs_old_year: max_rs_old_year,
                max_rs_year_now: max_rs_year_now,
                max_share_old_year: max_rs_share_old_year,
                max_share_year_now: max_rs_share_year_now,
            });
            this["datatable_ytd_rs_model"].add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null,
            });
            return item;
        });
        this["datatable_ytd_rs"] = await this["datatable_ytd_rs_model"].getDatas();
        this["columntable_ytd_rs"] = await this["datatable_ytd_rs_model"].getColumn();
        //! Table YTD M.Size
        const { data: dataResultMsize } = await this["api"].getDataChartTableYTDMS({
            coverage: this["state"]['coverage'],
            model: initial['model'],
            month: initial['month'],
            jenis: "m_size"
        });
        const { TableByBrand: TableMSizeByModel } = dataResultMsize[0];
        this["datatable_ytd_msize_model"] = new DataTableMuiModel({ readOnly: true });
        const max_ms_old_year = Math.max(...TableMSizeByModel.map((obj: any) => obj['ms_old_year']));
        const max_ms_year_now = Math.max(...TableMSizeByModel.map((obj: any) => obj['ms_new_year']));
        const max_ms_share_old_year = Math.max(...TableMSizeByModel.map((obj: any) => obj['share_old_year']));
        const max_ms_share_year_now = Math.max(...TableMSizeByModel.map((obj: any) => obj['share_year_now']));
        TableMSizeByModel.map((item: any, i: number) => {
            let dataModel = new InternalInformationMsizeModel({
                ...item,
                max_ms_old_year: max_ms_old_year,
                max_ms_year_now: max_ms_year_now,
                max_share_old_year: max_ms_share_old_year,
                max_share_year_now: max_ms_share_year_now,
            });
            this["datatable_ytd_msize_model"].add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null,
            });
            return item;
        });
        this["datatable_ytd_msize"] = await this["datatable_ytd_msize_model"].getDatas();
        this["columntable_ytd_msize"] = await this["datatable_ytd_msize_model"].getColumn();
        //! Table YTD Vol
        const { data: dataResultVol } = await this["api"].getDataChartTableYTDMS({
            coverage: this["state"]['coverage'],
            model: initial['model'],
            month: initial['month'],
            jenis: "m_vol"
        });
        const { TableByBrand: TableVolByModel } = dataResultVol[0];
        this["datatable_ytd_vol_model"] = new DataTableMuiModel({ readOnly: true });
        const max_vol_old_year = Math.max(...TableVolByModel.map((obj: any) => obj['ms_old_year']));
        const max_vol_year_now = Math.max(...TableVolByModel.map((obj: any) => obj['ms_new_year']));
        const max_vol_share_old_year = Math.max(...TableVolByModel.map((obj: any) => obj['share_old_year']));
        const max_vol_share_year_now = Math.max(...TableVolByModel.map((obj: any) => obj['share_year_now']));
        TableVolByModel.map((item: any, i: number) => {
            let dataModel = new InternalInformationVolModel({
                ...item,
                max_vol_old_year: max_vol_old_year,
                max_vol_year_now: max_vol_year_now,
                max_share_old_year: max_vol_share_old_year,
                max_share_year_now: max_vol_share_year_now,
            });
            this["datatable_ytd_vol_model"].add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null,
            });
            return item;
        });
        this["datatable_ytd_vol"] = await this["datatable_ytd_vol_model"].getDatas();
        this["columntable_ytd_vol"] = await this["datatable_ytd_vol_model"].getColumn();
        //! Table YTD MS
        const { data: dataResultMS } = await this["api"].getDataChartTableYTDMS({
            coverage: this["state"]['coverage'],
            model: initial['model'],
            month: initial['month'],
            jenis: "m_s"
        });
        const { TableByBrand: TableMSByModel } = dataResultMS[0];
        this["datatable_ytd_ms_model"] = new DataTableMuiModel({ readOnly: true });
        const max_msg_old_year = Math.max(...TableMSByModel.map((obj: any) => obj['ms_old_year']));
        const max_msg_year_now = Math.max(...TableMSByModel.map((obj: any) => obj['ms_new_year']));
        TableMSByModel.map((item: any, i: number) => {
            let dataModel = new InternalInformationMSModel({
                ...item,
                max_msg_old_year: max_msg_old_year,
                max_msg_year_now: max_msg_year_now,
            });
            this["datatable_ytd_ms_model"].add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null,
            });
            return item;
        });
        this["datatable_ytd_ms"] = await this["datatable_ytd_ms_model"].getDatas();
        this["columntable_ytd_ms"] = await this["datatable_ytd_ms_model"].getColumn();
        this.setState({
            ...this["state"],
            loading: false
        });
    }
    //** End: Load Data List */
    render(this: any) {
        const { initialValues, initialValuesChartColumnLine, dashboardValues } = this["state"];
        const { spk, do: do_, retail_sales, market_share } = initialValuesChartColumnLine;
        return (
            <React.Fragment>
                <PageTitle breadcrumbs={[{ title: "Sales Growth", path: "/sales/sales-growth", isSeparator: false, isActive: false }]}>
                    {this["title"]}
                </PageTitle>
                {/* BEGIN:: Card Filter Input */}
                <div className="row g-5">
                    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={() => { }}>
                        {({ setFieldValue, values }) => (
                            <Form>
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-xxl-3 mb-5">
                                        <FilterMultiSelect
                                            label={"ASM"}
                                            name="asm"
                                            options={this['option_asm']}
                                            onChange={async (selected: Option[]) => {
                                                setFieldValue("asm", selected);
                                                const asm = selected.map(
                                                    (x: object) => x["value"]
                                                );
                                                let newArrays = {
                                                    asm: asm,
                                                    sm: [],
                                                    outlet: [],
                                                }
                                                this.loadOptionsSm(newArrays);
                                                const model = values['model'].map(
                                                    (x: object) => x["value"]
                                                );
                                                const month = values['month'].map(
                                                    (x: object) => x["value"]
                                                );
                                                let filterArray = {
                                                    model: model,
                                                    month: month,
                                                }
                                                setTimeout(() => {
                                                    this.LoadChart1(filterArray);
                                                    this.LoadChart2(filterArray);
                                                    this.LoadChart3(filterArray);
                                                }, 500);
                                            }}
                                            value={values["asm"]}
                                        />
                                    </div>
                                    <div className="col-xxl-2 mb-5">
                                        <FilterMultiSelect
                                            label={"SM"}
                                            name="sm"
                                            options={this['option_sm']}
                                            isLoading={this["state"]["isLoading"]}
                                            onChange={(selected: Option[]) => {
                                                setFieldValue("sm", selected);
                                                const asm = values["asm"].map(
                                                    (x: object) => x["value"]
                                                );
                                                const sm = selected.map(
                                                    (x: object) => x["value"]
                                                );
                                                let newArrays = {
                                                    asm: asm,
                                                    sm: sm,
                                                    outlet: [],
                                                }
                                                this.loadOptionsOutlet(newArrays);
                                                const model = values['model'].map(
                                                    (x: object) => x["value"]
                                                );
                                                const month = values['month'].map(
                                                    (x: object) => x["value"]
                                                );
                                                let filterArray = {
                                                    model: model,
                                                    month: month,
                                                }
                                                setTimeout(() => {
                                                    this.LoadChart1(filterArray);
                                                    this.LoadChart2(filterArray);
                                                    this.LoadChart3(filterArray);
                                                }, 500);
                                            }}
                                            value={values["sm"]}
                                        />
                                    </div>
                                    <div className="col-xxl-2 mb-5">
                                        <FilterMultiSelect
                                            label={"Outlet"}
                                            name="outlet"
                                            options={this['option_outlet']}
                                            isLoading={this["state"]["isLoading"]}
                                            onChange={(selected: Option[]) => {
                                                setFieldValue("outlet", selected);
                                                const asm = values["asm"].map(
                                                    (x: object) => x["value"]
                                                );
                                                const sm = values["sm"].map(
                                                    (x: object) => x["value"]
                                                );
                                                const outlet = selected.map(
                                                    (x: object) => x["value"]
                                                );
                                                let newArrays = {
                                                    asm: asm,
                                                    sm: sm,
                                                    outlet: outlet,
                                                }
                                                this.loadOptionsOutlet(newArrays);
                                                const model = values['model'].map(
                                                    (x: object) => x["value"]
                                                );
                                                const month = values['month'].map(
                                                    (x: object) => x["value"]
                                                );
                                                let filterArray = {
                                                    model: model,
                                                    month: month,
                                                }
                                                setTimeout(() => {
                                                    this.LoadChart1(filterArray);
                                                    this.LoadChart2(filterArray);
                                                    this.LoadChart3(filterArray);
                                                }, 500);
                                            }}
                                            value={values["outlet"]}
                                        />
                                    </div>
                                    <div className="col-xxl-2 mb-5">
                                        <FilterMultiSelect
                                            label={"Model"}
                                            name="model"
                                            options={this['option_model']}
                                            onChange={(selected: Option[]) => {
                                                setFieldValue("model", selected);
                                                const asm = values["asm"].map(
                                                    (x: object) => x["value"]
                                                );
                                                const sm = values["sm"].map(
                                                    (x: object) => x["value"]
                                                );
                                                const outlet = values['outlet'].map(
                                                    (x: object) => x["value"]
                                                );
                                                let newArrays = {
                                                    asm: asm,
                                                    sm: sm,
                                                    outlet: outlet,
                                                }
                                                this.loadOptionsOutlet(newArrays);
                                                const model = selected.map(
                                                    (x: object) => x["value"]
                                                );
                                                const month = values['month'].map(
                                                    (x: object) => x["value"]
                                                );
                                                let filterArray = {
                                                    model: model,
                                                    month: month,
                                                }
                                                setTimeout(() => {
                                                    this.LoadChart1(filterArray);
                                                    this.LoadChart2(filterArray);
                                                    this.LoadChart3(filterArray);
                                                }, 500);
                                            }}
                                            value={values["model"]}
                                        />
                                    </div>
                                    <div className="col-xxl-3 mb-5">
                                        <FilterMultiSelect
                                            label={"Month"}
                                            name="month"
                                            options={this['option_month']}
                                            onChange={(selected: Option[]) => {
                                                setFieldValue("month", selected);
                                                const asm = values["asm"].map(
                                                    (x: object) => x["value"]
                                                );
                                                const sm = values["sm"].map(
                                                    (x: object) => x["value"]
                                                );
                                                const outlet = values['outlet'].map(
                                                    (x: object) => x["value"]
                                                );
                                                let newArrays = {
                                                    asm: asm,
                                                    sm: sm,
                                                    outlet: outlet,
                                                }
                                                this.loadOptionsOutlet(newArrays);
                                                const model = values['model'].map(
                                                    (x: object) => x["value"]
                                                );
                                                const month = selected.map(
                                                    (x: object) => x["value"]
                                                );
                                                let filterArray = {
                                                    model: model,
                                                    month: month,
                                                }
                                                setTimeout(() => {
                                                    this.LoadChart1(filterArray);
                                                    this.LoadChart2(filterArray);
                                                    this.LoadChart3(filterArray);
                                                }, 500);
                                            }}
                                            value={values["month"]}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                {/* END:: Card Filter Input */}
                {/* ----------------------------------------------------------------------------- */}
                {/* BEGIN:: Dashboard SPK, DO, RS, Market Size Kumala Volume Kumala & Market Share Kumala */}
                <div className='row g-5'>
                    {dashboardValues.map((val: any, i: number) => {
                        return (
                            <div className='col-xxl-2' key={i}>
                                <StatisticsWidget
                                    className='h-xxl-200px mb-5'
                                    textAlign="text-center"
                                    svgIcon='chart-simple'
                                    color='white'
                                    iconColor='primary'
                                    title={val['title']}
                                    subTitle={val['yearNow']}
                                    description={val['subtitle']}
                                    subDescription={val['yearOld']}
                                />
                            </div>
                        )
                    })}
                </div>
                {/* END:: Dashboard SPK, DO, RS, Market Size Kumala Volume Kumala & Market Share Kumala */}
                {/* ----------------------------------------------------------------------------- */}
                {/* BEGIN:: Card Chart Column Line SPK & DO */}
                <div className="row g-5 mh-100">
                    {/* SPK */}
                    <div className="col-xxl-6 bd-highlight">
                        <KTCard className='mb-5 h-xxl-500px'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-2">SPK</span>
                                </h3>
                            </div>
                            <KTCardBody className="pl-5 pt-0 pb-0">
                                <HighchartsSample
                                    id="spk_chart1"
                                    type="column_line"
                                    height="430px"
                                    series={spk["series"]}
                                    categories={spk["categories"]}
                                    data={spk["data"]}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                    {/* DO */}
                    <div className="col-xxl-6 bd-highlight">
                        <KTCard className='mb-5 h-xxl-500px'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-2">DO</span>
                                </h3>
                            </div>
                            <KTCardBody className="pl-5 pt-0 pb-0">
                                <HighchartsSample
                                    id="do_chart1"
                                    type="column_line"
                                    height="430px"
                                    series={do_["series"]}
                                    categories={do_["categories"]}
                                    data={do_["data"]}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* END:: Card Chart Column Line SPK & DO */}
                {/* ----------------------------------------------------------------------------- */}
                {/* BEGIN:: Card Chart Column Line Retail Sales & Market Share */}
                <div className="row g-5 mh-100">
                    {/* Retail Sales */}
                    <div className="col-xxl-6 bd-highlight">
                        <KTCard className='mb-5 h-xxl-500px'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-2">Retail Sales</span>
                                </h3>
                            </div>
                            <KTCardBody className="pl-5 pt-0 pb-0">
                                <HighchartsSample
                                    id="retail_sales_chart1"
                                    type="column_line"
                                    height="430px"
                                    series={retail_sales["series"]}
                                    categories={retail_sales["categories"]}
                                    data={retail_sales["data"]}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                    {/* Market Share */}
                    <div className="col-xxl-6 bd-highlight">
                        <KTCard className='mb-5 h-xxl-500px'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-2">Market Share</span>
                                </h3>
                            </div>
                            <KTCardBody className="pl-5 pt-0 pb-0">
                                <HighchartsSample
                                    id="market_share_chart1"
                                    type="column_line"
                                    height="430px"
                                    series={market_share["series"]}
                                    categories={market_share["categories"]}
                                    data={market_share["data"]}
                                    formatColumnPercent={"percent1f"}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* END:: Card Chart Column Line Retail Sales & Market Share */}
                {/* ----------------------------------------------------------------------------- */}
                {/* BEGIN:: Card Table YTD SPK Growth by Model & YTD DO Growth by Model */}
                <div className="row g-5 mh-100">
                    {/* YTD SPK Growth by Model */}
                    <div className="col-xxl-6 bd-highlight">
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">YTD SPK Growth by Model</span>
                                </h3>
                            </div>
                            <KTCardBody>
                                <MaterialReactTableDetails
                                    data={this["datatable_ytd_spk"]}
                                    columns={this["columntable_ytd_spk"]}
                                    isLoading={this["state"]["loading"]}
                                    hover={false}
                                    density={'comfortable'}
                                    maxHeight={"400px"}
                                    optionsHead={{
                                        fontSize: 10,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                    {/* YTD DO Growth by Model */}
                    <div className="col-xxl-6 bd-highlight">
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">YTD DO Growth by Model</span>
                                </h3>
                            </div>
                            <KTCardBody>
                                <MaterialReactTableDetails
                                    data={this["datatable_ytd_do"]}
                                    columns={this["columntable_ytd_do"]}
                                    isLoading={this["state"]["loading"]}
                                    hover={false}
                                    density={'comfortable'}
                                    maxHeight={"400px"}
                                    optionsHead={{
                                        fontSize: 10,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* END:: Card Table YTD SPK Growth by Model & YTD DO Growth by Model */}
                {/* ----------------------------------------------------------------------------- */}
                {/* BEGIN:: Card Table YTD RS Growth by Model & YTD M.Size Growth by Segment */}
                <div className="row g-5 mh-100">
                    {/* YTD RS Growth by Model */}
                    <div className="col-xxl-6 h-md-100 mb-5 bd-highlight">
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">YTD RS Growth by Model</span>
                                </h3>
                            </div>
                            <KTCardBody>
                                <MaterialReactTableDetails
                                    data={this["datatable_ytd_rs"]}
                                    columns={this["columntable_ytd_rs"]}
                                    isLoading={this["state"]["loading"]}
                                    hover={false}
                                    density={'comfortable'}
                                    maxHeight={"400px"}
                                    optionsHead={{
                                        fontSize: 10,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                    {/* YTD M.Size Growth by Segment */}
                    <div className="col-xxl-6 h-md-100 mb-5 bd-highlight">
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">YTD M.Size Growth by Segment</span>
                                </h3>
                            </div>
                            <KTCardBody>
                                <MaterialReactTableDetails
                                    data={this["datatable_ytd_msize"]}
                                    columns={this["columntable_ytd_msize"]}
                                    isLoading={this["state"]["loading"]}
                                    hover={false}
                                    density={'comfortable'}
                                    maxHeight={"400px"}
                                    optionsHead={{
                                        fontSize: 10,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* END:: Card Table YTD RS Growth by Model & YTD M.Size Growth by Segment */}
                {/* ----------------------------------------------------------------------------- */}
                {/* BEGIN:: Card Table YTD Vol Growth by Segment & YTD M/S Growth by Segment */}
                <div className="row g-5 mh-100">
                    {/* Table YTD Vol Growth by Segment */}
                    <div className="col-xxl-6 bd-highlight">
                        <KTCard className='mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">YTD Vol Growth by Segment</span>
                                </h3>
                            </div>
                            <KTCardBody>
                                <MaterialReactTableDetails
                                    data={this["datatable_ytd_vol"]}
                                    columns={this["columntable_ytd_vol"]}
                                    isLoading={this["state"]["loading"]}
                                    hover={false}
                                    density={'comfortable'}
                                    maxHeight={"400px"}
                                    optionsHead={{
                                        fontSize: 10,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                    {/* YTD M/S Growth by Segment */}
                    <div className="col-xxl-6 bd-highlight">
                        <KTCard className='mb-5'>
                        <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">YTD M/S Growth by Segment</span>
                                </h3>
                            </div>
                            <KTCardBody>
                                <MaterialReactTableDetails
                                    data={this["datatable_ytd_ms"]}
                                    columns={this["columntable_ytd_ms"]}
                                    isLoading={this["state"]["loading"]}
                                    hover={false}
                                    density={'comfortable'}
                                    maxHeight={"400px"}
                                    optionsHead={{
                                        fontSize: 10,
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* END:: Card Table YTD Vol Growth by Segment & YTD M/S Growth by Segment */}
            </React.Fragment>
        );
    }
}
export default ChartSalesGrowthIndex;
