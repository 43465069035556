import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { TableFooter } from '@mui/material';
require("highcharts/modules/exporting")(Highcharts);


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    // '&:last-child td, &:last-child th': {
    //   border: 0,
    // },
}));

type dtTrend = {
    title: string, 
    dtTO: { 
        mp: number[], 
        to : number[], 
        newHire?: number[],
        resign: number[], 
    },
    chartCategory?: string[]
}
function ChartTrendOver({ title, dtTO }: dtTrend) {
    const chartComponent = React.useRef(null);
    const options = {
        chart: {
            backgroundColor: "transparent",
            // type: "column",
            height: 500,
        },
        title: {
            text: "",
        },
        xAxis: [
            {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
                crosshair: false,
            },
        ],
        yAxis: [
            {
                // Primary yAxis
                show: false,
                min: 0,
                max: dtTO.mp ? Math.max(...dtTO.mp) * 1.2 : 100,
                labels: {
                    format: "{value}",
                    style: {
                    },
                },
                visible: false,
                title: false,
                opposite: true,
            },
            {
                // Secondary yAxis
                visible: false,
                min: -100,
                gridLineWidth: 0,
                title: false,
                labels: {
                    format: "{value}",
                    style: {
                    },
                },
            },
        ],
        tooltip: {
            shared: true,
            valueDecimals: 2,
            shadow: false,
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            backgroundColor:
                "rgba(255,255,255,0.25)",
        },
        plotOptions: {
            // dataLabels: {
            //   textShadow: false,
            //   textOutline: "0px",
            //   borderWidth: 0,
            // },
            series: {
                // pointStart: 0,
                pointWidth: 50,
                dataLabels: {
                    enabled: true,
                    allowOverlap: true,
                    borderWidth: 0,
                    // textShadow: false,
                    align: "center",
                    //   y: 0, // 10 pixels down from the top
                    style: {
                        textOutline: 'none',
                        fontSize: "10px",
                        // textOutline: "0px",
                    },
                },
            },
            line: {
                dataLabels: {
                    textShadow: false,
                    textOutline: "0px",
                    borderWidth: 0,
                    format: "{point.y:.1f}",
                    // format: "{y} %",
                },
            },
            column: {
                // stacking: "normal",
                //   pointPadding: 1,
                // grouping: false,
                // groupPadding: 0.05,
                //   padding: 1,
                shadow: false,
                pointWidth: 75,
                min: 0,
                dataLabels: {
                    borderWidth: 0,
                    verticalAlign: "top",
                    // y: -20,
                },
            },
        },
        series: [
            {
                name: "MP Total",
                type: "column",
                color: "#287F9D",
                //   yAxis: 1,
                data: dtTO.mp,
                // stack: "curr",
                tooltip: {
                    // valueSuffix: " mm",
                },
            },
            {
                name: "New Hire",
                type: "line",
                data: dtTO.newHire,
                color: "#4FA553",
                yAxis: 1,
                dataLabels: {
                    textShadow: false,
                    textOutline: "0px",
                    borderWidth: 0,
                    y:30
                },
                // center: [375, 365],
                tooltip: {
                    // pointFormat: `{series.name}: {point.y} <b>({point.percentage:.1f}%)</b>`,

                    valueSuffix: "",
                    // positioner: function () {
                    //   return { x: 80, y: 50 };
                    // },
                    shadow: false,
                    borderWidth: 0,
                    backgroundColor: "rgba(255,255,255,0.8)",
                },
            },
            {
                name: "Resign",
                type: "line",
                data: dtTO.resign,
                yAxis: 1,
                dataLabels: {
                    textShadow: false,
                    textOutline: "0px",
                    borderWidth: 0,
                },
                // center: [375, 365],
                tooltip: {
                    // pointFormat: `{series.name}: {point.y} <b>({point.percentage:.1f}%)</b>`,

                    valueSuffix: "",
                    // positioner: function () {
                    //   return { x: 80, y: 50 };
                    // },
                    shadow: false,
                    borderWidth: 0,
                    backgroundColor: "rgba(255,255,255,0.8)",
                },
            },
        ],
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            floating: false,
                            layout: "horizontal",
                            align: "center",
                            verticalAlign: "bottom",
                            x: 0,
                            y: 0,
                        },
                        yAxis: [
                            {
                                labels: {
                                    align: "right",
                                    x: 0,
                                    y: -6,
                                },
                                showLastLabel: false,
                            },
                            {
                                labels: {
                                    align: "left",
                                    x: 0,
                                    y: -6,
                                },
                                showLastLabel: false,
                            },
                            {
                                visible: false,
                            },
                        ],
                    },
                },
            ],
        },
    };

    const lineOptions = {
        chart: {
            type: "line",
            height: 250,
            backgroundColor: "transparent",
        },
        credits: {
            enabled: false,
        },
        title: {
            text: title,
            style: {
                fontSize: "30px",
            },
            align: "center",
            verticalAlign: "top",
        },

        yAxis: {
            min: -5,
            title: false,
            visible: false,
        },

        xAxis: {
            categories: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
            crosshair: false,
            visible: false,
            //   accessibility: {
            //     rangeDescription: "Range: 2010 to 2020",
            //   },
        },

        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "top",
        },
        tooltip: {
            shared: true,
            valueDecimals: 2,
        },
        plotOptions: {
            gridLineWidth: 0,
            series: {
                label: {
                    connectorAllowed: true,
                },
                dataLabels: {
                    enabled: true,
                    textShadow: false,
                    textOutline: "0px",
                    allowOverlap: true,
                    borderWidth: 0,
                    // color: "#FFFFFF",
                    align: "center",
                    //   y: 0, // 10 pixels down from the top
                    style: {
                        fontSize: "10px",
                    },
                },
                // pointStart: 2010,
            },
            line: {
                dataLabels: {
                    format: "{point.y:.1f}%",
                    // format: "{y} %",
                },
            },
        },

        series: [
            {
                name: "Turn Over",
                type: "line",
                data: dtTO.to,
                format: "{point.y:.1f}%",
                dataLabels: {
                    y: 20,
                    textShadow: false,
                    textOutline: "0px",
                    borderWidth: 0,
                },
            },
        ],

        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            layout: "vertical",
                            align: "center",
                            verticalAlign: "top",
                        },
                    },
                },
            ],
        },
    };
    React.useEffect(() => {
    }, []);
    return (
        <>
            <div className="card">
                <div style={{ marginBottom: "-4rem", overflow: "visible" }}>
                    <HighchartsReact
                        ref={chartComponent}
                        highcharts={Highcharts}
                        options={lineOptions}
                    // series={series}
                    />
                </div>
                <div>
                    <HighchartsReact
                        ref={chartComponent}
                        highcharts={Highcharts}
                        options={options}
                    // series={series}
                    />
                </div>
            </div>
        </>
    );
}

type Column = {
    id: 'outlet' | 'mp' | 'mpResign' | 'mpNewHire' | 'to';
    label: string;
    minWidth?: number;
    align?: 'right' | 'center';
    style?: { [key: string]: string };
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'outlet', label: 'Outlet', minWidth: 30 },
    { id: 'mp', label: 'MP Active', minWidth: 30 },
    {
        id: 'mpNewHire',
        label: 'MP New Hire',
        minWidth: 10,
        align: 'center',
        // format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'mpResign',
        label: 'MP Resign',
        minWidth: 15,
        align: 'center',
        style: { color: 'green' },
        // format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'to',
        label: 'Turn Over',
        minWidth: 10,
        align: 'center',
        format: (value: number) => `${value} %`,
    },
];
type dtTable = {
    dt : [{
        outlet: string, 
        mp: number, 
        mpNewHire: number, 
        mpResign: number, 
        to: number,
        totalActive: number,
        totalResign: number,
        totalNewHire: number
    }]
}

function OutletTurnOver({dt}: dtTable) {
    console.log(dt.length > 0 ? dt.map((v) => v) : "Load");
    
    return (
        <>
            <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
                {/* begin::Title */}
                <h3 className="align-items-center flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">Turn Over By Outlet</span>
                </h3>
            </div>
            <Paper sx={{ overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 700, minHeight: 700, borderRadius: 5 }}>
                    <Table sx={{ minWidth: 200 }} stickyHeader aria-label="sticky table" size='small'>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, fontSize: "16px", backgroundColor: '#287f9d', color: 'white' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dt.length > 0 ? dt.map((row) => {
                                return (
                                    <StyledTableRow key={row.outlet}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align} style={{...column.style, fontSize: "12px"}} >
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </StyledTableRow>
                                )
                            }) : <TableRow><TableCell>Loading</TableCell></TableRow>}
                        </TableBody>
                        {dt.length > 0 ?
                        <TableFooter
                        style={{
                            position: "sticky",
                            bottom: "0px",
                            backgroundColor: "white",
                        }}>
                            <TableRow>
                                <TableCell align="left"
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        color: "black",
                                    }}
                                    >Grand Total</TableCell>
                                <TableCell align="left"
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        color: "black",
                                    }}
                                    >{dt[0].totalActive}</TableCell>
                                <TableCell align="center"
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        color: "black",
                                    }}
                                    >{dt[0].totalNewHire}</TableCell>
                                <TableCell align="right"
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        color: "black",
                                        textAlign: "center",
                                    }}
                                    >{dt[0].totalResign}</TableCell>
                                <TableCell align="right"
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        color: "black",
                                        textAlign: "center",
                                    }}
                                    >{Math.round((dt[0].totalResign / (dt[0].totalActive + dt[0].totalResign)) * 100)} %</TableCell>
                            </TableRow>
                        </TableFooter>
                        : null}
                    </Table>
                </TableContainer>
            </Paper>
        </>
        );
}

// type chart = {
//     title: string;
//     datas?: string[];
// }
function ChartTurnOver({ title, dtTO, chartCategory }: dtTrend) {
    const chartComponent = React.useRef(null);

    const options = {
        chart: {
            backgroundColor: "transparent",
            zoomType: "xy",
            type: "bar",
            height: 300
        },
        credits: {
            enabled: false,
        },
        title: {
            text: "",
            align: "left",
        },
        xAxis: [
            {
                // categories: datas,
                categories: chartCategory,
                // disable shadow behind labels
                crosshair: false,
            },
        ],
        yAxis: [
            {
                visible: false,
                labels: {
                    // format: "{value}°C",
                    style: {
                        color: "red",
                    },
                },
                title: {
                    text: "Temperature",
                    style: {
                        color: "blue",
                    },
                },
            },
        ],
        tooltip: {
            shared: true,
        },
        legend: {
            layout: 'horizontal',
            reversed: true,
            // backgroundColor: '#FFFFFF',
            align: 'center',
            verticalAlign: 'top',
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                // maxPointWidth: 20,
                dataLabels: {
                    enabled: true,
                    // x: -2,
                    textShadow: false,
                    // textOutline: "0px",
                    color: '#FFFFFF',
                    borderWidth: 0,
                    style: {
                        fontSize: "10px",
                    },
                },
            },
            bar: {
                pointPadding: 0,
                borderWidth: 2,
                groupPadding: 0.1,
            },
        },
        series: [
            {
                name: "Turn Over",
                type: "column",
                color: "#8E24AA",
                data: dtTO.to,
                tooltip: {
                    valueSuffix: " %",
                }
            },
            {
                name: "Resign",
                type: "column",
                color: "#B71B1C", //red
                data: dtTO.resign,
            },
            {
                name: "Active Manpower",
                type: "column",
                color: "#287f9d",
                data: dtTO.mp,
            },
        ],
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            layout: "vertical",
                            align: "center",
                            verticalAlign: "top",
                        },
                    },
                },
            ],
        },
    };
    React.useEffect(() => {
        // const chart = chartComponent.current.chart;
    }, []);
    return (
        <>
            <div style={{ overflow: "visible" }}>
                <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
                    {/* begin::Title */}
                    <h3 className="align-items-center flex-column">
                        <span className="card-label fw-bold fs-1 mb-1">{title}</span>
                    </h3>
                </div>
                <HighchartsReact
                    ref={chartComponent}
                    highcharts={Highcharts}
                    options={options}
                // series={series}
                />
            </div>
        </>
    );
}

export { OutletTurnOver, ChartTurnOver, ChartTrendOver }