import React from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { PieTeamFocus, TableTeamFocus } from '../TeamFocusModelHelper'

function TeamFocusModel() {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    {
                        title: "Man Power",
                        path: "/sales/sales-wo-spk",
                        isSeparator: false,
                        isActive: false,
                    },
                ]}
            >
                {"Team Focus Model"}
            </PageTitle>
            <div className='row d-flex justify-content-around'>
                <div className='col col-lg-6'>
                    <PieTeamFocus />
                </div>
                <div className='col col-lg-6'>
                    <PieTeamFocus />
                </div>
            </div>
            <div className='my-10'>
                <TableTeamFocus title='SPK by SPV & Model'/>
            </div>
            <div className='my-10'>
                <TableTeamFocus title='SPK by Salesman & Model'/>
            </div>
        </>
    )
}

export default TeamFocusModel