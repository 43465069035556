import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { TableFooter, styled } from '@mui/material';
require("highcharts/modules/exporting")(Highcharts);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

type Column = {
    id: 'outlet' | 'target' | 'actual' | 'gap';
    label: string;
    minWidth?: number;
    align?: 'right' | 'center';
    style?: { [key: string]: string };
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'outlet', label: 'Outlet', minWidth: 150 },
    { id: 'target', label: 'Target', minWidth: 100 },
    {
        id: 'actual',
        label: 'Actual',
        minWidth: 150,
        align: 'center',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'gap',
        label: 'Gap',
        minWidth: 150,
        align: 'center',
        // style: { backgroundColor: value  },
        // format: (value: number) => value.toLocaleString('en-US'),
    },
];

type DataTarget = {
    data: any[]
}

function TargetvActualTable({ data }: DataTarget) {
    const sumTarget = data.map((x) => x.target).reduce((a, b) => a + b, 0);
    const sumActual = data.map((x) => x.actual).reduce((a, b) => a + b, 0);
    const sumGap = sumActual - sumTarget;
    React.useEffect(() => {
    }, [data]);
    return (
        <>
            <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
                {/* begin::Title */}
                <h3 className="align-items-center flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">Target v Actual</span>
                </h3>
            </div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 700 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, backgroundColor: '#287f9d' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row) => {
                                return (
                                    <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.outlet}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                column.id === 'gap' ?
                                                    (<StyledTableCell key={column.id} align={column.align} style={{ backgroundColor: value < 0 ? '#F4C7C3' : value > 0 ? '#FFECB3' : '#C8E6C9' }} >
                                                        {value}
                                                    </StyledTableCell>) :
                                                    (<StyledTableCell key={column.id} align={column.align} style={column.style} >
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </StyledTableCell>)
                                            );
                                        })}
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter
                            style={{
                                position: "sticky",
                                bottom: "0px",
                                backgroundColor: "white",
                            }}>
                            <TableRow>
                                <TableCell align="left"
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        color: "black",
                                    }}
                                    >Grand Total</TableCell>
                                <TableCell align="left"
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        color: "black",
                                    }}
                                    >{sumTarget}</TableCell>
                                <TableCell align="left"
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        color: "black",
                                        textAlign: "center",
                                    }}
                                    >{sumActual}</TableCell>
                                <TableCell align="left"
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        color: "black",
                                        textAlign: "center",
                                    }}
                                    >{sumGap}</TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}

type chart = {
    title: string;
    datas: string[];
    dataChart: number[];
}
function ChartQuantity({ title, datas, dataChart }: chart) {
    const chartComponent = React.useRef(null);

    const options = {
        chart: {
            backgroundColor: "transparent",
            zoomType: "xy",
            type: "bar",
            height: 300
        },
        credits: {
            enabled: false,
        },
        title: {
            text: "",
            align: "left",
        },
        xAxis: [
            {
                categories: datas,
                // categories: [
                //     "No Level",
                //     "Professional",
                //     "Expert",
                //     "Master"
                // ],
                // disable shadow behind labels
                crosshair: false,
            },
        ],
        yAxis: [
            {
                visible: false,
                labels: {
                    // format: "{value}°C",
                    style: {
                        color: "red",
                    },
                },
                title: {
                    text: "Temperature",
                    style: {
                        color: "blue",
                    },
                },
            },
        ],
        tooltip: {
            shared: true,
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                // maxPointWidth: 20,
                dataLabels: {
                    enabled: true,
                    x: -25,
                    textShadow: false,
                    // textOutline: "0px",
                    color: '#FFFFFF',
                    borderWidth: 0,
                    style: {
                        fontSize: "10px",
                    },
                },
            },
            bar: {
                pointPadding: 0,
                borderWidth: 2,
                groupPadding: 0.1,
            },
        },
        series: [
            {
                name: "",
                type: "column",
                color: "#287f9d",
                data: dataChart,
            },
        ],
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            layout: "vertical",
                            align: "center",
                            verticalAlign: "top",
                        },
                    },
                },
            ],
        },
    };
    React.useEffect(() => {
        // const chart = chartComponent.current.chart;
    }, []);
    return (
        <>
            <div style={{ overflow: "visible" }}>
                <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
                    {/* begin::Title */}
                    <h3 className="align-items-center flex-column">
                        <span className="card-label fw-bold fs-1 mb-1">{title}</span>
                    </h3>
                </div>
                <HighchartsReact
                    ref={chartComponent}
                    highcharts={Highcharts}
                    options={options}
                // series={series}
                />
            </div>
        </>
    );
}

export { TargetvActualTable, ChartQuantity }