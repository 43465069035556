import React, { useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import SingleLoopTable from './dataTable';
import { MscpApprovalAPI } from '../../../../api/MscpAPI';

type initialValues = {
    month: number[];
    asm: string[];
};

const ApprovalIndex = (props: any) => {
    const [approvalbyDp, setApprovalbyDp] = useState<any[]>([]);
    const [ approvalbyTenor, setApprovalbyTenor] = useState<any[]>([]);
    useEffect(() => {
        new MscpApprovalAPI().getDtRunningMonth().then((res) => {
            setApprovalbyDp(res.data);
        })
        new MscpApprovalAPI().getDtRunningMonthbyTenor().then((res) => {
            setApprovalbyTenor(res.data);
        })
    }, []);

    return (
        <React.Fragment>
            <PageTitle breadcrumbs={[{ title: "Credit Process", path: "/sales/approval", isSeparator: false, isActive: false }]}>
                {"Approval"}
            </PageTitle>
            {/* Running Month Approval (by DP) */}
            <div className="row g-5 mh-100">
                <div className="bd-highlight">
                    <KTCard className='mb-5 mh-700px'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-2">Running Month Approval (by DP)</span>
                            </h3>
                        </div>
                        <KTCardBody className="row pl-5 px-2 pt-0">
                            <div className="col-xxl-6 bd-highlight">
                                <div className='mb-5 h-400px'>
                                    <div className="card-header">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bold fs-3">Approve</span>
                                        </h3>
                                    </div>
                                    <div className="pl-5 px-2 my-5 pt-0">
                                        <SingleLoopTable
                                            data={{
                                                columns: ['DP (%)'],
                                                data: approvalbyDp,
                                                targetData: 'approve',
                                                max : 'maxApprove'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 bd-highlight">
                                <div className='mb-5 h-400px'>
                                    <div className="card-header">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bold fs-3">DO</span>
                                        </h3>
                                    </div>
                                    <div className="pl-5 px-2 my-5 pt-0">
                                        <SingleLoopTable
                                            data={{
                                                columns: ['DP (%)'],
                                                data: approvalbyDp,
                                                targetData: 'do',
                                                max : 'maxDo'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* O/S PO (by DP) */}
            <div className="row g-5 mh-100">
                <div className="bd-highlight">
                    <KTCard className='mb-5 mh-700px'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-2">O/S PO (by DP)</span>
                            </h3>
                        </div>
                        <KTCardBody className="row pl-5 px-2 pt-0">
                            <div className="col-xxl-6 bd-highlight">
                                <div className='mb-5 h-400px'>
                                    <div className="card-header">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bold fs-3">Approve</span>
                                        </h3>
                                    </div>
                                    <div className="pl-5 px-2 my-5 pt-0">
                                        <SingleLoopTable
                                            data={{
                                                columns: ['DP (%)'],
                                                data: approvalbyDp,
                                                targetData: 'outApprove',
                                                max : 'maxOutApprove'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 bd-highlight">
                                <div className='mb-5 h-400px'>
                                    <div className="card-header">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bold fs-3">DO</span>
                                        </h3>
                                    </div>
                                    <div className="pl-5 px-2 my-5 pt-0">
                                        <SingleLoopTable
                                            data={{
                                                columns: ['DP (%)'],
                                                data: approvalbyDp,
                                                targetData: 'outDo',
                                                max : 'maxOutDo'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* Running Month Approval (by Tenor) */}
            <div className="row g-5 mh-100">
                <div className="bd-highlight">
                    <KTCard className='mb-5 mh-700px'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-2">Running Month Approval (by Tenor)</span>
                            </h3>
                        </div>
                        <KTCardBody className="row pl-5 px-2 pt-0">
                            <div className="col-xxl-6 bd-highlight">
                                <div className='mb-5 h-400px'>
                                    <div className="card-header">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bold fs-3">Approve</span>
                                        </h3>
                                    </div>
                                    <div className="pl-5 px-2 my-5 pt-0">
                                    <SingleLoopTable
                                            data={{
                                                columns: ['Tenor'],
                                                data: approvalbyTenor,
                                                targetData: 'approve',
                                                max : 'maxApprove'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 bd-highlight">
                                <div className='mb-5 h-400px'>
                                    <div className="card-header">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bold fs-3">DO</span>
                                        </h3>
                                    </div>
                                    <div className="pl-5 px-2 my-5 pt-0">
                                    <SingleLoopTable
                                            data={{
                                                columns: ['Tenor'],
                                                data: approvalbyTenor,
                                                targetData: 'do',
                                                max : 'maxDo'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* O/S PO (by Tenor) */}
            <div className="row g-5 mh-100">
                <div className="bd-highlight">
                    <KTCard className='mb-5 mh-700px'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-2">O/S PO (by Tenor)</span>
                            </h3>
                        </div>
                        <KTCardBody className="row pl-5 px-2 pt-0">
                            <div className="col-xxl-6 bd-highlight">
                                <div className='mb-5 h-400px'>
                                    <div className="card-header">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bold fs-3">Approve</span>
                                        </h3>
                                    </div>
                                    <div className="pl-5 px-2 my-5 pt-0">
                                    <SingleLoopTable
                                            data={{
                                                columns: ['Tenor'],
                                                data: approvalbyTenor,
                                                targetData: 'outApprove',
                                                max : 'maxOutApprove'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 bd-highlight">
                                <div className='mb-5 h-400px'>
                                    <div className="card-header">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bold fs-3">DO</span>
                                        </h3>
                                    </div>
                                    <div className="pl-5 px-2 my-5 pt-0">
                                    <SingleLoopTable
                                            data={{
                                                columns: ['Tenor'],
                                                data: approvalbyTenor,
                                                targetData: 'outDo',
                                                max : 'maxOutDo'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ApprovalIndex;