import { ThemeModeComponent } from "../../../../_metronic/assets/ts/layout";
import {MaterialReactTable} from "material-react-table";
import React, { useRef, useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ProgressBar } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material";
require("highcharts/modules/exporting")(Highcharts);

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#ff9800",
    },
    background: {
      default: "#ffffff",
    },
    secondary: {
      main: "#00bcd4",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#81980f",
    },
    secondary: {
      main: "#00bcd4",
    },
  },
});

let theme = ThemeModeComponent.getMode() === "dark" ? darkTheme : lightTheme;
function GreenYellowRed(number) {
  let numVal = number > 100 ? 300 : number;
  // console.log("Theme", theme);
  // rgb(129,198,132)
  // number--; // working with 0-99 will be easier
  let r,
    g,
    b = 54;
  // if (number < 50) {
  //     // green to yellow
  //     g = Math.floor(255 * (number / 50));
  //     // g = 198;
  //     r = 255;

  //   } else {
  //       // yellow to red
  //       g = 255;
  //       // r = 129;
  //       r = Math.floor(255 * ((50-number%50) / 50));
  //     }
  //     b = 0;

  // rgb(244,67,54)

  r = numVal < 50 ? 255 : Math.floor(244 - ((numVal * 2 - 100) * 255) / 100);
  g = numVal > 50 ? 255 : Math.floor((numVal * 2 * 255) / 100);
  // return `rgb(${r + "," + g + "," + b })`;
  return `rgba(${r + "," + g + "," + b + "," + 0.5})`;
  // return `rgb(${(100 - number) *2.56}, ${number *2.56},0)})`;
}

function objAverage(obj, param1, param2) {
  // console.log("Param1", param1.includes("target"));
  // console.log(obj.length);
  let fil1 = obj.filter((val) => val[param1]);
  let fil2 = obj.filter((val) => val[param2]);

  // console.log("Fil1,2", fil1, fil2);
  if (fil1.length === 0) {
    return 0;
  }
  if (fil2.length === 0) {
    return 0;
  }

  const sum1 = fil1.reduce((total, next) => total + next[param1], 0);
  const sum2 = fil2.reduce((total, next) => total + next[param2], 0);

  // console.log("Sum1,2", sum1, sum2);
  if (param1.includes("target")) {
    const vals = Math.round((sum2 / sum1) * 100);
    return vals;
  } else {
    const vals = Math.round(((sum2 - sum1) / sum1) * 100);
    return vals;
  }
}

function objSum(obj, param) {
  // console.log(obj.length);
  let fil = obj.filter((val) => val[param]);

  if (fil.length === 0) {
    return 0;
  }
  const average = fil.reduce((total, next) => total + next[param], 0);

  return Math.round(average);
}

function TableHelper({ title, dtSpk }) {
  // const [age, setAge] = useState(10);
  const [dt, setDt] = useState(10);
  function handleChange(event) {
    // setAge(event.target.value);
    setDt(event.target.value);
    // console.log("Event", event.target.value);
  }

  const data = dtSpk;
  const currDate = new Date();
  const day = currDate.getDate();
  const formatter = new Intl.DateTimeFormat("us", { month: "short" });
  const month1 = formatter.format(new Date());

  let curr = month1 + " " + day;

  const columns = [
    {
      accessorKey: "outlet", //access nested data with dot notation
      header: "Outlet",
      size: 30,
      footer: "Grand Total",
    },
    {
      header: "EV",
      id: "ev",
      align: "center",
      columns: [
        {
          accessorKey:
            dt === 10
              ? "target_bef_ev"
              : dt === 20
              ? "mom_bef_ev"
              : "yoy_bef_ev",
          header: dt === 10 ? "Target" : dt === 20 ? "MoM" : "YoY",
          Cell: ({ cell }) =>
            cell.getValue() === null ? cell.getValue() + "-" : cell.getValue(),
          // align: "left",
          // muiTableHeadCellProps: {
          //   align: 'left',
          // },
          // muiTableBodyCellProps: {
          //   align: 'left',
          // },
          size: 10,
          footer: objSum(
            data,
            dt === 10
              ? "target_bef_ev"
              : dt === 20
              ? "mom_bef_ev"
              : "yoy_bef_ev"
          ),
        },
        {
          accessorKey:
            dt === 10 ? "target_af_ev" : dt === 20 ? "mom_af_ev" : "yoy_af_ev",
          header: curr + " MTD",
          size: 15,
          footer: objSum(
            data,
            dt === 10 ? "target_af_ev" : dt === 20 ? "mom_af_ev" : "yoy_af_ev"
          ),
        },
        {
          accessorKey:
            dt === 10
              ? "growth_target_ev"
              : dt === 20
              ? "growth_mom_ev"
              : "growth_yoy_ev",
          header: dt === 10 ? "Ach" : "Growth",
          size: 15,
          footer:
            objAverage(
              data,
              dt === 10
                ? "target_bef_ev"
                : dt === 20
                ? "mom_bef_ev"
                : "yoy_bef_ev",
              dt === 10 ? "target_af_ev" : dt === 20 ? "mom_af_ev" : "yoy_af_ev"
            ) + " %",
          Cell: ({ cell }) => Math.round(cell.getValue()) + "%",
          muiTableBodyCellProps: ({ cell }) => ({
            sx: {
              backgroundColor: GreenYellowRed(cell.getValue()),
              // fontWeight:
              //   cell.column.id === "ach" && cell.getValue() > 40
              //     ? "100"
              //     : "200",
            },
          }),
        },
      ],
    },
    {
      header: "ICE",
      id: "ice",
      columns: [
        {
          accessorKey:
            dt === 10
              ? "target_bef_ice"
              : dt === 20
              ? "mom_bef_ice"
              : "yoy_bef_ice",
          header: dt === 10 ? "Target" : dt === 20 ? "MoM" : "YoY",
          size: 15,
          footer: objSum(
            data,
            dt === 10
              ? "target_bef_ice"
              : dt === 20
              ? "mom_bef_ice"
              : "yoy_bef_ice"
          ),
        },
        {
          accessorKey:
            dt === 10
              ? "target_af_ice"
              : dt === 20
              ? "mom_af_ice"
              : "yoy_af_ice",
          header: curr + " MTD",
          size: 15,
          footer: objSum(
            data,
            dt === 10
              ? "target_af_ice"
              : dt === 20
              ? "mom_af_ice"
              : "yoy_af_ice"
          ),
        },
        {
          accessorKey:
            dt === 10
              ? "growth_target_ice"
              : dt === 20
              ? "growth_mom_ice"
              : "growth_yoy_ice",
          header: dt === 10 ? "Ach" : "Growth",
          size: 15,
          footer:
            objAverage(
              data,
              dt === 10
                ? "target_bef_ice"
                : dt === 20
                ? "mom_bef_ice"
                : "yoy_bef_ice",
              dt === 10
                ? "target_af_ice"
                : dt === 20
                ? "mom_af_ice"
                : "yoy_af_ice"
            ) + " %",
          Cell: ({ cell }) => Math.round(cell.getValue()) + "%",
          muiTableBodyCellProps: ({ cell }) => ({
            sx: {
              backgroundColor: GreenYellowRed(cell.getValue()),
              // fontWeight:
              //   cell.column.id === "ach" && cell.getValue() > 40
              //     ? "100"
              //     : "200",
            },
          }),
        },
      ],
    },
    {
      header: "All Model",
      id: "all",
      columns: [
        {
          accessorKey:
            dt === 10
              ? "target_bef_all"
              : dt === 20
              ? "mom_bef_all"
              : "yoy_bef_all",
          header: dt === 10 ? "Target" : dt === 20 ? "MoM" : "YoY",
          size: 15,
          footer: objSum(
            data,
            dt === 10
              ? "target_bef_all"
              : dt === 20
              ? "mom_bef_all"
              : "yoy_bef_all"
          ),
        },
        {
          accessorKey:
            dt === 10
              ? "target_af_all"
              : dt === 20
              ? "mom_af_all"
              : "yoy_af_all",
          header: curr + " MTD",
          size: 15,
          footer: objSum(
            data,
            dt === 10
              ? "target_af_all"
              : dt === 20
              ? "mom_af_all"
              : "yoy_af_all"
          ),
        },
        {
          accessorKey:
            dt === 10
              ? "growth_target_all"
              : dt === 20
              ? "growth_mom_all"
              : "growth_yoy_all",
          header: dt === 10 ? "Ach" : "Growth",
          size: 15,
          footer:
            objAverage(
              data,
              dt === 10
                ? "target_bef_all"
                : dt === 20
                ? "mom_bef_all"
                : "yoy_bef_all",
              dt === 10
                ? "target_af_all"
                : dt === 20
                ? "mom_af_all"
                : "yoy_af_all"
            ) + " %",
          Cell: ({ cell }) => Math.round(cell.getValue()) + "%",
          // muiTableBodyCellProps: ({ cell }) => ({
          //   sx: {
          //     backgroundColor: GreenYellowRed(cell.getValue()),
          //     // fontWeight:
          //     //   cell.column.id === "ach" && cell.getValue() > 40
          //     //     ? "100"
          //     //     : "200",
          //   },
          // }),
        },
      ],
    },
  ];
  useEffect(() => {}, [data]);
  return (
    <>
      <div className="card d-flex align-items-center flex-column mt-3 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 mb-1">{title}</span>
        </h3>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
            Compare to:
          </InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select"
            value={dt}
            label="Compare to:"
            onChange={(e) => {
              handleChange(e);
              // setAge(e.target.value);
            }}
          >
            <MenuItem value={10}>Target</MenuItem>
            <MenuItem value={20}>MoM</MenuItem>
            <MenuItem value={30}>YoY</MenuItem>
          </Select>
        </FormControl>
        {/* end::Title */}
      </div>
      <ThemeProvider theme={theme}>
        <MaterialReactTable
          columns={columns}
          data={data}
          enablePagination={false}
          enablePinning
          enableColumnFilters={false}
          enableBottomToolbar={false}
          muiTableBodyRowProps={{ hover: false }}
          enableColumnActions={false}
          initialState={{
            density: "compact",
            columnPinning: { left: ["outlet"] },
          }}
          muiTableHeadCellProps={{
            sx: {
              borderRight: " solid #e0e0e0",
              alignItems: "left",
              // "& .Mui-TableHeadCell-Content-Labels": {
              //   padding: "0px",
              //   margin: "0px 0px",
              // },
              "& .MuiBox-root": {
                padding: "0px",
                margin: "0px 0px",
              },
              // backgroundColor: "white",

              // borderTop: " solid #e0e0e0",
            },
          }}
          muiTableProps={{
            sx: {
              // tableLayout: "fixed",
              fontSize: "8px",
            },
          }}
        />
      </ThemeProvider>
    </>
  );
}

// export  TableHelper;
function sumData(data) {
  const sum = data.reduce((total, next) => total + next, 0);
  return sum;
}

function TableYTDHelper({ title, datas, category }) {
  // const globalTheme = useTheme();
  const data = datas;
  const perc = Math.round((sumData(data.achive) / sumData(data.target)) * 100);

  useEffect(() => {
    // console.log("mAPS2", data);
  }, [datas]);

  return (
    <>
      <div className="d-flex align-items-center flex-column mt-3 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 mb-1">{title}</span>
        </h3>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{category}</TableCell>
              <TableCell align="right">Jan</TableCell>
              <TableCell align="right">Feb</TableCell>
              <TableCell align="right">Mar</TableCell>
              <TableCell align="right">Apr</TableCell>
              <TableCell align="right">May</TableCell>
              <TableCell align="right">Jun</TableCell>
              <TableCell align="right">Jul</TableCell>
              <TableCell align="right">Aug</TableCell>
              <TableCell align="right">Sep</TableCell>
              <TableCell align="right">Oct</TableCell>
              <TableCell align="right">Nov</TableCell>
              <TableCell align="right">Dec</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Target
              </TableCell>
              {data.target.map((row, i) => (
                <TableCell key={i + 10} align="right">
                  {row}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Actual
              </TableCell>
              {data.achive.map((row, i) => (
                <TableCell key={i + 20} align="right">
                  {row}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Diff
              </TableCell>
              {data.diff.map((row, i) => (
                <TableCell
                  key={i + 30}
                  style={{
                    backgroundColor:
                      row < 0 ? "rgb(255,105,105)" : " rgb(255,255,255)",
                  }}
                  align="right"
                >
                  {row}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/* <ThemeProvider theme={theme}>
        <MaterialReactTable
          columns={columns}
          data={data}
          enablePagination={false}
          initialState={{ density: "compact" }}
          muiTableBodyProps={{
            sx: (theme) => ({
              "& tr:nth-of-type(odd)": {
                // backgroundColor: theme.palette.action.hover,
                // backgroundColor: darken(theme.palette.background.default, 0.1),
              },
            }),
          }}
        />
      </ThemeProvider> */}
      <div className="d-flex justify-content-between">
        <div>
          <h4 style={{ marginTop: "20px" }}>DO Actual</h4>
          <p style={{ fontSize: "24px" }}>{sumData(data.achive)}</p>
          <p>
            {perc} % of {sumData(data.target)} YTD Target
          </p>
          <ProgressBar
            variant={`${
              perc < 80
                ? "danger"
                : perc >= 80 && perc <= 100
                ? "warning"
                : "success"
            }`}
            now={perc}
          />
        </div>
        <div>
          <h4 style={{ marginTop: "20px" }}>DO Actual</h4>
          <p style={{ fontSize: "24px" }}>{sumData(data.achive)}</p>
          <p>
            {perc} % of {sumData(data.target)} YTD Target
          </p>
          <ProgressBar
            variant={`${
              perc < 80
                ? "danger"
                : perc >= 80 && perc <= 100
                ? "warning"
                : "success"
            }`}
            now={perc}
          />
        </div>
      </div>
    </>
  );
}

function ChartYTDHelper({ title, category, dt }) {
  const chartComponent = useRef(null);

  const options = {
    chart: {
      backgroundColor: "transparent",
      zoomType: "xy",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
      align: "left",
    },
    // subtitle: {
    //   text:
    //     "Source: " +
    //     '<a href="https://www.yr.no/nb/historikk/graf/5-97251/Norge/Troms%20og%20Finnmark/Karasjok/Karasjok?q=2021"' +
    //     'target="_blank">YR</a>',
    //   align: "left",
    // },
    xAxis: [
      {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        // disable shadow behind labels
        crosshair: false,
      },
    ],
    yAxis: [
      {
        visible: false,
        min: -1000,
        // Primary yAxis
        labels: {
          // format: "{value}°C",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "Temperature",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      {
        visible: false,
        // Secondary yAxis
        title: {
          text: "Precipitation",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        labels: {
          format: "{value} mm",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      // x: 80,
      // verticalAlign: "bottom",
      // y: 60,
      // floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    plotOptions: {
      series: {
        // maxPointWidth: 20,
        dataLabels: {
          enabled: true,
        },
      },
      column: {
        pointPadding: 0,
        borderWidth: 2,
        groupPadding: 0.1,
      },
      spline: {
        dataLabels: {
          format: "{y}%",
        },
      },
    },
    series: [
      {
        name: "DO Target",
        type: "column",
        color: "#287f9d",
        yAxis: 1,
        data: dt.target,
        tooltip: {
          // valueSuffix: " mm",
        },
      },
      {
        name: "DO Actual",
        type: "column",
        color: "#fcc47c",
        yAxis: 1,
        data: dt.achive,
        tooltip: {
          // valueSuffix: " mm",
        },
      },
      {
        name: "Ach",
        type: "spline",
        data: dt.growth,
        tooltip: {
          valueSuffix: "%",
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "vertical",
              align: "center",
              verticalAlign: "top",
            },
          },
        },
      ],
    },
  };
  useEffect(() => {
    // const chart = chartComponent.current.chart;
  }, []);
  return (
    <>
      <div style={{ marginBottom: "-4rem", overflow: "visible" }}>
        <HighchartsReact
          ref={chartComponent}
          highcharts={Highcharts}
          options={options}
          // series={series}
        />
      </div>
    </>
  );
}

export { TableYTDHelper, TableHelper, ChartYTDHelper };
