import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material';
import { RadialBarChart } from '../../../../_metronic/helpers/components/chart/radialBar';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#316076",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
type Column = {
    id: 'nama' | 'spv' | 'spk';
    label: string;
    minWidth?: number;
    align?: 'right' | 'center' | 'left';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    // { id: 'name', label: 'SPV', minWidth: 170 },
    // { id: 'code', label: 'Nama Sales', minWidth: 100 },
    {
        id: 'spv',
        label: 'SPV',
        minWidth: 70,
        align: 'center',
    },
    {
        id: 'nama',
        label: 'Nama Sales',
        minWidth: 170,
        align: 'center',
    },
    {
        id: 'spk',
        label: 'SPK',
        minWidth: 170,
        align: 'center',
    },
];
type Datawospk = {
    data: any[]
}

function SalesWoTable({ data }: Datawospk) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    React.useEffect(() => {
    }, [data])

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 1100 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <StyledTableRow>
                                {columns.map((column) => (
                                    <StyledTableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {data
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, i) => {
                                    return (
                                        <StyledTableRow hover role="checkbox" tabIndex={-1} key={i}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <StyledTableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </StyledTableCell>
                                                );
                                            })}
                                        </StyledTableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[100, 250, 1000]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

type Props = {
    title: string;
    sub1?: string;
    sub2?: string;
    total?: number;
    type?: string;
    v1?: number | undefined;
    v2?: number | undefined;
}
function CardRadial({ title, total, ...rest }: Props) {
    const { sub1, sub2, v1, v2, type } = rest;
    return (
        <>
            <div className='d-flex bd-highlight p-2 card'>
                <div className='d-flex m-0 mx-xl-2 align-self-center bd-highlight mb-5'>
                    <span className='text-gray-800 fs-5 text-wrap fw-bolder ms-3'>{title}</span>
                </div>
                <div className='row d-flex justify-content-between'>
                    {!type ? (
                        <div className='col col-lg-6'>
                            {!total ? <span className='text-gray-800 fs-3 text-wrap fw-bolder ms-3'>No Data</span> :
                                <RadialBarChart
                                    chartColor='primary'
                                    chartHeight='120px'
                                    className='woSpk'
                                    value={total ? parseFloat(total.toFixed(1)) : 70}
                                />
                            }
                        </div>) : (
                        <div className='col col-lg-6'>
                            {!total ?
                                <div className="text-center align-items-center" style={{ height: '120px' }}>
                                    <span className='text-gray-800 fa-2x'>No Data</span>
                                </div> :
                                (
                                    <div className="text-center" style={{ height: '120px' }}>
                                        <span className="fa-fw fa-5x">
                                            {total ? parseFloat(total.toFixed(1)) : 70}
                                        </span>
                                    </div>
                                )
                            }
                        </div>)}
                    <div className='col col-lg-6'>
                        <div className='lh-1'>
                            <h6>{sub1}</h6>
                            <p className='fs-3 fw-bolder'>{v1}</p>
                        </div>
                        <div className='lh-1'>
                            <h6>{sub2}</h6>
                            <p className='fs-3 fw-bolder'>{v2}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { SalesWoTable, CardRadial }