import { useEffect, useRef } from 'react'
import { FilterSegmentAPI } from '../api/MarketShareAPI';

const SegmentFilter = () => {
    const filterSegment = useRef([]);

    useEffect(() => {
        const getSegment = async () => {
            const res = await new FilterSegmentAPI().getSegment()
            if (res && res.data) {
                filterSegment.current = res.data.map((val: { text: string; id: string | number }) => {
                    return ({
                        label: val.text,
                        value: val.id
                    })
                })
            };
        }
        getSegment();
    }, []);

    return filterSegment.current
}

export default SegmentFilter