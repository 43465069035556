/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { PageTitle } from "../../../../../../../_metronic/layout/core";
import { KTCard, KTCardBody, KTIcon } from "../../../../../../../_metronic/helpers";
import { MaterialReactTableSimple } from "../../../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable";
import DataTableMuiModel from "../../../../../../models/DataTable/dataTableModal";
import { MasterDataSalesAPI } from "../../../../../../api/MasterDataSalesAPI";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    InputDatePicker,
    InputFileUpload,
    InputNumber,
    InputText,
} from "../../../../../../../_metronic/helpers/components/form-actions/FormInput";
import SimpleAlert from "../../../../../../../_metronic/helpers/components/alert/SimpleAlert";
import { FormUploadFilesModal } from "../../../../../../../_metronic/helpers/components/form-actions/ModalForm";
import { MasterDataRsWsModel } from "../../../../../../models/sales/MasterDataSalesAnalystModel";

export class MasterDataRsWsIndex extends Component {
    constructor(props: any) {
        super(props);
        this["title"] = "Master Data RS WS";
        this["api"] = new MasterDataSalesAPI();
        this["initialData"] = new MasterDataRsWsModel();
        this["datatable"] = [];
        this["columntable"] = [];
        this["state"] = {
            isLoading: true,
            progressInfos: {
                percentage: "",
                error: true,
            },
            modal: {
                loading: false,
                show: false,
                initialData: this["initialData"],
                isEdit: false,
            },
            alert: {
                activeId: [],
                show: false,
            },
        };
    }
    //** Start: Component */
    async componentDidMount() {
        await this.LoadData();
    }
    //** End: Component */
    //* ----------------------------------------------------------------------------- */
    //** Start: Load Data List */

    //** Begin:: Handle Modal && Alert */
    handleModal = {
        upload: async () => {
            let state = { ...this["state"] };
            state = {
                progressInfos: {
                    percentage: "",
                    error: true,
                },
                modal: {
                    show: true,
                    title: `Upload File Excel`,
                    isEdit: false,
                    initialData: this["initialData"],
                },
            };
            this.setState({ ...state });
        },
        edit: async (dataFromModel: any) => {
            let state = { ...this["state"] };
            state = {
                modal: {
                    show: true,
                    title: `Edit Excel Data`,
                    isEdit: true,
                    initialData: dataFromModel,
                },
            };
            this.setState({ ...state });
        },
        hide: () => {
            let state = { ...this["state"] };
            state = {
                modal: {
                    show: false,
                    initialData: this["initialData"],
                },
            };
            this.setState({ ...state });
        },
    };

    handleAlert = {
        show: (id) => {
            let alert = { ...this["state"] };
            alert = {
                alert: {
                    show: true,
                    activeId: id,
                },
            };
            this.setState({ ...alert });
        },
        hide: () => {
            let alert = { ...this["state"] };
            alert = {
                alert: {
                    show: false,
                    activeId: null,
                },
            };
            this.setState({ ...alert });
        },
        confirmed: async () => {
            await this["api"].DeleteDataGaikindo(this["state"]["alert"]["activeId"]);
            this["handleAlert"].hide();
            setTimeout(() => {
                this.LoadData();
            }, 2000);
        },
    };
    //** End:: Handle Modal && Alert */
    async LoadData() {
        this.setState({ loading: true });
        let data = await this["api"].getDataRsWs();
        this["DataTableMuiModel"] = new DataTableMuiModel({ readOnly: true });
        data["data"].map((item: any, i: number) => {
            let dataModel = new MasterDataRsWsModel(item);
            this["DataTableMuiModel"].add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null,
                // actions: (
                //     <>
                //         <div className="d-flex justify-content-center flex-shrink-0">
                //             <ButtonAction
                //                 title={"Edit"}
                //                 iconName="pencil"
                //                 onClick={() => this["handleModal"].edit(dataModel)}
                //             />
                //         </div>
                //     </>
                // ),
            });
            return item;
        });
        this["datatable"] = await this["DataTableMuiModel"].getDatas();
        this["columntable"] = await this["DataTableMuiModel"].getColumn();
        this.setState({ ...this["state"], loading: false });
    }
    //** End: Load Data List */
    render() {
        return (
            <>
                <PageTitle breadcrumbs={[{ title: "Master Data", path: "/sales/upload-data-gaikindo", isSeparator: false, isActive: false }]}>{this["title"]}</PageTitle>
                {/* Begin:: Card */}
                <KTCard>
                    <div className="card-header border-0 pt-6">
                        {/* Card Title */}
                        <div className="card-title"></div>
                        {/* Begin:: Card Toolbar */}
                        <div className="card-toolbar">
                            <div
                                className="d-flex justify-content-end"
                                data-kt-user-table-toolbar="base"
                            >
                                <button
                                    type={"button"}
                                    className={"btn btn-sm btn-light-primary"}
                                    onClick={() => {
                                        this["handleModal"].upload();
                                    }}
                                >
                                    <KTIcon iconName={"file-up"} className={"fs-4 me-2"} /> Upload
                                    File
                                </button>
                            </div>
                        </div>
                        {/* End:: Card Toolbar */}
                    </div>
                    {/* Begin:: Card Body */}
                    <KTCardBody className="py-4">
                        <div className="table-responsive">
                            <MaterialReactTableSimple
                                data={this["datatable"]}
                                columns={this["columntable"]}
                                // enableRowSelection
                                isLoading={this["state"]["loading"]}
                            // renderTopToolbarCustomActions={({ table }) => {
                            //     const handleDelete = async () => {
                            //         let data = table.getSelectedRowModel().flatRows.map((row: any) => { return row.getValue("id") });
                            //         this['handleAlert'].show({ id: data });
                            //     };
                            //     return (
                            //         <Box
                            //             sx={{
                            //                 display: "flex",
                            //                 gap: "1rem",
                            //                 p: "0.5rem",
                            //                 flexWrap: "wrap",
                            //             }}
                            //         >
                            //             {table.getSelectedRowModel().flatRows.length > 0 && (
                            //                 <Button
                            //                     color="error"
                            //                     hidden={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                            //                     onClick={handleDelete}
                            //                     startIcon={<DeleteIcon />}
                            //                     variant="contained"
                            //                 >{table.getIsSomeRowsSelected()
                            //                     ? "Delete"
                            //                     : "Delete All"}
                            //                 </Button>
                            //             )}
                            //         </Box>
                            //     );
                            // }}
                            />
                        </div>
                    </KTCardBody>
                    {/* End:: Card Body */}
                </KTCard>
                {/* End:: Card */}

                {/* Begin:: Modal */}
                <Formik
                    enableReinitialize={true}
                    initialValues={this["state"]["modal"]["initialData"]}
                    validationSchema={
                        !this["state"]["modal"]["isEdit"]
                            ? Yup.object().shape({
                                excel_n_rws: Yup.string().required("Files is required"),
                            })
                            : null
                    }
                    onSubmit={async (values, { resetForm }) => {
                        if (this["state"]["modal"]["isEdit"]) {
                            // // Update Data
                            // const dataSend = {
                            //     id: values["id"],
                            //     kode_upload: values["kode_upload"],
                            //     month_year: moment(values["month"]).format("YYYY-MM-DD"),
                            //     brand: values["brand"],
                            //     rs: values["rs"],
                            //     ws: values["ws"],
                            // };
                            // await this["api"].UploadFilesRsWs(dataSend);
                        } else {
                            const data = { excel_n_rws: values["excel_n_rws"] };
                            await this["api"].UploadFilesRsWs(data, (event) => {
                                this.setState({
                                    progressInfos: {
                                        percentage: Math.round((100 * event.loaded) / event.total),
                                    },
                                });
                            });
                        }
                        resetForm();
                        setTimeout(async () => {
                            this["handleModal"].hide();
                            this.LoadData();
                        }, 2000);
                    }}
                >
                    {(formik) => (
                        <FormUploadFilesModal
                            title={this["state"]["modal"]["title"]}
                            show={this["state"]["modal"]["show"]}
                            onHide={this["handleModal"]["hide"]}
                            onConfirm={formik.handleSubmit}
                            size={"lg"}
                            content={
                                <>
                                    {!this["state"]["modal"]["isEdit"] ? (
                                        <div className="row mb-3">
                                            <InputFileUpload
                                                name="excel_n_rws"
                                                progressInfos={this["state"]["progressInfos"]}
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                formik={formik}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <InputDatePicker
                                                name="month"
                                                label="Month/Year"
                                                showMonthYearPicker
                                                formik={formik}
                                            />
                                            <InputText
                                                name="brand"
                                                label={"Nama Brand"}
                                                formik={formik}
                                            />
                                            <InputNumber name="rs" label={"RS"} formik={formik} />
                                            <InputNumber name="ws" label={"WS"} formik={formik} />
                                        </>
                                    )}
                                </>
                            }
                        />
                    )}
                </Formik>
                {/* End:: Modal */}
                {/* Alert Hapus */}
                <SimpleAlert
                    title={"Hapus Data"}
                    content={"Anda yakin hapus data ini...?"}
                    show={this["state"]["alert"]["show"]}
                    onHide={() => this["handleAlert"].hide()}
                    onConfirmed={this["handleAlert"]["confirmed"]}
                />
            </>
        );
    }
}
export default MasterDataRsWsIndex;
