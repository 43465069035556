/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { numberWithPoint } from '../../helpers-data/AssetHelpers'

type Props = {
  className: string
  color: string
  svgIcon: string
  iconColor: string
  title: string
  subTitle: number
  titleColor?: string
  description: string
  subDescription: number
  descriptionColor?: string
  textAlign?: string
}

const StatisticsWidget: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  subTitle,
  titleColor,
  description,
  subDescription,
  descriptionColor,
  textAlign,
}) => {
  let textColor = "success";
  let icon = "arrow-up";
  let valuesPercent: any = "Tidak ada data";
  if (subTitle && subDescription) {
    valuesPercent = ((subTitle / subDescription) - 1) * 100;
    if (valuesPercent >= 0) {
      textColor = "success";
      icon = "arrow-up";
    } else {
      textColor = "danger";
      icon = "arrow-down";
    }
  }

  return (
    <div className={`card ${className} ${textAlign}`}>
      <div className='card-body p-2'>
        <div className={`fw-bold text-muted mb-2 fs-3 mt-0`}>{title}</div>
        <div className={`fw-bold fs-2 mb-2 mt-0`}>{subTitle ? typeof subTitle === "number" ? numberWithPoint(subTitle) : `${subTitle}%` : "Tidak ada data"}</div>
        <div className={`fw-bold fs-2 mb-2 mt-0`}>
          {typeof valuesPercent === "number" ? (
            <span className={`badge badge-light-${textColor} fs-4`}>
              <i className={`ki-duotone ki-${icon} fs-4 text-${textColor} ms-n1`}>
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              {`${Math.round(valuesPercent)}%`}
            </span>
          ) : (
            <span className={`badge fs-4`}>
              {`${valuesPercent}`}
            </span>
          )}
        </div>
        <div className={`fw-semibold text-muted mb-2 mt-0 fs-7 text-${descriptionColor}`}>{description}</div>
        <div className={`fw-semibold m-0 fs-6 text-${descriptionColor}`}>{subDescription ? typeof subDescription === "number" ? numberWithPoint(subDescription) : `${subDescription}%` : "Tidak ada data"}</div>
      </div>
    </div>
  )
}

export { StatisticsWidget }
