import React, { useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { AvatarSalesCompetency, SalesmanTable, SmallTableCompetency } from '../SalesCompetencyHelper'
import { SalesCompetencyAPI } from '../../../../api/ManPower'
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput'
import { equalsCheck } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers'

function SalesCompetency() {
    const currDate = new Date();
    const currMonth = currDate.getMonth();
    const [slcMonth, setSlcMonth] = useState<number[]>([currMonth + 1]);
    const [dtSM, setdtSM] = useState<[]>([]);
    const [dtOutlet, setdtOutlet] = useState<[]>([]);
    const [dtSPV, setdtSPV] = useState<[]>([]);
    const [dtTblSalesman, setDtTblSalesman] = useState<[]>([]);
    const [dtASM, setdtASM] = useState<[]>([]);

    const monthOpt = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
    ];
    const [monthSlc, setMonthSlc] = useState<Option[]>([monthOpt[currMonth]]);

    const getData = (m: number[] | string[]) => {
        Promise.all([
            new SalesCompetencyAPI().dtSalesComp(m).then((res) => {
                setDtTblSalesman(res.data.data)
                setdtSM(res.data.dataSM)
                setdtOutlet(res.data.dataOutlet)
                setdtSPV(res.data.dataSPV)
                setdtASM(res.data.dataASM)
            }),
        ]).then(() => console.log("Done"));
        // setLoading(false))
    }

    useEffect(() => {
        getData(slcMonth)
    }, [slcMonth]);
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    {
                        title: "Man Power",
                        path: "/sales/sales-competency-level",
                        isSeparator: false,
                        isActive: false,
                    },
                ]}
            >
                {"Sales Competency Level"}
            </PageTitle>
            <div className="col d-flex justify-content-around mb-15">
                <div className="col-xxl-3 mb-5">
                    <FilterMultiSelect
                        options={monthOpt}
                        label="Type"
                        isSelectAll={true}
                        value={monthSlc}
                        onMenuClose={() => {
                            let toFilter = monthSlc?.map((val) => val.value);
                            return equalsCheck(
                                toFilter as [],
                                slcMonth
                            )
                                ? null
                                : setSlcMonth(toFilter as []);
                        }}
                        onChange={(selected: Option[]) => {
                            setMonthSlc(selected);
                        }}
                    />
                </div>
                {/* <MultipleSelectCheckmarks
                    title={"Month"}
                    datas={monthOpt}
                    dt={slcMonth}
                    setDt={setSlcMonth}
                    dtwidth={300}
                /> */}
                {/* <SingleAutocomplete title={"Month"} datas={monthOpt} dt={month} setDt={setMonth} /> */}
            </div>
            <div className='row d-flex justify-content-between my-10'>
                {dtASM.map((val: { nama: string, total: number }, index) => (
                    <div key={index} className='card col-lg-4' style={{ maxWidth: "35rem" }}>
                        <AvatarSalesCompetency total={Math.round(val.total * 100)} area={val.nama} />
                    </div>
                ))}
                {dtASM.length === 0 &&
                    <>
                        <div className='card col-lg-4' style={{ maxWidth: "35rem" }}>
                            <AvatarSalesCompetency area={"Java"} />
                        </div>
                        <div className='card col-lg-4' style={{ maxWidth: "35rem" }}>
                            <AvatarSalesCompetency area={"Sulbagsel-Bali"} />
                        </div>
                        <div className='card col-lg-4' style={{ maxWidth: "35rem" }}>
                            <AvatarSalesCompetency area={"Sulbagut Ternate"} />
                        </div>
                    </>
                }
            </div>

            <div className='row d-flex justify-content-between'>
                <div className='col-lg-4'>
                    <SmallTableCompetency dt={dtSM} title={"Sales Manager"} head={"SM"} />
                </div>
                <div className='col-lg-4'>
                    <SmallTableCompetency dt={dtOutlet} title={"Outlet"} head={"Outlet"} />
                </div>
                <div className='col-lg-4'>
                    <SmallTableCompetency dt={dtSPV} title={"SPV"} head={"SPV"} />
                </div>
            </div>
            <div className='my-10'>
                <SalesmanTable dt={dtTblSalesman} title={"Salesman"} />
            </div>
        </>
    )
}

export default SalesCompetency

