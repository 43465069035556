import React, { useEffect, useMemo, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import { Form, Formik } from 'formik';
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { MaterialReactTableDetails } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';
import moment from "moment";

type dataType = {
    sm: string;
    days: number[];
    date: string;
};
type initialValues = {
    month: number[];
    asm: string[];
};

const ReportComplianceIndex = (props: any) => {
    const [initialValues] = useState<initialValues>({ asm: [], month: [] });
    const [loading, setloading] = useState<boolean>(true);
    const [columnCreditProcess, setColumnCreditProcess] = useState<any>();
    const [dataCreditProcess, setDataCreditProcess] = useState<any>([]);
    const [columnMme, setColumnMme] = useState<any>();
    const [dataMme, setDataMme] = useState<any>([]);
    const [columnStm, setColumnStm] = useState<any>();
    const [dataStm, setDataStm] = useState<any>([]);
    // API:: Credit Process
    const fetchingCreditProcessAPI = (values: object) => {
        setloading(true);
        const data: dataType[] = [{
            sm: "Pettarani - Perintis",
            days: [1, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }, {
            sm: "Gowa - Maros",
            days: [7, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }];
        let datasArray: any = [];
        data.map((item: any) => {
            let objData: any = {};
            for (let i = 0; i < item['days'].length; i++) {
                objData[`days${i + 1}`] = item['days'][i];
            }
            datasArray.push({
                sm: item['sm'],
                ...objData
            });
            return item;
        });
        let columns: any = [{
            header: 'SM',
            accessorKey: 'sm',
            size: 150,
            muiTableHeadCellProps: {
                align: "center"
            },
        }];
        // Berdasarkan Day in Month
        for (let i = 0; i < parseInt(moment('2023-09-01').endOf("month").format("D")); i++) {
            let objColumn: any = {};
            objColumn['header'] = `${i + 1}`;
            objColumn['accessorKey'] = `days${i + 1}`;
            objColumn['size'] = 10;
            objColumn['muiTableBodyCellProps'] = ({ cell }) => ({
                align: "center",
                sx: {
                    backgroundColor: cell.getValue() >= 5 ? "green" : "red",
                    color: cell.getValue() >= 5 ? "green" : "red",
                },
            });
            objColumn['muiTableHeadCellProps'] = {
                align: "center",
            };
            columns.push(objColumn);
        }
        setColumnCreditProcess(columns);
        setDataCreditProcess(datasArray);
        setloading(false);
    };
    // API:: MME
    const fetchingMmeAPI = (values: object) => {
        setloading(true);
        const data: dataType[] = [{
            sm: "Pondok Indah",
            days: [1, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }, {
            sm: "Parung",
            days: [7, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }];
        let datasArray: any = [];
        data.map((item: any) => {
            let objData: any = {};
            for (let i = 0; i < item['days'].length; i++) {
                objData[`days${i + 1}`] = item['days'][i];
            }
            datasArray.push({
                sm: item['sm'],
                ...objData
            });
            return item;
        });
        let columns: any = [{
            header: 'SM',
            accessorKey: 'sm',
            size: 150,
            muiTableHeadCellProps: {
                align: "center"
            },
        }];
        // Berdasarkan Data Ada
        for (let i = 0; i < parseInt(moment('2023-09-01').endOf("month").format("D")); i++) {
            let objColumn: any = {};
            objColumn['header'] = `${i + 1}`;
            objColumn['accessorKey'] = `days${i + 1}`;
            objColumn['size'] = 10;
            objColumn['muiTableBodyCellProps'] = ({ cell }) => ({
                align: "center",
                sx: {
                    backgroundColor: cell.getValue() >= 5 ? "green" : "red",
                    color: cell.getValue() >= 5 ? "green" : "red",
                },
            });
            objColumn['muiTableHeadCellProps'] = {
                align: "center",
            };
            columns.push(objColumn);
        }
        setColumnMme(columns);
        setDataMme(datasArray);
        setloading(false);
    };
    // API:: STM & Salesman Activity
    const fetchingStmAPI = () => {
        setloading(true);
        const data: dataType[] = [{
            sm: "Bandung BKR",
            days: [1, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }, {
            sm: "Parung",
            days: [7, 2, 3, 4, 5, 6, 0, 1, 2, 4, 5, 6],
            date: '2023-08-01'
        }];
        let datasArray: any = [];
        data.map((item: any) => {
            let objData: any = {};
            for (let i = 0; i < item['days'].length; i++) {
                objData[`days${i + 1}`] = item['days'][i];
            }
            datasArray.push({
                sm: item['sm'],
                ...objData
            });
            return item;
        });
        let columns: any = [{
            header: 'SM',
            accessorKey: 'sm',
            size: 100,
            muiTableHeadCellProps: {
                align: "center"
            },
        }];
        // Berdasarkan Year In MONTH
        let arrayMonth = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        for (let i = 0; i < arrayMonth.length; i++) {
            let objColumn: any = {};
            objColumn['header'] = arrayMonth[i];
            objColumn['accessorKey'] = `days${i + 1}`;
            objColumn['size'] = 10;
            // objColumn['maxSize'] = 10;
            objColumn['muiTableBodyCellProps'] = ({ cell }) => ({
                align: "center",
                sx: {
                    backgroundColor: cell.getValue() >= 5 ? "green" : "red",
                    color: cell.getValue() >= 5 ? "green" : "red",
                },
            });
            objColumn['muiTableHeadCellProps'] = {
                align: "center",
            };
            columns.push(objColumn);
        }
        setColumnStm(columns);
        setDataStm(datasArray);
        setloading(false);
    };
    // USEMEMO:: Credit Process Columns
    const creditProcessColumns = useMemo(() => [{
        id: 'dayCreditProcess',
        header: "Day/is Present",
        columns: columnCreditProcess,
        muiTableHeadCellProps: {
            align: "right"
        },
    }], [columnCreditProcess]);
    // USEMEMO:: MME Columns
    const MmeColumns = useMemo(() => [{
        id: 'dayMME',
        header: "Day/is Present",
        columns: columnMme,
        muiTableHeadCellProps: {
            align: "right"
        },
    }], [columnMme]);
    // USEMEMO:: STM & Salesman Activity
    const StmColumns = useMemo(() => [{
        id: 'daySTM',
        header: "Day/is Present",
        columns: columnStm,
        muiTableHeadCellProps: {
            align: "right"
        },
    }], [columnStm]);
    useEffect(() => {
        fetchingCreditProcessAPI(initialValues);
        fetchingMmeAPI(initialValues);
        fetchingStmAPI();
    }, [initialValues]);


    return (
        <>
            <PageTitle breadcrumbs={[{ title: "KPI Dashboard", path: "/sales/report-compliance", isSeparator: false, isActive: false }]}>
                {"Report Compliance"}
            </PageTitle>
            {/* BEGIN:: Card Filter Input */}
            <div className="col-xxl-12 mb-5">
                <KTCard flush stretch={"stretch"}>
                    <KTCardBody>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            onSubmit={() => { }}
                        >
                            {({ setFieldValue, values }) => (
                                <Form>
                                    <div className="row m-auto">
                                        <div className="col-lg-3 mb-5">
                                            <FilterMultiSelect
                                                label={"ASM"}
                                                name="asm"
                                                options={[]}
                                                onChange={(selected: Option[]) => {
                                                    setFieldValue("asm", selected);
                                                    const arrayValueMonth = values["month"].map(
                                                        (x: Object) => x["value"]
                                                    );
                                                    const arrayValueAsm = selected.map(
                                                        (x: Object) => x["value"]
                                                    );
                                                    setTimeout(() => {
                                                        fetchingCreditProcessAPI({
                                                            asm: arrayValueAsm,
                                                            month: arrayValueMonth,
                                                        })
                                                    }, 500);
                                                }}
                                                value={values["dealer_group"]}
                                            />
                                        </div>
                                        <div className="col-lg-3 mb-5">
                                            <FilterMultiSelect
                                                label={"Month"}
                                                name="month"
                                                options={[]}
                                                onChange={(selected: Option[]) => {
                                                    setFieldValue("month", selected);
                                                    const arrayValueMonth = selected.map(
                                                        (x: Object) => x["value"]
                                                    );
                                                    const arrayValueAsm = values["asm"].map(
                                                        (x: Object) => x["value"]
                                                    );
                                                    setTimeout(() => {
                                                        fetchingCreditProcessAPI({
                                                            asm: arrayValueAsm,
                                                            month: arrayValueMonth,
                                                        })
                                                    }, 500);
                                                }}
                                                value={values["month"]}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </KTCardBody>
                </KTCard>
            </div>
            {/* END:: Card Filter Input */}
            {/* ----------------------------------------------------------------------------- */}
            {/* BEGIN:: Table Credit Process  */}
            <div className="row flex-xxl-row h-md-100">
                {/* Credit Process */}
                <div className="col-xxl-12 h-md-100 mb-5 bd-highlight">
                    <KTCard flush stretch={"stretch-75"}>
                        <div className="card-header d-flex align-items-center flex-column mt-3 w-100 border-0 pt-5 pb-0">
                            {/* begin::Title */}
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1 mb-1">Credit Process</span>
                            </h3>
                            {/* end::Title */}
                        </div>
                        <KTCardBody className="pl-5 pt-0">
                            <MaterialReactTableDetails
                                columns={creditProcessColumns}
                                data={dataCreditProcess}
                                isLoading={loading}
                                maxHeight={"408px"}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* END:: Table Credit Process  */}
            {/* ----------------------------------------------------------------------------- */}
            {/* BEGIN:: Table MME  */}
            <div className="row flex-xxl-row h-md-100">
                {/* MME */}
                <div className="col-xxl-12 h-md-100 mb-5 bd-highlight">
                    <KTCard flush stretch={"stretch-75"}>
                        <div className="card-header d-flex align-items-center flex-column mt-3 w-100 border-0 pt-5 pb-0">
                            {/* begin::Title */}
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1 mb-1">MME</span>
                            </h3>
                            {/* end::Title */}
                        </div>
                        <KTCardBody className="pl-5 pt-0">
                            <MaterialReactTableDetails
                                columns={MmeColumns}
                                data={dataMme}
                                isLoading={loading}
                                maxHeight={"408px"}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* END:: Table MME  */}
            {/* ----------------------------------------------------------------------------- */}
            {/* BEGIN:: Table STM & Salesman Activity  */}
            <div className="row flex-xxl-row h-md-100">
                {/* STM & Salesman Activity */}
                <div className="col-xxl-12 h-md-100 mb-5 bd-highlight">
                    <KTCard flush stretch={"stretch-75"}>
                        <div className="card-header d-flex align-items-center flex-column mt-3 w-100 border-0 pt-5 pb-0">
                            {/* begin::Title */}
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1 mb-1">STM & Salesman Activity</span>
                            </h3>
                            {/* end::Title */}
                        </div>
                        <KTCardBody className="pl-5 pt-0">
                            <MaterialReactTableDetails
                                columns={StmColumns}
                                data={dataStm}
                                isLoading={loading}
                                maxHeight={"408px"}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* END:: Table STM & Salesman Activity  */}
        </>
    )
}

export default ReportComplianceIndex;