import React, { useRef, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import { styled } from '@mui/material/styles';
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, Skeleton } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);

// function createDataBrand(brand, prevYearMarket, currYearMarket, growth) {
// return {brand, prevYearMarket, currYearMarket, growth};
// }
function maxWidth(max, val) {
  let maxs = (val / max) * 60;
  return `${Math.ceil(maxs)}%`;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(174,191,200)",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function TableTopBrand({ title, dt, prevMax, currMax }) {
  useEffect(() => {}, [dt]);
  return (
    <>
      <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 mb-1">{title}</span>
        </h3>
      </div>
      <Paper sx={{ width: "100%", overflowX: "auto" }}>
        <TableContainer sx={{ maxHeight: 350 }}>
          <Table size="small" aria-label="a dense table" style={{ width: 700 }}>
            <TableHead
            // sx={{ backgroundColor: "rgb(117,156,185)", width: "100%" }}
            >
              <TableRow
                key={"models"}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell
                  style={{
                    backgroundColor: "rgb(117,156,185)",
                    position: "sticky",
                    left: 0,
                    top: 0,
                  }}
                  width={"5%"}
                >
                  No
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    backgroundColor: "rgb(117,156,185)",
                    position: "sticky",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                  }}
                  width={"10%"}
                >
                  Brand
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    backgroundColor: "rgb(117,156,185)",
                    position: "sticky",
                    left: 0,
                    top: 0,
                  }}
                  colSpan={2}
                >
                  Market 2022
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    backgroundColor: "rgb(117,156,185)",
                    position: "sticky",
                    left: 0,
                    top: 0,
                  }}
                  colSpan={2}
                >
                  Market 2023
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    backgroundColor: "rgb(117,156,185)",
                    position: "sticky",
                    left: 0,
                    top: 0,
                  }}
                  width={"10%"}
                >
                  Growth
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dt.map((row, i) => (
                <StyledTableRow
                  key={row.brand}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell style={{ fontSize: "8.5px" }} align="left">
                    {i + 1}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      // fontSize: "8.5px",
                      whiteSpace:"nowrap",
                      position: "sticky",
                      left: -1,
                      backgroundColor: "white",
                      border: 1,
                    }}
                    align="left"
                  >
                    {row.brand}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontSize: "10px" }}
                    colSpan={2}
                    align="left"
                  >
                    <span
                      style={{
                        width: "2.5rem",
                        display: "inline-block",
                        verticalAlign: "top",
                      }}
                    >
                      {row.marketMinYear}
                    </span>
                    <div
                      className="d-inline-block"
                      style={{
                        backgroundColor: "rgb(40,127,157)",
                        width: maxWidth(prevMax, row.marketMinYear),
                        height: "8px",
                      }}
                    ></div>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontSize: "10px" }}
                    colSpan={2}
                    align="left"
                  >
                    <span
                      style={{
                        width: "2.5rem",
                        display: "inline-block",
                        verticalAlign: "top",
                      }}
                    >
                      {row.marketMaxYear}
                    </span>
                    <div
                      className="d-inline-block"
                      style={{
                        backgroundColor: "rgb(40,127,157)",
                        width: maxWidth(currMax, row.marketMaxYear),
                        height: "10px",
                      }}
                    ></div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.growth !== "NaN" ? row.growth + "%" : ""}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

function TableTopModel({ title, dt, prevMax, currMax }) {
  useEffect(() => {}, [dt]);
  return (
    <>
      <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 mb-1">{title}</span>
        </h3>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 350 }}>
          <Table size="small" aria-label="a dense table" style={{ width: 615 }}>
            <TableHead
            // style={{ backgroundColor: "rgb(141,184,128)", width: "100%" }}
            >
              <TableRow key={"models"}>
                <TableCell
                  style={{
                    backgroundColor: "rgb(141,184,128)",
                    position: "sticky",
                    left: 0,
                    top: 0,
                  }}
                >
                  No
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(141,184,128)",
                    position: "sticky",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  Segment
                </TableCell>
                <TableCell
                  width={"15%"}
                  style={{
                    backgroundColor: "rgb(141,184,128)",
                    position: "sticky",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  Model
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(141,184,128)",
                    position: "sticky",
                    left: 0,
                    top: 0,
                  }}
                  align="center"
                  colSpan={2}
                >
                  Market 2022
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(141,184,128)",
                    position: "sticky",
                    left: 0,
                    top: 0,
                  }}
                  align="center"
                  colSpan={2}
                >
                  Market 2023
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(141,184,128)",
                    position: "sticky",
                    left: 0,
                    top: 0,
                  }}
                >
                  Growth
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dt.map((row, i) => (
                <StyledTableRow
                  key={row.model}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell style={{ fontSize: "8.5px" }} align="left">
                    {i + 1}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      // fontSize: "8.5px",
                      position: "sticky",
                      left: -1,
                      // backgroundColor: "white",
                      border: 1,
                    }}
                    align="left"
                  >
                    {row.segment}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      // fontSize: "8.5px",
                      position: "sticky",
                      left: -1,
                      backgroundColor: "white",
                      opacity: 1,
                      border: 1,
                      whiteSpace:"nowrap"
                    }}
                    align="left"
                  >
                    {row.model}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontSize: "10px" }}
                    colSpan={2}
                    align="left"
                  >
                    <span
                      style={{
                        width: "2.5rem",
                        display: "inline-block",
                        verticalAlign: "top",
                      }}
                    >
                      {row.marketMinYear}
                    </span>
                    <div
                      className="d-inline-block"
                      style={{
                        backgroundColor: "rgb(40,127,157)",
                        width: maxWidth(prevMax, row.marketMinYear),
                        height: "8px",
                      }}
                    ></div>
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ fontSize: "10px" }}
                    colSpan={2}
                    align="left"
                  >
                    <span
                      style={{
                        width: "2.5rem",
                        display: "inline-block",
                        verticalAlign: "top",
                      }}
                    >
                      {row.marketMaxYear}
                    </span>
                    <div
                      className="d-inline-block"
                      style={{
                        backgroundColor: "rgb(40,127,157)",
                        width: maxWidth(currMax, row.marketMaxYear),
                        height: "10px",
                      }}
                    ></div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.growth !== "NaN" ? row.growth + "%" : ""}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

function ChartMarketSeg({ title, category, dt }) {
  const chartComponent = useRef(null);

  const options = {
    chart: {
      backgroundColor: "transparent",
      zoomType: "xy",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
      align: "left",
    },
    // subtitle: {
    //   text:
    //     "Source: " +
    //     '<a href="https://www.yr.no/nb/historikk/graf/5-97251/Norge/Troms%20og%20Finnmark/Karasjok/Karasjok?q=2021"' +
    //     'target="_blank">YR</a>',
    //   align: "left",
    // },
    xAxis: [
      {
        categories: dt.segment,
        // disable shadow behind labels
        crosshair: false,
      },
    ],
    yAxis: [
      {
        visible: false,
        min: -5000,
        // Primary yAxis
        labels: {
          format: "{value}°C",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "Temperature",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      {
        visible: false,
        // Secondary yAxis
        title: {
          text: "Precipitation",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        labels: {
          format: "{value} mm",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      // x: 80,
      // verticalAlign: "bottom",
      // y: 60,
      // floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    plotOptions: {
      series: {
        // maxPointWidth: 20,
        dataLabels: {
          enabled: true,
        },
      },
      column: {
        pointPadding: 0,
        borderWidth: 2,
        groupPadding: 0.1,
      },
      spline: {
        dataLabels: {
          format: "{y}%",
        },
      },
    },
    series: [
      {
        name: "Market 2022",
        type: "column",
        color: "#287f9d",
        yAxis: 1,
        data: dt.marketMinYear,
        tooltip: {
          // valueSuffix: " mm",
        },
      },
      {
        name: "Market 2023",
        type: "column",
        color: "#fcc47c",
        yAxis: 1,
        data: dt.marketMaxYear,
        tooltip: {
          // valueSuffix: " mm",
        },
      },
      {
        name: "Growth",
        type: "spline",
        data: dt.growth,
        tooltip: {
          valueSuffix: "%",
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "top",
            },
          },
        },
      ],
    },
  };
  useEffect(() => {
    // console.log("Data Chart",dt);
    // const chart = chartComponent.current.chart;
  }, [dt]);
  return (
    <>
      <div className="text-center mt-5" style={{ overflow: "visible" }}>
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 mb-1">{title}</span>
        </h3>
        <HighchartsReact
          ref={chartComponent}
          highcharts={Highcharts}
          options={options}
          // series={series}
        />
      </div>
    </>
  );
}

const haloOptions = {
  events: {
    click: function () {
      let point = this,
        chart = point.series.chart,
        shapeArgs = point.shapeArgs,
        size = 10,
        opacity = 0.5,
        path = chart.renderer.symbols.arc(
          shapeArgs.x + chart.plotLeft,
          shapeArgs.y + chart.plotTop,
          shapeArgs.r + size,
          shapeArgs.r + size,
          {
            innerR: shapeArgs.r - 1,
            start: shapeArgs.start,
            end: shapeArgs.end,
          }
        ),
        drawNewGraphic = false,
        arc;
      drawNewGraphic = chart.customHalo.index !== point.index ? true : false;
      if (!drawNewGraphic && chart.customHalo.graphic) {
        chart.customHalo.graphic.destroy();
        chart.customHalo.index = null;
        chart.customHalo.graphic = null;
      } else if (chart.customHalo.graphic) {
        chart.customHalo.graphic.destroy();
      }

      if (drawNewGraphic) {
        arc = chart.renderer
          .path(path)
          .attr({
            fill: point.color,
            opacity: opacity,
          })
          .add();

        chart.customHalo = {
          index: point.index,
          graphic: arc,
        };

        chart.customHalo.index = point.index;
      }
    },
    function(event) {
      // alert(this.x + " " + this.y);
      alert(this.id);
    },
  },
};

function SegmentPieChart(dt) {
  const chartComponent = useRef(null);

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      backgroundColor: "transparent",
      type: "pie",
      events: {
        load: function () {
          var chart = this;

          chart.customHalo = {
            graphic: null,
            index: null,
          };
        },
      },
    },
    title: {
      text: "MS by Brand",
      align: "center",
    },
    tooltip: {
      // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      pointFormat: "{point.y}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
      // itemMarginTop: 10,
      // itemMarginBottom: 10
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.1f}%",
          distance: -30,
          align: "center",
        },
        slicedOffset: 0,
        showInLegend: true,
      },
    },
    series: [
      {
        // colorByPoint: true,
        name: "Brands",
        colorByPoint: true,
        states: {
          hover: {
            enabled: true,
          },
          inactive: {
            enabled: false,
          },
        },
        point: haloOptions,
        data: dt.dt,
      },
    ],
  };
  useEffect(() => {
    // const chart = chartComponent.current.chart;
  }, [dt]);
  return (
    <>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        options={options}
        // series={series}
      />
    </>
  );
}

function SegmentDonutChart({ dt, kmg, nonkmg }) {
  useEffect(() => {}, [dt]);
  const options = {
    colors: ["#01BAF2", "#71BF45", "#FAA74B", "#B37CD2"],
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      events: {
        load: function () {
          var chart = this;
          chart.customHalo = {
            graphic: null,
            index: null,
          };
        },
        render: function addTitle() {
          if (this.title) {
            this.title.destroy();
          }

          var r = this.renderer,
            x = this.series[0].center[0] + this.plotLeft,
            // y = 180;
            y = this.plotTop + this.series[0].center[1] - 20;
          // y = this.series[0].center[0] + this.plotTop;
          this.title = r
            .text(
              `
          <span style="font-size:10px">MS Wuling Kumala</span>
          <br><p style="font-size:15px">${(kmg * 100).toFixed(1)} %</p><br>
          <span style="font-size:10px">MS Wuling Non Kumala </span>
          <br><p style="font-size:15px">${(nonkmg * 100).toFixed(1)} %</p><br>
          `,
              0,
              0
            )
            .css({
              fontSize: "10px",
              textAnchor: "middle",
            })
            .hide()
            .add();

          var bbox = this.title.getBBox();
          this.title
            .attr({
              x: x - bbox.width / 100,
              y: y,
            })
            .show();
        },
      },
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    title: {
      text: "",
      // text: "February 2020 Norway passenger auto registrations",
    },
    // subtitle: {
    //   text: "",
    // },
    tooltip: {
      pointFormat: "{point.y}: <b>{point.percent:.0f}%</b>",
    },
    legend: {
      // layout: "horizontal",
      align: "left",
      // verticalAlign: "bottom",
      // y:30,
      // adjustChartSize: true,
      maxHeight: 50,
      // height:35,
      // maxWidth: 300,
      width: 400,
      itemWidth: 90,
      navigation: {
        // enabled: true,
        activeColor: "#3E576F",
        animation: true,
        arrowSize: 12,
        inactiveColor: "#CCC",
        style: {
          fontWeight: "bold",
          color: "#333",
        },
      },
      // itemMarginTop: 1.5,
      itemMarginBottom: 10,
    },
    plotOptions: {
      pie: {
        // allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.0f} %",
          distance: -30,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Registrations",
        colorByPoint: true,
        states: {
          hover: {
            enabled: true,
          },
          inactive: {
            enabled: false,
          },
        },
        point: haloOptions,
        innerSize: "55%",
        data: dt,
      },
    ],
  };
  useEffect(() => {
    // console.log("Data Chart",dt);
    // const chart = chartComponent.current.chart;
  }, [dt]);
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
}
const kumalaSegment = [
  "FORMO",
  "AIR EV",
  "FORMO MAX",
  "ALVEZ",
  "ALMAZ",
  "CORTEZ",
  "CONFERO"
];
function TableSegment(dt) {
  useEffect(() => {
    // console.log("Table Data",dt);
  }, [dt]);
  return (
    <>
      <TableContainer component={Paper} style={{ maxHeight: "350px" }}>
        <Table
          style={{ minWidth: 380 }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead sx={{backgroundColor: "#287f9d"}}>
            <TableRow>
              <TableCell width={10}>Model</TableCell>
              <TableCell width="100%" colSpan={2}>
                Market
              </TableCell>
              <TableCell width={10}>YoY</TableCell>
              <TableCell width={10}>MoM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dt.dt.map((row) => {
            return kumalaSegment.includes(row.model) ? (
              <StyledTableRow
                key={row.model}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell sx={{background: "rgb(212,207,175)", whiteSpace:"nowrap"}} component="th" scope="row">
                  {row.model}
                </TableCell>
                <TableCell colSpan={2} align="left" sx={{background: "rgb(212,207,175)"}}>
                  <span
                    style={{
                      width: "2.4rem",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {row.market}
                  </span>
                  <div
                    className="d-inline-block"
                    style={{
                      backgroundColor: "rgb(40,127,157)",
                      width: maxWidth(Math.max(...dt.max), row.market),
                      height: "10px",
                    }}
                  ></div>
                  {/* <ProgressBar now={100} style={{width: 100, height: 10, display:"inline-grid"}}/> */}
                </TableCell>
                <TableCell sx={{background: "rgb(212,207,175)"}}>{row.yoy}</TableCell>
                <TableCell sx={{background: "rgb(212,207,175)"}}>{row.mom}</TableCell>
                {/* <TableCell align="right">{row.carbs}</TableCell> */}
              </StyledTableRow>
             ) : (
              <StyledTableRow
                key={row.model}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" sx={{whiteSpace:"nowrap"}}>
                  {row.model}
                </TableCell>
                <TableCell colSpan={2} align="left">
                  <span
                    style={{
                      width: "2.4rem",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {row.market}
                  </span>
                  <div
                    className="d-inline-block"
                    style={{
                      backgroundColor: "rgb(40,127,157)",
                      width: maxWidth(Math.max(...dt.max), row.market),
                      height: "10px",
                    }}
                  ></div>
                  {/* <ProgressBar now={100} style={{width: 100, height: 10, display:"inline-grid"}}/> */}
                </TableCell>
                <TableCell>{row.yoy}</TableCell>
                <TableCell>{row.mom}</TableCell>
                {/* <TableCell align="right">{row.carbs}</TableCell> */}
              </StyledTableRow>
             )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function MovementbyModels({ title, dt, loadState }) {
  const options = {
    chart: {
      backgroundColor: "transparent",
      zoomType: "xy",
      height: 500,
      type: "spline",
    },
    title: {
      text: title,
      style: {
        fontSize: "30px",
      },
      align: "center",
      verticalAlign: "top",
    },
    xAxis: {
      categories: [
        "Jan 22",
        "Feb 22",
        "Mar 22",
        "Apr 22",
        "May 22",
        "Jun 22",
        "Jul 22",
        "Aug 22",
        "Sep 22",
        "Oct 22",
        "Nov 22",
        "Dec 22",
        "Jan 23",
        "Feb 23",
        "Mar 23",
        "Apr 23",
        "May 23",
        "Jun 23",
        "Jul 23",
        "Aug 23",
        "Sep 23",
        "Oct 23",
        "Nov 23",
        "Dec 23",
      ],
    },
    yAxis: [
      {
        visible: false,
      },
    ],
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          borderWidth: 0,
          // textShadow: false,
          align: "center",
          //   y: 0, // 10 pixels down from the top
          style: {
            textOutline: "none",
            fontSize: "10px",
            // textOutline: "0px",
          },
        },
        marker: {
          /* fillColor: '#FFFFFF', */
          lineWidth: 2,
          lineColor: null, // inherit from series
        },
      },
    },
    tooltip: {
      shared: true,
    },
    series: dt.slice(0, 10),
    // series: [
    //   {
    //     name: "2022",
    //     marker: {
    //       symbol: "circle",
    //     },
    //     data: [
    //       29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
    //       95.6, 54.4,
    //     ],
    //   },
    // ],
  };
  useEffect(() => {
    // console.log("Data Chart",dt);
  }, [dt]);
  return (
    <>
      {loadState ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"100%"}
          height={500}
        />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </>
  );
}

function DoVolWuling({ title, series, dt, loadState }) {
  const chartComponent = useRef(null);
  const options = {
    chart: {
      backgroundColor: "transparent",
      // type: "column",
      height: 550,
    },
    title: {
      text: "",
    },
    xAxis: [
      {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        crosshair: false,
      },
    ],
    yAxis: [
      {
        // Primary yAxis
        show: false,
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[2],
          },
        },
        visible: false,
        title: false,
        opposite: true,
      },
      {
        // Secondary yAxis
        visible: false,
        gridLineWidth: 0,
        title: false,
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
      },
      {
        // Tertiary yAxis
        gridLineWidth: 0,
        visible: false,
        title: false,
        min: -10,
        labels: {
          // format: "{value} %",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: false,
      },
    ],
    tooltip: {
      shared: true,
      valueDecimals: 2,
      shadow: false,
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    plotOptions: {
      // dataLabels: {
      //   textShadow: false,
      //   textOutline: "0px",
      //   borderWidth: 0,
      // },
      series: {
        // pointStart: 0,
        borderRadius: "15%",
        pointWidth: 50,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          borderWidth: 0,
          // textShadow: false,
          align: "center",
          //   y: 0, // 10 pixels down from the top
          style: {
            textOutline: "none",
            fontSize: "10px",
            // textOutline: "0px",
          },
        },
      },
      spline: {
        dataLabels: {
          textShadow: false,
          textOutline: "0px",
          borderWidth: 0,
          // format: "{point.y:.1f}",
          format: "{y}",
        },
      },
      column: {
        // stacking: "normal",
        pointPadding: 2,
        // grouping: false,
        shadow: false,
        // groupPadding: 0.05,
        pointWidth: 35,
        padding: 1,
        min: 0,
        dataLabels: {
          borderWidth: 0,
          verticalAlign: "top",
          // y: -20,
        },
      },
    },
    series: [
      {
        name: "Vol 2022",
        type: "column",
        color: "#287F9D",
        yAxis: 1,
        data: dt.data22,
        dataLabels: {
          textShadow: false,
          textOutline: "0px",
        },
        // stack: "before",
        // tooltip: {
        //   valueSuffix: " mm",
        // },
      },
      {
        name: "Vol 2023",
        type: "column",
        color: "#FCC47C",
        yAxis: 1,
        data: dt.data23,
        // stack: "curr",
        tooltip: {
          // valueSuffix: " mm",
        },
      },
      {
        name: "DO 2022",
        type: "spline",
        color: "#A63ED9",
        data: dt.do22,
        yAxis: 2,
        dataLabels: {
          textShadow: false,
          textOutline: "0px",
          borderWidth: 0,
        },
        // center: [375, 365],
        tooltip: {
          // pointFormat: `{series.name}: {point.y} <b>({point.percentage:.1f}%)</b>`,
          shadow: false,
          borderWidth: 0,
          backgroundColor: "rgba(255,255,255,0.8)",
        },
      },
      {
        name: "DO 2023",
        type: "spline",
        color: "#FC8E1C",
        data: dt.do23,
        yAxis: 2,
        marker: {
          symbol: "circle",
        },
        dataLabels: {
          textShadow: false,
          textOutline: "0px",
          borderWidth: 0,
        },
        // center: [375, 365],
        tooltip: {
          // pointFormat: `{series.name}: {point.y} <b>({point.percentage:.1f}%)</b>`,
          shadow: false,
          borderWidth: 0,
          backgroundColor: "rgba(255,255,255,0.8)",
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              floating: false,
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
              x: 0,
              y: 0,
            },
            yAxis: [
              {
                labels: {
                  align: "right",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                labels: {
                  align: "left",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                visible: false,
              },
            ],
          },
        },
      ],
    },
  };

  const lineOptions = {
    chart: {
      type: "spline",
      height: 250,
      backgroundColor: "transparent",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title,
      style: {
        fontSize: "30px",
      },
      align: "center",
      verticalAlign: "top",
    },

    yAxis: {
      min: 0,
      title: false,
      visible: false,
    },

    xAxis: {
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      crosshair: false,
      visible: false,
      //   accessibility: {
      //     rangeDescription: "Range: 2010 to 2020",
      //   },
    },

    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
    },
    plotOptions: {
      gridLineWidth: 0,
      series: {
        label: {
          connectorAllowed: true,
        },
        dataLabels: {
          enabled: true,
          textShadow: false,
          textOutline: "0px",
          allowOverlap: true,
          borderWidth: 0,
          // color: "#FFFFFF",
          align: "center",
          //   y: 0, // 10 pixels down from the top
          style: {
            fontSize: "10px",
          },
        },
        // pointStart: 2010,
      },
      spline: {
        dataLabels: {
          format: "{point.y:.1f}%",
          // format: "{y} %",
        },
      },
    },

    series: [
      {
        name: "MS 2022",
        type: "spline",
        color: "#FC8E1C",
        data: dt.msWuling22,
        format: "{point.y:.1f}%",
        marker: {
          symbol: "circle",
        },
        dataLabels: {
          y: 20,
          textShadow: false,
          textOutline: "0px",
          borderWidth: 0,
        },
      },
      {
        name: "MS 2023",
        data: dt.msWuling23,
        format: "{point.y:.1f}%",
        marker: {
          symbol: "circle",
        },
        dataLabels: {
          y: -10,
          textShadow: false,
          textOutline: "0px",
          borderWidth: 0,
        },
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "vertical",
              align: "center",
              verticalAlign: "top",
            },
          },
        },
      ],
    },
  };
  React.useEffect(() => {
    // console.log("Dt",dt);
    // const chart = chartComponent.current.chart;
  }, []);
  return (
    <>
      {loadState ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"100%"}
          height={500}
        />
      ) : (
        <div className="card">
          <div style={{ marginBottom: "-4rem", overflow: "visible" }}>
            <HighchartsReact
              ref={chartComponent}
              highcharts={Highcharts}
              options={lineOptions}
              // series={series}
            />
          </div>
          <div>
            <HighchartsReact
              ref={chartComponent}
              highcharts={Highcharts}
              options={options}
              // series={series}
            />
          </div>
        </div>
      )}
    </>
  );
}

function SparkLine({ dt }) {
  const month = (a) => {
    //  return console.log("month",this.x);
    return moment(a, "MM").format("MMM");
  };
  /**
   * Create a constructor for sparklines that takes some sensible defaults
   * and merges in the individual chart options. This function is also available
   * from the jQuery plugin as $(element).highcharts('SparkLine').
   */
  Highcharts.SparkLine = function (a, b, c) {
    const hasRenderToArg = typeof a === "string" || a.nodeName;
    let options = arguments[hasRenderToArg ? 1 : 0];
    const defaultOptions = {
      chart: {
        renderTo:
          (options.chart && options.chart.renderTo) || (hasRenderToArg && a),
        backgroundColor: null,
        borderWidth: 0,
        type: "area",
        margin: [2, 0, 2, 0],
        // width: 720,
        height: 20,
        style: {
          overflow: "visible",
        },
        // small optimalization, saves 1-2 ms each sparkline
        skipClone: true,
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tickPositions: [0],
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        hideDelay: 0,
        outside: true,
        shared: true,
        formatter: function () {
          const header = `<span style="color: blue;">${month(
            this.x
          )}</span><br/>
          <span>${this.y.toFixed(2)} %</span>
          `;

          return header;
        },
      },
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 1,
          shadow: false,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          dataLabels: {
            enabled: true,
            allowOverlap: true,
            borderWidth: 0,
            // textShadow: false,
            align: "center",
            format: "{point.y:.1f}%",
            y: -10, // 10 pixels down from the top
            style: {
              textOutline: "none",
              fontSize: "10px",
              color: "black",
              // textOutline: "0px",
            },
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 2,
              },
            },
          },
          fillOpacity: 0.25,
        },
        column: {
          negativeColor: "#910000",
          borderColor: "silver",
          pointWidth: 50,
        },
      },
    };

    options = Highcharts.merge(defaultOptions, options);

    return hasRenderToArg
      ? new Highcharts.Chart(a, options, c)
      : new Highcharts.Chart(options, b);
  };

  const start = +new Date(),
    tds = Array.from(document.querySelectorAll("td[data-sparkline]")),
    fullLen = tds.length;

  let n = 0;

  // Creating 153 sparkline charts is quite fast in modern browsers, but mobile
  // can take some seconds, so we split the input into chunks and
  // apply them in timeouts in order avoid locking up the browser process
  // and allow interaction.
  function doChunk() {
    const time = +new Date(),
      len = tds.length;

    for (let i = 0; i < len; i += 1) {
      const td = tds[i];
      const stringdata = td.dataset.sparkline;
      const arr = stringdata.split("; ");
      const data = arr[0].split(", ").map(parseFloat);
      const chart = {};
      if (arr[1]) {
        chart.type = arr[1];
      }

      Highcharts.SparkLine(td, {
        series: [
          {
            data: data,
            pointStart: 1,
          },
        ],
        chart: chart,
      });

      n += 1;

      // If the process takes too much time, run a timeout
      // to allow interaction with the browser
      if (new Date() - time > 500) {
        tds.splice(0, i + 1);
        setTimeout(doChunk, 0);
        break;
      }

      // Print a feedback on the performance
      // if (n === fullLen) {
      //   document.getElementById("result").innerHTML =
      //     "Generated " +
      //     fullLen +
      //     " sparklines in " +
      //     (new Date() - start) +
      //     " ms";
      // }
    }
  }
  doChunk();
  useEffect(() => {}, [dt]);
  if (dt.length > 0) {
    return (
      <Skeleton
        animation="wave"
        variant="rectangular"
        width={"100%"}
        height={100}
      />
    );
  }
  return (
    <>
      <div id="result"></div>
      <table id="table-sparkline">
        <thead>
          <tr>
            <th></th>
            <th style={{ width: "95rem" }}></th>
          </tr>
        </thead>
        <tbody id="tbody-sparkline">
          <tr style={{ height: "5rem", borderTop: "3px solid gray" }}>
            <th>MoM Wuling Market</th>
            <td data-sparkline={dt.mom.join(", ") + "; column"} />
          </tr>
          <tr style={{ height: "5rem", borderBottom: "3px solid gray" }}>
            <th>YoY Wuling Market</th>
            <td data-sparkline={dt.yoy.join(", ") + "; column"} />
          </tr>
        </tbody>
      </table>
    </>
  );
}

export {
  TableTopBrand,
  TableTopModel,
  ChartMarketSeg,
  SegmentPieChart,
  SegmentDonutChart,
  TableSegment,
  MovementbyModels,
  DoVolWuling,
  SparkLine,
};
