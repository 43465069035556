import * as React from 'react';
import { ThemeProvider, createTheme, useTheme, styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { backgroundColorHSLDarkToLight } from '../../../../../_metronic/helpers/components/MaterialReactTable/ComponentStyleRowsTable';
import { ThemeModeComponent } from '../../../../../_metronic/assets/ts/layout';
import { useThemeMode } from '../../../../../_metronic/partials';
import { totalValue } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';

const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";
export default function SingleLoopTable({
    data,
    backgroundColor
}) {
    data.data?.all_leasing?.map((v) => {
        return data.columns.push(v.leasing === null ? "Null" : v.leasing)
    });
    data.columns.push("Grand Total");
    const { mode } = useThemeMode();
    const calculatedMode = mode === "system" ? systemMode : mode;
    const globalTheme = useTheme();
    const tableTheme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: calculatedMode, //let's use the same dark/light mode as the global theme
                    primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
                    info: {
                        main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            calculatedMode === 'light'
                                ? 'rgb(255,255,255)' //random light yellow color for the background in light mode
                                : '#000', //pure black table in dark mode for fun
                    },
                }
            }),
        [calculatedMode, globalTheme.palette.secondary]
    );

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#fbfcfe',
            fontFamily: 'serif',
            fontSize: 9.5,
            color: theme.palette.common.black,
        }, [`&.${tableCellClasses.body}`]: {
            fontSize: 9.5,
            fontFamily: 'serif',
        },

    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            border: 0,
            fontSize: 9.5,
            fontFamily: 'serif',
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
            fontSize: 9.5,
            fontFamily: 'serif',
        },
    }));

    return (
        <ThemeProvider theme={tableTheme}>
            <TableContainer sx={{
                maxHeight: 250,
                padding: 0,
                fontFamily: 'serif',
                '& tr > *:first-of-type': {
                    position: 'sticky',
                    left: 0,
                    fontSize: 9.5,
                    bgcolor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                },
                '& tr > *:last-child': {
                    position: 'sticky',
                    right: 0,
                    fontSize: 9.5,
                    bgcolor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                },
            }}>
                <Table size="small" aria-label="table with sticky header">
                    <TableHead >
                        <TableRow>
                            <StyledTableCell style={{
                                top: 0,
                                fontSize: 9.5,
                                fontFamily: 'serif',
                                backgroundColor: calculatedMode === 'light' ? '#e0e0e0' : '#1c1c1c',
                                color: calculatedMode === 'light' ? 'black' : 'white',
                                zIndex: 1
                            }}
                                colSpan={data?.columns.length}
                                align='right'>Leasing/Record Count</StyledTableCell>
                        </TableRow>
                        <StyledTableRow>
                            {/* {data['columns'].map(val => { */}
                            {data?.columns?.map((val, i: number) => {
                                return (
                                    <React.Fragment key={i} >
                                        <StyledTableCell style={{
                                            position: "sticky",
                                            top: 32,
                                            fontSize: 9.5,
                                            fontFamily: 'serif',
                                            minWidth: 60,
                                            backgroundColor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                                            color: calculatedMode === 'light' ? 'black' : 'white',
                                            zIndex: `${i === 0 || (i === data?.columns.length - 1) ? 1 : 0}`
                                        }} align="center">{val}</StyledTableCell>
                                    </React.Fragment>
                                )
                            })
                            }
                        </StyledTableRow>

                    </TableHead>
                    <TableBody>
                        {/* START:: LOPING */}
                        {data?.data?.allDp?.map((v, s: number) => {
                            return (
                                <StyledTableRow key={s + "Dps"}>
                                    <StyledTableCell>{v.dp === null ? "Null" : v.dp}</StyledTableCell>
                                    {v?.data?.map((val, i: number) => {
                                        return <React.Fragment key={i + "Dpa"}>
                                            <StyledTableCell style={{ backgroundColor: backgroundColorHSLDarkToLight(backgroundColor, val[`${data?.targetData}`], v[`${data?.max}`]) }}>{val[`${data?.targetData}`]}</StyledTableCell>
                                        </React.Fragment>
                                    })}
                                    <StyledTableCell>{totalValue(v?.data.map(val => val[`${data?.targetData}`]))}</StyledTableCell>
                                </StyledTableRow>
                            )
                        })}
                        {/* END:: LOPING */}
                    </TableBody>
                    {/* <TableFooter sx={{
                        position: "sticky",
                        bottom: 0,
                        fontFamily: 'serif',
                        fontSize: 9.5,
                        backgroundColor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                        color: calculatedMode === 'light' ? 'black' : 'white',
                    }}>
                        <TableRow hover tabIndex={-1}>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif', fontSize: 9.5 }}>Total Keseluruhan</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif', fontSize: 9.5 }} align="center">1.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif', fontSize: 9.5 }} align="center">2.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif', fontSize: 9.5 }} align="center">3.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif', fontSize: 9.5 }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif', fontSize: 9.5 }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif', fontSize: 9.5 }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif', fontSize: 9.5 }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif', fontSize: 9.5 }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif', fontSize: 9.5 }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif', fontSize: 9.5 }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif', fontSize: 9.5 }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif', fontSize: 9.5 }} align="center">1</StyledTableCell>
                        </TableRow>
                    </TableFooter> */}
                </Table>
            </TableContainer>
        </ThemeProvider >
    );
}
