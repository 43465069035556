/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";

import { KTIcon } from "../KTIcon";
import { Link } from "react-router-dom";

type Props = {
  title?: string;
  label?: string;
  linkTo?: string;
  iconName: string;
  loading?: boolean;
  onClick?: () => void;
};

const ButtonSaveChange: FC<Props> = ({
  label,
  iconName = "",
  loading = false,
  onClick,
}) => {
  return (
    <button
      type="button"
      className="btn btn-primary me-2 px-6"
      disabled={loading}
      onClick={onClick}
    >
      {!loading && (
        <>
          <KTIcon iconName={iconName} className={"fs-4 me-2"} />
          {label}
        </>
      )}
      {loading && (
        <span className="indicator-progress" style={{ display: "block" }}>
          Please wait...{" "}
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      )}
    </button>
  );
};

const ButtonAction: FC<Props> = ({
  title,
  linkTo = "",
  iconName = "",
  onClick,
}) => {
  return (
    <div className="d-flex justify-content-center flex-shrink-0">
      {linkTo !== "" ? (
        <>
          {/* <div className="d-flex justify-content-center flex-shrink-0"> */}
          <Link
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            title={title}
            to={linkTo}
            onClick={onClick}
          >
            <KTIcon iconName={iconName} className="fs-3" />
          </Link>
          {/* </div> */}
        </>
      ) : (
        <>
          {/* <div className="d-flex justify-content-center flex-shrink-0"> */}
          <a
            href={"#"}
            title={title}
            className={
              "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            }
            onClick={onClick}
          >
            <KTIcon iconName={iconName} className="fs-3" />
          </a>
          {/* </div> */}
        </>
      )}
    </div>
  );
};

type PropsSwitchStatus = {
  name?: string;
  values;
  onChange: (e) => void;
};
const SwitchStatusAction: FC<PropsSwitchStatus> = ({
  name,
  values,
  onChange,
}) => {
  return (
    <div className="d-flex justify-content-center flex-shrink-0">
      <label className="form-check form-switch form-check-custom form-check-solid ms-3">
        <input
          style={{ cursor: "pointer" }}
          className="form-check-input h-30px w-50px"
          type="checkbox"
          value={values}
          defaultChecked={values}
          name={name}
          onChange={(e) => {
            onChange(e);
          }}
        />
      </label>
    </div>
  );
};

export { ButtonSaveChange, ButtonAction, SwitchStatusAction };
