/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { PriceSegmentAPI } from "../../../../api/MarketShareAPI";
import {  PrbyTypes } from "../PriceSegmentHelper";

const PriceByTypes = ({ val }) => {
  const [loading, setLoading] = useState(true);
  const [priceRangeType, setPriceRangeType] = useState([]);
  async function getData() {
    setLoading(true);
    await new PriceSegmentAPI().priceRangeType(val).then((res) => {
      setPriceRangeType(res.response);
    }).then(() => setLoading(false));
  }
  useEffect(() => {
    getData();
  }, [val]);

  return (
    <>
      <PrbyTypes dt={priceRangeType} loadState={loading}/>
    </>
  );
};

export default PriceByTypes;
