import * as React from 'react';
import { ThemeProvider, makeStyles, createTheme, useTheme, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { TableFooter } from '@mui/material';
import { backgroundColorHSLDarkToLight } from '../../../../../_metronic/helpers/components/MaterialReactTable/ComponentStyleRowsTable';
import { ThemeModeComponent } from '../../../../../_metronic/assets/ts/layout';
import { useThemeMode } from '../../../../../_metronic/partials';

const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";
export default function DataTable({
    maxHeight,
    data
}) {
    const { mode } = useThemeMode();
    const calculatedMode = mode === "system" ? systemMode : mode;
    const globalTheme = useTheme();
    const tableTheme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: calculatedMode,  //let's use the same dark/light mode as the global theme
                    primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
                    info: {
                        main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            calculatedMode === 'light'
                                ? 'rgb(255,255,255)' //random light yellow color for the background in light mode
                                : '#000', //pure black table in dark mode for fun
                    },
                }
            }),
        [calculatedMode, globalTheme.palette.secondary]
    );

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#455a64',
            fontFamily: 'serif',
            fontSize: 9.5,
        }, [`&.${tableCellClasses.body}`]: {
            fontSize: 9.5,
            fontFamily: 'serif',
        },

    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            border: 0,
            fontSize: 9.5,
            fontFamily: 'serif',
            color: calculatedMode === 'light' ? 'black' : 'white',
        },
        // hide last border
        '&:last-child(2) td': {
            backgroundColor: theme.palette.action.hover,
            border: 0,
            fontSize: 9.5,
            fontFamily: 'serif',
            color: calculatedMode === 'light' ? 'black' : 'white',
        },
    }));

    return (
        <ThemeProvider theme={tableTheme}>
            <TableContainer sx={{
                maxHeight: maxHeight,
                padding: 0,
                fontFamily: 'serif',
            }}>
                <Table size="small" aria-label="table with sticky header">
                    <TableHead >
                        <TableRow>
                            {data['columns'].map((val, i) => {
                                return (
                                    <React.Fragment key={i} >
                                        <StyledTableCell key={i} style={{
                                            top: 0,
                                            fontSize: 9.5,
                                            fontFamily: 'serif',
                                            backgroundColor: '#455a64',
                                            color: 'white',
                                            zIndex: 1
                                        }} align='center'>{val['label']}</StyledTableCell>
                                    </React.Fragment>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* START:: LOPING */}
                        {data['rows'].map((row, i) => {
                            return (
                                <React.Fragment key={i} >
                                    <StyledTableRow hover tabIndex={-1} key={i}>
                                        {data['columns'].map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <StyledTableCell
                                                key={column.id}
                                                    align={column['align']}
                                                    style={{
                                                        fontSize: 10,
                                                        fontFamily: 'serif',
                                                    }}>{value}</StyledTableCell>
                                            );
                                        })}
                                    </StyledTableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                    <TableFooter sx={{
                        position: "sticky",
                        bottom: 0,
                        fontFamily: 'serif',
                        fontSize: 9.5,
                        backgroundColor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                        color: calculatedMode === 'light' ? 'black' : 'white',
                    }}>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell colSpan={3} style={{ fontWeight: "bold", fontFamily: 'serif' }} align="right">Total Keseluruhan</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", }} align="center">53</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", }} align="center">16</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", }} align="center">3</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", }} align="center">508</StyledTableCell>
                        </StyledTableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </ThemeProvider >
    );
}
