import { fetchingGetData, multiPartFilesFetching } from "./API";

export class CreditProcessAPI {
  //! Start Menu: Dashboard
  // All Bar Information : Application In, Processed, Approve,Reject, Reject before Processing & In Process
  async getAllBarInformation(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  // Approval Rate and Rejection Rate by Outlet
  async getApprovalRateAndRejectionRateByOutlet(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  // Reject Before Processing by Outlet
  async getRejectBeforeProcessingByOutlet(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  // Reject Before Processing by Type
  async getRejectBeforeProcessingByType(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  // Lead Time by Outlet
  async getLeadTimeByOutlet(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  // Approval by DP Application
  async getApprovalByDPApplication(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  // MoM Approval rate and Rejection rate
  async getMomApprovalRateAndRejectionRate(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  //! End Menu: Dashboard
  //--------------------------------------------------------------------------------------------------------
  //! Start Menu: MTD Process
  // Leasing Table 1
  async getLeasing1(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  // Leasing Table 2
  async getLeasing2(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  // Status Process Table 1
  async getStatusProcess1(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  // Status Process Table 2
  async getStatusProcess2(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  // In Process by Model 1
  async getInProcessByModel1(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  // In Process by Model 2
  async getInProcessByModel2(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  //! End Menu: MTD Process
  //--------------------------------------------------------------------------------------------------------
  //! Start Menu: Process Journey

  //! End Menu: Process Journey
  //--------------------------------------------------------------------------------------------------------
  //! Start Menu: Leads Quality

  //! End Menu: Leads Quality
  //--------------------------------------------------------------------------------------------------------
  //! Start Menu: Reject

  //! End Menu: Reject
  //--------------------------------------------------------------------------------------------------------
  //! Start Menu: Approval
  // DO Leasing Comparison
  async getDoLeasingComparison(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  //! End Menu: Approval
  //**Begin:: Get Select Data */
  async getFilter(values: any) {
    let res = await fetchingGetData({
      url: `select/mtd/performance?asm=${values['asm'] || ""}&sm=${values['sm'] || ""}&outlet=${values['outlet'] || ""}`,
      method: "get",
    });
    return res;
  }
  // Get Month
  async getMonth() {
    let res = await fetchingGetData({
      url: `select/national-information-gaikindo?tipe=getMonth`,
      method: "get",
    });
    return res;
  }

  async getDataUpload() {
    let res = await fetchingGetData({
      url: `credit_process`,
      method: "get",
    });
    return res;
  }

  async uploadFilesCreditProcess(file: any, onUploadProgress: any) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `credit_process/upload`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }
}
