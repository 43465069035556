/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import { Component } from "react";

import * as Yup from "yup";

import { Formik } from "formik";

import { KTCard, KTCardBody, KTIcon } from "../../../../_metronic/helpers";
import DataTableMuiModel from "../../../models/DataTable/dataTableModal";
import { PageTitle } from "../../../../_metronic/layout/core";
import { MaterialReactTableSimple } from "../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable";
import { ButtonAction, SwitchStatusAction } from "../../../../_metronic/helpers/components/form-actions/FormButton";
import { InputCheckbox, InputSelect2, InputText } from "../../../../_metronic/helpers/components/form-actions/FormInput";
import { FormModal } from "../../../../_metronic/helpers/components/form-actions/ModalForm";
import { MasterAPI } from "../../../api/Master";
import UserLoginModel from "../../../models/Master/UserLogin/userLoginModel";
import { Box } from "@mui/material";

function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 1000));
}

export class UserLogin extends Component {
  constructor(props) {
    super(props);
    this["title"] = "User Login";
    this["api"] = new MasterAPI();
    this["initialData"] = new UserLoginModel();
    this["option_nama_karyawan"] = [];
    this["option_brand"] = [];
    this["option_roles"] = [];
    this["datatable"] = [];
    this["columntable"] = [];
    this["state"] = {
      showHide: false,
      isLoading: true,
      listPerusahaan: [],
      modal: {
        loading: false,
        show: false,
        initialData: this["initialData"],
        isEdit: false,
      },
    };
  }
  //** Start: Component */
  async componentDidMount() {
    await this.LoadData();
  }
  //** End: Component */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Data List */
  async LoadData() {
    this.setState({ loading: true });
    let data = await this["api"].getDataUserLogin();
    this["DataTableMuiModel"] = new DataTableMuiModel(this.props);
    data["data"].map((item: any, i: number) => {
      let dataModel = new UserLoginModel(item);
      this["DataTableMuiModel"].add({
        id: i,
        no: i + 1,
        dataModel: dataModel,
        actions: (
          <>
            <div className="d-flex justify-content-center flex-shrink-0">
              <ButtonAction
                title={"Edit"}
                iconName="pencil"
                onClick={() => this["handleModal"].edit(dataModel)}
              />
              <SwitchStatusAction
                name={"status"}
                values={item["status"] === 1 ? true : false}
                onChange={async (e) => {
                  let values = e.target.checked;
                  await this["api"].updateStatusUsers({ id: item["id"], status: values ? 1 : 0 });
                  setTimeout(() => {
                    this.LoadData();
                  }, 2000);
                }} />
            </div>
          </>
        ),
      });
      return item;
    });
    this["datatable"] = await this["DataTableMuiModel"].getDatas();
    this["columntable"] = await this["DataTableMuiModel"].getColumn();
    this.setState({ ...this["state"], loading: false });
  }
  //** End: Load Data List */

  //** Begin:: Handle Modal && Alert */
  handleModal = {
    loadOptionNamaKaryawan: async (value) => {
      this.setState({ isLoading: true }, () => {
        simulateNetworkRequest().then(() => {
          this["api"].getSelectKaryawan(value).then((res: any) => {
            this["option_nama_karyawan"] = res["data"].map((val: string) => {
              return {
                value: val["id"],
                label: val["text"],
                nik: val["nik"],
                id_perusahaan: val["id_perusahaan"],
                email: val["email"],
              };
            });
            this.setState({ isLoading: false });
          });
        });
      });
    },
    loadOptionBrand: async () => {
      this.setState({ isLoading: true }, () => {
        simulateNetworkRequest().then(() => {
          this["api"].getSelectMasterBrand().then((res: any) => {
            this["option_brand"] = res["data"].map((val: string) => {
              return {
                value: val["id"],
                label: (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                      }}
                    >
                      <img
                        alt="logos"
                        height={20}
                        src={val["logos"]}
                        loading="lazy"
                        style={{ borderRadius: '50%' }}
                      />
                      <span style={{ marginLeft: "5px", textTransform: "capitalize" }}>
                        {val["text"]}
                      </span>
                    </Box>

                  </>
                ),
                // label: val["text"],
              };
            });
            this.setState({ isLoading: false });
          });
        });
      });
    },
    loadOptionRoles: async () => {
      this.setState({ isLoading: true }, () => {
        simulateNetworkRequest().then(() => {
          this["api"].getSelectRoles().then((res: any) => {
            this["option_roles"] = res["data"].map((val: string) => {
              return {
                value: val["id"],
                label: val["text"],
              };
            });
            this.setState({ isLoading: false });
          });
        });
      });
    },
    loadOptionPerusahaan: async (values: any) => {
      this.setState({ isLoading: true }, () => {
        simulateNetworkRequest().then(() => {
          this["api"].getSelectPerusahaan(values).then((res: any) => {
            this.setState({ isLoading: false, listPerusahaan: res['data'] });
          });
        });
      });
    },
    tambah: async () => {
      let state = { ...this["state"] };
      state = {
        modal: {
          show: true,
          title: `Tambah ${this["title"]}`,
          isEdit: false,
          initialData: this["initialData"],
        },
      };
      this["handleModal"].loadOptionNamaKaryawan("add");
      this["handleModal"].loadOptionBrand();
      this["handleModal"].loadOptionRoles();
      this["setState"]({ showHide: false });
      this["handleModal"].loadOptionPerusahaan("");
      this.setState({ ...state });
    },
    edit: async (dataFromModel: any) => {
      let state = { ...this["state"] };
      state = {
        modal: {
          show: true,
          title: `Edit ${this["title"]}`,
          isEdit: true,
          initialData: dataFromModel,
        },
      };
      if (dataFromModel['id_brand'] !== null) {
        this["setState"]({ showHide: true });
        this["handleModal"].loadOptionPerusahaan(dataFromModel['id_brand']);
      } else {
        this["setState"]({ showHide: false });
        this["handleModal"].loadOptionPerusahaan("");
      }
      this["handleModal"].loadOptionNamaKaryawan("edit");
      this["handleModal"].loadOptionBrand();
      this["handleModal"].loadOptionRoles();
      this.setState({ ...state });
    },
    hide: () => {
      let state = { ...this["state"] };
      state = {
        modal: {
          show: false,
          initialData: this["initialData"],
        },
      };
      this.setState({ ...state });
    }
  };
  //** End:: Handle Modal && Alert */
  render() {
    return (
      <>
        <PageTitle breadcrumbs={[{ title: 'Master', path: '/user-login', isSeparator: false, isActive: false }]}>{this["title"]}</PageTitle>
        {/* Begin:: Card */}
        <KTCard>
          <div className="card-header border-0 pt-6">
            {/* Card Title */}
            <div className="card-title"></div>
            {/* Begin:: Card Toolbar */}
            <div className="card-toolbar">
              <div
                className="d-flex justify-content-end"
                data-kt-user-table-toolbar="base"
              >
                <button
                  type={"button"}
                  className={"btn btn-sm btn-light-primary"}
                  onClick={() => {
                    this["handleModal"].tambah();
                  }}
                >
                  <KTIcon iconName={"plus"} className={"fs-4 me-2"} /> Tambah
                </button>
              </div>
            </div>
            {/* End:: Card Toolbar */}
          </div>
          {/* Begin:: Card Body */}
          <KTCardBody className="py-4">
            <div className="table-responsive">
              <MaterialReactTableSimple
                data={this["datatable"]}
                columns={this["columntable"]}
                isLoading={this["state"]["loading"]}
              />
              {/* <MUIDataTableStandar data={datas} columns={columns} /> */}
            </div>
          </KTCardBody>
          {/* End:: Card Body */}
        </KTCard>
        {/* End:: Card */}

        {/* Begin:: Modal */}
        <Formik
          enableReinitialize={true}
          initialValues={this["state"]["modal"]["initialData"]}
          validationSchema={Yup.object().shape({
            id_karyawan: Yup.string().required("Nama Karyawan/User is required"),
            username: Yup.string().required("Username is required"),
            id_roles: Yup.string().required("Roles is required"),
            id_brand: Yup.string().required("Brand is required"),
          })}
          onSubmit={async (values, { resetForm }) => {
            const data = {
              id_karyawan: values["id_karyawan"],
              id_perusahaan: values["id_perusahaan"],
              id_brand: values["id_brand"],
              email: values["email"],
              nama: values["nama"],
              username: values["username"],
              password: values["nik"],
              id_roles: values["id_roles"],
              coverage: values["coverage"]
            };
            if (this["state"]["modal"]['isEdit']) {
              // Update Data
              const dataSend = { id_user: values["id"], ...data, }
              await this["api"].updateUserLogin(dataSend);
              await this["api"].setUpdateCoverage(dataSend);
            } else {
              // Insert data
              await this["api"].setUserLogin(data).then(async (result: any) => {
                const dataSendCoverage = { id_user: result['data']["id_user"], id_brand: values['id_brand'], coverage: values["coverage"] }
                await this["api"].setUpdateCoverage(dataSendCoverage);
              });
            }
            this['handleModal'].hide();
            resetForm();
            setTimeout(() => {
              this.LoadData();
            }, 2000);
          }}
        >
          {(formik) => (
            <FormModal
              title={this["state"]["modal"]["title"]}
              show={this["state"]["modal"]["show"]}
              onHide={this["handleModal"]["hide"]}
              onConfirm={formik.handleSubmit}
              size={`${this["state"]['showHide'] ? "mw-900px" : "lg"}`}
              content={
                <>
                  <div className="row mb-1">
                    <div className={`${this["state"]['showHide'] ? "col-lg-6" : ""}`}>
                      <InputSelect2
                        name="id_karyawan"
                        label={"Nama Karyawan/User"}
                        required
                        options={this["option_nama_karyawan"]}
                        isClearable
                        isSearchable
                        formik={formik}
                        onChange={(e: number) => {
                          const values = e?.["value"];
                          if (values) {
                            formik.setFieldValue("nik", e?.["nik"]);
                            formik.setFieldValue("id_perusahaan", e?.["id_perusahaan"]);
                            formik.setFieldValue("email", e?.["email"]);
                            formik.setFieldValue("nama", e?.["label"]);
                          } else {
                            formik.setFieldValue("nik", "");
                            formik.setFieldValue("id_perusahaan", "");
                            formik.setFieldValue("nama", "");
                            formik.setFieldValue("email", "");
                          }
                        }}
                      />
                      <InputText
                        name="nik"
                        label={"NIK"}
                        disabled
                        formik={formik}
                      />
                      <InputText
                        name="username"
                        label={"Username"}
                        required
                        formik={formik}
                      />
                      <InputSelect2
                        name="id_roles"
                        label={"Role"}
                        required
                        options={this["option_roles"]}
                        isClearable
                        isSearchable
                        formik={formik}
                      />
                      <InputSelect2
                        name="id_brand"
                        label={"Brand"}
                        required
                        options={this["option_brand"]}
                        isClearable
                        isSearchable
                        formik={formik}
                        onChange={async (e: number) => {
                          const id_brand = e?.["value"];
                          if (id_brand) {
                            this["setState"]({ showHide: true });
                            this["handleModal"].loadOptionPerusahaan(id_brand);
                            let data = await this["api"].getDataUserLoginByIdBrand({ id: formik['values']['id'], id_brand: id_brand });
                            data["data"].map((item: any, i: number) => {
                              formik.setFieldValue("coverage", item['coverage']);
                              return item;
                            });
                            formik.setFieldValue("id_brand", id_brand);
                          } else {
                            this["setState"]({ showHide: false });
                            this["handleModal"].loadOptionPerusahaan("");
                          }
                        }}
                      />
                    </div>
                    {this["state"]['showHide'] && (
                      <>
                        <div className="col-lg-6">
                          {this["state"]['listPerusahaan'].map((val: any) => {
                            return (
                              <>
                                <InputCheckbox
                                  name={`coverage`}
                                  id={`checkbox-${val['id_perusahaan']}`}
                                  title={val['nama_perusahaan']}
                                  values={val['id_perusahaan']}
                                  formik={formik}
                                />
                              </>
                            )
                          })}
                        </div>
                      </>
                    )
                    }
                  </div>
                </>
              }
            />
          )}
        </Formik>
        {/* End:: Modal */}
      </>
    );
  }
}

export default UserLogin;
