import React, { Fragment, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from '@mui/material/styles';
// import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from "@mui/material/TableRow";
import { Paper, Skeleton, TableFooter } from "@mui/material";
// import LinearProgress from "@mui/material/LinearProgress";
// import InfiniteScroll from "react-infinite-scroll-component";
import TableContainer from "@mui/material/TableContainer";
import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(174,191,200)",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
function maxWidth(max, val) {
  let maxs = (val / max) * 85;
  return `${Math.ceil(maxs)}%`;
}

function TablePriceSegment({ title, dt }) {
  useEffect(() => {}, [dt]);
  return (
    <>
      <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 fs-xxl-5 fs-xl-3 mb-1">
            {title}
          </span>
        </h3>
      </div>
      <TableContainer component={Paper} style={{ maxHeight: "350px" }}>
        <Table sx={{}} size="" aria-label="a dense table">
          <TableHead
            sx={{
              position: "sticky",
              top: "0px",
              backgroundColor: "rgb(49,96,118)",
            }}
          >
            <TableRow>
              <TableCell sx={{ color: "white" }} width="25%">
                Range
              </TableCell>
              <TableCell sx={{ color: "white" }} width="60%">
                Vol
              </TableCell>
              <TableCell sx={{ color: "white" }} width="15%">
                %
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dt.map((row) => (
              <StyledTableRow
                key={row.priceRange}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.priceRange}
                </TableCell>
                <TableCell colSpan={0} align="left">
                  <span
                    style={{
                      width: "2.6rem",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {row.vol}
                  </span>
                  <div
                    className="d-inline-block"
                    style={{
                      backgroundColor: "rgb(40,127,157)",
                      width: maxWidth(Math.max(...row.totalMax), row.vol),
                      height: "10px",
                    }}
                  ></div>
                  {/* <ProgressBar now={100} style={{width: 100, height: 10, display:"inline-grid"}}/> */}
                </TableCell>
                <TableCell align="right">{row.percent} %</TableCell>
                {/* <TableCell align="right">{row.carbs}</TableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
function TableWulingModel({ title, dt }) {
  useEffect(() => {}, [dt]);
  return (
    <>
      <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 fs-xxl-5 fs-xl-3 mb-1">
            {title}
          </span>
        </h3>
      </div>
      <TableContainer component={Paper} style={{ maxHeight: "350px" }}>
        <Table sx={{}} size="small" aria-label="a dense table">
          <TableHead
            sx={{ position: "sticky", top: "0px", backgroundColor: "gray" }}
          >
            <TableRow>
              <TableCell width="25%">Model</TableCell>
              <TableCell width="25%">Range</TableCell>
              <TableCell width="100%" align="center">
                Vol
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dt.map((val, i) => (
              <Fragment key={val.name}>
                <TableRow
                  // key={val.name + i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell rowSpan={val.data.length + 1}>
                    {val.name}
                  </TableCell>
                </TableRow>
                {val.data.map((row, i) => (
                  <Fragment key={i}>
                    {row.price_range ? (
                      <StyledTableRow hover>
                        <TableCell>{row.price_range}</TableCell>
                        <TableCell colSpan={0} align="left">
                          <span
                            style={{
                              width: "2.4rem",
                              display: "inline-block",
                              verticalAlign: "top",
                            }}
                          >
                            {row.vol}
                          </span>
                          <div
                            className="d-inline-block"
                            style={{
                              backgroundColor: "rgb(40,127,157)",
                              width: maxWidth(Math.max(...row.totals), row.vol),
                              height: "10px",
                            }}
                          ></div>
                        </TableCell>
                      </StyledTableRow>
                    ) : (
                      <StyledTableRow key={row.total}>
                        <TableCell>Total</TableCell>
                        <TableCell colSpan={2} align="right">
                          {row.total}
                        </TableCell>
                      </StyledTableRow>
                    )}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </TableBody>
          <TableFooter
            sx={{
              position: "sticky",
              bottom: "0px",
              backgroundColor: "white",
            }}
          >
            <TableRow>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "12px", color: "black" }}
                align="left"
                colSpan={2}
              >
                Grand Total
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "12px", color: "black" }}
                align="right"
              >
                {dt
                  .map((val) => val.totals.map((row) => row.total))
                  .flat()
                  .reduce((a, b) => a + b, 0)}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

function PrbyBrands({ dt, loadState }) {
  // function tableRScolor(val) {
  //   if (val < 70) {
  //     return "#F4C7C3";
  //   } else {
  //     return "#00897B";
  //   }
  // }
  useEffect(() => {}, [dt]);

  return (
    <>
      <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 fs-xxl-5 fs-xl-3 mb-1">
            Price Range by Brands
          </span>
        </h3>
      </div>
      {loadState ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"100%"}
          height={500}
        />
      ) : (
        <TableContainer component={Paper} style={{ maxHeight: "500px" }}>
          <Table>
            <TableHead
              sx={{
                position: "sticky",
                top: "0px",
                backgroundColor: "rgb(174,191,200)",
                zIndex: 2,
              }}
            >
              <TableRow>
                <TableCell>Range</TableCell>
                {dt[0].brand.map((val) => (
                  <TableCell key={val}>{val}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {console.log(Object.entries(dt[0].data))} */}
              {Object.entries(dt[0].data).map((row, i) => (
                <Fragment key={i}>
                  <TableRow hover>
                    <TableCell
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row[0]}
                    </TableCell>
                    {row[1].vol.map((val) => (
                      <TableCell
                        key={(Math.random() + 1).toString(36).substring(7)}
                      >
                        {val ? val : "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
            <TableFooter
              sx={{
                position: "sticky",
                bottom: "0px",
                backgroundColor: "white",
              }}
            >
              {/* <TableRow>
                <TableCell
                  colSpan={1}
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "black",
                  }}
                  align="left"
                >
                  Grand Total
                </TableCell>
                {dt[0].total.map((val) => (
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "black",
                    }}
                    align="center"
                  >
                    {val}
                  </TableCell>
                ))}
              </TableRow> */}
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
function PrbyTypes({ dt, loadState}) {
  // function tableRScolor(val) {
  //   if (val < 70) {
  //     return "#F4C7C3";
  //   } else {
  //     return "#00897B";
  //   }
  // }
  useEffect(() => {}, [dt]);

  return (
    <>
      <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 fs-xxl-5 fs-xl-3 mb-1">
            Price Range by Type
          </span>
        </h3>
      </div>
      {loadState ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"100%"}
          height={500}
        />
      ) : (
        <TableContainer component={Paper} style={{ maxHeight: "500px" }}>
          <Table>
            <TableHead
              sx={{
                position: "sticky",
                top: "0px",
                backgroundColor: "rgb(174,191,200)",
                zIndex: 2,
              }}
            >
              <TableRow>
                <TableCell>Range</TableCell>
                <TableCell>Type</TableCell>
                {dt[0].brand.map((val) => (
                  <TableCell key={val}>{val}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(dt[0].data).map((row, i) => (
                <Fragment key={row[0] + i}>
                  <TableRow>
                    <TableCell
                      style={{
                        whiteSpace: "nowrap",
                        position: "sticky",
                        zIndex: 1,
                        backgroundColor: "white",
                        verticalAlign: "top",
                        top: "35px",
                      }}
                      rowSpan={Object.entries(row[1]).length + 1}
                    >
                      {row[0]}
                    </TableCell>
                  </TableRow>
                  {Object.entries(row[1]).map((val) => (
                    <TableRow hover key={val[0]}>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {val[0]}
                      </TableCell>
                      {val[1].vol.map((val) => (
                        <TableCell
                          key={(Math.random() + 1).toString(36).substring(8)}
                        >
                          {val ? val : "-"}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </Fragment>
              ))}
            </TableBody>
            {/* <TableFooter>
            <TableRow>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "12px", color: "black" }}
                align="left"
              >
                Grand Total
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "12px", color: "black" }}
                align="center"
              >
                88
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "12px", color: "black" }}
                align="center"
              >
                55
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "12px", color: "black" }}
                align="center"
              >
                120
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "12px", color: "black" }}
                align="center"
              >
                34
              </TableCell>
            </TableRow>
          </TableFooter> */}
          </Table>
        </TableContainer>
      )}
    </>
  );
}


function PrDetails({ dt, loadState}) {
  const findLength = (v) => {
    let res = dt[0].count.filter((val) => {
      return val.kab === v ? val.count : "";
    });
    return res[0].count;
  };

  const lengthKec = (v) => {
    let res = dt[0].countKec.filter((val) => {
      return val.kec === v ? val.count : "";
    });
    // console.log("Length Kecamatan",res);
    return res[0].count;
  };

  useEffect(() => {
  }, [dt]);
  
  if (loadState) {
    return  <Skeleton
    animation="wave"
    variant="rectangular"
    width={"100%"}
    height={500}
    />
  }

  return (
    <>
     <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
      <Table sx={{ minWidth: 700, overflow:"scroll" }} aria-label="customized table">
        <TableHead 
        sx={{
          position: "sticky",
          top: "0px",
          backgroundColor: "rgb(174,191,200)",
          zIndex: 2,
        }}
        >
          <TableRow>
            <StyledTableCell>Kab</StyledTableCell>
            <StyledTableCell align="left">Kec</StyledTableCell>
            <StyledTableCell align="left" sx={{whiteSpace: "nowrap"}}>Price Range</StyledTableCell>
            <StyledTableCell align="left">Type</StyledTableCell>
            {dt[0].brand.map((val) => (
            <StyledTableCell key={val} align="right">{val}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {dt[0].data.map((val,i) => {
            return (
              <TableRow key={i} hover>
                <StyledTableCell>{val.kab}</StyledTableCell>
                <StyledTableCell>{val.kec}</StyledTableCell>
                <StyledTableCell sx={{whiteSpace: "nowrap"}}>{val.price_range}</StyledTableCell>
                <StyledTableCell>{val.tidy_type}</StyledTableCell>
                {val.vol.map((v) => (
                  <StyledTableCell key={(Math.random() + 1).toString(36).substring(5)}>{v}</StyledTableCell>
                ))}
              </TableRow>
            )
            })}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

export {
  TablePriceSegment,
  TableWulingModel,
  PrbyBrands,
  PrbyTypes,
  PrDetails,
};
