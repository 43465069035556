import { useEffect, useRef } from 'react'
import { PerformanceAPI } from '../api/PerformanceAPI';

const TypesFilter = () => {
    const typesData = useRef([]);

    useEffect(() => {
        const getTypes = async () => {
            const res = await new PerformanceAPI().filterType()
            if (res && res.data) {
                typesData.current = res.data.map((val: { text: string; id: string | number }) => {
                    return ({
                        label: val.text,
                        value: val.id
                    })
                })
            };
        }
        getTypes();
    }, []);

    return typesData.current
}

export default TypesFilter