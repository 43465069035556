import React, { useEffect, useMemo, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core';
import { KTCard, KTCardBody } from '../../../../_metronic/helpers';
import { Form, Formik } from 'formik';
import FilterMultiSelect, { Option, SingleSelect } from '../../../../_metronic/helpers/components/form-actions/FilteringInput';
import moment from "moment";
import { HighchartsSample } from '../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample';
import { MaterialReactTableDetails } from '../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';
import MultiLoopTable from './dataTable';
import { MmeAPI } from '../../../api/MmeAPI';
import { PerformanceAPI } from '../../../api/PerformanceAPI';
import { equalsCheck, getDate } from '../../../../_metronic/helpers/helpers-data/AssetHelpers';

type selectType = Array<{ label: string; value: string | number }>

type dataType = {
    sm: string;
    days: number[];
    date: string;
};
type initialValues = {
    month: number[];
    asm: string[];
};

const MmeIndex = (props: any) => {
    const date = getDate();
    const [initialValues] = useState<initialValues>({ asm: [], month: [] });
    const [loading, setloading] = useState<boolean>(true);
    const [week] = useState<any>(['W1', 'W2', 'W3', 'W4', 'W5']);
    const [smDOvActual, setSmDOvActual] = useState<any>();
    const [smSPKvActual, setSmSPKvActual] = useState<any>();
    const [tablesmDOvForecast, setTableSmDOvForecast] = useState<any>();
    const [tablesmSPKvForecast, setTableSmSPKvForecast] = useState<any>();
    const [doForecastActual, setdoForecastActual] = useState<any>();
    const [tableDoForecast, settableDoForecast] = useState<any>();
    // Approval Rate and Rejection Rate by Outlet
    const [asmVal, setasmVal] = useState<string[]>([]);
    const [smVal, setsmVal] = useState<string[]>([]);
    const [outletVal, setoutletVal] = useState<string[]>([]);
    const [asmOpt, setasmOpt] = useState<selectType>([]);
    const [smOpt, setsmOpt] = useState<selectType>([]);
    const [outletOpt, setoutletOpt] = useState<selectType>([]);
    const [arrSelect, setArrSelect] = useState<Option[] | null>([]);
    const [smSelect, setSmSelect] = useState<Option[] | null>([]);
    const [outletSelect, setOutletSelect] = useState<Option[] | null>([]);
    const [cov, setCoverage] = useState<string>("");
    const monthOpt = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
    ];
    const [month, setMonth] = useState<Option[]>([monthOpt[date.currMonth]]);
    const [slcMonth, setSlcMonth] = useState<number[]>([date.currMonth + 1]);

    // API:: Filter
    async function getFilter() {
        let val = {
            asm: asmVal.slice(),
            sm: smVal.slice(),
            outlet: outletVal.slice(),
        };
        return await new PerformanceAPI().getFilter(val).then((res) => {
            let tempAsm: selectType = [];
            let tempsm: selectType = [];
            let tempoutlet: selectType = [];
            res.data.dataAsm.map((val: { area: string; id_team_asm: string | number }) => {
                return tempAsm.push({
                    label: val.area,
                    value: val.id_team_asm,
                });
            });
            res.data.dataSm.map((val: { region: string; id_team_sm: string | number }) => {
                return tempsm.push({
                    label: val.region,
                    value: val.id_team_sm,
                });
            });
            res.data.dataOutlet.map((val: { text: string; id_outlet: string | number }) => {
                return tempoutlet.push({
                    label: val.text,
                    value: val.id_outlet,
                });
            });
            setasmOpt(tempAsm);
            setsmOpt(tempsm);
            setoutletOpt(tempoutlet);
            setCoverage(res.data.coverage);
            return res.data.coverage
        }).then((val) => {
            let monthFilter =
            slcMonth.length > 0 ? slcMonth.toString() : (date.currMonth + 1).toString();
            getData(val, monthFilter);
        });
    }
    // API:: MME
    const getData = (cov: string, m: number | string) => {
        Promise.all([
            new MmeAPI().getSMdoForecast(cov, m).then((res) => {
                setSmDOvActual(res.data)
            }),
            new MmeAPI().getSmSpkForecast(cov, m).then((res) => {
                setSmSPKvActual(res.data)
            }),
            new MmeAPI().getTableSMdoForecast(cov, m).then((res) => {
                setTableSmDOvForecast(res.data)
            }),
            new MmeAPI().getTableSMspkForecast(cov, m).then((res) => {
                setTableSmSPKvForecast(res.data)
            }),
            new MmeAPI().getDOForecast(cov, m).then((res) => {
                setdoForecastActual(res.data)
            }),
            new MmeAPI().getTableDOForecast(cov, m).then((res) => {
                settableDoForecast(res.data)
            }),
        ]).then((val) => console.log("Done", val));
        // setLoading(false))
    }

    useEffect(() => {
        getFilter();
        // getData();
        // fetchingCreditProcessAPI(initialValues);
        // fetchingMmeAPI(initialValues);
        // fetchingStmAPI();
    }, [asmVal, smVal, outletVal, slcMonth]);

    return (
        <React.Fragment>
            <PageTitle breadcrumbs={[{ title: "MME", path: "/sales/mme", isSeparator: false, isActive: false }]}>
                {"MME"}
            </PageTitle>
            {/* BEGIN:: Filter Input */}
            <div className="row g-5">
                <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={() => { }}>
                    {({ setFieldValue, values }) => (
                        <Form>
                            <div className="row d-flex justify-content-center mb-15 g-5 absolute">
                                <div className="col-xxl-3 mb-5">
                                    <FilterMultiSelect
                                        options={asmOpt}
                                        label="ASM"
                                        isSelectAll={true}
                                        value={arrSelect}
                                        onMenuClose={() => {
                                            let toFilter = arrSelect?.map((val) => val.value);

                                            return equalsCheck(
                                                toFilter as [],
                                                asmVal
                                            )
                                                ? null
                                                : setasmVal(toFilter as []);
                                        }}
                                        onChange={(selected: Option[]) => {
                                            setArrSelect(selected);
                                        }}
                                    />
                                </div>
                                <div className="col-xxl-3 mb-5"
                                    style={{ zIndex: 4 }}>
                                    <FilterMultiSelect
                                        options={smOpt}
                                        label="SM"
                                        isSelectAll={true}
                                        value={smSelect}
                                        onMenuClose={() => {
                                            let toFilter = smSelect?.map((val) => val.value);
                                            return equalsCheck(
                                                toFilter as [],
                                                smVal
                                            )
                                                ? null
                                                : setsmVal(toFilter as []);
                                        }}
                                        onChange={(selected: Option[]) => {
                                            console.log("selected", selected);
                                            setSmSelect(selected);
                                        }}
                                    />
                                </div>
                                <div className="col-xxl-3 mb-5"
                                    style={{ zIndex: 4 }}>
                                    <FilterMultiSelect
                                        options={outletOpt}
                                        label="Outlet"
                                        isSelectAll={true}
                                        value={outletSelect}
                                        onMenuClose={() => {
                                            let toFilter = outletSelect?.map((val) => val.value);
                                            return equalsCheck(
                                                toFilter as [],
                                                outletVal
                                            )
                                                ? null
                                                : setoutletVal(toFilter as []);
                                        }}
                                        onChange={(selected: Option[]) => {
                                            setOutletSelect(selected);
                                        }}
                                    />
                                </div>
                                <div className="col-xxl-3 mb-5">
                                    <FilterMultiSelect
                                        options={monthOpt}
                                        label="Type"
                                        isSelectAll={true}
                                        value={month}
                                        onMenuClose={() => {
                                            let toFilter = month?.map((val) => val.value);
                                            return equalsCheck(
                                                toFilter as [],
                                                slcMonth
                                            )
                                                ? null
                                                : setSlcMonth(toFilter as []);
                                        }}
                                        onChange={(selected: Option[]) => {
                                            setMonth(selected);
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {/* END:: Filter Input */}
            {/* ----------------------------------------------------------------------------- */}
            <div className="row g-5 mh-100">
                {/* On Process */}
                <div className="col-xxl-6 bd-highlight">
                    <KTCard className='mb-5 h-xxl-350px'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-2">SM DO Forecast ⓥ Actual</span>
                            </h3>
                        </div>
                        <KTCardBody className="row pb-0 ps-5 pe-2 pt-0">
                            <div className="col col-xxl-9">
                                <HighchartsSample
                                    id="chart_line"
                                    type="line"
                                    height='70px'
                                    series={[{
                                        name: "DO Accuracy",
                                        type: "line",
                                        data: smDOvActual?.doAccuracy,
                                        tooltip: {
                                            valueSuffix: " %",
                                        },
                                    }]}
                                    categories={week}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                    optionsSetting={{
                                        colors: ["#297f9d"],
                                        xAxis: {
                                            visible: false
                                        }, yAxis: {
                                            visible: false
                                        }, legend: {
                                            align: "right",
                                            verticalAlign: "top",
                                            layout: "vertical",
                                            y: 16
                                        }
                                    }}
                                />
                                <HighchartsSample
                                    id="chart_line"
                                    type="line"
                                    height='200px'
                                    series={[{
                                        name: "DO Forecast",
                                        type: "line",
                                        data: smDOvActual?.doForecast,
                                        tooltip: {
                                            valueSuffix: "",
                                        },
                                        dataLabels: {
                                            format: "{y}",
                                        }
                                    }, {
                                        name: "DO Actual",
                                        type: "line",
                                        data: smDOvActual?.doActual,
                                        tooltip: {
                                            valueSuffix: "",
                                        },
                                        dataLabels: {
                                            format: "{y}",
                                        }
                                    }]}
                                    categories={week}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                    optionsSetting={{
                                        colors: ["#fcc47c", "#43a047"],
                                        xAxis: {
                                            visible: true,
                                        }, yAxis: {
                                            gridLineWidth: 0,
                                            visible: true,
                                            min: 0,
                                            title: {
                                                text: null
                                            }
                                        }, legend: {
                                            align: "right",
                                            verticalAlign: "top",
                                            layout: "vertical",
                                            x: -20,
                                            y: 50
                                        }
                                    }}
                                />
                            </div>
                            <div className='row align-items-center col-xxl-3 my-10 ms-0'>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end">Full Month DO Target</div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end">{smDOvActual?.doTargetTotal}</div>
                                </div>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end">Full Month DO Forecast</div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end">{smDOvActual?.doForecastTotal}</div>
                                </div>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end">Gap Target ⒱ Forecat</div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end">{`${smDOvActual?.doGapTarget} %`}</div>
                                </div>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end">Total DO Actual</div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end">{smDOvActual?.doActualTotal}</div>
                                </div>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end">MTD Accuracy</div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end">75,3%</div>
                                </div>
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
                {/* Idle SPK */}
                <div className="col-xxl-6 bd-highlight">
                    <KTCard className='mb-5 h-xxl-350px'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-2">SM SPK Forecast ⓥ Actual</span>
                            </h3>
                        </div>
                        <KTCardBody className="row pb-0 ps-5 pe-2 pt-0">
                            <div className="col col-xxl-9">
                                <HighchartsSample
                                    id="chart_line"
                                    type="line"
                                    height='70px'
                                    series={[{
                                        name: "SPK Accuracy",
                                        type: "line",
                                        data: smSPKvActual?.spkAccuracy,
                                        tooltip: {
                                            valueSuffix: " %",
                                        },
                                    }]}
                                    categories={week}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                    optionsSetting={{
                                        colors: ["#297f9d"],
                                        xAxis: {
                                            visible: false
                                        }, yAxis: {
                                            visible: false
                                        }, legend: {
                                            align: "right",
                                            verticalAlign: "top",
                                            layout: "vertical",
                                            y: 16
                                        }
                                    }}
                                />
                                <HighchartsSample
                                    id="chart_line"
                                    type="line"
                                    height='200px'
                                    series={[{
                                        name: "SPK Total",
                                        type: "line",
                                        data: smSPKvActual?.Forecast,
                                        tooltip: {
                                            // valueSuffix: " %",
                                        },
                                        dataLabels: {
                                            format: "{y}",
                                        }
                                    }, {
                                        name: "SPK Actual",
                                        type: "line",
                                        data: smSPKvActual?.spkActual,
                                        tooltip: {
                                            // valueSuffix: " %",
                                        },
                                        dataLabels: {
                                            format: "{y}",
                                        }
                                    }]}
                                    categories={week}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                    optionsSetting={{
                                        colors: ["#fcc47c", "#43a047"],
                                        xAxis: {
                                            visible: true,
                                        }, yAxis: {
                                            gridLineWidth: 0,
                                            visible: true,
                                            min: 0,
                                            title: {
                                                text: null
                                            }
                                        }, legend: {
                                            align: "right",
                                            verticalAlign: "top",
                                            layout: "vertical",
                                            x: -20,
                                            y: 50
                                        }
                                    }}
                                />
                            </div>
                            <div className='row align-items-center col-xxl-3 my-10 ms-0'>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end"></div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end"></div>
                                </div>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end"></div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end"></div>
                                </div>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end">Full Month SPK Forecast</div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end">{smSPKvActual?.spkForecastTotal}</div>
                                </div>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end">Total SPK Actual</div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end">{smSPKvActual?.spkActualTotal}</div>
                                </div>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end">MTD Accuracy</div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end">0%</div>
                                </div>
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            <div className="row g-5 mh-100">
                {/* On Process */}
                <div className="col-xxl-6 bd-highlight">
                    <KTCard className='mb-5 h-xxl-700px'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-2">SM DO Forecast</span>
                            </h3>
                        </div>
                        <KTCardBody className="pb-0 ps-5 pe-2 pt-0">
                            <MultiLoopTable
                                maxHeight={600}
                                data={tablesmDOvForecast}
                                type={'smtable'}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
                {/* Idle SPK */}
                <div className="col-xxl-6 bd-highlight">
                    <KTCard className='mb-5 h-xxl-700px'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-2">SM SPK Forecast</span>
                            </h3>
                        </div>
                        <KTCardBody className="pb-0 ps-5 pe-2 pt-0">
                            <MultiLoopTable
                                maxHeight={600}
                                data={tablesmSPKvForecast}
                                type={'smtable'}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            <div className="row g-5 mh-100">
                {/* On Process */}
                <div className="col-xxl-6 bd-highlight">
                    <KTCard className='mb-5 h-xxl-500px'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-2">DO Forecast ⓥ Actual</span>
                            </h3>
                        </div>
                        <KTCardBody className="row pb-0 ps-5 pe-2 pt-0">
                            <div className="col col-xxl-9">
                                <HighchartsSample
                                    id="chart_line"
                                    type="line"
                                    height='70px'
                                    series={[{
                                        name: "DO Accuracy",
                                        type: "line",
                                        data: doForecastActual?.doAccuracy,
                                        tooltip: {
                                            valueSuffix: " %",
                                        },
                                    }]}
                                    categories={week}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                    optionsSetting={{
                                        colors: ["#297f9d"],
                                        xAxis: {
                                            visible: false
                                        }, yAxis: {
                                            visible: false
                                        }, legend: {
                                            align: "right",
                                            verticalAlign: "top",
                                            layout: "vertical",
                                            y: 16
                                        }
                                    }}
                                />
                                <HighchartsSample
                                    id="chart_line"
                                    type="line"
                                    height='350px'
                                    series={[{
                                        name: "DO Forecast",
                                        type: "line",
                                        data: doForecastActual?.doForecast,
                                        tooltip: {
                                            // valueSuffix: " %",
                                        },
                                        dataLabels: {
                                            format: "{y}",
                                        }
                                    }, {
                                        name: "DO Actual",
                                        type: "line",
                                        data: doForecastActual?.doActual,
                                        tooltip: {
                                            // valueSuffix: " %",
                                        },
                                        dataLabels: {
                                            format: "{y}",
                                        }
                                    }]}
                                    categories={week}
                                    chartStyle={{
                                        spacingLeft: 5,
                                        spacingRight: 5,
                                    }}
                                    optionsSetting={{
                                        colors: ["#fcc47c", "#43a047"],
                                        xAxis: {
                                            visible: true,
                                        }, yAxis: {
                                            gridLineWidth: 0,
                                            visible: true,
                                            min: 0,
                                            title: {
                                                text: null
                                            }
                                        }, legend: {
                                            align: "right",
                                            verticalAlign: "top",
                                            layout: "vertical",
                                            x: -20,
                                            y: 100
                                        }
                                    }}
                                />
                            </div>
                            <div className='row align-items-center col-xxl-3 my-10 ms-0'>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end">Full Month DO Target</div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end">{doForecastActual?.doTargetTotal}</div>
                                </div>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end">Full Month DO Forecast</div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end">{doForecastActual?.doForecastTotal}</div>
                                </div>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end">Gap Target ⒱ Forecat</div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end">{doForecastActual?.doGapTarget}</div>
                                </div>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end">Total DO Actual</div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end">{smDOvActual?.doActualTotal}</div>
                                </div>
                                <div className='border border-0 border-gray-500 min-w-60px'>
                                    <div className="fs-8 text-gray-800 text-wrap text-center text-md-end">MTD Accuracy</div>
                                    <div className="fs-6 text-gray-800 text-center text-md-end">70,1%</div>
                                </div>
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
                {/* Idle SPK */}
                <div className="col-xxl-6 bd-highlight">
                    <KTCard className='mb-5 h-xxl-500px'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-2">DO Forecast ⓥ Actual by Model</span>
                            </h3>
                        </div>
                        <KTCardBody className="pb-0 ps-5 pe-2 pt-5">
                            <MultiLoopTable
                                maxHeight={400}
                                data={tableDoForecast}
                                type={"doTable"}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MmeIndex;