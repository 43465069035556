import React, { Component } from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { NationalInformationAPI } from "../../../../api/NationalInformationAPI";
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers";
import { Form, Formik } from "formik";
import FilterMultiSelect, {
  Option,
} from "../../../../../_metronic/helpers/components/form-actions/FilteringInput";
import { HighchartsSample } from "../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample";
import { RetailSalesModel, WholesalesModel } from "../../../../models/sales/NationalInformationModel";
import DataTableMuiModel from "../../../../models/DataTable/dataTableModal";
import { MaterialReactTableDetails } from "../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable";

export default class ChartRSWSIndex extends Component {
  constructor(props: any) {
    super(props);
    this["title"] = "Retail Sales (RS) & Wholesales (WS)";
    this["api"] = new NationalInformationAPI();
    this["month"] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    this["state"] = {
      loading: true,
      initialValues: {
        month: [],
        dealer_group: [],
        model: []
      }, initialValuesChartColumnLine: {
        retail_sales: {
          series: [],
          categories: props["month"],
          data: [],
        }, wholesales: {
          series: [],
          categories: props["month"],
          data: [],
        },
      }, initialValuesChartSpline: {
        retail_sales: {
          series: [],
          categories: props["month"],
        }, wholesales: {
          series: [],
          categories: props["month"],
        },
      }, initialValuesChartPie: {
        retail_sales: {
          series_old: [],
          series_now: []
        }, wholesales: {
          series_old: [],
          series_now: []
        },
      }, initialValuesChartColumnStacked: {
        retail_sales: {
          series: [],
          categories: props["month"],
        }, wholesales: {
          series: [],
          categories: props["month"],
        },
      }
    };
    this["datatable_rs"] = [];
    this["columntable_rs"] = [];
    this["datatable_ws"] = [];
    this["columntable_ws"] = [];
    this["option_month"] = [];
    this["option_dealer_group"] = [];
    this["option_model"] = [];
  }
  //* ----------------------------------------------------------------------------- */
  // BEGIN:: componentDidMount */
  async componentDidMount() {
    //? Load Filter Options Month
    await this["api"].getMonthRSWS().then(async (res: any) => {
      this["option_month"] = res["data"].map((val: any) => {
        return {
          value: val["id"],
          label: val["text"],
          year: val["year"],
        };
      });
    });
    //? Load Filter Options Dealer Group
    await this['api'].getBrandRSWS().then(async (res: any) => {
      this['option_dealer_group'] = res['data'].map((val: any) => {
        return {
          value: val['id'],
          label: val['text'],
        };
      });
    });
    //? Load Filter Options Model
    await this['api'].getModelRSWS().then(async (res: any) => {
      this['option_model'] = res['data'].map((val: any) => {
        return {
          value: val['id'],
          label: val['text'],
        };
      });
    });
    //! Load Chart 1
    await this.LoadChart1(this["state"]["initialValues"]);
    //! Load Chart 2
    await this.LoadChart2(this["state"]["initialValues"]);
    //! Load Chart 3
    await this.LoadChart3(this['state']['initialValues']);
  } //END:: componentDidMount */
  //* ----------------------------------------------------------------------------- */
  //! Function Load Chart 1
  async LoadChart1(initial: []) {
    const { data: dataResultColumnLine } = await this["api"].getDataChartColumnLineRSWS({
      ...initial,
    });
    const {
      dataYearOld: dataRSYearOld,
      dataYearNow: dataRSYearNow,
      growth: growthRS,
      sumGrowthYTD: sumRSGrowthYTD,
      sumDataYTDNow: sumDataRSYTDNow,
      sumDataYTDold: sumDataRSYTDold,
    } = dataResultColumnLine["RSChartLine"];
    const {
      dataYearOld: dataWSYearOld,
      dataYearNow: dataWSYearNow,
      growth: growthWS,
      sumGrowthYTD: sumWSGrowthYTD,
      sumDataYTDNow: sumDataWSYTDNow,
      sumDataYTDold: sumDataWSYTDold,
    } = dataResultColumnLine["WSChartLine"];

    this["setState"]({
      ...this["state"],
      initialValuesChartColumnLine: {
        retail_sales: {
          series: [
            {
              name: `${this["option_month"][0]?.["year"] - 1} RS`,
              type: "column",
              yAxis: 1,
              data: dataRSYearOld,
            },
            {
              name: `${this["option_month"][0]?.["year"]} RS`,
              type: "column",
              yAxis: 1,
              data: dataRSYearNow,
            },
            {
              name: "Growth",
              type: "line",
              data: growthRS,
              tooltip: {
                valueSuffix: " %",
              },
            },
          ],
          categories: this["month"],
          data: [
            {
              name: "YTD Growth",
              value: sumRSGrowthYTD,
            },
            {
              name: `${this["option_month"][0]?.["year"]} YTD RS`,
              value: sumDataRSYTDNow,
            },
            {
              name: `${this["option_month"][0]?.["year"] - 1} YTD RS`,
              value: sumDataRSYTDold,
            },
          ]
        }, wholesales: {
          series: [{
            name: `${this["option_month"][0]?.["year"] - 1} RS`,
            type: "column",
            yAxis: 1,
            data: dataWSYearOld,
          }, {
            name: `${this["option_month"][0]?.["year"]} RS`,
            type: "column",
            yAxis: 1,
            data: dataWSYearNow,
          }, {
            name: "Growth",
            type: "line",
            data: growthWS,
            tooltip: {
              valueSuffix: " %",
            }
          }],
          categories: this["month"],
          data: [{
            name: "YTD Growth",
            value: sumWSGrowthYTD,
          }, {
            name: `${this["option_month"][0]?.["year"]} YTD RS`,
            value: sumDataWSYTDNow,
          }, {
            name: `${this["option_month"][0]?.["year"] - 1} YTD RS`,
            value: sumDataWSYTDold,
          }],
        }
      }
    });
  }
  //* ----------------------------------------------------------------------------- */
  //! Function Load Chart 2
  async LoadChart2(initial: []) {
    const { data: dataResultSpline } = await this["api"].getDataChartSplineRSWS(initial);
    const { lineRs, lineWs } = dataResultSpline[0]['SpLine'];
    this["setState"]({
      ...this["state"],
      initialValuesChartSpline: {
        retail_sales: {
          series: lineRs,
          categories: this["month"],
        }, wholesales: {
          series: lineWs,
          categories: this["month"],
        },
      }
    });
  }
  //* ----------------------------------------------------------------------------- */
  //! Function Load Chart 3
  async LoadChart3(initial: []) {
    this["setState"]({ loading: true });
    const { data: dataTable } = await this["api"].getDataTableRsWs({ ...initial, tipe: "ChartTablePie" });
    const { tableRSbyBrand, tableWSbyBrand } = dataTable[0]['TableByBrand'];
    //! Table Retail Sales
    this["RSdataTableMuiModel"] = new DataTableMuiModel({ readOnly: true });
    const max_rs_old_year = Math.max(...tableRSbyBrand.map((obj: any) => obj['rs_old_year']));
    const max_rs_year_now = Math.max(...tableRSbyBrand.map((obj: any) => obj['rs_new_year']));
    // Share
    const max_rs_share_old_year = Math.max(...tableRSbyBrand.map((obj: any) => obj['share_old_year']));
    const max_rs_share_year_now = Math.max(...tableRSbyBrand.map((obj: any) => obj['share_year_now']));
    tableRSbyBrand.map((item: any, i: number) => {
      let dataModel = new RetailSalesModel({
        ...item,
        max_rs_old_year: max_rs_old_year,
        max_rs_year_now: max_rs_year_now,
        max_share_old_year: max_rs_share_old_year,
        max_share_year_now: max_rs_share_year_now,
      });
      this["RSdataTableMuiModel"].add({
        id: i,
        no: i + 1,
        dataModel: dataModel,
        actions: null,
      });
      return item;
    });
    this["datatable_rs"] = await this["RSdataTableMuiModel"].getDatas();
    this["columntable_rs"] = await this["RSdataTableMuiModel"].getColumn();
    //* ----------------------------------------------------------------------------- */
    //! Table Wholesales
    this["WSdataTableMuiModel"] = new DataTableMuiModel({ readOnly: true });
    const max_ws_old_year = Math.max(...tableWSbyBrand.map((obj: any) => obj['ws_old_year']));
    const max_ws_year_now = Math.max(...tableWSbyBrand.map((obj: any) => obj['ws_new_year']));
    // Share
    const max_ws_share_old_year = Math.max(...tableWSbyBrand.map((obj: any) => obj['share_old_year']));
    const max_ws_share_year_now = Math.max(...tableWSbyBrand.map((obj: any) => obj['share_year_now']));
    tableWSbyBrand.map((item: any, i: number) => {
      let dataModel = new WholesalesModel({
        ...item,
        max_ws_old_year: max_ws_old_year,
        max_ws_year_now: max_ws_year_now,
        max_share_old_year: max_ws_share_old_year,
        max_share_year_now: max_ws_share_year_now,
      });
      this["WSdataTableMuiModel"].add({
        id: i,
        no: i + 1,
        dataModel: dataModel,
        actions: null,
      });
      return item;
    });
    this["datatable_ws"] = await this["WSdataTableMuiModel"].getDatas();
    this["columntable_ws"] = await this["WSdataTableMuiModel"].getColumn();
    //* ----------------------------------------------------------------------------- */
    //! Chart Pie
    const { data: dataChart } = await this["api"].getDataChartPieRsWs({ ...initial, tipe: "ChartTablePie" });
    const { YearOldCompRS, YearNowCompRS, YearOldCompWS, YearNowCompWS } = dataChart[0]['chartPie'];
    //! Column Stacked
    const { data: dataResult } = await this["api"].getDataChartColumnStackedRSWS({
      ...initial,
    });
    const { columnRS, columnWS } = dataResult[0]['ColumnGroupStacked'];
    const { category: category_rs, data: data_rs } = columnRS;
    const { category: category_ws, data: data_ws } = columnWS;
    this["setState"]({
      ...this["state"],
      initialValuesChartPie: {
        retail_sales: {
          series_old: YearOldCompRS[0],
          series_now: YearNowCompRS[0]
        }, wholesales: {
          series_old: YearOldCompWS[0],
          series_now: YearNowCompWS[0]
        }
      }, initialValuesChartColumnStacked: {
        retail_sales: {
          series: data_rs,
          categories: category_rs,
        }, wholesales: {
          series: data_ws,
          categories: category_ws,
        },
      },
      loading: false,
    });
  }

  render(this: any) {
    const { initialValues, initialValuesChartColumnLine, initialValuesChartPie, initialValuesChartSpline, initialValuesChartColumnStacked } = this["state"];
    const { retail_sales: retail_sales_column_line, wholesales: wholesales_column_line } = initialValuesChartColumnLine;
    const { retail_sales: retail_sales_spline, wholesales: wholesales_spline } = initialValuesChartSpline;
    const { retail_sales: retail_sales_pie, wholesales: wholesales_pie } = initialValuesChartPie;
    const { retail_sales: retail_sales_column_stacked, wholesales: wholesales_column_stacked } = initialValuesChartColumnStacked;
    return (
      <React.Fragment>
        <PageTitle breadcrumbs={[{ title: "National Information", path: "/sales/rs-ws", isSeparator: false, isActive: false }]}>
          {this["title"]}
        </PageTitle>
        {/* BEGIN:: Card Filter Input */}
        <div className="row g-5">
          <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={() => { }} >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-3 mb-5">
                    <FilterMultiSelect
                      label={"Month"}
                      name="month"
                      options={this["option_month"]}
                      onChange={(selected: Option[]) => {
                        setFieldValue("month", selected);
                        const arrayValueMonth = selected?.map((x: Object) => x["value"]);
                        const arrayValueModel = values["model"]?.map((x: Object) => x["value"]);
                        const arrayValueDealerGroup = values["dealer_group"]?.map((x: Object) => x["value"]);
                        setTimeout(() => {
                          this.LoadChart3({
                            month: arrayValueMonth,
                            dealer_group: arrayValueDealerGroup,
                            model: arrayValueModel,
                          });
                        }, 500);
                      }}
                      value={values["month"]}
                    />
                  </div>
                  <div className="col-lg-3 mb-5">
                    <FilterMultiSelect
                      label={"Dealer Group"}
                      name="dealer_group"
                      options={this['option_dealer_group']}
                      onChange={(selected: Option[]) => {
                        setFieldValue("dealer_group", selected);
                        const arrayValueMonth = values["month"]?.map((x: Object) => x["value"]);
                        const arrayValueModel = values["model"]?.map((x: Object) => x["value"]);
                        const arrayValueDealerGroup = selected?.map((x: Object) => x["value"]);
                        setTimeout(() => {
                          this.LoadChart1({
                            month: arrayValueMonth,
                            dealer_group: arrayValueDealerGroup,
                            model: arrayValueModel,
                          });
                        }, 500);
                      }}
                      value={values["dealer_group"]}
                    />
                  </div>
                  <div className="col-lg-3 mb-5">
                    <FilterMultiSelect
                      label={"Model"}
                      name="model"
                      options={this['option_model']}
                      onChange={(selected: Option[]) => {
                        setFieldValue("model", selected);
                        const arrayValueMonth = values["month"]?.map((x: Object) => x["value"]);
                        const arrayValueDealerGroup = values["month"]?.map((x: Object) => x["value"]);
                        const arrayValueModel = selected?.map((x: Object) => x["value"]);
                        setTimeout(() => {
                          this.LoadChart1({
                            month: arrayValueMonth,
                            dealer_group: arrayValueDealerGroup,
                            model: arrayValueModel,
                          });
                          this.LoadChart2({
                            model: arrayValueModel,
                          });
                          this.LoadChart3({
                            month: arrayValueMonth,
                            dealer_group: arrayValueDealerGroup,
                            model: arrayValueModel,
                          });
                        }, 500);
                      }}
                      value={values["model"]}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* END:: Card Filter Input */}
        {/* ----------------------------------------------------------------------------- */}
        {/* BEGIN:: Retail Sales & Wholesales */}
        <div className="row g-5 mh-md-800px">
          {/* Retail Sales */}
          <div className="col-xxl-6 bd-highlight">
            <KTCard stretch='stretch' className='mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1">Retail Sales</span>
                </h3>
              </div>
              <KTCardBody className="p-3">
                <HighchartsSample
                  id="retail_sales"
                  type="column_line"
                  positionColumnData={"top"}
                  height="400px"
                  series={retail_sales_column_line["series"]}
                  categories={retail_sales_column_line["categories"]}
                  data={retail_sales_column_line["data"]}
                  chartStyle={{
                    spacingLeft: 5,
                    spacingRight: 5,

                  }}
                />
              </KTCardBody>
            </KTCard>
          </div>
          {/* Wholesales */}
          <div className="col-xxl-6 bd-highlight">
            <KTCard stretch='stretch' className='mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1">Wholesales</span>
                </h3>
              </div>
              <KTCardBody className="p-3">
                <HighchartsSample
                  id="wholesales"
                  type="column_line"
                  positionColumnData={"top"}
                  height="400px"
                  series={wholesales_column_line["series"]}
                  categories={wholesales_column_line["categories"]}
                  data={wholesales_column_line["data"]}
                  chartStyle={{
                    spacingLeft: 5,
                    spacingRight: 5,

                  }}
                />
              </KTCardBody>
            </KTCard>
          </div>
        </div>
        {/* END:: Retail Sales & Wholesales */}
        {/* ----------------------------------------------------------------------------- */}
        {/* BEGIN:: Retail Sales by Dealer Group & Wholesales by Dealer Group */}
        <div className="row g-5 mh-md-700px">
          {/* Retail Sales by Dealer Group */}
          <div className="col-xxl-6 bd-highlight">
            <KTCard stretch='stretch' className='mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1">Retail Sales by Dealer Group</span>
                </h3>
              </div>
              <KTCardBody className="p-3">
                <MaterialReactTableDetails
                  data={this["datatable_rs"]}
                  columns={this["columntable_rs"]}
                  isLoading={this["state"]["loading"]}
                  maxHeight={"400px"}
                  hover={false}
                  optionsHead={{
                    fontSize: 9,
                  }}
                />
              </KTCardBody>
            </KTCard>
          </div>
          {/* Wholesales by Dealer Group */}
          <div className="col-xxl-6 bd-highlight">
            <KTCard stretch='stretch' className='mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1">Wholesales by Dealer Group</span>
                </h3>
              </div>
              <KTCardBody className="p-3">
                <MaterialReactTableDetails
                  data={this["datatable_ws"]}
                  columns={this["columntable_ws"]}
                  isLoading={this["state"]["loading"]}
                  maxHeight={"400px"}
                  hover={false}
                  optionsHead={{
                    fontSize: 10,
                  }}
                />
              </KTCardBody>
            </KTCard>
          </div>
        </div>
        {/* END:: Retail Sales by Dealer Group & Wholesales by Dealer Group */}
        {/* ----------------------------------------------------------------------------- */}
        {/* BEGIN:: Year Now MoM RS by Dealer Group & Year MoM WS by Dealer Group */}
        <div className="row g-5 mh-md-800px">
          {/* Year Now MoM RS by Dealer Group */}
          <div className="col-xxl-6 bd-highlight">
            <KTCard stretch='stretch' className='mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1">{this['option_month']?.[0]?.['year']} MoM RS by Dealer Group</span>
                </h3>
              </div>
              <KTCardBody className="p-3">
                <HighchartsSample
                  id="retail_sales_spline_chart"
                  type="spline"
                  positionLegend={"TopCenter"}
                  height="450px"
                  series={retail_sales_spline["series"]}
                  categories={retail_sales_spline["categories"]}
                  chartStyle={{
                    spacingLeft: 5,
                    spacingRight: 5,
                  }}
                />
              </KTCardBody>
            </KTCard>
          </div>
          {/* Year Now MoM WS by Dealer Group */}
          <div className="col-xxl-6 bd-highlight">
            <KTCard stretch='stretch' className='mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1">{this['option_month']?.[0]?.['year']} MoM WS by Dealer Group</span>
                </h3>
              </div>
              <KTCardBody className="p-3">
                <HighchartsSample
                  id="wholesales_spline_chart"
                  type="spline"
                  positionLegend={"TopCenter"}
                  height="450px"
                  series={wholesales_spline["series"]}
                  categories={wholesales_spline["categories"]}
                  chartStyle={{
                    spacingLeft: 5,
                    spacingRight: 5,
                  }}
                />
              </KTCardBody>
            </KTCard>
          </div>
        </div>
        {/* END:: Year Now MoM RS by Dealer Group & Year MoM WS by Dealer Group */}
        {/* ----------------------------------------------------------------------------- */}
        {/* BEGIN:: Card Chart Pie */}
        <div className="row g-5 mh-md-800px">
          {/* Year Now MoM RS by Dealer Group */}
          <div className="col-xxl-6 bd-highlight">
            <KTCard stretch='stretch' className='mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1">Dealer RS Contribution Growth</span>
                </h3>
              </div>
              <KTCardBody className="row d-flex p-0">
                <div className="col-xxl-6">
                  <HighchartsSample
                    id="retail_sales_pie_chart1"
                    type="pie"
                    title={`${this['option_month']?.[0]?.['year'] - 1}`}
                    height="450px"
                    series={retail_sales_pie?.["series_old"]}
                    chartStyle={{
                      spacingLeft: 5,
                      spacingRight: 5,
                    }}
                  />
                </div>
                <div className="col-xxl-6">
                  <HighchartsSample
                    id="retail_sales_pie_chart2"
                    type="pie"
                    title={`${this['option_month']?.[0]?.['year']}`}
                    height="450px"
                    series={retail_sales_pie?.["series_now"]}
                    chartStyle={{
                      spacingLeft: 5,
                      spacingRight: 5,
                    }}
                  />
                </div>
              </KTCardBody>
            </KTCard>
          </div>
          {/* Year Now MoM WS by Dealer Group */}
          <div className="col-xxl-6 bd-highlight">
            <KTCard stretch='stretch' className='mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1">Dealer WS Contribution Growth</span>
                </h3>
              </div>
              <KTCardBody className="row d-flex p-0">
                <div className="col-xxl-6">
                  <HighchartsSample
                    id="wholesales_pie_chart1"
                    type="pie"
                    title={`${this['option_month']?.[0]?.['year'] - 1}`}
                    height="450px"
                    series={wholesales_pie?.["series_old"]}
                    chartStyle={{
                      spacingLeft: 5,
                      spacingRight: 5,
                    }}
                  />
                </div>
                <div className="col-xxl-6">
                  <HighchartsSample
                    id="wholesales_pie_chart2"
                    type="pie"
                    title={`${this['option_month']?.[0]?.['year']}`}
                    height="450px"
                    series={wholesales_pie?.["series_now"]}
                    chartStyle={{
                      spacingLeft: 5,
                      spacingRight: 5,
                    }}
                  />
                </div>
              </KTCardBody>
            </KTCard>
          </div>
        </div>
        {/* END:: Card Chart Pie */}
        {/* ----------------------------------------------------------------------------- */}
        {/* BEGIN:: Card Chart Column Stacked */}
        <div className="row g-5 mh-md-800px">
          {/* Year Now MoM RS by Dealer Group */}
          <div className="col-xxl-6 bd-highlight">
            <KTCard stretch='stretch' className='mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1">{this['option_month']?.[0]?.['year']} RS Model Composition by Dealer Group</span>
                </h3>
              </div>
              <KTCardBody className="p-3">
                <HighchartsSample
                  id="retail_sales_column_stacked_chart"
                  type="column_stacked"
                  stacking="percent"
                  height="450px"
                  series={retail_sales_column_stacked["series"]}
                  categories={retail_sales_column_stacked["categories"]}
                  chartStyle={{
                    spacingLeft: 5,
                    spacingRight: 5,
                  }}
                />
              </KTCardBody>
            </KTCard>
          </div>
          {/* Year Now MoM WS by Dealer Group */}
          <div className="col-xxl-6 bd-highlight">
            <KTCard stretch='stretch' className='mb-5'>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1">{this['option_month']?.[0]?.['year']} WS Model Composition by Dealer Group</span>
                </h3>
              </div>
              <KTCardBody className="p-3">
                <HighchartsSample
                  id="whole_sales_column_stacked_chart"
                  type="column_stacked"
                  stacking="percent"
                  height="450px"
                  series={wholesales_column_stacked["series"]}
                  categories={wholesales_column_stacked["categories"]}
                  chartStyle={{
                    spacingLeft: 5,
                    spacingRight: 5,
                  }}
                />
              </KTCardBody>
            </KTCard>
          </div>
        </div>
        {/* END:: Card Chart Column Stacked */}
        {/* ----------------------------------------------------------------------------- */}
      </React.Fragment>
    );
  }
}
