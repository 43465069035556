import { useEffect, useMemo, useState } from 'react'
import { PerformanceAPI } from '../api/PerformanceAPI';
import { Options } from '../constant';

type Val = {
    asmVal?: string[],
    smVal?: string[],
    outletVal?: string[],
}
const GetDataFilter = ({ asmVal, smVal, outletVal }: Val) => {
    const [asmOpt, setAsmOpt] = useState<Options[]>([])
    const [smOpt, setSmOpt] = useState<Options[]>([])
    const [outletOpt, setOutletOpt] = useState<Options[]>([])
    const [coverage, setCoverage] = useState<String>("")
    let val = useMemo(() => {
        return {
            asm: asmVal?.slice(),
            sm: smVal?.slice(),
            outlet: outletVal?.slice(),
        };
    }, [asmVal, smVal, outletVal]);

    useEffect(() => {
        const fetchFilter = async (val: any) => {

            const res = await new PerformanceAPI().getFilter(val)

            if (res.data) {
                setCoverage(res.data.coverage)
                setAsmOpt(res.data.dataAsm.map((val: { area: string; id_team_asm: string | number }) => ({ label: val.area, value: val.id_team_asm })))
                setSmOpt(res.data.dataSm.map((val: { region: string; id_team_sm: string | number }) => ({ label: val.region, value: val.id_team_sm })))
                setOutletOpt(res.data.dataOutlet.map((val: { text: string; id_outlet: string | number }) => ({ label: val.text, value: val.id_outlet })))
            }
        }
        fetchFilter(val)
        return () => {
            console.log("unmounting");
        }
    }, [val, coverage]);

    return { asmOpt, smOpt, outletOpt, coverage }
}

export default GetDataFilter