import React, { Fragment, useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);

const currYear = new Date().getFullYear();
function sumData(arr) {
  let sum = arr.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);
  return sum;
}

function sumDataAvg(arr) {
  let sum = arr.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  let avg = sum / arr.length;
  return avg.toFixed(1) || 0;
}

function sumDataMS(arr) {
  let avg = (arr[1] / arr[0]) * 100;
  return `${avg.toFixed(1)} %` || 0;
}

function TableOverview({ title, dt, gt }) {
  // function tableRScolor(val) {
  //   if (val < 70) {
  //     return "#F4C7C3";
  //   } else {
  //     return "#C8E6C9";
  //   }
  // }
  // const classes = useStyles();
  React.useEffect(() => {}, [dt]);
  return (
    <>
      <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 mb-1">{title}</span>
        </h3>
      </div>
      <Paper sx={{ width: "100%", overflow: "scroll" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table>
            <TableHead
              style={{
                position: "sticky",
                top: "0px",
                backgroundColor: "rgb(197,202,232)",
              }}
            >
              <TableRow>
                <TableCell>ASM</TableCell>
                <TableCell>Outlet</TableCell>
                <TableCell>Market YTD {currYear}</TableCell>
                <TableCell>Vol YTD {currYear}</TableCell>
                <TableCell>M/S</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dt.map((item, i) => (
                <Fragment key={i}>
                  <TableRow>
                    <TableCell rowSpan={item.outlet.length + 2}>
                      {item.name}
                    </TableCell>
                  </TableRow>
                  {item.outlet.map((detail, i) => (
                    <TableRow key={i} hover>
                      <TableCell>{detail}</TableCell>
                      <TableCell>
                        {item.total[i][0] !== null
                          ? `${parseFloat(item.total[i][0]).toFixed(1)}`
                          : null}
                      </TableCell>
                      <TableCell>
                        {item.total[i][1] !== null
                          ? `${parseFloat(item.total[i][1]).toFixed(1)}`
                          : null}
                      </TableCell>
                      <TableCell>
                        {item.total[i][2] !== null
                          ? `${parseFloat(item.total[i][2]).toFixed(1)}`
                          : null}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell>
                      {sumData(item.total.map((val) => val[0]))}
                    </TableCell>
                    <TableCell>
                      {sumData(item.total.map((val) => val[1]))}
                    </TableCell>
                    <TableCell>
                      {sumDataAvg(item.total.map((val) => val[2]))}
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
            <TableFooter
              style={{
                position: "sticky",
                bottom: "0px",
                backgroundColor: "white",
              }}
            >
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "black",
                  }}
                  colSpan={2}
                >
                  Grand Total:
                </TableCell>
                {dt.length > 0
                  ? gt.map((val, i) => (
                      <Fragment key={i}>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                            color: "black",
                          }}
                        >
                          {val}
                        </TableCell>
                      </Fragment>
                    ))
                  : null}
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    color: "black",
                  }}
                >
                  {sumDataMS(gt)}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

function TableTen({ title, color, dt }) {
  return (
    <>
      <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 mb-1">{title}</span>
        </h3>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table>
            <TableHead style={{ backgroundColor: color }}>
              <TableRow>
                <TableCell>Outlet</TableCell>
                <TableCell>Market YTD {currYear}</TableCell>
                <TableCell>Vol YTD {currYear}</TableCell>
                <TableCell>M/S</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dt.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>{item.area}</TableCell>
                  <TableCell>{item.size}</TableCell>
                  <TableCell>{item.vol}</TableCell>
                  <TableCell>{item.m_s} %</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

function ChartOverviewMS({ title, category, dt }) {
  const chartComponent = useRef(null);

  const options = {
    chart: {
      backgroundColor: "transparent",
      zoomType: "xy",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
      align: "left",
    },
    // subtitle: {
    //   text:
    //     "Source: " +
    //     '<a href="https://www.yr.no/nb/historikk/graf/5-97251/Norge/Troms%20og%20Finnmark/Karasjok/Karasjok?q=2021"' +
    //     'target="_blank">YR</a>',
    //   align: "left",
    // },
    xAxis: [
      {
        categories: [
          `Jan ${currYear - 1}`,
          `Feb ${currYear - 1}`,
          `Mar ${currYear - 1}`,
          `Apr ${currYear - 1}`,
          `May ${currYear - 1}`,
          `Jun ${currYear - 1}`,
          `Jul ${currYear - 1}`,
          `Aug ${currYear - 1}`,
          `Sep ${currYear - 1}`,
          `Oct ${currYear - 1}`,
          `Nov ${currYear - 1}`,
          `Dec ${currYear - 1}`,
          `Jan ${currYear}`,
          `Feb ${currYear}`,
          `Mar ${currYear}`,
          `Apr ${currYear}`,
          `May ${currYear}`,
          `Jun ${currYear}`,
          `Jul ${currYear}`,
          `Aug ${currYear}`,
          `Sep ${currYear}`,
          `Oct ${currYear}`,
          `Nov ${currYear}`,
          `Dec ${currYear}`,
        ],
        // disable shadow behind labels
        crosshair: false,
      },
    ],
    yAxis: [
      {
        visible: false,
        min: -100,
        // Primary yAxis
        labels: {
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "Temperature",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      {
        visible: false,
        // Secondary yAxis
        title: {
          text: "Precipitation",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        labels: {
          // format: "{value} mm",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      // x: 80,
      // verticalAlign: "bottom",
      // y: 60,
      // floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    plotOptions: {
      series: {
        // maxPointWidth: 20,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          borderWidth: 0,
        },
      },
      column: {
        pointPadding: 0,
        borderWidth: 2,
        groupPadding: 0.1,
        dataLabels: {
          borderWidth: 0,
        },
      },
      spline: {
        dataLabels: {
          textShadow: false,
          textOutline: "0px",
          borderWidth: 0,
          format: "{y}%",
        },
      },
    },
    series: [
      {
        name: "Market",
        type: "column",
        color: "#606368",
        yAxis: 1,
        data: dt.market,
        tooltip: {
          // valueSuffix: " mm",
        },
      },
      {
        name: "Kumala Vol",
        type: "column",
        color: "#c85246",
        yAxis: 1,
        data: dt.vol,
        tooltip: {
          // valueSuffix: " mm",
        },
      },
      {
        name: "MS",
        type: "spline",
        data: dt.growth,
        tooltip: {
          valueSuffix: "%",
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "vertical",
              align: "center",
              verticalAlign: "top",
            },
          },
        },
      ],
    },
  };
  useEffect(() => {
    // const chart = chartComponent.current.chart;
  }, [dt]);
  return (
    <>
      <div className="d-flex align-items-center flex-column mt-3 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 mb-1">{title}</span>
        </h3>
      </div>
      <div className="row d-flex mt-10" style={{ overflow: "visible" }}>
        <div className="card col-lg-10">
          <HighchartsReact
            ref={chartComponent}
            highcharts={Highcharts}
            options={options}
            // series={series}
          />
        </div>
        <div className="card col-lg-2 d-flex text-center justify-content-center">
          <h5>YTD M/S {currYear}</h5>
          <p>{dt.ytdYearNew} %</p>
          <h5>YTD M/S {currYear - 1}</h5>
          <p>{dt.ytdYearOld} %</p>
        </div>
      </div>
    </>
  );
}

function ChartMarketGrowth({ title, col, category, dt }) {
  const chartComponent = useRef(null);

  const options = {
    chart: {
      backgroundColor: "transparent",
      zoomType: "xy",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
      align: "left",
    },
    xAxis: [
      {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        // disable shadow behind labels
        crosshair: false,
      },
    ],
    yAxis: [
      {
        visible: false,
        min: -600,
        // Primary yAxis
        labels: {
          // format: "{value}°C",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "Temperature",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      {
        visible: false,
        // Secondary yAxis
        title: {
          text: "Precipitation",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        labels: {
          format: "{value} mm",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      // x: 80,
      // verticalAlign: "bottom",
      // y: 60,
      // floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    plotOptions: {
      series: {
        // maxPointWidth: 20,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
        },
      },
      column: {
        pointPadding: 0,
        borderWidth: 2,
        groupPadding: 0.1,
      },
      spline: {
        dataLabels: {
          format: "{y}%",
        },
      },
    },
    series: [
      {
        name: `Market ${currYear - 1}`,
        type: "column",
        color: "#606368",
        yAxis: 1,
        data: dt.dataYearOld,
        tooltip: {
          // valueSuffix: " mm",
        },
      },
      {
        name: `Market ${currYear}`,
        type: "column",
        color: col,
        yAxis: 1,
        data: dt.dataYearNow,
        tooltip: {
          // valueSuffix: " mm",
        },
      },
      {
        name: "Growth",
        type: "spline",
        data: dt.dataGrowth,
        tooltip: {
          valueSuffix: "%",
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "vertical",
              align: "center",
              verticalAlign: "top",
            },
          },
        },
      ],
    },
  };
  useEffect(() => {
    // const chart = chartComponent.current.chart;
  }, [dt]);
  return (
    <>
      <div className="d-flex align-items-center flex-column mt-3 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 mb-1">{title}</span>
        </h3>
      </div>
      <div className="row d-flex mt-10" style={{ overflow: "visible" }}>
        <div className="card col-lg-10">
          <HighchartsReact
            ref={chartComponent}
            highcharts={Highcharts}
            options={options}
            // series={series}
          />
        </div>
        <div className="card col-lg-2 d-flex text-center justify-content-center">
          <h5>YTD Growth</h5>
          <p style={{fontSize: "20px", color:`${dt.ytdGrowth > 0 ? "#606368" : "#c85246"}`}}>{parseFloat(dt.ytdGrowth).toFixed()} % <br/> ({dt.ytdRest})</p>
          <h6>Market {currYear} YTD</h6>
          <p>{dt.ytdYearNow}</p>
          <h5>Market {currYear - 1} YTD</h5>
          <p>{dt.ytdYearOld}</p>
        </div>
      </div>
    </>
  );
}

export {
  TableOverview,
  TableTen,
  ChartOverviewMS,
  ChartMarketGrowth,
};
