import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { progressBarLine } from '../../../../../_metronic/helpers/components/MaterialReactTable/ComponentStyleRowsTable';
import { totalValue } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';

// type TypeTableMoMApprove = {
//     barColor: string,
//     data: {
//         columns: string[],
//         rows: object,
//     }
// }
type TypeTableMoMApprove = {
    barColor: string,
    data: {
        allData: any[],
        allYear: any[]
    },
    headerData: string[]
}

type TypeTableMoM = {
    barColor: string,
    data: any[],
    headerData: string[]
}

const GroupingTable1: React.FC<TypeTableMoM> = ({
    barColor,
    data,
    headerData
}) => {

    data[0]?.dates?.map((val: { month: string; year: string }) =>
        headerData.push(`${val.month} ${val.year}`)
    )
    headerData.push("Grand Total")

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '',
            color: theme.palette.common.black,
        }, [`&.${tableCellClasses.body}`]: {
            fontSize: 9,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    return (
        <Paper>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            {headerData?.map((val, i) => {
                                return (
                                    <StyledTableCell key={i}
                                        style={{ fontSize: 9, minWidth: 100 }}
                                        align="center">{val}</StyledTableCell>
                                )
                            })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((val, index) => {
                            return <React.Fragment key={index}>
                                <StyledTableRow hover tabIndex={-1}>
                                    <StyledTableCell rowSpan={6}>{val?.leasing ?? "NULL"}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell>Application In</StyledTableCell>
                                    {val?.data?.map((v) => {
                                        return <StyledTableCell align="center">{progressBarLine(v?.appin, val.maxAppin, barColor)}</StyledTableCell>
                                    })}
                                    <StyledTableCell style={{ fontWeight: "bold" }} align="center">{totalValue(val?.data?.map((val) => val.appin))}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell>Approve</StyledTableCell>
                                    {val?.data?.map((v) => {
                                        return <StyledTableCell align="center">{progressBarLine(v?.approve, val.maxApprove, barColor)}</StyledTableCell>
                                    })}
                                    <StyledTableCell style={{ fontWeight: "bold" }} align="center">{totalValue(val?.data?.map((val) => val.approve))}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell>DO</StyledTableCell>
                                    {val?.data?.map((v) => {
                                        return <StyledTableCell align="center">{progressBarLine(v?.do, val.maxDo, barColor)}</StyledTableCell>
                                    })}
                                    <StyledTableCell style={{ fontWeight: "bold" }} align="center">{totalValue(val?.data?.map((val) => val.do))}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell>Reject</StyledTableCell>
                                    {val?.data?.map((v) => {
                                        return <StyledTableCell align="center">{progressBarLine(v?.reject, val.maxReject, barColor)}</StyledTableCell>
                                    })}
                                    <StyledTableCell style={{ fontWeight: "bold" }} align="center">{totalValue(val?.data?.map((val) => val.reject))}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell>In Process</StyledTableCell>
                                    {val?.data?.map((v) => {
                                        return <StyledTableCell
                                            style={{ width: "25px" }}
                                            align="center">{progressBarLine(v?.inProcess, val.maxInProcess, barColor)}</StyledTableCell>
                                    })}
                                    <StyledTableCell
                                        style={{ fontWeight: "bold" }}
                                        align="center"
                                    >{totalValue(val?.data?.map((val) => val.inProcess))}</StyledTableCell>
                                </StyledTableRow>
                            </React.Fragment>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper >
    );
}

const GroupingTable2: React.FC<TypeTableMoMApprove> = ({
    barColor,
    data,
    headerData
}) => {

    data?.allYear?.map((val: { month: string; year: string }) =>
        headerData.push(`${val.month} ${val.year}`)
    )
    headerData.push("Grand Total")

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '',
            color: theme.palette.common.black,
        }, [`&.${tableCellClasses.body}`]: {
            fontSize: 9,
        },
    }));

    // const StyledTableRow = styled(TableRow)(({ theme }) => ({
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: theme.palette.action.hover,
    //     },
    //     // hide last border
    //     '&:last-child td, &:last-child th': {
    //         border: 0,
    //     },
    // }));

    // if (!data?.allData?.length) {
    //     return <Skeleton variant="rectangular" width="100%" height={500} />
    // }
    return (
        <Paper sx={{ width: '100%' }} >
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader size="small" aria-label="table with sticky header">
                    <TableHead>
                        <TableRow>
                            {headerData.map((val, i) => {
                                return (
                                    <StyledTableCell key={i + "allYear"} style={{ fontSize: 9, minWidth: 80 }} align="center">{val}</StyledTableCell>
                                )
                            })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* START:: LOPING */}
                        {data?.allData?.map((val, i) => {
                            return <React.Fragment key={i + "allData"}>
                                <TableRow key={i + "Leasings"}>
                                    <StyledTableCell rowSpan={(val?.dtDp?.length + 1) + (val?.dtDp?.map(v => v.dp_approve.length).reduce((a: number, b: number) => a + b, 0))}>{val?.leasing ?? "NULL"}</StyledTableCell>
                                </TableRow>
                                {val?.dtDp?.map((v, i) => {
                                    return <React.Fragment key={i + "dtDpss"}>
                                        <TableRow key={i + "dps"}>
                                            <StyledTableCell rowSpan={v.dp_approve.length + 1} key={i + "dp"}>{v.dp === null ? "NULL" : v.dp}</StyledTableCell>
                                        </TableRow>
                                        {v.data?.map((dt, i) => {
                                            return <TableRow key={i + "dpApprove"}>
                                                <StyledTableCell key={i + "dtDpApprove"} align="center">{dt.dp_approve ?? "NULL"}</StyledTableCell>
                                                {dt.dt.map((val, i) => {
                                                    return <StyledTableCell key={i + "datas"} align="center">{progressBarLine(val, dt?.max, barColor)}</StyledTableCell>
                                                })}
                                                <StyledTableCell
                                                    style={{ fontWeight: "bold" }}
                                                    align="center"
                                                >{totalValue(dt?.dt?.map((val) => val))}</StyledTableCell>
                                            </TableRow>
                                        })
                                        }
                                    </React.Fragment>
                                })}
                            </React.Fragment>

                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper >
    );
}

const GroupingTable3: React.FC<TypeTableMoMApprove> = ({
    barColor,
    data,
    headerData
}) => {

    data?.allYear?.map((val: { month: string; year: string }) =>
        headerData.push(`${val.month} ${val.year}`)
    )
    headerData.push("Grand Total")

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '',
            color: theme.palette.common.black,
        }, [`&.${tableCellClasses.body}`]: {
            fontSize: 9,
        },
    }));

    // const StyledTableRow = styled(TableRow)(({ theme }) => ({
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: theme.palette.action.hover,
    //     },
    //     // hide last border
    //     '&:last-child td, &:last-child th': {
    //         border: 0,
    //     },
    // }));
    return (
        <Paper>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            {headerData?.map((val, i) => {
                                return <StyledTableCell key={i + "Headers"}
                                    style={{ fontSize: 9, minWidth: 100 }}
                                    align="center">{val}</StyledTableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.allData?.map((val, i) => {
                            return <React.Fragment>
                                <TableRow key={i + "dts"}>
                                    <StyledTableCell rowSpan={val?.dp_approve?.length + 1} align="center" key={i + "dt1"}>{val.leasing === null ? "NULL" : val.leasing}</StyledTableCell>
                                </TableRow>
                                {val?.dp_approve?.map((v, i: number) => {
                                    return <TableRow key={i + "dt2Row"}>
                                        <StyledTableCell align="center" key={i + "dt2"}>{v.approve === null ? "NULL" : v.approve}</StyledTableCell>
                                        {v.data?.map((dt, i: number) => {
                                            return <StyledTableCell align="center" key={i + "dt3"}>{progressBarLine(dt, v?.max, barColor)}</StyledTableCell>
                                        })}
                                    </TableRow>
                                })}
                            </React.Fragment>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper >
    );
}
const GroupingTable4: React.FC<TypeTableMoMApprove> = ({
    barColor,
    data,
    headerData
}) => {

    data?.allYear?.map((val: { month: string; year: string }) =>
        headerData.push(`${val.month} ${val.year}`)
    )
    headerData.push("Grand Total")

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '',
            color: theme.palette.common.black,
        }, [`&.${tableCellClasses.body}`]: {
            fontSize: 9,
        },
    }));

    // const StyledTableRow = styled(TableRow)(({ theme }) => ({
    //     '&:nth-of-type(odd)': {
    //         backgroundColor: theme.palette.action.hover,
    //     },
    //     // hide last border
    //     '&:last-child td, &:last-child th': {
    //         border: 0,
    //     },
    // }));
    return (
        <Paper>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            {headerData?.map((val, i) => {
                                return <StyledTableCell key={i + "ss"}
                                    style={{ fontSize: 9, minWidth: 100 }}
                                    align="center">{val}</StyledTableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.allData?.map((val, i) => {
                            return <React.Fragment>
                                <TableRow key={i + "dts"}>
                                    <StyledTableCell rowSpan={(val.reject_length + 1) + (val.leasing?.map((v, i) => v.leasing_length).reduce((a, b) => a + b, 0))} align="center" key={i + "dt1"}>{val.reject_leasing === null ? "NULL" : val.reject_leasing}</StyledTableCell>
                                </TableRow>
                                {val?.leasing?.map((v, i: number) => {
                                    return <React.Fragment>
                                        <TableRow key={i + "dt2Row"}>
                                            <StyledTableCell rowSpan={v.leasing_length + 1} align="center" key={i + "dt2"}>{v.leasing === null ? "NULL" : v.leasing}</StyledTableCell>
                                        </TableRow>
                                        {v?.decision?.map((dt, i: number) => {
                                            return <TableRow>
                                                <StyledTableCell>{dt.decision}</StyledTableCell>
                                                {dt?.value?.map((d, i: number) => {
                                                   return <StyledTableCell>{progressBarLine(d, dt?.max, barColor)}</StyledTableCell>
                                                })}
                                                <StyledTableCell
                                                    style={{ fontWeight: "bold" }}
                                                    align="center"
                                                >{totalValue(dt?.value?.map((val) => val))}</StyledTableCell>
                                            </TableRow>
                                        })}
                                    </React.Fragment>
                                })}
                            </React.Fragment>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper >
    );
}

export { GroupingTable1, GroupingTable2, GroupingTable3, GroupingTable4 };
