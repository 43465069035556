import React, { useEffect, useState } from 'react'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput'
import { GroupingTable, SingleLoopTable } from './dataTable'
import { HighchartsSample } from '../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample'
import { equalsCheck } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers'
import { MscpMTDAPI } from '../../../../api/MscpAPI'

const ComponentHelper = ({ defaultValue, leasingOpt, dates, coverage, model }) => {

    const [leasing, setLeasing] = useState<any>([defaultValue.value])
    const [tempLeasing, setTempLeasing] = useState<Option[]>([defaultValue])
    const [dtPie, setDtPie] = useState<any>([])

    const [tableData, setTableData] = useState<any>([])
    const [dtInProcessTable, setInProcessTable] = useState<any>([])

    useEffect(() => {
        let tempLeasing = leasing.length > 0 ? leasing : leasingOpt?.map((val) => {
            return val.value
        })
        if (coverage.length > 0) {
            new MscpMTDAPI().getDtTable({ coverage, dates, leasing: tempLeasing, model }).then((res) => {
                setTableData(res.data)
            })
            new MscpMTDAPI().getDtPie({ coverage, dates, leasing: tempLeasing, model }).then((res) => {
                setDtPie({
                    title: [
                        'Approve', 'Approve Bersyarat', 'In Process', 'Reject'
                    ],
                    values: res.data
                })
            })
            new MscpMTDAPI().getDtTableInProcess({ coverage, dates, leasing: tempLeasing, model }).then((res) => {
                setInProcessTable(res.data)
            })
        }

    }, [leasing, dates, coverage, model, leasingOpt])

    return (
        <>
        {console.log(dtPie)}
        <div className="col-xxl-6 bd-highlight">
            <KTCard className='mb-5 mh-700px'>
                <div className="row mx-10 justify-content-center align-items-center">
                    <div className="col-xxl-6 my-5"
                        style={{ zIndex: 3 }}>
                        <FilterMultiSelect
                            label={"Leasing"}
                            name="leasing_1"
                            options={leasingOpt}
                            onMenuClose={() => {
                                let toFilter = tempLeasing?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    leasing
                                )
                                    ? null
                                    : setLeasing(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempLeasing(selected)
                            }}
                            value={tempLeasing}
                        />
                    </div>
                </div>
                <KTCardBody className="pl-5 px-2 pt-0">
                    <GroupingTable dt={tableData} />
                </KTCardBody>
            </KTCard>

            <KTCard className='mb-5 mh-550px'>
                <div className="card-header justify-content-center">
                    <h3 className="card-title align-items-center flex-column">
                        <span className="card-label fw-bold fs-1x">Status Process</span>
                    </h3>
                </div>
                <KTCardBody className="pl-5 px-2 pt-0">
                    <HighchartsSample
                        id="status_process_1"
                        type="pie"
                        height="450px"
                        positionLegend={"BottomCenter"}
                        series={dtPie}
                        chartStyle={{
                            spacingLeft: 5,
                            spacingRight: 5,
                        }}
                        optionsSetting={{
                            legend: {
                                enabled: true,
                                align: "right",
                                verticalAlign: "top",
                                layout: "vertical",
                                x: 0,
                                y: 100
                            }
                        }}
                    />
                </KTCardBody>
            </KTCard>

            <KTCard className='mb-5 mh-400px'>
                <div className="card-header justify-content-center">
                    <h3 className="card-title align-items-center flex-column">
                        <span className="card-label fw-bold fs-1">In Process by Model</span>
                    </h3>
                </div>
                <KTCardBody className="pl-5 px-2 pt-0">
                    <SingleLoopTable dt={dtInProcessTable}/>
                </KTCardBody>
            </KTCard>
        </div>
        </>
    )
}

export { ComponentHelper }