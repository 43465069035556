import moment from "moment";

// Sales Target ⓥ Achievement
export class ModelSalesTargetAchievementModel {
    constructor(data = {
        model: '',
        jan: 0,
        feb: 0,
        mar: 0,
        apr: 0,
        may: 0,
        jun: 0,
        jul: 0,
        aug: 0,
        sep: 0,
        oct: 0,
        nov: 0,
        dec: 0,
        total: 0,
    }) {
        const { model, jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec, total } = data;
        this['model'] = model;
        this['jan'] = jan;
        this['feb'] = feb;
        this['mar'] = mar;
        this['apr'] = apr;
        this['may'] = may;
        this['jun'] = jun;
        this['jul'] = jul;
        this['aug'] = aug;
        this['sep'] = sep;
        this['oct'] = oct;
        this['nov'] = nov;
        this['dec'] = dec;
        this['total'] = total;
    }
    async getdata(this: any) {
        const { model, jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec, total } = this;
        return [{
            header: "Model",
            id: "model",
            value: model,
            enableColumnOrdering: false,
            size: 60,
            fontSize: 9,
            footer: "Total Keseluruhan",
        }, {
            header: "Jan",
            id: "jan",
            value: jan,
            enableColumnOrdering: false,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 10,
            fontSize: 9,
            footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('jan'), 0),
        }, {
            header: "Feb",
            id: "feb",
            value: feb,
            enableColumnOrdering: false,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 10,
            fontSize: 9,
            footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('feb'), 0),
        }, {
            header: "Mar",
            id: "mar",
            value: mar,
            enableColumnOrdering: false,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 10,
            fontSize: 9,
            footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('mar'), 0),
        }, {
            header: "Apr",
            id: "apr",
            value: apr,
            enableColumnOrdering: false,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 10,
            fontSize: 9,
            footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('apr'), 0),
        }, {
            header: "May",
            id: "may",
            value: may,
            enableColumnOrdering: false,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 10,
            fontSize: 9,
            footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('may'), 0),
        }, {
            header: "Jun",
            id: "jun",
            value: jun,
            enableColumnOrdering: false,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 10,
            fontSize: 9,
            footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('jun'), 0),
        }, {
            header: "Jul",
            id: "jul",
            value: jul,
            enableColumnOrdering: false,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 10,
            fontSize: 9,
            footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('jul'), 0),
        }, {
            header: "Aug",
            id: "aug",
            value: aug,
            enableColumnOrdering: false,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 10,
            fontSize: 9,
            footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('aug'), 0),
        }, {
            header: "Sep",
            id: "sep",
            value: sep,
            enableColumnOrdering: false,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 10,
            fontSize: 9,
            footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('sep'), 0),
        }, {
            header: "Oct",
            id: "oct",
            value: oct,
            enableColumnOrdering: false,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 10,
            fontSize: 9,
            footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('oct'), 0),
        }, {
            header: "Nov",
            id: "nov",
            value: nov,
            enableColumnOrdering: false,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 10,
            fontSize: 9,
            footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('nov'), 0),
        }, {
            header: "Dec",
            id: "dec",
            value: dec,
            enableColumnOrdering: false,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 10,
            fontSize: 9,
            footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('dec'), 0),
        }, {
            header: "Total",
            id: "total",
            value: total,
            enableColumnOrdering: false,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 10,
            fontSize: 9,
            footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('total'), 0),
        }];
    }
}

// Leads Source & Conversion
export class ConversionLeadsToSpkTestDriveModel {
    constructor(data = {
        name: null,
        lead: null,
        spk: null,
        conv: null,
    }) {
        const { name, lead, spk, conv } = data;
        this['name'] = name;
        this['lead'] = lead;
        this['spk'] = spk;
        this['conv'] = conv;
    }
    async getdata(this: any) {
        const { name, lead, spk, conv } = this;
        return [{
            header: "Sumber Prospect",
            id: "name",
            value: name,
            size: 120,
            fontSize: 10,
        }, {
            header: "Leads",
            id: "lead",
            value: lead,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 100,
            fontSize: 10,
        }, {
            header: "SPK",
            id: "spk",
            value: spk,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 100,
            fontSize: 10,
        }, {
            header: "Conv.",
            id: "conv",
            value: `${conv ? conv : 0}%`,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 100,
            fontSize: 10,
        }];
    }
}

// Leads To Spk
export class LeadsToSpkModel {
    constructor(data = {
        id_target_lead_to_spk: "",
        month: "",
        outlet: "",
        salesman: "",
        salesspv: "",
        spk_target_name: "",
        contact_number: "",
        model: "",
        followup_first: "",
        issues: "",
        next_followup: "",
        followup_second: "",
        loss: false,
        re_fu: false,
        spk: false,
    }) {
        const { id_target_lead_to_spk, month, outlet, salesman, salesspv, spk_target_name, contact_number, model, followup_first, issues, next_followup, followup_second, loss, re_fu, spk } = data;
        this['id_target_lead_to_spk'] = id_target_lead_to_spk;
        this['month'] = month;
        this['outlet'] = outlet;
        this['salesman'] = salesman;
        this['salesspv'] = salesspv;
        this['spk_target_name'] = spk_target_name;
        this['contact_number'] = contact_number;
        this['model'] = model;
        this['followup_first'] = followup_first;
        this['issues'] = issues;
        this['next_followup'] = next_followup;
        this['followup_second'] = followup_second;
        this['loss'] = loss;
        this['re_fu'] = re_fu;
        this['spk'] = spk;
    }
    async getdata(this: any) {
        const { month, outlet, salesman, salesspv, spk_target_name, contact_number, model, followup_first, issues, next_followup, followup_second, loss, re_fu, spk } = this;
        return [{
            header: "Month",
            id: "month",
            value: moment(`${month}`).format("MMM"),
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            // size: 70,
            fontSize: 10,
        }, {
            header: "Outlet",
            id: "outlet",
            value: outlet,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            // size: 70,
            fontSize: 10,
        }, {
            header: "Salesman",
            id: "salesman",
            value: salesman,
            // size: 70,
            fontSize: 10,
        }, {
            header: "Sales SPV",
            id: "salesspv",
            value: salesspv,
            // size: 70,
            fontSize: 10,
        }, {
            header: "SPK Target Name",
            id: "spk_target_name",
            value: spk_target_name,
            // size: 70,
            fontSize: 10,
        }, {
            header: "Contact Number",
            id: "contact_number",
            value: contact_number,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            // size: 70,
            fontSize: 10,
        }, {
            header: "Model",
            id: "model",
            value: model,
            // size: 70,
            fontSize: 10,
        }, {
            header: "FOLLOW UP RESULT / LEADS COMMENTS",
            id: "followup_first",
            value: followup_first,
            size: 250,
            fontSize: 10,
        }, {
            header: "CUSTOMER OBJECTION / ISSUES NEED TO ADDRESSED",
            id: "issues",
            value: issues,
            size: 270,
            fontSize: 10,
        }, {
            header: "NEXT FOLLOW UP DATE",
            id: "next_followup",
            value: next_followup,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            // size: 100,
            fontSize: 10,
        }, {
            header: "FOLLOW UP RESULT / LEADS COMMENTS",
            id: "followup_second",
            value: followup_second,
            size: 250,
            fontSize: 10,
        }, {
            header: "LOSS",
            id: "loss",
            value: loss,
            Cell: ({ cell }) => {
                return cell.getValue() ? <span className="fa-2x">&#9745;</span> : '';
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "RE-FU",
            id: "re_fu",
            value: re_fu,
            Cell: ({ cell }) => {
                return cell.getValue() ? <span className="fa-2x">&#9745;</span> : '';
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "SPK",
            id: "spk",
            value: spk,
            Cell: ({ cell }) => {
                return cell.getValue() ? <span className="fa-2x">&#9745;</span> : '';
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }];
    }
}

// Spk To DO
export class SpkToDoModel {
    constructor(data = {
        id_target_spk_to_do: "",
        month: "",
        outlet: "",
        salesman: "",
        salesspv: "",
        do_target_name: "",
        contact_number: null,
        model: "",
        do_date_plan: null,
        cash: false,
        credit: true,
        leasing: "",
        submit_order: "",
        survey: "",
        doc_lengkap: "",
        ca_process: "",
        decision: "",
        decision_date: "",
        appeal: null,
        appeal_date: null,
        final_result: "",
        do: "",
        carry_over: true,
    }) {
        const { id_target_spk_to_do, month, outlet, salesman, salesspv, do_target_name, contact_number, model, do_date_plan, cash, credit, leasing, submit_order, survey, doc_lengkap, ca_process, decision, decision_date, appeal, appeal_date, final_result, do: dos, carry_over } = data;
        this['id_target_spk_to_do'] = id_target_spk_to_do;
        this['month'] = month;
        this['outlet'] = outlet;
        this['salesman'] = salesman;
        this['salesspv'] = salesspv;
        this['do_target_name'] = do_target_name;
        this['contact_number'] = contact_number;
        this['model'] = model;
        this['do_date_plan'] = do_date_plan;
        this['cash'] = cash;
        this['credit'] = credit;
        this['leasing'] = leasing;
        this['submit_order'] = submit_order;
        this['survey'] = survey;
        this['doc_lengkap'] = doc_lengkap;
        this['ca_process'] = ca_process;
        this['decision'] = decision;
        this['decision_date'] = decision_date;
        this['appeal'] = appeal;
        this['appeal_date'] = appeal_date;
        this['final_result'] = final_result;
        this['do'] = dos;
        this['carry_over'] = carry_over;
    }
    async getdata(this: any) {
        const { month, outlet, salesman, salesspv, do_target_name, contact_number, model, do_date_plan, cash, credit, leasing, submit_order, survey, doc_lengkap, ca_process, decision, decision_date, appeal, appeal_date, final_result, do: dos, carry_over } = this;
        return [{
            header: "Month",
            id: "month",
            value: moment(`${month}`).format("MMM"),
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Outlet",
            id: "outlet",
            value: outlet,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Salesman",
            id: "salesman",
            value: salesman,
            fontSize: 10,
        }, {
            header: "Sales SPV",
            id: "salesspv",
            value: salesspv,
            fontSize: 10,
        }, {
            header: "DO Target Name",
            id: "do_target_name",
            value: do_target_name,
            fontSize: 10,
        }, {
            header: "Contact Number",
            id: "contact_number",
            value: contact_number,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Model",
            id: "model",
            value: model,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "DO DATE PLAN",
            id: "do_date_plan",
            value: do_date_plan,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "CASH",
            id: "cash",
            value: cash,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                if (cell.getValue()) {
                    return "Yes";
                }
                if (cell.row.original['credit']) {
                    return "";
                }
            },
            fontSize: 10,
        }, {
            header: "Credit",
            id: "credit",
            value: credit,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                if (cell.getValue()) {
                    return "Yes";
                }
                if (cell.row.original['cash']) {
                    return "";
                }
            },
            fontSize: 10,
        }, {
            header: "Leasing",
            id: "leasing",
            value: leasing,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Submit Order",
            id: "submit_order",
            value: submit_order,
            Cell: ({ cell }) => {
                return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Survey",
            id: "survey",
            value: survey,
            Cell: ({ cell }) => {
                return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Document Lengkap",
            id: "doc_lengkap",
            value: doc_lengkap,
            Cell: ({ cell }) => {
                return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Ca Process",
            id: "ca_process",
            value: ca_process,
            Cell: ({ cell }) => {
                return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Decision",
            id: "decision",
            value: decision,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Decision Date",
            id: "decision_date",
            value: decision_date,
            Cell: ({ cell }) => {
                return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Appeal",
            id: "appeal",
            value: appeal,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Appeal Date",
            id: "appeal_date",
            value: appeal_date,
            Cell: ({ cell }) => {
                return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Final Result",
            id: "final_result",
            value: final_result,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Do",
            id: "dos",
            value: dos,
            Cell: ({ cell }) => {
                return cell.getValue() ? moment(`${cell.getValue()}`).format("DD MMM YYYY") : "";
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }, {
            header: "Carry Over",
            id: "carry_over",
            value: carry_over,
            Cell: ({ cell }) => {
                if (cell.getValue()) {
                    return "Yes";
                }
                if (!cell.getValue()) {
                    return "No";
                }
                if (cell.getValue() === '' || cell.getValue() === null) {
                    return "";
                }
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 10,
        }];
    }
}