/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { PageTitle } from "../../../../../../../_metronic/layout/core";
import { KTCard, KTCardBody, KTIcon } from "../../../../../../../_metronic/helpers";
import { MaterialReactTableSimple } from "../../../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable";
import DataTableMuiModel from "../../../../../../models/DataTable/dataTableModal";
import { MasterDataSalesAPI } from "../../../../../../api/MasterDataSalesAPI";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormUploadFilesModal } from "../../../../../../../_metronic/helpers/components/form-actions/ModalForm";
import {
    InputDatePicker,
    InputFileUpload,
    InputNumber,
    InputText,
} from "../../../../../../../_metronic/helpers/components/form-actions/FormInput";
import { MasterOutletTargetModel, MasterTargetAllModel } from "../../../../../../models/sales/MasterDataSalesAnalystModel";
export class TargetAllIndex extends Component {
    constructor(props: any) {
        super(props);
        this["title"] = "Target SPK, DO, RS, PO & TestDrive";
        this["api"] = new MasterDataSalesAPI();
        this["initialData"] = new MasterOutletTargetModel();
        this["datatable"] = [];
        this["columntable"] = [];
        this["state"] = {
            isLoading: true,
            progressInfos: {
                percentage: "",
                error: true,
            },
            modal: {
                loading: false,
                show: false,
                initialData: this["initialData"],
                isEdit: false,
            },
            alert: {
                activeId: [],
                show: false,
            },
        };
    }
    //** Start: Component */
    async componentDidMount() {
        await this.LoadData();
    }
    //** End: Component */
    //* ----------------------------------------------------------------------------- */
    //** Start: Load Data List */

    //** Begin:: Handle Modal && Alert */
    handleModal = {
        upload: async () => {
            let state = { ...this["state"] };
            state = {
                progressInfos: {
                    percentage: "",
                    error: true,
                },
                modal: {
                    show: true,
                    title: `Upload File Excel`,
                    isEdit: false,
                    initialData: this["initialData"],
                },
            };
            this.setState({ ...state });
        },
        edit: async (dataFromModel: any) => {
            let state = { ...this["state"] };
            state = {
                modal: {
                    show: true,
                    title: `Edit Excel Data`,
                    isEdit: true,
                    initialData: dataFromModel,
                },
            };
            this.setState({ ...state });
        },
        hide: () => {
            let state = { ...this["state"] };
            state = {
                modal: {
                    show: false,
                    initialData: this["initialData"],
                },
            };
            this.setState({ ...state });
        },
    };
    //** End:: Handle Modal && Alert */
    async LoadData() {
        this.setState({ loading: true });
        let data = await this["api"].getDataTargetAll();
        this["DataTableMuiModel"] = new DataTableMuiModel({ readOnly: true });
        data["data"].map((item: any, i: number) => {
            let dataModel = new MasterTargetAllModel(item);
            this["DataTableMuiModel"].add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null,
            });
            return item;
        });
        this["datatable"] = await this["DataTableMuiModel"].getDatas();
        this["columntable"] = await this["DataTableMuiModel"].getColumn();
        this.setState({ ...this["state"], loading: false });
    }
    //** End: Load Data List */
    render() {
        return (
            <>
                <PageTitle breadcrumbs={[{ title: "Master Data", path: "/sales/target-all", isSeparator: false, isActive: false }]}>{this["title"]}</PageTitle>
                {/* Begin:: Card */}
                <KTCard>
                    <div className="card-header border-0 pt-6">
                        {/* Card Title */}
                        <div className="card-title"></div>
                        {/* Begin:: Card Toolbar */}
                        <div className="card-toolbar">
                            <div
                                className="d-flex justify-content-end"
                                data-kt-user-table-toolbar="base"
                            >
                                <button
                                    type={"button"}
                                    className={"btn btn-sm btn-light-primary"}
                                    onClick={() => {
                                        this["handleModal"].upload();
                                    }}
                                >
                                    <KTIcon iconName={"file-up"} className={"fs-4 me-2"} /> Upload
                                    File
                                </button>
                            </div>
                        </div>
                        {/* End:: Card Toolbar */}
                    </div>
                    {/* Begin:: Card Body */}
                    <KTCardBody className="py-4">
                        <div className="table-responsive">
                            <MaterialReactTableSimple
                                data={this["datatable"]}
                                columns={this["columntable"]}
                                isLoading={this["state"]["loading"]}
                            />
                        </div>
                    </KTCardBody>
                    {/* End:: Card Body */}
                </KTCard>
                {/* End:: Card */}

                {/* Begin:: Modal */}
                <Formik
                    enableReinitialize={true}
                    initialValues={this["state"]["modal"]["initialData"]}
                    validationSchema={
                        !this["state"]["modal"]["isEdit"]
                            ? Yup.object().shape({
                                excel_i_spk_do_rs_po_leads_testdrive: Yup.string().required("Files is required"),
                            })
                            : null
                    }
                    onSubmit={async (values, { resetForm }) => {
                        const data = { excel_i_spk_do_rs_po_leads_testdrive: values["excel_i_spk_do_rs_po_leads_testdrive"] };
                        await this["api"].UploadFilesTargetAll(data, (event: { loaded: number; total: number; }) => {
                            this.setState({
                                progressInfos: {
                                    percentage: Math.round((100 * event.loaded) / event.total),
                                },
                            });
                        });
                        resetForm();
                        setTimeout(async () => {
                            this["handleModal"].hide();
                            this.LoadData();
                        }, 2000);
                    }}
                >
                    {(formik) => (
                        <FormUploadFilesModal
                            title={this["state"]["modal"]["title"]}
                            show={this["state"]["modal"]["show"]}
                            onHide={this["handleModal"]["hide"]}
                            onConfirm={formik.handleSubmit}
                            size={"lg"}
                            content={
                                <>
                                    {!this["state"]["modal"]["isEdit"] ? (
                                        <div className="row mb-3">
                                            <InputFileUpload
                                                name="excel_i_spk_do_rs_po_leads_testdrive"
                                                progressInfos={this["state"]["progressInfos"]}
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                formik={formik}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <InputDatePicker
                                                name="month"
                                                label="Month/Year"
                                                showMonthYearPicker
                                                formik={formik}
                                            />
                                            <InputText
                                                name="brand"
                                                label={"Nama Brand"}
                                                formik={formik}
                                            />
                                            <InputNumber name="rs" label={"RS"} formik={formik} />
                                            <InputNumber name="ws" label={"WS"} formik={formik} />
                                        </>
                                    )}
                                </>
                            }
                        />
                    )}
                </Formik>
                {/* End:: Modal */}
            </>
        );
    }
}
export default TargetAllIndex;
