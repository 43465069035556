import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { totalValue } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';

export function GroupingTable({ dt }) {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '',
            color: theme.palette.common.black,
        }, [`&.${tableCellClasses.body}`]: {
            fontSize: 9,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const column_header = ['Status', 'DP Approve']
    const dtHeader = dt[0]?.percent?.map(val => val === null ? "null" : val)

    console.log("dtHeader", dtHeader);

    dt[0]?.percent?.map(val => {
        return column_header.push(val === null ? "null" : val)
    })
    column_header.push('Total')

    return (
        <Paper sx={{ width: '100%' }} >
            <TableContainer sx={{ maxHeight: 600, zIndex: 0 }}>
                <Table stickyHeader size="small" aria-label="table with sticky header">
                    <TableHead>
                        <TableRow>
                            {column_header.map(val => {
                                return (
                                    <StyledTableCell key={val} style={{ fontSize: 9 }} align="center">{val}</StyledTableCell>
                                )
                            })
                            }
                        </TableRow>
                    </TableHead>
                    {dt.length === 0 ? (
                        <TableBody>
                            <TableRow>
                                <TableCell>Loading Data.....</TableCell>
                            </TableRow>
                        </TableBody>
                    ) :
                        <TableBody>
                            {/* START:: LOPING */}
                            <StyledTableRow hover tabIndex={-1}>
                                <StyledTableCell>{dt[0]?.status}</StyledTableCell>
                                <StyledTableCell align="center">{dt[0]?.approve}</StyledTableCell>
                                {dt[0]?.appIn?.map((val) =>
                                    <StyledTableCell align="center">{val.appin}</StyledTableCell>
                                )}
                                <StyledTableCell style={{ fontWeight: "bold" }} align="center">{totalValue(dt[0]?.appIn?.map(val => val.appin))}</StyledTableCell>
                            </StyledTableRow>
                            {/* Approve */}
                            <StyledTableRow hover tabIndex={-1}>
                                <StyledTableCell rowSpan={dt[1]?.dtApprove.length + 1}>Approve</StyledTableCell>
                            </StyledTableRow>
                            {dt[1]?.approve?.map((val, i) =>
                                <StyledTableRow>
                                    <StyledTableCell align="center">{val === null ? "null" : val}</StyledTableCell>
                                    {dt[1]?.dtApprove[i]?.map((v) =>
                                        <StyledTableCell align="center">{v}</StyledTableCell>
                                    )}
                                    <StyledTableCell style={{ fontWeight: "bold" }} align="center">{totalValue(dt[1]?.dtApprove[i]?.map(val => val))}</StyledTableCell>
                                </StyledTableRow>
                            )}
                            {/* End Approve */}

                            {/* DO */}
                            <StyledTableRow hover tabIndex={-1}>
                                <StyledTableCell rowSpan={dt[1]?.dtApprove.length + 1}>DO</StyledTableCell>
                            </StyledTableRow>
                            {dt[1]?.approve?.map((val, i) =>
                                <StyledTableRow>
                                    <StyledTableCell align="center">{val === null ? "null" : val}</StyledTableCell>
                                    {dt[1]?.dtDo[i]?.map((v) =>
                                        <StyledTableCell align="center">{v}</StyledTableCell>
                                    )}
                                    <StyledTableCell style={{ fontWeight: "bold" }} align="center">{totalValue(dt[1]?.dtDo[i]?.map(val => val))}</StyledTableCell>
                                </StyledTableRow>
                            )}
                            {/* End DO */}

                            {/* In Process */}
                            <StyledTableRow hover tabIndex={-1}>
                                <StyledTableCell rowSpan={dt[1]?.dtApprove.length + 1}>In Process</StyledTableCell>
                            </StyledTableRow>
                            {dt[1]?.approve?.map((val, i) =>
                                <StyledTableRow>
                                    <StyledTableCell align="center">{val === null ? "null" : val}</StyledTableCell>
                                    {dt[1]?.dtInProcess[i]?.map((v) =>
                                        <StyledTableCell align="center">{v}</StyledTableCell>
                                    )}
                                    <StyledTableCell style={{ fontWeight: "bold" }} align="center">{totalValue(dt[1]?.dtInProcess[i]?.map(val => val))}</StyledTableCell>
                                </StyledTableRow>
                            )}
                            {/* End In Process */}

                            {/* Reject */}
                            <StyledTableRow hover tabIndex={-1}>
                                <StyledTableCell>{dt[4]?.status}</StyledTableCell>
                                <StyledTableCell align="center">{dt[4]?.approve}</StyledTableCell>
                                {dt[4]?.reject?.map((val) =>
                                    <StyledTableCell align="center">{val.rejection}</StyledTableCell>
                                )}
                                <StyledTableCell style={{ fontWeight: "bold" }} align="center">{totalValue(dt[4]?.reject?.map(val => val.rejection))}</StyledTableCell>
                            </StyledTableRow>
                            {/* End Reject */}
                        </TableBody>
                    }
                </Table>
            </TableContainer>
        </Paper >
    );
}
export function SingleLoopTable({ dt }) {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '',
            color: theme.palette.common.black,
        }, [`&.${tableCellClasses.body}`]: {
            fontSize: 9,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const column_header = ['Model']
    // console.log(dt);

    dt[0]?.percent?.map((v) => column_header.push(v === null ? "null" : v))
    column_header.push('Total')

    return (
        <Paper sx={{ width: '100%' }} >
            <TableContainer sx={{ maxHeight: 300 }}>
                <Table stickyHeader size="small" aria-label="table with sticky header">
                    <TableHead>
                        <TableRow>
                            {column_header.map(val => {
                                return (
                                    <StyledTableCell key={val} style={{ fontSize: 9 }} align="center">{val}</StyledTableCell>
                                )
                            })
                            }
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {/* START:: LOPING */}
                        {dt?.map((v, i) => {
                            return (
                                <React.Fragment key={i + 21}>
                                    <StyledTableRow key={i} hover tabIndex={-1}>
                                        <StyledTableCell>{v?.model}</StyledTableCell>
                                        {v?.dtPercent.map((val, i) => {
                                            return (
                                                <StyledTableCell key={i + 100} align="center">{val}</StyledTableCell>
                                            )
                                        })}
                                        <StyledTableCell style={{ fontWeight: "bold" }} align="center">{v?.dtPercent.reduce((a, b) => a + b, 0)}</StyledTableCell>
                                    </StyledTableRow>
                                </React.Fragment>
                            )
                        })}
                        {/* END:: LOPING */}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper >
    );
}