import * as React from 'react';
import { ThemeProvider, createTheme, useTheme, styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableFooter } from '@mui/material';
import { ThemeModeComponent } from '../../../../_metronic/assets/ts/layout';
import { useThemeMode } from '../../../../_metronic/partials';

type TableSM = {
    varian: string,
    w1F: number,
    w1A: number,
    w2F: number,
    w2A: number,
    w3F: number,
    w3A: number,
    w4F: number,
    w4A: number,
    w5F: number,
    w5A: number,
    totalF: number,
    totalA: number
}
type TableDO = {
    varian: string,
    doForecast: number,
    doActual: number
}

type TypeTable = {
    data: {
        columns: string[],
        rows: object,
    }
}

const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";
export default function MultiLoopTable({
    maxHeight,
    data,
    type
}) {
    const { mode } = useThemeMode();
    const calculatedMode = mode === "system" ? systemMode : mode;
    const globalTheme = useTheme();
    const tableTheme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: calculatedMode, //let's use the same dark/light mode as the global theme
                    primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
                    info: {
                        main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            calculatedMode === 'light'
                                ? 'rgb(255,255,255)' //random light yellow color for the background in light mode
                                : '#000', //pure black table in dark mode for fun
                    },
                }
            }),
        [calculatedMode, globalTheme.palette.secondary]
    );

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#fbfcfe',
            // fontFamily: 'serif',
            fontSize: 9.5,
        }, [`&.${tableCellClasses.body}`]: {
            fontSize: 9.5,
            // fontFamily: 'serif',
        },

    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            border: 0,
            fontSize: 9.5,
            // fontFamily: 'serif',
            color: calculatedMode === 'light' ? 'black' : 'white',
        },
        // hide last border
        '&:last-child(2) td': {
            backgroundColor: theme.palette.action.hover,
            border: 0,
            fontSize: 9.5,
            // fontFamily: 'serif',
            color: calculatedMode === 'light' ? 'black' : 'white',
        },
    }));
    const doColumn_header = ['Type', 'Varian', 'DO Forecast', 'DO Actual']
    const column_header1 = ['Type', 'Varian', 'F', 'A', 'F', 'A', 'F', 'A', 'F', 'A', 'F', 'A', 'F', 'A']
    const column_header2 = ['F', 'A']

    let column_header = [{}];
    for (let i = 1; i <= 5; i++) {
        let obj = {};
        obj['values'] = `W${i}`;
        column_header.push(obj);
    }
    column_header.shift();
    column_header.push({
        values: 'Total Keseluruhan'
    });

    const smHeader = <React.Fragment>
        <TableRow>
            <StyledTableCell style={{
                top: 0,
                fontSize: 9.5,
                position: 'sticky',
                backgroundColor: calculatedMode === 'light' ? '#e0e0e0' : '#1c1c1c',
                color: calculatedMode === 'light' ? 'black' : 'white',
                zIndex: 1
            }}
                colSpan={column_header1.length}
                align='right'>Week / F / A
            </StyledTableCell>
        </TableRow>
        <StyledTableRow>
            <StyledTableCell colSpan={2}></StyledTableCell>
            {column_header.map((val, i) => {
                return (
                    <React.Fragment key={i} >
                        <StyledTableCell style={{
                            top: 32,
                            fontSize: 9.5,
                            backgroundColor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                            color: calculatedMode === 'light' ? 'black' : 'white',
                            // zIndex: `${(i === column_header.length - 1) ? 1 : 0}`
                        }} colSpan={column_header2.length} align="center">{val['values']}
                        </StyledTableCell>
                    </React.Fragment>
                )
            })}
        </StyledTableRow>
        <StyledTableRow>
            {column_header1.map((val, i) => {
                return (
                    <React.Fragment key={i} >
                        <TableCell style={{
                            position: "sticky",
                            top: 32,
                            fontSize: 10,
                            fontWeight: 'bold',
                            backgroundColor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                            color: calculatedMode === 'light' ? 'black' : 'white',
                            zIndex: 2
                            // zIndex: `${i === 0 || (i === column_header.length - 1) ? 1 : 0}`
                        }} align="center">{val}
                        </TableCell>
                    </React.Fragment>
                )
            })}
        </StyledTableRow>
    </React.Fragment>

    const doForecastHeader = <React.Fragment>
        <StyledTableRow>
            {doColumn_header.map((val, i) => {
                return (
                    <React.Fragment key={i} >
                        <TableCell style={{
                            position: "sticky",
                            top: 0,
                            fontSize: 10,
                            zIndex: 2,
                            backgroundColor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                            color: calculatedMode === 'light' ? 'black' : 'white',
                        }} align="center">{val}
                        </TableCell>
                    </React.Fragment>
                )
            })}
        </StyledTableRow>
    </React.Fragment>

    const smTableData = data?.map((row, i) => {
        return (
            <React.Fragment key={"dataTableMME"}>
                <StyledTableRow key={i}>
                    <StyledTableCell rowSpan={row.dt.length + 1}>{row.type}</StyledTableCell>
                </StyledTableRow>
                {row.dt.map((val: TableSM, i) => {
                    return <StyledTableRow key={`child+${i}+${val.varian}`}>
                        <StyledTableCell>{val.varian}</StyledTableCell>
                        <StyledTableCell align="center">{val.w1F || 0}</StyledTableCell>
                        <StyledTableCell align="center">{val.w1A || 0}</StyledTableCell>
                        <StyledTableCell align="center">{val.w2F || 0}</StyledTableCell>
                        <StyledTableCell align="center">{val.w2A || 0}</StyledTableCell>
                        <StyledTableCell align="center">{val.w3F || 0}</StyledTableCell>
                        <StyledTableCell align="center">{val.w3A || 0}</StyledTableCell>
                        <StyledTableCell align="center">{val.w4F || 0}</StyledTableCell>
                        <StyledTableCell align="center">{val.w4A || 0}</StyledTableCell>
                        <StyledTableCell align="center">{val.w5F || 0}</StyledTableCell>
                        <StyledTableCell align="center">{val.w5A || 0}</StyledTableCell>
                        <StyledTableCell
                            style={{
                                backgroundColor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                                position: 'sticky',
                                right: 45
                            }}
                            align="center">{val.totalF}</StyledTableCell>
                        <StyledTableCell
                            style={{
                                backgroundColor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                                position: 'sticky',
                                right: 0
                            }}
                            align="center">{val.totalA}</StyledTableCell>
                    </StyledTableRow>
                })}
            </React.Fragment>
        )
    })
    const doTableData = data?.map((row, i) => {
        return (
            <React.Fragment key={"dataTableMME"}>
                <StyledTableRow key={i}>
                    <StyledTableCell rowSpan={row.dt.length + 1}>{row.type}</StyledTableCell>
                </StyledTableRow>
                {row.dt.map((val: TableDO, i: number) => {
                    return <TableRow key={`child+${i}+${val.varian}`}>
                        <TableCell>{val.varian}</TableCell>
                        <TableCell style={{ backgroundColor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c' }} align="center">{val.doForecast || 0}</TableCell>
                        <TableCell align="center">{val.doActual || 0}</TableCell>
                    </TableRow>
                })}
            </React.Fragment>
        )
    })
    return (
        <ThemeProvider theme={tableTheme}>
            <TableContainer sx={
                {
                    maxHeight: maxHeight,
                    padding: 0,
                    '& tr > *:first-of-type': {
                        position: 'sticky',
                        left: 0,
                        top: 32,
                        fontSize: 9.5,
                        color: calculatedMode === 'light' ? 'black' : 'white',
                        bgcolor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                    },
                    '& tr > *:last-child': {
                        position: 'sticky',
                        right: 0,
                        fontSize: 9.5,
                        fontWeight: "bold",
                        color: calculatedMode === 'light' ? 'black' : 'white',
                        bgcolor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                    },
                }}>
                <Table size="small" aria-label="table with sticky header">
                    <TableHead >
                        {type === "smtable" ? smHeader : doForecastHeader}
                    </TableHead>
                    <TableBody>
                        {type === "smtable" ? smTableData : doTableData}
                    </TableBody>
                    <TableFooter sx={{
                        position: "sticky",
                        bottom: 0,
                        fontFamily: 'serif',
                        fontSize: 9.5,
                        backgroundColor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                        color: calculatedMode === 'light' ? 'black' : 'white',
                    }}>
                        {/* <TableRow hover tabIndex={-1}>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }}>Total Keseluruhan</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">1.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">2.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">3.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell align="center">100</StyledTableCell>
                        </TableRow> */}
                    </TableFooter>
                </Table>
            </TableContainer>
        </ThemeProvider >
    );
}
