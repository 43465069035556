import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
// import Chart from "react-apexcharts";
import Highcharts from "highcharts";
import { ChartYTDHelper, TableYTDHelper } from "../TableHelper";
import { PerformanceAPI } from "../../../../api/PerformanceAPI";
import { LinearProgress } from "@mui/material";
import FilterMultiSelect from "../../../../../_metronic/helpers/components/form-actions/FilteringInput";
import { equalsCheck } from "../../../../../_metronic/helpers/helpers-data/AssetHelpers";
require("highcharts/modules/exporting")(Highcharts);

function YearonYear() {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [dt, setDt] = useState([]);
  const [asmVal, setasmVal] = useState([]);
  // const asmOpt = ["Sulbagut & Ternate", "Sulbagsel & Bali","Java"];
  const [asmOpt, setasmOpt] = useState([]);
  const [smOpt, setsmOpt] = useState([]);
  const [smVal, setsmVal] = useState([]);
  const [outletOpt, setoutletOpt] = useState([]);
  const [outletVal, setoutletVal] = useState([]);
  const [cov, setCoverage] = useState("");
  const [arrSelect, setArrSelect] = useState([]);
  const [smSelect, setSmSelect] = useState([]);
  const [outletSelect, setOutletSelect] = useState([]);

  async function getFilter() {
    let val = {
      asm: asmVal.slice(),
      sm: smVal.slice(),
      outlet: outletVal.slice(),
    };
    return await new PerformanceAPI()
      .getFilter(val)
      .then((res) => {
        let tempAsm = [];
        let tempsm = [];
        let tempoutlet = [];
        // console.log("Res Data",res.data);
        res.data.dataAsm.map((val) => {
          // console.log("Val",val);
          return tempAsm.push({
            label: val.area,
            value: val.id_team_asm,
          });
        });
        res.data.dataSm.map((val) => {
          return tempsm.push({
            label: val.region,
            value: val.id_team_sm,
          });
        });
        res.data.dataOutlet.map((val) => {
          return tempoutlet.push({
            label: val.text,
            value: val.id_outlet,
          });
        });
        setasmOpt(tempAsm);
        setsmOpt(tempsm);
        setoutletOpt(tempoutlet);
        setCoverage(res.data.coverage);
        return res;
      })
      .then((res) => {
        getData(res.data.coverage);
      });
  }
  async function getData(val) {
    setLoading2(true);
    await new PerformanceAPI()
      .getYTD(val)
      .then((res) => {
        setDt(res.data);
      })
      .then(() => {
        setLoading(false);
        setLoading2(false);
      });
  }
  useEffect(() => {
    // getData(cov);
    getFilter();
  }, [asmVal, smVal, outletVal, cov]);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <>
        <PageTitle
          breadcrumbs={[
            {
              title: "Performance",
              path: "/sales/ytd",
              isSeparator: false,
              isActive: false,
            },
          ]}
        >
          {"YTD Performance"}
        </PageTitle>
        <div className="row d-flex justify-content-around mb-10">
          {/*
          <MultipleSelectCheckmarks
            title={"ASM"}
            datas={asmOpt}
            dt={asmVal}
            setDt={setasmVal}
          />
          <MultipleSelectCheckmarks
            title={"SM"}
            datas={smOpt}
            dt={smVal}
            setDt={setsmVal}
          />
          <MultipleSelectCheckmarks
            title={"Outlet"}
            datas={outletOpt}
            dt={outletVal}
            setDt={setoutletVal}
          /> */}
          <div className="col-xxl-3 mb-5">
            <FilterMultiSelect
              options={asmOpt}
              label="ASM"
              isSelectAll={true}
              value={arrSelect}
              onMenuClose={() => {
                return equalsCheck(
                  arrSelect.map((val) => val.value),
                  asmVal
                )
                  ? null
                  : setasmVal(arrSelect.map((val) => val.value));
              }}
              onChange={(selected) => {
                setArrSelect(selected);
              }}
            />
          </div>
          <div className="col-xxl-3 mb-5">
            <FilterMultiSelect
              options={smOpt}
              label="SM"
              isSelectAll={true}
              value={smSelect}
              onMenuClose={() => {
                return equalsCheck(
                  smSelect.map((val) => val.value),
                  smVal
                )
                  ? null
                  : setsmVal(smSelect.map((val) => val.value));
              }}
              onChange={(selected) => {
                setSmSelect(selected);
              }}
            />
          </div>
          <div className="col-xxl-3 mb-5">
            <FilterMultiSelect
              options={outletOpt}
              label="Outlet"
              isSelectAll={true}
              value={outletSelect}
              onMenuClose={() => {
                return equalsCheck(
                  outletSelect.map((val) => val.value),
                  outletVal
                )
                  ? null
                  : setoutletVal(outletSelect.map((val) => val.value));
              }}
              onChange={(selected) => {
                setOutletSelect(selected);
              }}
            />
          </div>
        </div>

        {!loading2 ? (
          <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2">
            {Object.entries(dt)
              .reverse()
              .map((e, i, a) => {
                // console.log("maps",e);
                return (
                  <div
                    key={e}
                    className="col mb-20"
                    // style={{ maxWidth: "60rem" }}
                  >
                    <div className="card p-5">
                      <TableYTDHelper
                        title={`${new Date().getFullYear()} FY Performance (${
                          e[1].title
                        })`}
                        datas={e[1]}
                        category={`${e[1].title}`}
                      />
                      <div>
                        <ChartYTDHelper dt={e[1]} />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <LinearProgress />
        )}
      </>
    );
  }
}

export default YearonYear;
