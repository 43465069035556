import { numberWithCommas } from "../../../_metronic/helpers/helpers-data/AssetHelpers";
import moment from "moment";

//**BEGIN:: Model National Information */
// Gaikindo
export class MasterNationalGaikindoModel {
    constructor(
        data = {
            id: "",
            month: "",
            brand: "",
            rs: "",
            ws: "",
            excel_gaikindo: "",
            status: 0,
        }
    ) {
        const { id, month, brand, rs, ws, status } = data;
        this["id"] = id;
        this["month"] = new Date(month).getTime();
        this["brand"] = brand;
        this["rs"] = rs;
        this["ws"] = ws;
        this["excel_gaikindo"] = "";
        this["status"] = status;
    }

    async getdata(this: any) {
        const { id, month, brand, rs, ws } = this;
        return [{
            header: "ID",
            id: "id",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: id,
            enableHiding: true,
            size: 10,
        }, {
            header: "Month",
            id: "month",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: moment(month).format("MMMM YYYY"),
            size: 60,
        }, {
            header: "Brand",
            id: "brand",
            filterVariant: "select",
            value: brand,
            size: 60,
        }, {
            header: "RS",
            id: "rs",
            value: numberWithCommas(rs),
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 60,
        }, {
            header: "WS",
            id: "ws",
            value: numberWithCommas(ws),
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 60,
        }];
    }
}
// Retail Sale (RS) & Whole Sales (WS)
export class MasterDataRsWsModel {
    constructor(
        data = {
            id_national_retail_whole_sales: "",
            month: "",
            dealer_group: "",
            model: "",
            rs: "",
            ws: "",
            excel_n_rws: "",
            status: 0,
        }
    ) {
        const { id_national_retail_whole_sales, month, dealer_group, model, rs, ws, status } = data;
        this["id_national_retail_whole_sales"] = id_national_retail_whole_sales;
        this["month"] = month;
        this["dealer_group"] = dealer_group;
        this["model"] = model;
        this["rs"] = rs;
        this["ws"] = ws;
        this["excel_n_rws"] = "";
        this["status"] = status;
    }

    async getdata(this: any) {
        const { month, dealer_group, model, rs, ws } = this;
        return [{
            header: "Month",
            id: "month",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: moment(month).format("MMMM YYYY"),
            size: 60,
        }, {
            header: "Dealer Group",
            id: "dealer_group",
            filterVariant: "select",
            value: dealer_group,
            size: 60,
        }, {
            header: "Model",
            id: "model",
            filterVariant: "select",
            value: model,
            size: 60,
        }, {
            header: "RS",
            id: "rs",
            value: rs,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 60,
        }, {
            header: "WS",
            id: "ws",
            value: ws,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 60,
        }];
    }
}
// National MS
export class NationalMarketShareModel {
    constructor(
        data = {
            id_national_market_share: "",
            date: "",
            island: "",
            province: "",
            kumala_area: "",
            segment: "",
            market: "",
            vol: "",
            excel_n_ms: "",
            status: "",
        }
    ) {
        const { id_national_market_share, date, island, province, kumala_area, segment, market, vol, status } = data;
        this["id_national_market_share"] = id_national_market_share;
        this["date"] = date;
        this["island"] = island;
        this["province"] = province;
        this["kumala_area"] = kumala_area;
        this["segment"] = segment
        this["market"] = market
        this["vol"] = vol;
        this["excel_n_ms"] = "";
        this["status"] = status;
    }

    async getdata(this: any) {
        const { date, island, province, kumala_area, segment, market, vol } = this;
        return [{
            header: "Date",
            id: "date",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: moment(date).format("MMMM YYYY"),
        }, {
            header: "Island",
            id: "island",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: island,
        }, {
            header: "Province",
            id: "province",
            filterVariant: "select",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: province,
        }, {
            header: "Kumala Area",
            id: "kumala_area",
            value: kumala_area,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }, {
            header: "Segment",
            id: "segment",
            value: segment,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }, {
            header: "Market",
            id: "market",
            value: market,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }, {
            header: "Vol",
            id: "vol",
            value: vol,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }];
    }
}

// National MS Maps
export class NationalMarketShareMapsModel {
    constructor(
        data = {
            id_national_market_share: "",
            date: "",
            island: "",
            province: "",
            kumala_area: "",
            segment: "",
            market: "",
            vol: "",
            excel_n_ms_maps: "",
            status: "",
        }
    ) {
        const { id_national_market_share, date, island, province, kumala_area, segment, market, vol, status } = data;
        this["id_national_market_share"] = id_national_market_share;
        this["date"] = date;
        this["island"] = island;
        this["province"] = province;
        this["kumala_area"] = kumala_area;
        this["segment"] = segment
        this["market"] = market
        this["vol"] = vol;
        this["excel_n_ms_maps"] = "";
        this["status"] = status;
    }

    async getdata(this: any) {
        const { date, island, province, kumala_area, segment, market, vol } = this;
        return [{
            header: "Date",
            id: "date",
            value: moment(date).format("MMMM YYYY"),
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }, {
            header: "Island",
            id: "island",
            value: island,
        }, {
            header: "Province",
            id: "province",
            filterVariant: "select",
            value: province,
        }, {
            header: "Kumala Area",
            id: "kumala_area",
            value: kumala_area,
        }, {
            header: "Segment",
            id: "segment",
            value: segment,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }, {
            header: "Market",
            id: "market",
            value: market,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }, {
            header: "Vol",
            id: "vol",
            value: vol,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }];
    }
}
//**END:: Model National Information */
//------------------------------------------------------------------------------//
//**BEGIN:: Model Internal Information */
// Market Share
export class InternalMarketShareModel {
    constructor(
        data = {
            id_internal_market_share: "",
            month: "",
            asm: "",
            sm: "",
            outlet: "",
            model: "",
            segment: "",
            m_size: "",
            vol: "",
            excel_i_ms: "",
            status: 0,
        }
    ) {
        const { id_internal_market_share, month, asm, sm, outlet, model, segment, m_size, vol, status
        } = data;
        this["id_internal_market_share"] = id_internal_market_share;
        this["month"] = month;
        this["asm"] = asm;
        this["sm"] = sm;
        this["outlet"] = outlet;
        this["model"] = model;
        this["segment"] = segment;
        this["m_size"] = m_size;
        this["vol"] = vol;
        this["excel_i_ms"] = "";
        this["status"] = status;
    }

    async getdata(this: any) {
        const { month, asm, sm, outlet, model, segment, m_size, vol } = this;
        return [{
            header: "Month",
            id: "month",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: moment(month).format("MMMM YYYY"),
            size: 60,
        }, {
            header: "ASM",
            id: "asm",
            filterVariant: "select",
            value: asm,
            size: 60,
        }, {
            header: "SM",
            id: "sm",
            value: sm,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 60,
        }, {
            header: "Outlet",
            id: "outlet",
            value: outlet,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 60,
        }, {
            header: "Model",
            id: "model",
            value: model,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 60,
        }, {
            header: "Segment",
            id: "segment",
            value: segment,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 60,
        }, {
            header: "M Size",
            id: "m_size",
            value: m_size,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 60,
        }, {
            header: "Vol",
            id: "vol",
            value: vol,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 60,
        }];
    }
}
// Retail Sales
export class InternalRetailSalesModel {
    constructor(
        data = {
            id: "",
            dealer_code: "",
            dealer_name: "",
            province: "",
            city: "",
            actual_sales_no: "",
            customer_type: "",
            customer_name: "",
            contact_number: "",
            payment_method: "",
            coupon_code: "",
            material_code: "",
            car_series: "",
            models: "",
            configuration: "",
            vin: "",
            report_date: "",
            plate_registration_dealer_code: "",
            plate_registration_dealer: "",
            status_submit: "",
            cabang_retail: "",
            month_retail: "",
            year_retail: "",
            model: "",
            outlet: "",
            excel_i_rs: "",
            status: 0,
        }
    ) {
        const { id, dealer_code, dealer_name, province, city, actual_sales_no, customer_type, customer_name, contact_number, payment_method, coupon_code, material_code, car_series, models, configuration, vin, report_date, plate_registration_dealer_code, plate_registration_dealer, status_submit, cabang_retail, month_retail, year_retail, model, outlet, status } = data;
        this["id"] = id;
        this["dealer_code"] = dealer_code;
        this["dealer_name"] = dealer_name;
        this["province"] = province;
        this["city"] = city;
        this["actual_sales_no"] = actual_sales_no
        this["customer_type"] = customer_type;
        this["customer_name"] = customer_name;
        this["contact_number"] = contact_number;
        this["payment_method"] = payment_method;
        this["coupon_code"] = coupon_code;
        this["material_code"] = material_code;
        this["car_series"] = car_series
        this["models"] = models;
        this["configuration"] = configuration;
        this["vin"] = vin;
        this["report_date"] = report_date;
        this["plate_registration_dealer_code"] = plate_registration_dealer_code;
        this["plate_registration_dealer"] = plate_registration_dealer;
        this["status_submit"] = status_submit
        this["cabang_retail"] = cabang_retail;
        this["month_retail"] = month_retail;
        this["year_retail"] = year_retail;
        this["model"] = model;
        this["outlet"] = outlet;
        this["excel_i_rs"] = "";
        this["status"] = status;
    }

    async getdata(this: any) {
        const { dealer_code, dealer_name, province, city, actual_sales_no, customer_type, customer_name, contact_number, payment_method, coupon_code, material_code, car_series, models, configuration, vin, report_date, plate_registration_dealer_code, plate_registration_dealer, status_submit, cabang_retail, month_retail, year_retail, model, outlet } = this;
        return [{
            header: "Dealer Code",
            id: "dealer_code",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: dealer_code,
            size: 170,
        }, {
            header: "Dealer Name",
            id: "dealer_name",
            // filterVariant: "select",
            value: dealer_name,
            size: 200,
        }, {
            header: "Province",
            id: "province",
            value: province,
            size: 270,
        }, {
            header: "City",
            id: "city",
            value: city,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 120,
        }, {
            header: "Actual Sales No",
            id: "actual_sales_no",
            value: actual_sales_no,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }, {
            header: "Customer Type",
            id: "customer_type",
            value: customer_type,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }, {
            header: "Customer Name",
            id: "customer_name",
            value: customer_name,
            size: 350,
        }, {
            header: "Contact Number",
            id: "contact_number",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: contact_number,
            size: 200,
        }, {
            header: "Payment Method",
            id: "payment_method",
            value: payment_method,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }, {
            header: "Coupon Code",
            id: "coupon_code",
            value: coupon_code,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }, {
            header: "Material Code",
            id: "material_code",
            value: material_code,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }, {
            header: "Car Series",
            id: "car_series",
            value: car_series,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }, {
            header: "Models",
            id: "models",
            value: models,
            size: 200,
        }, {
            header: "Configuration",
            id: "configuration",
            value: configuration,
            size: 250,
        }, {
            header: "VIN",
            id: "vin",
            value: vin,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }, {
            header: "Report Date",
            id: "report_date",
            value: moment(report_date).format("DD-MM-YYYY"),
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 190,
        }, {
            header: "Plate Registration Dealer Code",
            id: "plate_registration_dealer_code",
            value: plate_registration_dealer_code,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }, {
            header: "Plate Registration Dealer",
            id: "plate_registration_dealer",
            value: plate_registration_dealer,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }, {
            header: "Status",
            id: "status_submit",
            value: status_submit,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 140,
        }, {
            header: "Cabang Retail",
            id: "cabang_retail",
            value: cabang_retail,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }, {
            header: "Month Retail",
            id: "month_retail",
            value: month_retail,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }, {
            header: "Year Retail",
            id: "year_retail",
            value: year_retail,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }, {
            header: "Model",
            id: "model",
            value: model,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }, {
            header: "Outlet",
            id: "outlet",
            value: outlet,
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            size: 200,
        }];
    }
}
//**END:: Model National Information */
//------------------------------------------------------------------------------//
//**BEGIN:: Model Target */
// Target Model
export class MasterModelTargetModel {
    constructor(
        data = {
            model: "",
            month: "",
            target: "",
            excel_i_model: "",
        }
    ) {
        const { model, month, target, } = data;
        this["model"] = model;
        this["month"] = month;
        this["target"] = target;
        this["excel_i_model"] = "";
    }

    async getdata(this: any) {
        const { model, month, target } = this;
        return [{
            header: "Model",
            id: "model",
            filterVariant: "select",
            value: model,
        }, {
            header: "Month",
            id: "month",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: moment(month).format("MMMM YYYY"),
        }, {
            header: "Target",
            id: "target",
            value: numberWithCommas(target),
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }];
    }
}
// Target Outlet
export class MasterOutletTargetModel {
    constructor(
        data = {
            outlet: "",
            target_ev_spk: "",
            target_ice_spk: "",
            target_ev_do: "",
            target_ice_do: 0,
            excel_i_outlet: "",
        }
    ) {
        const { outlet, target_ev_spk, target_ice_spk, target_ev_do, target_ice_do } = data;
        this["outlet"] = outlet;
        this["target_ev_spk"] = target_ev_spk;
        this["target_ice_spk"] = target_ice_spk;
        this["target_ev_do"] = target_ev_do;
        this["target_ice_do"] = target_ice_do;
        this["excel_i_outlet"] = ""
    }

    async getdata(this: any) {
        const { outlet, target_ev_spk, target_ice_spk, target_ev_do, target_ice_do } = this;
        return [{
            header: "Outlet",
            id: "outlet",
            value: outlet,
            enableHiding: true,
        }, {
            header: "Target EV SPK",
            id: "target_ev_spk",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: numberWithCommas(target_ev_spk),
        }, {
            header: "Target ICE SPK",
            id: "target_ice_spk",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: numberWithCommas(target_ice_spk),
        }, {
            header: "Target EV DO",
            id: "target_ev_do",
            value: numberWithCommas(target_ev_do),
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }, {
            header: "Target ICE DO",
            id: "target_ice_do",
            value: numberWithCommas(target_ice_do),
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
        }];
    }
}

export class MasterTargetAllModel {
    constructor(
        data = {
            outlet: "",
            bulan: "",
            type: "",
            target_spk: 0,
            target_do: 0,
            target_rs: 0,
            target_po: 0,
            target_leads: 0,
            target_testdrive: 0,
            excel_i_spk_do_rs_po_leads_testdrive: "",
        }
    ) {
        const { outlet, bulan, type, target_spk, target_do, target_rs, target_po, target_leads, target_testdrive } = data;
        this["outlet"] = outlet;
        this["bulan"] = bulan;
        this["type"] = type;
        this["target_spk"] = target_spk;
        this["target_do"] = target_do;
        this["target_rs"] = target_rs;
        this["target_po"] = target_po;
        this["target_leads"] = target_leads;
        this["target_testdrive"] = target_testdrive;
        this["excel_i_spk_do_rs_po_leads_testdrive"] = ""
    }

    async getdata(this: any) {
        const { outlet, bulan, type, target_spk, target_do, target_rs, target_po, target_leads, target_testdrive } = this;
        return [{
            header: "Outlet",
            id: "outlet",
            size: 200,
            fontSize: 9,
            value: outlet,
        }, {
            header: "Bulan",
            id: "bulan",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 9,
            value: moment(bulan).format('MMMM'),
        }, {
            header: "Type",
            id: "type",
            fontSize: 9,
            value: type,
        }, {
            header: "Target SPK",
            id: "target_spk",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 9,
            value: numberWithCommas(target_spk),
        }, {
            header: "Target DO",
            id: "target_do",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 9,
            value: numberWithCommas(target_do),
        }, {
            header: "Target RS",
            id: "target_rs",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 9,
            value: numberWithCommas(target_rs),
        }, {
            header: "Target PO",
            id: "target_po",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 9,
            value: numberWithCommas(target_po),
        }, {
            header: "Target Leads",
            id: "target_leads",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 9,
            value: numberWithCommas(target_leads),
        }, {
            header: "Target Test Drive",
            id: "target_testdrive",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            fontSize: 9,
            value: numberWithCommas(target_testdrive),
        }];
    }
}
//**END:: Model Target */