import { type } from 'os'
import React, { Component } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { Card2 } from '../../../../../_metronic/helpers/components/widgets/CardTemplate';
import { KTCard, KTCardBody, KTIcon } from '../../../../../_metronic/helpers';
import { HighchartsSample } from '../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample';
import { MaterialReactTableDetails } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';
import { Form, Formik } from 'formik';

export default class OutletKpiIndex extends React.Component {
    render() {
        return (
            <>
                <PageTitle breadcrumbs={[{ title: "KPI Dashboard", path: "/sales/outlet-kpi", isSeparator: false, isActive: false }]}>
                    {"Outlet KPI"}
                </PageTitle>
                {/* BEGIN:: Filter Input */}
                <div className="col-xxl-12 mb-5">
                    <KTCard flush stretch={"stretch"}>
                        <KTCardBody>
                            <Formik
                                enableReinitialize={true}
                                initialValues={[]}
                                onSubmit={() => { }}
                            >
                                {({ setFieldValue, values }) => (
                                    <Form>
                                        <div className="row m-auto">
                                            <div className="col-lg-3 mb-5">
                                                <FilterMultiSelect
                                                    label={"Outlet"}
                                                    name="outlet"
                                                    options={[]}
                                                    onChange={(selected: Option[]) => {
                                                        // setFieldValue("asm", selected);
                                                        // const arrayValueMonth = values["month"].map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // const arrayValueAsm = selected.map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // setTimeout(() => {
                                                        //     fetchingCreditProcessAPI({
                                                        //         asm: arrayValueAsm,
                                                        //         month: arrayValueMonth,
                                                        //     })
                                                        // }, 500);
                                                    }}
                                                    value={values["outlet"]}
                                                />
                                            </div>
                                            <div className="col-lg-3 mb-5">
                                                <FilterMultiSelect
                                                    label={"Month"}
                                                    name="month"
                                                    options={[]}
                                                    onChange={(selected: Option[]) => {
                                                        // setFieldValue("month", selected);
                                                        // const arrayValueMonth = selected.map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // const arrayValueAsm = values["asm"].map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // setTimeout(() => {
                                                        //     fetchingCreditProcessAPI({
                                                        //         asm: arrayValueAsm,
                                                        //         month: arrayValueMonth,
                                                        //     })
                                                        // }, 500);
                                                    }}
                                                    value={values["month"]}
                                                />
                                            </div>
                                            <div className="col-lg-3 mb-5">
                                                <FilterMultiSelect
                                                    label={"Range"}
                                                    name="range"
                                                    options={[]}
                                                    onChange={(selected: Option[]) => {
                                                        // setFieldValue("month", selected);
                                                        // const arrayValueMonth = selected.map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // const arrayValueAsm = values["asm"].map(
                                                        //     (x: Object) => x["value"]
                                                        // );
                                                        // setTimeout(() => {
                                                        //     fetchingCreditProcessAPI({
                                                        //         asm: arrayValueAsm,
                                                        //         month: arrayValueMonth,
                                                        //     })
                                                        // }, 500);
                                                    }}
                                                    value={values["month"]}
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </KTCardBody>
                    </KTCard>
                </div>
                {/* END:: Filter Input */}
                {/* ----------------------------------------------------------------------------- */}
                {/* BEGIN:: Card Score Profile, Summary & KPI Chart  */}
                <div className="row g-5 mb-5 mh-md-450px">
                    {/* Credit Process */}
                    <div className="col-md-2 col-lg-6 col-xxl-2">
                        <Card2
                            avatar='/media/avatars/blank.png'
                            name='Dicky Triwibowo'
                            subName='Area Sales Manager'
                            job='Art Director'
                            avgEarnings='$14,560'
                            totalEarnings='$236,400'
                            chartHeight={"115px"}
                        />
                    </div>
                    {/* Credit Process */}
                    <div className="col-md-6 col-xxl-7 h-md-100">
                        <KTCard flush className='mh-100'>
                            <div className="card-header min-h-1px align-items-center mb-5 flex-column w-100 border-0 pt-5">
                                {/* begin::Title */}
                                <span className="card-label fw-bold fs-2">Summary</span>
                                {/* end::Title */}
                            </div>
                            <KTCardBody className='py-6 px-3'>
                                {/* begin::Row */}
                                <div className='row mx-10 m-2 justify-content-center g-5 align-items-center h-md-auto'>
                                    <div className='col-md-3 mb-6'>
                                        <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-success">
                                            <span className="card-label fw-bold text-white fs-md-7 text-center">DO</span>
                                        </div>
                                        <div className="card-body p-5 rounded-bottom-4 bg-light-dark">
                                            <div className='d-flex flex-center'>
                                                <span className="fs-3 text-dark fw-bold">8,21%</span>
                                            </div>
                                            <div className='d-flex flex-center'>
                                                <span className="fs-4 text-gray-500 fw-bold">of 15%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3 mb-6'>
                                        <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-success">
                                            <span className="card-label fw-bold text-white fs-md-7 text-center">MSCP</span>
                                        </div>
                                        <div className="card-body p-5 rounded-bottom-4 bg-light-dark">
                                            <div className='d-flex flex-center'>
                                                <span className="fs-3 text-dark fw-bold">8,21%</span>
                                            </div>
                                            <div className='d-flex flex-center'>
                                                <span className="fs-4 text-gray-500 fw-bold">of 15%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3 mb-6'>
                                        <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-success">
                                            <span className="card-label fw-bold text-white fs-md-7 text-center">Data Entry</span>
                                        </div>
                                        <div className="card-body p-5 rounded-bottom-4 bg-light-dark">
                                            <div className='d-flex flex-center'>
                                                <span className="fs-3 text-dark fw-bold">8,21%</span>
                                            </div>
                                            <div className='d-flex flex-center'>
                                                <span className="fs-4 text-gray-500 fw-bold">of 15%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3 mb-6'>
                                        <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-success">
                                            <span className="card-label fw-bold text-white fs-md-7 text-center">M/S</span>
                                        </div>
                                        <div className="card-body p-5 rounded-bottom-4 bg-light-dark">
                                            <div className='d-flex flex-center'>
                                                <span className="fs-3 text-dark fw-bold">8,21%</span>
                                            </div>
                                            <div className='d-flex flex-center'>
                                                <span className="fs-4 text-gray-500 fw-bold">of 15%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end::Row */}
                                {/* begin::Row */}
                                <div className='row mx-10 justify-content-center align-items-center h-md-auto'>
                                    <div className='col-md-3 mb-6'>
                                        <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-success">
                                            <span className="card-label fw-bold text-white fs-md-7 text-center">Test Drive</span>
                                        </div>
                                        <div className="card-body p-5 rounded-bottom-4 bg-light-dark">
                                            <div className='d-flex flex-center'>
                                                <span className="fs-3 text-dark fw-bold">8,21%</span>
                                            </div>
                                            <div className='d-flex flex-center'>
                                                <span className="fs-4 text-gray-500 fw-bold">of 15%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3 mb-6'>
                                        <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-success">
                                            <span className="card-label fw-bold text-white fs-md-7 text-center">Manpower</span>
                                        </div>
                                        <div className="card-body p-5 rounded-bottom-4 bg-light-dark">
                                            <div className='d-flex flex-center'>
                                                <span className="fs-3 text-dark fw-bold">8,21%</span>
                                            </div>
                                            <div className='d-flex flex-center'>
                                                <span className="fs-4 text-gray-500 fw-bold">of 15%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3 mb-6'>
                                        <div className="card-header min-h-35px mh-50px justify-content-center align-items-center rounded-top-4 bg-success">
                                            <span className="card-label fw-bold text-white fs-md-7 text-center">Sales Process</span>
                                        </div>
                                        <div className="card-body p-5 rounded-bottom-4 bg-light-dark">
                                            <div className='d-flex flex-center'>
                                                <span className="fs-3 text-dark fw-bold">8,21%</span>
                                            </div>
                                            <div className='d-flex flex-center'>
                                                <span className="fs-4 text-gray-500 fw-bold">of 15%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* end::Row */}
                            </KTCardBody>
                        </KTCard>
                    </div>
                    {/* Credit Process */}
                    <div className="col-md-3 col-xxl-3">
                        <KTCard flush className='h-100'>
                            <div className="card-header min-h-1px align-items-center mb-5 flex-column w-100 border-0 pt-5">
                                {/* begin::Title */}
                                <span className="card-label fw-bold fs-2">KPI Chart</span>
                                {/* end::Title */}
                            </div>
                            <KTCardBody className="py-0 px-0">
                                <div className='w-100 justify-content-center g-5 align-items-center'>
                                    <HighchartsSample
                                        id="kpi_chart"
                                        type="spider_area"
                                        height="315px"
                                        // height="315px"
                                        series={[]}
                                    />
                                </div>
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* END:: Card Score Profile, Summary & KPI Chart  */}
                {/* ----------------------------------------------------------------------------- */}
                {/* BEGIN:: Table Sales Manager Standings & Outlet Standings */}
                <div className="row flex-xxl-row h-md-100">
                    <div className="h-md-100 mb-5 bd-highlight">
                        <KTCard flush stretch={"stretch-75"}>
                            <KTCardBody className="pl-5 pt-0">
                                <MaterialReactTableDetails
                                    columns={[]}
                                    data={[]}
                                    isLoading={false}
                                    maxHeight={"600px"}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* END:: Table Sales Manager Standings & Outlet Standings  */}
            </>
        )
    }
}
