
//** Start Huruf Besar */
export const capitalizeWords = (Carakter: string, values: any) => {

  if (typeof values === "string") {
    let ClearCarakter = values.split(Carakter).join(" ");
    const data = ClearCarakter.split(" ");
    for (let i = 0; i < data.length; i++) {
      data[i] = data[i].charAt(0).toUpperCase() + data[i].slice(1);
    }
    return data.join(" ");
  } else {
    let datas = values.map((val: string) => { return val.split(Carakter).join(" ") });
    let ClearCarakter = datas.toString().split(Carakter).join();
    const data = ClearCarakter.split(",");
    for (let i = 0; i < data.length; i++) {
      data[i] = data[i].charAt(0).toUpperCase() + data[i].slice(1);
    }
    return data.join(", ");
  }
}

//** Hapus Space Jadi Karakter - */
export const toSlug = (value: string) => {
  return value.toLowerCase().replace(/ /g, "-").replace(/[^\w-]+/g, "")
}

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const numberWithPoint = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

// Cek jika 2 Array Sama
export const equalsCheck = (a: any[], b: any[]) => {
  return JSON.stringify(a) === JSON.stringify(b);
}

export const getDate = () => {
  const date = new Date();
  const currYear = date.getFullYear();
  const currMonth = date.getMonth();
  const currDay = date.getDate();
  
  return { currYear, currMonth, currDay, date };
}

export const monthOpt = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const calcPercentage = (a: number | undefined, b: number | undefined) => {
 let value = a && b ? ((a / b) * 100).toFixed(2) : 0;
  return `${value}%`
}

export const getUniquefromArray = (arr: any[]) => {
  return Array.from(new Set(arr));
}

export const totalValue = (arr: any[]) => {
  return arr.reduce((total: number, next: number) => total + next, 0);
}