import React, { useEffect, useRef, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { CardRadial, SalesWoTable } from '../SalesWoHelper'
import SingleAutocomplete, { MultipleSelectCheckmarks } from '../../performance/SelectHelper'
import { PerformanceAPI } from '../../../../api/PerformanceAPI'
import { SaleswospkAPI } from '../../../../api/ManPower'
import { ChartMoM } from '../ProductivityHelper'
import FilterMultiSelect, { Option, SingleSelect } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput'
import { equalsCheck, getDate } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers'

type selectType = Array<{ label: string; value: string | number }>
function SalesWoSpk() {
    const date = getDate();
    const legend: { a: string, b: string, c: string } = { a: 'Salesman Total', b: 'Salesman w/o SPK', c: 'w/o SPK Comp' }
    const [dtTable, setDtTable] = useState<object[]>([]);
    const [asmVal, setasmVal] = useState<string[]>([]);
    const [smVal, setsmVal] = useState<string[]>([]);
    const [outletVal, setoutletVal] = useState<string[]>([]);
    const [asmOpt, setasmOpt] = useState<selectType>([]);
    const [smOpt, setsmOpt] = useState<selectType>([]);
    const [outletOpt, setoutletOpt] = useState<selectType>([]);
    const [arrSelect, setArrSelect] = useState<Option[] | null>([]);
    const [smSelect, setSmSelect] = useState<Option[] | null>([]);
    const [outletSelect, setOutletSelect] = useState<Option[] | null>([]);
    const [cov, setCoverage] = useState<string>("");
    const [dtChartTrainingLvl, setDtChartTrainingLvl] = useState<any>({});
    const [dtChartPeriod, setDtChartPeriod] = useState<any>({});
    const allSalesCount = useRef<number>(0);
    const allSpkCount = useRef<number>(0);
    const monthOpt = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
    ];
    const [month, setMonth] = useState<number>(date.currMonth + 1);
    const [slcMonth, setSlcMonth] = useState<Option[]>([monthOpt[date.currMonth]]);
    async function getFilter() {
        let val = {
            asm: asmVal.slice(),
            sm: smVal.slice(),
            outlet: outletVal.slice(),
        };
        return await new PerformanceAPI().getFilter(val).then((res) => {
            let tempAsm: selectType = [];
            let tempsm: selectType = [];
            let tempoutlet: selectType = [];
            res.data.dataAsm.map((val: { area: string; id_team_asm: string | number }) => {
                return tempAsm.push({
                    label: val.area,
                    value: val.id_team_asm,
                });
            });
            res.data.dataSm.map((val: { region: string; id_team_sm: string | number }) => {
                return tempsm.push({
                    label: val.region,
                    value: val.id_team_sm,
                });
            });
            res.data.dataOutlet.map((val: { text: string; id_outlet: string | number }) => {
                return tempoutlet.push({
                    label: val.text,
                    value: val.id_outlet,
                });
            });
            setasmOpt(tempAsm);
            setsmOpt(tempsm);
            setoutletOpt(tempoutlet);
            setCoverage(res.data.coverage);
            return res.data.coverage
        }).then((val) => {
            getData(val, month);
        });
    }

    const getData = async (cov: string, m: number) => {
        Promise.all([
            await new SaleswospkAPI().tablewospk(cov, m).then((res) => {
                setDtTable(res.data.withoutSPK)
                setDtChartPeriod(res.data.salesByWorkingPeriodeCategory)
                setDtChartTrainingLvl(res.data.salesByTrainingLevel)
                allSalesCount.current = res.data.allSalesCount
                allSpkCount.current = res.data.allSpk
            }),
        ]).then(() => console.log("Done"));
        // setLoading(false))
    }
    useEffect(() => {
        getFilter();
    }, [month, cov, asmVal, smVal, outletVal])
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    {
                        title: "Man Power",
                        path: "/sales/sales-wo-spk",
                        isSeparator: false,
                        isActive: false,
                    },
                ]}
            >
                {"Sales w/o SPK"}
            </PageTitle>
            {/* <div className="col d-flex justify-content-around mb-15">
                <MultipleSelectCheckmarks
                    title={"ASM"}
                    datas={asmOpt}
                    dt={asmVal}
                    setDt={setasmVal}
                    dtwidth={300}
                />
                <MultipleSelectCheckmarks
                    title={"SM"}
                    datas={smOpt}
                    dt={smVal}
                    setDt={setsmVal}
                    dtwidth={300}
                />
                <MultipleSelectCheckmarks
                    title={"Outlet"}
                    datas={outletOpt}
                    dt={outletVal}
                    setDt={setoutletVal}
                    dtwidth={300}
                />
                <SingleAutocomplete title={"Month"} datas={monthOpt} dt={month} setDt={setMonth} />
            </div> */}
            <div className="row d-flex justify-content-center mb-15 g-5 absolute">
                <div className="col-xxl-3 mb-5">
                    <FilterMultiSelect
                        options={asmOpt}
                        label="ASM"
                        isSelectAll={true}
                        value={arrSelect}
                        onMenuClose={() => {
                            let toFilter = arrSelect?.map((val) => val.value);

                            return equalsCheck(
                                toFilter as [],
                                asmVal
                            )
                                ? null
                                : setasmVal(toFilter as []);
                        }}
                        onChange={(selected: Option[]) => {
                            setArrSelect(selected);
                        }}
                    />
                </div>
                <div className="col-xxl-3 mb-5"
                    style={{ zIndex: 4 }}>
                    <FilterMultiSelect
                        options={smOpt}
                        label="SM"
                        isSelectAll={true}
                        value={smSelect}
                        onMenuClose={() => {
                            let toFilter = smSelect?.map((val) => val.value);
                            return equalsCheck(
                                toFilter as [],
                                smVal
                            )
                                ? null
                                : setsmVal(toFilter as []);
                        }}
                        onChange={(selected: Option[]) => {
                            console.log("selected", selected);
                            setSmSelect(selected);
                        }}
                    />
                </div>
                <div className="col-xxl-3 mb-5"
                style={{ zIndex: 4 }}>
                    <FilterMultiSelect
                        options={outletOpt}
                        label="Outlet"
                        isSelectAll={true}
                        value={outletSelect}
                        onMenuClose={() => {
                            let toFilter = outletSelect?.map((val) => val.value);
                            return equalsCheck(
                                toFilter as [],
                                outletVal
                            )
                                ? null
                                : setoutletVal(toFilter as []);
                        }}
                        onChange={(selected: Option[]) => {
                            setOutletSelect(selected);
                        }}
                    />
                </div>
                <div className='col-xxl-3 mb-5'
                    style={{ zIndex: 4 }}>
                    <SingleSelect
                        defaultValue={slcMonth}
                        options={monthOpt}
                        label="Month"
                        onChange={(val) => {
                            setMonth(val.value);
                            setSlcMonth(val);
                        }} />
                </div>
            </div>
            <div className='row d-flex flex-sm-row flex-column'>
                <div className='col col-lg-6'>
                    <div className='row'>
                        <div className='col col-lg-6'>
                            <CardRadial title={"Salesman w/o SPK Comp"} total={(dtTable.length / allSalesCount.current) * 100} sub1='Salesman w/o SPK' sub2='Salesman Total'  v1={dtTable.length}  v2={allSalesCount.current}/>
                        </div>
                        <div className='col col-lg-6'>
                            <CardRadial title={"SPK/Salesman"} type="no-chart" total={allSpkCount.current / allSalesCount.current} sub1='Total SPK' sub2='Salesman Total' v1={allSpkCount.current} v2={allSalesCount.current}/>
                        </div>
                        <div className='col col-lg-12 mt-5'>
                            <div className='card'>
                                <div className="d-flex align-items-center flex-column my-5 border-0 p-5">
                                    {/* begin::Title */}
                                    <h3 className="align-items-center flex-column">
                                        <span className="card-label fw-bold fs-1 mb-1">Salesman w/o SPK by Training Level
                                        </span>
                                    </h3>
                                </div>
                                <ChartMoM title={'Productivity by Training Level'}
                                    category={['No Level', 'Professional', 'Expert', 'Master']}
                                    dt={{ dtMP: dtChartTrainingLvl.totalSalesman, dtDO: dtChartTrainingLvl.woSPK, dtProd: dtChartTrainingLvl.woComp }}
                                    legend={legend}
                                />
                            </div>
                        </div>
                        <div className='col col-lg-12 mt-5'>
                            <div className='card'>
                                <div className="d-flex align-items-center flex-column my-5 border-0 p-5">
                                    {/* begin::Title */}
                                    <h3 className="align-items-center flex-column">
                                        <span className="card-label fw-bold fs-1 mb-1">Salesman w/o SPK by Working Periode
                                        </span>
                                    </h3>
                                </div>
                                <ChartMoM title={'Productivity by Training Level'}
                                    category={['0-3 Month', '3-6 Month', '6-9 Month', '9-12 Month']}
                                    dt={{ dtMP: dtChartPeriod.totalSalesman, dtDO: dtChartPeriod.woSPK, dtProd: dtChartPeriod.woComp }}
                                    legend={legend}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col col-lg-6'>
                    <div className='card p-2'>
                        <div className="d-flex align-items-center flex-column my-2 border-0 p-5">
                            {/* begin::Title */}
                            <h3 className="align-items-center flex-column">
                                <span className="card-label fw-bold fs-1 mb-1">Salesman w/o SPK
                                </span>
                            </h3>
                        </div>
                        <SalesWoTable data={dtTable} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SalesWoSpk