export class ModelUploadMarketShareKec {
    constructor(
        data = {
            id_activity_vs_market: "",
            asm: "",
            sm: "",
            area: "",
            province: "",
            kab: "",
            kab_kota: "",
            kec: "",
            segment: "",
            market: 0,
            vol: 0,
            rank: 0,
            number_of_activity: "",
            display_unit: "",
            prospect: "",
            test_drive: "",
            spk: "",
            created_at: "",
            updated_at: "",
            deleted_at: "",
        }
    ) {
        const { id_activity_vs_market, asm, sm,area, province, kab, kab_kota, kec, segment, market, vol, rank, number_of_activity, display_unit, prospect, test_drive, spk, created_at, updated_at, deleted_at } = data;
        this["id_activity_vs_market"] = id_activity_vs_market;
        this["asm"] = asm;
        this["sm"] = sm;
        this["area"] = area;
        this["province"] = province;
        this["kab"] = kab;
        this["kab_kota"] = kab_kota;
        this["kec"] = kec;
        this["segment"] = segment;
        this["market"] = market;
        this["vol"] = vol;
        this["rank"] = rank;
        this["number_of_activity"] = number_of_activity;
        this["display_unit"] = display_unit;
        this["prospect"] = prospect;
        this["test_drive"] = test_drive;
        this["spk"] = spk;
        this["created_at"] = created_at;
        this["updated_at"] = updated_at;
        this["deleted_at"] = deleted_at;
    }

    async getdata(this: any) {
        const { asm, sm, area, province, kab, kab_kota, kec, segment, market, vol, rank, number_of_activity, display_unit, prospect, test_drive, spk, created_at, updated_at, deleted_at } = this;
        return [{
            header: "ASM",
            id: "asm",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: asm,
            enableHiding: true,
        },
        {
            header: "SM",
            id: "sm",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: sm,
            enableHiding: true,
        },
        {
            header: "Area",
            id: "area",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: area,
            enableHiding: true,
        },
        {
            header: "Area",
            id: "area",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: area,
            enableHiding: true,
        },
        {
            header: "Province",
            id: "province",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: province,
            enableHiding: true,
        },
        {
            header: "Kab",
            id: "kab",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: kab,
            enableHiding: true,
        },
        {
            header: "Kab_Kota",
            id: "kab_kota",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: kab_kota,
            enableHiding: true,
        },
        {
            header: "Kec",
            id: "kec",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: kec,
            enableHiding: true,
        },
        {
            header: "Segment",
            id: "segment",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: segment,
            enableHiding: true,
        },
        {
            header: "Market",
            id: "market",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: market,
            enableHiding: true,
        },
        {
            header: "Vol",
            id: "vol",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: vol,
            enableHiding: true,
        },
        {
            header: "Rank",
            id: "rank",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: rank,
            enableHiding: true,
        },
        {
            header: "Number of Activity",
            id: "number_of_activity",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: number_of_activity,
            enableHiding: true,
        },
        {
            header: "Display Unit",
            id: "display_unit",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: display_unit,
            enableHiding: true,
        },
        {
            header: "Prospect",
            id: "prospect",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: prospect,
            enableHiding: true,
        },
        {
            header: "Test Drive",
            id: "test_drive",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: test_drive,
            enableHiding: true,
        },
        {
            header: "SPK",
            id: "spk",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: spk,
            enableHiding: true,
        },
        {
            header: "created_at",
            id: "created_at",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: created_at,
            enableHiding: true,
        },
        {
            header: "updated_at",
            id: "updated_at",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: updated_at,
            enableHiding: true,
        },
        {
            header: "Deleted At",
            id: "deleted_at",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: deleted_at,
            enableHiding: true,
        },
        ];
    }
}