import { fetchingGetData } from "./API";
import { getUniquefromArray } from "../../_metronic/helpers/helpers-data/AssetHelpers";

export class MscpAPI {
  //! Start Menu: MSCP
  // MoM Approve ⓥ DO
  async getMarketShareCreditPortion(val: any) {
    if (val['tipe'] === "ModelAbsorptionContribution") {
      let res = await fetchingGetData({
        url: `market_share_credit_portion/mscp/chart?tipe=${val['tipe']}&coverage=${val['coverage']}&year=${val['year']}&month=${val['month']}&leasing=${val['leasing']}&status=${val['status']}`,
        method: "get",
      });
      return res;
    }
    let res = await fetchingGetData({
      url: `market_share_credit_portion/mscp/chart?tipe=${val['tipe']}&coverage=${val['coverage']}&year=${val['year']}&month=${val['month']}&leasing=${val['leasing']}`,
      method: "get",
    });
    return res;
  }
  // Model Absorption
  async getModelAbsorption(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  // MoM Model Absorption
  async getMomModelAbsorption(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  // MoM Approval Rate ⓥ Rejection Rate
  async getMomApprovalRateVRejectionRate(val: { [x: string]: any; }) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }
  //! End Menu: MSCP
  //--------------------------------------------------------------------------------------------------------
  //! Start Menu: Contribution Comparison
  // DO Leasing Comparison
  async getDoLeasingComparison(coverage: string, leasing: object) {
    let res = await fetchingGetData({
      url: `market_share_credit_portion/contribution_comparison/chart?coverage=${coverage}&leasing_1=${leasing['leasing_1']}&leasing_2=${leasing['leasing_2']}`,
      method: "get",
    });
    return res;
  }
  //! End Menu: Contribution Comparison
  //**Begin:: Get Select Data */
  async getFilter(values: any) {
    let res = await fetchingGetData({
      url: `select/mtd/performance?asm=${values['asm'] || ""}&sm=${values['sm'] || ""}&outlet=${values['outlet'] || ""}`,
      method: "get",
    });
    return res;
  }
  // Get Month
  async getMonth() {
    let res = await fetchingGetData({
      url: `select/national-information-gaikindo?tipe=getMonth`,
      method: "get",
    });
    return res;
  }

  async getLeasing() {
    let res = await fetchingGetData({
      url: `select/leasingcreditprocess`,
      method: "get",
    });
    return res;
  }
  async getDate() {
    let res = await fetchingGetData({
      url: `select/datecreditprocess`,
      method: "get",
    });
    return res;
  }

  async getDtBox({ coverage, dates, leasing }: any) {
    let toString = leasing.map((val: any) => `"${val}"`).join(",");
    
    let month = getUniquefromArray(dates.month).map((val: any) => `"${val}"`).join(",");
    let year = getUniquefromArray(dates.year).map((val: any) => `"${val}"`).join(",");
    
    let res = await fetchingGetData({
      url: `credit_process/boxes?coverage=${coverage}&leasing=${toString}&month=${month}&year=${year}`,
      method: "get",
    });
    return res;
  }
  async getDtTableRatebyOutlet({ coverage, dates, leasing }: any) {
    let toString = leasing.map((val: any) => `"${val}"`).join(",");
    let month = getUniquefromArray(dates.month).map((val: any) => `"${val}"`).join(",");
    let year = getUniquefromArray(dates.year).map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/table_rate_outlet?coverage=${coverage}&leasing=${toString}&month=${month}&year=${year}`,
      method: "get",
    });
    return res;
  }

  async getDtTableRatebyModel({ coverage, dates, leasing }: any) {
    let toString = leasing.map((val: any) => `"${val}"`).join(",");
    let month = getUniquefromArray(dates.month).map((val: any) => `"${val}"`).join(",");
    let year = getUniquefromArray(dates.year).map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/table_rate_model?coverage=${coverage}&leasing=${toString}&month=${month}&year=${year}`,
      method: "get",
    });
    return res;
  }
  async getDtLeadtimebyOutlet({ coverage, dates,leasing, model }: any) {
    let toString = leasing.map((val: any) => `"${val}"`).join(",");
    let modelString = model.map((val: any) => `"${val}"`).join(",");
    let month = getUniquefromArray(dates.month).map((val: any) => `"${val}"`).join(",");
    let year = getUniquefromArray(dates.year).map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/leadtime?coverage=${coverage}&leasing=${toString}&month=${month}&year=${year}&model=${modelString}`,
      method: "get",
    });
    return res;
  }
  async getDtTableApproval({ coverage, dates,leasing, model }: any) {
    let toString = leasing.map((val: any) => `"${val}"`).join(",");
    let modelString = model.map((val: any) => `"${val}"`).join(",");
    let month = getUniquefromArray(dates.month).map((val: any) => `"${val}"`).join(",");
    let year = getUniquefromArray(dates.year).map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/approval_dp?coverage=${coverage}&leasing=${toString}&month=${month}&year=${year}&model=${modelString}`,
      method: "get",
    });
    return res;
  }

  async getDtMoMRate({ coverage, dates,leasing }: any) {
    let toString = leasing.map((val: any) => `"${val}"`).join(",");
    let year = getUniquefromArray(dates.year).map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/mom_rate?coverage=${coverage}&leasing=${toString}&year=${year}`,
      method: "get",
    });
    return res;
  }
}

export class MscpMTDAPI {
  async getDtTable({ coverage, dates,leasing, model }: any) {
    let toString = leasing.map((val: any) => `"${val}"`).join(",");
    let modelString = model.map((val: any) => `"${val}"`).join(",");
    let month = getUniquefromArray(dates.month).map((val: any) => `"${val}"`).join(",");
    let year = getUniquefromArray(dates.year).map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/mtd_table?coverage=${coverage}&leasing=${toString}&month=${month}&year=${year}&model=${modelString}`,
      method: "get",
    });
    return res;
  }

  async getDtPie({ coverage, dates,leasing, model }: any) {
    let toString = leasing.map((val: any) => `"${val}"`).join(",");
    let modelString = model.map((val: any) => `"${val}"`).join(",");
    let month = getUniquefromArray(dates.month).map((val: any) => `"${val}"`).join(",");
    let year = getUniquefromArray(dates.year).map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/mtd_pie?coverage=${coverage}&leasing=${toString}&month=${month}&year=${year}&model=${modelString}`,
      method: "get",
    });
    return res;
  }

  async getDtTableInProcess({ coverage, dates,leasing, model }: any) {
    let toString = leasing.map((val: any) => `"${val}"`).join(",");
    let modelString = model.map((val: any) => `"${val}"`).join(",");
    let month = getUniquefromArray(dates.month).map((val: any) => `"${val}"`).join(",");
    let year = getUniquefromArray(dates.year).map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/mtd_inprocess?coverage=${coverage}&leasing=${toString}&month=${month}&year=${year}&model=${modelString}`,
      method: "get",
    });
    return res;
  }
}

export class MscpProcessJourneyAPI {
  async getDtMoMRate({ coverage, model }: any) {
    let modelString = model.map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/journey_appin?coverage=${coverage}&model=${modelString}`,
      method: "get",
    });
    return res;
  }

  async getDtMoMApprove({ coverage, model }: any) {
    let modelString = model.map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/journey_approve?coverage=${coverage}&model=${modelString}`,
      method: "get",
    });
    return res;
  }
  async getDtMoMReject({ coverage, model }: any) {
    let modelString = model.map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/journey_reject?coverage=${coverage}&model=${modelString}`,
      method: "get",
    });
    return res;
  }
  async getDtMoMProcess({ coverage, model }: any) {
    let modelString = model.map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/journey_process?coverage=${coverage}&model=${modelString}`,
      method: "get",
    });
    return res;
  }

}
export class MscpApprovalAPI {
  async getDtRunningMonth() {
    let res = await fetchingGetData({
      url: `credit_process/approval_bydp`,
      method: "get",
    });
    return res;
  }
  async getDtRunningMonthbyTenor() {
    let res = await fetchingGetData({
      url: `credit_process/approval_bytenor`,
      method: "get",
    });
    return res;
  }

}

export class MscpRejectAPI {
  async getDtTable({ coverage, dates }: any) {
    let month = getUniquefromArray(dates.month).map((val: any) => `"${val}"`).join(",");
    let year = getUniquefromArray(dates.year).map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/reject/dtTable?coverage=${coverage}&month=${month}&year=${year}`,
      method: "get",
    });
    return res;
  }
  async getDtListSpk({ coverage, dates }: any) {
    let month = getUniquefromArray(dates.month).map((val: any) => `"${val}"`).join(",");
    let year = getUniquefromArray(dates.year).map((val: any) => `"${val}"`).join(",");
    let res = await fetchingGetData({
      url: `credit_process/reject/listSpk?coverage=${coverage}&month=${month}&year=${year}`,
      method: "get",
    });
    return res;
  }
}
