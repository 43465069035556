import ModalImage from "react-modal-image";
import React, { FC, useEffect, useState } from "react";
import Select2, { components, IndicatorsContainerProps } from "react-select";

import { toAbsoluteUrl } from "../../AssetHelpers";
import { useField } from "formik";
import CheckboxTree from "react-checkbox-tree";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useThemeMode } from "../../../partials";
import { ThemeModeComponent } from "../../../assets/ts/layout";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";

type Props = {
  type?: string;
  name: string;
  label: string;
  required?: boolean;
  formik?: any;
  disabled?: boolean;
  horizontalRows?: boolean;
  rows?: number;
  isLoading?: boolean;
  sizeBold?: boolean;
  hideLabel?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  options?: Array<{}>;
  onChange?: any;
  formCSSSolid?: boolean;
  value?: any;
  className?: string;
};

const InputText: FC<Props> = ({
  name,
  label,
  required = false,
  formik,
  disabled = false,
  horizontalRows = false,
  onChange = () => { },
  sizeBold = false,
  formCSSSolid = false,
}) => {
  const [field] = useField(name);
  return (
    <div className={"row mb-6"}>
      <label
        className={`${horizontalRows ? "col-lg-3" : ""} mb-3 ${required ? "required" : ""
          } fs-6 ${sizeBold ? "fw-bold fw-bolder " : ""}`}
      >
        {label}
      </label>

      <div className={`${horizontalRows ? "col-lg-9" : ""} fv-row`}>
        <input
          disabled={disabled}
          type="text"
          className={`${formCSSSolid ? "form-control-solid" : ""
            } form-control form-control-lg`}
          placeholder={`${label}...`}
          onChange={(option) => {
            onChange(
              option,
              formik.setFieldValue(name, option["target"]["value"])
            );
            field.onChange(name);
          }}
          value={formik['values'][name]}
        // {...formik.getFieldProps(name)}
        />
        {formik["touched"][name] && formik["errors"][name] && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik["errors"][name]}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const InputNumber: FC<Props> = ({
  name,
  label,
  required = false,
  formik,
  disabled = false,
  horizontalRows = false,
  onChange,
  sizeBold = false,
  formCSSSolid = false,
}) => {
  return (
    <div className={"row mb-6"}>
      <label
        className={`${horizontalRows ? "col-lg-3" : ""} mb-3 ${required ? "required" : ""
          } fs-6 ${sizeBold ? "fw-bold fw-bolder " : ""}`}
      >
        {label}
      </label>

      <div className={`${horizontalRows ? "col-lg-9" : ""} fv-row`}>
        <input
          disabled={disabled}
          min="0"
          type="number"
          className={`${formCSSSolid ? "form-control-solid" : ""
            } form-control form-control-lg`}
          placeholder={`${label}...`}
          {...formik.getFieldProps(name)}
        />
        {formik["touched"][name] && formik["errors"][name] && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik["errors"][name]}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const InputPassword: FC<Props> = ({
  type = "password",
  name,
  label,
  required = false,
  formik,
  disabled = false,
  horizontalRows = false,
  onChange,
  sizeBold = false,
  formCSSSolid = false,
}) => {
  return (
    <div className={"row mb-6"}>
      <label
        className={`${horizontalRows ? "col-lg-3" : ""} mb-3 ${required ? "required" : ""
          } fs-6 ${sizeBold ? "fw-bold fw-bolder " : ""}`}
      >
        {label}
      </label>

      <div className={`${horizontalRows ? "col-lg-9" : ""} fv-row`}>
        <input
          disabled={disabled}
          type={type}
          className={`${formCSSSolid ? "form-control-solid" : ""
            } form-control form-control-lg`}
          placeholder={`${label}...`}
          id={name}
          {...formik.getFieldProps(name)}
        />
        {formik["touched"][name] && formik["errors"][name] && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik["errors"][name]}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const InputTextArea: FC<Props> = ({
  name,
  label,
  required = false,
  formik,
  disabled = false,
  horizontalRows = false,
  rows = 2,
  onChange,
  sizeBold = false,
  formCSSSolid = false,
  value,
}) => {
  return (
    <div className={"row mb-6"}>
      <label
        className={`${horizontalRows ? "col-lg-3" : ""} mb-3 ${required ? "required" : ""
          } fs-6 ${sizeBold ? "fw-bold fw-bolder " : ""}`}
      >
        {label}
      </label>

      <div className={`${horizontalRows ? "col-lg-9" : ""} fv-row`}>
        <textarea
          disabled={disabled}
          className={`form-control form-control-lg ${formCSSSolid ? "form-control-solid" : ""
            } resize-none min-h-25px`}
          rows={rows}
          placeholder={`${label}...`}
          value={value}
          {...formik.getFieldProps(name)}
        ></textarea>
        {formik["touched"][name] && formik["errors"][name] && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik["errors"][name]}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const InputImages: FC<Props> = ({
  name,
  label,
  required = false,
  formik,
  horizontalRows = false,
  sizeBold = false,
  formCSSSolid = false,
}) => {
  const [pic, setPic] = useState<String>("");
  const [picUrl, setPicUrl] = useState<String>("");
  useEffect(() => {
    if (formik["values"][name]) {
      setPicUrl(formik["values"][name]);
    }
  }, [formik, name]);

  const removePic = () => {
    setPic("");
    setPicUrl("");
    formik["setFieldValue"](name, "");
  };

  return (
    <div className={"row mb-6"}>
      <label
        className={`${horizontalRows ? "col-lg-3" : ""} mb-3 ${required ? "required" : ""
          } fs-6 ${sizeBold ? "fw-bold fw-bolder " : ""}`}
      >
        {label}
      </label>

      <div className={`${horizontalRows ? "col-lg-9" : ""} fv-row`}>
        <div
          className="image-input image-input-outline"
          data-kt-image-input="true"
          style={{
            backgroundColor: "#eee",
          }}
        >
          {pic !== "" || picUrl !== "" ? (
            <>
              <ModalImage
                className="image-input-wrapper w-125px h-125px"
                small={`${pic ? pic : picUrl}`}
                large={`${pic ? pic : picUrl}`}
                showRotate={true}
              />
            </>
          ) : (
            <>
              <div
                className="image-input-wrapper w-125px h-125px"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/avatars/blank.png"
                  )})`,
                }}
              />
            </>
          )}
          <label
            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
            data-kt-image-input-action="change"
            data-bs-toggle="tooltip"
            title="Change avatar"
          >
            <i className="ki-duotone ki-pencil fs-7">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="file"
              name={name}
              multiple
              accept=".png, .jpg, .jpeg"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const selectFiles = e.target.files as FileList;
                setPic(URL.createObjectURL(selectFiles?.[0]));
                setPicUrl(URL.createObjectURL(selectFiles?.[0]));
                formik["setFieldValue"](name, selectFiles?.[0]);
              }}
            />
            {/* <input type="file" name="avatar" accept=".png, .jpg, .jpeg" /> */}
            <input type="hidden" name="avatar_remove" />
          </label>
          <span
            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
            data-kt-image-input-action="cancel"
            data-bs-toggle="tooltip"
            title="Cancel avatar"
          >
            <i className="ki-duotone ki-cross fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </span>
          <span
            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
            data-kt-image-input-action="remove"
            data-bs-toggle="tooltip"
            title="Remove avatar"
            onClick={removePic}
          >
            <i className="ki-duotone ki-cross fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </span>
        </div>
        <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
        {formik["touched"][name] && formik["errors"][name] && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik["errors"][name]}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const InputSelect2: FC<Props> = ({
  name,
  label,
  required = false,
  formik,
  disabled = false,
  horizontalRows = false,
  isLoading = false,
  isClearable = false,
  hideLabel = true,
  isSearchable = false,
  isMulti = false,
  options,
  onChange = () => { },
  sizeBold = false,
  className = '',
}) => {
  const [field] = useField(name);
  const { mode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  const IndicatorsContainer = (
    props: IndicatorsContainerProps<{}>
  ) => {
    return (
      <div style={{ background: disabled ? "var(--bs-gray-200)" : "", padding: "1px", }}>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };
  return (
    <div className={"row mb-6"}>
      <label
        className={`${horizontalRows ? "col-lg-3" : ""} mb-3 ${required ? "required" : ""
          } fs-6 ${sizeBold ? "fw-bold fw-bolder " : ""}`}
      >
        {hideLabel ? "" : label}
      </label>

      <div className={`${horizontalRows ? "col-lg-9" : ""} fv-row`}>
        <Select2
          placeholder={"--- Select " + label + " ---"}
          isClearable={isClearable}
          isLoading={isLoading}
          isDisabled={disabled}
          isSearchable={isSearchable}
          isMulti={isMulti}
          className={className}
          components={{ IndicatorsContainer }}
          theme={calculatedMode === "dark" ? (theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: 'var(--bs-gray-300)',
              primary: 'var(--bs-gray-200)',
            },
          }) : undefined}
          // styles={calculatedMode === "dark" ? {
          //   control: (baseStyles, state) => ({
          //     ...baseStyles,
          //     borderColor: state.isFocused ? 'var(--bs-gray-300)' : 'var(--bs-gray-300)',
          //     backgroundColor: "var(--bs-body-bg)",
          //     backgroundClip: "padding-box",
          //     color: "#92929f"
          //   }),
          //   singleValue: (base) => ({ ...base, color: "#92929f" }),
          //   valueContainer: (base) => ({
          //     ...base,
          //     backgroundColor: disabled ? "var(--bs-gray-200)" : "",
          //     padding: "5.5px 8px",
          //     color: "#92929f",
          //   }),
          //   multiValue: (styles, { data }) => {
          //     return {
          //       ...styles,
          //       backgroundColor: disabled ? "var(--bs-gray-200)" : "",
          //     };
          //   },
          //   multiValueLabel: (styles, { data }) => ({
          //     ...styles,
          //     color: "#92929f",
          //   }),
          //   multiValueRemove: (styles, { data }) => ({
          //     ...styles,
          //     color: "#92929f",
          //     ':hover': {
          //       backgroundColor: "var(--bs-gray-200)",
          //       color: "white"
          //     },
          //   }),
          //   menu: (provided, state) => ({
          //     ...provided,
          //     backgroundColor: "var(--bs-body-bg)",
          //     backgroundClip: "padding-box",
          //     fontWeight: "500",
          //     lineHeight: "1.5",
          //     fontSize: "1.1rem",
          //     color: "#92929f"
          //   })
          // } : undefined}
          options={options}
          value={isMulti ? options ? options.filter((obj) => formik["values"][name] ? formik["values"][name].includes(obj["value"]) : "") : "" : (options ? options.find((val) => val["value"] === formik["values"][name]) : []) || ""
          }
          onChange={(option) => {
            onChange(option, formik.setFieldValue(name, isMulti ? Array.isArray(option) ? option.map((x) => x['value']) : [] : option ? option["value"] : ""));
            field.onChange(name);
          }}
        />
        {formik["touched"][name] && formik["errors"][name] && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik["errors"][name]}</div>
          </div>
        )}
      </div>
    </div>
  );
};

type TypeCheckboxTree = {
  name: string,
  nodes;
  formik,
}

const InputCheckboxTree: FC<TypeCheckboxTree> = ({
  name,
  nodes,
  formik,
}) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  return (
    <div className='form-group mb-10 d-flex flex-stack'>
      <CheckboxTree
        nodes={nodes}
        checked={formik['values'][name]}
        expanded={expanded}
        // checkModel={"all"}
        nameAsArray={true}
        onCheck={(checked, targetNode) => {
          formik.setFieldValue(name, checked)
        }}
        onExpand={expanded => {
          setExpanded(expanded)
        }}
        iconsClass={"fa5"}
        icons={{
          check: <span className="rct-icon rct-icon-check" />,
          uncheck: <span className="rct-icon rct-icon-uncheck" />,
          halfCheck: <span className="rct-icon rct-icon-half-check" />,
          expandClose: <span className="rct-icon rct-icon-expand-close" />,
          expandOpen: <span className="rct-icon rct-icon-expand-open" />,
          expandAll: <span className="rct-icon rct-icon-expand-all" />,
          collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
          parentClose: <span className="rct-icon rct-icon-parent-close" />,
          parentOpen: <span className="rct-icon rct-icon-parent-open" />,
          leaf: <span className="rct-icon rct-icon-leaf" />,
        }}
      />
    </div>
  );
};

type TypeCheckbox = {
  name: string,
  id: string,
  title: string;
  values: string;
  formik,
}

const InputCheckbox: FC<TypeCheckbox> = ({
  name,
  id,
  title,
  values,
  formik,
}) => {
  return (
    <div key={`${id}-${name}`} className='form-group mb-10 d-flex flex-stack'>
      <div className="form-check form-check-custom form-check-solid form-check-sm">
        <input
          id={id}
          type="checkbox"
          className="form-check-input"
          checked={formik['values'][name].includes(values)}
          onChange={(e) => {

            const set = new Set(formik['values'][name]);
            if (set.has(values)) {
              set.delete(values);
            } else {
              set.add(values);
            }
            formik.setFieldValue(name, Array.from(set));
          }} />
        <label className="form-check-label" htmlFor={id}>{title}</label>
      </div>
    </div>
  );
};

type TypeFileUpload = {
  name: string,
  title?: string;
  accept: string;
  horizontalRows?: boolean;
  maxFile?: boolean;
  required?: boolean;
  sizeBold?: boolean;
  progressInfos: Array<{}>;
  formik,
}

const InputFileUpload: FC<TypeFileUpload> = ({
  name,
  title,
  accept,
  horizontalRows = false,
  required = false,
  sizeBold = false,
  maxFile = false,
  progressInfos,
  formik,
}) => {
  return (
    <div className={"row mb-6"}>
      {title && (
        <label htmlFor={name} className={`${horizontalRows ? "col-lg-3" : ""} mb-3 ${required ? "required" : ""
          } fs-6 ${sizeBold ? "fw-bold fw-bolder " : ""}`}>{title}</label>
      )}

      <div className={`${horizontalRows ? "col-lg-9" : ""} fv-row`}>
        <input
          type="file"
          name={name}
          id={name}
          multiple
          className="form-control"
          accept={accept}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const selectFiles = e.target.files as FileList;
            formik["setFieldValue"](name, selectFiles?.[0]);

          }}
        />
      </div>
      <div className='d-flex align-items-center flex-column mt-3'>
        {!progressInfos['error'] && progressInfos['percentage'] && (
          <>
            <div className='progress justify-content-between mt-auto mb-2 w-100px w-xl-150px w-xxl-300px bg-light-success'>
              <div
                className='progress-bar rounded bg-success fs-7 fw-bold'
                role='progressbar'
                style={{ width: progressInfos['percentage'] + "%" }}
                aria-valuenow={parseInt(progressInfos['percentage'])}
                aria-valuemin={0}
                aria-valuemax={100}
              >{progressInfos['percentage'] + "%"}</div>
            </div>
          </>
        )}
      </div>
      {maxFile &&
        (
          <span className="form-text fs-6 text-muted">Max file size is 5MB file.</span>
        )
      }
      <>
        {formik["touched"][name] && formik["errors"][name] && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik["errors"][name]}</div>
          </div>
        )}
      </>
    </div>
  );
};

type TypeDatePicker = {
  name: string,
  label: string,
  required?: boolean;
  disabled?: boolean;
  horizontalRows?: boolean;
  showYearPicker?: boolean;
  showMonthYearPicker?: boolean;
  sizeBold?: boolean;
  onChange?: any;
  formCSSSolid?: boolean;
  formik,
}
const InputDatePicker: FC<TypeDatePicker> = ({
  name,
  label,
  required = false,
  disabled = false,
  horizontalRows = false,
  showYearPicker = false,
  showMonthYearPicker = false,
  sizeBold = false,
  formCSSSolid = false,
  onChange = () => { },
  formik,
}) => {
  return (
    <div className={"row mb-6"}>
      <label
        className={`${horizontalRows ? "col-lg-3" : ""} mb-3 ${required ? "required" : ""
          } fs-6 ${sizeBold ? "fw-bold fw-bolder " : ""}`}
      >
        {label}
      </label>
      <div className={`${horizontalRows ? "col-lg-9" : ""} fv-row`}>
        <DatePicker
          id={name}
          name={name}
          placeholderText={showYearPicker ? "YYYY" : showMonthYearPicker ? "MM/YYYY" : "DD-MM-YYYY"}
          disabled={disabled}
          dateFormat={showYearPicker ? "yyyy" : showMonthYearPicker ? "MM/yyyy" : "dd-MM-yyyy"}
          selectsStart
          selectsEnd
          isClearable={!disabled ?? true}
          peekNextMonth
          className={`${formCSSSolid ? "form-control-solid" : ""
            } form-control form-control-lg`}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          useShortMonthInDropdown
          showYearPicker={showYearPicker}
          showMonthYearPicker={showMonthYearPicker}
          // showFullMonthYearPicker={showMonthYearPicker}
          showFourColumnMonthYearPicker={showMonthYearPicker}
          closeOnScroll={true}
          selected={formik['values'][name]}
          onChange={(Date: Date) => {
            onChange(Date, formik.setFieldValue(name, Date ? Date : null));
          }}
        />
        {formik["touched"][name] && formik["errors"][name] && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik["errors"][name]}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export {
  InputText,
  InputNumber,
  InputPassword,
  InputTextArea,
  InputImages,
  InputSelect2,
  InputCheckboxTree,
  InputCheckbox,
  InputFileUpload,
  InputDatePicker
};
