import { FC } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { KTIcon } from "../../../../helpers";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  active?: boolean;
  hasArrow?: boolean;
  hasBullet?: boolean;
  onClick?: () => void;
};

const MenuItemCustome: FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  active = false,
  hasArrow = false,
  hasBullet = false,
  onClick,
}) => {
  return (
    <div className="menu-item me-lg-1">
      <Link
        className={clsx("menu-link py-3", {
          "active menu-here": active,
        })}
        onClick={onClick}
        to={to}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}

        {icon && (
          <span className="menu-icon">
            <KTIcon iconName={icon} className="fs-2" />
          </span>
        )}

        {fontIcon && (
          <span className="menu-icon">
            <i className={clsx("bi fs-3", fontIcon)}></i>
          </span>
        )}

        <span className="menu-title">{title}</span>

        {hasArrow && <span className="menu-arrow"></span>}
      </Link>
    </div>
  );
};

export { MenuItemCustome };
