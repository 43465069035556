import React from "react";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { useAuth } from "../../../../../app/pages/auth";
const SidebarMenuMain = () => {
  // const intl = useIntl();
  const { sideBar } = useAuth();
  return (
    <>
      {sideBar?.map((currentValue: any, i: number) => {
        return (
          <React.Fragment key={i}>
            <div className="menu-item">
              <div className="menu-content pb-2">
                <span className="menu-section text-muted text-uppercase fs-8 ls-1">{`${currentValue["nama"]}`}</span>
              </div>
            </div>
            {currentValue["sidebar"]?.map((sidebar: any, s: number) => {
              return (
                <React.Fragment key={s}>
                  {sidebar["sidebar_sub"].length > 0 ? (
                    <SidebarMenuItemWithSub
                      to={`${sidebar["path"]}`}
                      icon={`${sidebar["icon"]}`}
                      title={`${sidebar["nama"]}`}
                      fontIcon="bi-layers"
                    >
                      {sidebar["sidebar_sub"].map((sideSub: any, t: number) => {
                        return (
                          <React.Fragment key={t}>
                            {sideSub["sidebar_sub_child"].length > 0 ? (
                              <SidebarMenuItemWithSub
                                to={`${sideSub["path"]}`}
                                icon={`${sideSub["icon"]}`}
                                title={`${sideSub["nama"]}`}
                                fontIcon="bi-layers"
                              >
                                {sideSub["sidebar_sub_child"].map((sideSub: any, i: number) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <SidebarMenuItem
                                        to={`${sideSub["path"]}`}
                                        title={`${sideSub["nama"]}`}
                                        hasBullet={true}
                                      />
                                    </React.Fragment>
                                  );
                                }
                                )}
                              </SidebarMenuItemWithSub>
                            ) : (
                              <SidebarMenuItem
                                to={`${sideSub["path"]}`}
                                title={`${sideSub["nama"]}`}
                                hasBullet={true}
                              />
                            )}
                          </React.Fragment>
                        );
                      }
                      )}
                    </SidebarMenuItemWithSub>
                  ) : (
                    <SidebarMenuItem
                      to={`${sidebar["path"]}`}
                      icon={`${sidebar["icon"]}`}
                      title={`${sidebar["nama"]}`}
                      fontIcon="bi-layers"
                    />
                  )}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      })
      }
    </>
  );
};
export { SidebarMenuMain };
