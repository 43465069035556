import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { TargetvActualTable, ChartQuantity } from '../QuantityHelper';
import { QuantityAPI } from '../../../../api/ManPower';
import { PerformanceAPI } from '../../../../api/PerformanceAPI';
import FilterMultiSelect, { Option, SingleSelect } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { equalsCheck } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';

type selectType = Array<{ label: string; value: string | number }>

function Quantity() {
    const currDate = new Date();
    const currMonth = currDate.getMonth();
    const [asmVal, setasmVal] = useState<string[]>([]);
    const [smVal, setsmVal] = useState<string[]>([]);
    const [outletVal, setoutletVal] = useState<string[]>([]);
    const [asmOpt, setasmOpt] = useState<selectType>([]);
    const [smOpt, setsmOpt] = useState<selectType>([]);
    const [outletOpt, setoutletOpt] = useState<selectType>([]);
    const [arrSelect, setArrSelect] = useState<Option[] | null>([]);
    const [smSelect, setSmSelect] = useState<Option[] | null>([]);
    const [outletSelect, setOutletSelect] = useState<Option[] | null>([]);
    const [cov, setCoverage] = useState<string>("");
    const chartTextLevel = ["No Level", "Professional", "Expert", "Master"];
    const chartTextPeriode = ["0-3 Month", "3-6 Month", "6-9 Month", "9-12 Month"];
    const [dtQtyByTraining, setDtByTraining] = useState<number[]>([]);
    const [dtWorkPeriod, setDtByWorkPeriod] = useState<number[]>([]);
    const [dttargetvactual, setTargetvactual] = useState<object[]>([]);
    const monthOpt = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
    ];
    const [month, setMonth] = useState<number>(currMonth + 1);
    const [slcMonth, setSlcMonth] = useState<Option[]>([monthOpt[currMonth]]);

    async function getFilter() {
        let val = {
            asm: asmVal.slice(),
            sm: smVal.slice(),
            outlet: outletVal.slice(),
        };
        return await new PerformanceAPI().getFilter(val).then((res) => {
            let tempAsm: selectType = [];
            let tempsm: selectType = [];
            let tempoutlet: selectType = [];
            res.data.dataAsm.map((val: { area: string; id_team_asm: string | number }) => {
                return tempAsm.push({
                    label: val.area,
                    value: val.id_team_asm,
                });
            });
            res.data.dataSm.map((val: { region: string; id_team_sm: string | number }) => {
                return tempsm.push({
                    label: val.region,
                    value: val.id_team_sm,
                });
            });
            res.data.dataOutlet.map((val: { text: string; id_outlet: string | number }) => {
                return tempoutlet.push({
                    label: val.text,
                    value: val.id_outlet,
                });
            });
            setasmOpt(tempAsm);
            setsmOpt(tempsm);
            setoutletOpt(tempoutlet);
            setCoverage(res.data.coverage);
            return res.data.coverage
        }).then((val) => {
            getData(val, month);
        });
    }

    const getData = (cov: string, m: number) => {
        Promise.all([
            new QuantityAPI().qtyByLevel(cov, m).then((res) => {
                setDtByTraining(res.data)
            }),
            new QuantityAPI().qtyByWorkingPeriod(cov, m).then((res) => {
                setDtByWorkPeriod(res.data)
            }),
            new QuantityAPI().targetvactual(cov, m).then((res) => {
                setTargetvactual(res.data)
            }),
        ]).then(() => console.log("Done"));
        // setLoading(false))
    }

    useEffect(() => {
        getFilter();
        // console.log("Month", month);
    }, [month, cov, asmVal, smVal, outletVal]);
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    {
                        title: "Man Power",
                        path: "/sales/quantity",
                        isSeparator: false,
                        isActive: false,
                    },
                ]}
            >
                {"Quantity"}
            </PageTitle>
            {/* <div className="col d-flex justify-content-around mb-15">
                <MultipleSelectCheckmarks
                    title={"ASM"}
                    datas={asmOpt}
                    dt={asmVal}
                    setDt={setasmVal}
                    dtwidth={300}
                />
                <MultipleSelectCheckmarks
                    title={"SM"}
                    datas={smOpt}
                    dt={smVal}
                    setDt={setsmVal}
                    dtwidth={300}
                />
                <MultipleSelectCheckmarks
                    title={"Outlet"}
                    datas={outletOpt}
                    dt={outletVal}
                    setDt={setoutletVal}
                    dtwidth={300}
                />
                <SingleAutocomplete title={"Month"} datas={monthOpt} dt={month} setDt={setMonth} />
            </div> */}
            <div className="row d-flex justify-content-center mb-15 g-5 absolute">
                <div className="col-xxl-3 mb-5">
                    <FilterMultiSelect
                        options={asmOpt}
                        label="ASM"
                        isSelectAll={true}
                        value={arrSelect}
                        onMenuClose={() => {
                            let toFilter = arrSelect?.map((val) => val.value);

                            return equalsCheck(
                                toFilter as [],
                                asmVal
                            )
                                ? null
                                : setasmVal(toFilter as []);
                        }}
                        onChange={(selected: Option[]) => {
                            setArrSelect(selected);
                        }}
                    />
                </div>
                <div className="col-xxl-3 mb-5"
                    style={{ zIndex: 4 }}>
                    <FilterMultiSelect
                        options={smOpt}
                        label="SM"
                        isSelectAll={true}
                        value={smSelect}
                        onMenuClose={() => {
                            let toFilter = smSelect?.map((val) => val.value);
                            return equalsCheck(
                                toFilter as [],
                                smVal
                            )
                                ? null
                                : setsmVal(toFilter as []);
                        }}
                        onChange={(selected: Option[]) => {
                            console.log("selected", selected);
                            setSmSelect(selected);
                        }}
                    />
                </div>
                <div className="col-xxl-3 mb-5">
                    <FilterMultiSelect
                        options={outletOpt}
                        label="Outlet"
                        isSelectAll={true}
                        value={outletSelect}
                        onMenuClose={() => {
                            let toFilter = outletSelect?.map((val) => val.value);
                            return equalsCheck(
                                toFilter as [],
                                outletVal
                            )
                                ? null
                                : setoutletVal(toFilter as []);
                        }}
                        onChange={(selected: Option[]) => {
                            setOutletSelect(selected);
                        }}
                    />
                </div>
                <div className='col-xxl-3 mb-5'
                style={{ zIndex: 4 }}>
                    <SingleSelect
                        defaultValue={slcMonth}
                        options={monthOpt}
                        label="Month"
                        onChange={(val) => {
                            setMonth(val.value);
                            setSlcMonth(val);
                        }} />
                </div>
            </div>
            <div className='row d-flex justify-content-around flex-sm-row flex-column'>
                <div className='col col-lg-6 card'>
                    <TargetvActualTable data={dttargetvactual} />
                </div>
                <div className='col col-lg-6'>
                    <div className='card'>
                        <ChartQuantity title={"Quantity by Training Level"} datas={chartTextLevel} dataChart={dtQtyByTraining} />
                    </div>
                    <div className='mt-10 card'>
                        <ChartQuantity title={"Quantity by Working Periode"} datas={chartTextPeriode} dataChart={dtWorkPeriod} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Quantity;
