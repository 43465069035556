import { Box } from "@mui/material";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
export default class UserLoginModel {
  constructor(
    data = {
      id: 0,
      id_karyawan: "",
      id_brand: "",
      id_roles: "",
      id_perusahaan: "",
      email: "",
      nama: "",
      logos: "",
      nik: "",
      username: "",
      roles: "",
      coverage: [],
      status: 0,
    }
  ) {
    const { id, id_karyawan, id_brand, id_roles, id_perusahaan, email, nama, logos, nik, username, coverage, roles, status } = data;
    this["id"] = id;
    this["id_karyawan"] = id_karyawan;
    this["id_brand"] = id_brand;
    this["id_roles"] = id_roles;
    this["id_perusahaan"] = id_perusahaan;
    this["email"] = email;
    this["nama"] = nama;
    this["logos"] = logos;
    this["nik"] = nik;
    this["username"] = username;
    this["roles"] = roles;
    this["coverage"] = coverage;
    this["status"] = status;
  }

  async getdata(this: any) {
    const { nama, logos, nik, username, roles, status } = this;
    return [{
      header: "nama",
      id: "id_names",
      value: nama,
    }, {
      header: "Nama",
      id: "logos",
      value: logos,
      Cell: ({ cell }) => {
        return <Box
          sx={{
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <img
            alt="logos"
            height={40}
            src={cell.getValue() ? cell.getValue() : toAbsoluteUrl("/media/avatars/blank.png")}
            loading="lazy"
            style={{ borderRadius: '50%' }}
          />
          <span> {cell.row.original['id_names']}</span>
        </Box>
      },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      size: 100,
    }, {
      header: "NIK",
      id: "nik",
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      value: nik,
      size: 60,
    }, {
      header: "Username",
      id: "username",
      value: username,
      size: 40,
    }, {
      header: "Roles",
      id: "roles",
      value: roles,
      size: 40,
    }, {
      header: "Status Aktif",
      id: "status",
      value: status,
      Cell: ({ cell }) => {
        return <span className={`badge py-3 px-4 fs-7 badge-light-${cell.getValue() === 1 ? "success" : "danger"}`}>{cell.getValue() === 1 ? "Aktif" : "Tidak Aktif"}</span>
      },
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      size: 60,
    }];
  }
}
