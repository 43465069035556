import React, { useEffect, useMemo, useState } from 'react'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'
import { ApprovalDp, ChartLeadtimeHelper } from './helper'
import { MaterialReactTableDetails } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable'
import { MscpAPI } from '../../../../api/MscpAPI'
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput'
import { equalsCheck } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers'
import DataTableMuiModel from '../../../../models/DataTable/dataTableModal'
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
} from 'material-react-table';

type dtTable = {
    dp_percent: string;
    dp_approved_percent: string;
    approve: number;
    approve_up_dp: number;
    approve_up_deposit: number;
    rejection: number;
    count: number;
  }
const LeadtimeApproval = ({ coverage, dates, leasing, modelOpt }) => {
    const columns = useMemo<MRT_ColumnDef<dtTable>[]>(
        //column definitions...
        () => [
            {
                header: 'DP Application',
                accessorKey: 'dp_percent',
            },
            {
                header: 'DP Approved',
                accessorKey: 'dp_approved_percent',
            },
            {
                header: 'Approve',
                accessorKey: 'approve',
            },
            {
                header: 'Approve(Deposit)',
                accessorKey: 'approve_up_deposit',
            },
            {
                header: 'Approve(UP DP)',
                accessorKey: 'approve_up_dp',
            },
            {
                header: 'Reject',
                accessorKey: 'rejection',
            },
        ],
        [],
        //end
    );
    const [dtLeadtimeOutlet, setDtLeadtimeOutlet] = useState<any>([])

    const [modelValue, setModelValue] = useState<any>([])
    const [tempModel, setTempModel] = useState<any>([])

    // Dt Table Approval Application
    // const [dtTableApproval, setDtTableApproval] = useState<any>([])
    // const [colTableApproval, setColTableApproval] = useState<any>([])
    const [data, setData] = useState<any>([])
    const table = useMaterialReactTable({
        columns,
        data,
        enableGrouping: true,
        enableStickyHeader: true,
        enableColumnActions: false,
        enableSorting: false,
        initialState: {
            density: 'compact',
            grouping: ['dp_percent'], //an array of columns to group by by default (can be multiple)
            expanded: true,
        },
        enablePagination: false,
        enableBottomToolbar: false,
        muiTableContainerProps: { sx: { maxHeight: '650px' } },
        defaultColumn: {
            minSize: 10, //allow columns to get smaller than default
            maxSize: 30, //allow columns to get larger than default
            size: 10, //make columns wider by default
          },
    });

    let getDataTableApproval = async ({ coverage, dates, leasing, model: dtModelValue }) => {
        const { data: tableApprovalDP } = await new MscpAPI().getDtTableApproval({ coverage, dates, leasing, model: dtModelValue })
        setData(tableApprovalDP)
        // let tableApproval = new DataTableMuiModel({ readOnly: true });
        // tableApprovalDP.map((item: any, i: number) => {
        //     let dataModel = new ApprovalDp(item);
        //     tableApproval.add({
        //         id: i,
        //         no: i + 1,
        //         dataModel: dataModel,
        //         actions: null,
        //     });
        //     return item;
        // })
        // setColTableApproval(await tableApproval.getColumn());
        // setDtTableApproval(await tableApproval.getDatas());
    }

    useEffect(() => {
        let dtModelValue = modelValue.length > 0 ? modelValue : modelOpt.map((val: any) => val.value);

        new MscpAPI().getDtLeadtimebyOutlet({ coverage, dates, leasing, model: dtModelValue }).then((res: any) => {
            setDtLeadtimeOutlet(res.data)
        })

        getDataTableApproval({ coverage, dates, leasing, model: dtModelValue })

    }, [coverage, dates, leasing, modelValue, modelOpt])
    return (
        <>
            {/* BEGIN:: Filter Input */}
            <div className="row g-5 mt-10">
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-3 mb-5"
                        style={{ zIndex: 3 }}>
                        <FilterMultiSelect
                            label={"Model"}
                            name="model"
                            options={modelOpt}
                            onMenuClose={() => {
                                let toFilter = tempModel?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    modelValue
                                )
                                    ? null
                                    : setModelValue(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempModel(selected)
                            }}
                            value={tempModel}
                        />
                    </div>
                </div>
            </div>
            {/* END:: Filter Input */}
            <div className="row g-5 mh-xxl">
                {/* Leadtime by Outlet */}
                <div className="col-xxl-6 bd-highlight">
                    <KTCard stretch='stretch' className='mb-5'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1x">Leadtime by Outlet</span>
                            </h3>
                        </div>
                        <KTCardBody className="pl-5 px-2 pt-0">
                            <ChartLeadtimeHelper dt={dtLeadtimeOutlet} />
                        </KTCardBody>
                    </KTCard>
                </div>
                {/* Approval by DP Application */}
                <div className="col-xxl-6 bd-highlight">
                    <KTCard className='mb-5'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1x">Approval by DP Application</span>
                            </h3>
                        </div>
                        <KTCardBody className="pl-5 px-2 pt-0">
                            <MaterialReactTable 
                            table={table}
                            />
                            {/* <MaterialReactTableDetails
                                data={dtTableApproval}
                                columns={colTableApproval}
                                isLoading={false}
                                maxHeight={"650px"}
                                hover={false}
                                optionsHead={{
                                    backgroundColor: '#0097a7',
                                    fontSize: 10,
                                }}
                            /> */}
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
        </>
    )
}

export default LeadtimeApproval