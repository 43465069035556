/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { DoVolWuling, MovementbyModels, SparkLine } from "../SegmentHelper";
import { SegmentAPI } from "../../../../api/MarketShareAPI";
import { LinearProgress } from "@mui/material";
import { PerformanceAPI } from "../../../../api/PerformanceAPI";
import { MultipleSelectCheckmarks } from "../../performance/SelectHelper";
import MovementByModelsView from "./MovementByModels";
import FilterMultiSelect from "../../../../../_metronic/helpers/components/form-actions/FilteringInput";
import { equalsCheck } from "../../../../../_metronic/helpers/helpers-data/AssetHelpers";

function MarketSegmentCont() {
  const [asmVal, setasmVal] = React.useState([]);
  const [smVal, setsmVal] = React.useState([]);
  const [cov, setCoverage] = React.useState([]);
  const [asmOpt, setasmOpt] = React.useState([]);
  const [arrSelect, setArrSelect] = React.useState([]);
  const [smOpt, setsmOpt] = React.useState([]);
  const [smSelect, setSmSelect] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [dtSegmentMov, setDtSegmentMov] = useState([]);
  const [chartWulingVol, setChartWulingVol] = useState([]);
  const [dtSparkLine, setDtSparkLine] = useState([]);

  async function getFilter() {
    let val = {
      asm: asmVal.slice(),
      sm: smVal.slice(),
    };
    return await new PerformanceAPI().getFilter(val).then((res) => {
      let tempAsm = [];
      let tempsm = [];
      // console.log("Res Data",res.data);
      res.data.dataAsm.map((val) => {
        // console.log("Val",val);
        return tempAsm.push({
          label: val.area,
          value: val.id_team_asm,
        });
      });
      res.data.dataSm.map((val) => {
        return tempsm.push({
          label: val.region,
          value: val.id_team_sm,
        });
      });
      setasmOpt(tempAsm);
      setsmOpt(tempsm);
      setCoverage(res.data.coverage);
      tableData(res.data.coverage).then(() => {
        setLoading(false);
      });
    });
  }
  async function tableData(cov) {
    setLoading2(true);
    await new SegmentAPI().sparkLineVol(cov).then((res) => {
      return setDtSparkLine(res.response);
    });
    await new SegmentAPI().movementSegment(cov).then((res) => {
      res.response.map((val) => {
        return setDtSegmentMov(val.chart);
      });
    });
    await new SegmentAPI().chartWulingVol(cov).then((res) => {
      return setChartWulingVol(res.response);
    }).then(() => {
      setLoading2(false);
    });
  }

  useEffect(() => {
    getFilter();
  }, [asmVal, smVal, cov]);

  if (!loading) {
    return (
      <>
        <PageTitle
          breadcrumbs={[
            {
              title: "Market Segment",
              path: "/sales/market-segment",
              isSeparator: false,
              isActive: false,
            },
          ]}
        >
          {"Market Segment"}
        </PageTitle>
        <div className="row d-flex justify-content-center my-15 g-5 absolute">
        <div className="col-xxl-3 mb-5">
          <FilterMultiSelect
            options={asmOpt}
            label="ASM"
            isSelectAll={true}
            value={arrSelect}
            onMenuClose={() => {
              let toFilter = arrSelect?.map((val) => val.value);

              return equalsCheck(toFilter, asmVal) ? null : setasmVal(toFilter);
            }}
            onChange={(selected) => {
              setArrSelect(selected);
            }}
          />
        </div>
        <div className="col-xxl-3 mb-5" style={{ zIndex: 4 }}>
          <FilterMultiSelect
            options={smOpt}
            label="SM"
            isSelectAll={true}
            value={smSelect}
            onMenuClose={() => {
              let toFilter = smSelect?.map((val) => val.value);
              return equalsCheck(toFilter, smVal) ? null : setsmVal(toFilter);
            }}
            onChange={(selected) => {
              console.log("selected", selected);
              setSmSelect(selected);
            }}
          />
        </div>
      </div>
        {/* Movement By Models */}
        <div className="mt-10">
          <MovementByModelsView cov={cov} loadingState={loading3} setLoading={setLoading3}/>
        </div>
        <div className="mt-10">
          <MovementbyModels title={"Segment Movement"} dt={dtSegmentMov} loadState={loading2}/>
        </div>
        <div className="mt-10">
          <DoVolWuling title={"DO & Wuling Volume"} dt={chartWulingVol} loadState={loading2}/>
        </div>
        <div className="mt-10">
          <SparkLine dt={dtSparkLine} />
        </div>
      </>
    );
  } else {
    return <LinearProgress />;
  }
}

export default MarketSegmentCont;
