import { Text2Color, TextColor, background2Color, backgroundColorHSLDarkToLight, progressBarLine, spanNumberWithPoint, spanString } from "../../../_metronic/helpers/components/MaterialReactTable/ComponentStyleRowsTable";
import { numberWithPoint } from "../../../_metronic/helpers/helpers-data/AssetHelpers";
import moment from "moment";
export class GaikindoRSModel {
    constructor(
        data = {
            id: "",
            brand: "",
            rs_old_year: "",
            sum_rs_old_year: "",
            rs_year_now: "",
            sum_rs_year_now: "",
            growth: null,
            sum_growth: 0,
            share_old_year: 0,
            sum_share_old_year: 0,
            share_year_now: 0,
            sum_share_year_now: 0,
            year: 0,
            status: 0,
            max_rs_old_year: 0,
            max_rs_year_now: 0,
            max_share_old_year: 0,
            max_share_year_now: 0,
        }
    ) {
        const { id, brand, rs_old_year, sum_rs_old_year, rs_year_now, sum_rs_year_now, growth, sum_growth, share_old_year, sum_share_old_year, share_year_now, sum_share_year_now, year, status, max_rs_old_year, max_rs_year_now, max_share_old_year, max_share_year_now } = data;
        this["id"] = id;
        this["brand"] = brand;
        this["rs_old_year"] = rs_old_year;
        this["sum_rs_old_year"] = sum_rs_old_year;
        this["rs_year_now"] = rs_year_now;
        this["sum_rs_year_now"] = sum_rs_year_now;
        this["growth"] = growth;
        this["sum_growth"] = sum_growth;
        this["share_old_year"] = share_old_year;
        this["sum_share_old_year"] = sum_share_old_year;
        this["share_year_now"] = share_year_now;
        this["sum_share_year_now"] = sum_share_year_now;
        this["year"] = year;
        this["status"] = status;
        this["max_rs_old_year"] = max_rs_old_year;
        this["max_rs_year_now"] = max_rs_year_now;
        this["max_share_old_year"] = max_share_old_year;
        this["max_share_year_now"] = max_share_year_now;
    }
    async getdata(this: any) {
        const { brand, rs_old_year, sum_rs_old_year, rs_year_now, sum_rs_year_now, growth, sum_growth, share_old_year, sum_share_old_year, share_year_now, sum_share_year_now, year, max_rs_old_year, max_rs_year_now, max_share_old_year, max_share_year_now } = this;
        return [{
            header: "Brand",
            id: "brand",
            filterVariant: "select",
            value: brand,
            size: 120,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: `${year - 1} RS`,
            id: "rs_old_year",
            value: rs_old_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_rs_old_year, '#297f9d');
            },
            size: 130,
            footer: spanNumberWithPoint(sum_rs_old_year, false)
        }, {
            header: `${year} RS`,
            id: "rs_year_now",
            value: rs_year_now,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_rs_year_now, '#fcc47c');
            },
            size: 130,
            footer: spanNumberWithPoint(sum_rs_year_now, false)
        }, {
            header: "Growth",
            id: "growth",
            value: growth,
            Cell: ({ cell }) => {
                return Text2Color(cell.getValue(), 0, ['text-success', 'text-danger'])
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: background2Color(cell.getValue(), 0, ['#c8e6c9', '#f4c7c3'])
                    },
                    align: 'center',
                })
            },
            size: 60,
            footer: spanNumberWithPoint(sum_growth, true)
        }, {
            header: `${year - 1} Share`,
            id: "share_old_year",
            value: share_old_year,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["196", "59%", "39%"], cell.getValue(), max_share_old_year)
                    },
                    align: 'center',
                })
            },
            size: 60,
            footer: spanNumberWithPoint(sum_share_old_year, true)
        }, {
            header: `${year} Share`,
            id: "share_year_now",
            value: share_year_now,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["34", "96%", "74%"], cell.getValue(), max_share_year_now)
                    },
                    align: 'center',
                })
            },
            size: 60,
            footer: spanNumberWithPoint(sum_share_year_now, true)
        }];
    }
}
export class GaikindoWSModel {
    constructor(
        data = {
            id: "",
            brand: "",
            ws_old_year: "",
            sum_ws_old_year: "",
            ws_year_now: "",
            sum_ws_year_now: "",
            growth: null,
            sum_growth: 0,
            share_old_year: 0,
            sum_share_old_year: 0,
            share_year_now: 0,
            sum_share_year_now: 0,
            year: 0,
            status: 0,
            max_ws_old_year: 0,
            max_ws_year_now: 0,
            max_share_old_year: 0,
            max_share_year_now: 0,
        }
    ) {
        const { id, brand, ws_old_year, sum_ws_old_year, ws_year_now, sum_ws_year_now, growth, sum_growth, share_old_year, sum_share_old_year, share_year_now, sum_share_year_now, year, status, max_ws_old_year, max_ws_year_now, max_share_old_year, max_share_year_now } = data;
        this["id"] = id;
        this["brand"] = brand;
        this["ws_old_year"] = ws_old_year;
        this["sum_ws_old_year"] = sum_ws_old_year;
        this["ws_year_now"] = ws_year_now;
        this["sum_ws_year_now"] = sum_ws_year_now;
        this["growth"] = growth;
        this["sum_growth"] = sum_growth;
        this["share_old_year"] = share_old_year;
        this["sum_share_old_year"] = sum_share_old_year;
        this["share_year_now"] = share_year_now;
        this["sum_share_year_now"] = sum_share_year_now;
        this["year"] = year;
        this["status"] = status;
        this["max_ws_old_year"] = max_ws_old_year;
        this["max_ws_year_now"] = max_ws_year_now;
        this["max_share_old_year"] = max_share_old_year;
        this["max_share_year_now"] = max_share_year_now;
    }
    async getdata(this: any) {
        const { brand, ws_old_year, sum_ws_old_year, ws_year_now, sum_ws_year_now, growth, sum_growth, share_old_year, sum_share_old_year, share_year_now, sum_share_year_now, year, max_ws_old_year, max_ws_year_now, max_share_old_year, max_share_year_now } = this;
        return [{
            header: "Brand",
            id: "brand",
            filterVariant: "select",
            value: brand,
            size: 120,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: `${year - 1} WS`,
            id: "ws_old_year",
            value: ws_old_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_ws_old_year, '#297f9d');
            },
            size: 130,
            footer: spanNumberWithPoint(sum_ws_old_year, false)
        }, {
            header: `${year} WS`,
            id: "ws_year_now",
            value: ws_year_now,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_ws_year_now, '#fcc47c');
            },
            size: 130,
            footer: spanNumberWithPoint(sum_ws_year_now, false)
        }, {
            header: "Growth",
            id: "growth",
            value: growth,
            Cell: ({ cell }) => {
                return Text2Color(cell.getValue(), 0, ['text-success', 'text-danger'])
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: background2Color(cell.getValue(), 0, ['#c8e6c9', '#f4c7c3'])
                    },
                    align: 'center',
                })
            },
            size: 60,
            footer: spanNumberWithPoint(sum_growth, true)
        }, {
            header: `${year - 1} Share`,
            id: "share_old_year",
            value: share_old_year,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["196", "59%", "39%"], cell.getValue(), max_share_old_year)
                    },
                    align: 'center',
                })
            },
            size: 60,
            footer: spanNumberWithPoint(sum_share_old_year, true)
        }, {
            header: `${year} Share`,
            id: "share_year_now",
            value: share_year_now,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["34", "96%", "74%"], cell.getValue(), max_share_year_now)
                    },
                    align: 'center',
                })
            },
            size: 60,
            footer: spanNumberWithPoint(sum_share_year_now, true)
        }];
    }
}
export class RetailSalesModel {
    constructor(
        data = {
            dealer_group: "",
            growth: "",
            rs_new_year: "",
            rs_old_year: "",
            share_old_year: "",
            share_year_now: "",
            sum_growth: null,
            sum_rs_old_year: 0,
            sum_rs_year_now: 0,
            sum_share_old_year: 0,
            sum_share_year_now: 0,
            year: 0,
            status: 0,
            max_rs_old_year: 0,
            max_rs_year_now: 0,
            max_share_old_year: 0,
            max_share_year_now: 0,
        }
    ) {
        const { dealer_group, growth, rs_new_year, rs_old_year, share_old_year, share_year_now, sum_growth, sum_rs_old_year, sum_rs_year_now, sum_share_old_year, sum_share_year_now, year, status, max_rs_old_year, max_rs_year_now, max_share_old_year, max_share_year_now } = data;
        this["dealer_group"] = dealer_group;
        this["growth"] = growth;
        this["rs_new_year"] = rs_new_year;
        this["rs_old_year"] = rs_old_year;
        this["share_old_year"] = share_old_year;
        this["share_year_now"] = share_year_now;
        this["sum_growth"] = sum_growth;
        this["sum_rs_old_year"] = sum_rs_old_year;
        this["sum_rs_year_now"] = sum_rs_year_now;
        this["sum_share_old_year"] = sum_share_old_year;
        this["sum_share_year_now"] = sum_share_year_now;
        this["year"] = year;
        this["status"] = status;
        this["max_rs_old_year"] = max_rs_old_year;
        this["max_rs_year_now"] = max_rs_year_now;
        this["max_share_old_year"] = max_share_old_year;
        this["max_share_year_now"] = max_share_year_now;
    }
    async getdata(this: any) {
        const { dealer_group, growth, rs_new_year, rs_old_year, share_old_year, share_year_now, sum_growth, sum_rs_old_year, sum_rs_year_now, sum_share_old_year, sum_share_year_now, year, max_rs_old_year, max_rs_year_now, max_share_old_year, max_share_year_now } = this;
        return [{
            header: "Dealer Group",
            id: "dealer_group",
            filterVariant: "select",
            value: dealer_group,
            size: 120,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: `${year - 1} RS`,
            id: "rs_old_year",
            value: rs_old_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_rs_old_year, '#297f9d');
            },
            size: 130,
            footer: spanNumberWithPoint(sum_rs_old_year, false)
        }, {
            header: `${year} RS`,
            id: "rs_new_year",
            value: rs_new_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_rs_year_now, '#fcc47c');
            },
            size: 130,
            footer: spanNumberWithPoint(sum_rs_year_now, false)
        }, {
            header: "Growth",
            id: "growth",
            value: growth,
            Cell: ({ cell }) => {
                return Text2Color(cell.getValue(), 0, ['text-success', 'text-danger'])
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: background2Color(cell.getValue(), 0, ['#c8e6c9', '#f4c7c3'])
                    },
                    align: 'center',
                })
            },
            size: 60,
            footer: spanNumberWithPoint(sum_growth, true)
        }, {
            header: `${year - 1} Share`,
            id: "share_old_year",
            value: share_old_year,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["196", "59%", "39%"], cell.getValue(), max_share_old_year)
                    },
                    align: 'center',
                })
            },
            size: 60,
            footer: spanNumberWithPoint(sum_share_old_year, true)
        }, {
            header: `${year} Share`,
            id: "share_year_now",
            value: share_year_now,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["34", "96%", "74%"], cell.getValue(), max_share_year_now)
                    },
                    align: 'center',
                })
            },
            size: 60,
            footer: spanNumberWithPoint(sum_share_year_now, true)
        }];
    }
}
export class WholesalesModel {
    constructor(
        data = {
            dealer_group: "",
            growth: "",
            ws_new_year: "",
            ws_old_year: "",
            share_old_year: "",
            share_year_now: "",
            sum_growth: null,
            sum_ws_old_year: 0,
            sum_ws_year_now: 0,
            sum_share_old_year: 0,
            sum_share_year_now: 0,
            year: 0,
            status: 0,
            max_ws_old_year: 0,
            max_ws_year_now: 0,
            max_share_old_year: 0,
            max_share_year_now: 0,
        }
    ) {
        const { dealer_group, growth, ws_new_year, ws_old_year, share_old_year, share_year_now, sum_growth, sum_ws_old_year, sum_ws_year_now, sum_share_old_year, sum_share_year_now, year, status, max_ws_old_year, max_ws_year_now, max_share_old_year, max_share_year_now } = data;
        this["dealer_group"] = dealer_group;
        this["growth"] = growth;
        this["ws_new_year"] = ws_new_year;
        this["ws_old_year"] = ws_old_year;
        this["share_old_year"] = share_old_year;
        this["share_year_now"] = share_year_now;
        this["sum_growth"] = sum_growth;
        this["sum_ws_old_year"] = sum_ws_old_year;
        this["sum_ws_year_now"] = sum_ws_year_now;
        this["sum_share_old_year"] = sum_share_old_year;
        this["sum_share_year_now"] = sum_share_year_now;
        this["year"] = year;
        this["status"] = status;
        this["max_ws_old_year"] = max_ws_old_year;
        this["max_ws_year_now"] = max_ws_year_now;
        this["max_share_old_year"] = max_share_old_year;
        this["max_share_year_now"] = max_share_year_now;
    }
    async getdata(this: any) {
        const { dealer_group, growth, ws_new_year, ws_old_year, share_old_year, share_year_now, sum_growth, sum_ws_old_year, sum_ws_year_now, sum_share_old_year, sum_share_year_now, year, max_ws_old_year, max_ws_year_now, max_share_old_year, max_share_year_now } = this;
        return [{
            header: "Dealer Group",
            id: "dealer_group",
            filterVariant: "select",
            value: dealer_group,
            size: 120,
            footer: spanString('Total Keseluruhan', 'fs-1x text-dark')
        }, {
            header: `${year - 1} WS`,
            id: "ws_old_year",
            value: ws_old_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_ws_old_year, '#297f9d');
            },
            size: 150,
            footer: spanNumberWithPoint(sum_ws_old_year, false)
        }, {
            header: `${year} WS`,
            id: "ws_new_year",
            value: ws_new_year,
            Cell: ({ cell }) => {
                return progressBarLine(cell.getValue(), max_ws_year_now, '#fcc47c');
            },
            size: 150,
            footer: spanNumberWithPoint(sum_ws_year_now, false)
        }, {
            header: "Growth",
            id: "growth",
            value: growth,
            Cell: ({ cell }) => {
                return Text2Color(cell.getValue(), 0, ['text-success', 'text-danger'])
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: background2Color(cell.getValue(), 0, ['#c8e6c9', '#f4c7c3'])
                    },
                    align: 'center',
                })
            },
            size: 60,
            footer: spanNumberWithPoint(sum_growth, true)
        }, {
            header: `${year - 1} Share`,
            id: "share_old_year",
            value: share_old_year,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["196", "59%", "39%"], cell.getValue(), max_share_old_year)
                    },
                    align: 'center',
                })
            },
            size: 60,
            footer: spanNumberWithPoint(sum_share_old_year, true)
        }, {
            header: `${year} Share`,
            id: "share_year_now",
            value: share_year_now,
            Cell: ({ cell }) => {
                return TextColor(cell.getValue())
            },
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    sx: {
                        backgroundColor: backgroundColorHSLDarkToLight(["34", "96%", "74%"], cell.getValue(), max_share_year_now)
                    },
                    align: 'center',
                })
            },
            size: 60,
            footer: spanNumberWithPoint(sum_share_year_now, true)
        }];
    }
}
export class NationalMsIslandModel {
    constructor(
        data = {
            island: '',
            ms_fy_year_old: '',
            market_ytd_now_unit: '',
            market_ytd_percent: '',
            polreg_ytd_now_unit: '',
            ms_ytd_now: '',
            chg_month: '',
            chg_year: '',
            date: '',
        }
    ) {
        const {
            island,
            ms_fy_year_old,
            market_ytd_now_unit,
            market_ytd_percent,
            polreg_ytd_now_unit,
            ms_ytd_now,
            chg_month,
            chg_year,
            date,
        } = data;
        this["island"] = island;
        this["ms_fy_year_old"] = ms_fy_year_old;
        this["market_ytd_now_unit"] = market_ytd_now_unit;
        this["market_ytd_percent"] = market_ytd_percent;
        this["polreg_ytd_now_unit"] = polreg_ytd_now_unit;
        this["ms_ytd_now"] = ms_ytd_now;
        this["chg_month"] = chg_month;
        this["chg_year"] = chg_year;
        this["date"] = date;
    }
    async getdata(this: any) {
        const {
            island,
            ms_fy_year_old,
            market_ytd_now_unit,
            market_ytd_percent,
            polreg_ytd_now_unit,
            ms_ytd_now,
            chg_month,
            chg_year,
            date
        } = this;
        let year1 = moment(date).format("YYYY");
        let year2 = moment(date).format("YY");
        let last_month = moment(date).subtract(1, 'month').format("MMM")
        let month_now = moment(date).format("MMM");
        let month_year = moment(date).format("MMM YY");
        return [{
            header: `Island`,
            id: "island",
            value: island,
            muiTableBodyCellProps: ({ cell }) => {
                let font = '';
                if (cell.row.original['island'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        fontSize: 12,
                        fontWeight: font,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            size: 80,
        }, {
            header: `MS FY
             ${parseInt(year1) - 1}`,
            id: "ms_fy_year_old",
            value: ms_fy_year_old,
            muiTableBodyCellProps: ({ cell }) => {
                let font = '';
                if (cell.row.original['island'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        fontSize: 12,
                        fontWeight: font,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                return `${cell.getValue()}%`;
            },
            size: 70,
        }, {
            header: `Market YTD ${month_year} (Unit)`,
            id: "market_ytd_now_unit",
            value: market_ytd_now_unit,
            muiTableBodyCellProps: ({ cell }) => {
                let font = '';
                if (cell.row.original['island'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        fontSize: 12,
                        fontWeight: font,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                return numberWithPoint(parseInt(cell.getValue()));
            },
            size: 80,
        }, {
            header: `Market YTD ${month_year} (%)`,
            id: "market_ytd_percent",
            value: market_ytd_percent,
            muiTableBodyCellProps: ({ cell }) => {
                let font = '';
                if (cell.row.original['island'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        fontSize: 12,
                        fontWeight: font,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                return `${cell.getValue()}%`;
            },
            size: 70,
        }, {
            header: `Polreg YTD ${month_year} (Unit)`,
            id: "polreg_ytd_now_unit",
            value: polreg_ytd_now_unit,
            muiTableBodyCellProps: ({ cell }) => {
                let font = '';
                if (cell.row.original['island'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        fontSize: 12,
                        fontWeight: font,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                return numberWithPoint(parseInt(cell.getValue()));
            },
            size: 70,
        }, {
            header: `MS YTD 
            ${month_year} (%)`,
            id: "ms_ytd_now",
            value: ms_ytd_now,
            muiTableBodyCellProps: ({ cell }) => {
                let colorBorder = '';
                if (cell.row.original['chg_year'] < 0) {
                    colorBorder = `2px solid #bf392b`
                }
                let backgroundColor = '';
                if (cell.row.original['no'] === 1 || cell.row.original['no'] === 2 || cell.row.original['no'] === 3) {
                    backgroundColor = `#ffecb3`
                }
                let font = '';
                if (cell.row.original['island'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        fontSize: 12,
                        fontWeight: font,
                        backgroundColor: backgroundColor,
                        borderLeft: colorBorder,
                        borderTop: colorBorder,
                        borderBottom: colorBorder,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                return `${cell.getValue()}%`;
            },
            size: 60,
        }, {
            header: `Chg (M/S YTD ${month_now} vs M/S YTD ${last_month})`,
            id: "chg_month",
            value: chg_month,
            muiTableBodyCellProps: ({ cell }) => {
                let colors = '';
                if (cell.getValue() >= 0) {
                    colors = 'green';
                } else if (cell.getValue() < 0) {
                    colors = 'red';
                }

                let colorBorder = '';
                if (cell.row.original['chg_year'] < 0) {
                    colorBorder = `2px solid #bf392b`
                }
                let font = '';
                if (cell.row.original['island'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        color: colors,
                        fontWeight: font,
                        fontSize: 12,
                        borderTop: colorBorder,
                        borderBottom: colorBorder,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                if (cell.getValue() !== 0) {
                    return `${cell.getValue()}%`;
                } else {
                    return `-`;
                }
            },
            size: 75,
        }, {
            header: `Chg (M/S YTD ${year2} vs M/S YTD ${parseInt(year2) - 1})`,
            id: "chg_year",
            value: chg_year,
            muiTableBodyCellProps: ({ cell }) => {
                let colors = '';
                if (cell.getValue() >= 0) {
                    colors = 'green';
                } else if (cell.getValue() < 0) {
                    colors = 'red';
                }

                let colorBorder = '';
                if (cell.row.original['chg_year'] < 0) {
                    colorBorder = `2px solid #bf392b`
                }
                let font = '';
                if (cell.row.original['island'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        fontSize: 12,
                        fontWeight: font,
                        color: colors,
                        borderRight: colorBorder,
                        borderTop: colorBorder,
                        borderBottom: colorBorder,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                if (cell.getValue() !== 0) {
                    return `${cell.getValue()}%`;
                } else {
                    if (cell.row.original['island'] === 'National') {
                        return `${cell.getValue()}%`;
                    }
                    return `-`;
                }
            },
            size: 75,
        }];
    }
}
export class NationalMsProvinceModel {
    constructor(
        data = {
            province: '',
            ms_fy_year_old: '',
            full_year_now_market: '',
            market_ytd_percent: '',
            polreg_ytd_now_unit: '',
            ms_ytd_month_year_now: '',
            chg_month: '',
            chg_year: '',
            date: ''
        }
    ) {
        const {
            province,
            ms_fy_year_old,
            full_year_now_market,
            market_ytd_percent,
            polreg_ytd_now_unit,
            ms_ytd_month_year_now,
            chg_month,
            chg_year,
            date
        } = data;
        this["province"] = province;
        this["ms_fy_year_old"] = ms_fy_year_old;
        this["full_year_now_market"] = full_year_now_market;
        this["market_ytd_percent"] = market_ytd_percent;
        this["polreg_ytd_now_unit"] = polreg_ytd_now_unit;
        this["ms_ytd_month_year_now"] = ms_ytd_month_year_now;
        this["chg_month"] = chg_month;
        this["chg_year"] = chg_year;
        this["date"] = date;
    }
    async getdata(this: any) {
        const { province,
            ms_fy_year_old,
            full_year_now_market,
            market_ytd_percent,
            polreg_ytd_now_unit,
            ms_ytd_month_year_now,
            chg_month,
            chg_year,
            date,
        } = this;
        let year1 = moment(date).format("YYYY");
        let year2 = moment(date).format("YY");
        let last_month = moment(date).subtract(1, 'month').format("MMM")
        let month_now = moment(date).format("MMM");
        let month_year = moment(date).format("MMM YY");
        return [{
            header: `Province`,
            id: "province",
            value: province,
            muiTableBodyCellProps: ({ cell }) => {
                let font = '';
                if (cell.row.original['province'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        fontSize: 12,
                        fontWeight: font,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            size: 80,
        }, {
            header: `MS FY
             ${parseInt(year1) - 1}`,
            id: "ms_fy_year_old",
            value: ms_fy_year_old,
            muiTableBodyCellProps: ({ cell }) => {
                let font = '';
                if (cell.row.original['province'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        fontSize: 12,
                        fontWeight: font,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                return `${cell.getValue()}%`;
            },
            size: 65,
        }, {
            header: `Market YTD ${month_year} (Unit)`,
            id: "full_year_now_market",
            value: full_year_now_market,
            muiTableBodyCellProps: ({ cell }) => {
                let font = '';
                if (cell.row.original['province'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        fontSize: 12,
                        fontWeight: font,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                return numberWithPoint(parseInt(cell.getValue()));
            },
            size: 75,
        }, {
            header: `Market YTD ${month_year} (%)`,
            id: "market_ytd_percent",
            value: market_ytd_percent,
            muiTableBodyCellProps: ({ cell }) => {
                let font = '';
                if (cell.row.original['province'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        fontSize: 12,
                        fontWeight: font,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                return `${cell.getValue()}%`;
            },
            size: 70,
        }, {
            header: `Polreg YTD ${month_year} (Unit)`,
            id: "polreg_ytd_now_unit",
            value: polreg_ytd_now_unit,
            muiTableBodyCellProps: ({ cell }) => {
                let font = '';
                if (cell.row.original['province'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        fontSize: 12,
                        fontWeight: font,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                return numberWithPoint(parseInt(cell.getValue()));
            },
            size: 65,
        }, {
            header: `MS YTD 
            ${month_year} (%)`,
            id: "ms_ytd_month_year_now",
            value: ms_ytd_month_year_now,
            muiTableBodyCellProps: ({ cell }) => {
                let font = '';
                if (cell.row.original['province'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        fontSize: 12,
                        fontWeight: font,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                return `${cell.getValue()}%`;
            },
            size: 60,
        }, {
            header: `Chg (M/S YTD ${month_now} vs M/S YTD ${last_month})`,
            id: "chg_month",
            value: chg_month,
            muiTableBodyCellProps: ({ cell }) => {
                let colors = '';
                if (cell.getValue() >= 0) {
                    colors = 'green';
                } else if (cell.getValue() < 0) {
                    colors = 'red';
                }
                let font = '';
                if (cell.row.original['province'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        color: colors,
                        fontSize: 12,
                        fontWeight: font,
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                return `${cell.getValue()}%`;
            },
            size: 75,
        }, {
            header: `Chg (M/S YTD ${year2} vs M/S YTD ${parseInt(year2) - 1})`,
            id: "chg_year",
            value: chg_year,
            muiTableBodyCellProps: ({ cell }) => {
                let colors = '';
                if (cell.getValue() >= 0) {
                    colors = 'green';
                } else if (cell.getValue() < 0) {
                    colors = 'red';
                }
                let font = '';
                if (cell.row.original['province'] === 'National') {
                    font = 'bold';
                }
                return ({
                    sx: {
                        color: colors,
                        fontSize: 12,
                        fontWeight: font,
                    },
                    align: 'center',
                })
            },
            Cell: ({ cell }) => {
                if (cell.row.original['province'] === 'National') {
                    return `${cell.getValue()}%`;
                }
                return `${cell.getValue()}%`;
            },
            size: 75,
        }];
    }
}