import React, { useEffect, useRef, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import { HighchartsSample } from '../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample';
import { MaterialReactTableDetails } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';
import DataTableMuiModel from '../../../../models/DataTable/dataTableModal';
import { TargetConversionAPI } from '../../../../api/TargetConversionAPI';
import { spanNumberWithPoint } from '../../../../../_metronic/helpers/components/MaterialReactTable/ComponentStyleRowsTable';
import { ConversionLeadsToSpkTestDriveModel } from '../../../../models/sales/TargetConversionModel';
import GetDataFilter from '../../../../hooks/filter';
import TypesFilter from '../../../../hooks/filterType';
import { equalsCheck, monthOpt } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';
import { LinearProgress } from '@mui/material';

const LeadsSourceNConversionIndex = () => {
  const typesOpt = TypesFilter();
  const [dataLeads, setDataLeads] = useState<any>([]);
  const [asmVal, setasmVal] = useState<string[]>([]);
  const [smVal, setsmVal] = useState<string[]>([]);
  const [outletVal, setoutletVal] = useState<string[]>([]);
  const [arrSelect, setArrSelect] = useState<Option[] | null>([]);
  // const arrSelect = useRef<Option[]>([]);
  const [smSelect, setSmSelect] = useState<Option[] | null>([]);
  const [outletSelect, setOutletSelect] = useState<Option[] | null>([]);
  const [slcMonth, setSlcMonth] = useState<number[]>([]);
  const [monthSlc, setMonthSlc] = useState<Option[]>([]);
  const [dataSpk, setDataSpk] = useState<any>([]);
  const [load, setLoad] = useState<boolean>(false);
  const [column_no_testdrive, setColumn_no_testdrive] = useState<any>([]);
  const [data_no_testdrive, setData_no_testdrive] = useState<any>([]);
  const [column_yes_testdrive, setColumn_yes_testdrive] = useState<any>([]);
  const [data_yes_testdrive, setData_yes_testdrive] = useState<any>([]);
  const [tempType, setTempType] = useState<Option[] | null>([]);
  const [typeValue, setTypeValue] = useState<String[]>([]);
  const filterData = GetDataFilter({ asmVal: asmVal, smVal: smVal, outletVal: outletVal });


  //* ----------------------------------------------------------------------------- */
  async function FilterLoadTable(params?: Object) {
    // API : Conversion Leads to SPK (w/o Test Drive)
    const { data: table_no_test_drive } = await new TargetConversionAPI().getTableLeadsToSpkNoTestDrive({
    });
    let dataTableNoTestDrive = new DataTableMuiModel({ readOnly: true, NumRows: true });
    table_no_test_drive.map((item: any, i: number) => {
      let dataModel = new ConversionLeadsToSpkTestDriveModel(item);
      dataTableNoTestDrive.add({
        id: i,
        no: i + 1,
        dataModel: dataModel,
        actions: null,
      });
      return item;
    });
    setColumn_no_testdrive(await dataTableNoTestDrive.getColumn());
    setData_no_testdrive(await dataTableNoTestDrive.getDatas());
    // API : Conversion Leads to SPK (Test Drive)
    const { data: table_yes_test_drive } = await new TargetConversionAPI().getTableLeadsToSpkYesTestDrive({
    });
    let dataTableYesTestDrive = new DataTableMuiModel({ readOnly: true, NumRows: true });
    table_yes_test_drive.map((item: any, i: number) => {
      let dataModel2 = new ConversionLeadsToSpkTestDriveModel(item);
      dataTableYesTestDrive.add({
        id: i,
        no: i + 1,
        dataModel: dataModel2,
        actions: null,
      });
      return item;
    });
    setColumn_yes_testdrive(await dataTableYesTestDrive.getColumn());
    setData_yes_testdrive(await dataTableYesTestDrive.getDatas());
  }
  useEffect(() => {
    setLoad(true);
    Promise.all([
      new TargetConversionAPI().getChartBarSourceLeads({}).then((res) => {
        setDataLeads(res.data[0]);
      }),
      new TargetConversionAPI().getChartBarSourceSpk({}).then((res) => {
        setDataSpk(res.data[0]);
      }),
      FilterLoadTable({}),
    ]).then(() => {
      setTimeout(() => {
        setLoad(false)
      }, 2000);
    });

  }, [filterData.coverage, slcMonth, typeValue]);

  return (
    <React.Fragment>
      <PageTitle breadcrumbs={[{ title: "Target & Conversion", path: "/sales/leads-source-n-conversion", isSeparator: false, isActive: false }]}>
        {"Leads Source & Conversion"}
      </PageTitle>
      {/* Filter Input */}
      <div className="row g-5">
        <div className="col-md-2 mb-5">
          <FilterMultiSelect
            label={"ASM"}
            name="asm"
            options={filterData.asmOpt}
            onMenuClose={() => {
              let toFilter = arrSelect?.map((val) => val.value);

              return equalsCheck(
                toFilter as [],
                asmVal
              )
                ? null
                : setasmVal(toFilter as []);
            }}
            onChange={(selected: Option[]) => {
              setArrSelect(selected);
            }}
            value={arrSelect}
          />
        </div>
        <div className="col-md-3 mb-5">
          <FilterMultiSelect
            options={filterData.smOpt}
            label="SM"
            value={smSelect}
            onMenuClose={() => {
              let toFilter = smSelect?.map((val) => val.value);
              return equalsCheck(
                toFilter as [],
                smVal
              )
                ? null
                : setsmVal(toFilter as []);
            }}
            onChange={(selected: Option[]) => {
              console.log("selected", selected);
              setSmSelect(selected);
            }}
          />
        </div>
        <div className="col-md-3 mb-5">
          <FilterMultiSelect
            options={filterData.outletOpt}
            label="Outlet"
            value={outletSelect}
            onMenuClose={() => {
              let toFilter = outletSelect?.map((val) => val.value);
              return equalsCheck(
                toFilter as [],
                outletVal
              )
                ? null
                : setoutletVal(toFilter as []);
            }}
            onChange={(selected: Option[]) => {
              setOutletSelect(selected);
            }}
          />
        </div>
        <div className="col-md-2 mb-5">
          <FilterMultiSelect
            label={"Month"}
            options={monthOpt}
            value={monthSlc}
            onMenuClose={() => {
              let toFilter = monthSlc?.map((val) => val.value);
              return equalsCheck(
                toFilter as [],
                slcMonth
              )
                ? null
                : setSlcMonth(toFilter as []);
            }}
            onChange={(selected: Option[]) => {
              setMonthSlc(selected);
            }}
          />
        </div>
        <div className="col-md-2 mb-5">
          <FilterMultiSelect
            label={"Type"}
            name="type"
            options={typesOpt}
            value={tempType}
            onMenuClose={() => {
              let toFilter = tempType?.map((val) => val.value);
              return equalsCheck(
                toFilter as [],
                typeValue
              )
                ? null
                : setTypeValue(toFilter as []);
            }}
            onChange={(selected: Option[]) => {
              setTempType(selected);
            }}
          />
        </div>
      </div>
      {load ? <LinearProgress /> :
        <React.Fragment>
          <div className="row g-5">
            {/* Source Leads */}
            <div className='col-xxl-7 bd-highlight'>
              <KTCard className='mb-5'>
                <div className="card-header justify-content-center">
                  <h3 className="card-title align-items-center flex-column">
                    <span className="card-label fw-bold fs-1">Source Leads</span>
                  </h3>
                </div>
                <KTCardBody className="p-3">
                  <HighchartsSample
                    id="source_leads"
                    type="bar"
                    series={[{
                      type: 'bar',
                      name: 'Leads',
                      colorByPoint: false,
                      data: dataLeads?.chart
                    }]}
                    height='350px'
                    optionsSetting={{
                      colors: ['#297f9d'],
                      yAxis: {
                        visible: false
                      }, legend: {
                        enabled: false
                      }, tooltip: {
                        enabled: true,
                      }
                    }}
                  />
                </KTCardBody>
              </KTCard>
            </div>
            {/* Total Leads */}
            <div className='col-xxl-5 bd-highlight'>
              <KTCard stretch='stretch' className='mb-5'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-50px m-5'>
                    <img src="/media/images/total_leads.png" alt="" />
                  </div>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className='fw-bold text-gray-400'>Total Leads</div>
                    <div className='fw-bolder fs-1 text-gray-700'>{spanNumberWithPoint(dataLeads?.total, false)}</div>
                  </div>
                </div>
                <HighchartsSample
                  id="total_leads"
                  type="pie_donut"
                  height='300px'
                  positionLegend='TopRight'
                  series={dataLeads?.donat ? dataLeads?.donat[0] : []}
                />
              </KTCard>
            </div>
          </div>
          {/* ----------------------------------------------------------------------------- */}
          {/* Source SPK & Total SPK */}
          <div className="row g-5">
            {/* Source SPK */}
            <div className='col-xxl-7 bd-highlight'>
              <KTCard className='mb-5'>
                <div className="card-header justify-content-center">
                  <h3 className="card-title align-items-center flex-column">
                    <span className="fw-bold fs-4">Source SPK</span>
                  </h3>
                </div>
                <KTCardBody className="p-0">
                  <HighchartsSample
                    id="source_spk"
                    type="bar"
                    // series={chart_bar_source_spk.series}
                    series={[{
                      type: 'bar',
                      name: 'SPK',
                      colorByPoint: false,
                      data: dataSpk?.chart
                    }]}
                    height='350px'
                    optionsSetting={{
                      colors: ['#297f9d'],
                      yAxis: {
                        visible: false
                      }, legend: {
                        enabled: false
                      }, tooltip: {
                        enabled: true,
                      }
                    }}
                  />
                </KTCardBody>
              </KTCard>
            </div>
            {/* Total spk */}
            <div className='col-xxl-5 bd-highlight'>
              <KTCard stretch='stretch' className='mb-5'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-50px m-5'>
                    <img src="/media/images/total_spk.png" alt="" />
                  </div>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className='fw-bold text-gray-400'>Total SPK</div>
                    <div className='fw-bolder fs-1 text-gray-700'>{spanNumberWithPoint(dataSpk?.total, false)}</div>
                  </div>
                </div>
                <HighchartsSample
                  id="total_spk"
                  type="pie_donut"
                  height='300px'
                  positionLegend='TopRight'
                  series={dataSpk?.donat ? dataSpk?.donat[0] : dataSpk?.donat ?? [0]}
                />
              </KTCard>
            </div>
          </div>
          {/* Conversion Leads to SPK */}
          <div className="row g-5">
            {/* w/o Test Drive */}
            <div className='col-xxl-6 h-md-100 bd-highlight'>
              <KTCard stretch='stretch' className='mb-5'>
                <div className="card-header min-h-100 justify-content-center">
                  <h3 className="card-title align-items-center flex-column">
                    <span className="card-label fw-bold fs-4">Conversion Leads to SPK</span>
                    <span className="text-muted fw-semibold fs-6">(w/o Test Drive)</span>
                  </h3>
                </div>
                <KTCardBody className="py-5 px-2">
                  <div className='mb-6'>
                    <MaterialReactTableDetails
                      columns={column_no_testdrive}
                      data={data_no_testdrive}
                      // isLoading={loading}
                      maxHeight='250px'
                      enableTopToolbar={false}
                      hover={false}
                      optionsHead={{
                        fontSize: 9,
                      }}
                    />
                  </div>
                </KTCardBody>
              </KTCard>
            </div>
            {/* Test Drive */}
            <div className='col-xxl-6 h-md-100 bd-highlight'>
              <KTCard stretch='stretch' className='mb-5'>
                <div className="card-header min-h-100 justify-content-center">
                  <h3 className="card-title align-items-center flex-column">
                    <span className="card-label fw-bold fs-4">Conversion Leads to SPK</span>
                    <span className="text-muted fw-semibold fs-6">(Test Drive)</span>
                  </h3>
                </div>
                <KTCardBody className="py-5 px-2">
                  <div className='mb-6'>
                    <MaterialReactTableDetails
                      columns={column_yes_testdrive}
                      data={data_yes_testdrive}
                      // isLoading={loading}
                      maxHeight='250px'
                      enableTopToolbar={false}
                      hover={false}
                      optionsHead={{
                        fontSize: 9,
                      }}
                    />
                  </div>
                </KTCardBody>
              </KTCard>
            </div>
          </div>
        </React.Fragment>}
    </React.Fragment>
  )
}

export default LeadsSourceNConversionIndex
