import { multiPartFilesFetching, fetchingGetData } from "./API";

export class MasterDataSalesAPI {
  //**BEGIN:: NATIONAL INFORMATION API */
  // Gaikindo
  async getDataGaikindo() {
    let res = await fetchingGetData({
      url: `sales/master-data/gaikindo`,
      method: "get",
    });
    return res;
  }

  async UploadFilesGaikindo(file: any, onUploadProgress: any) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `sales/master-data/gaikindo/upload`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }

  // Retail Sales (RS) & Whole Sales (WS)
  async getDataRsWs() {
    let res = await fetchingGetData({
      url: `retail_whole_sales/national`,
      method: "get",
    });
    return res;
  }

  async UploadFilesRsWs(file: any, onUploadProgress: any) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `retail_whole_sales/national/upload`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }

  // National MS
  async getDataNationalMs() {
    let res = await fetchingGetData({
      url: `market_share/national`,
      method: "get",
    });
    return res;

  }
  async UploadFilesNationalMs(file: any, onUploadProgress: any) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `market_share/national/upload`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }

  // National MS Maps
  async getDataNationalMsMaps() {
    let res = await fetchingGetData({
      url: `market_share/national/maps`,
      method: "get",
    });
    return res;
  }

  async UploadFilesNationalMsMaps(file: any, onUploadProgress: any) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `market_share/national/maps/upload`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }

  //**END:: NATIONAL INFORMATION API */
  //------------------------------------------------------------------------------//
  //**BEGIN:: INTERNAL INFORMATION API */
  // Market Share
  async getDataInternalMarketShare() {
    let res = await fetchingGetData({
      url: `market_share/internal`,
      method: "get",
    });
    return res;
  }

  async UploadFilesInternalMarketShare(file: any, onUploadProgress: any) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `market_share/internal/upload`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }

  // Retail Sales
  async getDataInternalRetailSales() {
    let res = await fetchingGetData({
      url: `retail_sales/internal`,
      method: "get",
    });
    return res;
  }

  async UploadFilesInternalRetailSales(file: any, onUploadProgress: any) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `retail_sales/internal/upload`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }

  //**END:: INTERNAL INFORMATION API */
  //------------------------------------------------------------------------------//
  //**BEGIN:: TARGET API */
  // Model
  async getDataTargetModel() {
    let res = await fetchingGetData({
      url: `target/model`,
      method: "get",
    });
    return res;
  }

  async UploadFilesTargetModel(file: any, onUploadProgress: any) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `target/model/upload`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }

  // Outlet
  async getDataTargetOutlet() {
    let res = await fetchingGetData({
      url: `target/outlet`,
      method: "get",
    });
    return res;
  }

  async UploadFilesTargetOutlet(file: any, onUploadProgress: any) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `target/outlet/upload`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }

  // Target All 
  async getDataTargetAll() {
    let res = await fetchingGetData({
      url: `target/spk_do_rs_po_leads_testdrive`,
      method: "get",
    });
    return res;
  }

  async UploadFilesTargetAll(file: any, onUploadProgress: any) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `target/spk_do_rs_po_leads_testdrive`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }

  // Target Lead To Spk 
  async getDataTargetLeadToSpk() {
    let res = await fetchingGetData({
      url: `master/target/lead_to_spk/get`,
      method: "get",
    });
    return res;
  }

  async UploadFilesTargetLeadToSpk(file: any, onUploadProgress: any) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `master/target/lead_to_spk/upload`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }

  // Target Lead To Spk 
  async getDataTargetSpkToDo() {
    let res = await fetchingGetData({
      url: `master/target/spk_to_do/get`,
      method: "get",
    });
    return res;
  }

  async UploadFilesTargetSpkToDo(file: any, onUploadProgress: any) {
    let fd = new FormData();
    for (const key in file) {
      fd.append(key, file[key]);
    }
    let res = await multiPartFilesFetching({
      url: `master/target/spk_to_do/upload`,
      method: "post",
      data: fd,
      onUploadProgress,
    });
    return res;
  }
  //**END:: TARGET API */
}
