import { fetchingGetData } from "./API";

class QuantityAPI {
    async targetvactual(cov: string,m?: number | string) {
      let res = await fetchingGetData({
        url: `manpower/targetvactual?coverage=${cov || ""}&month=${m || ""}`,
        method: "get",
      });
      return res;
    }
    async qtyByLevel(cov: string,m?: number | string) {
      let res = await fetchingGetData({
        url: `manpower/qtybylevel?coverage=${cov || ""}&month=${m || ""}`,
        method: "get",
      });
      return res;
    }
    async qtyByWorkingPeriod(cov: string,m?: number | string) {
      let res = await fetchingGetData({
        url: `manpower/qtybyworkperiod?coverage=${cov || ""}&month=${m || ""}`,
        method: "get",
      });
      return res;
    }
  
}
class SaleswospkAPI {
    async tablewospk(cov: string,m?: number | string) {
      let res = await fetchingGetData({
        url: `manpower/saleswospk?coverage=${cov || ""}&month=${m || ""}`,
        method: "get",
      });
      return res;
    }
}
class ProductivityAPI {
    async tablemp(cov: string) {
      let res = await fetchingGetData({
        url: `manpower/productivity/tablebyspv?coverage=${cov || ""}`,
        method: "get",
      });
      return res;
    }
    async chartprodbyworkperiod(cov: string,m?: number | string) {
      let res = await fetchingGetData({
        url: `manpower/productivity/prodbyworkperiod?coverage=${cov || ""}&month=${m || ""}`,
        method: "get",
      });
      return res;
    }
    async chartprodbytraininglvl(cov: string,m?: number | string) {
      let res = await fetchingGetData({
        url: `manpower/productivity/prodbytraininglvl?coverage=${cov || ""}&month=${m || ""}`,
        method: "get",
      });
      return res;
    }
    async chartprodMoM(cov: string) {
      let res = await fetchingGetData({
        url: `manpower/productivity/productivityMoM?coverage=${cov || ""}`,
        method: "get",
      });
      return res;
    }

    async chartprodbyOutlet(cov: string,m?: number | string) {
      let res = await fetchingGetData({
        url: `manpower/productivity/productivityOutlet?coverage=${cov || ""}&month=${m || ""}`,
        method: "get",
      });
      return res;
    }
}

class SalesCompetencyAPI {
    async dtSalesCompetency() {
      let res = await fetchingGetData({
        url: `manpower/salescompetency`,
        // url: `manpower/productivity/tablebyspv?coverage=${cov || ""}`,
        method: "get",
      });
      return res;
    }
    async dtSalesComp(m?: number[] | string[]) {
      let res = await fetchingGetData({
        url: `manpower/dtsalescompetency?month=${m || ""}`,
        // url: `manpower/productivity/tablebyspv?coverage=${cov || ""}`,
        method: "get",
      });
      return res;
    }
}
class TurnOverAPI {
    async dtTurnOverTrend(cov: string) {
      let res = await fetchingGetData({
        url: `manpower/turnover/trend?coverage=${cov || ""}`,
        // url: `manpower/salescompetency`,
        method: "get",
      });
      return res;
    }
    async dtTObyTraininglvl(cov: string, m?: number | string) {
      let res = await fetchingGetData({
        url: `manpower/turnover/traininglevel?coverage=${cov || ""}&month=${m || ""}`,
        // url: `manpower/salescompetency`,
        method: "get",
      });
      return res;
    }
    async dtTObyOutlet(cov: string, m?: number | string) {
      let res = await fetchingGetData({
        url: `manpower/turnover/outlet?coverage=${cov || ""}&month=${m || ""}`,
        // url: `manpower/salescompetency`,
        method: "get",
      });
      return res;
    }
}

  export { QuantityAPI, SaleswospkAPI, ProductivityAPI, SalesCompetencyAPI, TurnOverAPI }