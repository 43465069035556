import React from "react";
import { getDate } from "../../../../../_metronic/helpers/helpers-data/AssetHelpers";
import { ProgressBar } from "react-bootstrap";

const BoxDo = ({ boxDO }) => {
    const dates = getDate()
    const currMonth = dates.currMonth
  return (
    <>
      <div
        className="row d-flex justify-content-around mt-10"
        style={{ height: "" }}
      >
        <div
          className="card col-12 col-md-2 bg-secondary"
          style={{ textAlign: "center" }}
        >
          <div className="card-body">
            <h6 className="card-title">Quartal 1 DO Ach</h6>
            {boxDO ? (
              <>
                <span
                  style={{ fontSize: "24px" }}
                  className="card-subtitle mb-2 h2"
                >
                  {boxDO.quartalSatu.qachieve}
                  {/* {doFullYear.slice(0, 3).reduce((a, b) => a + b, 0)} */}
                </span>
                {currMonth > 2 ? (
                  <i className="fa-solid fa-lock fa-9x"></i>
                ) : (
                  ""
                )}
                <p className="card-text">
                  {boxDO.quartalSatu.qpercent} % of {boxDO.quartalSatu.qtarget}{" "}
                  Q1 Target
                  {/* {doTarget.slice(0, 3).reduce((a, b) => a + b, 0)} */}
                </p>
                <ProgressBar
                  variant={`${
                    boxDO.quartalSatu.qpercent < 80
                      ? "danger"
                      : boxDO.quartalSatu.qpercent >= 80 &&
                        boxDO.quartalSatu.qpercent <= 100
                      ? "warning"
                      : "success"
                  }`}
                  now={boxDO.quartalSatu.qpercent}
                />
              </>
            ) : (
              "Load..."
            )}
          </div>
        </div>
        <div
          className="card col-12 col-md-2 bg-secondary"
          style={{ textAlign: "center" }}
        >
          <div className="card-body">
            <h6 className="card-title">Quartal 2 DO Ach</h6>
            {boxDO ? (
              <>
                <span
                  style={{ fontSize: "24px" }}
                  className="card-subtitle mb-2 h2"
                >
                  {boxDO.quartalDua.qachieve}
                  {/* {doFullYear.slice(0, 3).reduce((a, b) => a + b, 0)} */}
                </span>
                {currMonth > 5 ? (
                  <i className="fa-solid fa-lock fa-9x"></i>
                ) : (
                  ""
                )}
                <p className="card-text">
                  {boxDO.quartalDua.qpercent} % of {boxDO.quartalDua.qtarget} Q2
                  Target
                  {/* {doTarget.slice(0, 3).reduce((a, b) => a + b, 0)} */}
                </p>
                <ProgressBar
                  variant={`${
                    boxDO.quartalDua.qpercent < 80
                      ? "danger"
                      : boxDO.quartalDua.qpercent >= 80 &&
                        boxDO.quartalDua.qpercent <= 100
                      ? "warning"
                      : "success"
                  }`}
                  now={boxDO.quartalDua.qpercent}
                />
              </>
            ) : (
              "Load..."
            )}
          </div>
        </div>
        <div
          className="card col-12 col-md-2 bg-secondary"
          style={{ textAlign: "center" }}
        >
          <div className="card-body">
            <h6 className="card-title">Quartal 3 DO Ach</h6>
            {boxDO ? (
              <>
                <span
                  style={{ fontSize: "24px" }}
                  className="card-subtitle mb-2 h2"
                >
                  {boxDO.quartalTiga.qachieve}
                  {/* {doFullYear.slice(0, 3).reduce((a, b) => a + b, 0)} */}
                </span>
                {currMonth > 8 ? (
                  <i className="fa-solid fa-lock fa-9x"></i>
                ) : (
                  ""
                )}
                <p className="card-text">
                  {boxDO.quartalTiga.qpercent} % of {boxDO.quartalTiga.qtarget}{" "}
                  Q3 Target
                  {/* {doTarget.slice(0, 3).reduce((a, b) => a + b, 0)} */}
                </p>
                <ProgressBar
                  variant={`${
                    boxDO.quartalTiga.qpercent < 80
                      ? "danger"
                      : boxDO.quartalTiga.qpercent >= 80 &&
                        boxDO.quartalTiga.qpercent <= 100
                      ? "warning"
                      : "success"
                  }`}
                  now={boxDO.quartalTiga.qpercent}
                />
              </>
            ) : (
              "Load..."
            )}
          </div>
        </div>
        <div
          className="card col-12 col-md-2 bg-secondary"
          style={{ textAlign: "center" }}
        >
          <div className="card-body">
            <h6 className="card-title">Quartal 4 DO Ach</h6>
            {boxDO ? (
              <>
                <span
                  style={{ fontSize: "24px" }}
                  className="card-subtitle mb-2 h2"
                >
                  {boxDO.quartalEmpat.qachieve}
                  {/* {doFullYear.slice(0, 3).reduce((a, b) => a + b, 0)} */}
                </span>
                {currMonth > 11 ? (
                  <i className="fa-solid fa-lock fa-9x"></i>
                ) : (
                  ""
                )}
                <p className="card-text">
                  {boxDO.quartalEmpat.qpercent} % of{" "}
                  {boxDO.quartalEmpat.qtarget} Q4 Target
                  {/* {doTarget.slice(0, 3).reduce((a, b) => a + b, 0)} */}
                </p>
                <ProgressBar
                  variant={`${
                    boxDO.quartalEmpat.qpercent < 80
                      ? "danger"
                      : boxDO.quartalEmpat.qpercent >= 80 &&
                        boxDO.quartalEmpat.qpercent <= 100
                      ? "warning"
                      : "success"
                  }`}
                  now={boxDO.quartalEmpat.qpercent}
                />
              </>
            ) : (
              "Load..."
            )}
          </div>
        </div>
        <div
          className="card col-12 col-md-2 bg-secondary"
          style={{ textAlign: "center" }}
        >
          <div className="card-body">
            <h6 className="card-title">Full Year DO Ach</h6>
            {boxDO ? (
              <>
                <span
                  style={{ fontSize: "24px" }}
                  className="card-subtitle mb-2 h2"
                >
                  {boxDO.quartalTotal.qachieve}
                  {/* {doFullYear.slice(0, 3).reduce((a, b) => a + b, 0)} */}
                </span>
                <p className="card-text">
                  {boxDO.quartalTotal.qpercent} % of{" "}
                  {boxDO.quartalTotal.qtarget} FY Target
                  {/* {doTarget.slice(0, 3).reduce((a, b) => a + b, 0)} */}
                </p>
                <ProgressBar
                  variant={`${
                    boxDO.quartalTotal.qpercent < 80
                      ? "danger"
                      : boxDO.quartalTotal.qpercent >= 80 &&
                        boxDO.quartalTotal.qpercent <= 100
                      ? "warning"
                      : "success"
                  }`}
                  now={boxDO.quartalTotal.qpercent}
                />
              </>
            ) : (
              "Load..."
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BoxDo;
