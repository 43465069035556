export class ModelUploadMME {
    constructor(
        data = {
            id_mme: "",
            asm: "",
            sm: "",
            outlet: "",
            month: "",
            type: "",
            varian: "",
            week: "",
            do_total: 0,
            do_target: 0,
            spk_total: 0,
            spk_target: 0,
            new_spk: 0,
            actual_do: 0,
            forecast: 0,
            model: "",
            created_at: "",
            updated_at: "",
            deleted_at: "",
        }
    ) {
        const { asm, sm, month, outlet, type, varian, week, deleted_at, spk_target, spk_total, do_target, actual_do, forecast, model, created_at, updated_at } = data;
        this["asm"] = asm;
        this["sm"] = sm;
        this["month"] = month;
        this["outlet"] = outlet;
        this["type"] = type;
        this["varian"] = varian;
        this["week"] = week;
        this["spk_target"] = spk_target;
        this["do_target"] = do_target;
        this["spk_target"] = spk_target;
        this["spk_total"] = spk_total;
        this["do_target"] = do_target;
        this["actual_do"] = actual_do;
        this["forecast"] = forecast;
        this["model"] = model;
        this["created_at"] = created_at;
        this["updated_at"] = updated_at;
        this["deleted_at"] = deleted_at;
    }

    async getdata(this: any) {
        const { asm, sm, month, outlet, type, varian, week, deleted_at, spk_target, spk_total, do_target, actual_do, forecast, model, created_at, updated_at } = this;
        return [{
            header: "ASM",
            id: "asm",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: asm,
            enableHiding: true,
        },
        {
            header: "SM",
            id: "sm",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: sm,
            enableHiding: true,
        }, {
            header: "Outlet",
            id: "outlet",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: outlet,
            enableHiding: true,
        },
        {
            header: "Month",
            id: "month",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: month,
            enableHiding: true,
        },
        {
            header: "type",
            id: "type",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: type,
            enableHiding: true,
        }, {
            header: "Varian",
            id: "varian",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: varian,
            enableHiding: true,
        }, {
            header: "Week",
            id: "week",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: week,
            enableHiding: true,
        }, {
            header: "SPK Target",
            id: "spk_target",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: spk_target,
            enableHiding: true,
        }, {
            header: "SPK Total",
            id: "spk_total",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: spk_total,
            enableHiding: true,
        }, {
            header: "Target DO",
            id: "do_target",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: do_target,
            enableHiding: true,
        }, {
            header: "Actual DO",
            id: "actual_do",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: actual_do,
            enableHiding: true,
        }, {
            header: "Forecast",
            id: "forecast",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: forecast,
            enableHiding: true,
        }, {
            header: "Model",
            id: "model",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: model,
            enableHiding: true,
        }, {
            header: "created_at",
            id: "created_at",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: created_at,
            enableHiding: true,
        },
        {
            header: "updated_at",
            id: "updated_at",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: updated_at,
            enableHiding: true,
        },
        {
            header: "Deleted At",
            id: "deleted_at",
            muiTableBodyCellProps: ({ cell }) => {
                return ({
                    align: 'center',
                })
            },
            value: deleted_at,
            enableHiding: true,
        },
        ];
    }
}