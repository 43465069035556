import React from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import KumalaRegional from "./KumalaRegional";
import KmgMarketOverview from "./KmgMarketOverview";

function MarketOverview() {

  React.useEffect(() => {
  }, []);
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: "Market Share",
            path: "/sales/market-overview",
            isSeparator: false,
            isActive: false,
          },
        ]}
      >
        {"Market Overview"}
      </PageTitle>
      <div className="row bg-secondary text-center my-10">
        <h2>Kumala Regional M/S Achievement</h2>
      </div>
      <KumalaRegional/>
      <div className="row bg-secondary text-center mt-10">
        <h2>Kumala Market Overview</h2>
      </div>
      <KmgMarketOverview/>
    </>
  );
}

export default MarketOverview;
