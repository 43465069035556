import * as React from 'react';
import { ThemeProvider, makeStyles, createTheme, useTheme, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { TableFooter } from '@mui/material';
import { backgroundColorHSLDarkToLight } from '../../../../../_metronic/helpers/components/MaterialReactTable/ComponentStyleRowsTable';
import { ThemeModeComponent } from '../../../../../_metronic/assets/ts/layout';
import { useThemeMode } from '../../../../../_metronic/partials';

interface Column {
    id: string;
    label: string;
    minWidth?: number;
    align?: "center" | "inherit" | "left" | "right" | "justify" | undefined;
}

type TypeTable = {
    data: {
        columns: string[],
        rows: object,
    }
}

const columns: Column[] = [
    { id: 'outlet', label: 'Name', minWidth: 200 },
    { id: 'spv', label: 'ISO\u00a0Code', minWidth: 200 },
    { id: 'salesman', label: 'ISO\u00a0Code', minWidth: 200 },
    {
        id: 'days1_5_p_t',
        label: 'T',
        minWidth: 10,
        align: 'right',
    },
    // {
    //     id: 'prospect_a',
    //     label: 'T',
    //     minWidth: 10,
    //     align: 'right',
    // },
    // {
    //     id: 'spk_t',
    //     label: 'T',
    //     minWidth: 10,
    //     align: 'right',
    // },
    // {
    //     id: 'spk_a',
    //     label: 'T',
    //     minWidth: 10,
    //     align: 'right',
    // },
    // {
    //     id: 'do_t',
    //     label: 'T',
    //     minWidth: 10,
    //     align: 'right',
    // },
    // {
    //     id: 'do_a',
    //     label: 'T',
    //     minWidth: 10,
    //     align: 'right',
    // },
    //  {
    //     id: 'p_a_1',
    //     label: 'A',
    //     minWidth: 10,
    //     align: 'center',
    // },
    // {
    //     id: 's_t_1',
    //     label: 'T',
    //     minWidth: 10,
    //     align: 'right',
    // }, {
    //     id: 's_a_1',
    //     label: 'A',
    //     minWidth: 10,
    //     align: 'center',
    // },
    // {
    //     id: 'd_t_1',
    //     label: 'T',
    //     minWidth: 10,
    //     align: 'right',
    // }, {
    //     id: 'd_a_1',
    //     label: 'A',
    //     minWidth: 10,
    //     align: 'center',
    // },
];

interface Data {
    outlet: string;
    spv: object;
    salesman: object;
    days1_5_p_t: object;
    // days1_5_p_a: object;
    // days6_10_p_t: object;
    // days6_10_p_a: object;
    // days11_15_p_t: object;
    // days11_15_p_a: object;
    // days16_20_p_t: object;
    // days16_20_p_a: object;
    // days21_25_p_t: object;
    // days21_25_p_a: object;
    // days26_31_p_t: object;
    // days26_31_p_a: object;
}

// function createData(
//     outlet: string,
//     spv: string,
//     salesman: string,
//     p_t_1: number,
//     p_a_1: number,
//     s_t_1: number,
//     s_a_1: number,
//     d_t_1: number,
//     d_a_1: number,
// ): Data {
//     return { outlet, spv, salesman, p_t_1, p_a_1, s_t_1, s_a_1, d_t_1, d_a_1 };
// }

// const rows = [
//     createData('Bandung BKR', 'Unang Supriyatna', 'Dinda Dakosta Pasaribu', 75, 65, 75, 65, 75, 65),
//     createData('China', 'CN', 'Dinda Dakosta Pasaribu', 75, 65, 75, 65, 75, 65),
//     createData('Italy', 'IT', 'Dinda Dakosta Pasaribu', 75, 65, 75, 65, 75, 65),
//     // createData('United States', 'US', 327167434, 9833520),
//     // createData('Canada', 'CA', 37602103, 9984670),
//     // createData('Australia', 'AU', 25475400, 7692024),
//     // createData('Germany', 'DE', 83019200, 357578),
//     // createData('Ireland', 'IE', 4857000, 70273),
//     // createData('Mexico', 'MX', 126577691, 1972550),
//     // createData('Japan', 'JP', 126317000, 377973),
//     // createData('France', 'FR', 67022000, 640679),
//     // createData('United Kingdom', 'GB', 67545757, 242495),
//     // createData('Russia', 'RU', 146793744, 17098246),
//     // createData('Nigeria', 'NG', 200962417, 923768),
//     // createData('Brazil', 'BR', 210147125, 8515767),
//     // createData('Brazil', 'BR', 210147125, 8515767),
//     // createData('Brazil', 'BR', 210147125, 8515767),
//     // createData('Brazil', 'BR', 210147125, 8515767),
//     // createData('Brazil', 'BR', 210147125, 8515767),
//     // createData('Brazil', 'BR', 210147125, 8515767),
//     // createData('Brazil', 'BR', 210147125, 8515767),
//     // createData('Brazil', 'BR', 210147125, 8515767),
//     // createData('Brazil', 'BR', 210147125, 8515767),
//     // createData('Brazil', 'BR', 210147125, 8515767),
//     // createData('Brazil', 'BR', 210147125, 8515767),
//     // createData('Brazil', 'BR', 210147125, 8515767),
//     // createData('Brazil', 'BR', 210147125, 8515767),
//     // createData('Brazil', 'BR', 210147125, 8515767),
// ];

const sample = [
    {
        outlet: "1",
        spv: [{
            value: "1",
            salesman: [{
                value: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7"
                ],
                days1_5_p_t: [0, 8, 4, 5, 6, 2, 8]
            }]
        }, {
            value: "2",
            salesman: [{
                value: [
                    "Gorontalo",
                    "Ringroad",
                    "Malalayang",
                    "Minut"
                ],
                days1_5_p_t: [5, 6, 2, 8]
            }]
        }]
    }, {
        outlet: "TSM - Alauddin",
        spv: [{
            value: "Andriansyah",
            salesman: [{
                value: [
                    "Palu",
                    "Ternate",
                    "Mamuju",
                    "Gorontalo",
                    "Ringroad",
                    "Malalayang",
                    "Minut"
                ],
                days1_5_p_t: [0, 8, 4, 5, 6, 2, 8]
            }]
        }]
    },
    // {
    //     outlet: "Sulbagut & Ternate",
    //     spv: "Sulbagut & Ternate",
    //     salesman: "Sulbagut & Ternate",
    //     rows1: [75, 65, 75, 65, 75, 65],
    // },
];


// const SingleLoopTable: React.FC<TypeTable> = ({
//     data
// }) => {
const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";
export default function DataTable({
    maxHeight,
    data,
    backgroundColor
}) {
    const { mode } = useThemeMode();
    const calculatedMode = mode === "system" ? systemMode : mode;
    const globalTheme = useTheme();
    const tableTheme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: calculatedMode, //let's use the same dark/light mode as the global theme
                    primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
                    info: {
                        main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            calculatedMode === 'light'
                                ? 'rgb(255,255,255)' //random light yellow color for the background in light mode
                                : '#000', //pure black table in dark mode for fun
                    },
                }
            }),
        [calculatedMode, globalTheme.palette.secondary]
    );
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#00897b',
            fontFamily: 'serif',
            fontSize: 9.5,
        }, [`&.${tableCellClasses.body}`]: {
            fontSize: 9.5,
            fontFamily: 'serif',
        },

    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            border: 0,
            fontSize: 9.5,
            fontFamily: 'serif',
            color: calculatedMode === 'light' ? 'black' : 'white',
        },
        // hide last border
        '&:last-child(2) td': {
            backgroundColor: theme.palette.action.hover,
            border: 0,
            fontSize: 9.5,
            fontFamily: 'serif',
            color: calculatedMode === 'light' ? 'black' : 'white',
        },
    }));
    const column_header1 = ['Order', 'Type', 'Varian', 'F', 'A', 'F', 'A', 'F', 'A', 'F', 'A', 'F', 'A', 'F', 'A']
    const column_header2 = ['F', 'A']

    let column_header = [{}];
    for (let i = 1; i <= 5; i++) {
        let obj = {};
        obj['values'] = `W${i}`;
        column_header.push(obj);
    }
    column_header.shift();
    column_header.push({
        values: 'Total Keseluruhan'
    });

    // let headers = [{}];
    // for (let i = 1; i <= column_header.length; i++) {
    //     let obj = {};
    //     obj = `F`;
    //     headers.push(obj);
    //     for (let a = 1; a <= column_header.length; a++) {
    //         obj = `A`;
    //         headers.push(obj);
    //     }
    // }
    // headers.shift();



    return (
        <ThemeProvider theme={tableTheme}>
            <TableContainer sx={{
                maxHeight: maxHeight,
                padding: 0,
                fontFamily: 'serif',
                '& tr > *:first-child': {
                    position: 'sticky',
                    left: 0,
                    top: 32,
                    fontSize: 9.5,
                    color: calculatedMode === 'light' ? 'black' : 'white',
                    bgcolor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                },
                '& tr > *:last-child': {
                    position: 'sticky',
                    right: 0,
                    fontSize: 9.5,
                    fontWeight: "bold",
                    fontFamily: 'serif',
                    color: calculatedMode === 'light' ? 'black' : 'white',
                    bgcolor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                },
            }}>
                <Table size="small" aria-label="table with sticky header">
                    <TableHead >
                        <TableRow>
                            {data['columns'].map((val, i) => {
                                return (
                                    <React.Fragment key={i} >
                                        <StyledTableCell style={{
                                            top: 0,
                                            fontSize: 9.5,
                                            fontFamily: 'serif',
                                            backgroundColor: '#00897b',
                                            color: 'white',
                                            zIndex: 1
                                        }} align='center'>{val}</StyledTableCell>
                                    </React.Fragment>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* START:: LOPING */}
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>Rheza Firmansyah</StyledTableCell>
                            <StyledTableCell align="center">7</StyledTableCell>
                            <StyledTableCell align="center">7</StyledTableCell>
                            <StyledTableCell align="center">6</StyledTableCell>
                            <StyledTableCell align="center">6</StyledTableCell>
                            <StyledTableCell align="center">7</StyledTableCell>
                            <StyledTableCell align="center">3</StyledTableCell>
                            <StyledTableCell align="center">5</StyledTableCell>
                            <StyledTableCell align="center">5</StyledTableCell>
                            <StyledTableCell align="center">48</StyledTableCell>
                            <StyledTableCell align="center">3</StyledTableCell>
                            <StyledTableCell align="center">-</StyledTableCell>
                            <StyledTableCell align="center">-</StyledTableCell>
                            <StyledTableCell align="center">96</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>Mevander Mark Muntu</StyledTableCell>
                            <StyledTableCell align="center">7</StyledTableCell>
                            <StyledTableCell align="center">7</StyledTableCell>
                            <StyledTableCell align="center">6</StyledTableCell>
                            <StyledTableCell align="center">6</StyledTableCell>
                            <StyledTableCell align="center">7</StyledTableCell>
                            <StyledTableCell align="center">3</StyledTableCell>
                            <StyledTableCell align="center">5</StyledTableCell>
                            <StyledTableCell align="center">5</StyledTableCell>
                            <StyledTableCell align="center">48</StyledTableCell>
                            <StyledTableCell align="center">3</StyledTableCell>
                            <StyledTableCell align="center">-</StyledTableCell>
                            <StyledTableCell align="center">-</StyledTableCell>
                            <StyledTableCell align="center">96</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow hover tabIndex={-1}>
                            <StyledTableCell>Aji Juanda</StyledTableCell>
                            <StyledTableCell align="center">7</StyledTableCell>
                            <StyledTableCell align="center">7</StyledTableCell>
                            <StyledTableCell align="center">6</StyledTableCell>
                            <StyledTableCell align="center">6</StyledTableCell>
                            <StyledTableCell align="center">7</StyledTableCell>
                            <StyledTableCell align="center">3</StyledTableCell>
                            <StyledTableCell align="center">5</StyledTableCell>
                            <StyledTableCell align="center">5</StyledTableCell>
                            <StyledTableCell align="center">48</StyledTableCell>
                            <StyledTableCell align="center">3</StyledTableCell>
                            <StyledTableCell align="center">-</StyledTableCell>
                            <StyledTableCell align="center">-</StyledTableCell>
                            <StyledTableCell align="center">96</StyledTableCell>
                        </StyledTableRow>
                        {/* END:: LOPING */}
                    </TableBody>
                    <TableFooter sx={{
                        position: "sticky",
                        bottom: 0,
                        fontFamily: 'serif',
                        fontSize: 9.5,
                        backgroundColor: calculatedMode === 'light' ? '#fbfcfe' : '#1c1c1c',
                        color: calculatedMode === 'light' ? 'black' : 'white',
                    }}>
                        <TableRow hover tabIndex={-1}>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }}>Total Keseluruhan</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">1.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">2.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">3.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell style={{ fontWeight: "bold", fontFamily: 'serif' }} align="center">4.000</StyledTableCell>
                            <StyledTableCell align="center">100</StyledTableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </ThemeProvider >
    );
}
