/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import { Component } from "react";

import * as Yup from "yup";

import { Formik } from "formik";

import { KTCard, KTCardBody, KTIcon } from "../../../../_metronic/helpers";
import DataTableMuiModel from "../../../models/DataTable/dataTableModal";
import BrandModel from "../../../models/Master/Brand//brandModel";
import { PageTitle } from "../../../../_metronic/layout/core";
import { MaterialReactTableSimple } from "../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable";
import { ButtonAction } from "../../../../_metronic/helpers/components/form-actions/FormButton";
import { InputImages, InputSelect2, InputText } from "../../../../_metronic/helpers/components/form-actions/FormInput";
import { FormModal } from "../../../../_metronic/helpers/components/form-actions/ModalForm";
import SimpleAlert from "../../../../_metronic/helpers/components/alert/SimpleAlert";
import { MasterAPI } from "../../../api/Master";

function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 1000));
}

export class Brand extends Component {
  constructor(props) {
    super(props);
    this["title"] = "Brand";
    this["api"] = new MasterAPI();
    this["initialData"] = new BrandModel();
    this["option_brand"] = [];
    this["datatable"] = [];
    this["columntable"] = [];
    this["state"] = {
      isLoading: true,
      modal: {
        loading: false,
        show: false,
        initialData: this["initialData"],
        isEdit: false,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
  }
  //** Start: Component */
  async componentDidMount() {
    await this.LoadData();
  }
  //** End: Component */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Data List */
  async LoadData() {
    this.setState({ loading: true });
    let data = await this["api"].getDataBrand();
    this["DataTableMuiModel"] = new DataTableMuiModel(this.props);
    data["data"].map((item: any, i: number) => {
      let dataModel = new BrandModel(item);
      if (item["status"] === 1) {
        this["DataTableMuiModel"].add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              <div className="d-flex justify-content-center flex-shrink-0">
                <ButtonAction
                  title={"Edit"}
                  iconName="pencil"
                  onClick={() => this["handleModal"].edit(dataModel)}
                />
                <ButtonAction title={"Delete"} iconName="trash"
                  onClick={() => {
                    this['handleAlert'].show({ id: item['id'] });
                  }} />
              </div>
            </>
          ),
        });
      }
      return item;
    });
    this["datatable"] = await this["DataTableMuiModel"].getDatas();
    this["columntable"] = await this["DataTableMuiModel"].getColumn();
    this.setState({ ...this["state"], loading: false });
  }
  //** End: Load Data List */

  //** Begin:: Handle Modal && Alert */
  handleModal = {
    loadOptionBrand: async (val: string) => {
      this.setState({ isLoading: true }, () => {
        simulateNetworkRequest().then(() => {
          this["api"].getSelectBrand(val).then((res: any) => {
            this["option_brand"] = res["data"].map((val: string) => {
              return {
                value: val["id"],
                label: val["text"],
                nama_brand: val["nama_brand"],
              };
            });
            this.setState({ isLoading: false });
          });
        });
      });
    },
    tambah: async () => {
      let state = { ...this["state"] };
      state = {
        modal: {
          show: true,
          title: `Tambah ${this["title"]}`,
          isEdit: false,
          initialData: this["initialData"],
        },
      };
      this.setState({ ...state });
      this["handleModal"].loadOptionBrand("add");
    },
    edit: async (dataFromModel: any) => {
      let state = { ...this["state"] };
      state = {
        modal: {
          show: true,
          title: `Edit ${this["title"]}`,
          isEdit: true,
          initialData: dataFromModel,
        },
      };
      if (dataFromModel['id_parent'] !== null) {
        this.setState({ disabledIcon: false });
      } else {
        this.setState({ disabledIcon: true });
      }
      this.setState({ ...state });
      this["handleModal"].loadOptionBrand("edit");
    },
    hide: () => {
      let state = { ...this["state"] };
      state = {
        modal: {
          show: false,
          initialData: this["initialData"],
        },
      };
      this.setState({ ...state });
    }
  };

  handleAlert = {
    show: (id) => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: true,
          activeId: id
        }
      }
      this.setState({ ...alert });
    },
    hide: () => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: false,
          activeId: null
        }
      }
      this.setState({ ...alert });
    },
    confirmed: async () => {
      await this["api"].deleteBrand(this['state']['alert']['activeId']);
      this['handleAlert'].hide();
      setTimeout(() => {
        this.LoadData();
      }, 2000);
    },
  };
  //** End:: Handle Modal && Alert */
  render() {
    return (
      <>
        <PageTitle breadcrumbs={[{ title: 'Master', path: '/brand', isSeparator: false, isActive: false }]}>{this["title"]}</PageTitle>
        {/* Begin:: Card */}
        <KTCard>
          <div className="card-header border-0 pt-6">
            {/* Card Title */}
            <div className="card-title"></div>
            {/* Begin:: Card Toolbar */}
            <div className="card-toolbar">
              <div
                className="d-flex justify-content-end"
                data-kt-user-table-toolbar="base"
              >
                <button
                  type={"button"}
                  className={"btn btn-sm btn-light-primary"}
                  onClick={() => {
                    this["handleModal"].tambah();
                  }}
                >
                  <KTIcon iconName={"plus"} className={"fs-4 me-2"} /> Tambah
                </button>
              </div>
            </div>
            {/* End:: Card Toolbar */}
          </div>
          {/* Begin:: Card Body */}
          <KTCardBody className="py-4">
            <div className="table-responsive">
              <MaterialReactTableSimple
                data={this["datatable"]}
                columns={this["columntable"]}
                isLoading={this["state"]["loading"]}
              />
              {/* <MUIDataTableStandar data={datas} columns={columns} /> */}
            </div>
          </KTCardBody>
          {/* End:: Card Body */}
        </KTCard>
        {/* End:: Card */}

        {/* Begin:: Modal */}
        <Formik
          enableReinitialize={true}
          initialValues={this["state"]["modal"]["initialData"]}
          validationSchema={Yup.object().shape({
            id_brand: Yup.string().required("Brand is required"),
            logos: Yup.string().required("Logo is required"),
          })}
          onSubmit={async (values, { resetForm }) => {
            const data = {
              id_brand: values["id_brand"],
              nama: values["nama"],
              logos: values["logos"],
              background: values["background"],
            };
            if (this["state"]["modal"]['isEdit']) {
              // Update Data
              const dataSend = { id: values["id"], ...data, }
              await this["api"].updateBrand(dataSend);
            } else {
              await this["api"].setBrand(data);
            }
            this['handleModal'].hide();
            resetForm();
            setTimeout(() => {
              this.LoadData();
            }, 2000);
          }}
        >
          {(formik) => (
            <FormModal
              title={this["state"]["modal"]["title"]}
              show={this["state"]["modal"]["show"]}
              onHide={this["handleModal"]["hide"]}
              onConfirm={formik.handleSubmit}
              size={"lg"}
              content={
                <>
                  <div className="row mb-1">
                    <InputSelect2
                      name="id_brand"
                      label={"Brand"}
                      required
                      options={this["option_brand"]}
                      isClearable
                      isSearchable
                      formik={formik}
                      onChange={(e: number) => {
                        const nama_brand = e?.["nama_brand"];
                        if (nama_brand) {
                          formik.setFieldValue("nama", nama_brand);
                        } else {
                          formik.setFieldValue("nama", "");
                        }
                      }}
                    />
                    <InputText
                      name="nama"
                      label={"Nama Brand"}
                      disabled
                      formik={formik}
                    />
                    <InputImages
                      name="logos"
                      label={"Logo"}
                      horizontalRows
                      sizeBold
                      formik={formik}
                    />
                    <InputImages
                      name="background"
                      label={"Dashboard Background"}
                      horizontalRows
                      sizeBold
                      formik={formik}
                    />
                  </div>
                </>
              }
            />
          )}
        </Formik>
        {/* End:: Modal */}
        {/* Alert Hapus */}
        <SimpleAlert
          title={"Hapus Data"}
          content={"Anda yakin hapus data ini...?"}
          show={this['state']['alert']['show']}
          onHide={() => this['handleAlert'].hide()}
          onConfirmed={this['handleAlert']['confirmed']}
        />
      </>
    );
  }
}

export default Brand;
