import React from "react";
import { ChartMarketGrowth, ChartOverviewMS } from "../OverviewHelper";
import { OverviewAPI } from "../../../../api/MarketShareAPI";
import FilterMultiSelect from "../../../../../_metronic/helpers/components/form-actions/FilteringInput";
import { equalsCheck } from "../../../../../_metronic/helpers/helpers-data/AssetHelpers";
import GetDataFilter from "../../../../hooks/filter";
import SegmentFilter from "../../../../hooks/segment";

const KmgMarketOverview = () => {
  const [asmVal, setasmVal] = React.useState([]);
  const [smVal, setsmVal] = React.useState([]);
  const [arrSelect, setArrSelect] = React.useState([]);
  const [smSelect, setSmSelect] = React.useState([]);
  const [segmentSlc, setSegmentSlc] = React.useState([]);
  const [slcSegment, setSlcSegment] = React.useState([]);
  const [chartMSAll, setChartMSAll] = React.useState([]);
  const [chartMSWuling, setChartMSWuling] = React.useState([]);
  const [chartAllGrowth, setChartAllGrowth] = React.useState([]);
  const [chartWulingGrowth, setChartWulingGrowth] = React.useState([]);
  const filterData = GetDataFilter({asmVal:asmVal, smVal:smVal});
  const segmentOpt = SegmentFilter();

  async function chartDt(cov, segment) {
    new OverviewAPI().chartMSAll(cov, segment).then((res) => {
      res.data.map((val) => {
        return setChartMSAll(val);
      });
    });

    new OverviewAPI().chartMSWuling(cov, segment).then((res) => {
      res.data.map((val) => {
        return setChartMSWuling(val);
      });
    });

    new OverviewAPI().chartMarketGrowthAll(cov, segment).then((res) => {
      return setChartAllGrowth(res.data);
    });

    new OverviewAPI().chartMarketGrowthWuling(cov, segment).then((res) => {
      return setChartWulingGrowth(res.data);
    });
  }
  React.useEffect(() => {
    chartDt(filterData.coverage, slcSegment);
    return () => {};
  }, [filterData.coverage,slcSegment]);
  return (
    <>
      <div className="row d-flex justify-content-center my-15 g-5 absolute">
        <div className="col-xxl-3 mb-5">
          <FilterMultiSelect
            options={filterData.asmOpt}
            label="ASM"
            isSelectAll={true}
            value={arrSelect}
            onMenuClose={() => {
              let toFilter = arrSelect?.map((val) => val.value);

              return equalsCheck(toFilter, asmVal) ? null : setasmVal(toFilter);
            }}
            onChange={(selected) => {
              setArrSelect(selected);
            }}
          />
        </div>
        <div className="col-xxl-3 mb-5" style={{ zIndex: 4 }}>
          <FilterMultiSelect
            options={filterData.smOpt}
            label="SM"
            isSelectAll={true}
            value={smSelect}
            onMenuClose={() => {
              let toFilter = smSelect?.map((val) => val.value);
              return equalsCheck(toFilter, smVal) ? null : setsmVal(toFilter);
            }}
            onChange={(selected) => {
              console.log("selected", selected);
              setSmSelect(selected);
            }}
          />
        </div>
        <div className="col-xxl-3 mb-5" style={{ zIndex: 4 }}>
          <FilterMultiSelect
            options={segmentOpt}
            label="Segment"
            isSelectAll={true}
            value={segmentSlc}
            onMenuClose={() => {
              let toFilter = segmentSlc?.map((val) => val.value);
              return equalsCheck(toFilter, slcSegment)
                ? null
                : setSlcSegment(toFilter);
            }}
            onChange={(selected) => {
              setSegmentSlc(selected);
            }}
          />
        </div>
      </div>
      <div>
        <ChartOverviewMS title={"M/S All Industry"} dt={chartMSAll} />
        <ChartOverviewMS title={"M/S Wuling Segment"} dt={chartMSWuling} />
        <ChartMarketGrowth
          title={"All Industry Market Growth"}
          col={"#00897b"}
          dt={chartAllGrowth}
        />
        <ChartMarketGrowth
          title={"Wuling Segment Market Growth"}
          col={"#287f9d"}
          dt={chartWulingGrowth}
        />
      </div>
    </>
  );
};

export default KmgMarketOverview;
