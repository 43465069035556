import { fetchingGetData } from "./API";

export class TargetConversionAPI {
  //! Get Table Data DO, SPK, TestDrive dan Leads
  async getDataTableTargetConversion(values: any) {
    let res = await fetchingGetData({
      url: `target_conversion/target/do_spk_testdrive_leads`,
      method: "get",
    });
    return res;
  }
  //! Get Chart Data Target V Actual SPK
  async getChartTargetVActualSPK(values: any) {
    let res = await fetchingGetData({
      url: `target_conversion/target/spk/chart`,
      method: "get",
    });
    return res;
  }
  //! Get Chart Data Target V Actual DO
  async getChartTargetVActualDO(values: any) {
    let res = await fetchingGetData({
      url: `target_conversion/target/do/chart`,
      method: "get",
    });
    return res;
  }
  //! Get Chart Data Target V Actual RS
  async getChartTargetVActualRS(values: any) {
    let res = await fetchingGetData({
      url: `target_conversion/target/rs/chart`,
      method: "get",
    });
    return res;
  }
  // Source Leads Bar & Donut Leads
  //! Get Chart Source Leads Bar & Donut Leads
  async getChartBarSourceLeads(values: any) {
    let res = await fetchingGetData({
      url: `target_conversion/leads/chart`,
      method: "get",
    });
    return res;
  }
  // Source SPK Bar & Donut Spk
  //! Get Chart Source SPK Bar & Donut Spk
  async getChartBarSourceSpk(values: any) {
    let res = await fetchingGetData({
      url: `target_conversion/spk/chart`,
      method: "get",
    });
    return res;
  }
  // Conversion Leads to SPK (w/o Test Drive)
  //! Get Table Conversion Leads to SPK (Test Drive)
  async getTableLeadsToSpkNoTestDrive(values: any) {
    let res = await fetchingGetData({
      url: `target_conversion/spk_leads/test_drive_n`,
      method: "get",
    });
    return res;
  }
  // Conversion Leads to SPK (Test Drive)
  //! Get Table Conversion Leads to SPK (w/o Test Drive)
  async getTableLeadsToSpkYesTestDrive(values: any) {
    let res = await fetchingGetData({
      url: `target_conversion/spk_leads/test_drive_y`,
      method: "get",
    });
    return res;
  }

  //? TEST DRIVE ⓥ ACHIEVEMENT
  //! Get Table Test Drive Achievement by Days
  async getTestDriveAchByDay(values: any) {
    let res = await fetchingGetData({
      url: `target_conversion/test_drive/achv/days/table`,
      method: "get",
    });
    return res;
  }

   //! Get Table Test Drive Per Model by Days
   async getTestDriveModelByDay(values: any) {
    let res = await fetchingGetData({
      url: `target_conversion/test_drive/model/days/table`,
      method: "get",
    });
    return res;
  }

   //! Get Table W/O Test Drive Leads Conversion by Model
   async getWOTestDriveLeadsConversionModel(values: any) {
    let res = await fetchingGetData({
      url: `target_conversion/test_drive/wo/model/table`,
      method: "get",
    });
    return res;
  }
   //! Get Table Test Drive Leads Conversion by Model
   async getTestDriveLeadsConversionModel(values: any) {
    let res = await fetchingGetData({
      url: `target_conversion/test_drive/wi/model/table`,
      method: "get",
    });
    return res;
  }

   //! Get Table Test Drive Leads Conversion by Model
   async getTableTargetSpesificByManpower(values: any) {
    let res = await fetchingGetData({
      url: `target_conversion/target/manpower/table`,
      method: "get",
    });
    return res;
  }

  //**Begin:: Get Select Data */
  //! Get Filter ASM, SM and Outlet
  async getFilter(values: any) {
    let res = await fetchingGetData({
      url: `select/mtd/performance?asm=${values['asm'] || ""}&sm=${values['sm'] || ""}&outlet=${values['outlet'] || ""}`,
      method: "get",
    });
    return res;
  }
  // Get Model
  async getModel() {
    let res = await fetchingGetData({
      url: `select/spk_do_rs/model`,
      method: "get",
    });
    return res;
  }
  //**End:: Get Select Data */
}
