/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { PriceSegmentAPI } from "../../../../api/MarketShareAPI";
import { PrDetails } from "../PriceSegmentHelper";

const PriceByDetails = ({ val }) => {
  const [loading, setLoading] = useState(true);
  const [priceRangeDetails, setPriceRangeDetails] = useState([]);
  function getData() {
    setLoading(true);
      new PriceSegmentAPI().priceRangeDetails(val).then((res) => {
        setPriceRangeDetails(res.response);
      }).then(() => setLoading(false));
  }
  useEffect(() => {
    const controller = new AbortController();
    getData();
    return () => controller?.abort();
  }, [val]);

  return (
    <>
      <PrDetails dt={priceRangeDetails} loadState={loading}/>
    </>
  );
};

export default PriceByDetails;
