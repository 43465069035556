import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

type Column = {
    id: 'kabupaten'|'segment' | 'displayed'| 'rank' | 'market' | 'vol' | 'ms' | 'activity' | 'prospek' | 'testdrive' | 'spk';
    label: string;
    minWidth?: number;
    align?: 'right' | 'center';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'kabupaten', label: 'Kabupaten', minWidth: 80 },
    { id: 'segment', label: 'Segment', minWidth: 50 },
    { id: 'displayed', label: 'Displayed', minWidth: 100 },
    { id: 'rank', label: 'Segment Rank In Kecamatan', minWidth: 40 },
    {
        id: 'market',
        label: 'Market',
        minWidth: 50,
        format: (value: number) => value.toFixed(0),
    },
    {
        id: 'vol',
        label: 'Vol',
        minWidth: 50,
        align: 'center',
        format: (value: number) => value.toFixed(0),
    },
    {
        id: 'ms',
        label: 'M/S',
        minWidth: 50,
        align: 'right',
        format: (value: number) => `${value.toFixed(1)} %`,
    },
    {
        id: 'activity',
        label: 'Number of Activity',
        minWidth: 50,
        align: 'center',
        format: (value: number) => value.toFixed(0),
    },
    {
        id: 'prospek',
        label: 'Prospect',
        minWidth: 50,
        align: 'right',
        format: (value: number) => value.toFixed(0),
    },
    {
        id: 'testdrive',
        label: 'Test Drive',
        minWidth: 50,
        align: 'center',
        format: (value: number) => value.toFixed(0),
    },
    {
        id: 'spk',
        label: 'SPK',
        minWidth: 50,
        align: 'center',
        format: (value: number) => value.toFixed(0),
    },
];

type dtComp = {
    kabupaten: string;
    brand: string;
    model: string;
    segment: string;
    market: number;
    vol: number;
}

type datas = {
    data: Array<dtComp>
}

function StickyHeadTable({data}: datas) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    React.useEffect(() => {
        console.log(data);
    }, [data])

    return (
        <>
            <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
                {/* begin::Title */}
                <h3 className="align-items-center flex-column">
                    <span className="card-label fw-bold fs-1 mb-1">M/S Kecamatan</span>
                </h3>
            </div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, color: 'white' ,backgroundColor: '#00897b' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row,i) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                            {columns.map((column) => {
                                                const value = row[column.id] ? row[column.id] : '-';
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[100, 250, 1000]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

export { StickyHeadTable }