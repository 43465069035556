/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { KTCard, KTCardBody } from "../../../../../_metronic/helpers";
import { Form, Formik } from "formik";
import { NationalInformationAPI } from "../../../../api/NationalInformationAPI";
import FilterMultiSelect, { Option } from "../../../../../_metronic/helpers/components/form-actions/FilteringInput";
import DataTableMuiModel from "../../../../models/DataTable/dataTableModal";
import { MaterialReactTableDetails } from "../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable";
import { HighchartsSample } from "../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample";
import { GaikindoRSModel, GaikindoWSModel } from "../../../../models/sales/NationalInformationModel";
import DataTable from "./dataTable";
export class WsSuggestionIndex extends Component {
    constructor(props: any) {
        super(props);
        this["title"] = "WS Suggestion";
        this["api"] = new NationalInformationAPI();
        this["month"] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        this["asm"] = ['Java', 'Sulbagsel - Bali', 'Sulbagut - Ternate'];
        this["state"] = {
            loading: true,
            initialValues: {
                month: [],
                brand: [],
            }, initialValuesChartColumnLine: {
                retail_sales: {
                    series: [],
                    categories: props["month"],
                    data: [],
                }, wholesales: {
                    series: [],
                    categories: props["month"],
                    data: [],
                },
            }, initialValuesChartPie: {
                retail_sales: {
                    series_old: [],
                    series_now: []
                }, wholesales: {
                    series_old: [],
                    series_now: []
                },
            }
        };
        this['option_asm'] = [];
        this['option_province'] = [];
        this['option_outlet'] = [];
        this["datatable_rs"] = [];
        this["columntable_rs"] = [];
        this["datatable_ws"] = [];
        this["columntable_ws"] = [];
    }
    //* ----------------------------------------------------------------------------- */
    // BEGIN:: componentDidMount */
    async componentDidMount() {
        //? Load Filter Options Month
        await this['api'].getMonth().then(async (res: any) => {
            this['option_month'] = res['data'].map((val: any) => {
                return {
                    value: val['id'],
                    label: val['text'],
                    year: val['year']
                };
            });
        });
        //? Load Filter Options Dealer Group
        await this['api'].getBrand().then(async (res: any) => {
            this['option_brand'] = res['data'].map((val: any) => {
                return {
                    value: val['id'],
                    label: val['text'],
                };
            });

        });
        //! Load Chart 1
        await this.LoadChart1(this['state']['initialValues']);
        //! Load Chart 2
    }
    //** End: Component */
    //* ----------------------------------------------------------------------------- */
    //! Function Load Chart 1
    async LoadChart1(initial: []) {
        const { data: dataResultColumnLine } = await this["api"].getDataChartGaikindo({ ...initial, tipe: "ChartLineBar" });
        const {
            dataYearOld: dataRSYearOld,
            dataYearNow: dataRSYearNow,
            growth: growthRS,
            sumGrowthYTD: sumRSGrowthYTD,
            sumDataYTDNow: sumDataRSYTDNow,
            sumDataYTDold: sumDataRSYTDold,
        } = dataResultColumnLine["RSChartLine"][0];
        const {
            dataYearOld: dataWSYearOld,
            dataYearNow: dataWSYearNow,
            growth: growthWS,
            sumGrowthYTD: sumWSGrowthYTD,
            sumDataYTDNow: sumDataWSYTDNow,
            sumDataYTDold: sumDataWSYTDold,
        } = dataResultColumnLine["WSChartLine"][0];
        this["setState"]({
            ...this["state"],
            initialValuesChartColumnLine: {
                retail_sales: {
                    series: [
                        {
                            name: "Growth",
                            type: "line",
                            data: growthRS,
                            tooltip: {
                                valueSuffix: " %",
                            },
                        },
                    ],
                    categories: this["month"],
                }, wholesales: {
                    series: [{
                        name: `${this["option_month"][0]?.["year"] - 1} RS`,
                        type: "column",
                        yAxis: 1,
                        data: dataWSYearOld,
                    }, {
                        name: `${this["option_month"][0]?.["year"]} RS`,
                        type: "column",
                        yAxis: 1,
                        data: dataWSYearNow,
                    }, {
                        name: "Growth",
                        type: "line",
                        data: growthWS,
                        tooltip: {
                            valueSuffix: " %",
                        }
                    }],
                    categories: this["month"],
                    data: [{
                        name: "YTD Growth",
                        value: sumWSGrowthYTD,
                    }, {
                        name: `${this["option_month"][0]?.["year"]} YTD RS`,
                        value: sumDataWSYTDNow,
                    }, {
                        name: `${this["option_month"][0]?.["year"] - 1} YTD RS`,
                        value: sumDataWSYTDold,
                    }],
                }
            }
        });
    }
    //** End: Load Data List */
    render(this: any) {
        const { initialValues, initialValuesChartColumnLine, initialValuesChartPie } = this["state"];
        const { retail_sales: retail_sales_column_line, wholesales: wholesales_column_line } = initialValuesChartColumnLine;
        const { retail_sales: retail_sales_pie, wholesales: wholesales_pie } = initialValuesChartPie;
        return (
            <React.Fragment>
                <PageTitle breadcrumbs={[{ title: "Stock", path: "/sales/ws-suggestion", isSeparator: false, isActive: false }]}>
                    {this["title"]}
                </PageTitle>
                {/* BEGIN:: Filter Input */}
                <div className="row g-5">
                    <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={() => { }}>
                        {({ setFieldValue, values }) => (
                            <Form>
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-lg-3 mb-5">
                                        <FilterMultiSelect
                                            label={"ASM"}
                                            name="asm"
                                            options={this['option_asm']}
                                            onChange={(selected: Option[]) => {
                                                // setFieldValue("month", selected);
                                                // const arrayValueMonth = selected.map((x: Object) => x["value"]);
                                                // const arrayValueDealerGroup = values["brand"].map((x: Object) => x["value"]);
                                                // setTimeout(() => {
                                                //     this.LoadChart2({
                                                //         month: arrayValueMonth,
                                                //         brand: arrayValueDealerGroup,
                                                //     });
                                                // }, 500);
                                            }}
                                            value={values["asm"]}
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-5">
                                        <FilterMultiSelect
                                            label={"Province"}
                                            name="province"
                                            options={this['option_province']}
                                            onChange={(selected: Option[]) => {
                                                // setFieldValue("month", selected);
                                                // const arrayValueMonth = selected.map((x: Object) => x["value"]);
                                                // const arrayValueDealerGroup = values["brand"].map((x: Object) => x["value"]);
                                                // setTimeout(() => {
                                                //     this.LoadChart2({
                                                //         month: arrayValueMonth,
                                                //         brand: arrayValueDealerGroup,
                                                //     });
                                                // }, 500);
                                            }}
                                            value={values["province"]}
                                        />
                                    </div>
                                    <div className="col-lg-3 mb-5">
                                        <FilterMultiSelect
                                            label={"Outlet"}
                                            name="outlet"
                                            options={this['option_outlet']}
                                            onChange={(selected: Option[]) => {
                                                // setFieldValue("month", selected);
                                                // const arrayValueMonth = values["month"].map((x: object) => x["value"]);
                                                // const arrayValueDealerGroup = selected.map((x: object) => x["value"]);
                                                // setTimeout(() => {
                                                //     this.LoadChart1({
                                                //         month: arrayValueMonth,
                                                //         month: arrayValueDealerGroup,
                                                //     });
                                                // }, 500);
                                            }}
                                            value={values["outlet"]}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                {/* END:: Filter Input */}
                {/* ----------------------------------------------------------------------------- */}
                <div className="row g-5">
                    <div className='d-xxl-flex justify-content-between'>
                        <div className='border border-gray-300 rounded mh-150px mb-5 bg-white'>
                            <div className='d-flex align-items-center py-3 px-3 mx-3'>
                                <div className='symbol symbol-90px mt-5'>
                                    <img src="/media/images/widget1.png" alt="" />
                                </div>
                                <div className='d-flex justify-content-end flex-column w-100'>
                                    <div className='fw-bold text-wrap fs-5 text-end w-100px text-gray-800 mb-5'>Running Rate</div>
                                    <div className='text-end fs-2x fw-bolder text-gray-700 my-2'>317</div>
                                </div>
                            </div>
                        </div>
                        <div className='border border-gray-300 rounded mh-150px mb-5 bg-white'>
                            <div className='d-flex align-items-center py-3 px-3 mx-3'>
                                <div className='symbol symbol-90px mt-5'>
                                    <img src="/media/images/widget2.png" alt="" />
                                </div>
                                <div className='d-flex justify-content-end flex-column w-100'>
                                    <div className='fw-bold text-wrap fs-3 text-end w-100px text-gray-800 mb-5'>Stock</div>
                                    <div className='text-end fs-2x fw-bolder text-gray-700 my-2'>1.006</div>
                                </div>
                            </div>
                        </div>
                        <div className='border border-gray-300 rounded mh-150px mb-5 bg-white'>
                            <div className='d-flex align-items-center py-3 px-3 mx-3'>
                                <div className='symbol symbol-90px mt-5'>
                                    <img src="/media/images/widget3.png" alt="" />
                                </div>
                                <div className='d-flex justify-content-end flex-column w-100'>
                                    <div className='fw-bold text-wrap fs-7 text-end w-100px text-gray-800 mb-5'>WS Suggestion by History</div>
                                    <div className='text-end fs-2x fw-bolder text-gray-700 my-2'>250</div>
                                </div>
                            </div>
                        </div>
                        <div className='border border-gray-300 rounded mh-150px mb-5 bg-white'>
                            <div className='d-flex align-items-center py-3 px-3 mx-3'>
                                <div className='symbol symbol-90px'>
                                    <img src="/media/images/widget4.png" alt="" />
                                </div>
                                <div className='d-flex flex-end flex-column w-100'>
                                    <div className='fw-bold text-wrap fs-7 text-end w-100px text-gray-800 mb-5'>WS Suggestion by Allocation SGMW</div>
                                    <div className='text-end fs-1 fw-bolder text-gray-700 my-2'>304</div>
                                </div>
                            </div>
                        </div>
                        <div className='border border-gray-300 rounded mh-150px mb-5 bg-white'>
                            <div className='d-flex align-items-center py-3 px-3 mx-3'>
                                <div className='symbol symbol-90px'>
                                    <img src="/media/images/widget5.png" alt="" />
                                </div>
                                <div className='d-flex flex-end flex-column w-100'>
                                    <div className='fw-bold text-wrap fs-5 text-end w-100px text-gray-800'>Stock Month</div>
                                    <div className='fw-bold text-wrap fs-7 text-end text-gray-400'>by History</div>
                                    <div className='text-end fs-2 fw-bolder text-gray-700'>4,0</div>
                                    <div className='fw-bold text-wrap fs-7 text-end text-gray-400'>by Allocation</div>
                                    <div className='text-end fs-2 fw-bolder text-gray-700'>4,1</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ----------------------------------------------------------------------------- */}
                <div className="row g-5">
                    <div className="col-xxl-6 bd-highlight">
                        <KTCard className='h-700px mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">Suggestion WS by History</span>
                                </h3>
                            </div>
                            <KTCardBody className="p-3">
                                <DataTable
                                    maxHeight={"600px"}
                                    data={{
                                        columns: [
                                            { id: 'model', label: 'Model', minWidth: 200, align: 'left' },
                                            { id: 'varian', label: 'Sales Name', minWidth: 200, align: 'left' },
                                            { id: 'color', label: 'Customer Name', minWidth: 200, align: 'left' },
                                            { id: 'running_rate', label: 'Type Customer', minWidth: 200, align: 'center' },
                                            { id: 'stock', label: 'Type Customer', minWidth: 200, align: 'center' },
                                            { id: 'ws_suggestion', label: 'Type Customer', minWidth: 200, align: 'center' },
                                            { id: 'stock_month', label: 'Type Customer', minWidth: 200, align: 'center' },
                                        ],
                                        rows: [{
                                            model: 'FORMO S',
                                            varian: 'FORMO 1.2 MB 5F M/T',
                                            color: 'Starry Black',
                                            running_rate: 0,
                                            stock: 0,
                                            ws_suggestion: 0,
                                            stock_month: 'null',
                                        }, {
                                            model: 'FORMO S',
                                            varian: 'FORMO 1.2 MB 5F M/T',
                                            color: 'Carnelian Red',
                                            running_rate: 0,
                                            stock: 0,
                                            ws_suggestion: 0,
                                            stock_month: 'null',
                                        }],
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                    <div className="col-xxl-6 bd-highlight">
                        <KTCard className='h-700px mb-5'>
                            <div className="card-header justify-content-center">
                                <h3 className="card-title align-items-center flex-column">
                                    <span className="card-label fw-bold fs-1">Suggestion WS by Allocation SGMW</span>
                                </h3>
                            </div>
                            <KTCardBody className="p-3">
                                <DataTable
                                    maxHeight={"600px"}
                                    data={{
                                        columns: [
                                            { id: 'model', label: 'Model', minWidth: 200, align: 'left' },
                                            { id: 'varian', label: 'Sales Name', minWidth: 200, align: 'left' },
                                            { id: 'color', label: 'Customer Name', minWidth: 200, align: 'left' },
                                            { id: 'running_rate', label: 'Type Customer', minWidth: 200, align: 'center' },
                                            { id: 'stock', label: 'Type Customer', minWidth: 200, align: 'center' },
                                            { id: 'ws_suggestion', label: 'Type Customer', minWidth: 200, align: 'center' },
                                            { id: 'stock_month', label: 'Type Customer', minWidth: 200, align: 'center' },
                                        ],
                                        rows: [{
                                            model: 'FORMO S',
                                            varian: 'FORMO 1.2 MB 5F M/T',
                                            color: 'Starry Black',
                                            running_rate: 0,
                                            stock: 0,
                                            ws_suggestion: 0,
                                            stock_month: 'null',
                                        }, {
                                            model: 'FORMO S',
                                            varian: 'FORMO 1.2 MB 5F M/T',
                                            color: 'Carnelian Red',
                                            running_rate: 0,
                                            stock: 0,
                                            ws_suggestion: 0,
                                            stock_month: 'null',
                                        }],
                                    }}
                                />
                            </KTCardBody>
                        </KTCard>
                    </div>
                </div>
                {/* ----------------------------------------------------------------------------- */}
            </React.Fragment>
        );
    }
}
export default WsSuggestionIndex;
