import { fetchingGetData } from "./API";

export class InternalInformationAPI {

  //! Get Chart Column Line Data SPK
  async getDataChartColumnLineSPK(values: any) {
    let res = await fetchingGetData({
      url: `growth/spk/chart?coverage=${values['coverage']}&model=${values['model']}&month=${values['month']}`,
      method: "get",
    });
    return res;
  }
  //! Get Chart Column Line Data DO
  async getDataChartColumnLineDO(values: any) {
    let res = await fetchingGetData({
      url: `growth/do/chart?coverage=${values['coverage']}&model=${values['model']}&month=${values['month']}`,
      method: "get",
    });
    return res;
  }
  //! Get Chart Column Line Data Retail Sales
  async getDataChartColumnLineRS(values: any) {
    let res = await fetchingGetData({
      url: `retail_sales/internal/chart?coverage=${values['coverage']}&model=${values['model']}&month=${values['month']}`,
      method: "get",
    });
    return res;
  }
  //! Get Chart Column Line Data Market Share
  async getDataChartColumnLineMS(values: any) {
    let res = await fetchingGetData({
      url: `market_share/internal/chart?coverage=${values['coverage']}&model=${values['model']}&month=${values['month']}`,
      method: "get",
    });
    return res;
  }

  //**Begin:: Table API */
  //! Get BOX API
  async getDataDashboardBox(values: any) {
    let res = await fetchingGetData({
      url: `growth/sales/box?coverage=${values['coverage']}&model=${values['model']}&month=${values['month']}`,
      method: "get",
    });
    return res;
  }

  //! Get Table SPK
  async getDataChartTableYTDSPK(values: any) {
    let res = await fetchingGetData({
      url: `growth/spk/model/chart_table?coverage=${values['coverage']}&model=${values['model']}&month=${values['month']}`,
      method: "get",
    });
    return res;
  }
  //! Get Table DO
  async getDataChartTableYTDDO(values: any) {
    let res = await fetchingGetData({
      url: `growth/do/model/chart_table?coverage=${values['coverage']}&model=${values['model']}&month=${values['month']}`,
      method: "get",
    });
    return res;
  }
  //! Get Table RS
  async getDataChartTableYTDRS(values: any) {
    let res = await fetchingGetData({
      url: `retail_sales/internal/chart_table?coverage=${values['coverage']}&model=${values['model']}&month=${values['month']}`,
      method: "get",
    });
    return res;
  }
  //! Get Table MS
  async getDataChartTableYTDMS(values: []) {
    let res = await fetchingGetData({
      url: `market_share/internal/${values['jenis']}/chart_table?coverage=${values['coverage']}&model=${values['model']}&month=${values['month']}`,
      method: "get",
    });
    return res;
  }
  //**End:: Table API */

  //**Begin:: Get Select Data */
  //! Gaikindo Select API
  async getFilter(values: any) {
    let res = await fetchingGetData({
      url: `select/mtd/performance?asm=${values['asm'] || ""}&sm=${values['sm'] || ""}&outlet=${values['outlet'] || ""}`,
      method: "get",
    });
    return res;
  }
  // Get Month
  async getMonth() {
    let res = await fetchingGetData({
      url: `select/national-information-gaikindo?tipe=getMonth`,
      method: "get",
    });
    return res;
  }
  // Get Month
  async getModel() {
    let res = await fetchingGetData({
      url: `select/spk_do_rs/model`,
      method: "get",
    });
    return res;
  }
  //**End:: Get Select Data */
}
