/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { KTIcon } from "../..";
import { ButtonSaveChange } from "./FormButton";
import { Form } from "formik";
// import {ListsWidget4, ListsWidget5} from '../widgets'

type Props = {
  title: string;
  show: boolean;
  onHide: any;
  onConfirm: any;
  content: any;
  size?: "lg" | "mw-900px" | "modal-xl";
  handleClose?: () => void;
};

const FormModal: React.FC<Props> = ({
  title,
  show = false,
  onHide,
  handleClose,
  onConfirm,
  content,
  size = "mw-900px",
}) => {
  const [loading, setloading] = useState(false);
  return (
    <Modal
      className={"modal fade"}
      id={"kt_modal_select_location"}
      data-backdrop={"static"}
      tabIndex={-1}
      role={"dialog"}
      show={show}
      dialogClassName={`modal-dialog modal-dialog-centered ${size}`}
      aria-hidden={"true"}
      onHide={() => {
        if (onHide != null) onHide();
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            onClick={onHide}
          >
            <KTIcon iconName="cross" className="fs-2x" />
          </div>
        </div>
        <div className="modal-body">
          <Form>{content}</Form>
        </div>
        <div className="modal-footer">
          <button
            onClick={onHide}
            id={"kt_password_cancel"}
            type={"button"}
            className={"btn btn-light-primary"}
          >
            Cancel
          </button>
          <ButtonSaveChange
            label="Apply"
            iconName="save-2"
            loading={loading}
            onClick={() => {
              setloading(true);
              setTimeout(() => {
                if (onConfirm !== null) onConfirm();
                setloading(false);
              }, 150);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

const FormUploadFilesModal: React.FC<Props> = ({
  title,
  show = false,
  onHide,
  handleClose,
  onConfirm,
  content,
  size = "mw-900px",
}) => {
  const [loading, setloading] = useState(false);
  return (
    <Modal
      className={"modal fade"}
      id={"kt_modal_select_location"}
      data-backdrop={"static"}
      tabIndex={-1}
      role={"dialog"}
      show={show}
      dialogClassName={`modal-dialog modal-dialog-centered ${size}`}
      aria-hidden={"true"}
      onHide={() => {
        if (onHide != null) onHide();
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            onClick={onHide}
          >
            <KTIcon iconName="cross" className="fs-2x" />
          </div>
        </div>
        <div className="modal-body">
          <Form>{content}</Form>
        </div>
        <div className="modal-footer">
          <button
            onClick={onHide}
            id={"kt_password_cancel"}
            type={"button"}
            className={"btn btn-light-primary"}
          >
            Cancel
          </button>
          <ButtonSaveChange
            label="Upload"
            iconName="file-up"
            loading={loading}
            onClick={() => {
              setloading(true);
              setTimeout(() => {
                if (onConfirm !== null) onConfirm();
                setloading(false);
              }, 150);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export { FormModal, FormUploadFilesModal };
