/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useMemo, useState } from 'react'
import { toAbsoluteUrl } from '../..'
import { RadialBarChart } from '../chart/radialBar'
import { MaterialReactTableDetails } from '../MaterialReactTable/MaterialReactTable'

type Props2 = {
  avatar?: string
  name: string
  subName: string
  job: string
  avgEarnings: string
  totalEarnings: string
  chartHeight?: string
}
const Card2: FC<Props2> = ({
  avatar = '',
  name,
  subName,
  job,
  avgEarnings,
  totalEarnings,
  chartHeight = '100px'
}) => {

  return (
    <div className='card card-flush h-100'>
      <div className='card-body py-5 px-3'>
        <div className='d-flex flex-center mb-5'>
          <img alt='Pic' src={toAbsoluteUrl(avatar)} className='h-md-125px h-xxl-150px' />
        </div>
        <div className='d-flex flex-center'>
          <span className="fs-2 text-center text-dark fw-bold">{name}</span>
        </div>
        <div className='d-flex flex-center'>
          <span className="fs-4 mb-3 text-center text-gray-500 fw-bold">{subName}</span>
        </div>
        <div className='d-flex justify-content-center flex-center'>
          <div className='fs-5 text-dark mb-0'>Score</div>
          <RadialBarChart
            chartColor='primary'
            chartHeight={chartHeight}
          />
        </div>
      </div>
    </div>
  )
}

type Props3 = {
  avatar?: string
  name: string
  job: string
  avgEarnings: string
  totalEarnings: string
}
const Card3: FC<Props3> = ({
  avatar = '',
  name,
  job,
  avgEarnings,
  totalEarnings,
}) => {
  const [dataCreditProcess] = useState<any>([{
    name: "Kumala",
    all_industry: 30381,
    wuling_vol: 800,
    ms: 2.63
  }, {
    name: "Berkat",
    all_industry: 3822,
    wuling_vol: 99,
    ms: 2.59
  }, {
    name: "Maju",
    all_industry: 32588,
    wuling_vol: 838,
    ms: 2.57
  }, {
    name: "Arista",
    all_industry: 82510,
    wuling_vol: 2081,
    ms: 2.52
  }, {
    name: "Prima",
    all_industry: 16817,
    wuling_vol: 357,
    ms: 2.12
  }, {
    name: "Perdana",
    all_industry: 9074,
    wuling_vol: 183,
    ms: 2.02
  }, {
    name: "AJM",
    all_industry: 18466,
    wuling_vol: 364,
    ms: 2.97
  }]);

  const creditProcessColumns = useMemo(() => [{
    header: "Model",
    accessorKey: 'name',
    size: 95,
    muiTableHeadCellProps: {
      align: "center"
    }, muiTableBodyCellProps: {
      align: "center"
    }, Footer: "Total Keseluruhan"
  }, {
    header: "Stock",
    accessorKey: 'all_industry',
    size: 95,
    muiTableHeadCellProps: {
      align: "center"
    }, muiTableBodyCellProps: {
      align: "center"
    }, Footer: 10
  }, {
    header: "Running Rate",
    accessorKey: 'wuling_vol',
    size: 95,
    muiTableHeadCellProps: {
      align: "center"
    }, muiTableBodyCellProps: {
      align: "center"
    }, Footer: 10
  }, {
    header: "Stock Month",
    accessorKey: 'ms',
    size: 95,
    muiTableHeadCellProps: {
      align: "center"
    }, muiTableBodyCellProps: {
      align: "center"
    }, Footer: 10
  }], []);

  return (
    <div className='card'>
      <div className='card-body py-5 px-3'>
        <div className='d-flex justify-content-center mb-5 bd-highlight'>
          <div className='d-flex m-0 mx-xl-2 align-items-center w-100 flex-center bd-highlight'>
            <div className='symbol symbol-circle symbol-100px symbol-fixed'>
              <img alt='Pic' className="img-fluid img-thumbnail" src={toAbsoluteUrl(avatar)} />
            </div>
            <span className='text-gray-800 fs-3 text-wrap fw-bolder ms-3'>{name}</span>
          </div>
          <div className='d-flex flex-row-fluid w-100 flex-center'>
            <RadialBarChart
              chartColor='primary'
              chartHeight='95px'
              className=' me-0'
            />
            {/*Start:: Rank */}
            <div className='d-flex text-center flex-column'>
              <span className="fw-bolder text-gray-700 fs-xl-7 fs-xxl-4">RANK</span>
              <div className='d-flex flex-center'>
                <span className='fs-xl-7 fs-xxl-3 text-muted m-0'>1</span>
                <div className='bullet w-xl-10px h-xl-3px w-15px h-4px rounded-1'></div>
              </div>
            </div>
            {/*End:: Rank */}
          </div>
        </div>
        <div className='d-flex flex-center p-0'>
          <h3 className="card-title">
            <span className="card-label fw-bold fs-1 mb-1">Inventory</span>
          </h3>
        </div>
        <MaterialReactTableDetails
          columns={creditProcessColumns}
          data={dataCreditProcess}
          isLoading={false}
          enableTopToolbar={false}
        />
      </div>
    </div>
  )
}

export { Card2, Card3 }
