import React, { useEffect, useMemo, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { GroupingTable1, GroupingTable2, GroupingTable3, GroupingTable4 } from './dataTable';
import GetDataFilter from '../../../../hooks/filter';
import TypesFilter from '../../../../hooks/filterType';
import { equalsCheck } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';
import { MscpProcessJourneyAPI } from '../../../../api/MscpAPI';
import { Skeleton } from '@mui/material';

const ProcessJourneyIndex = (props: any) => {
    // Filter ASM & Outlet
    const [asmVal, setAsmVal] = useState<string[]>([]);
    const [tempAsm, setTempAsm] = useState<Option[]>([]);
    const [outletVal, setOutletVal] = useState<string[]>([]);
    const [tempOutlet, setTempOutlet] = useState<Option[]>([]);
    const filterData = GetDataFilter({ asmVal: asmVal, outletVal: outletVal });

    const modelOpt = TypesFilter();
    const [tempModel, setTempModel] = useState<Option[]>([]);
    const [modelValue, setModelValue] = useState<any>([]);
    const [loading, setloading] = useState<boolean>(true);

    // Data MoM Application In Approve & Reject
    const [dataMoMRate, setDataMoMRate] = useState<any>([]);
    // Data MoM Approve
    const [dataMoMApprove, setDataMoMApprove] = useState<any>([]);
    // Data MoM Reject
    const [dataMoMReject, setDataMoMReject] = useState<any>([]);
    // Data MoM Process
    const [dataMoMProcess, setDataMoMProcess] = useState<any>([]);

    let memoInitialData = useMemo(() => {
        let dtTempModel = modelValue.length > 0 ? modelValue : modelOpt.map((val: any) => val.value)
        return dtTempModel
    }, [modelOpt, modelValue]);

    const getData = ({ coverage, model }) => {
        setloading(true)
        new MscpProcessJourneyAPI().getDtMoMRate({ coverage, model }).then((res: any) => {
            setDataMoMRate(res.data)
        })
        new MscpProcessJourneyAPI().getDtMoMReject({ coverage, model }).then((res: any) => {
            setDataMoMReject(res.data)
        })
        new MscpProcessJourneyAPI().getDtMoMProcess({ coverage, model }).then((res: any) => {
            setDataMoMProcess(res.data)
        })
        new MscpProcessJourneyAPI().getDtMoMApprove({ coverage, model }).then((res: any) => {
            setDataMoMApprove(res.data)
        }).then(() => {
            setloading(false)
        })
    }
    useEffect(() => {
        // setloading(true)
        if (memoInitialData?.length > 0) {
            getData({ coverage: filterData?.coverage, model: memoInitialData })
        }
        return () => { }
    }, [memoInitialData, filterData?.coverage]);

    return (
        <React.Fragment>
            <PageTitle breadcrumbs={[{ title: "Credit Process", path: "/sales/process-journey", isSeparator: false, isActive: false }]}>
                {"Process Journey"}
            </PageTitle>
            {/* BEGIN:: Filter Input */}
            <div className="row g-5">
                <div className="row justify-content-center align-items-center"
                    style={{ zIndex: 3 }}>
                    <div className="col-lg-3 mb-5">
                        <FilterMultiSelect
                            label={"ASM"}
                            name="asm"
                            options={filterData?.asmOpt}
                            onMenuClose={() => {
                                let toFilter = tempAsm?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    asmVal
                                )
                                    ? null
                                    : setAsmVal(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempAsm(selected);
                            }}
                            value={tempAsm}
                        />
                    </div>
                    <div className="col-lg-3 mb-5">
                        <FilterMultiSelect
                            label={"Cabang"}
                            name="cabang"
                            options={filterData?.outletOpt}
                            onMenuClose={() => {
                                let toFilter = tempOutlet?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    outletVal
                                )
                                    ? null
                                    : setOutletVal(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempOutlet(selected);
                            }}
                            value={tempOutlet}
                        />
                    </div>
                    <div className="col-lg-3 mb-5">
                        <FilterMultiSelect
                            label={"Model"}
                            name="model"
                            options={modelOpt}
                            onMenuClose={() => {
                                let toFilter = tempModel?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    modelValue
                                )
                                    ? null
                                    : setModelValue(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempModel(selected);
                            }}
                            value={tempModel}
                        />
                    </div>
                </div>
            </div>
            {/* END:: Filter Input */}
            {/* ----------------------------------------------------------------------------- */}
            <div className="row g-5">
                <div className="bd-highlight">
                    <KTCard className='mb-5'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1">MoM Application In Approve & Reject</span>
                            </h3>
                        </div>
                        <KTCardBody className="pl-5 px-2 my-5 pt-0">
                            <GroupingTable1
                                barColor={'#297f9d'}
                                data={dataMoMRate}
                                headerData={["Leasing", "Status"]}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* ----------------------------------------------------------------------------- */}
            <div className="row g-5 mh-100">
                <div className="bd-highlight">
                    <KTCard className='mb-5'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1">MoM Approve</span>
                            </h3>
                        </div>
                        <KTCardBody className="pl-5 px-2 my-5 pt-0">
                            {loading ? <Skeleton variant="rectangular" height={600} /> :
                                <GroupingTable2
                                    barColor={'#297f9d'}
                                    data={dataMoMApprove}
                                    headerData={['Leasing', 'DP (%)', 'DP Approve']}
                                />
                            }
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* ----------------------------------------------------------------------------- */}
            <div className="row g-5 mh-100">
                <div className="bd-highlight">
                    <KTCard className='mb-5'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1">MoM Reject</span>
                            </h3>
                        </div>
                        <KTCardBody className="pl-5 px-2 my-5 pt-0">
                            <GroupingTable3
                                barColor={'#bf392b'}
                                data={dataMoMReject}
                                headerData={['Leasing', 'DP Approve']}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* ----------------------------------------------------------------------------- */}
            <div className="row g-5 mh-100">
                <div className="bd-highlight">
                    <KTCard className='mb-5'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1">Process Journey</span>
                            </h3>
                        </div>
                        <KTCardBody className="pl-5 px-2 my-5 pt-0">
                            <GroupingTable4
                                barColor={'#bf392b'}
                                data={dataMoMProcess}
                                headerData={['Leasing Rejected', 'Leasing Process', 'Approved']}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProcessJourneyIndex;