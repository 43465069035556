import React, { Fragment } from "react";
// import { makeStyles } from "@mui/styles";
// import { makeStyles } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Paper from "@material-ui/core/Paper";
import { Paper } from "@mui/material";

function sumData(arr) {
  // console.log("Cek Array", arr);
  let sum = arr.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);
  return sum.toFixed(1);
}

function sumDataAvg(arr) {
  let sum = arr.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  let avg = sum / arr.length;
  return avg.toFixed(1) || 0;
}
// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     marginTop: theme.spacing(3),
//     overflowX: "auto"
//   },
//   table: {
//     minWidth: 700
//   }
// }));

function TableRS({ title, dt, gt }) {
  // const [data,setData] = React.useState([]);
  function tableRScolor(val) {
    if (val < 70) {
      return "#F4C7C3";
    } else {
      return "#C8E6C9";
    }
  }

  // React.useEffect(() => {}, [dt,gt]);
  // const classes = useStyles();
  return (
    <>
      <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 fs-xxl-5 fs-xl-3 mb-1">
            {title}
          </span>
        </h3>
      </div>
      <Paper sx={{ width: "100%", height: "550px", overflow: "scroll" }}>
        <Table>
          <TableHead
            sx={{ position: "sticky", top: "0px", backgroundColor: "white" }}
          >
            <TableRow>
              <TableCell>ASM</TableCell>
              <TableCell>Outlet</TableCell>
              <TableCell>January</TableCell>
              <TableCell>February</TableCell>
              <TableCell>Maret</TableCell>
              <TableCell>April</TableCell>
              <TableCell>Mei</TableCell>
              <TableCell>Juni</TableCell>
              <TableCell>Juli</TableCell>
              <TableCell>Agustus</TableCell>
              <TableCell>September</TableCell>
              <TableCell>Oktober</TableCell>
              <TableCell>November</TableCell>
              <TableCell>Desember</TableCell>
              <TableCell>Grand Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dt.map((item, i) => (
              <Fragment key={i}>
                <TableRow>
                  <TableCell rowSpan={item.outlet.length + 2}>
                    {item.name}
                  </TableCell>
                </TableRow>
                {item.outlet.map((detail, i) => (
                  <TableRow key={i} hover>
                    <TableCell>{detail}</TableCell>
                    <TableCell>{item.value[i][0]}</TableCell>
                    <TableCell>{item.value[i][1]}</TableCell>
                    <TableCell>{item.value[i][2]}</TableCell>
                    <TableCell>{item.value[i][3]}</TableCell>
                    <TableCell>{item.value[i][4]}</TableCell>
                    <TableCell>{item.value[i][5]}</TableCell>
                    <TableCell>{item.value[i][6]}</TableCell>
                    <TableCell>{item.value[i][7]}</TableCell>
                    <TableCell>{item.value[i][8]}</TableCell>
                    <TableCell>{item.value[i][9]}</TableCell>
                    <TableCell>{item.value[i][10]}</TableCell>
                    <TableCell>{item.value[i][11]}</TableCell>
                    <TableCell>{item.total[i]}</TableCell>
                    {/* <TableCell>B</TableCell> */}
                    {/* <TableCell
                      style={{
                        backgroundColor: `${tableRScolor(item.jan[i])}`,
                      }}
                    >
                      {item.jan[i]}
                    </TableCell>
                    <TableCell>{item.jan[i]}</TableCell>
                    <TableCell>{item.jan[i]}</TableCell>
                    <TableCell>{item.jan[i]}</TableCell> */}
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell>
                    {sumData(item.value.map((val) => val[0]))}
                  </TableCell>
                  <TableCell>
                    {sumData(item.value.map((val) => val[1]))}
                  </TableCell>
                  <TableCell>
                    {sumData(item.value.map((val) => val[2]))}
                  </TableCell>
                  <TableCell>
                    {sumData(item.value.map((val) => val[3]))}
                  </TableCell>
                  <TableCell>
                    {sumData(item.value.map((val) => val[4]))}
                  </TableCell>
                  <TableCell>
                    {sumData(item.value.map((val) => val[5]))}
                  </TableCell>
                  <TableCell>
                    {sumData(item.value.map((val) => val[6]))}
                  </TableCell>
                  <TableCell>
                    {sumData(item.value.map((val) => val[7]))}
                  </TableCell>
                  <TableCell>
                    {sumData(item.value.map((val) => val[8]))}
                  </TableCell>
                  <TableCell>
                    {sumData(item.value.map((val) => val[9]))}
                  </TableCell>
                  <TableCell>
                    {sumData(item.value.map((val) => val[10]))}
                  </TableCell>
                  <TableCell>
                    {sumData(item.value.map((val) => val[11]))}
                  </TableCell>
                  <TableCell>{sumData(item.total)}</TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
          <TableFooter
            sx={{
              position: "sticky",
              bottom: "0px",
              backgroundColor: "white",
            }}
          >
            <TableRow>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "12px", color: "black" }}
                align="left"
                colSpan={2}
              >
                Grand Total
              </TableCell>
              {dt.length > 0
                ? gt.map((val, i) => (
                    <Fragment key={i}>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: "12px",
                          color: "black",
                        }}
                      >
                        {val}
                      </TableCell>
                    </Fragment>
                  ))
                : null}
              <TableCell
                style={{ fontWeight: "bold", fontSize: "12px", color: "black" }}
              >
                {sumData(gt)}
              </TableCell>
              {/* <TableCell>{dt.total}</TableCell> */}
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </>
  );
}

function TablePerformance({ title, dt }) {
  const [data, setData] = React.useState([]);
  function tableRScolor(val) {
    if (val < 70) {
      return "#F4C7C3";
    } else if (val >= 70) {
      return "#C8E6C9";
    } else {
      return "transparent";
    }
  }
  // const classes = useStyles();
  return (
    <>
      <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
        {/* begin::Title */}
        <h3 className="align-items-center flex-column">
          <span className="card-label fw-bold fs-1 mb-1">{title}</span>
        </h3>
      </div>
      <Paper sx={{ width: "100%", height: "600px", overflow: "scroll" }}>
        <Table>
          <TableHead
            sx={{ position: "sticky", top: "0px", backgroundColor: "white" }}
          >
            <TableRow>
              <TableCell>ASM</TableCell>
              <TableCell>Outlet</TableCell>
              <TableCell>January</TableCell>
              <TableCell>February</TableCell>
              <TableCell>Maret</TableCell>
              <TableCell>April</TableCell>
              <TableCell>Mei</TableCell>
              <TableCell>Juni</TableCell>
              <TableCell>Juli</TableCell>
              <TableCell>Agustus</TableCell>
              <TableCell>September</TableCell>
              <TableCell>Oktober</TableCell>
              <TableCell>November</TableCell>
              <TableCell>Desember</TableCell>
              <TableCell>Grand Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dt.map((item, i) => (
              <Fragment key={i}>
                <TableRow>
                  <TableCell rowSpan={item.outlet.length + 2}>
                    {item.name}
                  </TableCell>
                </TableRow>
                {item.outlet.map((detail, i) => (
                  <TableRow key={i} hover>
                    <TableCell>{detail}</TableCell>
                    <TableCell
                      style={{
                        backgroundColor: `${tableRScolor(
                          parseFloat(item.value[i][0]).toFixed(1)
                        )}`,
                      }}
                    >
                      {item.value[i][0] ? `${parseFloat(item.value[i][0]).toFixed(1)}%` : null}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: `${tableRScolor(
                          parseFloat(item.value[i][1]).toFixed(1)
                        )}`,
                      }}
                    >
                       {item.value[i][1] ? `${parseFloat(item.value[i][1]).toFixed(1)}%` : null}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: `${tableRScolor(
                          parseFloat(item.value[i][2]).toFixed(1)
                        )}`,
                      }}
                    >
                       {item.value[i][2] ? `${parseFloat(item.value[i][2]).toFixed(1)}%` : null}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: `${tableRScolor(
                          parseFloat(item.value[i][3]).toFixed(1)
                        )}`,
                      }}
                    >
                       {item.value[i][3] ? `${parseFloat(item.value[i][3]).toFixed(1)}%` : null}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: `${tableRScolor(
                          parseFloat(item.value[i][4]).toFixed(1)
                        )}`,
                      }}
                    >
                       {item.value[i][4] ? `${parseFloat(item.value[i][4]).toFixed(1)}%` : null}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: `${tableRScolor(
                          parseFloat(item.value[i][5]).toFixed(1)
                        )}`,
                      }}
                    >
                       {item.value[i][5] ? `${parseFloat(item.value[i][5]).toFixed(1)}%` : null}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: `${tableRScolor(
                          parseFloat(item.value[i][6]).toFixed(1)
                        )}`,
                      }}
                    >
                       {item.value[i][6] ? `${parseFloat(item.value[i][6]).toFixed(1)}%` : null}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: `${tableRScolor(
                          parseFloat(item.value[i][7]).toFixed(1)
                        )}`,
                      }}
                    >
                       {item.value[i][7] ? `${parseFloat(item.value[i][7]).toFixed(1)}%` : null}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: `${tableRScolor(
                          parseFloat(item.value[i][8]).toFixed(1)
                        )}`,
                      }}
                    >
                       {item.value[i][8] !== null ? `${parseFloat(item.value[i][8]).toFixed(1)}%` : null}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: `${tableRScolor(
                          parseFloat(item.value[i][9]).toFixed(1)
                        )}`,
                      }}
                    >
                      {item.value[i][9] !== null ? `${parseFloat(item.value[i][9]).toFixed(1)}%` : null}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: `${tableRScolor(
                          parseFloat(item.value[i][10]).toFixed(1)
                        )}`,
                      }}
                    >
                      { item.value[i][10] !== null ? `${parseFloat(item.value[i][10]).toFixed(1)}%` : null}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: `${tableRScolor(
                          parseFloat(item.value[i][11]).toFixed(1)
                        )}`,
                      }}
                    >
                      {item.value[i][11] !== null ? `${parseFloat(item.value[i][11]).toFixed(1)}%` : null}
                    </TableCell>
                    <TableCell>{item.total[i]}%</TableCell>
                  </TableRow>
                ))}
                <TableRow hover>
                  <TableCell>Total</TableCell>
                  <TableCell>
                    {sumDataAvg(item.value.map((val) => val[0]))}%
                  </TableCell>
                  <TableCell>
                    {sumDataAvg(item.value.map((val) => val[1]))}%
                  </TableCell>
                  <TableCell>
                    {sumDataAvg(item.value.map((val) => val[2]))}%
                  </TableCell>
                  <TableCell>
                    {sumDataAvg(item.value.map((val) => val[3]))}%
                  </TableCell>
                  <TableCell>
                    {sumDataAvg(item.value.map((val) => val[4]))}%
                  </TableCell>
                  <TableCell>
                    {sumDataAvg(item.value.map((val) => val[5]))}%
                  </TableCell>
                  <TableCell>
                    {sumDataAvg(item.value.map((val) => val[6]))}%
                  </TableCell>
                  <TableCell>
                    {sumDataAvg(item.value.map((val) => val[7]))}%
                  </TableCell>
                  <TableCell>
                    {sumDataAvg(item.value.map((val) => val[8]))}%
                  </TableCell>
                  <TableCell>
                    {sumDataAvg(item.value.map((val) => val[9]))}%
                  </TableCell>
                  <TableCell>
                    {sumDataAvg(item.value.map((val) => val[10]))}%
                  </TableCell>
                  <TableCell>
                    {sumDataAvg(item.value.map((val) => val[11]))}%
                  </TableCell>
                  <TableCell>{sumDataAvg(item.total)}</TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
          <TableFooter
            sx={{
              position: "sticky",
              bottom: "0px",
              backgroundColor: "white",
              fontWeight: "bold",
            }}
          >
            <TableRow>
              <TableCell align="left" colSpan={2}>
                Grand Total
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </>
  );
}

export { TableRS, TablePerformance };
