import React, { useEffect, useState } from "react";
import { SegmentAPI } from "../../../../api/MarketShareAPI";
import { MovementbyModels } from "../SegmentHelper";
import { MultipleSelectCheckmarks } from "../../performance/SelectHelper";
import FilterMultiSelect from "../../../../../_metronic/helpers/components/form-actions/FilteringInput";
import { equalsCheck } from "../../../../../_metronic/helpers/helpers-data/AssetHelpers";

const MovementByModelsView = ({ cov, loadingState, setLoading }) => {
  const defaultValue = [
    { label: "FORMO", value: "FORMO" },
    { label: "AIR EV", value: "AIR EV" },
    { label: "FORMO MAX", value: "FORMO MAX" },
    { label: "ALVEZ", value: "ALVEZ" },
    { label: "ALMAZ", value: "ALMAZ" },
    { label: "CORTEZ", value: "CORTEZ" },
  ];
  const [dtModelMov, setDtModelMov] = useState([]);
  const [modelVal, setModelVal] = useState([
    "FORMO",
    "AIR EV",
    "FORMO MAX",
    "ALVEZ",
    "ALMAZ",
    "CORTEZ",
  ]);
  const [modelOpt, setModelOpt] = useState([]);
  const [modelSlc, setModelSlc] = useState(defaultValue);

  async function tableData(cov, model) {
    setLoading(true);
    await new SegmentAPI()
      .movementModel(cov, model)
      .then((res) => {
        res.response.map((val) => {
          return setDtModelMov(val.chart);
        });
      })
      .then(() => {
        setLoading(false);
      });
  }

  async function getFilter() {
    let val = {
      model: modelVal.slice(),
    };
    await new SegmentAPI().filterModel().then((res) => {
      setModelOpt(res.data);
    });
    tableData(cov, val.model);
  }

  useEffect(() => {
    getFilter();
  }, [cov, modelVal]);

  return (
    <>
      <div
        className="col d-flex justify-content-start"
        style={{ marginBottom: "-2%", zIndex: 2, left: "15%" }}
      >
        <div className="col-xxl-3 mb-5">
          <FilterMultiSelect
            options={modelOpt}
            label="Model"
            isSelectAll={true}
            value={modelSlc}
            onMenuClose={() => {
              let toFilter = modelSlc?.map((val) => val.value);

              return equalsCheck(toFilter, modelVal)
                ? null
                : setModelVal(toFilter);
            }}
            onChange={(selected) => {
              console.log("selected", selected);
              setModelSlc(selected);
            }}
          />
        </div>
      </div>
      <div style={{ zIndex: -1 }}>
        <MovementbyModels
          title={"Movement By Models"}
          dt={dtModelMov}
          loadState={loadingState}
        />
      </div>
    </>
  );
};

export default MovementByModelsView;
