/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { ThemeModeComponent } from '../../../../../_metronic/assets/ts/layout';
import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils';
import { ChartTable } from '../../../../../_metronic/helpers/components/google-chart/ChartTable';
// function filter_list(l: any) {
//     return l.filter((x: any) => typeof x === "number");
// }
const ChartMarketShareNationalGroupDealerIndex: any = () => {
    const baseColors = ["#bf392b", "#297f9d", "#fcc47c", "#43a047", "#00897b", "#7030a0", "#a67650", "#f7b781", "#59a7a7", "#7c459c", "#4c5d34", "#6c8cbf", "#872551", "#8c8751"];
    const labelColor = getCSSVariableValue("--bs-gray-dark");
    const labelColorForDark = getCSSVariableValue("--bs-gray-800");
    const [dataAI, setDataAI] = useState<{ columns: string[], rows: any[] }>({
        columns: [],
        rows: [],
    });
    const [dataWS, setDataWS] = useState<{ columns: string[], rows: any[] }>({
        columns: [],
        rows: [],
    });

    // All Industry
    const [chartOptionsAI, setChartOptionsAI] = useState<{
        chart?: any,
        credits?: any,
        colors?: any[],
        title?: any,
        legend?: any,
        tooltip?: any,
        xAxis?: any,
        yAxis?: any,
        plotOptions?: any,
        series: any[],
    }>({
        chart: {
            height: 650,
            backgroundColor: {
                stops: [
                    [0, "rgb(255, 255, 255)"],
                    [1, "rgb(240, 240, 255)"],
                ],
            },
        }, credits: {
            enabled: false,
            text: "KumalaGroup.co.id",
            style: {
                fontFamily: "inherit",
                fontSize: "11px",
                color:
                    ThemeModeComponent.getMode() === "dark"
                        ? labelColorForDark
                        : labelColor,
            },
        }, colors: baseColors, title: {
            text: "",
            align: "center",
            style: {
                color:
                    ThemeModeComponent.getMode() === "dark"
                        ? labelColorForDark
                        : labelColor,
                font: 'bold 16px "inherit"',
            },
        }, legend: {
            itemStyle: {
                font: "inherit",
                color: ThemeModeComponent.getMode() === "dark"
                    ? labelColorForDark
                    : labelColor,
            },
            align: 'center',
            verticalAlign: 'top',
            layout: 'horizontal',
            itemHoverStyle: {
                color: "gray",
            },
        }, tooltip: {
            useHTML: true,
            style: {
                fontSize: '12px',
            },
            headerFormat: '<table>',
            pointFormat: '<tr><th>Dealer Group</th><td>: {point.name}</td></tr>' +
                '<tr><th>Vol</th><td>: {point.x}</td></tr>' +
                '<tr><th>M.Size</th><td>: {point.y}</td></tr>' +
                '<tr><th>Ms All Industry</th><td>: {point.z}%</td></tr>',
            footerFormat: '</table>',
            followPointer: true
        }, xAxis: [{
            min: 0,
            // max: maxAI,
            labels: {
                style: {
                    fontSize: "10px",
                    color:
                        ThemeModeComponent.getMode() === "dark"
                            ? labelColorForDark
                            : labelColor,
                },
            },
            minorTickInterval: 'auto',
            startOnTick: true,
        }], yAxis: {
            maxPadding: 0,
            min: 0,
            // max: maxAI,
            visible: true,
        }, plotOptions: {
            series: {
                allowPointSelect: true,
                dataLabels: {
                    enabled: true,
                    allowOverlap: true,
                    format: '{point.name}',
                    style: {
                        fontSize: '10px',
                        style: {
                            color: 'black',
                            textOutline: 'none',
                            fontWeight: 'normal'
                        }
                    },
                },
                cursor: 'pointer',
                point: {
                    events: {
                        select(e: any) {
                            fetchingDataAllIndustry(e['target']['name']);
                        }, unselect(e: any) {
                            if (e.accumulate === false) {
                                fetchingDataAllIndustry(undefined);
                            }

                        }
                    },
                },
                marker: {
                    states: {
                        select: {
                            enabled: true,
                        },
                    },
                },
            }, bubble: {
                minSize: 80,
                maxSize: 80,
                /* zMin: 0, */
                zMax: 80,

            }
        }, series: [],
    });
    // Wuling Segment
    const [chartOptionsWS, setChartOptionsWS] = useState<{
        chart?: any,
        credits?: any,
        colors?: any[],
        title?: any,
        legend?: any,
        tooltip?: any,
        xAxis?: any,
        yAxis?: any,
        plotOptions?: any,
        series: any[],
    }>({
        chart: {
            height: 650,
            backgroundColor: {
                stops: [
                    [0, "rgb(255, 255, 255)"],
                    [1, "rgb(240, 240, 255)"],
                ],
            },
        }, credits: {
            enabled: false,
            text: "KumalaGroup.co.id",
            style: {
                fontFamily: "inherit",
                fontSize: "11px",
                color:
                    ThemeModeComponent.getMode() === "dark"
                        ? labelColorForDark
                        : labelColor,
            },
        }, colors: baseColors, title: {
            text: "",
            align: "center",
            style: {
                color:
                    ThemeModeComponent.getMode() === "dark"
                        ? labelColorForDark
                        : labelColor,
                font: 'bold 16px "inherit"',
            },
        }, legend: {
            itemStyle: {
                font: "inherit",
                color: ThemeModeComponent.getMode() === "dark"
                    ? labelColorForDark
                    : labelColor,
            },
            align: 'center',
            verticalAlign: 'top',
            layout: 'horizontal',
            itemHoverStyle: {
                color: "gray",
            },
        }, tooltip: {
            useHTML: true,
            style: {
                fontSize: '12px',
            },
            headerFormat: '<table>',
            pointFormat: '<tr><th>Dealer Group</th><td>: {point.name}</td></tr>' +
                '<tr><th>Vol</th><td>: {point.x}</td></tr>' +
                '<tr><th>M.Size</th><td>: {point.y}</td></tr>' +
                '<tr><th>Ms All Industry</th><td>: {point.z}%</td></tr>',
            footerFormat: '</table>',
            followPointer: true
        }, xAxis: [{
            min: 0,
            // max: maxAI,
            labels: {
                style: {
                    fontSize: "10px",
                    color:
                        ThemeModeComponent.getMode() === "dark"
                            ? labelColorForDark
                            : labelColor,
                },
            },
            minorTickInterval: 'auto',
            startOnTick: true,
        }], yAxis: {
            maxPadding: 0,
            min: 0,
            // max: maxAI,
            visible: true,
        }, plotOptions: {
            series: {
                allowPointSelect: true,
                dataLabels: {
                    enabled: true,
                    allowOverlap: true,
                    format: '{point.name}',
                    style: {
                        fontSize: '10px',
                        style: {
                            color: 'black',
                            textOutline: 'none',
                            fontWeight: 'normal'
                        }
                    },
                },
                cursor: 'pointer',
                point: {
                    events: {
                        select(e: any) {
                            fetchingDataWulingSegment(e['target']['name']);
                        }, unselect(e: any) {
                            if (e.accumulate === false) {
                                fetchingDataWulingSegment(undefined);
                            }

                        }
                    },
                },
                marker: {
                    states: {
                        select: {
                            enabled: true,
                        },
                    },
                },
            }, bubble: {
                minSize: 80,
                maxSize: 80,
                /* zMin: 0, */
                zMax: 80,

            }
        }, series: [],
    });

    // Fetching API All Industry
    const fetchingDataAllIndustry = async (selected: string | undefined) => {
        const dataAPI = [{
            name: "Kumala",
            all_industry: 30381,
            wuling_vol: 800,
            ms: 2.63
        }, {
            name: "Berkat",
            all_industry: 3822,
            wuling_vol: 99,
            ms: 2.59
        }, {
            name: "Maju",
            all_industry: 32588,
            wuling_vol: 838,
            ms: 2.57
        }, {
            name: "Arista",
            all_industry: 82510,
            wuling_vol: 2081,
            ms: 2.52
        }, {
            name: "Prima",
            all_industry: 16817,
            wuling_vol: 357,
            ms: 2.12
        }, {
            name: "Perdana",
            all_industry: 9074,
            wuling_vol: 183,
            ms: 2.02
        }, {
            name: "AJM",
            all_industry: 18466,
            wuling_vol: 364,
            ms: 2.97
        }]
        let row: [{}] = [{}];
        let series: any[] = [{}];
        dataAPI?.map((item) => {
            series.push({
                type: "bubble",
                name: item['name'],
                data: [{ x: item['all_industry'], y: item['wuling_vol'], z: item['ms'], name: item['name'] }],
            });
            row.push([item['name'], item['all_industry'], item['wuling_vol'], item['ms']])
            return item;
        });
        row.shift();
        series.shift();
        setChartOptionsAI({
            series: series,
        });
        if (selected === undefined) {
            setDataAI({
                columns: ["Dealer Group", "All Industry", "Wuling vol", "MS"],
                rows: row,
            });
        } else {
            let newTableArray = row.filter((va) => va[0] === selected);
            setDataAI({
                columns: ["Dealer Group", "All Industry", "Wuling vol", "MS"],
                rows: newTableArray,
            });
        }
    };

    // Fetching API All Industry
    const fetchingDataWulingSegment = async (selected: string | undefined) => {
        const dataAPI = [{
            name: "Kumala",
            wuling_market: 19780,
            wuling_vol: 800,
            ms: 2.63
        }, {
            name: "Berkat",
            wuling_market: 2705,
            wuling_vol: 99,
            ms: 2.59
        }, {
            name: "Maju",
            wuling_market: 21641,
            wuling_vol: 838,
            ms: 2.57
        }, {
            name: "Arista",
            wuling_market: 54170,
            wuling_vol: 2081,
            ms: 2.52
        }, {
            name: "Prima",
            wuling_market: 11984,
            wuling_vol: 357,
            ms: 2.12
        }, {
            name: "Perdana",
            wuling_market: 5869,
            wuling_vol: 183,
            ms: 2.02
        }, {
            name: "AJM",
            wuling_market: 11446,
            wuling_vol: 364,
            ms: 2.97
        }]
        let row: [{}] = [{}];
        let series: [{}] = [{}];
        dataAPI?.map((item) => {
            series.push({
                type: "bubble",
                name: item['name'],
                data: [{ x: item['wuling_market'], y: item['wuling_vol'], z: item['ms'], name: item['name'] }],
            })
            row.push([item['name'], item['wuling_market'], item['wuling_vol'], item['ms']])
            return item;
        });
        row.shift();
        series.shift();
        setChartOptionsWS({
            series: series,
        });
        if (selected === undefined) {
            setDataWS({
                columns: ["Dealer Group", "Wuling Market", "Wuling vol", "MS"],
                rows: row,
            });
        } else {
            let newTableArray = row.filter((va) => va[0] === selected);
            setDataWS({
                columns: ["Dealer Group", "All Industry", "Wuling vol", "MS"],
                rows: newTableArray,
            });
        }
    };

    useEffect(() => {
        fetchingDataAllIndustry(undefined);
        fetchingDataWulingSegment(undefined);
    }, []);
    return (
        <>
            <PageTitle breadcrumbs={[{ title: "National Information", path: "/sales/ms-national-group-dealer", isSeparator: false, isActive: false }]}>
                {"MS National Group Dealer"}
            </PageTitle>
            {/* ----------------------------------------------------------------------------- */}
            {/* BEGIN:: Card Chart Bubble M.Size Vol All Industry */}
            <div className="row flex-xxl-row h-md-100">
                {/* All Industry */}
                <div className="col-xxl-12 h-md-100 mb-5 bd-highlight">
                    <KTCard flush stretch={"stretch-75"}>
                        <div className="card-header d-flex align-items-center flex-column mt-3 w-100 border-0 pt-5 pb-0">
                            {/* begin::Title */}
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1 mb-1">All Industry</span>
                            </h3>
                            {/* end::Title */}
                        </div>
                        <KTCardBody className="row d-flex col-xxl-12">
                            <div className="col-xxl-9">
                                <HighchartsReact highcharts={Highcharts} options={chartOptionsAI} />
                            </div>
                            <div className="col-xxl-3">
                                <ChartTable
                                    data={[dataAI['columns'], ...dataAI['rows']]}
                                    formatters={[
                                        {
                                            type: "NumberFormat" as const,
                                            column: 3,
                                            options: {
                                                suffix: "%",
                                            },
                                        },
                                    ]}
                                />
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
                {/* Wuling Segment */}
                <div className="col-xxl-12 h-md-100 mb-5 bd-highlight">
                    <KTCard flush stretch={"stretch-75"}>
                        <div className="card-header d-flex align-items-center flex-column mt-3 w-100 border-0 pt-5 pb-0">
                            {/* begin::Title */}
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1 mb-1">Wuling Segment</span>
                            </h3>
                            {/* end::Title */}
                        </div>
                        <KTCardBody className="row d-flex col-xxl-12">
                            <div className="col-xxl-9">
                                <HighchartsReact highcharts={Highcharts} options={chartOptionsWS} />
                            </div>
                            <div className="col-xxl-3">
                                <ChartTable
                                    data={[dataWS['columns'], ...dataWS['rows']]}
                                    formatters={[
                                        {
                                            type: "NumberFormat" as const,
                                            column: 3,
                                            options: {
                                                suffix: "%",
                                            },
                                        },
                                    ]}
                                />
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
        </>
    );
};

export { ChartMarketShareNationalGroupDealerIndex };
