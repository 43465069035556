import { fetchingGetData, multiPartFetching, simpleFetching } from "./API";

export class MasterAPI {
    //**Begin:: Brand */
    async getDataBrand() {
        let res = await fetchingGetData({
            url: `brand`,
            method: "get",
        });
        return res;
    }

    // Add (Store Data Insert Query)
    async setBrand(values: any) {
        let fd = new FormData();
        for (const key in values) {
            fd.append(key, values[key]);
        }
        let res = await multiPartFetching({
            url: `brand`,
            method: "post",
            data: fd,
        });
        return res;
    }

    // Update (Update Data Insert Query)
    async updateBrand(values: any) {
        let fd = new FormData();
        for (const key in values) {
            fd.append(key, values[key]);
        }
        let res = await multiPartFetching({
            url: `brand`,
            method: "put",
            data: fd,
        });
        return res;
    }

    // Update (Update Data Insert Query)
    async deleteBrand(values: any) {
        let res = await simpleFetching({
            url: `brand`,
            method: "delete",
            data: values,
        });
        return res;
    }
    //**End:: Brand */

    //**Begin:: UserLogin */
    async getDataUserLogin() {
        let res = await fetchingGetData({
            url: `userlogin`,
            method: "get",
        });
        return res;
    }

    async getDataUserLoginByIdBrand(values) {
        let res = await fetchingGetData({
            url: `userlogin/get-perusahaan?id=${values['id']}&id_brand=${values['id_brand']}`,
            method: "get",
        });
        return res;
    }

    // Add (Store Data Insert Query)
    async setUserLogin(values: any) {
        let res = await fetchingGetData({
            url: `userlogin`,
            method: "post",
            data: values,
        });
        return res;
    }

    // Add (Store Data Insert Query)
    async setUpdateCoverage(values: any) {
        let res = await simpleFetching({
            url: `userlogin/coverage`,
            method: "post",
            data: values,
        });
        return res;
    }

    // Update (Update Data Insert Query)
    async updateUserLogin(values: any) {
        let res = await simpleFetching({
            url: `userlogin`,
            method: "put",
            data: values,
        });
        return res;
    }

    // Update (Update Data Insert Query)
    async updateStatusUsers(values: any) {
        let res = await simpleFetching({
            url: `userlogin/update_status_users`,
            method: "put",
            data: values,
        });
        return res;
    }

    // Update (Update Data Insert Query)
    async deleteUserLogin(values: any) {
        let res = await simpleFetching({
            url: `userlogin`,
            method: "delete",
            data: values,
        });
        return res;
    }


    //**End:: User Login */

    //**Begin:: Select Option Get API */
    // Get Brand
    async getSelectMasterBrand() {
        let res = await fetchingGetData({
            url: `select/master-brand`,
            method: "get",
        });
        return res;
    }

    async getSelectBrand(value: string) {
        let res = await fetchingGetData({
            url: `select/brand?tipe=${value}`,
            method: "get",
        });
        return res;
    }

    async getSelectKaryawan(value: string) {
        let res = await fetchingGetData({
            url: `select/karyawan?tipe=${value}`,
            method: "get",
        });
        return res;
    }

    async getSelectRoles() {
        let res = await fetchingGetData({
            url: `select/roles`,
            method: "get",
        });
        return res;
    }

    async getSelectPerusahaan(values: any) {
        let res = await fetchingGetData({
            url: `list/perusahaan?id_brand=${values}`,
            method: "get",
        });
        return res;
    }

    //**End:: Select Option Get API */
}
