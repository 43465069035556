import React, { useEffect, useMemo, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Form, Formik } from 'formik'
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import { HighchartsSample } from '../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample';
import { MaterialReactTableDetails } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';
import ColumnGroupingTable from './dataTable';
import { TargetConversionAPI } from '../../../../api/TargetConversionAPI';
import { spanNumberWithPointTotal } from '../../../../../_metronic/helpers/components/MaterialReactTable/ComponentStyleRowsTable';
import moment from 'moment';
import GetDataFilter from '../../../../hooks/filter';
import { equalsCheck, monthOpt } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';
import TypesFilter from '../../../../hooks/filterType';

type initialValues = {
  month: number[];
  asm: string[];
};
type typeDataTable = {
  columns: object[];
  datas: object[];
};
const TestDriveVAchievementIndex = () => {
  const typesOpt = TypesFilter();
  const [initialValues] = useState<initialValues>({ asm: [], month: [] });
  const [asmVal, setasmVal] = useState<string[]>([]);
  const [smVal, setsmVal] = useState<string[]>([]);
  const [outletVal, setoutletVal] = useState<string[]>([]);
  const filterData = GetDataFilter({ asmVal: asmVal, smVal: smVal, outletVal: outletVal });
  const [arrSelect, setArrSelect] = useState<Option[] | null>([]);
  const [smSelect, setSmSelect] = useState<Option[] | null>([]);
  const [outletSelect, setOutletSelect] = useState<Option[] | null>([]);
  const [slcMonth, setSlcMonth] = useState<number[]>([]);
  const [monthSlc, setMonthSlc] = useState<Option[]>([]);
  const [loading, setloading] = useState<boolean>(true);
  const [tempType, setTempType] = useState<Option[] | null>([]);
  const [typeValue, setTypeValue] = useState<String[]>([]);
  const [testDriveAchiByDays, setTestDriveAchiByDays] = useState<typeDataTable>({
    columns: [],
    datas: [],
  });
  const [testDriveModelByDays, setTestDriveModelByDays] = useState<typeDataTable>({
    columns: [],
    datas: [],
  });
  const [dataWOTestDriveLeadsConversionByModel, setDataWOTestDriveLeadsConversionByModel] = useState<any>([]);
  const [dataTestDriveLeadsConversionByModel, setDataTestDriveLeadsConversionByModel] = useState<any>([]);
  // Load Chart
  const fetchingTable1API = async (params: any) => {
    setloading(true);
    // Test Drive Achievement by Days
    const { data: table_testdrive_ach_by_day } = await new TargetConversionAPI().getTestDriveAchByDay({
      ...params
    });
    let datasAchByDays: any = [];
    table_testdrive_ach_by_day.map((item: any) => {
      let objData: any = {};
      let average = 0;
      for (let i = 0; i < item['days'].length; i++) {
        objData[`days${i + 1}`] = item['days'][i];
        average += item['days'][i];
      }
      objData[`total`] = spanNumberWithPointTotal(average);
      datasAchByDays.push({
        name: item['name'],
        ...objData,
        dates: item['date'],
      });
      return item;
    });
    let columnsAchByDays: any = [{
      header: 'Test Drive',
      accessorKey: 'name',
      size: 100,
    }];
    for (let i = 0; i < parseInt(moment(datasAchByDays['dates']).endOf("month").format("D")); i++) {
      let objColumn: any = {};
      objColumn['header'] = `${i + 1}`;
      objColumn['accessorKey'] = `days${i + 1}`;
      objColumn['size'] = 10;
      objColumn['muiTableBodyCellProps'] = ({ cell }) => ({
        align: "center",
      });
      objColumn['muiTableHeadCellProps'] = {
        align: "center",
      };
      columnsAchByDays.push(objColumn);
    }
    columnsAchByDays.push({
      header: 'Total',
      accessorKey: 'total',
      size: 10,
      muiTableBodyCellProps: {
        align: "center"
      },
      muiTableHeadCellProps: {
        align: "center"
      },
    });
    setTestDriveAchiByDays({
      columns: columnsAchByDays,
      datas: datasAchByDays,
    });

    // Test Drive Per Model by Days
    const { data: table_testdrive_model_by_day } = await new TargetConversionAPI().getTestDriveModelByDay({
      ...params
    });
    let datasTestDriveModelByDays: any = [];
    table_testdrive_model_by_day.map((item: any) => {
      let objData: any = {};
      let average = 0;
      for (let i = 0; i < item['days'].length; i++) {
        objData[`days${i + 1}`] = item['days'][i];
        average += item['days'][i];
      }
      objData[`total`] = spanNumberWithPointTotal(average);
      datasTestDriveModelByDays.push({
        name: item['name'],
        ...objData,
        dates: item['date'],
      });
      return item;
    });
    let columnsModelByDays: any = [{
      header: 'Type',
      accessorKey: 'name',
      size: 100,
      // muiTableHeadCellProps: {
      //   align: "center"
      // },
    }];
    for (let i = 0; i < parseInt(moment(datasTestDriveModelByDays['dates']).endOf("month").format("D")); i++) {
      let objColumn: any = {};
      objColumn['header'] = `${i + 1}`;
      objColumn['accessorKey'] = `days${i + 1}`;
      objColumn['size'] = 10;
      objColumn['muiTableBodyCellProps'] = ({ cell }) => ({
        align: "center",
        // sx: {
        //   backgroundColor: cell.getValue() >= 5 ? "green" : "red",
        //   color: cell.getValue() >= 5 ? "green" : "red",
        // },
      });
      objColumn['muiTableHeadCellProps'] = {
        align: "center",
      };
      columnsModelByDays.push(objColumn);
    }
    columnsModelByDays.push({
      header: 'Total',
      accessorKey: 'total',
      size: 10,
      muiTableBodyCellProps: {
        align: "center"
      },
      muiTableHeadCellProps: {
        align: "center"
      },
    });
    setTestDriveModelByDays({
      columns: columnsModelByDays,
      datas: datasTestDriveModelByDays,
    });

    // W/O Test Drive Leads Conversion by Model
    const { data: wo_testdrive_leads_conversion_by_model } = await new TargetConversionAPI().getWOTestDriveLeadsConversionModel({
      ...params
    });
    let datasWoTestDriveArray: any = [];
    wo_testdrive_leads_conversion_by_model.map((item: any) => {
      datasWoTestDriveArray.push({
        name: item['name'],
        leads: item['leads'],
        count_prospek: item['count_prospek'],
        count_prospek_by_leads: item['count_prospek_by_leads'] ? item['count_prospek_by_leads'] + "%" : '',
        count_spk: item['count_spk'],
        count_spk_by_prospek: item['count_spk_by_prospek'] ? item['count_spk_by_prospek'] + "%" : '',
        count_spk_by_leads: item['count_spk_by_leads'] ? item['count_spk_by_leads'] + "%" : '',
        count_do: item['count_do'],
        count_do_by_spk: item['count_do_by_spk'] ? item['count_do_by_spk'] + "%" : '',
        count_do_by_leads: item['count_do_by_leads'] ? item['count_do_by_leads'] + "%" : '',
      });
      return item;
    });
    setDataWOTestDriveLeadsConversionByModel(datasWoTestDriveArray);
    // Test Drive Leads Conversion by Model
    const { data: testdrive_leads_conversion_by_model } = await new TargetConversionAPI().getTestDriveLeadsConversionModel({
      ...params
    });
    let datasTestDriveArray: any = [];
    testdrive_leads_conversion_by_model.map((item: any) => {
      datasTestDriveArray.push({
        name: item['name'],
        leads: item['leads'],
        count_prospek: item['count_prospek'],
        count_prospek_by_leads: item['count_prospek_by_leads'] ? item['count_prospek_by_leads'] + "%" : '',
        count_spk: item['count_spk'],
        count_spk_by_prospek: item['count_spk_by_prospek'] ? item['count_spk_by_prospek'] + "%" : '',
        count_spk_by_leads: item['count_spk_by_leads'] ? item['count_spk_by_leads'] + "%" : '',
        count_do: item['count_do'],
        count_do_by_spk: item['count_do_by_spk'] ? item['count_do_by_spk'] + "%" : '',
        count_do_by_leads: item['count_do_by_leads'] ? item['count_do_by_leads'] + "%" : '',
      });
      return item;
    });
    setDataTestDriveLeadsConversionByModel(datasTestDriveArray);
    setloading(false);
  };

  // const fetchingTable2API = async (params: any) => {
  //   setloading(true);

  //   setloading(false);
  // };

  // USEMEMO:: Column Test Drive Leads Conversion by Model
  const TestDriveLeadsConversionByModel = useMemo(() => [{
    columns: [{
      header: "Type",
      accessorKey: 'name',
      size: 70,
      fontSize: 10,
      Footer: 'Total Keseluruhan',
    }, {
      header: 'Leads',
      accessorKey: 'leads',
      size: 10,
      fontSize: 10,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      Footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('leads'), 0),
    }, {
      header: 'Count',
      accessorKey: 'count_prospek',
      size: 10,
      fontSize: 10,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      Footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('count_prospek'), 0),
    }, {
      header: '% by Leads',
      accessorKey: 'count_prospek_by_leads',
      size: 10,
      fontSize: 10,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      Footer: ({ table }) => {
        const leads = table
          .getFilteredRowModel()
          .rows
          .reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('leads'), 0);
        const count_prospek = table
          .getFilteredRowModel()
          .rows
          .reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('count_prospek'), 0);
        const percent = Math.round((count_prospek / leads) * 100);
        return !isNaN(percent) && percent !== 0 ? percent + "%" : '';
      },
    }],
    header: 'Prospect',
    id: 'prospect'
  }, {
    columns: [{
      header: 'Count',
      accessorKey: 'count_spk',
      size: 10,
      fontSize: 10,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      Footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('count_spk'), 0),
    }, {
      header: '% by Pros.',
      accessorKey: 'count_spk_by_prospek',
      size: 10,
      fontSize: 10,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      Footer: ({ table }) => {
        const count_spk = table
          .getFilteredRowModel()
          .rows
          .reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('count_spk'), 0);
        const count_prospek = table
          .getFilteredRowModel()
          .rows
          .reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('count_prospek'), 0);
        const percent = Math.round((count_spk / count_prospek) * 100);
        return !isNaN(percent) && percent !== 0 ? percent + "%" : '';
      },
    }, {
      header: '% by Leads',
      accessorKey: 'count_spk_by_leads',
      size: 10,
      fontSize: 10,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      Footer: ({ table }) => {
        const count_spk = table
          .getFilteredRowModel()
          .rows
          .reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('count_spk'), 0);
        const leads = table
          .getFilteredRowModel()
          .rows
          .reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('leads'), 0);
        const percent = Math.round((count_spk / leads) * 100);
        return !isNaN(percent) && percent !== 0 ? percent + "%" : '';
      },
    }],
    header: 'SPK',
    id: 'spk'
  }, {
    columns: [{
      header: 'Count',
      accessorKey: 'count_do',
      size: 10,
      fontSize: 10,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      Footer: ({ table }) => table.getFilteredRowModel().rows.reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('count_do'), 0),
    }, {
      header: '% by SPK.',
      accessorKey: 'count_do_by_spk',
      size: 10,
      fontSize: 10,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      Footer: ({ table }) => {
        const count_do = table
          .getFilteredRowModel()
          .rows
          .reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('count_do'), 0);
        const count_spk = table
          .getFilteredRowModel()
          .rows
          .reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('count_spk'), 0);
        const percent = Math.round((count_do / count_spk) * 100);
        return !isNaN(percent) && percent !== 0 ? percent + "%" : '';
      },
    }, {
      header: '% by Leads',
      accessorKey: 'count_do_by_leads',
      size: 10,
      fontSize: 10,
      muiTableBodyCellProps: ({ cell }) => {
        return ({
          align: 'center',
        })
      },
      Footer: ({ table }) => {
        const count_do = table
          .getFilteredRowModel()
          .rows
          .reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('count_do'), 0);
        const leads = table
          .getFilteredRowModel()
          .rows
          .reduce((total: number, row: { getValue: (arg0: string) => number; }) => total + row.getValue('leads'), 0);
        const percent = Math.round((count_do / leads) * 100);
        return !isNaN(percent) && percent !== 0 ? percent + "%" : '';
      },
    }],
    header: 'DO',
    id: 'do'
  }], []);

  useEffect(() => {
    fetchingTable1API([filterData.coverage, slcMonth, typeValue]);
    // fetchingTable2API(initialValues);
    // fetchingNationalMSTableAPI();
    // fetchingDataKumalaArea(undefined);
  }, [filterData.coverage, slcMonth, typeValue]);
  return (
    <React.Fragment>
      <PageTitle breadcrumbs={[{ title: "Target & Conversion", path: "/sales/sales-target-vs-achievement", isSeparator: false, isActive: false }]}>
        {'Test Drive ⓥ Achievement'}
      </PageTitle>
      {/* Filter Input */}
      <div className="row g-6 g-xl-9">
        {/* Filter */}
        <div className="row justify-content-center align-items-center my-10">
          <div className="col-md-2 mb-5">
            <FilterMultiSelect
              label={"ASM"}
              name="asm"
              options={filterData.asmOpt}
              onMenuClose={() => {
                let toFilter = arrSelect?.map((val) => val.value);

                return equalsCheck(
                  toFilter as [],
                  asmVal
                )
                  ? null
                  : setasmVal(toFilter as []);
              }}
              onChange={(selected: Option[]) => {
                setArrSelect(selected);
              }}
              value={arrSelect}
            />
          </div>
          <div className="col-md-3 mb-5">
            <FilterMultiSelect
              options={filterData.smOpt}
              label="SM"
              value={smSelect}
              onMenuClose={() => {
                let toFilter = smSelect?.map((val) => val.value);
                return equalsCheck(
                  toFilter as [],
                  smVal
                )
                  ? null
                  : setsmVal(toFilter as []);
              }}
              onChange={(selected: Option[]) => {
                console.log("selected", selected);
                setSmSelect(selected);
              }}
            />
          </div>
          <div className="col-md-3 mb-5">
            <FilterMultiSelect
              options={filterData.outletOpt}
              label="Outlet"
              value={outletSelect}
              onMenuClose={() => {
                let toFilter = outletSelect?.map((val) => val.value);
                return equalsCheck(
                  toFilter as [],
                  outletVal
                )
                  ? null
                  : setoutletVal(toFilter as []);
              }}
              onChange={(selected: Option[]) => {
                setOutletSelect(selected);
              }}
            />
          </div>
          <div className="col-md-2 mb-5">
            <FilterMultiSelect
              label={"Month"}
              options={monthOpt}
              value={monthSlc}
              onMenuClose={() => {
                let toFilter = monthSlc?.map((val) => val.value);
                return equalsCheck(
                  toFilter as [],
                  slcMonth
                )
                  ? null
                  : setSlcMonth(toFilter as []);
              }}
              onChange={(selected: Option[]) => {
                setMonthSlc(selected);
              }}
            />
          </div>
          <div className="col-md-2 mb-5">
            <FilterMultiSelect
              label={"Type"}
              name="type"
              options={typesOpt}
              value={tempType}
              onMenuClose={() => {
                let toFilter = tempType?.map((val) => val.value);
                return equalsCheck(
                  toFilter as [],
                  typeValue
                )
                  ? null
                  : setTypeValue(toFilter as []);
              }}
              onChange={(selected: Option[]) => {
                setTempType(selected);
              }}
            />
          </div>
        </div>
      </div>
      {/* ----------------------------------------------------------------------------- */}
      {/* Sales Funnel (w/o Test Drive) */}
      <div className="row flex-xxl-row">
        <div className='col-xxl-6 h-md-100 bd-highlight'>
          <KTCard stretch='stretch' className='mb-5'>
            <div className="card-header min-h-100 justify-content-center">
              <h3 className="card-title align-items-center flex-column">
                <span className="fw-bold fs-4">Sales Funnel</span>
                <span className="text-muted fw-semibold fs-6">(w/o Test Drive)</span>
              </h3>
            </div>
            <KTCardBody className="p-0">
              <div className='mb-6'>
                <HighchartsSample
                  id="w_o_test_drive"
                  type="column_range"
                  series={[]}
                  height='250px'
                  categories={[]}
                  optionsSetting={{
                    colors: ['#fcc47c'],
                    legend: {
                      enabled: false
                    }, tooltip: {
                      enabled: false,
                    }
                  }}
                />
              </div>
            </KTCardBody>
          </KTCard>
        </div>
        {/* Sales Funnel (Test Drive) */}
        <div className='col-xxl-6 h-md-100 bd-highlight'>
          <KTCard stretch='stretch' className='mb-5'>
            <div className="card-header min-h-100 justify-content-center">
              <h3 className="card-title align-items-center flex-column">
                <span className="fw-bold fs-4">Sales Funnel</span>
                <span className="text-muted fw-semibold fs-6">(Test Drive)</span>
              </h3>
            </div>
            <KTCardBody className="p-0">
              <div className='mb-6'>
                <HighchartsSample
                  id="w_o_test_drive"
                  type="column_range"
                  series={[]}
                  height='250px'
                  categories={[]}
                  optionsSetting={{
                    colors: ['#43a047'],
                    legend: {
                      enabled: false
                    }, tooltip: {
                      enabled: false,
                    }
                  }}
                />
              </div>
            </KTCardBody>
          </KTCard>
        </div>
      </div>
      {/* ----------------------------------------------------------------------------- */}
      {/* Test Drive Achievement by Days */}
      <div className="row flex-xxl-row">
        <div className='h-md-100 bd-highlight'>
          <KTCard stretch='stretch' className='mb-5'>
            <div className="card-header min-h-100 justify-content-center">
              <h3 className="card-title align-items-center flex-column">
                <span className="fw-bold fs-4">Test Drive Achievement by Days</span>
              </h3>
            </div>
            <KTCardBody className="py-5 px-2">
              <div className='mb-6'>
                <MaterialReactTableDetails
                  columns={testDriveAchiByDays['columns']}
                  data={testDriveAchiByDays['datas']}
                  isLoading={loading}
                  maxHeight='130px'
                  enableTopToolbar={false}
                  hover={false}
                  optionsHead={{
                    backgroundColor: '#FFFFFF',
                    color: 'black',
                    fontSize: 9,
                  }}
                />
              </div>
            </KTCardBody>
            <div className="card-header min-h-100 justify-content-center">
              <h3 className="card-title align-items-center flex-column">
                <span className="fw-bold fs-4">Test Drive Per Model by Days</span>
              </h3>
            </div>
            <KTCardBody className="py-5 px-2">
              <div className='mb-6'>
                <MaterialReactTableDetails
                  columns={testDriveModelByDays['columns']}
                  data={testDriveModelByDays['datas']}
                  isLoading={loading}
                  maxHeight='250px'
                  enableTopToolbar={false}
                  hover={false}
                  optionsHead={{
                    backgroundColor: '#FFFFFF',
                    color: 'black',
                    fontSize: 9,
                  }}
                />
              </div>
            </KTCardBody>
          </KTCard>
          {/*  */}
        </div>
      </div>
      {/* ----------------------------------------------------------------------------- */}
      {/* W/O Test Drive Leads Conversion by Model */}
      <div className="row flex-xxl-row">
        <div className='col-xxl-6 h-md-100 bd-highlight'>
          <KTCard stretch='stretch' className='mb-5'>
            <div className="card-header min-h-100 justify-content-center">
              <h3 className="card-title">
                <span className="card-label fw-bold fs-4">W/O Test Drive Leads Conversion by Model</span>
              </h3>
            </div>
            <KTCardBody className="py-5 px-2">
              <div className='mb-6'>
                <MaterialReactTableDetails
                  columns={TestDriveLeadsConversionByModel}
                  data={dataWOTestDriveLeadsConversionByModel}
                  isLoading={loading}
                  maxHeight='300px'
                  enableTopToolbar={false}
                  hover={false}
                  optionsHead={{
                    fontSize: 9,
                  }}
                />
              </div>
            </KTCardBody>
          </KTCard>
        </div>
        {/* Test Drive Leads Conversion by Model */}
        <div className='col-xxl-6 h-md-100 bd-highlight'>
          <KTCard stretch='stretch' className='mb-5'>
            <div className="card-header min-h-100 justify-content-center">
              <h3 className="card-title">
                <span className="card-label fw-bold fs-4">Test Drive Leads Conversion by Model</span>
              </h3>
            </div>
            <KTCardBody className="py-5 px-2">
              <div className='mb-6'>
                <MaterialReactTableDetails
                  columns={TestDriveLeadsConversionByModel}
                  data={dataTestDriveLeadsConversionByModel}
                  isLoading={loading}
                  maxHeight='300px'
                  enableTopToolbar={false}
                  hover={false}
                  optionsHead={{
                    fontSize: 9,
                  }}
                />
              </div>
            </KTCardBody>
          </KTCard>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TestDriveVAchievementIndex