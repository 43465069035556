import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import 'typeface-roboto'
import { MRT_DensityState, MRT_RowSelectionState, MRT_SortingState, MRT_TableInstance, MRT_Virtualizer, MaterialReactTable } from "material-react-table";
// import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { KTIcon } from "../KTIcon";
// import { MRT_ColumnDef } from "material-react-table";
import { ThemeModeComponent } from "../../../assets/ts/layout";
import { useThemeMode } from "../../../partials";
type PropsSimple = {
  data: any;
  columns: any;
  isLoading: boolean;
  enableRowActions?: boolean;
  enableRowSelection?: boolean;
  enableEditing?: boolean;
  renderTopToolbarCustomActions?: any;
};
const systemMode = ThemeModeComponent.getSystemMode() as "light" | "dark";

const MaterialReactTableSimple: FC<PropsSimple> = ({
  data,
  columns,
  isLoading,
  enableRowActions = true,
  enableRowSelection = false,
  enableEditing = false,
  renderTopToolbarCustomActions,
}) => {
  const tableInstanceRef = useRef<MRT_TableInstance<(typeof data)[0]>>(null);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const { mode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const rowVirtualizerInstanceRef = useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);
  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      // console.error(error);
    }
  }, [sorting]);
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: calculatedMode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              calculatedMode === 'light'
                ? 'rgb(255,255,255)' //random light yellow color for the background in light mode
                : '#000', //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          // MuiSwitch: {
          //   styleOverrides: {
          //     thumb: {
          //       color: "pink", //change the color of the switch thumb in the columns show/hide menu to pink
          //     },
          //   },
          // },
        },
      }),
    [calculatedMode, globalTheme.palette.secondary]
  );
  // const csvOptions = {
  //   fieldSeparator: ",",
  //   quoteStrings: '"',
  //   decimalSeparator: ".",
  //   showLabels: true,
  //   showTitle: true,
  //   title: "My Awesome CSV",
  //   useTextFile: false,
  //   useBom: true,
  //   useKeysAsHeaders: true,
  //   // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  //   // headers: columns.map((c) => c.header),
  // };
  // const csvExporter = new ExportToCsv(csvOptions);
  // const handleExportData = () => {
  //   csvExporter.generateCsv(data);
  // };
  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowSelection={enableRowSelection} //enable some features
        // enableColumnOrdering
        enableGlobalFilter={true} //turn off a feature
        defaultDisplayColumn={{ enableResizing: true }}
        // enableBottomToolbar={true}
        // enableColumnResizing
        enableGlobalFilterModes
        enablePagination={true}
        enablePinning
        enableFullScreenToggle
        // Freeze Header and Footer
        enableStickyHeader
        enableStickyFooter
        editDisplayMode={"cell"}
        enableEditing={enableEditing}
        enableSelectAll={enableRowSelection}
        // enableMultiRowSelection
        //   defaultColumn={{
        //   maxSize: 400,
        //   minSize: 80,
        //   size: 150, //default size is usually 180
        // }}
        selectAllMode={"all"}
        positionToolbarAlertBanner="bottom"
        // enableRowNumbers
        muiTableContainerProps={{ sx: { maxHeight: "800px" } }}
        onSortingChange={setSorting}
        state={{ isLoading, sorting, rowSelection }}
        // tableInstanceRef={tableInstanceRef}
        enableFacetedValues
        getRowId={(row) => row['id']} //give each row a more useful id
        onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
        rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
        initialState={{
          showColumnFilters: false,
          columnVisibility: { id: false, id_names: false },
          density: "compact",
          columnPinning: {
            //left: ["no"],
            right: ["actions"],
          },
        }}
        muiCircularProgressProps={{
          color: 'secondary',
          thickness: 5,
          size: 55,
        }}
        muiSkeletonProps={{
          animation: 'pulse',
          height: 28,
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            size: 350, //set custom width
            muiTableHeadCellProps: {
              align: "center", //change head cell props
            },
          },
          "mrt-row-numbers": {
            enableColumnOrdering: true, //turn on some features that are usually off
            enableResizing: false,
          },
          "mrt-row-select": {
            enableColumnActions: true,
            enableHiding: true,
            size: 30,
            muiTableBodyCellProps: {
              align: "center", //change head cell props
            },
          },
        }}
        muiTableProps={{
          sx: {
            tableLayout: "fixed",
          },
        }}
        muiTableHeadCellProps={{
          variant: "head",
          sx: {
            border: "1px dashed #e0e0e0",
            paddingTop: "15px",
            textTransform: "uppercase",
            whiteSpace: "normal",
            paddingY: 2,
            lineHeight: 1.5,
            wordWrap: "break-word",
          },
          align: "center",
        }}
        muiTableBodyCellProps={{
          sx: {
            width: '2rem',
            border: "1px dashed #e0e0e0",
            fontSize: "1.0rem",
            whiteSpace: "normal",
            wordWrap: "break-word",
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "0",
            border: "0px dashed #e0e0e0",
          },
        }}
        muiTableBodyRowProps={({ row }) => ({
          //implement row selection click events manually
          // onClick: () =>
          //   setRowSelection((prev) => ({
          //     ...prev,
          //     [row.id]: !prev[row.id],
          //   })),
          // selected: rowSelection[row.id],
          sx: {
            // cursor: 'pointer',
            '&:nth-of-type(even)': {
              backgroundColor: "#f3f3f3 !important",
            },
          },
        })}
        icons={{
          //change sort icon, connect internal props so that it gets styled correctly
          ArrowDownwardIcon: (props) => <KTIcon iconName="down" {...props} />,
          // SearchIcon: () => <KTIcon iconName="down" />,
          // SortIcon: (props) => <KTIcon iconName="down" {...props} />, //best practice
        }}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
    </ThemeProvider>
  );
};

type PropsDetails = {
  data: any;
  columns: any;
  isLoading?: boolean;
  maxHeight?: string
  enableTopToolbar?: boolean
  hover?: boolean
  lineHeight?: number
  density?: MRT_DensityState | undefined
  optionsHead?: {
    color?: string,
    backgroundColor?: string,
    fontSize?: number,
    font?: string,
    fontWeight?: string,
  }
  optionsBody?: {
    fontSize?: number,
    font?: string,

  }
};

const MaterialReactTableDetails: FC<PropsDetails> = ({
  data,
  columns,
  isLoading,
  maxHeight = "450px",
  enableTopToolbar = true,
  hover = true,
  density = 'compact',
  lineHeight = 2.5,
  optionsHead,
  optionsBody,
}) => {
  const tableInstanceRef = useRef<MRT_TableInstance<(typeof data)[0]>>(null);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const { mode } = useThemeMode();
  const calculatedMode = mode === "system" ? systemMode : mode;
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const rowVirtualizerInstanceRef = useRef<MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null);
  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    try {
      rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
    } catch (error) {
      // console.error(error);
    }
  }, [sorting]);
  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: calculatedMode, //let's use the same dark/light mode as the global theme
          primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
          info: {
            main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              calculatedMode === 'light'
                ? 'rgb(255,255,255)' //random light yellow color for the background in light mode
                : '#000', //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          // MuiSwitch: {
          //   styleOverrides: {
          //     thumb: {
          //       color: "pink", //change the color of the switch thumb in the columns show/hide menu to pink
          //     },
          //   },
          // },
        },
      }),
    [calculatedMode, globalTheme.palette.secondary]
  );

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableSorting={false}
        enableBottomToolbar={false}
        // isMultiSortEvent={(row) => true} //now no need to hold `shift` key to multi-sort
        enableTopToolbar={enableTopToolbar}
        enableGlobalFilter={false}
        defaultDisplayColumn={{ enableResizing: true }}
        enableColumnResizing={false}
        enableGlobalFilterModes={false}
        enablePinning={false}
        enableFullScreenToggle
        enableStickyHeader
        enableDensityToggle={false}
        enableStickyFooter
        muiTableContainerProps={{ sx: { height: maxHeight, maxHeight: '1000px' } }}
        state={{ isLoading }}
        getRowId={(row) => row['id']} //give each row a more useful id
        enableFacetedValues
        layoutMode="grid"
        initialState={{
          // sorting: [
          //   { id: 'state', desc: false }, //sort by state in ascending order by default
          //   { id: 'city', desc: true }, //then sort by city in descending order by default
          // ],
          // isFullScreen: true,
          showColumnFilters: false,
          columnVisibility: { id: false },
          density: density,
          columnPinning: {
            left: ["no"],
            right: ["actions"],
          },
        }}
        // muiTableProps={{
        //   sx: {
        //     tableLayout: "fixed",
        //   },
        // }}
        muiCircularProgressProps={{
          color: 'secondary',
          thickness: 5,
          size: 55,
        }}
        muiSkeletonProps={{
          animation: 'pulse',
          height: 28,
        }}
        muiTableBodyRowProps={{
          hover: hover,
          sx: {
            '&:nth-of-type(even)': {
              backgroundColor: "#f3f3f3 !important",
            },
          },
        }}
        muiTableHeadCellProps={{
          align: "center",
          sx: {
            backgroundColor: "#455a64",
            color: "white",
            font: '12px "bold" "Roboto"',
            ...optionsHead,
            paddingY: 0.5,
            lineHeight: lineHeight,
            whiteSpace: "normal",
            wordWrap: "break-word",
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            fontSize: 10,
            ...optionsBody,
            whiteSpace: "normal",
            wordWrap: "break-word",
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-numbers': {
            enableHiding: true, //now row numbers are hidable too
          },
        }}
        muiTableFooterCellProps={{
          align: "center",
          sx: {
            fontSize: 10,
            alignItems: "center",
            whiteSpace: "normal",
            wordWrap: "break-word",
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "0",
            border: "0px dashed #e0e0e0",
          },
        }}
      />
    </ThemeProvider>
  );
};


export { MaterialReactTableSimple, MaterialReactTableDetails };
