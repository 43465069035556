import { fetchingGetData, simpleFetching } from "./API";

export class ReportAPI {
  async getDataTableMscp(val) {
    let res = await fetchingGetData({
      url: `report/mscp?coverage=${val['coverage']}&date=${val['month']}`,
      method: "get",
    });
    return res;
  }

  // Add (Store Data Insert Query)
  async setMscpProcess(values: object[]) {
    let res = await simpleFetching({
      url: `report/mscp`,
      method: "post",
      data: {
        datas: values
      },
    });
    return res;
  }

  async setDuplicateMscpProcess(values: object) {
    let res = await simpleFetching({
      url: `report/mscp/duplicate_data`,
      method: "post",
      data: {
        datas: values
      },
    });
    return res;
  }

  async getLeasing() {
    let res = await fetchingGetData({
      url: `select/all_leasing`,
      method: "get",
    });
    return res;
  }
}
