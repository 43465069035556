import { fetchingGetData } from "./API";

export class PerformanceAPI {
  // Get SPK
  async getSPK(val, model) {
    let res = await fetchingGetData({
      url: `performance/mtd/spk/chart?coverage=${val || ""}&model=${model || ""}`,
      method: "get",
      // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Imt1bWFsYWhvbGRpbmciLCJwYXNzd29yZCI6Imt1bWFsYWhvbGRpbmciLCJpYXQiOjE2OTYzMjA3NzksImV4cCI6MTY5NjMyNDM3OX0.TLdF63dzgzwvduSzl4pbtznuDG0a0iRbCgFP39VgWEE`,
    });
    return res;
  }

  async getDO(val, model) {
    let res = await fetchingGetData({
      url: `performance/mtd/do/chart?coverage=${val || ""}&model=${model || ""}`,
      method: "get",
    });
    return res;
  }

  async getRS(val, model) {
    let res = await fetchingGetData({
      url: `performance/mtd/rs/chart?coverage=${val || ""}&model=${model || ""}`,
      method: "get",
    });
    return res;
  }

  async getTableSPK(val, model) {
    console.log("SPK VL MODEL", val, model);
    let res = await fetchingGetData({
      url: `performance/mtd/spk/summary/table?coverage=${val || ""}&model=${model || ""}`,
      method: "get",
    });
    return res;
  }

  async getTableDO(val, model) {
    let res = await fetchingGetData({
      url: `performance/mtd/do/summary/table?coverage=${val || ""}&model=${model || ""}`,
      method: "get",
    });
    return res;
  }

  async getQDO(val, model) {
    let res = await fetchingGetData({
      url: `performance/mtd/do/box?coverage=${val || ""}&model=${model || ""}`,
      method: "get",
    });
    return res;
  }

  async getYTD(val) {
    let res = await fetchingGetData({
      url: `performance/ytd/do/table_chart?coverage=${val || ""}`,
      method: "get",
    });
    return res;
  }

  async getRSactual(val, model) {
    let res = await fetchingGetData({
      url: `performance/rs/dealer/chart?coverage=${val || ""}&model=${model || ""}`,
      method: "get",
    });
    return res;
  }
  async getFilter(val) {
    let res = await fetchingGetData({
      url: `select/mtd/performance?asm=${val.asm || ""}&sm=${val.sm || ""}&outlet=${val.outlet || ""}`,
      method: "get",
    });
    return res;
  }
  async filterType() {
    let res = await fetchingGetData({
      url: `select/spk_do_rs/model`,
      method: "get",
    });
    return res;
  }
  // Get Brand
  async getBrand() {
    let res = await fetchingGetData({
      url: `select/national-information-gaikindo?tipe=getBrand`,
      method: "get",
    });
    return res;
  }
  //**End:: Get Select Data */
}
