/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../auth'

const DashboardPage: FC = () => {
  const { currentUser } = useAuth();
  return (
    <>
      {/* begin::Row */}
      <div className='row'>
        {/* begin::Col */}
        <div className='col-xxl-12'>
          <div className={`card card-flush h-md-100`}>
            <div
              className='d-flex flex-column justify-content-between m-0 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
              style={{
                backgroundPosition: '100% 100%',
                backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
              }}
            >
              <img
                className=''
                src={currentUser?.[0]['background']}
                style={{ borderRadius: 5 }}
                alt=''
              />
            </div>
          </div>
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Main Menu' })}</PageTitle>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle> */}
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
