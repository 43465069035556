import React, { useEffect, useRef } from "react";
import { PageTitle } from "../../../../_metronic/layout/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import ReactApexChart from "react-apexcharts";
require("highcharts/modules/exporting")(Highcharts);

function ChartHelper({ title, series, dtSPK }) {
  const chartComponent = useRef(null);
  const options = {
    chart: {
      backgroundColor: "transparent",
      // type: "column",
      height: 550,
    },
    title: {
      text: "",
    },
    xAxis: [
      {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        crosshair: false,
      },
    ],
    yAxis: [
      {
        // Primary yAxis
        show: false,
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[2],
          },
        },
        visible: false,
        title: false,
        opposite: true,
      },
      {
        // Secondary yAxis
        visible: false,
        gridLineWidth: 0,
        title: false,
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
      },
      {
        // Tertiary yAxis
        gridLineWidth: 0,
        visible: false,
        title: false,
        min: -10,
        labels: {
          // format: "{value} %",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        opposite: false,
      },
    ],
    tooltip: {
      shared: true,
      valueDecimals: 2,
      shadow: false,
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    plotOptions: {
      // dataLabels: {
      //   textShadow: false,
      //   textOutline: "0px",
      //   borderWidth: 0,
      // },
      series: {
        // pointStart: 0,
        borderRadius: "15%",
        pointWidth: 50,
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          borderWidth: 0,
          // textShadow: false,
          align: "center",
          //   y: 0, // 10 pixels down from the top
          style: {
            textOutline: 'none',
            fontSize: "10px",
            // textOutline: "0px",
          },
        },
      },
      spline: {
        dataLabels: {
          textShadow: false,
          textOutline: "0px",
          borderWidth: 0,
          format: "{point.y:.1f}%",
          // format: "{y} %",
        },
      },
      column: {
        // stacking: "normal",
        pointPadding: 2,
        // grouping: false,
        shadow: false,
        // groupPadding: 0.05,
        pointWidth: 35,
        padding: 1,
        min: 0,
        dataLabels: {
          borderWidth: 0,
          verticalAlign: "top",
          // y: -20,
        },
      },
    },
    series: [
      {
        name: "",
        type: "column",
        showInLegend: false,
        yAxis: 1,
        data: dtSPK.dataYearOld,
        // stack: "before",
        color: "#c4dae1",
        pointPlacement: 0,
        dataLabels: {
          y: -20,
        },
        tooltip: {
          pointFormat: "",
          // valueSuffix: " mm",
        },
      },
      {
        name: new Date().getFullYear() - 1,
        type: "column",
        color: "#287f9d",
        pointPlacement: -0.125,
        yAxis: 1,
        data: dtSPK.dataCurrentYearOld,
        dataLabels: {
          textShadow: false,
          textOutline: "0px",
        },
        // stack: "before",
        // tooltip: {
        //   valueSuffix: " mm",
        // },
      },
      {
        name: "Target",
        type: "column",
        pointPlacement: 0.06,
        yAxis: 1,
        color: "#fcc47c",
        data: dtSPK.target,
        tooltip: {
          // valueSuffix: " mm",
        },
      },
      {
        name: "",
        type: "column",
        showInLegend: false,
        color: "#c4e3c5",
        yAxis: 1,
        pointPlacement: 0.25,
        data: dtSPK.dataYearNow,
        dataLabels: {
          y: -20,
        },
        // stack: "curr",
        tooltip: {
          pointFormat: "",
          // valueSuffix: " mm",
        },
      },
      {
        name: new Date().getFullYear(),
        type: "column",
        color: "#439f47",
        yAxis: 1,
        pointPlacement: 0.13,
        data: dtSPK.dataCurrentYearNew,
        // stack: "curr",
        tooltip: {
          // valueSuffix: " mm",
        },
      },
      {
        name: "Ach",
        type: "spline",
        data: dtSPK.achive,
        yAxis: 2,
        dataLabels: {
          textShadow: false,
          textOutline: "0px",
          borderWidth: 0,
        },
        // center: [375, 365],
        tooltip: {
          // pointFormat: `{series.name}: {point.y} <b>({point.percentage:.1f}%)</b>`,

          valueSuffix: "%",
          // positioner: function () {
          //   return { x: 80, y: 50 };
          // },
          shadow: false,
          borderWidth: 0,
          backgroundColor: "rgba(255,255,255,0.8)",
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              floating: false,
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
              x: 0,
              y: 0,
            },
            yAxis: [
              {
                labels: {
                  align: "right",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                labels: {
                  align: "left",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                visible: false,
              },
            ],
          },
        },
      ],
    },
  };

  const lineOptions = {
    chart: {
      type: "spline",
      height: 250,
      backgroundColor: "transparent",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: title,
      style: {
        fontSize: "30px",
      },
      align: "center",
      verticalAlign: "top",
    },

    yAxis: {
      min: -80,
      title: false,
      visible: false,
    },

    xAxis: {
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      crosshair: false,
      visible: false,
      //   accessibility: {
      //     rangeDescription: "Range: 2010 to 2020",
      //   },
    },

    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "top",
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
    },
    plotOptions: {
      gridLineWidth: 0,
      series: {
        label: {
          connectorAllowed: true,
        },
        dataLabels: {
          enabled: true,
          textShadow: false,
          textOutline: "0px",
          allowOverlap: true,
          borderWidth: 0,
          // color: "#FFFFFF",
          align: "center",
          //   y: 0, // 10 pixels down from the top
          style: {
            fontSize: "10px",
          },
        },
        // pointStart: 2010,
      },
      spline: {
        dataLabels: {
          format: "{point.y:.1f}%",
          // format: "{y} %",
        },
      },
    },

    series: [
      {
        name: "MoM",
        type: "spline",
        data: dtSPK.mom,
        format: "{point.y:.1f}%",
        dataLabels: {
          y: 20,
          textShadow: false,
          textOutline: "0px",
          borderWidth: 0,
        },
      },
      {
        name: "YoY",
        data: dtSPK.yoy,
        format: "{point.y:.1f}%",
        dataLabels: {
          y: -10,
          textShadow: false,
          textOutline: "0px",
          borderWidth: 0,
        },
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "vertical",
              align: "center",
              verticalAlign: "top",
            },
          },
        },
      ],
    },
  };
  useEffect(() => {
    const chart = chartComponent.current.chart;
  }, []);
  return (
    <>
      <div className="card">
        <div style={{ marginBottom: "-4rem", overflow: "visible" }}>
          <HighchartsReact
            ref={chartComponent}
            highcharts={Highcharts}
            options={lineOptions}
            // series={series}
          />
        </div>
        <div>
          <HighchartsReact
            ref={chartComponent}
            highcharts={Highcharts}
            options={options}
            // series={series}
          />
        </div>
      </div>
    </>
  );
}

function SmallChartHelper({ dt }) {
  const [series, setSeries] = React.useState([0, 100]);
  const optionsPie = {
    chart: {
      redrawOnParentResize: false,
      redrawOnWindowResize: false,
    },
    colors: ["#E91E63", "#ded9d9"],
    formatter: function (val) {
      return val + "%";
    },
    tooltip: {
      enabled: false,
      showAlways: true,
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
    },
    labels: ["", ""],
    dataLabels: {
      style: {
        fontSize: "12px",
      },
      enabled: false,
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        return w.config.labels[seriesIndex] + ":  " + value.toFixed(0) + " %";
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        labels: {
          show: true,
          // total: {
          //   show: true,
          //   color: "#0065bd",
          //   fontWeight: "bold",
          //   fontSize: "20px",
          //   label: "Testing",
          // },
        },
        donut: {
          size: "60%",
          labels: {
            show: true,
            position: "center",
            fontSize: "12px",
            value: {
              offsetY: -8, // -8 worked for me
              color: "gray",
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "gray",
              formatter: function (val) {
                return val.globals.seriesTotals[0] + "%";
              },
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          chart: {
            width: 80,
          },
          legend: {
            hidden: true,
            // position: 'bottom'
          },
        },
      },
    ],
  };

  useEffect(() => {
    // console.log("Cek Dt",dt);
    let valS = Math.round(dt);
    setSeries([valS, 100 - valS]);
    // setSeries([dt])
  }, [dt]);
  return (
    <>
      <ReactApexChart
        options={optionsPie}
        series={series}
        type="donut"
        width="100"
      />
    </>
  );
}

export { ChartHelper, SmallChartHelper };
