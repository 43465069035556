import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import MenuConfiguration from "../pages/system-configuration/menu-configuration/MenuConfiguration";
import Brand from "../pages/master/brand/Brand";
import UserLogin from "../pages/master/user-login/UserLogin";
import UploadDataGaikindo from "../pages/sales/sales-analyst/master-data/national-information/gaikindo/UploadDataGaikindo";
import { InternalRetailSalesMasterIndex } from "../pages/sales/sales-analyst/master-data/internal-information/retail-sales";
import MasterDataRsWsIndex from "../pages/sales/sales-analyst/master-data/national-information/rs-ws/MasterDataRsWs";
import UploadDataNationalMSIndex from "../pages/sales/sales-analyst/master-data/national-information/national-ms/MasterDataMarketShare";
import { InternalMarketShareMasterIndex } from "../pages/sales/sales-analyst/master-data/internal-information/market-share";
import ChartRSWSIndex from "../pages/sales/national-information/RS-WS";
import MonthToDate from "../pages/sales/performance/mtd/Mtd";
import YearToDate from "../pages/sales/performance/ytd/Ytd";
import Rs from "../pages/sales/performance/rs/Rs";
import ChartGaikindoIndex from "../pages/sales/national-information/gaikindo";
import ChartSalesGrowthIndex from "../pages/sales/internal-information/sales-growth";
import { ChartMarketShareNationalIndex } from "../pages/sales/national-information/ms-national";
import { ChartMarketShareNationalGroupDealerIndex } from "../pages/sales/national-information/ms-national-group-dealer";
import TargetOutletIndex from "../pages/sales/sales-analyst/master-data/target/Outlet";
import MarketOverview from "../pages/sales/market-share/market-overview/MarketOverview";
import MarketSegment from "../pages/sales/market-share/market-segment/MarketSegment";
import ReportComplianceIndex from "../pages/sales/kpi-dashboard/report-compliance";
import StandingsIndex from "../pages/sales/kpi-dashboard/standings";
import AsmKpiIndex from "../pages/sales/kpi-dashboard/asm-kpi";
import PriceSegment from "../pages/sales/market-share/price-segment/PriceSegment";
import SmKpiIndex from "../pages/sales/kpi-dashboard/sm-kpi";
import OutletKpiIndex from "../pages/sales/kpi-dashboard/outlet-kpi";
import RootCauseAnalysisIndex from "../pages/sales/kpi-dashboard/root-cause-analysis";
import ContributionComparisonIndex from "../pages/sales/MSCP/contribution-comparison";
import SalesTargetVAchievementIndex from "../pages/sales/target-n-conversion/sales-target-v-achievement";
import TestDriveVAchievementIndex from "../pages/sales/target-n-conversion/test-drive-v-achievement";
import LeadsSourceNConversionIndex from "../pages/sales/target-n-conversion/leads_source_n_conversion";
import DashboardIndex from "../pages/sales/credit-process/dashboard";
import MtdProcessIndex from "../pages/sales/credit-process/mtd-process";
import ProcessJourneyIndex from "../pages/sales/credit-process/process-journey";
import LeadsQualityIndex from "../pages/sales/credit-process/leads-quality";
import ApprovalIndex from "../pages/sales/credit-process/approval";
import RejectIndex from "../pages/sales/credit-process/reject";
import MmeIndex from "../pages/sales/mme";
import ExecutionResult from "../pages/sales/activity/execution-result/ExecutionResult";
import ActivityMarket from "../pages/sales/activity/activity-market/ActivityMarket";
import Quantity from "../pages/sales/man-power/quantity/Quantity";
import Productivity from "../pages/sales/man-power/productivity/Productivity";
import SalesWoSpk from "../pages/sales/man-power/sales-wo-spk/SalesWoSpk";
import TeamFocusModel from "../pages/sales/man-power/team-focus-model/TeamFocusModel";
import SalesCompetency from "../pages/sales/man-power/sales-competency-level/SalesCompetency";
import TrainingRecomendation from "../pages/sales/man-power/training-recomendation/SalesCompetency";
import TurnOver from "../pages/sales/man-power/turn-over/TurnOver";
import SalesFunnelNTargetAchievementIndex from "../pages/sales/fleet/sales-funnel-n-target-achievement";
import FollowUpIndex from "../pages/sales/fleet/follow-up";
import StockPeriodeIndex from "../pages/sales/stock/stock-periode";
import WsSuggestionIndex from "../pages/sales/stock/ws-suggestion";
import MarketSegmentCont from "../pages/sales/market-share/market-segment/MarketSegmentCont";
import SalesTargetManagementIndex from "../pages/sales/sales-target-management";
import MasterNationalMSMapsIndex from "../pages/sales/sales-analyst/master-data/national-information/national-ms-maps";
import { CreditProcessReportIndex } from "../pages/sales/sales-report/credit-process";
import TargetAllIndex from "../pages/sales/sales-analyst/master-data/target/target-all";
import MscpIndex from "../pages/sales/MSCP/mscp";
import LeadToSpkMasterIndex from "../pages/sales/sales-analyst/master-data/target/lead_to_spk/index";
import { SpkToDoMasterIndex } from "../pages/sales/sales-analyst/master-data/target/spk_to_do/index";
import UploadDataSalesCompetency from "../pages/sales/sales-analyst/master-data/man-power/UploadDataSalesCompetency";
import UploadDataMME from "../pages/sales/sales-analyst/master-data/target/mme/UploadDataMME";
import UploadDataCreditProcess from "../pages/sales/sales-analyst/master-data/credit-process/UploadDataCreditProcess";
import UploadDataMsKec from "../pages/sales/sales-analyst/master-data/internal-information/market-share-kec/UploadDataMsKec";
import UploadDataPriceSegment from "../pages/sales/sales-analyst/master-data/internal-information/market-share-price-segment/UploadDataPriceSegment";
// import SpkToDoMasterIndex from "../pages/sales/sales-analyst/master-data/target/spk_to_do/index";

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const RoleConfigurations = lazy(() => import("../pages/system-configuration/role-configuration/Index"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path="auth/*" element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Dashboard */}
        <Route path="/dashboard" element={<DashboardWrapper />} />
        <Route path="/sales" element={<DashboardWrapper />} />
        <Route path="/after-sales" element={<DashboardWrapper />} />
        <Route path="/financial" element={<DashboardWrapper />} />
        <Route path="/tax" element={<DashboardWrapper />} />
        <Route path="/accounting" element={<DashboardWrapper />} />

        {/* Master */}
        <Route path="/brand" element={<Brand />} />
        <Route path="/user-login" element={<UserLogin />} />

        {/* System Configuration */}
        <Route path="/menu-configuration" element={<MenuConfiguration />} />
        <Route path="/role-configuration/*"
          element={
            <SuspensedView>
              <RoleConfigurations />
            </SuspensedView>
          }
        />

        {/* Profiles */}
        <Route path="/account/profile/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/**Begin:: Sales Menu Routers */}
        {/* National Information */}
        <Route path="/sales/gaikindo" element={<ChartGaikindoIndex />} />
        <Route path="/sales/rs-ws" element={<ChartRSWSIndex />} />
        <Route path="/sales/ms-national" element={<ChartMarketShareNationalIndex />} />
        <Route path="/sales/ms-national-group-dealer" element={<ChartMarketShareNationalGroupDealerIndex />} />
        {/* KPI Dashboard */}
        <Route path="/sales/report-compliance" element={<ReportComplianceIndex />} />
        <Route path="/sales/standings" element={<StandingsIndex />} />
        <Route path="/sales/asm-kpi" element={<AsmKpiIndex />} />
        <Route path="/sales/sm-kpi" element={<SmKpiIndex />} />
        <Route path="/sales/outlet-kpi" element={<OutletKpiIndex />} />
        <Route path="/sales/root-cause-analysis" element={<RootCauseAnalysisIndex />} />
        {/* Internal Information */}
        <Route path="/sales/sales-growth" element={<ChartSalesGrowthIndex />} />
        {/* MSCP */}
        <Route path="/sales/mscp" element={<MscpIndex />} />
        <Route path="/sales/contribution-comparison" element={<ContributionComparisonIndex />} />
        {/* Target & Conversion */}
        <Route path="/sales/sales-target-vs-achievement" element={<SalesTargetVAchievementIndex />} />
        <Route path="/sales/test-drive-vs-achievement" element={<TestDriveVAchievementIndex />} />
        <Route path="/sales/leads-source-n-conversion" element={<LeadsSourceNConversionIndex />} />
        {/* Credit Process */}
        <Route path="/sales/dashboard" element={<DashboardIndex />} />
        <Route path="/sales/mtd-process" element={<MtdProcessIndex />} />
        <Route path="/sales/process-journey" element={<ProcessJourneyIndex />} />
        <Route path="/sales/leads-quality" element={<LeadsQualityIndex />} />
        <Route path="/sales/approval" element={<ApprovalIndex />} />
        <Route path="/sales/reject" element={<RejectIndex />} />
        {/* MME */}
        <Route path="/sales/mme" element={<MmeIndex />} />
        {/* Fleet */}
        <Route path="/sales/sales-funnel-n-target-achievement" element={<SalesFunnelNTargetAchievementIndex />} />
        <Route path="/sales/follow-up" element={<FollowUpIndex />} />
        {/* Stock */}
        <Route path="/sales/ws-suggestion" element={<WsSuggestionIndex />} />
        <Route path="/sales/stock-periode" element={<StockPeriodeIndex />} />
        {/* Sales Target Management */}
        <Route path="/sales/sales-target-management" element={<SalesTargetManagementIndex />} />

        {/* Target */}
        <Route path="/sales/outlet" element={<TargetOutletIndex />} />
        <Route path="/sales/target-all" element={<TargetAllIndex />} />
        {/**End:: Sales Menu Routers */}

        {/**Begin:: Master data Sales Analyst */}

        {/*Master Data:: National Information */}
        <Route path="/sales/upload-data-gaikindo" element={<UploadDataGaikindo />} />
        <Route path="/sales/upload-data-rs-ws" element={<MasterDataRsWsIndex />} />
        <Route path="/sales/upload-data-national-ms" element={<UploadDataNationalMSIndex />} />
        <Route path="/sales/upload-data-national-ms-maps" element={<MasterNationalMSMapsIndex />} />

        {/*Master Data:: Internal Information */}
        <Route path="/sales/upload-data-ms" element={<InternalMarketShareMasterIndex />} />
        <Route path="/sales/upload-data-retail-sales" element={<InternalRetailSalesMasterIndex />} />
        <Route path="/sales/upload-data-ms-kecamatan" element={<UploadDataMsKec />} />
        <Route path="/sales/upload-data-ms-price-segment" element={<UploadDataPriceSegment />} />

        {/*Master Data:: Target */}
        <Route path="/sales/lead-to-spk" element={<LeadToSpkMasterIndex />} />
        <Route path="/sales/spk-to-do" element={<SpkToDoMasterIndex />} />
        <Route path="/sales/upload-mme" element={<UploadDataMME />} />
        {/**End:: Master data Sales Analyst */}

        {/**Begin:: Sales Report */}
        <Route path="/sales/credit-process-report" element={<CreditProcessReportIndex />} />
        {/**End:: Sales Report */}

        {/* Master Data:: Man Power */}
        <Route path="/sales/upload-sales-competency" element={<UploadDataSalesCompetency />} />
        {/**End:: Master data Man Power */}

        {/* Master Data:: Credit Process */}
        <Route path="/sales/upload-credit-process" element={<UploadDataCreditProcess />} />
        {/**End:: Master data Man Power */}

        {/**Begin:: Performance Menu */}
        <Route path="/sales/mtd" element={<MonthToDate />} />
        <Route path="/sales/ytd" element={<YearToDate />} />
        <Route path="/sales/dealer-rs-performance" element={<Rs />} />
        {/* <Route path="/sales/upload-data-gaikindo" element={<UploadDataGaikindo />} /> */}
        {/**End:: Performance Menu */}
        {/**Begin:: Market Share */}
        <Route path="/sales/market-overview" element={<MarketOverview />} />
        <Route path="/sales/market-segment" element={<MarketSegment />} />
        <Route path="/sales/market-segment-cont" element={<MarketSegmentCont />} />
        <Route path="/sales/price-segment" element={<PriceSegment />} />
        {/**End:: Market Share */}

        {/* Begin:: Activity */}
        <Route path="/sales/activity-execution--result" element={<ExecutionResult />} />
        <Route path="/sales/activity-vs-market" element={<ActivityMarket />} />
        {/* End:: Activity */}
        {/* Begin:: Man Power */}
        <Route path="/sales/quantity" element={<Quantity />} />
        <Route path="/sales/productivity" element={<Productivity />} />
        <Route path="/sales/sales-wo-spk" element={<SalesWoSpk />} />
        <Route path="/sales/team-focus-model" element={<TeamFocusModel />} />
        <Route path="/sales/sales-competency-level" element={<SalesCompetency />} />
        <Route path="/sales/training-recomendation" element={<TrainingRecomendation />} />
        <Route path="/sales/turn-over" element={<TurnOver />} />
        {/* End:: Man Power */}
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
