import clsx from "clsx";
import { useAuth } from "../../../../app/pages/auth";
import { MenuInnerAPI } from "../../../../app/api/MenuInnerAPI";

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string;
  toggleBtnIconClass?: string;
  menuPlacement?: string;
  menuTrigger?: string;
};

const BrandPerusahaanModeSwitcher = ({
  toggleBtnClass = "",
  toggleBtnIconClass = "fs-1",
  menuPlacement = "bottom-end",
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const { currentUser } = useAuth();
  const currentBrand = currentUser?.[0]['brand'].find((x: any) => x['id_brand'] === currentUser?.[0]['id_brand']);
  return (
    <>
      {/* begin::Menu toggle */}
      <a
        href="#"
        className={clsx("btn btn-icon ", toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach="parent"
        data-kt-menu-placement={menuPlacement}
      >
        <img
          className="w-30px h-30px rounded-1 ms-2"
          src={currentBrand?.['logos']}
          alt="metronic"
        />
      </a>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-200px"
        data-kt-menu="true"
      >
        {/* begin::Menu item */}
        {currentUser?.[0]['brand'].map((val: any) => (
          <div
            className={clsx("menu-link px-3 py-2", {
              // active: menuMode === "light",
            })}
            key={val['id']}
            onClick={async () => {
              await new MenuInnerAPI().updateBrandID({ id: currentUser?.[0]['id'], id_brand: val['id_brand'] }).then((res) => {
                setTimeout(async () => {
                  window.location.reload();
                }, 500);
              });
            }}
          >
            <a
              href="#"
              className={clsx("menu-link d-flex px-5", {
                active: val['id_brand'] === currentBrand?.['id_brand'],
              })}
            >
              <span className="symbol symbol-20px me-4">
                <img className="rounded-1" src={val['logos']} alt="metronic" />
              </span>
              {val['nama']}
            </a>
          </div>
        ))}
      </div>
      {/* end::Menu */}
    </>
  );
};

export { BrandPerusahaanModeSwitcher };
