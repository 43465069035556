/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMap from "highcharts/highmaps";
import map from "@highcharts/map-collection/countries/id/id-all.topo.json";
import { ThemeModeComponent } from '../../../../../_metronic/assets/ts/layout';
import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils';
import { HighchartsSample } from '../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample';
import { MaterialReactTableDetails } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';
import { NationalInformationAPI } from '../../../../api/NationalInformationAPI';
import { numberWithCommas } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';
import DataTableMuiModel from '../../../../models/DataTable/dataTableModal';
import { NationalMsIslandModel, NationalMsProvinceModel } from '../../../../models/sales/NationalInformationModel';

const ChartMarketShareNationalIndex: any = () => {
    const baseColors = ["#bf392b", "#297f9d", "#fcc47c", "#43a047", "#00897b", "#7030a0", "#a67650", "#f7b781", "#59a7a7", "#7c459c", "#4c5d34", "#6c8cbf", "#872551", "#8c8751"];
    const labelColor = getCSSVariableValue("--bs-gray-dark");
    const labelColorForDark = getCSSVariableValue("--bs-gray-800");
    const [loading, setLoading] = useState<boolean>(true);
    // Column & Row Table Island
    const [columntableIsland, setColumntableIsland] = useState<{ column: string[], data: string[] | number[] }>({
        column: [],
        data: [],
    });
    // Column & Row Table Province
    const [columntableProvince, setColumntableProvince] = useState<{ column: string[], data: string[] | number[] }>({
        column: [],
        data: [],
    });
    // Column & Row Table Province Area
    const [columntableProvinceArea, setColumntableProvinceArea] = useState<{ column: string[], data: string[] | number[] }>({
        column: [],
        data: [],
    });
    const [mapNationalMS, setMapNationalMS] = useState<[]>([]);
    const [mapNationalMSIsland, setMapNationalMSIsland] = useState<object[]>([{}]);
    const [date, setDate] = useState<{}>({});
    const [units, setUnits] = useState<{ polreg: string, gaikindo: string }>({
        polreg: '',
        gaikindo: ''
    });
    const [rangking, setRangking] = useState<{ rank_1: string, rank_2: string, rank_3: string }>({
        rank_1: '',
        rank_2: '',
        rank_3: ''
    });
    // Kumala Area 
    const [chartOptionsKumalaArea, setChartOptionsKumalaArea] = useState<{
        chart?: any,
        credits?: any,
        colors?: any[],
        title?: any,
        legend?: any,
        colorAxis?: any,
        mapNavigation?: any,
        plotOptions?: any,
        series: any,
    }>({
        chart: {
            height: 550,
            backgroundColor: 'transparent',
        }, credits: {
            enabled: false,
            text: "KumalaGroup.co.id",
            style: {
                fontFamily: "inherit",
                fontSize: "11px",
                color:
                    ThemeModeComponent.getMode() === "dark"
                        ? labelColorForDark
                        : labelColor,
            },
        }, colors: baseColors, title: {
            text: "",
            align: "center",
            style: {
                color:
                    ThemeModeComponent.getMode() === "dark"
                        ? labelColorForDark
                        : labelColor,
                font: 'bold 16px "inherit"',
            },
        }, legend: {
            itemStyle: {
                font: "inherit",
                color: ThemeModeComponent.getMode() === "dark"
                    ? labelColorForDark
                    : labelColor,
            },
            align: 'center',
            verticalAlign: 'bottom',
            floating: true,
            labelFormatter: function () {
                if (this['from'] === undefined) {
                    return `MS ≤ ${this['to']}%`;
                }
                if (this['to'] === undefined) {
                    return `MS ≥ ${this['from']}%`;
                }
                return `${this['to']} < MS < ${this['from']}%`;
            },
            layout: 'horizontal',
            valueDecimals: 0,
            symbolRadius: 0,
            symbolHeight: 14,
            itemHoverStyle: {
                color: "gray",
            },
        }, colorAxis: {
            dataClasses: [{
                color: "#bf392b",
                to: 2
            }, {
                color: "#fcc47c",
                from: 2,
                to: 2.5
            }, {
                color: "#43a047",
                from: 2.5
            }]
        }, mapNavigation: {
            enabled: true,
            buttonOptions: {
                verticalAlign: 'top',
                align: 'right'
            }
        }, plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                point: {
                    events: {
                        select(e: any) {
                            fetchingDataKumalaArea(e['target']['name']);
                        }, unselect(e: any) {
                            if (e.accumulate === false) {
                                fetchingDataKumalaArea(undefined);
                            }

                        }
                    },
                },
                marker: {
                    states: {
                        select: {
                            enabled: true,
                        },
                    },
                },
            }, map: {
                dataLabels: {
                    allowOverlap: true,
                    enabled: false,
                    // color: '#FFFFFF',
                    nullFormat: '',
                    style: {
                        fontSize: "10px",
                        font: "inherit",
                        color:
                            ThemeModeComponent.getMode() === "dark"
                                ? labelColorForDark
                                : labelColor,
                    },
                    //   formatter: function () {
                    //     if (this.point.value) {
                    //       return this.point.name;
                    //     }
                    //   },
                    format: '{point.name}:<br>{point.value} items'
                },
                tooltip: {
                    headerFormat: `<span style="font-size:12px"><b>{point.key}</b></span><br>`,
                    pointFormat: `cat_ms: {point.value}%`,
                }
            }
        }, series: [{
            type: "map",
            joinBy: 'name',
            data: [],
            mapData: map,
        }],
    });
    // Load Api : M/S National Maps Chart
    const fetchingNationalMSChartAPI = useCallback(async () => {
        const { data } = await new NationalInformationAPI().getNationalMSMapChart();
        let datas: object[] = [];
        data?.['mapNationalMS']?.['island'].map((item: any, i: number) => {
            let text_color = '';
            let icon = '';
            if (item['ytd_vol_market'] > 0) {
                text_color = 'text-success';
                icon = 'ki-arrow-up';
            } else if (item['ytd_vol_market'] < 0) {
                text_color = 'text-danger';
                icon = 'ki-arrow-down';
            }
            datas.push({
                x: item['x'],
                y: item['y'],
                point: item['id'],
                align: item['align'],
                text: `<div class="flex-column rounded px-2">
                    <div class="fw-bold fs-8 text-end mt-2" style="color: rgb(82,86,89)">${item['id']} : ${item['ytd_year_now']}%</div>
                    <div class="text-end fw-bolder my-2" style="color: rgb(82,86,89)">
                        <span class="fs-8 ${text_color}">
                            <i class="ki-duotone ${icon} fs-8 ${text_color}">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i> ${item['ytd_vol_market']}%
                        </span>
                    </div>
                    <div class="fw-bold fs-8 text-end mb-2" style="color: rgb(82,86,89)">${item['dealer']}</div>
                </div>`
            });
            return item;
        });
        setMapNationalMSIsland(datas);
        setMapNationalMS(data['mapNationalMS']?.['provinsi']);
        setDate(data?.['date']);
        setUnits({
            polreg: numberWithCommas(parseInt(data['mapNationalMS']?.['ytd_units_total']?.['polreg'])),
            gaikindo: numberWithCommas(parseInt(data['mapNationalMS']?.['ytd_units_total']?.['gaikindo'])),
        });
    }, []);
    // Load Api : M/S National Table Island & Province
    const fetchingNationalMSTableAPI = useCallback(async () => {
        setLoading(true);
        // Table Island
        const { data: tableIslandProvince } = await new NationalInformationAPI().getNationalMSTable();
        let dataTableMuiIslandModel = new DataTableMuiModel({ readOnly: true, NumRows: true });

        setRangking({
            rank_1: tableIslandProvince['dataTable']['island']['rangking'][0],
            rank_2: tableIslandProvince['dataTable']['island']['rangking'][1],
            rank_3: tableIslandProvince['dataTable']['island']['rangking'][2],
        })
        tableIslandProvince['dataTable']['island']['data'].map((item: any, i: number) => {
            let dataModel = new NationalMsIslandModel(item);
            dataTableMuiIslandModel.add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null,
            });
            return item;
        });
        setColumntableIsland({
            column: await dataTableMuiIslandModel.getColumn(),
            data: await dataTableMuiIslandModel.getDatas(),

        });
        // Table Province
        let dataTableMuiProvinceModel = new DataTableMuiModel({ readOnly: true, NumRows: true });
        tableIslandProvince['dataTable']['province'].map((item: any, i: number) => {
            let dataModel = new NationalMsProvinceModel(item);
            dataTableMuiProvinceModel.add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: null,
            });
            return item;
        });
        setColumntableProvince({
            column: await dataTableMuiProvinceModel.getColumn(),
            data: await dataTableMuiProvinceModel.getDatas(),

        });

        // Map National MS Area Kumala
        const { data } = await new NationalInformationAPI().getNationalMSArea();
        setChartOptionsKumalaArea({
            series: [{
                data: data['mapsNationalMsArea'],
            }],
        });
        setLoading(false);
    }, []);
    // Fetching API Kumala Area
    const fetchingDataKumalaArea = async (selected: string | undefined) => {
        setLoading(true);
        const { data } = await new NationalInformationAPI().getNationalMSArea();
        // Table Province Area
        let dataTableMuiProvinceAreaModel = new DataTableMuiModel({ readOnly: true, NumRows: true });
        if (selected === undefined) {
            data['tableNationalMsArea'].map((item: any, i: number) => {
                let dataModel = new NationalMsProvinceModel(item);
                dataTableMuiProvinceAreaModel.add({
                    id: i,
                    no: i + 1,
                    dataModel: dataModel,
                    actions: null,
                });
                return item;
            });
            setColumntableProvinceArea({
                column: await dataTableMuiProvinceAreaModel.getColumn(),
                data: await dataTableMuiProvinceAreaModel.getDatas(),

            });
        } else {
            let newTableArray = data['tableNationalMsArea'].filter((va: any) => va['province'] === selected);
            newTableArray.map((item: any, i: number) => {
                let dataModel = new NationalMsProvinceModel(item);
                dataTableMuiProvinceAreaModel.add({
                    id: i,
                    no: i + 1,
                    dataModel: dataModel,
                    actions: null,
                });
                return item;
            });
            setColumntableProvinceArea({
                column: await dataTableMuiProvinceAreaModel.getColumn(),
                data: await dataTableMuiProvinceAreaModel.getDatas(),

            });
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchingNationalMSChartAPI();
        fetchingNationalMSTableAPI();
        fetchingDataKumalaArea(undefined);
    }, [fetchingNationalMSChartAPI, fetchingNationalMSTableAPI]);
    return (
        <React.Fragment>
            <PageTitle breadcrumbs={[{ title: "National Information", path: "/sales/ms-national-group-dealer", isSeparator: false, isActive: false }]}>
                {`Market Share by Map YTD ${date['month']} ${date['year']}`}
            </PageTitle>
            {/* ----------------------------------------------------------------------------- */}
            {/* BEGIN:: Card Chart Column Maps */}
            <div className="row g-5">
                {/* Retail Sales */}
                <div className="bd-highlight">
                    <KTCard className='mb-5'>
                        <KTCardBody className="row p-3">
                            <div className='col-xxl-11'>
                                <HighchartsSample
                                    id="topology_chart"
                                    type='topology'
                                    height="700px"
                                    title={`The national-wide M/S YTD ${date['year']} is 2.5% compared to last year and is kept maintained`}
                                    series={mapNationalMS}
                                    optionsSetting={{
                                        annotations: {
                                            labels: mapNationalMSIsland
                                        }
                                    }}
                                />
                                <div className="border flex-column border-gray-300 w-200px rounded my-5">
                                    <div className="fw-bold fs-8 text-start ms-5 my-2" style={{ color: "rgb(82,86,89)" }}>Polreg: {units['polreg']} units</div>
                                    <div className="fw-bold fs-8 text-start ms-5 mb-2" style={{ color: "rgb(82,86,89)" }}>Gaikindo: {units['gaikindo']} units</div>
                                </div>
                            </div>
                            <div className='col-xxl-1 p-1'>
                                <div className="flex-column my-5">
                                    <div className="fw-bold fs-9 text-end mt-2 me-2" style={{ color: "rgb(82,86,89)" }}>NATIONWIDE</div>
                                    <div className="fw-bold fs-9 text-end my-2 me-2" style={{ color: "rgb(82,86,89)" }}>M/S: 2.5%</div>
                                    <div className="fw-bold fs-9 text-end mb-2 me-2" style={{ color: "rgb(82,86,89)" }}>(↔ 0.0%)</div>
                                </div>
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* END:: Card Chart Column Map */}
            {/* ----------------------------------------------------------------------------- */}
            {/* BEGIN:: Card Table Island & Province */}
            <div className="row g-5">
                {/* Island */}
                <div className="col-xxl-6 bd-highlight">
                    <KTCard className='h-700px mb-5'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1">Island</span>
                            </h3>
                        </div>
                        <KTCardBody className="p-3">
                            <MaterialReactTableDetails
                                data={columntableIsland['data']}
                                columns={columntableIsland['column']}
                                isLoading={loading}
                                enableTopToolbar={false}
                                maxHeight={"390px"}
                                hover={false}
                                lineHeight={1.2}
                                optionsHead={{
                                    font: '12px "bold" "Roboto"',
                                    backgroundColor: '#e0e0e0',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                                optionsBody={{
                                    font: '12px "Roboto"',
                                }}
                            />
                            <div className="fw-bold fs-4 text-start px-5 my-2" style={{ font: 'Roboto', color: "rgb(82,86,89)" }}>{`${rangking['rank_2']} & ${rangking['rank_3']} are still below National M/S, and ${rangking['rank_1']} has managed a bit increase compared to ${date['last_month']}`}
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
                {/* Province */}
                <div className="col-xxl-6 bd-highlight">
                    <KTCard className='h-700px mb-5'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1">Province</span>
                            </h3>
                        </div>
                        <KTCardBody className="p-3">
                            <MaterialReactTableDetails
                                data={columntableProvince['data']}
                                columns={columntableProvince['column']}
                                isLoading={loading}
                                enableTopToolbar={false}
                                maxHeight={"600px"}
                                hover={false}
                                lineHeight={1.2}
                                optionsHead={{
                                    font: '12px "bold" "Roboto"',
                                    backgroundColor: '#e0e0e0',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                                optionsBody={{
                                    font: '12px "Roboto"',
                                }}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* END:: Card Table Island & Province */}
            {/* ----------------------------------------------------------------------------- */}
            {/* BEGIN:: Card Map Area With Table */}
            <div className="row g-5">
                <div className="col-xxl-12 bd-highlight">
                    <KTCard className='mb-5'>
                        <div className="card-header justify-content-center">
                            <h3 className="card-title align-items-center flex-column">
                                <span className="card-label fw-bold fs-1">Kumala Area</span>
                            </h3>
                        </div>
                        <KTCardBody className="row col-xxl-12 p-1">
                            <div className="col-xxl-6">
                                <HighchartsReact
                                    constructorType="mapChart"
                                    highcharts={HighchartsMap}
                                    options={chartOptionsKumalaArea} />
                            </div>
                            <div className="col-xxl-6">
                                <MaterialReactTableDetails
                                    data={columntableProvinceArea['data']}
                                    columns={columntableProvinceArea['column']}
                                    isLoading={loading}
                                    enableTopToolbar={false}
                                    maxHeight={"490px"}
                                    hover={false}
                                    lineHeight={1.2}
                                    optionsHead={{
                                        font: '12px "bold" "Roboto"',
                                        backgroundColor: '#e0e0e0',
                                        fontWeight: 'bold',
                                        color: 'black',
                                    }}
                                    optionsBody={{
                                        font: '12px "bold" "Roboto"',
                                    }}
                                />
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            {/* END:: Card Map Area With Table */}
        </React.Fragment>
    );
};

export { ChartMarketShareNationalIndex };
