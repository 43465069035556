/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Chart } from "react-google-charts";

type Props = {
    data: any[];
    formatters?: any[];
    height?: string,
    width?: number,
};

const ChartTable: FC<Props> = ({
    data,
    formatters,
    height = "100%",
}) => {
    return (
        <div className=''>
            <Chart
                chartType="Table"
                width={'100%'}
                height={height}
                data={data}
                options={{
                    allowHtml: true,
                    showRowNumber: false,
                    cssClassNames: {
                        'headerRow': 'cssHeaderRow',
                        'tableRow': 'text-gray-800 bg-body',
                        'oddTableRow': 'text-gray-800 bg-body',
                        'selectedTableRow': 'cssSelectedTableRow',
                        'hoverTableRow': 'bg-hover-light',
                        'headerCell': 'border-1 border-gray-300 border-start text-white text-center',
                        'tableCell': 'border-1 border-gray-300 border-start text-center',
                        'rowNumberCell': 'cssRowNumberCell'
                    },
                }}
                formatters={formatters}
            />
        </div>
    );
};

export { ChartTable };
