import React, { FC } from 'react'
import { numberWithPoint } from '../../../helpers-data/AssetHelpers'


type Props = {
  id: string
  chartRef: any
  height?: string
  data: any
}


const ColumnAverageTop: FC<Props> = ({
  id,
  chartRef,
  height = '450px',
  data
}) => {
  let val = parseInt(data[0]?.["value"]);
  let values: any;
  let textColor = "";
  let icon = "";
  if (!isNaN(val) && val !== 0) {
    if (val >= 0) {
      textColor = "success";
      icon = "arrow-up";
    } else {
      textColor = "danger";
      icon = "arrow-down";
    }
    values = `${data[0]?.["value"]}%`;
  } else {
    values = null;
  }
  return (
    <React.Fragment>
      <div className='card-rounded bg-body position-relative'>
        <div className='col g-0 mb-0'>
          <div className='col mx-5'>
            <div className="fs-5 text-gray-400">{`${data[0]?.["name"]} `}</div>
            {values && (
              <span className={`badge fs-1`}>
                <i className={`ki-duotone ki-${icon} fs-5 text-${textColor} ms-n1`}>
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <div className={`fs-1 fw-bold text-${textColor}`}>{`${values}`}</div>
              </span>
            )}
            {!values && (
              <div className="fs-1 text-gray-400">null</div>
            )}
          </div>
          <div className='d-flex flex-wrap mx-5'>
            <div className='border border-0 border-gray-500 min-w-60px py-0 px-0 me-0 mb-3'>
              <div className="fs-7 text-gray-400">{data[1]?.["name"]}</div>
              <div className="fs-4 fw-bold text-dark">{data[1]?.["value"] ? numberWithPoint(data[1]?.["value"]) : 0}</div>
            </div>
            <div className='min-w-60px py-0 px-0 ms-5'>
              <div className="fs-7">{data[2]?.["name"]}</div>
              <div className="fs-4 fw-bold">{data[2]?.["value"] ? numberWithPoint(data[2]?.["value"]) : 0}</div>
            </div>
          </div>
        </div>
      </div>
      <div ref={chartRef} id={id} style={{ height: height }} />
    </React.Fragment>
  )
}

const ColumnAverageRight: FC<Props> = ({
  id,
  chartRef,
  height = '450px',
  data
}) => {
  let val = parseInt(data[0]?.["value"]);
  let values: any;
  let textColor = "";
  let icon = "";
  if (!isNaN(val) && val !== 0) {
    if (val >= 0) {
      textColor = "success";
      icon = "arrow-up";
    } else {
      textColor = "danger";
      icon = "arrow-down";
    }
    values = `${data[0]?.["value"]}%`;
  } else {
    values = "null";
  }
  return (
    <div className='row g-sm-5 flex-fill'>
      <div className="col col-lg-10">
        <div ref={chartRef} id={id} style={{ height: height }} />
      </div>
      <div className='row align-items-center col-lg-2'>
        <div className='d-flex text-center flex-column text-white'>
          <div className='border border-0 border-gray-500 min-w-60px py-0 px-0 mb-5'>
            <div className="fs-3 text-gray-400">{`${data[0]?.["name"]} `}</div>
            {values && (
              <span className={`badge fs-2qx`}>
                <i className={`ki-duotone ki-${icon} fs-2qx text-${textColor} ms-n1`}>
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <div className={`fs-2qx fw-bold text-${textColor}`}>{`${values}`}</div>
              </span>
            )}
            {!values && (
              <div className="fs-2qx text-gray-400">null</div>
            )}
          </div>
          <div className='border border-0 border-gray-500 min-w-60px py-0 px-0 mb-5'>
            <div className="fs-5 text-gray-400">{data[1]?.["name"]}</div>
            <div className="fs-2x fw-bold text-dark">{data[1]?.["value"] ? numberWithPoint(data[1]?.["value"]) : 0}</div>
          </div>
          <div className='border border-0 border-gray-500 min-w-60px py-0 px-0'>
            <div className="fs-5 text-gray-400">{data[2]?.["name"]}</div>
            <div className="fs-2x fw-bold text-dark">{data[2]?.["value"] ? numberWithPoint(data[2]?.["value"]) : 0}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ColumnAverageTop, ColumnAverageRight }
