import { fetchingGetData, multiPartFilesFetching } from "./API";

class ActivityAPI {
    async TargetvActual(cov: string, m?: string) {
      let res = await fetchingGetData({
        url: `activity/event?coverage=${cov || ""}&month=${m || ""}`,
        method: "get",
      });
      return res;
    }
    async Conversion(cov: string, m?: string) {
      let res = await fetchingGetData({
        url: `activity/conversion?coverage=${cov || ""}&month=${m || ""}`,
        method: "get",
      });
      return res;
    }
    async SpkSource(cov: string, m?: string) {
      let res = await fetchingGetData({
        url: `activity/spkcomposition?coverage=${cov || ""}&month=${m || ""}`,
        method: "get",
      });
      return res;
    }

    async momresult(cov: string) {
      let res = await fetchingGetData({
        url: `activity/momresult?coverage=${cov || ""}`,
        method: "get",
      });
      return res;
    }
    async spkbymodel(cov: string, m?: string) {
      let res = await fetchingGetData({
        url: `activity/spkbymodel?coverage=${cov || ""}&month=${m || ""}`,
        method: "get",
      });
      return res;
    }

    async activitymarket(cov: string, m?: string) {
      let res = await fetchingGetData({
        url: `activity/activitymarket?coverage=${cov || ""}&month=${m || ""}`,
        method: "get",
      });
      return res;
    }

    async getDataUpload() {
      let res = await fetchingGetData({
        url: `activity_vs_market/get`,
        method: "get",
      });
      return res;
    }
    
    async UploadFileActivityMarket(file: any, onUploadProgress: any) {
      let fd = new FormData();
      for (const key in file) {
        fd.append(key, file[key]);
      }
      let res = await multiPartFilesFetching({
        url: `activity_vs_market/upload`,
        method: "post",
        data: fd,
        onUploadProgress,
      });
      return res;
    }
  
  }

  export { ActivityAPI }