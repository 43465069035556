/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import {
  TablePriceSegment,
  TableWulingModel,
} from "../PriceSegmentHelper";
import { FilterSegmentAPI, PriceSegmentAPI } from "../../../../api/MarketShareAPI";
import { MultipleSelectCheckmarks } from "../../performance/SelectHelper";
import { LinearProgress } from "@mui/material";
import PriceByBrands from "./PriceByBrands";
import PriceByTypes from "./PriceByTypes";
import PriceByDetails from "./PriceByDetails";

function PriceSegment() {
  const [loading, setLoading] = React.useState(true);
  const [dtFilter, setDtFilter] = React.useState();
  const [islandVal, setIslandVal] = React.useState([]);
  const [provinceVal, setProvinceVal] = React.useState([]);
  const [asmVal, setasmVal] = React.useState([]);
  const [smVal, setsmVal] = React.useState([]);
  const [kabVal, setkabVal] = React.useState([]);
  const [kecVal, setkecVal] = React.useState([]);
  const [segmentVal, setSegmentVal] = React.useState([]);
  const [filterSegment, setFilterSegment] = React.useState([]);
  const [modelOpt, setModelOpt] = React.useState([]);
  const [modelVal, setModelVal] = React.useState([]);
  const monthOpt = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];
  const [slcMonth, setSlcMonth] = React.useState([]);
  const [priceRange, setPriceRange] = React.useState([]);
  const [priceRangeWuling, setPriceRangeWuling] = React.useState([]);
  const valRef = React.useRef(null);
  
  async function getFilter() {
    let val = {
      island: islandVal.slice(),
      province: provinceVal.slice(),
      kab: kabVal.slice(),
      asm: asmVal.slice(),
      sm: smVal.slice(),
      kec: kecVal.slice(),
      period: slcMonth,
      segment:segmentVal,
      model: modelVal
    };
    valRef.current = val;
    await new PriceSegmentAPI().filterPriceSegment(val).then((res) => {
      setDtFilter(res.data.searchOpt);
    });

    getData(val);
  }

  async function segmentModel() {
    await new FilterSegmentAPI().getSegment().then((res) => {
      let tempSegment = [];
      res.data.map((val) => {
        return tempSegment.push({
          label: val.text,
          value: val.id,
        });
      });
      setFilterSegment(tempSegment);
    })
    await new FilterSegmentAPI().getModel().then((res) => {
      setModelOpt(res.data);
    });
  }
  const getData = async (val) => {
    await new PriceSegmentAPI().priceRange(val).then((res) => {
      setPriceRange(res.response);
    });

    await new PriceSegmentAPI().priceRangeWuling(val).then((res) => {
      // const temp = [...res.response]
      let onTable = [];
      for (const [key, value] of Object.entries(res.response)) {
        // console.log("Key",Object.keys(value));
        onTable.push({
          name: key,
          data: Object.values(value),
          totals: Object.values(value).filter((item) => {
            return item.total !== undefined ?? item.total;
          }),
        });
      }
      setPriceRangeWuling(onTable);
    }).then(() => setLoading(false));
  };
  React.useEffect(() => {
    const controller = new AbortController();
    // getData();
    segmentModel();
    getFilter();
    return () => controller?.abort();
  }, [islandVal, provinceVal, kabVal, kecVal,asmVal, smVal, modelVal, segmentVal, slcMonth]);

  if (loading) {
   return <LinearProgress />;
  }
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: "Market Segment",
            path: "/sales/market-segment",
            isSeparator: false,
            isActive: false,
          },
        ]}
      >
        {"Market Segment"}
      </PageTitle>
      {dtFilter === undefined ? (
        <div>Loading Filter....</div>
      ) : (
        <>
          <div className="col d-flex justify-content-between my-10">
            <MultipleSelectCheckmarks
              title={"Island"}
              datas={dtFilter?.island}
              dt={islandVal}
              setDt={setIslandVal}
              dtwidth={170}
            />
            <MultipleSelectCheckmarks
              title={"Province"}
              datas={dtFilter?.province}
              dt={provinceVal}
              setDt={setProvinceVal}
              dtwidth={170}
            />
            <MultipleSelectCheckmarks
              title={"ASM"}
              datas={dtFilter?.asm}
              dt={asmVal}
              setDt={setasmVal}
              dtwidth={170}
            />
            <MultipleSelectCheckmarks
              title={"SM"}
              datas={dtFilter?.sm}
              dt={smVal}
              setDt={setsmVal}
              dtwidth={170}
            />
            <MultipleSelectCheckmarks
              title={"Kab"}
              datas={dtFilter?.kab}
              dt={kabVal}
              setDt={setkabVal}
              dtwidth={170}
            />
            <MultipleSelectCheckmarks
              title={"Kec"}
              datas={dtFilter?.kec}
              dt={kecVal}
              setDt={setkecVal}
              dtwidth={170}
            />
            <MultipleSelectCheckmarks
              title={"Month"}
              datas={monthOpt}
              dt={slcMonth}
              setDt={setSlcMonth}
              dtwidth={170}
            />
          </div>
        </>
      )}
      <div className="col d-flex justify-content-center my-10">
            <MultipleSelectCheckmarks
              title={"Segment"}
              datas={filterSegment}
              dt={segmentVal}
              setDt={setSegmentVal}
              dtwidth={150}
            />
            <MultipleSelectCheckmarks
              title={"Model"}
              datas={modelOpt}
              dt={modelVal}
              setDt={setModelVal}
              dtwidth={150}
            />
            {/* <MultipleSelectCheckmarks
              title={"Month"}
              datas={monthOpt}
              dt={slcMonth}
              setDt={setSlcMonth}
              dtwidth={150}
            /> */}
          </div>
      <div className="row d-flex justify-content-around mt-10">
        <div className="col col-md-6">
          <TablePriceSegment title={"Price Range"} dt={priceRange} />
        </div>
        <div className="col col-md-6">
          <TableWulingModel
            title={"Price Range Wuling Model"}
            dt={priceRangeWuling}
          />
        </div>
      </div>
      <div>
        <PriceByBrands val={valRef.current}/>
      </div>
      <div className="mt-10">
        <PriceByTypes val={valRef.current}/>
      </div>
      <div className="mt-10">
        <div className="d-flex align-items-center flex-column my-5 border-0 pt-5">
          {/* begin::Title */}
          <h3 className="align-items-center flex-column">
            <span className="card-label fw-bold fs-1 fs-xxl-5 fs-xl-3 mb-1">
              Price Range in Details
            </span>
          </h3>
        </div>
        <PriceByDetails val={valRef.current}/>
      </div>
    </>
  );
}

export default PriceSegment;
