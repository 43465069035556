import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../../../_metronic/layout/core";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { ChartHelper, SmallChartHelper } from "../ChartHelper";
import { TableHelper } from "../TableHelper";
import UpDownHelper from "../UpDownHelper";
import { PerformanceAPI } from "../../../../api/PerformanceAPI";
import FilterMultiSelect from "../../../../../_metronic/helpers/components/form-actions/FilteringInput";
import { equalsCheck } from "../../../../../_metronic/helpers/helpers-data/AssetHelpers";
import GetDataFilter from "../../../../hooks/filter";
import TypesFilter from "../../../../hooks/filterType";
import BoxDo from "./BoxDo";
import { Skeleton } from "@mui/material";

function MonthToDate() {
  const [asmVal, setasmVal] = useState([]);
  const [smVal, setsmVal] = useState([]);
  const [outletVal, setoutletVal] = useState([]);
  const [arrSelect, setArrSelect] = useState([]);
  const [smSelect, setSmSelect] = useState([]);
  const [outletSelect, setOutletSelect] = useState([]);

  const [typeSelected, setTypeSelected] = useState([]);
  const [typeValue, setTypeValue] = useState([]);
  // spk
  const [dataSPK, setDataSPK] = useState([]);
  const [dtTableSpk, setDtTableSpk] = useState([]);

  // do
  const [doDt, setDoDt] = useState([]);
  const [dtTableDO, setDtTableDO] = useState([]);
  const [boxDO, setBoxDO] = useState();
  // rs
  const [rsDt, setRsDt] = useState([]);

  const currDate = new Date();
  const day = currDate.getDate();
  const currMonth = parseInt(currDate.getMonth());
  const formatter = new Intl.DateTimeFormat("us", { month: "short" });
  const month1 = formatter.format(new Date());
  const filterData = GetDataFilter({ asmVal, smVal, outletVal });
  const typesData = TypesFilter();
  
  useEffect(() => {
    getDatas(filterData.coverage, typeValue);
  }, [filterData.coverage, typeValue]);
  function getDatas(val, model) {
    Promise.all([
      new PerformanceAPI().getTableSPK(val, model).then((res) => {
        // console.log("Ressss Data", res.data.length);
        setDtTableSpk(res?.data);
      }),
      new PerformanceAPI().getTableDO(val, model).then((res) => {
        // console.log("Ressss Data", res.data.length);
        setDtTableDO(res?.data);
      }),
      new PerformanceAPI().getQDO(val, model).then((res) => {
        setBoxDO(res.data);
      }),
      new PerformanceAPI().getSPK(val, model).then((res) => {
        setDataSPK(res.data);
      }),
        new PerformanceAPI().getDO(val, model).then((res) => {
          setDoDt(res.data);
        }),
       new PerformanceAPI().getRS(val, model).then((res) => {
          setRsDt(res.data);
        })
    ]);
  }

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: "Performance",
            path: "/sales/ytd",
            isSeparator: false,
            isActive: false,
          },
        ]}
      >
        {"MTD Performance"}
      </PageTitle>
      <div className="row d-flex justify-content-center mb-15 g-5">
        <div className="col-xxl-3 mb-5">
          <FilterMultiSelect
            options={filterData.asmOpt}
            label="ASM"
            isSelectAll={true}
            value={arrSelect}
            onMenuClose={() => {
              return equalsCheck(
                arrSelect.map((val) => val.value),
                asmVal
              )
                ? null
                : setasmVal(arrSelect.map((val) => val.value));
            }}
            onChange={(selected) => {
              setArrSelect(selected);
            }}
          />
        </div>
        <div className="col-xxl-3 mb-5">
          <FilterMultiSelect
            options={filterData.smOpt}
            label="SM"
            isSelectAll={true}
            value={smSelect}
            onMenuClose={() => {
              return equalsCheck(
                smSelect.map((val) => val.value),
                smVal
              )
                ? null
                : setsmVal(smSelect.map((val) => val.value));
            }}
            onChange={(selected) => {
              setSmSelect(selected);
            }}
          />
        </div>
        <div className="col-xxl-3 mb-5">
          <FilterMultiSelect
            options={filterData.outletOpt}
            label="Outlet"
            isSelectAll={true}
            value={outletSelect}
            onMenuClose={() => {
              return equalsCheck(
                outletSelect.map((val) => val.value),
                outletVal
              )
                ? null
                : setoutletVal(outletSelect.map((val) => val.value));
            }}
            onChange={(selected) => {
              setOutletSelect(selected);
            }}
          />
        </div>
        <div className="col-xxl-3 mb-5">
          <FilterMultiSelect
            options={typesData}
            label="Type"
            isSelectAll={true}
            value={typeSelected}
            onMenuClose={() => {
              return equalsCheck(
                typeSelected.map((val) => val.value),
                typeValue
              )
                ? null
                : setTypeValue(typeSelected.map((val) => val.value));
            }}
            onChange={(selected) => {
              setTypeSelected(selected);
            }}
          />
        </div>
      </div>
      {/* <KTCard className='mb-10'> */}
      <div className="row d-flex justify-content-between">
        <div className="card col-md-4" style={{ maxWidth: "35rem" }}>
          <div className="card-body d-flex justify-content-between">
            <div style={{ marginLeft: "-20px" }}>
              <img
                src={toAbsoluteUrl("/media/svg/wuling/wuling-perf.svg")}
                style={{ height: "7rem" }}
                alt=""
              />
            </div>
            <div>
              <h5>
                {month1} {day} MTD SPK
              </h5>
              <h3>{dataSPK?.dataCurrentYearNew?.at(currMonth) || 0}</h3>
              <div>
                <UpDownHelper val={dataSPK?.mom?.at(currMonth)} /> MoM
              </div>
              <div>
                <UpDownHelper val={dataSPK?.yoy?.at(currMonth)} /> YoY
              </div>
            </div>
            <div className="col-sm-2">
              <SmallChartHelper dt={dataSPK?.achive?.at(currMonth)} />
            </div>
          </div>
        </div>
        <div className="card col-md-4" style={{ maxWidth: "35rem" }}>
          <div className="card-body d-flex justify-content-between">
            <div style={{ marginLeft: "-20px" }}>
              <img
                src={toAbsoluteUrl("/media/svg/wuling/wuling-perf-do.svg")}
                style={{ height: "7rem" }}
                alt=""
              />
            </div>
            <div>
              <h5>
                {month1} {day} MTD DO
              </h5>
              <h3>{doDt?.dataCurrentYearNew?.at(currMonth) || 0}</h3>
              <div>
                <UpDownHelper val={doDt?.mom?.at(currMonth)} /> MoM
              </div>
              <div>
                <UpDownHelper val={doDt?.yoy?.at(currMonth)} /> YoY
              </div>
            </div>
            <div className="col-sm-2">
              <SmallChartHelper dt={doDt?.achive?.at(currMonth)} />
            </div>
          </div>
        </div>
        <div className="card col-md-4" style={{ maxWidth: "35rem" }}>
          <div className="card-body d-flex justify-content-between">
            <div style={{ marginLeft: "-20px" }}>
              <img
                src={toAbsoluteUrl("/media/svg/wuling/wuling-perf-rs.svg")}
                style={{ height: "7rem" }}
                alt=""
              />
            </div>
            <div>
              <h5>
                {month1} {day} MTD RS
              </h5>
              <h2>{rsDt?.dataCurrentYearNew?.at(currMonth) || 0}</h2>
              <div>
                <UpDownHelper val={rsDt?.mom?.at(currMonth)} /> MoM
              </div>
              <div>
                <UpDownHelper val={rsDt?.yoy?.at(currMonth)} /> YoY
              </div>
            </div>
            <div className="col-sm-2">
              <SmallChartHelper dt={rsDt?.achive?.at(currMonth)} />
            </div>
          </div>
        </div>
      </div>
      {/* </KTCard> */}
      {/* Data Quartal */}
      <BoxDo boxDO={boxDO} />
      {/* SPK MoM and YoY */}
      <div className="card mt-10">
        <ChartHelper title={"SPK MoM and YoY"} dtSPK={dataSPK} />
      </div>
      {/* DO MoM and YoY */}
      <div className="card mt-10">
        <ChartHelper title={"DO MoM and YoY"} dtSPK={doDt} />
      </div>
      {/* RS MoM and YoY */}
      <div className="card mt-10">
        <ChartHelper title={"RS MoM and YoY"} dtSPK={rsDt} />
      </div>
      <div
        className="row d-flex flex-sm-row flex-column my-10 justify-content-evenly"
        // style={{ height: "50rem" }}
      >
        <div className="card col col-lg-6">
          {!dtTableSpk ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"100%"}
              height={500}
            />
          ) : (
            <TableHelper title={"Summary (SPK)"} dtSpk={dtTableSpk} />
          )}
        </div>
        <div className="card col col-lg-6">
          <TableHelper
            title={"Summary (DO)"}
            dtSpk={dtTableDO}
          />
        </div>
      </div>
    </>
  );
}

export default MonthToDate;
