import React from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import { HighchartsSample } from '../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample';
import { Form, Formik } from 'formik';
import { NationalInformationAPI } from '../../../../api/NationalInformationAPI';
import { MscpAPI } from '../../../../api/MscpAPI';
import { InputSelect2 } from '../../../../../_metronic/helpers/components/form-actions/FormInput';

type MyState = {
  title: string,
  coverage: string,
  isLoading: boolean,
  initial_values: Object,
  initial_values_leasing: Object,
  data_chart: Object,
}

export default class ContributionComparisonIndex extends React.Component {
  constructor(props: any) {
    super(props);
    this["api_"] = new NationalInformationAPI();
    this["api"] = new MscpAPI();
    this["month"] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    this['option_asm'] = [];
    this['option_sm'] = [];
    this['option_outlet'] = [];
    this['option_leasing'] = [];
  }
  state: MyState = {
    title: "Contribution Comparison",
    coverage: '',
    isLoading: true,
    initial_values: {
      asm: [],
      sm: [],
      outlet: [],
    }, initial_values_leasing: {
      leasing_1: '',
      leasing_2: '',
    }, data_chart: {
      line: {
        series: []
      }, column: {
        series: []
      }
    }
  }

  //* ----------------------------------------------------------------------------- */
  // BEGIN:: componentDidMount */
  async componentDidMount() {
    const { initial_values, initial_values_leasing } = this['state'];
    //? Load Filter Options ASM, SM, OUTLET
    await this['api'].getFilter(initial_values).then(async (res: any) => {
      this['option_asm'] = res['data']['dataAsm'].map((val: any) => {
        return {
          value: val['id_team_asm'],
          label: val['area'],
        };
      });
      this['option_sm'] = res['data']['dataSm'].map((val: any) => {
        return {
          value: val['id_team_sm'],
          label: val['nama_team'],
        };
      });
      this['option_outlet'] = res['data']['dataOutlet'].map((val: any) => {
        return {
          value: val['id_outlet'],
          label: val['text'],
        };
      });
      this.setState({
        isLoading: false,
        coverage: res['data']['coverage'],
      });
    });
    // LEASING
    await this['api'].getLeasing().then(async (res: any) => {
      this['option_leasing'] = res['data'].map((val: any) => {
        return {
          value: val['id_leasing'],
          label: val['nama_singkat_leasing'],
        };
      });
    });
    await this['FilterLoadChart'](this['state']['coverage'], initial_values_leasing);
  }
  // END:: componentDidMount */
  //* ----------------------------------------------------------------------------- */
  async FilterLoadChart(coverage?: Object, params_leasing?: Object) {
    const { data: dataResult } = await this["api"].getDoLeasingComparison(coverage, params_leasing);
    const { leasing_1, leasing_2, growth } = dataResult["contribution_comparison"];
    this["setState"]({
      ...this["state"],
      data_chart: {
        line: {
          series: [{
            name: "Diff",
            type: "line",
            data: growth,
            tooltip: {
              valueSuffix: " %",
            },
          }]
        }, column: {
          series: [{
            name: `Select Leasing 1`,
            type: "column",
            yAxis: 1,
            data: leasing_1,
          }, {
            name: `Select Leasing 2`,
            type: "column",
            yAxis: 1,
            data: leasing_2,
          }]
        }
      },
    });
  }
  async loadOptionsSm(array: object) {
    this.setState({ isLoading: true });
    await this['api'].getFilter(array).then(async (res: any) => {
      this['option_sm'] = res['data']['dataSm'].map((val: any) => {
        return {
          value: val['id_team_sm'],
          label: val['nama_team'],
        };
      });
      this['option_outlet'] = res['data']['dataOutlet'].map((val: any) => {
        return {
          value: val['id_outlet'],
          label: val['text'],
        };
      });
      this.setState({
        isLoading: false,
        coverage: res['data']['coverage'],
      });
    });
  }
  async loadOptionsOutlet(array: object) {
    this.setState({ isLoading: true });
    await this['api'].getFilter(array).then(async (res: any) => {
      this['option_outlet'] = res['data']['dataOutlet'].map((val: any) => {
        return {
          value: val['id_outlet'],
          label: val['text'],
        };
      });
      this.setState({
        isLoading: false,
        coverage: res['data']['coverage'],
      });
    });
  }
  render(this: object) {
    const { title, initial_values, initial_values_leasing, data_chart } = this['state'];
    return (
      <React.Fragment>
        <PageTitle breadcrumbs={[{ title: "MSCP", path: "/sales/contribution-comparison", isSeparator: false, isActive: false }]}>
          {title}
        </PageTitle>
        {/* Filter Input */}
        <div className="row g-5 g-xl-9">
          <Formik enableReinitialize={true} initialValues={initial_values} onSubmit={() => { }}>
            {({ setFieldValue, values }) => (
              <Form>
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-3 mb-5">
                    <FilterMultiSelect
                      label={"ASM"}
                      name="asm"
                      options={this['option_asm']}
                      onChange={async (selected: Option[]) => {
                        setFieldValue("asm", selected);
                        const asm = selected.map((x: object) => x["value"]);
                        let newArrays = {
                          asm: asm,
                          sm: [],
                          outlet: []
                        };
                        await this['loadOptionsSm'](newArrays);
                        setTimeout(() => {
                          this['FilterLoadChart'](this['state']['coverage'], {
                            leasing_1: initial_values_leasing['leasing_1'],
                            leasing_2: initial_values_leasing['leasing_2']
                          });
                        }, 500);
                      }}
                      value={values["asm"]}
                    />
                  </div>
                  <div className="col-md-3 mb-5">
                    <FilterMultiSelect
                      label={"SM"}
                      name="sm"
                      options={this['option_sm']}
                      isLoading={this["state"]["isLoading"]}
                      onChange={async (selected: Option[]) => {
                        setFieldValue("sm", selected);
                        const asm = values["asm"].map(
                          (x: object) => x["value"]
                        );
                        const sm = selected.map(
                          (x: object) => x["value"]
                        );
                        let newArrays = {
                          asm: asm,
                          sm: sm,
                          outlet: [],
                        }
                        await this['loadOptionsOutlet'](newArrays);
                        setTimeout(() => {
                          this['FilterLoadChart'](this['state']['coverage'], {
                            leasing_1: initial_values_leasing['leasing_1'],
                            leasing_2: initial_values_leasing['leasing_2']
                          });
                        }, 500);
                      }}
                      value={values["sm"]}
                    />
                  </div>
                  <div className="col-md-3 mb-5">
                    <FilterMultiSelect
                      label={"Outlet"}
                      name="outlet"
                      options={this['option_outlet']}
                      isLoading={this["state"]["isLoading"]}
                      onChange={(selected: Option[]) => {
                        setFieldValue("outlet", selected);
                        const asm = values["asm"].map(
                          (x: object) => x["value"]
                        );
                        const sm = values["sm"].map(
                          (x: object) => x["value"]
                        );
                        const outlet = selected.map(
                          (x: object) => x["value"]
                        );
                        let newArrays = {
                          asm: asm,
                          sm: sm,
                          outlet: outlet,
                        }
                        this['loadOptionsOutlet'](newArrays);
                        setTimeout(() => {
                          this['FilterLoadChart'](this['state']['coverage'], {
                            leasing_1: initial_values_leasing['leasing_1'],
                            leasing_2: initial_values_leasing['leasing_2']
                          });
                        }, 500);
                      }}
                      value={values["outlet"]}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* ----------------------------------------------------------------------------- */}
        <div className="row flex-xxl-row h-md-100">
          {/* Card DO Leasing Comparison */}
          <div className='h-md-100 mb-5 bd-highlight'>
            <KTCard flush stretch={"stretch-75"}>
              <div className="card-header justify-content-center">
                <h3 className="card-title align-items-center flex-column">
                  <span className="card-label fw-bold fs-1 mb-1">DO Leasing Comparison</span>
                </h3>
              </div>
              <KTCardBody className="p-3 align-items-start flex-column">
                <Formik enableReinitialize={true} initialValues={{
                  leasing_1: '',
                  leasing_2: '',
                }} onSubmit={() => { }}>
                  {(formik) => (
                    <Form>
                      <div className="row justify-content-center align-items-center">
                        <div className="col-md-3 mb-5">
                          <InputSelect2
                            label={"All"}
                            name="leasing_1"
                            options={[
                              {
                                label: "ALL",
                                value: ''
                              },
                              {
                                // All Leasing Tanpa Cash
                                label: "ALL W/O CASH",
                                value: 'k'
                              },
                              {
                                label: "CASH",
                                value: 0
                              },
                              ...this['option_leasing']
                            ]}
                            formik={formik}
                            isSearchable
                            // className='z-index-3'
                            onChange={async (e: number) => {
                              const values = e?.["value"];
                              this['setState']({ initial_values_leasing: {
                                leasing_1: values,
                                leasing_2: formik.values['leasing_2']
                              } });
                              await this['FilterLoadChart'](this['state']['coverage'], {
                                leasing_1: values,
                                leasing_2: formik.values['leasing_2']
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-3 mb-5">
                          <InputSelect2
                            label={"All"}
                            name="leasing_2"
                            options={[
                              {
                                label: "ALL",
                                value: ''
                              },
                              {
                                // All Leasing Tanpa Cash
                                label: "ALL W/O CASH",
                                value: 'k'
                              },
                              {
                                label: "CASH",
                                value: 0
                              },
                              ...this['option_leasing']
                            ]}
                            formik={formik}
                            isSearchable
                            // className='z-index-3'
                            onChange={async (e: number) => {
                              const values = e?.["value"];
                              this['setState']({ initial_values_leasing: {
                                leasing_1: formik.values['leasing_1'],
                                leasing_2: values
                              } });
                              await this['FilterLoadChart'](this['state']['coverage'], {
                                leasing_1: formik.values['leasing_1'],
                                leasing_2: values
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <HighchartsSample
                  id="chart_line"
                  type="line"
                  height='70px'
                  series={data_chart['line']['series']}
                  categories={this["month"]}
                  chartStyle={{
                    spacingLeft: 5,
                    spacingRight: 5,
                  }}
                  optionsSetting={{
                    colors: ["#43a047"],
                    xAxis: {
                      visible: false
                    }, yAxis: {
                      visible: false
                    }, legend: {
                      enabled: false
                    }
                  }}
                />
                <HighchartsSample
                  id="chart_column"
                  type="column_line"
                  series={data_chart['column']['series']}
                  categories={this["month"]}
                  chartStyle={{
                    spacingLeft: 5,
                    spacingRight: 5,
                  }}
                  optionsSetting={{
                    legend: {
                      enabled: false
                    }, xAxis: {
                      crosshair: false
                    }, tooltip: {
                      shared: false,
                    }
                  }}
                />
              </KTCardBody>
            </KTCard>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
