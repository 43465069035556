import React, { useEffect, useMemo, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { HighchartsSample } from '../../../../../_metronic/helpers/components/chart/Highcharts/HighchartsSample';
import SingleLoopTable from './dataTable';
import { MaterialReactTableDetails } from '../../../../../_metronic/helpers/components/MaterialReactTable/MaterialReactTable';
import GetDataFilter from '../../../../hooks/filter';
import { dates } from '../dashboard';
import { DateFilter } from '../../../../hooks/filterMSCP';
import { equalsCheck } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';
import { MscpRejectAPI } from '../../../../api/MscpAPI';
import DataTableMuiModel from '../../../../models/DataTable/dataTableModal';
import { ListSpk, ListSpkProcess } from './helperTable';

const RejectIndex = (props: any) => {
    const [asmVal, setAsmVal] = useState<string[]>([]);
    const [tempAsm, setTempAsm] = useState<Option[]>([]);
    const [outletVal, setOutletVal] = useState<string[]>([]);
    const [tempOutlet, setTempOutlet] = useState<Option[]>([]);
    const filterData = GetDataFilter({ asmVal: asmVal, outletVal: outletVal });
    // Date Filter
    const dateOpt = DateFilter();
    const [dateValue, setDateValue] = useState<any>([]);
    const [tempDate, setTempDate] = useState<Option[]>([]);
    const [dateSelected, setDateSelected] = useState<dates>({
        month: [],
        year: [],
    });
    const [dtTable, setDtTable] = useState<any>([]);
    const [rejectSummary, setRejectSummary] = useState<any>({
        title: ['New SPK', 'O/S SPK'],
        values: [73.2, 26.8]
    });

    // Table
    const [dtListSpk, setDtListSpk] = useState<any>([]);
    const [columnListSpkProcess, setColumnListSpkProcess] = useState<any>([]);
    const [dataListSpkProcess, setDataListSpkProcess] = useState<any>([]);

    const dataTable = async ({ coverage, dates }) => {
        await new MscpRejectAPI().getDtListSpk({ coverage, dates }).then((res: any) => {
            setDtListSpk(res.data);
        })
        // const { data: tableListSpk } = await new MscpRejectAPI().getDtListSpk({ coverage, dates })

        // let tableListSpkProcess = new DataTableMuiModel({ readOnly: true });
        // tableListSpk.map((item: any, i: number) => {
        //     let dataModel = new ListSpkProcess(item);
        //     tableListSpkProcess.add({
        //         id: i,
        //         no: i + 1,
        //         dataModel: dataModel,
        //         actions: null
        //     })
        //     return item
        // })
        // setColumnListSpkProcess(await tableListSpkProcess.getColumn());
        // setDataListSpkProcess(await tableListSpkProcess.getDatas());
    }

    let memoInitialData = useMemo(() => {
        let initialDates = {
            month: dateOpt?.map((val: dates) => val.month),
            year: dateOpt?.map((val: dates) => val.year)
        }
        return {
            dtTempDate: dateSelected?.month.length > 0 ? dateSelected : initialDates
        }
    }, [dateOpt, dateSelected]);

    useEffect(() => {
        if (filterData.coverage) {
            new MscpRejectAPI().getDtTable({ coverage: filterData.coverage, dates: memoInitialData.dtTempDate }).then((res: any) => {
                setDtTable(res.data);
                setRejectSummary({
                    title: ['New SPK', 'O/S SPK'],
                    values: res?.data?.dtPies
                });
            })
            dataTable({ coverage: filterData.coverage, dates: memoInitialData.dtTempDate })
        }
    }, [filterData.coverage, memoInitialData.dtTempDate]);

    return (
        <React.Fragment>
            <PageTitle breadcrumbs={[{ title: "Credit Process", path: "/sales/reject", isSeparator: false, isActive: false }]}>
                {"Reject"}
            </PageTitle>
            <div className="row g-5" >
                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-3 mb-5">
                        <FilterMultiSelect
                            label={"ASM"}
                            name="asm"
                            options={filterData?.asmOpt}
                            onMenuClose={() => {
                                let toFilter = tempAsm?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    asmVal
                                )
                                    ? null
                                    : setAsmVal(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempAsm(selected);
                            }}
                            value={tempAsm}
                        />
                    </div>
                    <div className="col-lg-3 mb-5 z-index-2">
                        <FilterMultiSelect
                            label={"Outlet"}
                            name="outlet"
                            options={filterData?.outletOpt}
                            onMenuClose={() => {
                                let toFilter = tempOutlet?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    outletVal
                                )
                                    ? null
                                    : setOutletVal(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setTempOutlet(selected);
                            }}
                            value={tempOutlet}
                        />
                    </div>
                    <div className="col-lg-3 mb-5 z-index-2">
                        <FilterMultiSelect
                            label={"Date"}
                            name="date"
                            options={dateOpt}
                            onMenuClose={() => {
                                let toFilter = tempDate?.map((val) => val.value);
                                let forDates = {
                                    month: tempDate?.map((val: Option) => val.month),
                                    year: tempDate?.map((val: Option) => val.year)
                                }
                                let toDates = tempDate.length > 0 ? forDates
                                    : ({
                                        month: dateOpt?.map((val: dates) => val.month),
                                        year: dateOpt?.map((val: dates) => val.year)
                                    })

                                return equalsCheck(
                                    toFilter as [],
                                    dateValue
                                )
                                    ? null
                                    : (setDateValue(toFilter as []), setDateSelected(toDates as any));
                            }}
                            onChange={(selected: Option[]) => {
                                setTempDate(selected);
                            }}
                            value={tempDate}
                        />
                    </div>
                </div>
            </div >

            <div className="row g-5 mh-100">
                {/* On Process */}
                <div className="col-xxl-5 bd-highlight">
                    <KTCard className='mb-5 h-xxl-350px'>
                        <KTCardBody className="pb-0 ps-5 pe-2 pt-0">
                            <div className='row'>
                                <div className="col-xxl-3 d-flex align-self-center mt-md-0 mt-5 text-center flex-column">
                                    <h3 className="card-title align-items-center ">
                                        <span className="card-label fw-bold flex-wrap fs-2">Rejection Summary</span>
                                    </h3>
                                    {/* <div className="h-xxl-350px me-2 justify-content-center">
                                        <span className="fs-1 fw-bolder text-center">Rejection Summary</span>
                                    </div> */}
                                </div>
                                <div className='col col-xxl-9'>
                                    <HighchartsSample
                                        id="total_leads"
                                        type="pie_donut"
                                        height='350px'
                                        series={rejectSummary}
                                        // positionLegend={'TopRight'}
                                        optionsSetting={{
                                            colors: ['#ff7043', '#ffb098'],
                                            legend: {
                                                align: "right",
                                                verticalAlign: "top",
                                                layout: "vertical",
                                                x: 0,
                                                y: 150
                                            }
                                        }}
                                        chartStyle={{
                                            spacingLeft: 5,
                                            spacingRight: 5,
                                        }}
                                    />
                                </div>
                            </div>
                        </KTCardBody>
                    </KTCard>
                </div>
                {/* Idle SPK */}
                <div className="col-xxl-7 bd-highlight">
                    <KTCard className='mb-5 h-350px'>
                        <div className="card-header justify-content-start">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-2">Reject</span>
                            </h3>
                        </div>
                        <KTCardBody className="row pt-0">
                            <SingleLoopTable
                                data={{
                                    columns: ['DP (%)'],
                                    data: dtTable,
                                    targetData: 'reject',
                                    max: 'maxReject'
                                }}
                                backgroundColor={["6", "63%", "50%"]}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            <div className="row g-5 mh-100">
                <div className="col-xxl-5 bd-highlight">
                    <KTCard className='mb-5 h-350px'>
                        <div className="card-header justify-content-start">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-2">On Process</span>
                            </h3>
                        </div>
                        <KTCardBody className="row pt-0">
                            <SingleLoopTable
                                data={{
                                    columns: ['DP (%)'],
                                    data: dtTable,
                                    targetData: 'inProcess',
                                    max: 'maxInProcess'
                                }}
                                backgroundColor={["57", "66%", "34%"]}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
                {/* Reject */}
                <div className="col-xxl-7 bd-highlight">
                    <KTCard className='mb-5 h-350px'>
                        <div className="card-header justify-content-start">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-2">Idle SPK</span>
                            </h3>
                        </div>
                        <KTCardBody className="row pt-0">
                            <SingleLoopTable
                                data={{
                                    columns: ['DP (%)'],
                                    data: dtTable,
                                    targetData: 'idleSpk',
                                    max: 'maxIdleSpk'
                                }}
                                backgroundColor={["6", "63%", "50%"]}
                            />
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
            <div className="row g-5 mh-100">
                <div className="bd-highlight">
                    <KTCard className='mb-5 h-700px'>
                        <div className="card-header justify-content-start">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold fs-2">List SPK Process</span>
                            </h3>
                        </div>
                        <KTCardBody className="row pt-0">
                            <ListSpk data={dtListSpk}/>
                            {/* <MaterialReactTableDetails
                                data={dataListSpkProcess}
                                columns={columnListSpkProcess}
                                isLoading={false}
                                maxHeight={"600px"}
                                hover={false}
                                optionsHead={{
                                    fontSize: 10,
                                }}
                            /> */}
                        </KTCardBody>
                    </KTCard>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RejectIndex;