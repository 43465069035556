import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { ConvertionTable, ExecutionTable, MoMChart, SPKPieChart, SPKbyModel } from '../Execution';
import { ActivityAPI } from '../../../../api/ActivityAPI';
import { LinearProgress } from '@mui/material';
import { PerformanceAPI } from '../../../../api/PerformanceAPI';
import FilterMultiSelect, { Option } from '../../../../../_metronic/helpers/components/form-actions/FilteringInput';
import { equalsCheck } from '../../../../../_metronic/helpers/helpers-data/AssetHelpers';

type selectType = Array<{ label: string; value: string | number }>
type modelspk = {
    tempData: Array<string | number>;
    model: Array<string | number>;
    sumberProspek: Array<string | number>
}
const ExecutionResult = () => {
    const currDate = new Date();
    const currMonth = currDate.getMonth();
    const monthOpt = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
    ];
    const [asmVal, setasmVal] = useState<string[]>([]);
    const [smVal, setsmVal] = useState<string[]>([]);
    const [outletVal, setoutletVal] = useState<string[]>([]);
    const [asmOpt, setasmOpt] = useState<selectType>([]);
    const [smOpt, setsmOpt] = useState<selectType>([]);
    const [outletOpt, setoutletOpt] = useState<selectType>([]);
    const [arrSelect, setArrSelect] = useState<Option[] | null>([]);
    const [smSelect, setSmSelect] = useState<Option[] | null>([]);
    const [outletSelect, setOutletSelect] = useState<Option[] | null>([]);
    const [slcMonth, setSlcMonth] = useState<number[]>([currMonth + 1]);
    const [monthSlc, setMonthSlc] = useState<Option[]>([monthOpt[currMonth + 1]]);
    const [cov, setCoverage] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [dataTable, setDataTable] = useState<[]>([]);
    const [spkComp, setSpkComp] = useState<[]>([]);
    const [conversionTable, setConversionTable] = useState<[]>([]);
    const [dtmomresult, setDtMomResult] = useState<[]>([]);
    const [dtspkbyModel, setDtSpkbyModel] = useState<modelspk>({
        tempData: [],
        model: [],
        sumberProspek: []
    });

    async function getFilter() {
        let val = {
            asm: asmVal.slice(),
            sm: smVal.slice(),
            outlet: outletVal.slice(),
        };
        return await new PerformanceAPI().getFilter(val).then((res) => {
            let tempAsm: selectType = [];
            let tempsm: selectType = [];
            let tempoutlet: selectType = [];
            res.data.dataAsm.map((val: { area: string; id_team_asm: string | number }) => {
                return tempAsm.push({
                    label: val.area,
                    value: val.id_team_asm,
                });
            });
            res.data.dataSm.map((val: { region: string; id_team_sm: string | number }) => {
                return tempsm.push({
                    label: val.region,
                    value: val.id_team_sm,
                });
            });
            res.data.dataOutlet.map((val: { text: string; id_outlet: string | number }) => {
                return tempoutlet.push({
                    label: val.text,
                    value: val.id_outlet,
                });
            });
            setasmOpt(tempAsm);
            setsmOpt(tempsm);
            setoutletOpt(tempoutlet);
            setCoverage(res.data.coverage);
            return res.data.coverage
        }).then((val) => {
            let monthFilter =
                slcMonth.length > 0 ? slcMonth.toString() : (currMonth + 1).toString();
            GetData(val, monthFilter)
        });
    }

    const GetData = async (v: string, m?: string) => {
        Promise.all([
            new ActivityAPI().TargetvActual(v, m).then((res) => {
                setDataTable(res.data)
            }),
            new ActivityAPI().Conversion(v, m).then((res) => {
                setConversionTable(res.data)
            }),
            new ActivityAPI().SpkSource(v, m).then((res) => {
                setSpkComp(res.data)
            }),
            new ActivityAPI().momresult(v).then((res) => {
                setDtMomResult(res.data)
            }),
            new ActivityAPI().spkbymodel(v, m).then((res) => {
                setDtSpkbyModel(res.data)
            })
        ]).then(() =>
            setLoading(false))
    }

    useEffect(() => {
        const controller = new AbortController();
        getFilter()
        return () => {
            controller.abort();
          };
        // GetData()
    }, [asmVal, smVal, outletVal, slcMonth]);

    if (loading) {
        return <LinearProgress />
    } else {
        return (
            <>
                <PageTitle
                    breadcrumbs={[
                        {
                            title: "Activity",
                            path: "/sales/activity-execution--result",
                            isSeparator: false,
                            isActive: false,
                        },
                    ]}
                >
                    {"Activity Execution & Result"}
                </PageTitle>
                <div className="row d-flex justify-content-center mb-15 g-5 absolute">
                    <div className="col-xxl-3 mb-5">
                        <FilterMultiSelect
                            options={asmOpt}
                            label="ASM"
                            isSelectAll={true}
                            value={arrSelect}
                            onMenuClose={() => {
                                let toFilter = arrSelect?.map((val) => val.value);

                                return equalsCheck(
                                    toFilter as [],
                                    asmVal
                                )
                                    ? null
                                    : setasmVal(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setArrSelect(selected);
                            }}
                        />
                    </div>
                    <div className="col-xxl-3 mb-5">
                        <FilterMultiSelect
                            options={smOpt}
                            label="SM"
                            isSelectAll={true}
                            value={smSelect}
                            onMenuClose={() => {
                                let toFilter = smSelect?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    smVal
                                )
                                    ? null
                                    : setsmVal(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                console.log("selected", selected);
                                setSmSelect(selected);
                            }}
                        />
                    </div>
                    <div className="col-xxl-3 mb-5">
                        <FilterMultiSelect
                            options={outletOpt}
                            label="Outlet"
                            isSelectAll={true}
                            value={outletSelect}
                            onMenuClose={() => {
                                let toFilter = outletSelect?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    outletVal
                                )
                                    ? null
                                    : setoutletVal(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setOutletSelect(selected);
                            }}
                        />
                    </div>
                    <div className="col-xxl-3 mb-5">
                        <FilterMultiSelect
                            options={monthOpt}
                            label="Type"
                            isSelectAll={true}
                            value={monthSlc}
                            onMenuClose={() => {
                                let toFilter = monthSlc?.map((val) => val.value);
                                return equalsCheck(
                                    toFilter as [],
                                    slcMonth
                                )
                                    ? null
                                    : setSlcMonth(toFilter as []);
                            }}
                            onChange={(selected: Option[]) => {
                                setMonthSlc(selected);
                            }}
                        />
                    </div>
                </div>
                <div className='card mt-5'>
                    <ExecutionTable comp={{ title: "Target V Actual", data: dataTable }} />
                </div>
                <div className='row d-flex mt-10 justify-content-between'>
                    <div className='card col col-md-6 mt-15'>
                        <MoMChart title='MoM Activity Result' data={dtmomresult} />
                    </div>
                    <div className='col col-md-6'>
                        <div className='card mt-15'>
                            <ConvertionTable comp={{ title: "Conversion", data: conversionTable }} />
                        </div>
                        <div className='card mt-10'>
                            <SPKPieChart comp={{ title: "SPK Source Composition", data: spkComp }} />
                        </div>
                    </div>
                </div>
                <div className='card mt-15'>
                    <SPKbyModel comp={{ title: "SPK by Model", data: dtspkbyModel }} />
                </div>
            </>
        );
    }
};

export default ExecutionResult;
