import { number } from "yup";

class MuiDataTableModel {
   constructor(data) {
      this['id'] = data['id'];
      this['no'] = data['no'];
      this['dataModel'] = data['dataModel'];
      this['actions'] = data['actions'];
   }
   async getData(readOnly) {
      let object = {};
      object["id"] = this['id'];
      object["no"] = this['no'];
      if (!readOnly) object["actions"] = this['actions'];
      await this['dataModel'].getdata().then((values) =>
         values.map((item) => {
            const { id, value } = item;
            return object[id] = value;
         })
      );
      return object;
   }

   async getColumn(readOnly, NumRows) {
      let outp = [];
      await this['dataModel'].getdata().then((values) =>
         values.map((item) => {
            const { id, header, enableClickToCopy, enableHiding, filterVariant, filterSelectOptions, size, footer, enableColumnOrdering, Cell, columns, muiTableBodyCellProps, muiEditTextFieldProps, enableEditing } = item;
            outp = [
               ...outp,
               {
                  id: id,
                  accessorKey: id,
                  header: header,
                  enableClickToCopy: enableClickToCopy,
                  columns: columns,
                  enableHiding: enableHiding,
                  filterVariant: filterVariant,
                  filterSelectOptions: filterSelectOptions,
                  size: size, //medium column
                  Footer: footer,
                  Cell: Cell,
                  enableColumnOrdering: enableColumnOrdering,
                  muiTableBodyCellProps: muiTableBodyCellProps,
                  enableEditing: enableEditing,
                  muiEditTextFieldProps: muiEditTextFieldProps,
                  //    muiTableBodyCellProps: ({ cell }) => ({
                  //       sx: {
                  //           fontSize: fontSize,
                  //          //  color: "white",
                  //          //  backgroundColor: cell.getValue() >= 16 ? "green" : "red"
                  //       },
                  //       align: align,
                  //   }),
                  muiTableFooterCellProps: ({ cell }) => ({
                     align: 'center',
                     sx: {
                        fontSize: 10,
                     },
                  })
               },
            ];
            return item;
         })
      );
      return readOnly && !NumRows ? [{
         id: "no",
         accessorKey: "no",
         header: "#",
         enableColumnFilter: false,
         // enableColumnActions: false,
         minSize: 50, //min size enforced during resizing
         maxSize: 80, //max size enforced during resizing
         muiTableBodyCellProps: {
            align: 'center',
         },
         muiTableBodyRowProps: {
            sx: {
               '&:nth-of-type(even)': {
                  backgroundColor: "#f3f3f3 !important",
               },
            },
         },
         muiTableHeadCellProps: {
            align: 'center',
         }
      }, ...outp] : readOnly && NumRows ? outp : [{
         id: "no",
         accessorKey: "no",
         header: "#",
         enableColumnFilter: false,
         // enableColumnActions: false,
         minSize: 50, //min size enforced during resizing
         maxSize: 80, //max size enforced during resizing
         muiTableBodyRowProps: {
            sx: {
               '&:nth-of-type(even)': {
                  backgroundColor: "#f3f3f3 !important",
               },
            },
         },
         muiTableBodyCellProps: {
            align: 'center',
         }
      }, ...outp, {
         id: "actions",
         accessorKey: "actions",
         header: "Actions",
         minSize: 120, //min size enforced during resizing
         maxSize: 150, //max size enforced during resizing
         enableSorting: false,
         enableColumnFilter: false,
         enablePinning: false,
         enableGlobalFilter: false,
         enableMultiSort: false,
         muiTableBodyRowProps: {
            sx: {
               '&:nth-of-type(even)': {
                  backgroundColor: "#f3f3f3 !important",
               },
            },
         },
         muiTableBodyCellProps: {
            align: 'center',
         }
      }];
   }
}

export default class DataTableMuiModel {
   constructor(props) {
      this['datas'] = [];
      this['readOnly'] = props == null || props['readOnly'] == null ? false : props['readOnly'];
      this['NumRows'] = props == null || props['NumRows'] == null ? false : props['NumRows'];
   }

   add(newData) {
      this['datas'] = [...this['datas'], new MuiDataTableModel(newData)];
   }

   async getDatas() {
      let outp = [];
      for (let i = 0; i < this['datas'].length; i++) {
         outp = [
            ...outp,
            { id: i, ...(await this['datas'][i].getData(this['readOnly'], this['NumRows'])) },
         ];
      }
      return outp;
   }

   async getColumn() {
      if (this['datas'].length <= 0)
         return [
            {
               accessorKey: "no",
               header: "",
            },
            {
               accessorKey: "actions",
               header: "",
            },
         ];
      else return await this['datas'][0].getColumn(this['readOnly'], this['NumRows']);
   }
}
